import { RemoveDoorFromDoorGroupDTO } from '@hakimo-ui/hakimo/types';
import useAuthenticatedMutation from '../shared/useAuthenticatedMutation';

export function useRemoveDoorFromDoorGroup(onSuccess: () => void) {
  const url = `/v2/orm/door_groups/doors`;

  const request = new Request(url, {
    method: 'DELETE',
  });

  return useAuthenticatedMutation<null, RemoveDoorFromDoorGroupDTO>(request, {
    onSuccessInvalidationKeys: [['doors'], ['door_groups']],
    onSuccess: onSuccess,
  });
}

export default useRemoveDoorFromDoorGroup;
