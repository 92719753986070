import { Fragment, memo } from 'react';
import { MARGIN, ZoomModeTickFactor } from '../constants';
import { Tick, ZoomMode, getFactor } from './util';

interface Props {
  svgWidth: number;
  allTicks: Tick[];
  tickGap: number;
  zoomMode: ZoomMode;
}

export function TicksContainer(props: Props) {
  const { svgWidth, allTicks, tickGap, zoomMode } = props;
  const maxLinePosition = svgWidth - MARGIN.right;
  return (
    <g id="ticks-container">
      <line
        x1={MARGIN.left}
        x2={svgWidth - MARGIN.right}
        y1="50"
        y2="50"
        className="stroke-status-gray stroke-1"
      />
      {allTicks.map((tick, i, arr) => (
        <Fragment key={`${tick.date}-${i}`}>
          <line
            x1={tickGap * i + MARGIN.left}
            x2={tickGap * i + MARGIN.left}
            y1="50"
            y2={i % ZoomModeTickFactor[zoomMode].frequency === 0 ? 40 : 45}
            className="stroke-status-gray stroke-1"
          />
          {i % ZoomModeTickFactor[zoomMode].frequency === 0 && (
            <text
              x={tickGap * i}
              y={35}
              className="dark:fill-ondark-text-1 fill-onlight-text-1 text-[10px]"
            >
              {tick.text}
            </text>
          )}
          {tick.isMidnight && (
            <>
              <line
                x1={tickGap * i + MARGIN.left}
                x2={tickGap * i + MARGIN.left}
                y1="25"
                y2="5"
                className="stroke-status-gray stroke-1"
              />
              <text
                x={
                  maxLinePosition <
                  tickGap * i +
                    (tickGap * getFactor(i, arr.length, zoomMode)) / 2 -
                    20
                    ? 2 * maxLinePosition
                    : tickGap * i +
                      (tickGap * getFactor(i, arr.length, zoomMode)) / 2 -
                      20
                }
                y={8}
                className="dark:fill-ondark-text-1 fill-onlight-text-1 text-[10px]"
              >
                {tick.date}
              </text>
              <line
                x1={Math.min(tickGap * i + MARGIN.left, maxLinePosition)}
                x2={Math.min(
                  tickGap * i +
                    (tickGap * getFactor(i, arr.length, zoomMode)) / 2 -
                    25,
                  maxLinePosition
                )}
                y1="5"
                y2="5"
                className="stroke-status-gray stroke-1"
              />
              <line
                x1={Math.min(
                  tickGap * i +
                    (tickGap * getFactor(i, arr.length, zoomMode)) / 2 +
                    50,
                  maxLinePosition
                )}
                x2={Math.min(
                  tickGap * (i + getFactor(i, arr.length, zoomMode)) +
                    MARGIN.left,
                  maxLinePosition
                )}
                y1="5"
                y2="5"
                className="stroke-status-gray stroke-1"
              />
            </>
          )}
        </Fragment>
      ))}
    </g>
  );
}

export default memo(TicksContainer);
