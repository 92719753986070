import { AudioDevice } from '@hakimo-ui/hakimo/types';
import useAuthenticatedMutation from '../shared/useAuthenticatedMutation';

export function useModifyAudioDevices(
  onSuccess?: () => void,
  onError?: () => void
) {
  const url = `/v2/orm/audio_devices`;

  const request = new Request(url, {
    method: 'PATCH',
  });

  return useAuthenticatedMutation<null, Partial<AudioDevice>>(request, {
    onSuccess,
    onSuccessInvalidationKeys: [['audio']],
    onError,
  });
}

export default useModifyAudioDevices;
