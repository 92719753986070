import { CameraDetail } from '@hakimo-ui/hakimo/types';
import { useState } from 'react';
import { SettingFieldText } from './SettingFieldText';
import { SettingSection } from './SettingSection';
import { UpdateLivestreamUrl } from './update-fields/UpdateLivestreamUrl';
import UpdateLocation from './update-fields/UpdateLocation';
import { UpdateName } from './update-fields/UpdateName';
import { UpdateRtspUrl } from './update-fields/UpdateRtspUrl';

interface Props {
  camera: CameraDetail;
}

export function SettingSectionDetails(props: Props) {
  const { camera } = props;
  const [updateName, setUpdateName] = useState(false);
  const [updateRtspUrl, setUpdateRtspUrl] = useState(false);
  const [updateLivestreamUrl, setUpdateLivestreamUrl] = useState(false);
  const [updateLocation, setUpdateLocation] = useState(false);

  const getCameraLocation = () => {
    if (!camera.location) {
      return 'Not specified';
    }
    const { name, city, state, country } = camera.location;
    const locationValues = [name, city, state, country].filter(Boolean);
    return locationValues.join(', ');
  };

  return (
    <>
      <SettingSection name="Details">
        <SettingFieldText
          name="Name"
          value={camera.name}
          onClickUpdate={() => setUpdateName(true)}
        />
        <SettingFieldText
          name="RTSP URL"
          value={camera.rtspUrl}
          onClickUpdate={() => setUpdateRtspUrl(true)}
        />
        <SettingFieldText
          name="Livestream URL"
          value={camera.livestreamUrl}
          onClickUpdate={() => setUpdateLivestreamUrl(true)}
        />
        <SettingFieldText
          name="Location"
          value={getCameraLocation()}
          onClickUpdate={() => setUpdateLocation(true)}
        />
      </SettingSection>
      {updateName && (
        <UpdateName
          cameraId={camera.id}
          name={camera.name}
          onClose={() => setUpdateName(false)}
        />
      )}
      {updateRtspUrl && (
        <UpdateRtspUrl
          cameraId={camera.id}
          rtspUrl={camera.rtspUrl}
          onClose={() => setUpdateRtspUrl(false)}
        />
      )}
      {updateLivestreamUrl && (
        <UpdateLivestreamUrl
          cameraId={camera.id}
          livestreamUrl={camera.livestreamUrl}
          onClose={() => setUpdateLivestreamUrl(false)}
        />
      )}
      {updateLocation && (
        <UpdateLocation
          cameraId={camera.id}
          locationId={String(camera.location?.id) || ''}
          onClose={() => setUpdateLocation(false)}
        />
      )}
    </>
  );
}
