import { UserListFilters } from '@hakimo-ui/hakimo/types';
import { FilterFooter, FilterSection } from '@hakimo-ui/hakimo/ui-filters';
import { useState } from 'react';
import { initialFilters } from '../../store/state';
import EmailFilter from './email-filter/EmailFilter';
import EnabledFilter from './enabled-filter/EnabledFilter';
import NameFilter from './name-filter/NameFilter';

interface Props {
  filters: UserListFilters;
  onApplyFilters: (filters: UserListFilters) => void;
}

function FilterPanelContent(props: Props) {
  const { filters, onApplyFilters } = props;
  const [localFilters, setLocalFilters] = useState<UserListFilters>(filters);
  return (
    <div className="space-y-6">
      <div className="dark:divide-dark-border-bg dark:border-dark-border-bg divide-y border-t border-b">
        <FilterSection
          title="Name"
          content={
            <NameFilter
              onChange={(names) =>
                setLocalFilters({
                  ...localFilters,
                  names: names.map((item) => item.name),
                })
              }
              value={localFilters.names.map((name) => ({
                name: name,
                id: name,
              }))}
            />
          }
          hasFilterApplied={localFilters.names.length > 0}
        />
        <FilterSection
          title="Email"
          content={
            <EmailFilter
              onChange={(emails) =>
                setLocalFilters({
                  ...localFilters,
                  emails: emails.map((item) => item.name),
                })
              }
              value={localFilters.emails.map((email) => ({
                name: email,
                id: email,
              }))}
            />
          }
          hasFilterApplied={localFilters.emails.length > 0}
        />
        <FilterSection
          title="Enabled"
          content={
            <EnabledFilter
              onChange={(enabledFilter) =>
                setLocalFilters({ ...localFilters, enabled: enabledFilter })
              }
              value={localFilters.enabled}
            />
          }
          hasFilterApplied={
            !localFilters.enabled.showEnabled ||
            localFilters.enabled.showNotEnabled
          }
        />
      </div>
      <FilterFooter
        onApply={() => onApplyFilters(localFilters)}
        onReset={() => setLocalFilters(initialFilters)}
        trackingEventNameApply="apply_users_filters"
        trackingEventNameReset="reset_users_filters"
      />
    </div>
  );
}

export default FilterPanelContent;
