import { AppState, Auth0Provider } from '@auth0/auth0-react';
import { environment } from '@hakimo-ui/hakimo/environments';
import { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';

interface Props {
  children: ReactNode;
}

export function Auth0ProviderWithRedirectCallback(props: Props) {
  const { children } = props;
  const navigate = useNavigate();

  const onRedirectCallback = (appState?: AppState) => {
    navigate((appState && appState.returnTo) || window.location.pathname);
  };
  const queryParams = new URLSearchParams(window.location.search);
  return (
    <Auth0Provider
      domain={environment.auth0.domain}
      clientId={environment.auth0.clientId}
      redirectUri={window.location.origin}
      onRedirectCallback={onRedirectCallback}
      audience={environment.auth0.audience}
      invitation={queryParams.get('invitation') ?? undefined}
      organization={queryParams.get('organization') ?? undefined}
      useRefreshTokens
    >
      {children}
    </Auth0Provider>
  );
}

export default Auth0ProviderWithRedirectCallback;
