import { TenantFilterValue } from '@hakimo-ui/hakimo/types';
import { selectHelpers, useUser } from '@hakimo-ui/hakimo/util';
import MultiSelectFilter from '../multi-select-filter/MultiSelectFilter';

interface Props {
  value: TenantFilterValue;
  label: string;
  enableNegativeFilters?: boolean;
  onChange: (value: TenantFilterValue) => void;
}

export function TenantFilter(props: Props) {
  const { value, label, enableNegativeFilters = false, onChange } = props;
  const user = useUser();
  const options = user.mspTenants ?? [];
  const getTenantsByName = (query: string) => {
    return Promise.resolve(
      options.filter((option) => selectHelpers.filterPredicate(query, option))
    );
  };

  return (
    <MultiSelectFilter
      value={value}
      label={label}
      enableNegativeFilters={enableNegativeFilters}
      onChange={onChange}
      getOptions={getTenantsByName}
    />
  );
}

export default TenantFilter;
