import {
  CheckCircleIcon,
  ExclamationTriangleIcon,
  InformationCircleIcon,
  XCircleIcon,
} from '@heroicons/react/24/solid';
import clsx from 'clsx';
import { ReactNode } from 'react';

interface Props {
  children: ReactNode;
  type?: 'success' | 'error' | 'warning' | 'info';
  className?: string;
}

export function Alert(props: Props) {
  const { children, type = 'info', className } = props;

  let Icon;

  switch (type) {
    case 'success':
      Icon = CheckCircleIcon;
      break;
    case 'error':
      Icon = XCircleIcon;
      break;
    case 'warning':
      Icon = ExclamationTriangleIcon;
      break;
    default:
      Icon = InformationCircleIcon;
  }

  return (
    <div
      className={clsx(
        'rounded-md p-4',
        type === 'success' && 'bg-green-50 dark:bg-green-900',
        type === 'error' && 'bg-red-50 dark:bg-red-900',
        type === 'warning' && 'bg-yellow-50 dark:bg-yellow-900',
        type === 'info' && 'bg-blue-50 dark:bg-blue-900',
        ...(className || '')
      )}
    >
      <div className="flex">
        <div className="flex-shrink-0">
          <Icon
            className={clsx(
              'h-5 w-5',
              type === 'success' && 'text-green-400 dark:text-green-200',
              type === 'error' && 'text-red-400 dark:text-red-100',
              type === 'warning' && 'text-yellow-400 dark:text-yellow-200',
              type === 'info' && 'text-blue-400 dark:text-blue-200'
            )}
            aria-hidden="true"
          />
        </div>
        <div className="ml-3 flex-1 md:flex md:justify-between">
          <p
            className={clsx(
              'text-sm',
              type === 'success' && 'text-green-700 dark:text-green-200',
              type === 'error' && 'text-red-700 dark:text-red-100',
              type === 'warning' && 'text-yellow-700 dark:text-yellow-200',
              type === 'info' && 'text-blue-700 dark:text-blue-200'
            )}
          >
            {children}
          </p>
        </div>
      </div>
    </div>
  );
}

export default Alert;
