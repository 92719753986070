/* eslint-disable max-lines */
import {
  BroadcastIcon,
  FullScreenExitIcon,
  FullScreenIcon,
} from '@hakimo-ui/hakimo/ui-elements';
import clsx from 'clsx';
import { useRef, useState } from 'react';
import { EventHistory } from '../../../types/event';
import { getTenantColor, HOVER_MARGIN_TOP, TIMELINE_HEIGHT } from '../utils';

interface Props {
  eventHistory: EventHistory[];
  playbackUrl?: string;
  isLiveMode: boolean;
  onChangeLiveMode: (val: boolean) => void;
  camName: string;
  tenantName: string;
  onChangeVideoSrc: (src: string) => void;
  isFullScreen: boolean;
  toggleFullscreen: () => void;
  showSeeker: boolean;
}

export function CamControls(props: Props) {
  const {
    eventHistory,
    playbackUrl,
    isLiveMode,
    onChangeLiveMode,
    camName,
    tenantName,
    onChangeVideoSrc,
    isFullScreen,
    toggleFullscreen,
    showSeeker,
  } = props;

  const timelineRef = useRef<HTMLDivElement>(null);
  const [hoverImagePosition, setHoverImagePosition] = useState<number | null>(
    null
  );
  const [hoverTimePosition, setHoverTimePosition] = useState<number | null>(
    null
  );
  const [hoverEvent, setHoverEvent] = useState<EventHistory>();
  const tenMinutesAgo = new Date(new Date().getTime() - 10 * 60 * 1000);

  const getEventPosition = (event: EventHistory) => {
    const timeDiff = event.createdTime - tenMinutesAgo.getTime();
    return (timeDiff / (10 * 60 * 1000)) * 100;
  };

  const relevantEvents = eventHistory.filter(
    (event) => event.createdTime > tenMinutesAgo.getTime()
  );

  const formatTime = (date: Date) => {
    return date.toLocaleTimeString([], {
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    });
  };

  const onHoverEvent =
    (event: EventHistory) =>
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      if (timelineRef.current) {
        const rect = timelineRef.current.getBoundingClientRect();
        const x = e.clientX - rect.left;
        const percentage = (x / rect.width) * 100;
        setHoverImagePosition(percentage);
        setHoverEvent(event);
      }
    };

  const onHoverTimeline = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (timelineRef.current) {
      const rect = timelineRef.current.getBoundingClientRect();
      const x = e.clientX - rect.left;
      const percentage = (x / rect.width) * 100;
      setHoverTimePosition(percentage);
    }
  };

  const getHoverTranslate = (position: number) => {
    if (!timelineRef.current || position === null) return '50%';

    const timelineWidth = timelineRef.current.offsetWidth;
    const imageWidth = timelineWidth / 2; // Assuming image width is half of timeline width
    const hoverX = (position / 100) * timelineWidth;

    if (hoverX < imageWidth / 2) {
      return '0%'; // Align to left edge
    } else if (hoverX > timelineWidth - imageWidth / 2) {
      return '100%'; // Align to right edge
    } else {
      return '50%'; // Center
    }
  };

  const onEventMouseLeave: React.MouseEventHandler<HTMLDivElement> = () =>
    setHoverImagePosition(null);

  const onTimelineMouseLeave: React.MouseEventHandler<HTMLDivElement> = () =>
    setHoverTimePosition(null);

  const getHoverTime = (position: number) => {
    if (position === null) return null;
    const hoverTime = new Date(
      tenMinutesAgo.getTime() + (position / 100) * 10 * 60 * 1000
    );
    return formatTime(hoverTime);
  };

  const onClickTimeline: React.MouseEventHandler<HTMLDivElement> = (e) => {
    if (timelineRef.current) {
      const rect = timelineRef.current.getBoundingClientRect();
      const x = e.clientX - rect.left;
      const percentage = (x / rect.width) * 100;
      const hoverTime = new Date(
        tenMinutesAgo.getTime() + (percentage / 100) * 10 * 60 * 1000
      );
      const hoverTimeISOFormat = hoverTime.toISOString();
      const duration = Math.floor((Date.now() - hoverTime.getTime()) / 1000);

      const params = new URLSearchParams();
      params.append('start', hoverTimeISOFormat);
      params.append('duration', String(duration));

      onChangeVideoSrc(`${playbackUrl}&${params.toString()}`);
      onChangeLiveMode(false);
    }
  };

  const onClickLiveMode = () => {
    onChangeVideoSrc('');
    onChangeLiveMode(true);
  };

  return (
    <div className="from-dark-surface absolute right-0 bottom-0 left-0 cursor-pointer bg-gradient-to-t">
      {hoverImagePosition && hoverEvent && (
        <div
          className="absolute z-10 w-1/2"
          style={{
            left: `${hoverImagePosition}%`,
            bottom: `${TIMELINE_HEIGHT + HOVER_MARGIN_TOP}px`,
            transform: `translateX(-${getHoverTranslate(hoverImagePosition)})`,
          }}
        >
          <span className="bg-ondark-bg-2 absolute top-1 left-1 rounded-md px-2 text-xs text-white">
            {formatTime(new Date(hoverEvent.createdTime))}
          </span>
          <img
            src={hoverEvent.frame}
            alt="event frame"
            className="border-ondark-text-1 rounded-md border"
          />
        </div>
      )}
      {hoverTimePosition && (
        <div
          className="bg-ondark-bg-3/70 absolute cursor-pointer rounded px-2 text-[9px] text-white"
          style={{
            left: `${hoverTimePosition}%`,
            transform: `translateX(-${getHoverTranslate(hoverTimePosition)})`,
            bottom: `${TIMELINE_HEIGHT + HOVER_MARGIN_TOP}px`,
          }}
        >
          {getHoverTime(hoverTimePosition)}
        </div>
      )}

      <div
        ref={timelineRef}
        className={clsx(
          'relative cursor-pointer rounded bg-gray-900/80',
          showSeeker ? 'block' : 'hidden'
        )}
        style={{ height: `${TIMELINE_HEIGHT}px` }}
        onMouseMoveCapture={onHoverTimeline}
        onMouseOverCapture={onHoverTimeline}
        onMouseLeave={onTimelineMouseLeave}
        onClick={onClickTimeline}
      >
        {relevantEvents.map((event, index) => (
          <div
            key={index}
            onMouseOverCapture={onHoverEvent(event)}
            onMouseLeave={onEventMouseLeave}
            className="absolute top-0 h-full w-[0.12rem] bg-red-500 transition-transform"
            style={{ left: `${getEventPosition(event)}%` }}
          ></div>
        ))}

        {hoverTimePosition && (
          <div
            className="bg-primary-500 pointer-events-none absolute z-10 w-[0.12rem]"
            style={{
              left: `${hoverTimePosition}%`,
              height: `${TIMELINE_HEIGHT}px`,
            }}
          ></div>
        )}
      </div>
      <div className="flex items-center justify-between px-1 py-[1px]">
        <button
          disabled={isLiveMode}
          className={clsx(
            'flex items-center gap-1 rounded-sm px-2 text-xs text-white',
            isLiveMode ? 'bg-red-500' : 'bg-gray-500'
          )}
          onClick={onClickLiveMode}
          title={isLiveMode ? '' : 'Switch to live feed'}
        >
          {isLiveMode && (
            <BroadcastIcon className="h-4 w-4 animate-pulse fill-white" />
          )}
          <span>LIVE</span>
        </button>
        <span
          title={tenantName}
          className="truncate text-xs"
          style={{ color: getTenantColor(tenantName || '') }}
        >
          {tenantName}
        </span>
        <span
          title={camName}
          className="truncate text-xs"
          style={{ color: getTenantColor(tenantName || '') }}
        >
          {camName}
        </span>
        <button
          onClick={toggleFullscreen}
          className="rounded-md focus:ring-offset-0 enabled:hover:bg-slate-800/50"
        >
          {isFullScreen ? (
            <FullScreenExitIcon className="fill-dark-text h-5 w-5" />
          ) : (
            <FullScreenIcon className="fill-dark-text h-5 w-5" />
          )}
        </button>
      </div>
    </div>
  );
}

export default CamControls;
