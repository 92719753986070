// See here for this hacky comment:
// https://stackoverflow.com/questions/60528893/tailwind-css-styling-not-being-applied-on-deploy
/**
 * PurgeCSS:
 * text-hakimo-yellow
 * stroke-hakimo-yellow
 * fill-hakimo-yellow
 * text-status-red
 * stroke-status-red
 * text-status-green
 * stroke-status-green
 * text-status-orange
 * stroke-status-orange
 * text-status-emerald
 * stroke-status-emerald
 * text-primary-700
 * stroke-primary-700
 */
export function getMarkerColor(color?: string) {
  switch (color) {
    case 'yellow':
      return 'hakimo-yellow';
    case 'red':
      return 'status-red';
    case 'green':
      return 'status-green';
    case 'orange':
      return 'status-orange';
    case 'emerald':
      return 'status-emerald';
    default:
      return 'primary-700';
  }
}
