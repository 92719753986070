import { AlarmUpdatesDTO } from '@hakimo-ui/hakimo/types';
import useAuthenticatedRequest from '../shared/useAuthenticatedRequest';

export function useAlarmUpdates(
  alarmId: string,
  searchParams: string,
  refetchInterval: number
) {
  const url = `/v2/orm/alarm/update/${alarmId}?${searchParams}`;
  const request = new Request(url);

  return useAuthenticatedRequest<AlarmUpdatesDTO>(request, {
    queryKey: ['alarm_updates', alarmId, { searchParams }],
    responseModifier: async (response) => {
      const respJson = await response.json();
      return respJson.payload;
    },
    refetchInterval,
  });
}

export default useAlarmUpdates;
