import { MotionAlarm } from '@hakimo-ui/hakimo/types';
import {
  ContactsIcon,
  FloorPlanIcon,
  SOPIcon,
} from '@hakimo-ui/hakimo/ui-elements';

export enum QuickActionsId {
  SOP = 'sop',
  FLOORPLAN = 'floorplan',
  LOCATION_CONTACTS = 'locationContacts',
}

export type LocationAlarmCamera = {
  id: string;
  isTalkdownEnabled: boolean;
  name?: string;
  livestreamUrl?: string;
};

export const QuickActions = [
  {
    id: QuickActionsId.SOP,
    title: 'Standard Operating Procedure (SOP)',
    icon: {
      component: SOPIcon,
      className: 'h-10 w-10 sop-quick-action',
    },
  },
  {
    id: QuickActionsId.FLOORPLAN,
    title: 'Floor Plan',
    icon: {
      component: FloorPlanIcon,
      className: 'h-10 w-10 floorplan-quick-action',
    },
  },
  {
    id: QuickActionsId.LOCATION_CONTACTS,
    title: 'Contacts',
    icon: {
      component: ContactsIcon,
      className: 'h-7 w-7 contacts-quick-action',
    },
  },
];

export function getCamerasFromRawAlarms(rawAlarms: MotionAlarm[]) {
  const sortedAlarms = [...rawAlarms].sort(
    (a, b) => new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime()
  );
  const cameraList: LocationAlarmCamera[] = sortedAlarms.map((alarm) => ({
    id: alarm.sourceEntity.id,
    name: alarm.sourceEntity.name,
    livestreamUrl: alarm.sourceEntity.livestreamUrl,
    isTalkdownEnabled: !!(
      alarm.sourceEntity.audio_devices &&
      alarm.sourceEntity.audio_devices.length > 0
    ),
  }));

  return cameraList;
}
