import { toast } from '@hakimo-ui/hakimo/util';
import * as Sentry from '@sentry/react';

const EVENT_PLAY_DELAY_MSEC = 1000;

export function getTimeStr(startTime: string, currentTime: number) {
  const date = new Date(startTime).getTime() + currentTime * 1000;

  return new Intl.DateTimeFormat('en-US', {
    timeStyle: 'medium',
  }).format(date);
}

export function isVideoPlaying(video: HTMLMediaElement) {
  return video.currentTime > 0 && !video.paused && !video.ended;
}

export function toastVideoError() {
  toast('Can not play media. Try again after reloading the page.', {
    type: 'error',
  });
}

export async function onTimestampClick(
  videoElem: HTMLMediaElement,
  timestamp: number
) {
  videoElem.currentTime = Math.max(timestamp, 0);

  if (isVideoPlaying(videoElem)) {
    videoElem.pause();
    await new Promise((r) => setTimeout(r, EVENT_PLAY_DELAY_MSEC));
    // Below promise will be rejected if pause operation was trigger before play
    // could resolve and video could start playing. This may happen if the video
    // playing itself had problems probably due to app being offline or video link
    // being expired, or due to markers being clicked quickly in succession.
    videoElem.play().catch((error) => {
      toastVideoError();

      // Capture as a message for analytics as this scenario does not mean
      // there is an issue with application
      Sentry.captureMessage('Failed to play media on marker click', {
        extra: {
          error,
        },
      });
    });
  }
}

function getExpirationDateFromVideoPath(videoPath: string): number | null {
  const videoUrl = new URL(videoPath);
  const expirationParam = videoUrl.searchParams.get('Expires');

  if (!expirationParam) return null;

  return Number(expirationParam) * 1000;
}

export function getRemainingMSecBeforeExpiration(
  videoPath: string
): number | false {
  const expirationDate = getExpirationDateFromVideoPath(videoPath);

  if (!expirationDate) return false;

  return expirationDate - Date.now();
}
