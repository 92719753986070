import { useAudioDeviceDeleteCamMapping } from '@hakimo-ui/hakimo/data-access';
import { AudioDevice } from '@hakimo-ui/hakimo/types';
import { toast, useCanModifyAudioDeviceCamMap } from '@hakimo-ui/hakimo/util';
import { Label } from '@hakimo-ui/shared/ui-base';
export interface AudioExpandedRowProps {
  audioDevice: AudioDevice;
}

export function AudioExpandedRow(props: AudioExpandedRowProps) {
  const { audioDevice } = props;
  const deleteMappingMutation = useAudioDeviceDeleteCamMapping(() =>
    toast('Audio Device Mapping Removed', { type: 'success' })
  );
  const canModifyMapping = useCanModifyAudioDeviceCamMap();
  return (
    <div className="p-4 text-xs">
      {audioDevice.cameras.map((cam) => (
        <div key={cam.id} className="m-2 inline-flex gap-4 rounded-xl px-2">
          <Label
            text={cam.name}
            removable={canModifyMapping}
            onClickRemove={() =>
              canModifyMapping &&
              deleteMappingMutation.mutate({
                audioDeviceId: audioDevice.id,
                cameraId: cam.id,
              })
            }
          />
        </div>
      ))}
      {audioDevice.cameras.length === 0 && (
        <div className="text-center text-gray-600 dark:text-gray-400">
          No cameras attached to this audio device
        </div>
      )}
    </div>
  );
}

export default AudioExpandedRow;
