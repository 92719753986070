import Hls from 'hls.js';
import { useEffect } from 'react';

export function useUpdatePlayStatus(
  updatePlayStatus: (isPlaying: boolean) => void,
  videoRef?: React.RefObject<HTMLVideoElement>
) {
  useEffect(() => {
    const video = videoRef?.current;
    const waithandler = () => updatePlayStatus(false);
    const canPlayhandler = () => updatePlayStatus(true);

    if (video) {
      video.addEventListener('waiting', waithandler);
      video.addEventListener('canplay', canPlayhandler);
    }

    return () => {
      if (video) {
        video.removeEventListener('waiting', waithandler);
        video.removeEventListener('canplay', canPlayhandler);
      }
    };
  }, [videoRef, updatePlayStatus]);
}

export function useVideoTimeController(
  triggerTime: number,
  hls?: Hls,
  videoRef?: React.RefObject<HTMLVideoElement>
) {
  useEffect(() => {
    const videoElem = videoRef?.current;
    if (hls && videoElem) {
      const hlsPlayingDate = hls.playingDate;
      // video is already loaded and trigger time has changed
      if (hlsPlayingDate) {
        const timeDiffSeconds = (triggerTime - hlsPlayingDate.getTime()) / 1000;
        if (Math.abs(timeDiffSeconds) > 10) {
          videoElem.currentTime += timeDiffSeconds;
        }
      } else {
        // hls is loading first time
        const onManifestParsed = () => {
          const firstSegmentTime = hls.levels[0].details?.startSN;
          if (firstSegmentTime) {
            const timeDiffSeconds =
              (triggerTime - firstSegmentTime * 1000) / 1000;
            hls.config.startPosition = timeDiffSeconds;
          }
          hls.off(Hls.Events.MANIFEST_PARSED, onManifestParsed);
        };
        hls.on(Hls.Events.MANIFEST_PARSED, onManifestParsed);
      }
    }
  }, [triggerTime, hls, videoRef]);
}
