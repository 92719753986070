import {
  AudioRecorder,
  LiveViewSwitch,
} from '@hakimo-ui/hakimo/feature-shared';
import { Location, MotionAlarm } from '@hakimo-ui/hakimo/types';
import { SelectMenu } from '@hakimo-ui/hakimo/ui-elements';
import { useMemo, useState } from 'react';
import { CameraSelectable } from '../types';
import CamSurvelliance from './CamSurvelliance';

export interface Props {
  alarms: MotionAlarm[];
  locationAlarmId: string;
  selectedCameraId: string;
  onChangeSelectedCameraId: (id: string) => void;
  onTalkdown: (cameraId: string, alarmId?: string) => void;
  location: Location;
}

export function LiveView(props: Props) {
  const {
    alarms,
    locationAlarmId,
    selectedCameraId,
    onChangeSelectedCameraId,
    onTalkdown,
    location,
  } = props;

  const [recording, setRecording] = useState(false);

  const cameras = useMemo(
    () =>
      alarms.reduce<CameraSelectable[]>((acc, alarm) => {
        const camera = acc.find((c) => c.id === alarm.sourceEntity.id);
        if (!camera) {
          acc.push({
            id: alarm.sourceEntity.id,
            name: alarm.sourceEntity.name ?? alarm.sourceEntity.id,
            isAudioSupported:
              (alarm.sourceEntity.audio_devices ?? []).length > 0,
            livestreamUrl: alarm.sourceEntity.livestreamUrl,
          });
        }
        return acc;
      }, []),
    [alarms]
  );

  const selectedCamera = cameras.find((c) => c.id === selectedCameraId);
  const audioEnabledForSelectedCamera = selectedCamera?.isAudioSupported;

  return (
    <div className="flex h-full flex-col space-y-3">
      <div className="relative flex h-9 items-center justify-between">
        {!recording && (
          <div className="flex items-center gap-2">
            <h2 className="flex items-center gap-2">
              <span className="bg-status-red h-1.5 w-1.5 animate-pulse rounded-full"></span>
              <span>Live</span>
            </h2>
            <SelectMenu
              items={cameras}
              value={selectedCamera}
              displayValue={(c) => c?.name || ''}
              onChange={(val) => onChangeSelectedCameraId(val.id)}
            />
          </div>
        )}
        <div className="flex gap-4">
          <CamSurvelliance
            location={location}
            initalCameraIds={cameras.map((c) => c.id)}
          />

          {audioEnabledForSelectedCamera && (
            <div className="">
              <AudioRecorder
                locAlarmId={locationAlarmId}
                cameraId={selectedCameraId}
                onTalkdown={onTalkdown}
                onToggle={setRecording}
                cameraName={selectedCamera?.name || ''}
              />
            </div>
          )}
        </div>
      </div>
      {selectedCamera && <LiveViewSwitch camera={selectedCamera} />}
    </div>
  );
}

export default LiveView;
