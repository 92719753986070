import { Selectable } from '@hakimo-ui/shared/ui-base';
import useAuthenticatedRequest from '../shared/useAuthenticatedRequest';

export function useSourceSystems() {
  const url = `/v2/orm/tenant/sourceSystems`;
  const request = new Request(url);

  return useAuthenticatedRequest<Selectable[]>(request, {
    queryKey: ['sourceSystems'],
    responseModifier: async (response) => {
      const respJson = await response.json();
      return respJson.payload.map((item: string) => ({ id: item, name: item }));
    },
  });
}

export default useSourceSystems;
