import { Button } from '@hakimo-ui/shared/ui-base';
import { useState } from 'react';
import { TextToSpeech } from '../text-to-speech/TextToSpeech';

interface Props {
  text: string;
  performTalkdown: (audio: File) => void;
  isLoading?: boolean;
}

export function AutoTextToSpeech(props: Props) {
  const { text, performTalkdown, isLoading } = props;

  const [audioFile, setAudioFile] = useState<File>();

  const onUpdateAudioFile = (file: File) => {
    setAudioFile(file);
  };

  const onSubmit = () => {
    audioFile && performTalkdown(audioFile);
  };

  return (
    <div className="flex flex-col items-center justify-center gap-4">
      <TextToSpeech
        text={text}
        updateAudioFile={onUpdateAudioFile}
        prefetch
        showFetchAction={false}
        alignment="vertical"
      />
      <Button variant="primary" onClick={onSubmit} loading={isLoading}>
        Send audio
      </Button>
    </div>
  );
}

export default AutoTextToSpeech;
