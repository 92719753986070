import { track, Types } from '@amplitude/analytics-browser';
import { TrackingEvent } from '@hakimo-ui/hakimo/types';

export function eventTracker<T>(eventName: TrackingEvent) {
  return (
    eventProperties?: Record<string, T> | undefined,
    eventOptions?: Types.EventOptions | undefined
  ) => {
    trackEvent(eventName, eventProperties, eventOptions);
  };
}

export function trackEvent<T>(
  eventName: TrackingEvent,
  eventProperties?: Record<string, T> | undefined,
  eventOptions?: Types.EventOptions | undefined
) {
  track(eventName, eventProperties, eventOptions);
}
