import {
  DoorGroupFilters,
  DoorGroupNameFilterValue,
} from '@hakimo-ui/hakimo/types';
import { SlideOver } from '@hakimo-ui/hakimo/ui-elements';
import { useState } from 'react';
import { DEFAULT_DOOR_GROUP_FILTER_VALUE } from './constants';
import FilterPanelContent from './FilterPanelContent';

interface Props {
  open: boolean;
  filters: DoorGroupFilters;
  onApplyFilters: (value: DoorGroupFilters) => void;
  onClose: () => void;
}

function FilterPanel(props: Props) {
  const { open, onClose, filters, onApplyFilters } = props;

  const [doorGroupFilterValue, setDoorGroupFilterValue] =
    useState<DoorGroupNameFilterValue>(filters.name);

  const onApplyFiltersCb = () => {
    onApplyFilters({
      name: doorGroupFilterValue,
    });

    onClose();
  };

  const onResetFilters = () => {
    setDoorGroupFilterValue(DEFAULT_DOOR_GROUP_FILTER_VALUE);
  };

  return (
    <SlideOver title="Door Groups Filters" open={open} onClose={onClose}>
      <FilterPanelContent
        doorGroupFilterValue={doorGroupFilterValue}
        onChangeDoorGroupFilterValue={setDoorGroupFilterValue}
        onApplyFilters={onApplyFiltersCb}
        onResetFilters={onResetFilters}
      />
    </SlideOver>
  );
}

export default FilterPanel;
