import { useGetPending, usePendingCount } from '@hakimo-ui/hakimo/data-access';
import { PageLoadingIndicator } from '@hakimo-ui/hakimo/ui-elements';
import { Page } from '@hakimo-ui/hakimo/ui-layout';
import {
  audioNotifyAtom,
  dismissToast,
  systemNotifyAtom,
  toast,
  trackEvent,
  triggerSystemNotification,
  withAuthz,
} from '@hakimo-ui/hakimo/util';
import { Alert } from '@hakimo-ui/shared/ui-base';
import { useAtomValue } from 'jotai';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { NEW_ALARM_TOAST_ID } from '../shared/constants';
import { getNewAlarmNotificationText } from '../shared/util/common';
import { NothingPending } from './NothingPending';
import { PendingAlarm } from './PendingAlarm';
import { PENDING_COUNT_REFRESH_INTERVAL } from './constants';

export function MonitoringScreen() {
  const navigate = useNavigate();
  const [count, setCount] = useState<number>(0);
  const pendingCountQuery = usePendingCount(PENDING_COUNT_REFRESH_INTERVAL);
  const systemNotifyPreference = useAtomValue(systemNotifyAtom);
  const audioNotify = useAtomValue(audioNotifyAtom);

  const getPendingQuery = useGetPending((locationAlarmId) => {
    dismissToast(NEW_ALARM_TOAST_ID);
    trackEvent('on_new_monitoring_alarm_load', { locationAlarmId });
    if (locationAlarmId === undefined) {
      toast('Alarm already picked up by operator');
      return;
    }
    navigate(`/monitoring/${locationAlarmId}`);
  });

  useEffect(() => {
    if (pendingCountQuery.data !== undefined) {
      setCount(pendingCountQuery.data);
    }
  }, [pendingCountQuery.data]);

  useEffect(() => {
    if (count !== undefined && count > 0) {
      triggerSystemNotification(
        systemNotifyPreference,
        getNewAlarmNotificationText('locationAlarm')
      );
      toast('New pending alarm', {
        audio: audioNotify,
        duration: Infinity,
        id: NEW_ALARM_TOAST_ID,
      });
    } else if (count === 0) {
      dismissToast(NEW_ALARM_TOAST_ID);
    }
  }, [count, audioNotify, systemNotifyPreference]);

  return (
    <Page title="Monitoring">
      {getPendingQuery.isError && (
        <Alert type="error">{getPendingQuery.error.message}</Alert>
      )}
      {pendingCountQuery.isLoading ? (
        <PageLoadingIndicator text="Initializing monitoring..." />
      ) : pendingCountQuery.error ? (
        <Alert type="error">Connection is not live</Alert>
      ) : (
        count !== undefined &&
        (count === 0 ? (
          <NothingPending />
        ) : (
          <PendingAlarm
            count={count}
            onGetPending={() => {
              getPendingQuery.refetch();
            }}
            isLoading={
              getPendingQuery.isRefetching || getPendingQuery.isLoading
            }
          />
        ))
      )}
    </Page>
  );
}

export default withAuthz(MonitoringScreen, [
  'location_alarms/monitoring:count',
]);
