import { TimeFilterValue, TimePeriod } from '@hakimo-ui/hakimo/types';
import { useMemo } from 'react';

export function useWithTimeFilterValue<T>(currentValue: T, period: TimePeriod) {
  const alarmListFilters: T & {
    time: TimeFilterValue;
  } = useMemo(
    () => ({
      time: {
        period,
        customRangeStart: '',
        customRangeEnd: '',
      },
      ...currentValue,
    }),
    [currentValue, period]
  );

  return alarmListFilters;
}
