import { useRemoveDoorFromDoorGroup } from '@hakimo-ui/hakimo/data-access';
import { Door, RemoveDoorFromDoorGroupDTO } from '@hakimo-ui/hakimo/types';
import { eventTracker, toast } from '@hakimo-ui/hakimo/util';
import { Button, Modal } from '@hakimo-ui/shared/ui-base';
import { useState } from 'react';

export type RemoveDoorFromDoorGroupModalProps = {
  open?: boolean;
  selectedDoors?: string[];
  onClose: () => void;
  allDoors?: Door[];
};

function RemoveDoorFromDoorGroupModal(
  props: RemoveDoorFromDoorGroupModalProps
) {
  const { open = false, onClose, allDoors = [], selectedDoors = [] } = props;

  const [doorMapping] = useState(() => {
    return selectedDoors
      .map((doorId) => {
        const selectedDoor = allDoors.find((d) => d.id === doorId);
        return {
          doorName: selectedDoor?.name,
          doorId: selectedDoor?.id,
          doorGroupId: selectedDoor?.doorGroup?.id,
          doorGroupName: selectedDoor?.doorGroup?.name,
        };
      })
      .filter((door) => !!door.doorGroupId);
  });

  function onRemoveDoorFromDoorGroup() {
    toast('Removed Doors from Door Group Successfully', {
      type: 'success',
    });
    onClose();
  }

  const removeDoorMutation = useRemoveDoorFromDoorGroup(
    onRemoveDoorFromDoorGroup
  );

  const onCloseCb = () => {
    onClose();
    removeDoorMutation.reset();
  };

  const onSubmit = () => {
    const payload: RemoveDoorFromDoorGroupDTO = { doors: [] };
    doorMapping.reduce((acc, doorMap) => {
      const existingGroup = acc.find(
        (group) => group.door_group_id === String(doorMap.doorGroupId)
      );
      if (existingGroup) {
        existingGroup.doors.push(String(doorMap.doorId));
      } else {
        acc.push({
          door_group_id: String(doorMap.doorGroupId),
          doors: [String(doorMap.doorId)],
        });
      }
      return acc;
    }, payload.doors);
    removeDoorMutation.mutate(payload);
  };

  const actions = (
    <>
      <Button
        variant="error"
        onClick={onSubmit}
        onSideEffect={eventTracker('submit_remove_door_from_door_group')}
        disabled={removeDoorMutation.isLoading || doorMapping.length === 0}
        loading={removeDoorMutation.isLoading}
      >
        Remove door/s from door group
      </Button>
      <Button
        onClick={onCloseCb}
        onSideEffect={eventTracker('cancel_remove_door_from_door_group')}
      >
        Cancel
      </Button>
    </>
  );
  return (
    <Modal
      title="Remove Door/s from Door Groups"
      open={open}
      onClose={onCloseCb}
      footer={actions}
    >
      <div className="w-[36rem] p-8">
        <div className="space-y-4">
          <p>
            Are you sure you want to remove below door/s from respective door
            group?
          </p>
          <div className="justify-center">
            <div className="grid grid-cols-2 py-3 text-left font-semibold text-gray-700 dark:text-gray-400">
              <span>Door Name</span>
              <span>Door Group</span>
            </div>
            {doorMapping.map((doorMap) => (
              <div
                key={`${doorMap.doorGroupId}-${doorMap.doorId}`}
                className="grid grid-cols-2 text-sm"
              >
                <span>{doorMap.doorName}</span>
                <span>{doorMap.doorGroupName}</span>
              </div>
            ))}
            {doorMapping.length === 0 && (
              <div className="text-center text-xs text-gray-600 dark:text-gray-400">
                All selected doors are not associated with door group
              </div>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default RemoveDoorFromDoorGroupModal;
