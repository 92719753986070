import { DragIcon } from '@hakimo-ui/hakimo/ui-elements';
import React, { useCallback, useEffect, useRef, useState } from 'react';

interface Position {
  top: number;
  left: number;
}

interface DraggableProps {
  children?: React.ReactNode;
}

export function DraggableHOC(props: DraggableProps) {
  const { children } = props;
  const parentRef = useRef<HTMLDivElement>(null);
  const parentWidth = parentRef.current ? parentRef.current.clientWidth : 200;
  const [position, setPosition] = useState<Position>({
    top: 0,
    left: window.innerWidth - parentWidth,
  });
  const [dragging, setDragging] = useState<boolean>(false);
  const posRef = useRef<{ x: number; y: number }>({ x: 0, y: 0 });

  const handleMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
    setDragging(true);
    posRef.current = {
      x: e.clientX - position.left,
      y: e.clientY - position.top,
    };
  };

  const handleMouseMove = useCallback(
    (e: MouseEvent) => {
      if (!dragging) return;

      // Calculate new position
      let newLeft = e.clientX - posRef.current.x;
      let newTop = e.clientY - posRef.current.y;

      // Constrain within the viewport
      const maxLeft = window.innerWidth - parentWidth;
      const maxTop = window.innerHeight - parentWidth;

      if (newLeft < 0) newLeft = 0;
      if (newTop < 0) newTop = 0;
      if (newLeft > maxLeft) newLeft = maxLeft;
      if (newTop > maxTop) newTop = maxTop;

      setPosition({
        left: newLeft,
        top: newTop,
      });
    },
    [dragging, parentWidth]
  );

  const handleMouseUp = () => {
    setDragging(false);
  };

  useEffect(() => {
    if (dragging) {
      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('mouseup', handleMouseUp);
    } else {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    }

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [dragging, handleMouseMove]);

  return (
    <div
      className="overflow-hidden rounded-md shadow-2xl shadow-gray-400 dark:shadow-gray-700"
      ref={parentRef}
      style={{
        position: 'fixed',
        top: `${position.top}px`,
        left: `${position.left}px`,
        zIndex: 1000,
      }}
    >
      <div
        className="bg-onlight-bg-3 dark:bg-ondark-bg-3 flex h-8 items-center justify-start"
        style={{ cursor: dragging ? 'grabbing' : 'grab' }}
        onMouseDown={handleMouseDown}
      >
        <DragIcon className="fill-onlight-text-2 dark:fill-ondark-text-2 ml-2 h-5 w-5" />
      </div>
      {children}
    </div>
  );
}

export default DraggableHOC;
