import Hls from 'hls.js';
import { useEffect } from 'react';
import { INITIAL_LOAD_LAG, NEXT_INDEX_LOAD_BUFFER } from './constants';
import { CameraHlsAndRef, PlaybackInfo } from './utils';

export function useSeekerTime(
  playbackInfoRef: React.RefObject<PlaybackInfo>,
  setSeekerTime: (timestamo: Date) => void,
  hls?: Hls
) {
  useEffect(() => {
    const intervalId = window.setInterval(() => {
      if (
        hls &&
        hls.playingDate &&
        playbackInfoRef.current &&
        playbackInfoRef.current.playbackStatus !== 'seeking'
      ) {
        setSeekerTime(hls.playingDate);
      }
    }, 1000);

    return () => {
      intervalId && clearInterval(intervalId);
    };
  }, [hls, playbackInfoRef, setSeekerTime]);
}

export function useVideoEnd(
  cameraHlsAndRef: CameraHlsAndRef,
  playbackInfoRef: React.RefObject<PlaybackInfo>,
  endDate: Date,
  requestNewVideoUrl: (marker: Date) => void
) {
  useEffect(() => {
    /* load next day index when current day video ends*/
    const videoElem = cameraHlsAndRef.videoRef.current;
    const onVideoEnd = () => {
      if (
        cameraHlsAndRef.hls &&
        cameraHlsAndRef.hls.playingDate &&
        cameraHlsAndRef.hls.playingDate.getTime() < endDate.getTime() - 4000 // 4000 is buffer for last segment for today's video
      ) {
        const nextDayTime = endDate.getTime() + NEXT_INDEX_LOAD_BUFFER;
        requestNewVideoUrl(new Date(nextDayTime));
        playbackInfoRef.current &&
          Object.assign(playbackInfoRef.current, {
            marker: null,
            playbackStatus: 'completed',
          });
      }
    };
    videoElem?.addEventListener('ended', onVideoEnd);
    return () => videoElem?.removeEventListener('ended', onVideoEnd);
  }, [
    endDate,
    cameraHlsAndRef.hls,
    playbackInfoRef,
    requestNewVideoUrl,
    cameraHlsAndRef.videoRef,
  ]);
}

export function useLoadAndUpdateHLS(
  playbackInfoRef: React.RefObject<PlaybackInfo>,
  hls?: Hls
) {
  useEffect(() => {
    const playbackInfo = playbackInfoRef.current;
    if (hls && playbackInfo) {
      let onManifestParsed: undefined | (() => void) = undefined;
      if (playbackInfo.playbackStatus === null) {
        /* For initial load starting at end - 15mins */
        onManifestParsed = () => {
          const totalDuration = hls.levels[0].details?.totalduration;
          if (totalDuration) {
            hls.config.startPosition = totalDuration - INITIAL_LOAD_LAG;
          }
          hls.off(Hls.Events.MANIFEST_PARSED, onManifestParsed);
        };
      } else if (playbackInfo.playbackStatus === 'seeking') {
        /* once marker goes to any other date, hls object is destroyed and created again for new index.m3u8 file. */
        onManifestParsed = () => {
          const firstSegmentTime = hls.levels[0].details?.startSN;
          if (playbackInfo.marker && firstSegmentTime) {
            const timeDiffSeconds =
              (playbackInfo.marker.getTime() - firstSegmentTime * 1000) / 1000;
            hls.config.startPosition = timeDiffSeconds;
          }
          hls.off(Hls.Events.MANIFEST_PARSED, onManifestParsed);
          playbackInfoRef.current &&
            Object.assign(playbackInfoRef.current, {
              marker: null,
              playbackStatus: null,
            });
        };
      }
      onManifestParsed && hls.on(Hls.Events.MANIFEST_PARSED, onManifestParsed);
    }
  }, [hls, playbackInfoRef]);
}
