import { ReactNode } from 'react';

export interface Template {
  text: string;
  prefetch?: boolean;
  inputAfterWordCount?: number;
  node?: ReactNode;
  placeholder?: string;
  index?: number;
}

export const Templates: Template[] = [
  {
    text: 'The premises is currently closed. This area is under video surveillance and you are being recorded. Please leave the area.',
    prefetch: true,
  },
  {
    text: 'Person in , You are being recorded. Please leave the area or police will be dispatched.',
    prefetch: false,
    inputAfterWordCount: 2,
    placeholder: 'eg: Red shirt',
  },
  {
    text: '',
    prefetch: false,
    inputAfterWordCount: 0,
    placeholder: 'Write your custom message here..',
  },
];

export function getFinalText(
  text: string,
  inputAfterWordCount?: number,
  inputValue?: string
) {
  if (inputAfterWordCount !== undefined && inputValue) {
    const splitTextArr = text.split(' ');
    splitTextArr.splice(inputAfterWordCount, 0, inputValue);
    return splitTextArr.join(' ');
  } else {
    return text;
  }
}
