import { useDeleteLocationContact } from '@hakimo-ui/hakimo/data-access';
import { LocationContact } from '@hakimo-ui/hakimo/types';
import { toast } from '@hakimo-ui/hakimo/util';
import { Button, Modal } from '@hakimo-ui/shared/ui-base';

interface Props {
  onClose: () => void;
  contact: LocationContact;
}

export function DeleteContactModal(props: Props) {
  const { onClose, contact } = props;

  const onSuccess = () => {
    toast('Location contacts deleted successfully', { type: 'success' });
    onClose();
  };

  const mutation = useDeleteLocationContact(
    contact.tenantId,
    contact.locationId,
    String(contact.id),
    onSuccess
  );

  const onSubmit = () => {
    mutation.mutate({});
  };

  const actions = (
    <>
      <Button
        variant="error"
        onClick={onSubmit}
        loading={mutation.isLoading}
        disabled={mutation.isLoading}
      >
        Delete
      </Button>
      <Button onClick={onClose} disabled={mutation.isLoading}>
        Cancel
      </Button>
    </>
  );
  return (
    <Modal title="Delete Contact" open onClose={onClose} footer={actions}>
      <div className="space-y-4 p-8">
        Are you sure you want to delete the below contact:
        <div className="mt-4">Name: {contact.name}</div>
        <div>Phone Number: {contact.phoneNumber}</div>
      </div>
    </Modal>
  );
}

export default DeleteContactModal;
