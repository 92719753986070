import { User } from '@hakimo-ui/hakimo/types';
import { SelectMenu } from '@hakimo-ui/hakimo/ui-elements';
import { currentTenantAtom } from '@hakimo-ui/hakimo/util';
import { useAtom } from 'jotai';
import { useMemo } from 'react';
import { getTenantFilterItems } from './util';

interface Props {
  user: User;
}

function TenantFilter(props: Props) {
  const { user } = props;
  const [currentTenant, setCurrentTenant] = useAtom(currentTenantAtom);

  const tenantFilterItems = useMemo(() => {
    return getTenantFilterItems(user);
  }, [user]);

  return (
    <SelectMenu
      items={tenantFilterItems}
      value={currentTenant}
      onChange={setCurrentTenant}
      displayValue={(item) => item?.name ?? ''}
    />
  );
}

export default TenantFilter;
