import { CheckCircleIcon } from '@heroicons/react/24/solid';

export function NothingPending() {
  return (
    <div className="my-auto flex-col">
      <div className="text-status-green mx-auto flex flex-auto justify-center">
        <CheckCircleIcon className="max-h-[24rem]" />
      </div>
      <div className="text-onlight-text-2 dark:text-ondark-text-2 mx-auto flex justify-center text-6xl">
        No pending alarms!
      </div>
    </div>
  );
}
