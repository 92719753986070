/* eslint-disable max-lines */
import { useAddCamera } from '@hakimo-ui/hakimo/data-access';
import { CameraAddPayload } from '@hakimo-ui/hakimo/types';
import { toast, useUser } from '@hakimo-ui/hakimo/util';
import { Alert, Button, Modal } from '@hakimo-ui/shared/ui-base';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { AddLocationField } from './AddLocationField';
import { TextField } from './TextField';
import { ToggleField } from './ToggleField';

interface Props {
  onClose: () => void;
}

export function AddCamera(props: Props) {
  const { tenantId } = useParams<{ tenantId: string }>();
  const { onClose } = props;

  const user = useUser();

  const defaultTenantId = user.tenant.id;

  const [value, setValue] = useState<CameraAddPayload>({
    enableMotionDetection: true,
    enablePlayback: false,
    name: '',
    passwordFilePath: '/secure/rtsp_password',
    userId: '',
    isEnabled: true,
    rtspUrl: '',
    livestreamUrl: '',
    tenantId: tenantId ?? defaultTenantId,
    locationId: '',
  });

  const addCameraMutation = useAddCamera(() => {
    toast(`${value.name} added!`, {
      type: 'success',
    });
    onClose();
  });

  const onSubmit = () => {
    addCameraMutation.mutate(value);
  };

  const hasAllMandatoryValues =
    value.name && value.userId && value.passwordFilePath && value.rtspUrl;

  const actions = (
    <>
      <span className="mr-2">
        <Button
          variant="primary"
          onClick={onSubmit}
          disabled={!hasAllMandatoryValues || addCameraMutation.isLoading}
          loading={addCameraMutation.isLoading}
        >
          Submit
        </Button>
      </span>
      <Button onClick={onClose}>Cancel</Button>
    </>
  );

  return (
    <Modal
      title="Add New Camera"
      footer={actions}
      open={true}
      onClose={onClose}
    >
      <div className="dark:divide-ondark-line-1 divide-onlight-line-1 w-[36rem] divide-y px-8 py-4 text-sm">
        {addCameraMutation.isError && (
          <Alert type="error">{addCameraMutation.error.message}</Alert>
        )}
        <TextField
          label="Name*"
          value={value.name}
          onChange={(val) =>
            setValue({
              ...value,
              name: val,
            })
          }
        />
        <TextField
          label="User ID*"
          value={value.userId}
          onChange={(val) =>
            setValue({
              ...value,
              userId: val,
            })
          }
        />
        <TextField
          label="Password File Path*"
          value={value.passwordFilePath}
          onChange={(val) =>
            setValue({
              ...value,
              passwordFilePath: val,
            })
          }
        />
        <TextField
          label="RTSP URL*"
          value={value.rtspUrl}
          onChange={(val) =>
            setValue({
              ...value,
              rtspUrl: val,
            })
          }
        />
        <TextField
          label="Livestream URL"
          value={value.livestreamUrl}
          onChange={(val) =>
            setValue({
              ...value,
              livestreamUrl: val,
            })
          }
        />
        <AddLocationField
          value={value.locationId}
          onChange={(id) =>
            setValue({
              ...value,
              locationId: id,
            })
          }
        />
        <ToggleField
          label="Motion Detection"
          value={value.enableMotionDetection}
          onChange={(val) =>
            setValue({
              ...value,
              enableMotionDetection: val,
            })
          }
        />
        <ToggleField
          label="Playback"
          value={value.enablePlayback}
          onChange={(val) =>
            setValue({
              ...value,
              enablePlayback: val,
            })
          }
        />
      </div>
    </Modal>
  );
}
