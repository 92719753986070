import { useUpdateCamera } from '@hakimo-ui/hakimo/data-access';
import { toast } from '@hakimo-ui/hakimo/util';
import { useCallback, useState } from 'react';

export function useUpdatePlayback(
  cameraId: string,
  playbackEnabled: boolean
): [boolean, () => void] {
  const [value, setValue] = useState(playbackEnabled);

  const updateCameraMutation = useUpdateCamera(
    cameraId,
    () => {
      toast(`Playback ${value ? 'enabled' : 'disabled'}!`, {
        type: 'success',
      });
    },
    () => {
      toast(`Update failed!`, {
        type: 'error',
      });
      setValue(!value);
    }
  );

  const onToggle = useCallback(() => {
    setValue(!value);

    setTimeout(() => {
      updateCameraMutation.mutate({
        enablePlayback: !value,
      });
    });
  }, [updateCameraMutation, value]);

  return [value, onToggle];
}
