import { useHttpClient } from '@hakimo-ui/hakimo/util';

/**
 * @param tap run any side-effects
 * @returns
 */
export function useTimezone(tap?: (response: Response) => void) {
  const httpClient = useHttpClient();

  return (name: string) => {
    const url = `/v2/orm/timezones?query=${name}`;

    return httpClient<string[]>(url, {
      responseModifier: async (response) => {
        const res = await response.json();
        const payload = res.payload as string[];
        return payload;
      },
      tap,
    });
  };
}

export default useTimezone;
