import { StatusType, displayStatusTypes } from '@hakimo-ui/hakimo/types';
import { Selectable } from '@hakimo-ui/shared/ui-base';

export const highlightTimePeriod = 5000;

export const displayStatusSelectables: Selectable<StatusType>[] =
  displayStatusTypes.map((name, index) => ({
    id: `${index}`,
    name,
  }));

export const tapColors = [
  '#118D3F',
  '#139310',
  '#439D0B',
  '#82A608',
  '#AB9507',
  '#B16A07',
  '#B16A07',
  '#B16A07',
  '#AA2B1C',
  '#AA2B1C',
];

export const NEW_ALARM_TOAST_ID = 'audio-notify-new-alarm';

export const LOCATION_ALARM_QUERY_INTERVAL = 10000;
