import {
  DoorNameFilterValue,
  LabelStatusFilterValue,
  LocationFilterValue,
} from '@hakimo-ui/hakimo/types';
import {
  DoorFilter,
  FilterFooter,
  FilterSection,
} from '@hakimo-ui/hakimo/ui-filters';
import DoorLocationFilter from './DoorLocationFilter';
import LabelStatusFilter from './LabelStatusFilter';

interface Props {
  labelStatusFilterValue: LabelStatusFilterValue;
  locationFilterValue: LocationFilterValue;
  doorFilterValue: DoorNameFilterValue;
  onChangeLabelStatusFilterValue: (value: LabelStatusFilterValue) => void;
  onChangeLocationFilterValue: (value: LocationFilterValue) => void;
  onChangeDoorFilterValue: (value: DoorNameFilterValue) => void;
  onApplyFilters: () => void;
  onResetFilters: () => void;
}

function FilterPanelContent(props: Props) {
  const {
    labelStatusFilterValue,
    locationFilterValue,
    doorFilterValue,
    onChangeLabelStatusFilterValue,
    onChangeLocationFilterValue,
    onChangeDoorFilterValue,
    onApplyFilters,
    onResetFilters,
  } = props;

  return (
    <div className="space-y-6">
      <div className="dark:divide-dark-border-bg dark:border-dark-border-bg divide-y border-t border-b">
        <FilterSection
          title="Label Status"
          content={
            <LabelStatusFilter
              value={labelStatusFilterValue}
              onChangeCb={onChangeLabelStatusFilterValue}
            />
          }
          hasFilterApplied={
            !labelStatusFilterValue.showLabeled ||
            !labelStatusFilterValue.showUnlabeled
          }
        />
        <FilterSection
          title="Location"
          content={
            <DoorLocationFilter
              locationFilterValue={locationFilterValue}
              onChangeLocationFilterValue={onChangeLocationFilterValue}
            />
          }
          hasFilterApplied={locationFilterValue.values.length > 0}
        />
        <FilterSection
          title="Door"
          content={
            <DoorFilter
              label="Door Name"
              value={doorFilterValue}
              onChange={onChangeDoorFilterValue}
            />
          }
          hasFilterApplied={doorFilterValue.values.length > 0}
        />
      </div>
      <FilterFooter
        onApply={onApplyFilters}
        onReset={onResetFilters}
        trackingEventNameApply="apply_doors_filters"
        trackingEventNameReset="reset_doors_filters"
      />
    </div>
  );
}

export default FilterPanelContent;
