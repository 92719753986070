import { UpsertLocationRequestDTO } from '@hakimo-ui/hakimo/types';
import useAuthenticatedMutation from '../shared/useAuthenticatedMutation';

export function useUpdateLocation(onSuccess: () => void, id: number) {
  const url = `/v2/orm/locations/${id}`;

  const request = new Request(url, {
    method: 'PATCH',
  });

  return useAuthenticatedMutation<null, UpsertLocationRequestDTO>(request, {
    onSuccessInvalidationKeys: [['locationId', id.toString()]],
    onSuccess,
  });
}
export default useUpdateLocation;
