import { AddUserPayload } from '@hakimo-ui/hakimo/types';
import useAuthenticatedMutation from '../shared/useAuthenticatedMutation';

export function useAddUser(onSuccess: () => void) {
  const url = `/v2/orm/user`;

  const request = new Request(url, {
    method: 'POST',
  });

  return useAuthenticatedMutation<null, AddUserPayload | null>(request, {
    onSuccessInvalidationKeys: [['users']],
    onSuccess,
  });
}
