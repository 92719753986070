import { FilterValues } from '@hakimo-ui/hakimo/types';
import { trackEvent } from './client';

export function trackFilters(filtersApplied: Partial<FilterValues>) {
  if (Object.keys(filtersApplied).length !== 0) {
    trackEvent('use_filters', {
      filters: filtersApplied,
    });
  }
}
