import { Alarm } from '@hakimo-ui/hakimo/types';
import { Row, TableData } from '@hakimo-ui/hakimo/ui-table';
import { Label } from '@hakimo-ui/shared/ui-base';
import React from 'react';
import { mapStatusToLabelType } from '../shared/util/common';
import { tapColors } from './constants';
import { AlarmColumn } from './types';
/*
Order of columns here is what is displayed in the table
*/
export const columns: AlarmColumn[] = [
  {
    id: 'timestamp',
    name: 'Time',
  },
  {
    id: 'status',
    name: 'Status',
    showAtBreakpoint: 'xl',
  },
  {
    id: 'tap',
    name: 'TAP',
    showAtBreakpoint: 'xl',
  },
  {
    id: 'type',
    name: 'Type',
    showAtBreakpoint: 'md',
  },
  {
    id: 'sourceSystem',
    name: 'Source System',
    showAtBreakpoint: 'lg',
  },
  {
    id: 'location',
    name: 'Location',
    showAtBreakpoint: 'lg',
  },
  {
    id: 'sourceEntity',
    name: 'Source Entity',
    showAtBreakpoint: 'md',
  },
  { id: 'employee', name: 'Employee', showAtBreakpoint: 'lg' },
  { id: 'tenant', name: 'Tenant', showAtBreakpoint: 'lg' },
];

const columnConstructor = {
  timestamp: (item: Alarm) => ({
    value: new Date(item.timestamp).toLocaleString(),
  }),
  status: (item: Alarm) => ({
    value: (
      <Label text={item.status} type={mapStatusToLabelType(item.status)} />
    ),
  }),
  tap: (item: Alarm) => {
    const tapColor = item.tap
      ? tapColors[Math.min(Math.floor(item.tap / 10), 9)]
      : null;
    return {
      value: tapColor ? (
        <div>
          <span
            className="inline-block h-4 w-4 rounded-full align-middle"
            style={{ backgroundColor: tapColor }}
          />
          <span className={`px-2 py-1 text-xs font-medium`}>{item.tap}</span>
        </div>
      ) : (
        '-'
      ),
    };
  },
  type: (item: Alarm) => ({ value: item.type }),
  sourceSystem: (item: Alarm) => ({ value: item.sourceSystem }),
  location: (item: Alarm) => ({
    value: item.location?.name ?? 'Unknown Location',
  }),
  sourceEntity: (item: Alarm) => ({
    value: item.sourceEntity?.name ? item.sourceEntity?.name : 'Unknown',
  }),
  employee: (item: Alarm) => ({ value: item.employee?.name }),
  tenant: (item: Alarm) => ({ value: item.tenant.name }),
};

export function getTableData(
  items: Alarm[],
  onClickRowCb: (
    item: Alarm,
    event: React.MouseEvent | React.KeyboardEvent
  ) => void,
  canViewTenantColumn: boolean,
  shownColumns: string[]
): TableData {
  const displayedColumns = columns
    .filter((column) => shownColumns.includes(column.id))
    .filter((col) => {
      return col.id !== 'tenant' || canViewTenantColumn;
    });

  const rows: Row[] = items.map<Row>((item) => {
    return {
      onClick: (event: React.MouseEvent | React.KeyboardEvent) =>
        onClickRowCb(item, event),
      id: item.id,
      cells: displayedColumns.map((col) => {
        const index = col.id;
        return columnConstructor[index](item);
      }),
    };
  });

  return {
    columns: displayedColumns,
    rows,
  };
}
