import { useUpdateMSPLocations } from '@hakimo-ui/hakimo/data-access';
import { ModifyUserMspLocationsPayload, User } from '@hakimo-ui/hakimo/types';
import { toast } from '@hakimo-ui/hakimo/util';
import { Alert, Button, Modal } from '@hakimo-ui/shared/ui-base';
import { useState } from 'react';
import LocationMultiSelect from './LocationMultiSelect';

interface Props {
  selectedUsers: User[];
  onClose: () => void;
}

export function AssignMSPLocationsModal(props: Props) {
  const { selectedUsers, onClose } = props;
  const [selectedLocationIds, setSelectedLocationIds] = useState<number[]>([]);

  const onSuccess = () => {
    toast('MSP locations assigned successfully');
    onClose();
  };

  const mutation = useUpdateMSPLocations(onSuccess);

  const onChangeSelectedLocations = (val: number[]) =>
    setSelectedLocationIds(val);

  const onSubmit = () => {
    const payload: ModifyUserMspLocationsPayload = {
      users: selectedUsers.map((usr) => usr.id),
      mspLocations: selectedLocationIds,
    };
    mutation.mutate(payload);
  };

  const actions = (
    <>
      <Button variant="primary" onClick={onSubmit} loading={mutation.isLoading}>
        Submit
      </Button>
      <Button onClick={onClose}>Cancel</Button>
    </>
  );

  return (
    <Modal
      open
      onClose={onClose}
      closable
      footer={actions}
      title="Assign MSP Locations"
    >
      <div className="space-y-6 p-4 px-8 text-sm">
        {mutation.isError && (
          <Alert type="error">{mutation.error.message}</Alert>
        )}
        <Alert type="info">
          If a user has no assigned MSP location, they will have access to
          alarms for all locations.
        </Alert>

        <div>
          <div className="my-2 italic">Selected Users:</div>
          <div className="grid grid-cols-2 gap-2">
            <span className="font-bold">Name</span>
            <span className="font-bold">Email Id</span>
            {selectedUsers.map((usr) => (
              <>
                <span>{usr.name}</span>
                <span>{usr.email}</span>
              </>
            ))}
          </div>
        </div>
        <div className="mt-4">
          <LocationMultiSelect
            onChange={onChangeSelectedLocations}
            selectedLocationIds={selectedLocationIds}
            label="MSP Locations"
          />
        </div>
      </div>
    </Modal>
  );
}

export default AssignMSPLocationsModal;
