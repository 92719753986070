import { Option, TimePeriod } from '@hakimo-ui/hakimo/types';

export const allTimePeriodOptions: Option<TimePeriod>[] = Object.entries(
  TimePeriod
).map(([id, name]) => ({ id, name }));

export const timePeriods = [
  TimePeriod.PAST_MINUTE,
  TimePeriod.PAST_HOUR,
  TimePeriod.PAST_SIX_HOURS,
  TimePeriod.PAST_24_HOURS,
  TimePeriod.ALL_TIME,
];
export const timePeriodOptions = allTimePeriodOptions.filter((opt) =>
  timePeriods.includes(opt.name)
);

export const agedAlarmsTimePeriods = [
  TimePeriod.ALL_TIME,
  TimePeriod.PAST_HALF_HOUR,
  TimePeriod.PAST_HOUR,
  TimePeriod.PAST_12_HOURS,
  TimePeriod.PAST_24_HOURS,
  TimePeriod.CUSTOM_TIME,
];

export const agedAlarmTimePeriodOptions = allTimePeriodOptions.filter((opt) =>
  agedAlarmsTimePeriods.includes(opt.name)
);
