import { SystemNotificationPreference } from '@hakimo-ui/hakimo/types';
import * as Sentry from '@sentry/react';
import { DEFAULT_AUDIO_NOTIF_FILE } from '../constants';

export function triggerSystemNotification(
  pref?: SystemNotificationPreference,
  text?: string,
  audioFileName = DEFAULT_AUDIO_NOTIF_FILE,
  notificationClickCallback?: (notif: Notification, ev: Event) => void
) {
  if (
    (pref === SystemNotificationPreference.INACTIVE &&
      document.visibilityState === 'hidden') ||
    pref === SystemNotificationPreference.ALWAYS
  ) {
    const notif = new Notification('Notification from Hakimo', {
      body: text,
      icon: '/assets/hakimo.svg',
      silent: true,
    });
    const sound: HTMLAudioElement = new Audio();
    sound.src = `assets/notif-sounds/${audioFileName}.mp3`;
    sound.load();

    sound.play().catch((error) => Sentry.captureMessage(error));

    notif.onclick = function (this: Notification, e: Event) {
      notif.close();
      notificationClickCallback && notificationClickCallback(this, e);
      window.focus();
    };
  }
}

export default triggerSystemNotification;
