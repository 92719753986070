import ProfileSection from '../profile-section/ProfileSection';
import Application from './application/Application';
import Notifications from './notifications/Notifications';

export function Preferences() {
  return (
    <div className="mx-auto w-full max-w-2xl">
      <ProfileSection title="Application" content={<Application />} />
      <ProfileSection title="Notifications" content={<Notifications />} />
    </div>
  );
}

export default Preferences;
