import { EventFilter } from '@hakimo-ui/hakimo/types';
import { eventTracker } from '@hakimo-ui/hakimo/util';
import { Button } from '@hakimo-ui/shared/ui-base';
interface Props {
  onApply: () => void;
  onReset: () => void;
  trackingEventNameApply: EventFilter;
  trackingEventNameReset: EventFilter;
}

export function FilterFooter(props: Props) {
  const { trackingEventNameApply, trackingEventNameReset, onApply, onReset } =
    props;
  return (
    <div className="space-y-3">
      <Button
        block
        variant="primary"
        onClick={onApply}
        onSideEffect={eventTracker(trackingEventNameApply)}
      >
        Apply
      </Button>
      <Button
        block
        variant="outline"
        onClick={onReset}
        onSideEffect={eventTracker(trackingEventNameReset)}
      >
        Reset
      </Button>
    </div>
  );
}

export default FilterFooter;
