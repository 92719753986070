import { useUpdateCamera } from '@hakimo-ui/hakimo/data-access';
import { CameraDetail } from '@hakimo-ui/hakimo/types';
import { toast } from '@hakimo-ui/hakimo/util';
import { Button } from '@hakimo-ui/shared/ui-base';
import { useState } from 'react';
import { ConfirmDelete } from './ConfirmDelete';
import { SettingField } from './SettingField';
import { SettingSection } from './SettingSection';

const sectionInfoText = `No longer want to use this camera for monitoring? You can delete the camera here.`;

interface Props {
  camera: CameraDetail;
  onDeleted: () => void;
}

export function SettingSectionDelete(props: Props) {
  const { camera, onDeleted } = props;
  const [confirm, setConfirm] = useState(false);

  const deleteMutation = useUpdateCamera(
    camera.id,
    () => {
      toast(`${camera.name} deleted!`, {
        type: 'success',
      });
      onDeleted();
    },
    () => {
      toast(`Failed to delete ${camera.name}!`, {
        type: 'error',
      });
    },
    true
  );

  const onConfirmDelete = () => {
    setConfirm(false);
    deleteMutation.mutate({
      isEnabled: false,
    });
  };

  return (
    <SettingSection name="Delete" infoText={sectionInfoText}>
      <SettingField>
        <Button
          variant="error"
          onClick={() => setConfirm(true)}
          loading={deleteMutation.isLoading}
          disabled={deleteMutation.isLoading}
        >
          Delete Camera
        </Button>
        {confirm && (
          <ConfirmDelete
            onClose={() => setConfirm(false)}
            onConfirm={onConfirmDelete}
          />
        )}
      </SettingField>
    </SettingSection>
  );
}
