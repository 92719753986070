import { useAuthUtils } from '@hakimo-ui/hakimo/util';
import Hls from 'hls.js';
import { useEffect, useRef, useState } from 'react';
import { getHls } from './hls-helper';

export function useHLS(
  videoRef: React.RefObject<HTMLVideoElement>,
  videoPath?: string,
  authRequired?: boolean,
  onHLSError?: (err: Error) => void,
  isPlayback?: boolean,
  onInit?: (hls: Hls, videoRef: React.RefObject<HTMLVideoElement>) => void
) {
  const { getAccessToken } = useAuthUtils();
  const [hlsState, setHlsState] = useState<Hls>();
  const videoPathRef = useRef(videoPath);

  useEffect(() => {
    if (!videoPath) {
      return;
    }
    if (videoPathRef.current === videoPath && isPlayback) return;
    else videoPathRef.current = videoPath;

    const videoElem = videoRef.current;

    if (!videoElem) {
      return;
    }

    let hlsPromise: Promise<Hls | undefined>;

    if (videoElem.canPlayType('application/vnd.apple.mpegurl')) {
      videoElem.src = videoPath;
      // If no native HLS support, check if HLS.js is supported
    } else {
      let url;
      try {
        url = new URL(videoPath);
      } catch (error) {
        if (error instanceof Error) {
          error.message = error.message + `: ${videoPath}`;
        }
        throw error;
      }

      hlsPromise = getHls(
        url.search,
        authRequired ? getAccessToken : null
      ).then((hls) => {
        if (hls) {
          hls.loadSource(videoPath);
          hls.attachMedia(videoElem);
          setHlsState(hls);
        }

        return hls;
      });

      hlsPromise.then((hls) => {
        hls && onInit && onInit(hls, videoRef);
        hls?.on(Hls.Events.ERROR, (event, data) => {
          if (data.fatal) {
            onHLSError &&
              onHLSError(new Error(`${event}-${data.type}-${data.details}`));
          }
        });
      });
    }

    return () => {
      if (hlsPromise) {
        onUnmount(hlsPromise);
      }
    };
  }, [
    videoRef,
    videoPath,
    getAccessToken,
    authRequired,
    onHLSError,
    isPlayback,
    onInit,
  ]);
  return hlsState;
}

function onUnmount(hlsPromise: Promise<Hls | undefined>) {
  hlsPromise.then((hls) => {
    if (hls) {
      hls.destroy();
    }
  });
}
