import { Alarm, MotionAlarm } from '@hakimo-ui/hakimo/types';
import { useMemo } from 'react';
import { TimelineSource } from './event-timeline/types';

function createTimelineSource(item: MotionAlarm | Alarm): TimelineSource {
  return {
    id: item?.sourceEntity?.id || '',
    name: item?.sourceEntity?.name ?? (item?.sourceEntity?.id || ''),
    events: [],
  };
}

function updateTimelineSource(
  orig: TimelineSource,
  item: MotionAlarm | Alarm
): TimelineSource {
  if (item.videoStartTime && item.videoEndTime) {
    let itemColor = 'fill-orange-300 hover:fill-orange-400';

    if (item.status && item.status.toLowerCase() !== 'pending') {
      itemColor = 'fill-green-300 hover:fill-green-400';
    }
    orig.events.push({
      id: item.id,
      timespan: [
        new Date(item.videoStartTime).getTime(),
        new Date(item.videoEndTime).getTime(),
      ],
      status: item.status || '',
      colorClass: itemColor,
    });
  }
  return orig;
}

export function createOrUpdateTimelineSource(
  map: Map<string, TimelineSource>,
  item: MotionAlarm | Alarm
): Map<string, TimelineSource> {
  if (!item.sourceEntity) {
    return map;
  }

  const timelineSource =
    map.get(item.sourceEntity.id) || createTimelineSource(item);

  map.set(item.sourceEntity.id, updateTimelineSource(timelineSource, item));

  return map;
}

export function useTimelineSources(alarms: MotionAlarm[]) {
  const timeLineSources = useMemo(() => {
    return Array.from(
      alarms
        .reduce(createOrUpdateTimelineSource, new Map<string, TimelineSource>())
        .values()
    );
  }, [alarms]);

  return timeLineSources;
}
