import {
  useCustomComments,
  useUpdateAlarm,
} from '@hakimo-ui/hakimo/data-access';
import { ReducedAlarm } from '@hakimo-ui/hakimo/types';
import { Select } from '@hakimo-ui/hakimo/ui-elements';
import { eventTracker, getSelectItemsSelectable } from '@hakimo-ui/hakimo/util';
import { Alert, Button, Modal, Selectable } from '@hakimo-ui/shared/ui-base';
import clsx from 'clsx';
import { useState } from 'react';

interface Props {
  open: boolean;
  onClose: () => void;
  alarm: ReducedAlarm;
}

export function CommentModal(props: Props) {
  const { open, onClose, alarm } = props;
  const [comment, setComment] = useState<Selectable | null>(null);
  const { data: options } = useCustomComments();
  const commentMutation = useUpdateAlarm(alarm.id, () => {
    // Close on success
    onClose();
    // Clear submitted comment
    setComment(null);
    // reset mutation
    commentMutation.reset();
  });
  const actions = (
    <>
      <span className="mr-2">
        <Button
          variant="primary"
          loading={commentMutation.isLoading}
          disabled={commentMutation.isLoading || !comment?.name.length}
          onClick={() => {
            if (comment) {
              commentMutation.mutate({
                comment: comment?.name,
                timestamp: new Date().toISOString(),
              });
            }
          }}
          onSideEffect={eventTracker('submit_add_alarm_comment')}
        >
          Submit
        </Button>
      </span>
      <Button
        onClick={onClose}
        onSideEffect={eventTracker('cancel_add_alarm_comment')}
      >
        Cancel
      </Button>
    </>
  );
  const getItems = (query: string) => {
    return getSelectItemsSelectable(query, comment, options);
  };

  return (
    <Modal open={open} onClose={onClose} title="Add Comment" footer={actions}>
      {commentMutation.isError ? (
        <div className="w-96 px-8 py-2">
          <Alert type="error">Error adding comment. Please try again.</Alert>
        </div>
      ) : null}
      <div
        className={clsx('w-96', commentMutation.isError ? 'px-8 py-2' : 'p-8')}
      >
        <Select
          label="Comment"
          getItems={getItems}
          value={comment}
          displayValue={(item) => item?.name ?? ''}
          onChange={setComment}
          id={(item) => item.id}
        />
      </div>
    </Modal>
  );
}

export default CommentModal;
