import { Tenant } from '@hakimo-ui/hakimo/types';
import useAuthenticatedRequest from '../shared/useAuthenticatedRequest';

export function useTenantDetails(
  tenantId: string,
  onSuccess?: (tenant: Tenant) => void
) {
  const url = `/v2/orm/tenant/${tenantId}`;
  const request = new Request(url);

  return useAuthenticatedRequest<Tenant>(request, {
    queryKey: ['tenant', tenantId],
    responseModifier: async (response) => {
      const respJson = await response.json();
      return respJson.payload;
    },
    onSuccess,
  });
}

export default useTenantDetails;
