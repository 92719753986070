import { PlaybackMotionMarkers } from '@hakimo-ui/hakimo/types';
import { memo, useMemo } from 'react';
import { MARGIN, MOTION_TICK_HEIGHT, MOTION_TICK_Y } from '../constants';
import { ZoomMode, getMotionMarkers, getXFromTimestamp } from './util';

interface Props {
  shownStartDate: Date;
  shownEndDate: Date;
  tickGap: number;
  zoomMode: ZoomMode;
  seekerTime: Date;
  timeZone: string;
  playbackMotionMarkers: PlaybackMotionMarkers;
}

export function SeekerAndMotion(props: Props) {
  const {
    shownStartDate,
    shownEndDate,
    tickGap,
    zoomMode,
    seekerTime,
    timeZone,
    playbackMotionMarkers,
  } = props;

  const seekerPosition = getXFromTimestamp(
    shownStartDate,
    tickGap,
    zoomMode,
    seekerTime
  );

  return (
    <>
      <MemoisedMotionTicks
        playbackMotionMarkers={playbackMotionMarkers}
        shownEndDate={shownEndDate}
        shownStartDate={shownStartDate}
        tickGap={tickGap}
        zoomMode={zoomMode}
      />
      <g
        id="seeker"
        fill="none"
        transform={`translate(${MARGIN.left + seekerPosition}, 50)`}
      >
        <line x1="0" y1="0" x2="0" y2="50" className="stroke-onlight-primary" />
        <polygon points="0,48 -5,58 5,58" className="fill-onlight-primary" />
        <text
          x="-23"
          y="70"
          className="dark:fill-ondark-text-1 fill-onlight-text-1 text-[10px]"
        >
          {seekerTime.toLocaleTimeString('en-US', { timeZone, hour12: true })}
        </text>
      </g>
    </>
  );
}
export default SeekerAndMotion;

interface MotionRectProps {
  shownStartDate: Date;
  shownEndDate: Date;
  tickGap: number;
  zoomMode: ZoomMode;
  playbackMotionMarkers: PlaybackMotionMarkers;
}

function MotionTicks(props: MotionRectProps) {
  const {
    shownStartDate,
    shownEndDate,
    tickGap,
    zoomMode,
    playbackMotionMarkers,
  } = props;
  const markers = useMemo(
    () =>
      getMotionMarkers(
        shownStartDate,
        shownEndDate,
        playbackMotionMarkers,
        tickGap,
        zoomMode
      ),
    [playbackMotionMarkers, shownEndDate, shownStartDate, tickGap, zoomMode]
  );
  return (
    <g id="motion-rect">
      {markers.map((marker, i) => (
        <rect
          key={`${marker.start}-${marker.end}-${i}`}
          x={MARGIN.left + marker.start}
          y={MOTION_TICK_Y}
          width={marker.end - marker.start}
          height={MOTION_TICK_HEIGHT}
          className="fill-status-red stroke-status-red pointer-events-none stroke-1 opacity-50 transition-opacity dark:opacity-100"
        />
      ))}
    </g>
  );
}

const MemoisedMotionTicks = memo(MotionTicks);
