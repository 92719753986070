import { eventTracker, toast } from '@hakimo-ui/hakimo/util';
import { Button } from '@hakimo-ui/shared/ui-base';
import { MicrophoneIcon, StopIcon, TrashIcon } from '@heroicons/react/24/solid';
import * as Sentry from '@sentry/react';
import { useCallback, useEffect, useRef, useState } from 'react';

interface Props {
  onClose: () => void;
  onChangeAudio: (file: File) => void;
  onSubmitAudio: () => void;
}

export function Recorder(props: Props) {
  const { onClose, onChangeAudio, onSubmitAudio } = props;
  const playerRef = useRef<HTMLAudioElement>(null);
  const [recording, setRecording] = useState(true);
  const [mediaRecorder, setMediaRecorder] = useState<MediaRecorder>();

  const onData = useCallback(
    (e: BlobEvent) => {
      if (e.data.size > 0) {
        const player = playerRef.current;

        if (player) {
          player.src = URL.createObjectURL(e.data);
          const file = new File([e.data], Date.now() + '.webm');
          onChangeAudio(file);
        }
      }
    },
    [onChangeAudio]
  );

  useEffect(() => {
    navigator.mediaDevices
      .getUserMedia({ audio: true, video: false })
      .then((stream) => {
        const audioContext = new AudioContext();
        const source = audioContext.createMediaStreamSource(stream);
        const gainNode = audioContext.createGain();
        const destination = audioContext.createMediaStreamDestination();

        // Set gain to increase volume
        gainNode.gain.value = 2;

        source.connect(gainNode);
        gainNode.connect(destination);
        const processedStream = destination.stream;
        const options = { mimeType: 'audio/webm;codecs="opus"' };
        const recorder = new MediaRecorder(processedStream, options);
        setMediaRecorder(recorder);
        setRecording(true);
      })
      .catch((error) => {
        toast('Unable to access microphone', { type: 'error' });
        Sentry.captureMessage('Unable to access microphone', {
          extra: { error },
        });
        onClose();
      });
  }, [onClose]);

  useEffect(() => {
    mediaRecorder?.addEventListener('dataavailable', onData);

    return () => {
      mediaRecorder?.removeEventListener('dataavailable', onData);
      mediaRecorder?.stream.getTracks().forEach((track) => track.stop());
    };
  }, [mediaRecorder, onData]);

  useEffect(() => {
    if (recording) {
      mediaRecorder?.start();
    } else {
      mediaRecorder?.stop();
    }
  }, [mediaRecorder, recording]);

  const onStartRecording = () => {
    setRecording(true);
  };

  const onStopRecording = () => {
    setRecording(false);
  };

  const onDiscard = () => {
    onClose();
  };

  const onSubmit = () => {
    onSubmitAudio();
    onClose();
  };

  return (
    <div className="flex flex-wrap items-center justify-center gap-4">
      <div className="flex items-center justify-center">
        {!recording && (
          <span className="-mr-10">
            <Button variant="icon" onClick={onDiscard}>
              <TrashIcon className="w-5" />
            </Button>
          </span>
        )}
        {recording ? (
          <div className="flex items-center gap-2">
            <span className="inline-flex h-3 w-3">
              <span className="absolute inline-flex h-3 w-3 animate-ping rounded-full bg-red-400 opacity-75"></span>
              <span className="relative inline-flex h-3 w-3 rounded-full bg-red-500"></span>
            </span>
            <span className="dark:text-dark-secondary-text text-gray-500">
              Recording audio...
            </span>
          </div>
        ) : (
          <audio
            ref={playerRef}
            className="inline-block h-12 scale-75"
            controls
          ></audio>
        )}
      </div>
      {recording ? (
        <Button variant="icon" onClick={onStopRecording}>
          <StopIcon className="w-5" />
        </Button>
      ) : (
        <span className="-ml-10">
          <Button variant="icon" onClick={onStartRecording}>
            <MicrophoneIcon className="w-5 text-red-500" />
          </Button>
        </span>
      )}
      {!recording && (
        <Button
          variant="primary"
          onClick={onSubmit}
          onSideEffect={eventTracker('submit_manual_camera_audio_talkdown')}
        >
          Send audio
        </Button>
      )}
    </div>
  );
}

export default Recorder;
