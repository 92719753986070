import useAuthenticatedMutation from '../shared/useAuthenticatedMutation';

export function useAddSsoUser(onSuccess: () => void) {
  const url = `/v2/orm/user/sso`;

  const request = new Request(url, {
    method: 'POST',
  });

  return useAuthenticatedMutation<null, null | null>(request, {
    onSuccessInvalidationKeys: [['users'], ['user', 'profile']],
    onSuccess,
  });
}

export default useAddSsoUser;
