import { useDCP } from '@hakimo-ui/hakimo/data-access';
import { QueryResult } from '@hakimo-ui/hakimo/ui-elements';
import { withErrorBoundary } from '@hakimo-ui/hakimo/util';
import LabelDoor from './LabelDoor';

interface Props {
  dcpId: string;
}

function DoorDetails(props: Props) {
  const { dcpId } = props;
  const queryResult = useDCP(dcpId);

  return (
    <QueryResult
      queryResult={queryResult}
      loadingText="Loading Door Details..."
      keepCurrentContentOnRefetch={false}
    >
      {(data) => <LabelDoor key={data.id} dcp={data} />}
    </QueryResult>
  );
}

export default withErrorBoundary(DoorDetails);
