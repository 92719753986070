import { UserRole } from '@hakimo-ui/hakimo/types';
import useAuthenticatedRequest from '../shared/useAuthenticatedRequest';

export function useValidRoles() {
  const url = `/v2/orm/user/roleOptions`;
  const request = new Request(url);

  return useAuthenticatedRequest<UserRole[]>(request, {
    queryKey: ['roles'],
    responseModifier: async (response) => {
      const respJson = await response.json();
      return respJson.payload;
    },
  });
}

export default useValidRoles;
