import { PlaybackTimeline } from '@hakimo-ui/hakimo/feature-shared';
import { useState } from 'react';
import { DEFAULT_PLAYBACK_PERIOD } from '../../constants';

interface Props {
  seekerTime: Date;
  updateSeekerTime: (seekerTime: Date) => void;
  timeZone: string;
}

export function MultiCamTimeline(props: Props) {
  const { seekerTime, timeZone, updateSeekerTime } = props;
  const [startDate] = useState(() => {
    const prevDate = new Date();
    prevDate.setDate(prevDate.getDate() - DEFAULT_PLAYBACK_PERIOD);
    return prevDate;
  });
  const [endDate] = useState(() => new Date());

  return (
    <PlaybackTimeline
      timeZone={timeZone}
      startDate={startDate}
      endDate={endDate}
      seekerTime={seekerTime}
      playbackMotionMarkers={[]}
      onMarkerChange={updateSeekerTime}
    />
  );
}

export default MultiCamTimeline;
