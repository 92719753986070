import { Combobox } from '@headlessui/react';
import { CheckIcon } from '@heroicons/react/24/solid';
import clsx from 'clsx';

interface Props<T> {
  item: T;
  displayValue: (item: T) => string;
  disabled?: boolean;
}

function Option<T>(props: Props<T>) {
  const { item, displayValue, disabled } = props;

  return (
    <Combobox.Option
      value={item}
      disabled={disabled}
      className={({ active }) =>
        clsx(
          'relative cursor-default select-none py-2 pl-3 pr-9',
          active
            ? 'bg-primary-600 dark:bg-primary-800 text-white'
            : 'dark:text-dark-text text-gray-700',
          disabled && 'pointer-events-none opacity-50'
        )
      }
    >
      {({ active, selected: isSelected }) => (
        <>
          <span
            className={clsx('block truncate', isSelected && 'font-semibold')}
          >
            {displayValue(item)}
          </span>
          {isSelected && (
            <span
              className={clsx(
                'absolute inset-y-0 right-0 flex items-center pr-4',
                active ? 'text-white' : 'text-primary-600'
              )}
            >
              <CheckIcon className="h-5 w-5" aria-hidden="true" />
            </span>
          )}
        </>
      )}
    </Combobox.Option>
  );
}

export default Option;
