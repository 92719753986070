import { ResourceType } from '@hakimo-ui/hakimo/data-access';
import { range } from '@hakimo-ui/hakimo/util';

export function getSharedResourcePath(
  resourceType: ResourceType,
  accessToken: string
) {
  let path = '';
  let resourceName = '';
  switch (resourceType) {
    case 'alarm':
      resourceName = 'alarm';
      break;
    case 'location-alarm':
      resourceName = 'location-alarm';
      break;
    case 'live-view':
      resourceName = 'live-view';
      break;
  }
  path = `/shared/${resourceName}?sharedToken=${accessToken}`;
  return path;
}

export type DurationUnit = 'hour' | 'day' | 'week';

export interface DurationOption {
  unit: DurationUnit;
  allowedValues: number[];
}

const liveViewOptions: DurationOption[] = [
  {
    unit: 'hour',
    allowedValues: range(1, 12),
  },
];

const allOptions: DurationOption[] = [
  {
    unit: 'hour',
    allowedValues: range(1, 23),
  },
  {
    unit: 'day',
    allowedValues: range(1, 6),
  },
  {
    unit: 'week',
    allowedValues: range(1, 3),
  },
];

export function getDurationOptions(resourceType: ResourceType) {
  if (resourceType === 'live-view') {
    return liveViewOptions;
  } else {
    return allOptions;
  }
}
