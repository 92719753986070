import { CameraPosition, DCPLabel, Point } from '@hakimo-ui/hakimo/types';
import { atom } from 'jotai';

export const doorMarkersAtom = atom<Point[] | null>(null);
export const floorMarkerAtom = atom<Point | null>(null);
export const cameraPositionAtom = atom<CameraPosition>('unknown');
export const labellingResolutionAtom = atom<Point | undefined>(undefined);
export const localDCPAtom = atom<DCPLabel>((get) => {
  return {
    doorMarkers: get(doorMarkersAtom),
    floorMarker: get(floorMarkerAtom),
    cameraPosition: get(cameraPositionAtom),
    labellingResolution: get(labellingResolutionAtom),
  };
});
