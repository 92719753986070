import { AppRootPathName, EventAppNavigation } from '@hakimo-ui/hakimo/types';

export function getRootNavigationEventName(
  pathname: string
): EventAppNavigation | undefined {
  switch (pathname as AppRootPathName) {
    case '/alarms':
      return 'navigate_alarms';
    case '/location-alarms':
      return 'navigate_location_alarms';
    case '/doors':
      return 'navigate_doors';
    case '/cameras':
      return 'navigate_cameras';
    case '/locations':
      return 'navigate_locations';
    case '/doorgroups':
      return 'navigate_door_groups';
    case '/users':
      return 'navigate_users';
    case '/audit-log':
      return 'navigate_audit_log';
    case '/insights':
      return 'navigate_insights';
    case '/monitoring':
      return 'navigate_monitoring';
    default:
      return undefined;
  }
}
