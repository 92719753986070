import { AuthUtils } from '@hakimo-ui/hakimo/types';
import { createContext, useContext } from 'react';

const defaultValue: AuthUtils = {
  getAccessToken: () => Promise.reject(),
  logout: () => ({}),
};

export const AuthUtilsContext = createContext(defaultValue);

export function useAuthUtils() {
  const authUtils = useContext(AuthUtilsContext);
  return authUtils;
}
