import { NotFound } from '@hakimo-ui/hakimo/ui-elements';
import { withAuthz } from '@hakimo-ui/hakimo/util';
import { useParams } from 'react-router-dom';
import DoorDetails from './DoorDetails';

export function DoorDetailsRoot() {
  const { dcpId } = useParams();

  return dcpId === undefined ? <NotFound /> : <DoorDetails dcpId={dcpId} />;
}

export default withAuthz(DoorDetailsRoot, ['dcp/details:view']);
