interface Props {
  startTime: string;
  endTime: string;
  plusOneDay: boolean;
  timezone: string;
}

function ConfirmationPrompt(props: Props) {
  const { startTime, endTime, plusOneDay, timezone } = props;

  return (
    <div className="leading-8">
      You are about to update automated talkdown window of the camera from{' '}
      <span className="dark:bg-ondark-bg-2 bg-onlight-bg-2 whitespace-pre rounded-md py-1 px-2 font-bold">
        {startTime} {timezone}
      </span>{' '}
      to{' '}
      <span className="dark:bg-ondark-bg-2 bg-onlight-bg-2 whitespace-pre rounded-md py-1 px-2 font-bold">
        {endTime} {plusOneDay && '(Next Day) '} {timezone}
      </span>
      . Are you sure?
    </div>
  );
}

export default ConfirmationPrompt;
