// Original Author: Chandra Mohan Meena
import { MenuDropdown, MenuItem } from '@hakimo-ui/hakimo/ui-elements';
import { EllipsisVerticalIcon, ShareIcon } from '@heroicons/react/24/solid';

interface Props {
  onShare: () => void;
}

export function CameraMenu(props: Props) {
  const { onShare } = props;
  const menuItems: MenuItem[] = [
    {
      name: 'Share live view',
      onClick: onShare,
      icon: <ShareIcon className="h-4 w-4" />,
    },
  ];
  const menuButton = (
    <EllipsisVerticalIcon className="h-5 w-5" data-testid="menu-button" />
  );
  return (
    <span className="mr-1">
      <MenuDropdown items={menuItems} menuButton={menuButton} />
    </span>
  );
}

export default CameraMenu;
