import {
  DoorNameFilterValue,
  LabelStatusFilterValue,
  LocationFilterValue,
} from '@hakimo-ui/hakimo/types';

export const DEFAULT_LABEL_STATUS_FILTER_VALUE: LabelStatusFilterValue = {
  showLabeled: true,
  showUnlabeled: true,
};

export const DEFAULT_LOCATION_FILTER_VALUE: LocationFilterValue = {
  values: [],
  negative: false,
};

export const DEFAULT_DOOR_FILTER_VALUE: DoorNameFilterValue = {
  values: [],
  negative: false,
};
