import { Location } from '@hakimo-ui/hakimo/types';
import { Button } from '@hakimo-ui/shared/ui-base';
import { PencilSquareIcon } from '@heroicons/react/24/outline';
import { useState } from 'react';
import AddUpdateLocation from '../../add-update-location/AddUpdateLocation';
import MonitoringDisarm from '../monitoring-disarm/MonitoringDisarm';

interface Props {
  location: Location;
}
function LocationDetailsHeader(props: Props) {
  const { location } = props;
  const [showModal, setShowModal] = useState(false);
  const onClose = () => {
    setShowModal(false);
  };
  const locationDetailsFormatted = () => {
    return `${location.description}, ${location.city}, ${location.state}, ${location.country}`;
  };
  return (
    <div className="dark:border-dark-border-bg border-onlight-line-3 mb-2 flex items-center justify-between border-b py-2">
      <div className="flex items-center gap-4">
        <div className="border-onlight-line-3 dark:border-dark-border-bg flex flex-col justify-between gap-1 border-r pr-2">
          <h2 className="text-onlight-text-1 dark:text-ondark-text-1 pt-1 text-base">
            {location.name}
          </h2>
          <div className="text-onlight-text-2 dark:text-ondark-text-2 inline-flex items-center text-xs">
            {locationDetailsFormatted()}
          </div>
        </div>
        <div>
          <Button
            variant="icon"
            onClick={() => setShowModal(true)}
            classNames="flex gap-2"
          >
            <PencilSquareIcon className="h-5 w-5" />
            Edit Location
          </Button>
          {showModal && (
            <AddUpdateLocation
              onClose={onClose}
              mode="edit"
              location={location}
            />
          )}
        </div>
      </div>
      <MonitoringDisarm location={location} />
    </div>
  );
}
export default LocationDetailsHeader;
