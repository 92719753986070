import { DCPLabel, Marker, Point, Polygon } from '@hakimo-ui/hakimo/types';
import { normalizePoint } from '@hakimo-ui/hakimo/ui-elements';
import { BOTTOM_LABELLING_LIMIT_PERCENT, DOOR_HEIGHT_LIMIT } from './constants';

export function normalizeLabel(label: DCPLabel | null): DCPLabel | null {
  if (label === null) {
    return null;
  }

  return {
    ...label,
    doorMarkers: label.doorMarkers
      ? label.doorMarkers.map(normalizePoint)
      : null,
    floorMarker: label.floorMarker ? normalizePoint(label.floorMarker) : null,
  };
}

export function checkValidity(label: DCPLabel): boolean {
  if (label.doorMarkers && label.doorMarkers.length > 0) {
    return Boolean(
      label.floorMarker &&
        label.doorMarkers.length === 4 &&
        (label.cameraPosition === 'secure' ||
          label.cameraPosition === 'unsecure')
    );
  }
  return true;
}

function identifyDoorBottomCoords(doorMarkers: Point[]): Point[] {
  const doorCoords = [...doorMarkers];
  return doorCoords.sort((a, b) => b[1] - a[1]).slice(0, 2);
}

function validateDoorSize(doorMarkers: Point[]): boolean {
  const doorCoords = [...doorMarkers];
  if (doorCoords.length === 0) {
    return true;
  }
  const [, minY] = doorCoords.sort((a, b) => a[1] - b[1])[0];
  const [, maxY] = doorCoords.sort((a, b) => b[1] - a[1])[0];
  const doorHeight = maxY - minY;
  return doorHeight > DOOR_HEIGHT_LIMIT;
}

function validateDoorBottomLabelling(
  doorBottomCoords: Point[],
  imageHeight: number
): boolean {
  const heightLimit = (imageHeight * BOTTOM_LABELLING_LIMIT_PERCENT) / 100;
  return !doorBottomCoords.some((coords) => {
    const [, doorHeight] = coords;
    return imageHeight - doorHeight < heightLimit;
  });
}

export function validateDoorAnnotation(label: DCPLabel): boolean {
  const { doorMarkers, labellingResolution } = label;
  const resolutionHeight = labellingResolution?.at(1) || 0;
  const isDoorLabellingInProgress = doorMarkers && doorMarkers.length !== 4;

  if (isDoorLabellingInProgress || !resolutionHeight) {
    return true;
  }

  const doorBottomCoords = identifyDoorBottomCoords(doorMarkers || []);

  const isBottomLabellingValid = validateDoorBottomLabelling(
    doorBottomCoords,
    resolutionHeight
  );
  const isDoorSizeValid = validateDoorSize(doorMarkers || []);

  return isBottomLabellingValid && isDoorSizeValid;
}

export function getPolygons(doorMarkers: Point[]): Polygon[] {
  let polygons: Polygon[] = [];
  if (doorMarkers && doorMarkers.length === 4) {
    polygons.push({ points: doorMarkers } as Polygon);
  } else if (doorMarkers) {
    polygons = [];
  }
  return polygons;
}

export function getPoints(
  doorMarkers?: Point[] | null,
  floorMarker?: Point | null
): Marker[] {
  const points: Marker[] = [];
  if (doorMarkers && doorMarkers.length < 4) {
    points.push(
      ...doorMarkers.map((point) => {
        return {
          point,
        };
      })
    );
  }
  if (floorMarker) {
    points.push({ point: floorMarker });
  }
  return points;
}
