import useAuthenticatedRequest from '../shared/useAuthenticatedRequest';

export function usePlaybackVideo(
  cameraId: string,
  timeStamp: number,
  onSuccess: (url: string) => void
) {
  const url = `/v2/orm/camera/videoPlayback/${cameraId}/${timeStamp}`;
  const request = new Request(url);

  return useAuthenticatedRequest<string>(request, {
    queryKey: [cameraId, timeStamp],
    cacheTime: 0,
    responseModifier: async (response) => {
      const respJson = await response.json();
      return respJson.playlist_url;
    },
    onSuccess,
  });
}

export default usePlaybackVideo;
