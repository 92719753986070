import clsx from 'clsx';
import { ReactNode } from 'react';
import { Align, Breakpoint } from './types';

interface Props {
  type: 'header' | 'data';
  children: ReactNode;
  breakpoint?: Breakpoint;
  align?: Align;
}

export function Cell(props: Props) {
  const { type, breakpoint, align = 'left', children } = props;

  const CellTag = type === 'header' ? 'th' : 'td';

  return (
    <CellTag
      className={clsx(
        'whitespace-nowrap px-3 text-sm sm:pl-6',
        type === 'header' &&
          'dark:text-ondark-text-1 text-onlight-text-1 sticky top-0 bg-gray-100 py-3 font-semibold dark:bg-gray-800',
        type === 'data' &&
          'dark:text-ondark-text-2 text-onlight-text-2 group-focus-visible:border-primary-500 py-2 group-focus-visible:border-y-2 first:group-focus-visible:border-l-2 last:group-focus-visible:border-r-2',
        breakpoint === 'sm' && 'hidden sm:table-cell',
        breakpoint === 'md' && 'hidden md:table-cell',
        breakpoint === 'lg' && 'hidden lg:table-cell',
        breakpoint === 'xl' && 'hidden xl:table-cell',
        breakpoint === '2xl' && 'hidden 2xl:table-cell',
        align === 'left' && 'text-left',
        align === 'center' && 'text-center'
      )}
      scope={type === 'header' ? 'col' : undefined}
    >
      {children}
    </CellTag>
  );
}

export default Cell;
