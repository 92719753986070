import { SEPARATOR } from '@hakimo-ui/hakimo/data-access';
import { LocationFilterValue } from '@hakimo-ui/hakimo/types';

export function getSearchParams(
  selectedLocations?: LocationFilterValue
): string {
  const params = new URLSearchParams();
  if (selectedLocations && selectedLocations.values.length > 0) {
    const locationNames = selectedLocations.values.map((item) => item.name);
    params.append('locations', locationNames.join(SEPARATOR));
  }
  return params.toString();
}
