import { Select } from '@hakimo-ui/hakimo/ui-elements';

interface Props {
  value: string | undefined;
  label: string;
  onChange: (val: string | undefined) => void;
}

const items = [
  { id: undefined, value: 'Show All locations' },
  { id: 'true', value: 'Show only staffed locations' },
  { id: 'false', value: 'Show only unstaffed locations' },
];

export function MannedLocationFilter(props: Props) {
  const { value, label, onChange } = props;

  const getItems = () => items;
  const selectVal = items.find((item) => item.id === value);
  return (
    <Select
      displayValue={(item) => (item ? item.value : '')}
      getItems={getItems}
      onChange={(item) => onChange(item?.id)}
      value={selectVal}
      label={label}
    />
  );
}

export default MannedLocationFilter;
