import { useDoorImage } from '@hakimo-ui/hakimo/data-access';
import { Marker, Point } from '@hakimo-ui/hakimo/types';
import {
  clientMarkerToImageMarker,
  ImagePanel,
  QueryResult,
} from '@hakimo-ui/hakimo/ui-elements';
import { Alert } from '@hakimo-ui/shared/ui-base';
import { useAtom } from 'jotai';
import { labellingResolutionAtom } from './store/state';
import { getPoints, getPolygons } from './util';

interface Props {
  dcpId: string;
  doorMarkers?: Point[] | null;
  floorMarker?: Point | null;
  onAddDoorMarker: (point: Point) => void;
  onAddFloorMarker: (point: Point) => void;
}
export function DoorLabelling(props: Props) {
  const { dcpId, doorMarkers, floorMarker, onAddDoorMarker, onAddFloorMarker } =
    props;
  const [labellingResolution, setLabellingResolution] = useAtom(
    labellingResolutionAtom
  );
  const onClickCanvas: React.MouseEventHandler<HTMLImageElement> = (e) => {
    if (!e.currentTarget || labellingResolution === undefined) {
      return;
    }
    const imgRect = e.currentTarget.getBoundingClientRect();
    const marker: Marker = clientMarkerToImageMarker(
      [imgRect.width, imgRect.height],
      [labellingResolution[0], labellingResolution[1]],
      [imgRect.left, imgRect.top],
      { point: [e.clientX, e.clientY] }
    );
    if (!doorMarkers || doorMarkers.length < 4) {
      onAddDoorMarker(marker.point);
    } else if (!floorMarker) {
      onAddFloorMarker(marker.point);
    }
  };

  const polygons = doorMarkers ? getPolygons(doorMarkers) : [];
  const markers = getPoints(doorMarkers, floorMarker);
  const queryResult = useDoorImage(dcpId);
  return (
    <QueryResult
      queryResult={queryResult}
      loadingText="Loading Image..."
      errorFormatter={(error) => <Alert type="error">{error.message}</Alert>}
      keepCurrentContentOnRefetch={false}
    >
      {(data) => (
        <ImagePanel
          imgUrl={data.url}
          polygons={polygons}
          markers={markers}
          onClickImage={onClickCanvas}
          onClickShape={onClickCanvas}
          onImageLoadCallback={setLabellingResolution}
        />
      )}
    </QueryResult>
  );
}

export default DoorLabelling;
