import { DoorDetailsRoot } from '@hakimo-ui/hakimo/feature-door-details';
import { Route, Routes } from 'react-router-dom';
import DoorList from './door-list/DoorList';

export function DoorsRoutes() {
  return (
    <Routes>
      <Route path="/" element={<DoorList />} />
      <Route path=":dcpId" element={<DoorDetailsRoot />} />
    </Routes>
  );
}

export default DoorsRoutes;
