import {
  useAddDoorsToDoorGroup,
  useDoorGroupsByName,
} from '@hakimo-ui/hakimo/data-access';
import { DoorGroup, DoorGroupDTO } from '@hakimo-ui/hakimo/types';
import { SelectAsync } from '@hakimo-ui/hakimo/ui-elements';
import { eventTracker, toast } from '@hakimo-ui/hakimo/util';
import { Button, Modal, Selectable } from '@hakimo-ui/shared/ui-base';
import { BuildingOffice2Icon } from '@heroicons/react/24/outline';
import { useState } from 'react';

export type AddToDoorGroupModalProps = {
  selectedDoors?: string[];
  onClose: () => void;
};

export function AddToDoorGroupModal(props: AddToDoorGroupModalProps) {
  const { selectedDoors = [], onClose } = props;
  const [doorGroup, setDoorGroup] = useState<Selectable | null>(null);
  const [doorGroups, setDoorGroups] = useState<DoorGroup[]>([]);

  const getDoorGroupByName = useDoorGroupsByName(async (response) => {
    const res = await response.json();
    const payload = res.payload as DoorGroupDTO;
    setDoorGroups(payload.items);
  });

  const mutation = useAddDoorsToDoorGroup({
    doorGroupId: doorGroup?.id ?? '',
    onSuccess: () => {
      toast(
        `Door${
          selectedDoors.length > 1 ? 's' : ''
        } added to Door Group successfully`
      );
      onClose();
    },
  });

  const onSubmit = () => {
    mutation.mutate({
      doors: selectedDoors,
      operation: 'update',
    });
  };

  const actions = (
    <>
      <Button
        variant="primary"
        onClick={onSubmit}
        disabled={!doorGroup || mutation.isLoading}
        onSideEffect={eventTracker('submit_add_doors_to_door_group')}
        loading={mutation.isLoading}
      >
        Submit
      </Button>
      <Button
        onClick={onClose}
        onSideEffect={eventTracker('cancel_add_doors_to_door_group')}
      >
        Cancel
      </Button>
    </>
  );

  const selectedDoorGroup = doorGroups.find(
    (dg) => String(dg.id) === doorGroup?.id
  );

  return (
    <Modal
      title={`Add Door${selectedDoors.length > 1 ? 's' : ''} to Door Group`}
      open
      onClose={onClose}
      footer={actions}
    >
      <div className="w-[30rem] space-y-4 p-8">
        <SelectAsync
          label="Door Group"
          placeholder="Type here to search door groups..."
          onChangeQuery={getDoorGroupByName}
          value={doorGroup}
          onChange={setDoorGroup}
          displayValue={(item) => item?.name || ''}
        />
        {selectedDoorGroup && (
          <div className="dark:border-dark-border-bg dark:bg-dark-surface mt-4 flex items-start justify-between gap-2 rounded border-gray-300 bg-gray-100 p-4">
            <div className="flex flex-col gap-1">
              <div className="mb-1 text-sm">{selectedDoorGroup.name}</div>
            </div>
            <BuildingOffice2Icon className="dark:text-dark-secondary-text h-5 w-5 text-gray-500" />
          </div>
        )}
      </div>
    </Modal>
  );
}

export default AddToDoorGroupModal;
