import { SEPARATOR } from '@hakimo-ui/hakimo/data-access';
import { DoorGroup, DoorGroupFilters } from '@hakimo-ui/hakimo/types';
import { Column as TableColumn, TableData } from '@hakimo-ui/hakimo/ui-table';
import { Button } from '@hakimo-ui/shared/ui-base';
import { CalendarDaysIcon } from '@heroicons/react/24/outline';
import { ActionsDoorGroup } from '../actions-door-groups/ActionsDoorGroup';
export const columns: TableColumn[] = [
  {
    id: 'name',
    name: 'Name',
  },
  {
    id: 'description',
    name: 'Description',
    showAtBreakpoint: 'sm',
  },
  {
    id: 'process-config',
    name: 'Processing Configuration',
    showAtBreakpoint: 'sm',
  },
  {
    id: 'actions',
    name: '',
    showAtBreakpoint: 'sm',
  },
];

export function getTableData(
  items: DoorGroup[],
  onClickProcConfig: (id: number) => () => void
): TableData {
  return {
    rows: items.map((door_group) => {
      return {
        id: String(door_group.id),
        cells: [
          {
            value: door_group.name,
          },
          {
            value: door_group.description,
          },
          {
            value: (
              <Button onClick={onClickProcConfig(door_group.id)} variant="icon">
                <CalendarDaysIcon className="h-5 w-5" />
              </Button>
            ),
          },
          {
            value: <ActionsDoorGroup doorGroup={door_group} />,
          },
        ],
      };
    }),
    columns,
  };
}

export function getSearchParams(
  page: number,
  filters: DoorGroupFilters,
  pageSize: number
): string {
  const params = new URLSearchParams();
  params.append('page', String(page));
  params.append('pageSize', String(pageSize));

  // name filter is not yet there in the backend API, but adding support here.
  if (filters.name.values.length > 0) {
    params.append(
      'name',
      filters.name.values.map((item) => item.name).join(SEPARATOR)
    );
  }

  return params.toString();
}
