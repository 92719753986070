interface Props {
  onChangeVideoSrc: (src: string) => void;
  videoSrc?: string;
  playbackUrl?: string;
}

export function CamPlaybackFeed(props: Props) {
  const { playbackUrl, videoSrc, onChangeVideoSrc } = props;

  const onVideoEnd: React.ReactEventHandler<HTMLVideoElement> = (e) => {
    const video = e.target as HTMLVideoElement;
    const src = video.currentSrc;
    const searchQuery = src.split('?')[1];
    const searchParams = new URLSearchParams(searchQuery);
    const currentStart = searchParams.get('start') || '';
    const currentDuration = parseInt(searchParams.get('duration') || '');
    const newStart = new Date(
      new Date(currentStart).getTime() + currentDuration * 1000
    );
    const newDuration = Math.floor((Date.now() - newStart.getTime()) / 1000);
    const updatedSearchParams = new URLSearchParams();
    updatedSearchParams.append('start', newStart.toISOString());
    updatedSearchParams.append('duration', String(newDuration));

    onChangeVideoSrc(`${playbackUrl}&${updatedSearchParams.toString()}`);
  };

  return (
    <>
      {!playbackUrl && <div>Playback Url is not available for this camera</div>}
      {videoSrc && (
        <video
          autoPlay
          key={videoSrc}
          className="dark:bg-ondark-bg-2 bg-onlight-bg-1 h-full w-full"
          onEnded={onVideoEnd}
        >
          <source src={videoSrc} type="video/mp4" />
        </video>
      )}
    </>
  );
}

export default CamPlaybackFeed;
