import { LabelStatusFilterValue } from '@hakimo-ui/hakimo/types';
import { MultiSelect } from '@hakimo-ui/hakimo/ui-elements';
import { getMultiSelectItems, selectHelpers } from '@hakimo-ui/hakimo/util';

interface Option {
  id: string;
  name: 'Pending' | 'Completed';
}

const completedOption: Option = { id: 'completed', name: 'Completed' };
const pendingOption: Option = { id: 'pending', name: 'Pending' };
const options: Option[] = [completedOption, pendingOption];

interface Props {
  value: LabelStatusFilterValue;
  onChangeCb: (value: LabelStatusFilterValue) => void;
}

function LabelStatusFilter(props: Props) {
  const { value, onChangeCb } = props;

  const onChange = (newVals: Option[]) => {
    onChangeCb({
      showLabeled: newVals.includes(completedOption),
      showUnlabeled: newVals.includes(pendingOption),
    });
  };
  const selected = options.filter(
    (option) =>
      (option.name === 'Completed' && value.showLabeled) ||
      (option.name === 'Pending' && value.showUnlabeled)
  );

  const getItems = (query: string) => {
    const { filterPredicate, isEqual } = selectHelpers;

    return getMultiSelectItems(query, selected, filterPredicate, isEqual, {
      staticItems: options,
    });
  };
  return (
    <MultiSelect
      label="Labelling Status"
      value={selected}
      onChange={onChange}
      getItems={getItems}
      displayValue={(item) => item.name}
      id={(item) => item.id}
    />
  );
}

export default LabelStatusFilter;
