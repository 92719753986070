/*eslint-disable max-lines*/
import { AlarmTypeSelect } from '@hakimo-ui/hakimo/feature-shared';
import {
  AlarmNotification,
  AlarmNotificationsMapping,
  UpsertAlarmNotificationPayload,
} from '@hakimo-ui/hakimo/types';
import { Column, TableData } from '@hakimo-ui/hakimo/ui-table';
import { NOTIFICATION_SOUND_LIST } from '@hakimo-ui/hakimo/util';
import { Label } from '@hakimo-ui/shared/ui-base';
import SelectNotificationSound from './SelectNotificationSound';
import ActionAlarmNotificationPreferences from './actions-alarm-notification-preference/ActionAlarmNotificationPreferences';

export const NEW_CONFIGURATION_ID = '-1';

export const columns: Column[] = [
  {
    id: 'alarm-type',
    name: 'Alarm Type',
    showAtBreakpoint: 'sm',
  },
  {
    id: 'notification-sound',
    name: 'Notification Sound',
    showAtBreakpoint: 'lg',
  },
  {
    id: 'actions',
    name: 'Action',
    showAtBreakpoint: 'md',
  },
];
export function getTableData(
  items: AlarmNotification[],
  canUpdate: boolean,
  onClickEdit: (userId: string) => void,
  onChangeSingleAlarmNotification: (
    newAlarmTypes: string[],
    id: string,
    newSound: string
  ) => void,
  editingRow: string,
  onCancelEdit: () => void,
  disabledTypesPerRow: Record<string, string[]>,
  isEditing: boolean,
  selectedNotificationSounds: string[]
): TableData {
  return {
    columns: columns,
    rows: items.map((item) => {
      return {
        id: item.id,
        cells: [
          {
            value: (
              <div className="flex flex-col">
                <div className="my-1 justify-start">
                  {isEditing && editingRow === item.id && (
                    <Label text="Editing" type="warning" />
                  )}
                </div>
                <div className="max-w-2xl">
                  <AlarmTypeSelect
                    alarmTypes={item.alarmTypes || []}
                    isEditing={isEditing && editingRow === item.id}
                    onChange={(newAlarmTypes) => {
                      onChangeSingleAlarmNotification(
                        newAlarmTypes,
                        item.id,
                        item.notificationSound
                      );
                    }}
                    disabledTypes={disabledTypesPerRow[item.id]}
                  />
                </div>
              </div>
            ),
          },
          {
            value: (
              <SelectNotificationSound
                item={item}
                isAdding={isEditing && item.id === NEW_CONFIGURATION_ID}
                onChange={(newNotifSound) => {
                  onChangeSingleAlarmNotification(
                    item.alarmTypes,
                    item.id,
                    newNotifSound
                  );
                }}
                selectedNotificationSounds={selectedNotificationSounds}
              />
            ),
          },
          {
            value: canUpdate ? (
              <ActionAlarmNotificationPreferences
                isEditing={isEditing}
                item={item}
                editingRow={editingRow}
                onClickEdit={onClickEdit}
                onCancelEdit={onCancelEdit}
              />
            ) : undefined,
          },
        ],
      };
    }),
  };
}

export function getAlarmNotificationPreferenceFromPayload(
  data: AlarmNotificationsMapping
): AlarmNotification[] {
  const alarmNotificationPreference: AlarmNotification[] =
    data.alarm_notification_mapping.map((payload) => ({
      id: payload.id,
      notificationSound: payload.notification_sound,
      alarmTypes: payload.alarm_types,
    }));
  return alarmNotificationPreference;
}
export function getDisabledAlarmsForEachRow(originalData: AlarmNotification[]) {
  const allSetAlarmTypes = originalData.flatMap((entry) => entry.alarmTypes);
  const disabledTypes: Record<string, string[]> = {};
  originalData.forEach((item) => {
    const disableTypes = allSetAlarmTypes.filter(
      (type) => !item.alarmTypes.includes(type)
    );
    disabledTypes[item.id] = disableTypes;
  });
  disabledTypes[NEW_CONFIGURATION_ID] = allSetAlarmTypes;
  return disabledTypes;
}
export function getPayloadForAlarmNotification(
  item: AlarmNotification
): UpsertAlarmNotificationPayload {
  if (item.id === NEW_CONFIGURATION_ID) {
    return {
      notification_sound: item.notificationSound,
      alarm_types: item.alarmTypes,
    };
  } else {
    return {
      id: item.id,
      notification_sound: item.notificationSound,
      alarm_types: item.alarmTypes,
    };
  }
}
export function validateUpsertAlarmNotification(item: AlarmNotification) {
  let isValid = true;
  if (
    item.notificationSound === '' ||
    (item.alarmTypes && item.alarmTypes.length === 0)
  ) {
    isValid = false;
  }
  return isValid;
}

export function getFormattedSeconds(seconds: number): string {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  const formattedMinutes = minutes.toString().padStart(1, '0');
  const formattedSeconds = remainingSeconds.toFixed(0).padStart(2, '0');

  return `${formattedMinutes}:${formattedSeconds}`;
}
export function getSelectedNotificationSounds(
  originalData: AlarmNotification[]
) {
  const selectedNotifSound = originalData.flatMap(
    (entry) => entry.notificationSound
  );
  const isSelected = NOTIFICATION_SOUND_LIST.every((sound) =>
    selectedNotifSound.includes(sound)
  );
  return { isSelected, selectedNotifSound };
}
