import { AudioRecorder } from '@hakimo-ui/hakimo/feature-shared';
import {
  eventTracker,
  useCanDebugMedia,
  useCanViewLiveCamera,
} from '@hakimo-ui/hakimo/util';
import { Toggle } from '@hakimo-ui/shared/ui-base';
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/outline';
import { WrenchScrewdriverIcon } from '@heroicons/react/24/solid';
import { useState } from 'react';

interface Props {
  cameraId: string | null;
  alarmId: string;
  debug: boolean;
  live: boolean;
  sharedToken?: string;
  onToggleDebug: (debug: boolean) => void;
  onToggleLive: (live: boolean) => void;
  onTalkdown: (cameraId: string, alarmId?: string) => void;
}

export function AlarmMediaActions(props: Props) {
  const {
    cameraId,
    alarmId,
    debug,
    live,
    sharedToken,
    onToggleDebug,
    onToggleLive,
    onTalkdown,
  } = props;
  const canDebug = useCanDebugMedia();
  const canViewLive = useCanViewLiveCamera();
  const [showRecorder, setShowRecorder] = useState(false);

  return (
    <div className="mr-8 flex items-center gap-4">
      {!showRecorder && (
        <>
          {canDebug && !sharedToken && (
            <>
              <span className="dark:text-dark-text">Debug Mode</span>
              <Toggle
                enabled={debug}
                onSideEffect={eventTracker('toggle_debug_mode')}
                onChange={onToggleDebug}
                EnabledIcon={WrenchScrewdriverIcon}
                DisabledIcon={WrenchScrewdriverIcon}
              />
            </>
          )}
          {canViewLive && (
            <>
              {live && <span className="text-red-500">LIVE</span>}
              <Toggle
                enabled={live}
                onSideEffect={eventTracker('toggle_live_mode')}
                onChange={onToggleLive}
                EnabledIcon={EyeIcon}
                DisabledIcon={EyeSlashIcon}
              />
            </>
          )}
        </>
      )}
      {live && cameraId && (
        <AudioRecorder
          cameraId={cameraId}
          alarmId={alarmId}
          onToggle={setShowRecorder}
          onTalkdown={onTalkdown}
        />
      )}
    </div>
  );
}

export default AlarmMediaActions;
