import { withAuthenticationRequired as withAuthenticationRequiredAuth0 } from '@auth0/auth0-react';
import { environment } from '@hakimo-ui/hakimo/environments';
import { PageLoadingIndicator } from '@hakimo-ui/hakimo/ui-elements';
import { ReactElement } from 'react';
import withAuthenticationRequired from './withAuthenticationRequired';

interface Props {
  children: ReactElement;
}

function AuthHandler(props: Props) {
  const { children } = props;

  return children;
}

export default environment.authMode === 'auth0'
  ? withAuthenticationRequiredAuth0(AuthHandler, {
      onRedirecting: () => <PageLoadingIndicator />,
    })
  : withAuthenticationRequired(AuthHandler);
