/* eslint-disable max-lines */
import { useAudioDevices } from '@hakimo-ui/hakimo/data-access';
import { AudioDevice, AudioFilterValues } from '@hakimo-ui/hakimo/types';
import { FilterPanel } from '@hakimo-ui/hakimo/ui-filters';
import { Page } from '@hakimo-ui/hakimo/ui-layout';
import { Pagination, Table, TableData } from '@hakimo-ui/hakimo/ui-table';
import {
  trackFilters,
  useCanModifyAudioDevice,
  useCanModifyAudioDeviceCamMap,
  withAuthz,
  withErrorBoundary,
} from '@hakimo-ui/hakimo/util';
import { Alert } from '@hakimo-ui/shared/ui-base';
import { useAtom } from 'jotai';
import { useEffect, useState } from 'react';
import { audioFilterAtom, filterConfigs, stateAtom } from '../state';
import AddAudioDeviceCamMapping from './add-audio-cam-mapping/AddAudioCamMapping';
import AddAudioDevice from './add-audio-device/AddAudioDevice';
import AudioExpandedRow from './table/AudioExpandedRow';
import TableHeader from './table/TableHeader';
import { columns, getTableData } from './table/util';
import { getSearchParams } from './util';

export function AudioDeviceList() {
  const [state, setState] = useAtom(stateAtom);
  const { page, pageSize } = state;
  const [openFilterPanel, setOpenFilterPanel] = useState(false);
  const [audioFilters, setAudioFilters] = useAtom(audioFilterAtom);

  const [tableData, setTableData] = useState<TableData>({
    columns,
    rows: [],
  });

  const [audioDevices, setAudioDevices] = useState<AudioDevice[]>([]);
  const [selectedAudioDevice, setSelectedAudioDevice] = useState<
    AudioDevice | undefined
  >(undefined);
  const [openAddAudioCamMapping, setOpenAddAudioCamMapping] = useState(false);

  const [total, setTotal] = useState(0);
  const [openAddAudioDevice, setOpenAddAudioDevice] = useState(false);

  const { isLoading, isError, error, data } = useAudioDevices(
    getSearchParams(page, pageSize, audioFilters)
  );

  const fromIndex = (page - 1) * pageSize;
  const toIndex = Math.min(page * pageSize, total) - 1;

  const canAddAudioDevice = useCanModifyAudioDevice();
  const canModifyAudioCamMap = useCanModifyAudioDeviceCamMap();
  useEffect(() => {
    if (data) {
      const { items, total: t } = data;
      setTableData(
        getTableData(items, canModifyAudioCamMap, (audioDevice) => {
          setSelectedAudioDevice(audioDevice);
          setOpenAddAudioCamMapping(true);
        })
      );
      setAudioDevices(items);
      setTotal(t);
    }
  }, [data, canModifyAudioCamMap]);

  const onApplyFilters = (values: AudioFilterValues) => {
    setAudioFilters(values);
    trackFilters(values);
    setState((prev) => {
      return { ...prev, page: 1 };
    });
    setOpenFilterPanel(false);
  };

  const tableHeader = (
    <TableHeader
      canAddAudioDevice={canAddAudioDevice}
      isLoading={isLoading}
      onClickAddAudioDevice={() => setOpenAddAudioDevice(true)}
      hasCustomFiltersApplied={audioFilters.location.values.length > 0}
      onClickFilter={() => setOpenFilterPanel(true)}
    />
  );

  const tableFooter = (
    <div className="dark:bg-dark-bg border-t bg-white p-4 dark:border-gray-800">
      <Pagination
        from={fromIndex + 1}
        to={toIndex + 1}
        pageSize={pageSize}
        total={total}
        disabled={isLoading}
        onChangePage={(val) =>
          setState((oldState) => {
            return { ...oldState, page: val };
          })
        }
      />
    </div>
  );

  const getExpandedRow = (rowId: string) => {
    const expandedAudioDevice = audioDevices.find(
      (audioDevice) => String(audioDevice.id) === rowId
    );
    return expandedAudioDevice ? (
      <AudioExpandedRow audioDevice={expandedAudioDevice} />
    ) : null;
  };

  return (
    <Page title="Audio Devices">
      {isError && (
        <div className="mb-2">
          <Alert type="error">{error.message}</Alert>
        </div>
      )}
      {openAddAudioDevice && (
        <AddAudioDevice onClose={() => setOpenAddAudioDevice(false)} />
      )}
      {openAddAudioCamMapping && selectedAudioDevice && (
        <AddAudioDeviceCamMapping
          audioDevice={selectedAudioDevice}
          onClose={() => {
            setSelectedAudioDevice(undefined);
            setOpenAddAudioCamMapping(false);
          }}
        />
      )}
      <div className="dark:bg-dark-bg -mx-4 flex min-h-0 flex-1 flex-col justify-start overflow-y-hidden sm:-mx-6 md:mx-0 md:rounded-lg">
        <Table
          data={tableData}
          header={tableHeader}
          footer={tableFooter}
          loading={isLoading}
          renderExpandedRow={getExpandedRow}
        />
      </div>
      <FilterPanel
        open={openFilterPanel}
        title="Audio Device Filters"
        filterConfigs={filterConfigs}
        initialFilterValues={audioFilters}
        trackingEventNameApply="apply_audio_filters"
        trackingEventNameReset="reset_audio_filters"
        onClose={() => setOpenFilterPanel(false)}
        onApply={onApplyFilters}
      />
    </Page>
  );
}

export default withAuthz(withErrorBoundary(AudioDeviceList), [
  'audio_devices:view',
]);
