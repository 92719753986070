import clsx from 'clsx';
import { ReactNode } from 'react';

export interface Props {
  text: string;
  children: ReactNode;
  position?:
    | 'top'
    | 'top-right'
    | 'right'
    | 'bottom-right'
    | 'bottom'
    | 'bottom-left'
    | 'left'
    | 'top-left';
  always?: boolean;
  rounded?: boolean;
  noShadow?: boolean;
  colorModifier?: 'error' | 'warning' | 'info' | 'success';
  size?: 'small' | 'medium' | 'large';
}

export function Tooltip(props: Props) {
  const {
    text,
    children,
    position = 'top',
    always = false,
    rounded = true,
    noShadow = true,
    colorModifier,
    size,
  } = props;

  return (
    <span
      className={clsx(
        'hover:after:whitespace-pre-line hover:after:content-[attr(aria-label)]',
        `hint--${position}`,
        always && 'hint--always',
        rounded && 'hint--rounded',
        noShadow && 'hint--no-shadow',
        colorModifier && `hint--${colorModifier}`,
        size && `hint--${size}`
      )}
      aria-label={text}
    >
      {children}
    </span>
  );
}

export default Tooltip;
