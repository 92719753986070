import { Button } from '@hakimo-ui/shared/ui-base';
import { MapPinIcon } from '@heroicons/react/24/outline';
import { LocationAlarmContacts } from '../../../location-alarms/alarm-overview/location-alarm-contacts/LocationAlarmContacts';

interface Props {
  siteAddress: string;
  escalationPoints: string[];
  escalationProtocol: string[];
  siteGoogleMapLocation: string;
  locationId: string;
  locationAlarmId: string;
  locationTenantId?: string;
}

export function Escalation(props: Props) {
  const {
    siteAddress,
    escalationPoints,
    escalationProtocol,
    siteGoogleMapLocation,
    locationId,
    locationAlarmId,
    locationTenantId,
  } = props;

  const onMapClick = () => {
    window.open(siteGoogleMapLocation, '_blank', 'noopener,noreferrer');
  };

  return (
    <div className="flex flex-col gap-4">
      <h2 className="text-center font-bold text-red-700/80 dark:text-red-400/80">
        Escalate now to concerned authorities
      </h2>
      <div className="flex gap-2">
        <span className="font-bold">Site address:</span>
        <span>{siteAddress}</span>
      </div>
      <div className="flex items-center gap-2">
        <span className="font-bold">Google map:</span>
        <Button variant="icon" onClick={onMapClick}>
          <MapPinIcon className="h-5 w-5" />
        </Button>
      </div>
      {escalationProtocol.length > 0 && (
        <div className="space-y-2">
          <div className="font-bold">Escalation Protocol:</div>
          <ol className="list-decimal space-y-1 text-sm">
            {escalationProtocol.map((point, i) => (
              <li key={i}>{point}</li>
            ))}
          </ol>
        </div>
      )}
      <div className="space-y-2">
        <div className="font-bold">
          Mention below necessary details on the call:
        </div>
        <ol className="list-decimal space-y-1 text-sm">
          {escalationPoints.map((point, i) => (
            <li key={i}>{point}</li>
          ))}
        </ol>
      </div>
      <div className="border-onlight-line-3 dark:border-ondark-line-3 mt-4 border-t pt-2">
        <LocationAlarmContacts
          locationId={+locationId}
          locationAlarmId={locationAlarmId}
          locationTenantId={locationTenantId}
        />
      </div>
    </div>
  );
}

export default Escalation;
