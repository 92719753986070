import { useCamerasByName } from '@hakimo-ui/hakimo/data-access';
import {
  CameraFilterValue,
  LocationFilterValue,
} from '@hakimo-ui/hakimo/types';
import { Alert } from '@hakimo-ui/shared/ui-base';
import MultiSelectFilter from '../multi-select-filter/MultiSelectFilter';
import { getSearchParams } from '../utils';

interface Props {
  value: CameraFilterValue;
  label: string;
  enableNegativeFilters?: boolean;
  onChange: (value: CameraFilterValue) => void;
  selectedLocations?: LocationFilterValue;
}

export function CameraFilter(props: Props) {
  const {
    value,
    label,
    enableNegativeFilters = false,
    onChange,
    selectedLocations,
  } = props;
  const getCamerasByNameAndLocation = useCamerasByName(
    getSearchParams(selectedLocations)
  );
  const CameraMultiSelect = (
    <MultiSelectFilter
      value={value}
      label={label}
      enableNegativeFilters={enableNegativeFilters}
      onChange={onChange}
      getOptions={getCamerasByNameAndLocation}
    />
  );
  return (
    <div>
      {selectedLocations ? (
        selectedLocations.values.length === 0 ? (
          <div className="m-1 mt-2">
            <Alert type="info">
              Select a location to enable filtering by cameras
            </Alert>
          </div>
        ) : (
          CameraMultiSelect
        )
      ) : (
        CameraMultiSelect
      )}
    </div>
  );
}

export default CameraFilter;
