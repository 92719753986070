import { Pagination } from '@hakimo-ui/hakimo/ui-table';
import { Fragment, ReactElement } from 'react';

interface ItemWithId {
  id: string;
}

interface Props<T extends ItemWithId> {
  items: T[];
  total: number;
  page: number;
  pageSize: number;
  onChangePage: (page: number) => void;
  renderListItem: (item: T) => ReactElement;
}

export function ListView<T extends ItemWithId>(props: Props<T>) {
  const { items, total, page, pageSize, onChangePage, renderListItem } = props;

  const fromIndex = (page - 1) * pageSize;
  const toIndex = Math.min(page * pageSize, total) - 1;

  return (
    <>
      <div className="space-y-1">
        {items.map((item) => {
          return <Fragment key={item.id}>{renderListItem(item)}</Fragment>;
        })}
      </div>
      <div className="pt-4">
        <Pagination
          from={fromIndex + 1}
          to={toIndex + 1}
          pageSize={pageSize}
          total={total}
          onChangePage={(val) => onChangePage(val)}
        />
      </div>
    </>
  );
}

export default ListView;
