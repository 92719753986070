import { MenuDropdown, MenuItem } from '@hakimo-ui/hakimo/ui-elements';
import {
  useCanAddAlarmFeedback,
  useCanReportBug,
} from '@hakimo-ui/hakimo/util';
import { EllipsisVerticalIcon } from '@heroicons/react/24/solid';

interface Props {
  onOpenFeedback: () => void;
  onOpenShareAlarm: () => void;
  onOpenBugReport: () => void;
}

export function PageMenu(props: Props) {
  const { onOpenFeedback, onOpenShareAlarm, onOpenBugReport } = props;
  const canReportBug = useCanReportBug();
  const canAddFeedback = useCanAddAlarmFeedback();

  const menuItems: MenuItem[] = [
    {
      name: 'Share alarm',
      onClick: onOpenShareAlarm,
    },
    {
      name: 'Add feedback',
      onClick: onOpenFeedback,
      hidden: !canAddFeedback,
    },
    {
      name: 'Report bug',
      onClick: onOpenBugReport,
      hidden: !canReportBug,
    },
  ];

  const menuButton = (
    <>
      <span className="sr-only">Open options</span>
      <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
    </>
  );

  return (
    <div className="flex items-center gap-2">
      <MenuDropdown
        items={menuItems}
        menuButton={menuButton}
        menuButtonTrackingEvent="open_alarm_actions_menu"
      />
    </div>
  );
}

export default PageMenu;
