interface Props {
  imagePath: string;
}

export function AlarmImage(props: Props) {
  const { imagePath } = props;

  return (
    <img
      src={imagePath}
      alt="Alarm media"
      className="dark:bg-dark-bg/70 max-h-[48rem] w-full bg-gray-200"
    />
  );
}

export default AlarmImage;
