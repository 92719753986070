import { Route, Routes } from 'react-router-dom';
import LocationDetailsRoot from './location-details/LocationDetailsRoot';
import LocationList from './location-list/LocationList';

export function LocationRoutes() {
  return (
    <Routes>
      <Route path="/" element={<LocationList />} />
      <Route path=":locationId" element={<LocationDetailsRoot />} />
    </Routes>
  );
}

export default LocationRoutes;
