import * as Sentry from '@sentry/react';
import { useState } from 'react';

export function useLocalStorage<T>(
  key: string,
  defaultValue?: T
): [T | undefined, (value: T) => void, () => void, () => T | undefined] {
  const [storedValue, setStoredValue] = useState<T | undefined>(() => {
    try {
      const value = window.localStorage.getItem(key);

      if (value) {
        return JSON.parse(value);
      } else if (defaultValue !== undefined) {
        window.localStorage.setItem(key, JSON.stringify(defaultValue));
      }
      return defaultValue;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(`Error initializing localStorage key “${key}”:`, error);
      Sentry.captureException(
        new Error(`Error initializing localStorage key “${key}”`),
        {
          extra: { error },
        }
      );
      return defaultValue;
    }
  });

  /**
   * Get item value lazily
   */
  const getCurrentValue: () => T = () => {
    try {
      const value = window.localStorage.getItem(key);

      if (value) {
        return JSON.parse(value);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(`Error getting localStorage key “${key}”:`, error);
      Sentry.captureException(
        new Error(`Error getting localStorage key “${key}”`),
        {
          extra: { error },
        }
      );
    }

    return defaultValue;
  };

  const setValue = (value: T) => {
    try {
      window.localStorage.setItem(key, JSON.stringify(value));
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(`Error setting localStorage key “${key}”:`, error);
      Sentry.captureException(
        new Error(`Error setting localStorage key “${key}”`),
        {
          extra: { error },
        }
      );
    }
    setStoredValue(value);
  };

  const removeItem = () => {
    try {
      window.localStorage.removeItem(key);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(`Error removing localStorage key “${key}”:`, error);
      Sentry.captureException(
        new Error(`Error removing localStorage key “${key}”:`),
        {
          extra: { error },
        }
      );
    }
    setStoredValue(undefined);
  };

  return [storedValue, setValue, removeItem, getCurrentValue];
}
