import { VideoControlsList } from '@hakimo-ui/hakimo/types';
import { useFullscreen } from '@hakimo-ui/hakimo/util';
import { Alert } from '@hakimo-ui/shared/ui-base';
import clsx from 'clsx';
import Hls from 'hls.js';
import { useMemo, useRef } from 'react';
import { VideoLoadingIndicator } from '../custom-video-controls/VideoLoadingIndicator';
import { HLSVideoControls } from './HLSVideoControls';
import { useHLS } from './useHLS';

interface HLSVideoPlayerProps {
  videoPath?: string;
  lockAspectRatio?: boolean;
  authRequired?: boolean;
  timeZone?: string;
  isPlayback?: boolean;
  controlsList?: VideoControlsList[];
  onInit?: (hls: Hls, videoRef: React.RefObject<HTMLVideoElement>) => void;
  onError?: (error: Error) => void;
}

export const HLSVideoPlayer = (props: HLSVideoPlayerProps) => {
  const {
    videoPath,
    lockAspectRatio = false,
    authRequired = false,
    onError,
    timeZone = 'UTC',
    isPlayback,
    controlsList = [],
    onInit,
  } = props;
  const videoRef = useRef<HTMLVideoElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const hls = useHLS(
    videoRef,
    videoPath,
    authRequired,
    onError,
    isPlayback,
    onInit
  );

  const { isFullScreen, toggleFullScreen } = useFullscreen(containerRef);
  const controlsListMemo = useMemo<VideoControlsList[]>(
    () => ['noLoader', ...controlsList],
    [controlsList]
  );

  return (
    <div className="relative min-h-0 overflow-hidden" ref={containerRef}>
      <video
        ref={videoRef}
        autoPlay
        className={clsx(
          'dark:bg-ondark-bg-2 before:border-hakimo-yellow h-full w-full bg-gray-200',
          lockAspectRatio && 'aspect-video'
        )}
        muted
      >
        <Alert>Your browser cannot play the provided video file.</Alert>
      </video>
      <VideoLoadingIndicator videoRef={videoRef} videoPath={videoPath} />

      {!controlsList.includes('none') && (
        <HLSVideoControls
          videoRef={videoRef}
          containerRef={containerRef}
          videoPath={videoPath}
          timeZone={timeZone}
          hls={hls}
          isFullScreen={!!isFullScreen}
          onFullScreen={toggleFullScreen}
          controlsList={controlsListMemo}
        />
      )}
    </div>
  );
};

export default HLSVideoPlayer;
