import { User } from '@hakimo-ui/hakimo/types';
import { createContext, useContext } from 'react';

/**
 * Rest of the application will not see this value
 * and will see a response from server instead
 */
const defaultValue: User = {
  id: '',
  email: '',
  name: '',
  roles: [],
  permissions: [],
  userIntercomHash: '',
  mspTenants: [],
  mspLocations: [],
  visionTenants: [],
  enabled: true,
  tenant: {
    id: '',
    name: '',
  },
};

export const UserProfileContext = createContext(defaultValue);

export function useUser() {
  const user = useContext(UserProfileContext);
  return user;
}
