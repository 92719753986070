import { ZoomMode } from './playback-timeline/util';

export const NEXT_INDEX_LOAD_BUFFER = 60 * 60 * 1000; // 60 minutes
export const INITIAL_LOAD_LAG = 15 * 60; // 15 minutes
export const SVG_HEIGHT = 150;
export const DEFAULT_SVG_WIDTH = 1200;
export const MARGIN = {
  left: 10,
  right: 30,
};
export const MOTION_TICK_HEIGHT = 30;
export const MOTION_TICK_Y = 60;
export const ZoomModeTickFactor = {
  [ZoomMode.DAILY]: {
    frequency: 2,
    perDayTicks: 48,
    perTickEquivalentHours: 0.5,
    numberOfDays: 1,
  },
  [ZoomMode.THREE_DAY]: {
    frequency: 6,
    perDayTicks: 24,
    perTickEquivalentHours: 1,
    numberOfDays: 3,
  },
  [ZoomMode.WEEKLY]: {
    frequency: 4,
    perDayTicks: 12,
    perTickEquivalentHours: 2,
    numberOfDays: 7,
  },
};
