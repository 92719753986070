import { UpdateField } from './UpdateField';

interface Props {
  cameraId: string;
  passwordFile?: string;
  onClose: () => void;
}

export function UpdatePasswordFile(props: Props) {
  const { cameraId, onClose, passwordFile } = props;

  return (
    <UpdateField
      cameraId={cameraId}
      name="passwordFilePath"
      displayName="Password File Path"
      currentValue={passwordFile}
      onClose={onClose}
    />
  );
}
