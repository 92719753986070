/* eslint-disable max-lines */
import { useSOP, useUpdateLocationAlarm } from '@hakimo-ui/hakimo/data-access';
import {
  LocationAlarmIncidentDTO,
  LocationAlarmStatus,
  SOPWorkflow,
  StatusType,
} from '@hakimo-ui/hakimo/types';
import { toast } from '@hakimo-ui/hakimo/util';
import { Alert, Button, HakimoSpinner } from '@hakimo-ui/shared/ui-base';
import clsx from 'clsx';
import { useSetAtom } from 'jotai';
import { useEffect, useState } from 'react';
import { LocationAlarmCamera } from '../quick-actions-panel/util';
import { ResolveAction } from './ResolveAction';
import { SOPWorkflowRoot } from './SOPWorkflowRoot';
import { EscalateAlarm } from './escalate/EscalateAlarm';
import { WorkflowItemType, currentStepAtom } from './util';

interface Props {
  locationId: string;
  locationAlarmId: string;
  alarmCameras: LocationAlarmCamera[];
  onAlarmResolve: (status: StatusType) => void;
  locationAlarmStatus: LocationAlarmStatus;
  locationAlarmIncident?: LocationAlarmIncidentDTO;
  locationTenantId?: string;
}

export function SOPWorkflowContainer(props: Props) {
  const {
    locationId,
    locationAlarmId,
    alarmCameras,
    onAlarmResolve,
    locationAlarmIncident,
    locationAlarmStatus,
    locationTenantId,
  } = props;
  const [sopWorkflow, setSOPWorkflow] = useState<SOPWorkflow>();
  const [isQuickResolve, setIsQuickResolve] = useState<boolean | undefined>(
    false
  );

  const resolveMutation = useUpdateLocationAlarm(locationAlarmId, () => {
    toast('Alarm status updated', { type: 'success' });
    onAlarmResolve(StatusType.RESOLVED);
  });

  const onSubmitResolveAction = (comment: string) => {
    resolveMutation.mutate({ status: StatusType.RESOLVED, comment });
  };

  const { isLoading, isRefetching, isError, error } = useSOP(
    {
      locationId,
    },
    async ({ sop_workflow: workflow }) => {
      setSOPWorkflow(workflow);
    }
  );
  const isIncidentTriggeringAlarm =
    locationAlarmIncident &&
    locationAlarmIncident.initiatingLocationAlarmId === locationAlarmId;
  const [currentWorkflowItemType, setCurrentWorkflowItemType] = useState<
    WorkflowItemType | undefined
  >(
    isIncidentTriggeringAlarm
      ? WorkflowItemType.ESCALATE
      : WorkflowItemType.STEPS
  );

  const setWorkflowCurrentStep = useSetAtom(currentStepAtom);

  useEffect(() => {
    setWorkflowCurrentStep(0);
  }, [setWorkflowCurrentStep, locationAlarmId]);

  useEffect(() => {
    if (locationAlarmStatus === 'Resolved') {
      setCurrentWorkflowItemType(undefined);
    }
  }, [locationAlarmStatus]);

  const navigateTo = (val: WorkflowItemType) => () => {
    setCurrentWorkflowItemType(val);
  };

  const navigateToResolve = (isQuickResolveVal?: boolean) => {
    navigateTo(WorkflowItemType.RESOLVE)();
    setIsQuickResolve(isQuickResolveVal === true); // explicitly checking with true because in other cases click event data is coming
  };

  return (
    <div className="relative mt-4 min-h-[40rem] p-5">
      {(isError || sopWorkflow === undefined) && (
        <div className="mx-auto max-w-screen-2xl p-6">
          {error?.message && <Alert type="error">{error.message ?? ''}</Alert>}
          {!(isLoading || isRefetching) && (
            <span>Please ask your admin to update the SOP.</span>
          )}
        </div>
      )}
      {(isLoading || isRefetching) && (
        <div className="absolute -top-6 left-[40%] flex justify-center p-2">
          <HakimoSpinner />
        </div>
      )}

      {sopWorkflow && (
        <div
          className={clsx(
            currentWorkflowItemType === WorkflowItemType.STEPS &&
              'flex min-h-[35rem] flex-col items-center justify-between gap-8'
          )}
        >
          {locationAlarmStatus === 'Resolved' && (
            <div>This location alarm is already resolved</div>
          )}
          {currentWorkflowItemType === WorkflowItemType.STEPS &&
            alarmCameras.length > 0 && (
              <SOPWorkflowRoot
                navigateToEscalate={navigateTo(WorkflowItemType.ESCALATE)}
                navigateToResolve={navigateToResolve}
                sopWorkflow={sopWorkflow}
                alarmCameras={alarmCameras}
                locationAlarmId={locationAlarmId}
                locationId={locationId}
                onQuickResolve={onSubmitResolveAction}
                isResolveSubmitting={resolveMutation.isLoading}
              />
            )}
          {currentWorkflowItemType === WorkflowItemType.RESOLVE && (
            <ResolveAction
              onClose={navigateTo(WorkflowItemType.STEPS)}
              isQuickResolve={isQuickResolve}
              onSubmitResolveAction={onSubmitResolveAction}
              isLoading={resolveMutation.isLoading}
            />
          )}
          {currentWorkflowItemType === WorkflowItemType.ESCALATE && (
            <EscalateAlarm
              escalationPoints={sopWorkflow.escalationPoints}
              escalationProtocol={sopWorkflow.escalationProtocol || []}
              isEscalated={!!locationAlarmIncident}
              locationAlarmId={locationAlarmId}
              locationId={locationId}
              siteAddress={sopWorkflow.siteAddress}
              siteGoogleMapLocation={sopWorkflow.siteGoogleMapLocation}
              locationTenantId={locationTenantId}
              onCancel={navigateTo(WorkflowItemType.STEPS)}
            />
          )}
          {currentWorkflowItemType === WorkflowItemType.STEPS && (
            <Button
              variant="error"
              onClick={navigateTo(WorkflowItemType.ESCALATE)}
            >
              Escalate
            </Button>
          )}
        </div>
      )}
    </div>
  );
}
