import { useId } from 'react';

interface Props {
  value: string;
  label?: string;
  rows?: number;
  onChange: (value: string) => void;
  placeholder?: string;
}

export function Textarea(props: Props) {
  const id = useId();
  const { value, label, rows = 4, onChange, placeholder } = props;

  return (
    <label className="block" htmlFor={id}>
      {label && (
        <span className="dark:text-dark-secondary-text mb-1 text-sm text-gray-700">
          {label}
        </span>
      )}
      <textarea
        rows={rows}
        id={id}
        className="focus:border-primary-500 focus:ring-primary-500 dark:bg-dark-bg dark:border-dark-border-surface block w-full rounded-md border-gray-300 shadow-sm sm:text-sm"
        value={value}
        onChange={(e) => onChange(e.target.value)}
        placeholder={placeholder}
      />
    </label>
  );
}

export default Textarea;
