import { Button } from '@hakimo-ui/shared/ui-base';
import clsx from 'clsx';
import { QuickActions, QuickActionsId } from './util';

interface Props {
  selected: QuickActionsId | undefined;
  onChange: (val: QuickActionsId | undefined) => void;
}

export function QuickActionsIcons(props: Props) {
  const { selected, onChange } = props;

  const onClick = (actionId: QuickActionsId) => () => onChange(actionId);

  return (
    <div className="bg-onlight-bg-2 dark:bg-ondark-bg-2 flex flex-col gap-8 rounded p-1">
      {QuickActions.map((action) => {
        const IconComp = action.icon.component;
        const className = action.icon.className;

        return (
          <Button
            variant="icon"
            key={action.id}
            onClick={onClick(action.id)}
            classNames={clsx(
              selected === action.id &&
                'ring-primary ring-1 ring-inset bg-primary-100/50 dark:bg-primary-100/20'
            )}
            title={action.title}
          >
            <IconComp className={className} />
          </Button>
        );
      })}
    </div>
  );
}

export default QuickActionsIcons;
