import clsx from 'clsx';
import { LABEL_PANEL_WIDTH } from './constants';

interface Props {
  height: number;
  visibleContentWidth: number;
  hasLeft: boolean;
  hasRight: boolean;
  onShowLeft: () => void;
  onShowRight: () => void;
}

export function Navigation(props: Props) {
  const {
    height,
    hasLeft,
    hasRight,
    visibleContentWidth,
    onShowLeft,
    onShowRight,
  } = props;

  return hasLeft || hasRight ? (
    <g
      transform={`translate(${LABEL_PANEL_WIDTH + visibleContentWidth / 2},${
        height - 24
      })`}
      className="timeline-navigation dark:text-ondark-text-2 stroke-current"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <g
        transform={`translate(-24, 0)`}
        onClick={onShowLeft}
        className={clsx('group', !hasLeft && 'pointer-events-none opacity-50')}
      >
        <path
          d="M15 18l-6-6 6-6"
          className="group-hover:stroke-ondark-primary"
        />
        <rect
          x="0"
          y="0"
          width="24"
          height="24"
          opacity="0"
          fill="transparent"
        />
      </g>
      <g
        onClick={onShowRight}
        className={clsx('group', !hasRight && 'pointer-events-none opacity-50')}
      >
        <path d="M9 18l6-6-6-6" className="group-hover:stroke-ondark-primary" />
        <rect
          x="0"
          y="0"
          width="24"
          height="24"
          opacity="0"
          fill="transparent"
        />
      </g>
    </g>
  ) : null;
}
