import { ReactNode } from 'react';

interface Props {
  title: string;
  subtitle?: string;
  children: ReactNode;
  secondary?: ReactNode;
}

export function BasicPanel(props: Props) {
  const { title, subtitle, children, secondary } = props;

  return (
    <div className="flex flex-col overflow-hidden rounded-xl">
      <div className="flex h-16 items-center justify-between bg-white dark:bg-gray-800">
        <div className="px-6">
          <h3 className="dark:text-dark-text font-medium leading-6 text-gray-800 dark:bg-gray-800">
            {title}
          </h3>
          {subtitle && (
            <p className="mt-1 max-w-2xl text-sm text-gray-500 dark:text-gray-400">
              {subtitle}
            </p>
          )}
        </div>
        {secondary && <div>{secondary}</div>}
      </div>
      <div className="min-h-[4rem] flex-1 overflow-auto bg-white dark:bg-gray-800">
        {children}
      </div>
    </div>
  );
}

export default BasicPanel;
