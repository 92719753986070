import { SEPARATOR } from '@hakimo-ui/hakimo/data-access';
import { AudioFilterValues } from '@hakimo-ui/hakimo/types';

export function getSearchParams(
  page: number,
  pageSize: number,
  audioFilters: AudioFilterValues
): string {
  const params = new URLSearchParams();
  params.append('page', String(page));
  params.append('pageSize', String(pageSize));
  if (audioFilters.location.values.length > 0) {
    params.append(
      'locationIds',
      audioFilters.location.values.map((loc) => loc.id).join(SEPARATOR)
    );
  }
  return params.toString();
}
