import { useCameraLivestreamUrl } from '@hakimo-ui/hakimo/data-access';
import { VideoControlsList } from '@hakimo-ui/hakimo/types';
import * as Sentry from '@sentry/react';
import Hls from 'hls.js';
import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import HLSVideoPlayer from '../hls-video-player/HLSVideoPlayer';
import { getAlert } from '../jsx-util/alert';

const TIMER_INTERVAL = 10000;
const BUFFER_TIME = 2;
const MAX_LAG = 6;

interface Props {
  cameraId: string | null;
  open: boolean;
  lockAspectRatio?: boolean;
  controlsList?: VideoControlsList[];
  renderAlert?: (message: string) => ReactElement;
  onFullScreenChange?: (isFullScreen: boolean) => void;
}

export function LiveHls(props: Props) {
  const {
    cameraId,
    open,
    lockAspectRatio = false,
    renderAlert,
    controlsList,
  } = props;

  return cameraId !== null ? (
    <WithCameraId
      cameraId={cameraId}
      open={open}
      renderAlert={renderAlert}
      lockAspectRatio={lockAspectRatio}
      controlsList={controlsList}
    />
  ) : open ? (
    (renderAlert || getAlert)('Live streaming not enabled')
  ) : null;
}

interface WithCameraIdProps {
  cameraId: string;
  open: boolean;
  lockAspectRatio?: boolean;
  renderAlert?: (message: string) => ReactElement;
  controlsList?: VideoControlsList[];
}

const WithCameraId = (props: WithCameraIdProps) => {
  const {
    cameraId,
    open,
    lockAspectRatio = false,
    renderAlert,
    controlsList,
  } = props;
  const { error, data } = useCameraLivestreamUrl(cameraId);
  const controlsListMemo = useMemo<VideoControlsList[]>(
    () => ['noVideoTimeline', 'noPlaybackRate', ...(controlsList || [])],
    [controlsList]
  );

  const [cameraHlsAndRef, setCameraHlsAndRef] = useState<{
    videoRef: React.RefObject<HTMLVideoElement>;
    hls?: Hls;
  }>();

  useEffect(() => {
    if (!cameraHlsAndRef || !cameraHlsAndRef.videoRef) {
      return;
    }
    const videoElement = cameraHlsAndRef.videoRef.current;
    // every 10 seconds if diff is more than MAX_LAG
    // seek video to latest segment
    const handler = () => {
      if (videoElement) {
        const duration = videoElement.duration;
        if (duration - videoElement.currentTime > MAX_LAG) {
          videoElement.currentTime = duration - BUFFER_TIME;
        }
      }
    };

    const timer = window.setInterval(handler, TIMER_INTERVAL);

    return () => {
      timer && window.clearInterval(timer);
    };
  }, [cameraHlsAndRef]);

  const onHlsError = useCallback((e: Error) => {
    Sentry.captureMessage(`hlserror-live-view-${String(e)}`);
  }, []);

  const onHlsInit = useCallback(
    (hls: Hls, videoRef: React.RefObject<HTMLVideoElement>) => {
      // set hls start segment as last segment
      setCameraHlsAndRef({ hls, videoRef });
      const onManifestParsed = () => {
        const totalDuration = hls.levels[0].details?.totalduration;
        if (totalDuration) {
          hls.config.startPosition = totalDuration - BUFFER_TIME;
        }
        hls.off(Hls.Events.MANIFEST_PARSED, onManifestParsed);
      };
      hls.on(Hls.Events.MANIFEST_PARSED, onManifestParsed);
    },
    []
  );

  return open ? (
    <>
      {error && (renderAlert || getAlert)(error.message)}
      <HLSVideoPlayer
        videoPath={data}
        onInit={onHlsInit}
        lockAspectRatio={lockAspectRatio}
        onError={onHlsError}
        controlsList={controlsListMemo}
      />
    </>
  ) : null;
};

export default LiveHls;
