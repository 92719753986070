import { useUpdateProcessingConfig } from '@hakimo-ui/hakimo/data-access';
import { DoorGroup, ProcConfig } from '@hakimo-ui/hakimo/types';
import { deepEqual, eventTracker, toast } from '@hakimo-ui/hakimo/util';
import { Alert, Button, Modal } from '@hakimo-ui/shared/ui-base';
import { useState } from 'react';
import { AddNewProcConf } from './AddNewProcConf';
import { ConfigDisplay } from './ConfigDisplay';
import { DEFAULT_PROC_CONF, ERROR_MSG } from './util';
export interface ProcessingConfigModalProps {
  doorGroup: DoorGroup;
  onClose: () => void;
}
export function ProcessingConfigModal(props: ProcessingConfigModalProps) {
  const { doorGroup, onClose } = props;
  const [isAdding, setIsAdding] = useState<boolean>(false);
  const [procConfigs, setProcConfigs] = useState<ProcConfig[]>(
    doorGroup.processingConfigs.map((item) => {
      return {
        ...item,
        updated: false,
        isNew: false,
        deleted: false,
        isEditing: false,
      };
    })
  );
  const [errorMessage, setErrorMessage] = useState(ERROR_MSG);
  const [newConfigCount, setNewConfigCount] = useState(1);
  const mutation = useUpdateProcessingConfig(doorGroup.id, () => {
    toast('Processing Schedules modified', { type: 'success' });
    onClose();
  });
  const onSubmit = () => {
    mutation.mutate(
      {
        processingConfigs: procConfigs.filter((item) => !item.deleted),
      },
      {
        onError: (error) => {
          setErrorMessage(error.message);
        },
      }
    );
  };
  const actions = (
    <>
      <Button
        variant="primary"
        onClick={onSubmit}
        disabled={
          !procConfigs.some(
            (item) => item.updated || item.isNew || item.deleted
          ) || procConfigs.some((item) => item.isEditing)
        }
        onSideEffect={eventTracker('submit_modify_processing_config')}
        loading={mutation.isLoading}
      >
        Submit
      </Button>
      <Button
        onClick={onClose}
        onSideEffect={eventTracker('cancel_modify_processing_config')}
      >
        Cancel
      </Button>
    </>
  );
  const onResetConfig = (id: number) => {
    setProcConfigs((oldConfigs) => {
      const newConfigs = oldConfigs.filter((item) => item.id !== id);
      const originalConf = doorGroup.processingConfigs.find(
        (item) => item.id === id
      );
      if (originalConf) {
        newConfigs.push({
          ...originalConf,
          updated: false,
          isNew: false,
          deleted: false,
          isEditing: false,
        });
      }
      return newConfigs;
    });
  };
  const onChangeSingleConfig = (
    id: number,
    newConfig: Partial<ProcConfig> | undefined
  ) => {
    mutation.reset();
    setProcConfigs((oldConfigs) => {
      const newConfigs = oldConfigs.filter((item) => item.id !== id);
      if (newConfig === undefined) {
        return newConfigs; // config was deleted
      }
      const oldConfig = oldConfigs.find((item) => item.id === id);
      if (oldConfig) {
        const newConf = {
          ...oldConfig,
          ...newConfig,
        };
        const originalConf = doorGroup.processingConfigs.find(
          (item) => item.id === id
        );
        const { updated, deleted, isNew, isEditing, ...compConf } = newConf;
        if (!isNew || !deleted) {
          newConf.updated = !deepEqual(originalConf, compConf);
          newConfigs.push(newConf);
        } // only add to list if not a new configuration and not deleted
      } else {
        newConfigs.push({
          ...DEFAULT_PROC_CONF,
          ...newConfig,
          id: -1 - newConfigCount,
          isNew: true,
        }); // New config added
        setNewConfigCount((oldCount) => oldCount + 1);
      }
      return newConfigs;
    });
  };
  return (
    <Modal
      title="Processing Configuration"
      open
      onClose={onClose}
      footer={actions}
    >
      <div className="max-h-[70vh] w-[48rem] space-y-4 overflow-y-auto p-8">
        {mutation.isError && <Alert type="error">{errorMessage}</Alert>}
        <AddNewProcConf
          isAdding={isAdding}
          onStartAdd={() => setIsAdding(true)}
          onCancelAdd={() => setIsAdding(false)}
          onAddNew={(newConf) => {
            onChangeSingleConfig(-1, newConf);
            setIsAdding(false);
          }}
        />
        <ConfigDisplay
          procConfigs={procConfigs}
          onChangeSingleConfig={onChangeSingleConfig}
          onResetConfig={onResetConfig}
          isAdding={isAdding}
        />
      </div>
    </Modal>
  );
}
export default ProcessingConfigModal;
