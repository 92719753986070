import { useSetCameraArmedStatus } from '@hakimo-ui/hakimo/data-access';
import { toast } from '@hakimo-ui/hakimo/util';
import { Alert, Button, Modal } from '@hakimo-ui/shared/ui-base';
import dayjs from 'dayjs';
import { useState } from 'react';
import ArmedStatusForm, { FormValue } from './ArmedStatusForm';
import ConfirmationPrompt from './ConfirmationPrompt';
import { DATE_FORMAT, armStatusRadioItems } from './util';

interface Props {
  cameraId: string;
  isArmed: boolean;
  onClose: () => void;
}

export function UpdateArmedStatus(props: Props) {
  const { cameraId, isArmed, onClose } = props;
  const [confirmationPrompt, setConfirmationPrompt] = useState(false);

  const now = dayjs();

  const [formValue, setFormValue] = useState<FormValue>({
    status: isArmed ? armStatusRadioItems[1] : armStatusRadioItems[0],
    startTime: now.format(DATE_FORMAT),
    endTime: now.add(1, 'hour').format(DATE_FORMAT),
  });

  const cameraArmedStatusMutation = useSetCameraArmedStatus(cameraId, () => {
    toast(
      `Camera is ${formValue.status.id === 'arm' ? 'armed!' : 'disarmed!'}`,
      {
        type: 'success',
      }
    );
    onClose();
  });

  const onApply = () => {
    cameraArmedStatusMutation.mutate({
      isArmed: formValue.status.id === 'arm',
      activation_window_start: dayjs(formValue.startTime).unix(),
      activation_window_end: dayjs(formValue.endTime).unix(),
    });
  };

  const onCancel = () => {
    if (confirmationPrompt) {
      setConfirmationPrompt(false);
    } else {
      onClose();
    }
  };

  const actions = (
    <>
      <span className="mr-2">
        {confirmationPrompt ? (
          <Button
            loading={cameraArmedStatusMutation.isLoading}
            disabled={cameraArmedStatusMutation.isLoading}
            variant="primary"
            onClick={onApply}
          >
            Confirm
          </Button>
        ) : (
          <Button variant="primary" onClick={() => setConfirmationPrompt(true)}>
            Submit
          </Button>
        )}
      </span>
      <Button onClick={onCancel}>
        {confirmationPrompt ? 'Back' : 'Cancel'}
      </Button>
    </>
  );

  return (
    <Modal
      open={true}
      onClose={onClose}
      title="Arm/Disarm Camera"
      footer={actions}
    >
      <div className="w-[28rem] px-8 py-4">
        {cameraArmedStatusMutation.isError && (
          <Alert type="error">{cameraArmedStatusMutation.error.message}</Alert>
        )}
        {confirmationPrompt ? (
          <ConfirmationPrompt
            isArming={formValue.status.id === 'arm'}
            fromDate={dayjs(formValue.startTime).toDate()}
            toDate={dayjs(formValue.endTime).toDate()}
          />
        ) : (
          <ArmedStatusForm
            value={formValue}
            minDate={now.format(DATE_FORMAT)}
            onChange={setFormValue}
          />
        )}
      </div>
    </Modal>
  );
}
