import { ModifyAudioDeviceCamMappingPayload } from '@hakimo-ui/hakimo/types';
import useAuthenticatedMutation from '../shared/useAuthenticatedMutation';

export function useAudioDeviceDeleteCamMapping(onSuccess?: () => void) {
  const url = `/v2/orm/audio_devices/mapping`;

  const request = new Request(url, {
    method: 'DELETE',
  });

  return useAuthenticatedMutation<null, ModifyAudioDeviceCamMappingPayload>(
    request,
    {
      onSuccess,
      onSuccessInvalidationKeys: [['audio']],
    }
  );
}

export default useAudioDeviceDeleteCamMapping;
