import { LocationAlarmUpdatesDTO } from '@hakimo-ui/hakimo/types';
import useAuthenticatedRequest from '../shared/useAuthenticatedRequest';

export function useLocationAlarmUpdates(
  locationAlarmId: string,
  refetchInterval: number
) {
  const url = `/v2/orm/location_alarms/status/${locationAlarmId}`;
  const request = new Request(url);

  return useAuthenticatedRequest<LocationAlarmUpdatesDTO>(request, {
    queryKey: ['location_alarm_updates', locationAlarmId],
    responseModifier: async (response) => {
      const respJson = await response.json();
      return respJson.payload;
    },
    refetchInterval,
  });
}

export default useLocationAlarmUpdates;
