import {
  useLocations,
  useLocationsByName,
} from '@hakimo-ui/hakimo/data-access';
import { SelectAsync } from '@hakimo-ui/hakimo/ui-elements';

interface Props {
  locationId?: string;
  label?: string;
  onChange: (id: string) => void;
}

export function LocationSelect(props: Props) {
  const { locationId, onChange, label } = props;

  const getLocations = useLocationsByName();
  const allLocations = useLocations('');

  const selectedLocationName = allLocations.data?.items.find(
    (item) => locationId && item.id === +locationId
  )?.name;

  return (
    <SelectAsync
      value={{
        id: locationId ? locationId : 'unknown',
        name: selectedLocationName || '',
      }}
      label={label}
      placeholder="Type to search ..."
      onChange={(newLoc) => onChange(newLoc.id)}
      onChangeQuery={getLocations}
      displayValue={(item) => item?.name || ''}
      id={(item) => String(item.id)}
    />
  );
}

export default LocationSelect;
