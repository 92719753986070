import { AddDoorsToLocationRequestDTO } from '@hakimo-ui/hakimo/types';
import useAuthenticatedMutation from '../shared/useAuthenticatedMutation';

interface Options {
  locationId: string;
  onSuccess: () => void;
}

export function useAddDoorsToLocation(options: Options) {
  const url = `/v2/orm/locations/${options.locationId}/door`;

  const request = new Request(url, {
    method: 'PATCH',
  });

  return useAuthenticatedMutation<null, AddDoorsToLocationRequestDTO>(request, {
    onSuccessInvalidationKeys: [['doors']],
    onSuccess: options.onSuccess,
  });
}

export default useAddDoorsToLocation;
