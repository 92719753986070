import { Location } from '@hakimo-ui/hakimo/types';
import { Alert, Button } from '@hakimo-ui/shared/ui-base';
import { useState } from 'react';
import UploadFloorplanModal from './UploadFloorplanModal';

interface Props {
  location: Location;
}

export function Floorplan(props: Props) {
  const { location } = props;
  const [showUploadFloorplanModal, setShowUploadFloorplanModal] =
    useState(false);

  const onClickUploadFloorplan = () => setShowUploadFloorplanModal(true);
  const onCloseModal = () => setShowUploadFloorplanModal(false);

  return (
    <div>
      <div className="pb-4 text-end">
        <Button onClick={onClickUploadFloorplan} variant="primary">
          Upload Floorplan
        </Button>
      </div>
      {location.floor_plan ? (
        <div className="bg-onlight-bg-2 dark:bg-dark-bg flex justify-center">
          <img src={location.floor_plan} alt="Floorplan" />
        </div>
      ) : (
        <div className="p-6">
          <Alert>Floorplan not available</Alert>
        </div>
      )}
      {showUploadFloorplanModal && (
        <UploadFloorplanModal
          locationId={location.id.toString()}
          locationName={location.name}
          onClose={onCloseModal}
        />
      )}
    </div>
  );
}

export default Floorplan;
