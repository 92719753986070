import { ReactNode } from 'react';

interface Props {
  children: ReactNode;
}

export function SettingField(props: Props) {
  const { children } = props;

  return <div className="pt-6">{children}</div>;
}
