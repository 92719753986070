export type SSEDatum = {
  id: string;
  p: number;
};

export interface EventHistory {
  id: string;
  cameraId: string;
  cameraName: string;
  createdTime: number; // in milliseconds
  frame: string; // Hex-encoded frame data
  personIds: string[];
}

export interface Person {
  name: string;
  class: number;
  confidence: number;
  box: {
    x1: number;
    y1: number;
    x2: number;
    y2: number;
  };
  person_id: string;
  overlap: number;
}

export enum VisionEventType {
  DETECTION = 'detection',
  HISTORY = 'history',
}

export interface VisionEventMetadata {
  frame: string;
  persons: Person[];
}

export interface VisionEvent {
  id: string;
  event_id: string;
  camera_id: string;
  tenant_id: string;
  group_id: string;
  objects: { person: number };
  timestamp_utc: number;
  p: number;
  camera_name: string;
  metadata: VisionEventMetadata;
  event_type: VisionEventType;
}

export enum VisionOutboundType {
  SAFE = 'safe',
  ESCALATION_OPEN = 'escalation_open',
  ESCALATION_CLOSE = 'escalation_close',
  HISTORY = 'history',
  CAMERA_COUNT = 'camera_count',
}
export interface VisionOutboundPayload {
  camera_id?: string;
  tenant_id?: string;
  event_type: VisionOutboundType;
  person_ids?: string[];
  additional_data?: {
    // all timestamps in milliseconds
    camera_display_timestamp?: number;
    safe_action_timestamp?: number;
    escalation_open_timestamp?: number;
    escalation_close_timestamp?: number;
    escalation_id?: string;
    active_camera_count?: number;
    snoozed_camera_count?: number;
    username?: string; // email
    resolution_status?: string;
  };
}
