import { User } from '@hakimo-ui/hakimo/types';
import useAuthenticatedRequest from '../shared/useAuthenticatedRequest';

export function useProfile() {
  const url = `/v2/orm/user/profile`;
  const request = new Request(url);

  return useAuthenticatedRequest<User>(request, {
    queryKey: ['user', 'profile'],
    responseModifier: async (response) => {
      const respJson = await response.json();
      return respJson.payload;
    },
  });
}

export default useProfile;
