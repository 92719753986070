import {
  AuthMode,
  Environment,
  WindowAppEnvironment,
  WindowAppEnvironmentKey,
} from './types';

declare global {
  interface Window {
    _APP_ENV_: WindowAppEnvironment;
  }
}

const ENVIRONMENTS: Environment[] = [
  'LOCAL',
  'DEV',
  'STAGING',
  'DEMO',
  'PROD',
  'AIRGAP',
];

const AUTH_MODES: AuthMode[] = ['local', 'auth0'];

export function getEnvValueAsString(name: WindowAppEnvironmentKey): string {
  return window._APP_ENV_?.[name] ?? 'UNKNOWN';
}

export function getEnvValueAsType<T>(
  name: WindowAppEnvironmentKey,
  typeValidator: (value: string) => T | null
): T {
  const value = getEnvValueAsString(name);
  const typedValue = typeValidator(value);
  if (typedValue === null) {
    throw new Error(
      `Invalid value "${value}" for environment variable "${name}"`
    );
  }
  return typedValue;
}

export function asEnvironment(value: string): Environment | null {
  return ENVIRONMENTS.includes(value as Environment)
    ? (value as Environment)
    : null;
}

export function asAuthMode(value: string): AuthMode | null {
  return AUTH_MODES.includes(value as AuthMode) ? (value as AuthMode) : null;
}
