/* eslint-disable max-lines */
import { DCP, Door, DoorFilters } from '@hakimo-ui/hakimo/types';
import { Column, Row, TableData } from '@hakimo-ui/hakimo/ui-table';
import { Button, Label } from '@hakimo-ui/shared/ui-base';
import { PlusIcon, TrashIcon } from '@heroicons/react/24/outline';
import { MouseEvent } from 'react';
import AddMappingModal, {
  AddMappingModalProps,
} from './add-mapping-modal/AddMappingModal';
import AddToDoorGroupModal, {
  AddToDoorGroupModalProps,
} from './add-to-door-group-modal/AddToDoorGroupModal';
import AddToLocationModal, {
  AddToLocationModalProps,
} from './add-to-location-modal/AddToLocationModal';
import RemoveDoorFromDoorGroupModal, {
  RemoveDoorFromDoorGroupModalProps,
} from './remove-from-door-group-modal/RemoveDoorFromDoorGroupModal';
import RemoveMappingModal, {
  RemoveMappingModalProps,
} from './remove-mapping-modal/RemoveMappingModal';

export function isNotDefaultFilter(filters: DoorFilters): boolean {
  return (
    !filters.labelStatus.showLabeled ||
    !filters.labelStatus.showUnlabeled ||
    filters.location.values.length > 0 ||
    filters.door.values.length > 0
  );
}

export const mapDoorFilter = (filters: DoorFilters) => {
  const { location, door, labelStatus } = filters;
  const filtersApplied: Record<string, string[] | object> = {};

  location.values.length > 0 &&
    (filtersApplied['location'] = {
      value: location.values.map((l) => l.name),
      negative: location.negative,
    });

  door.values.length > 0 &&
    (filtersApplied['door'] = {
      value: door.values.map((d) => d.name),
      negative: door.negative,
    });

  filtersApplied['labelStatus'] = labelStatus;

  return filtersApplied;
};

function isLabelled(dcp?: DCP | null): boolean {
  if (dcp == null) {
    return false;
  }
  // Check for both null and undefined
  if (dcp.label == null) {
    return false;
  }
  return (
    dcp.label.doorMarkers !== null &&
    dcp.label.doorMarkers.length === 4 &&
    dcp.label.cameraPosition !== 'unknown'
  );
}

export const columns: Column[] = [
  {
    id: 'door',
    name: 'Door',
  },
  {
    id: 'camera',
    name: 'Camera',
    showAtBreakpoint: 'xl',
  },
  {
    id: 'location',
    name: 'Location',
    showAtBreakpoint: 'lg',
  },
  {
    id: 'doorGroup',
    name: 'Door Group',
    showAtBreakpoint: 'lg',
  },
  {
    id: 'label',
    name: 'Label',
    align: 'center',
    showAtBreakpoint: 'md',
  },
  {
    id: 'sourceSystem',
    name: 'Source System',
    showAtBreakpoint: 'lg',
  },
  {
    id: 'action',
    name: '',
    align: 'center',
    showAtBreakpoint: 'md',
  },
];

export function getTableData(
  items: Door[],
  onClickCb: (item: Door) => void,
  onClickRemove: (
    event: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>,
    dcp: Door
  ) => void,
  onClickAddMapping: (
    event: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>,
    door: Door
  ) => void,
  canRemove: boolean,
  canAdd: boolean
): TableData {
  const rows: Row[] = items.map<Row>((item) => {
    return {
      id: item.id,
      onClick: () => {
        if (item.dcp) {
          onClickCb(item);
        }
      },
      cells: [
        {
          value: <div className="truncate">{item.name}</div>,
        },
        {
          value: item.dcp?.cameraName,
        },
        {
          value: item.location?.name,
        },
        {
          value: item.doorGroup?.name,
        },
        {
          value: isLabelled(item.dcp) ? (
            <Label text="Completed" type="success" />
          ) : (
            <Label text="Pending" type="error" />
          ),
          align: 'center',
        },
        {
          value: item.sourceSystem,
        },
        {
          value:
            item.dcp && canRemove ? (
              <Button
                variant="icon"
                onClick={(event) => onClickRemove(event, item)}
              >
                <TrashIcon className="w-4" />
              </Button>
            ) : !item.dcp?.cameraName && canAdd ? (
              <Button
                variant="icon"
                onClick={(event) => onClickAddMapping(event, item)}
              >
                <PlusIcon className="w-4" />
              </Button>
            ) : (
              <span></span>
            ),
        },
      ],
    };
  });

  return {
    columns,
    rows,
  };
}

export enum DoorActionModal {
  ADD_MAPPING = 'addMapping',
  ADD_TO_LOCATION = 'addToLocation',
  REMOVE_MAPPING = 'removeMapping',
  ADD_DOOR_GROUP = 'addDoorGroup',
  REMOVE_DOOR_FROM_DOOR_GROUP = 'removeDoorFromDoorGroup',
}
type ModalProps =
  | AddMappingModalProps
  | AddToLocationModalProps
  | RemoveMappingModalProps
  | AddToDoorGroupModalProps
  | RemoveDoorFromDoorGroupModalProps;

type ModalMap = {
  id: string;
  Modal: React.FC<ModalProps>;
};

export const getModalMapping = (): ModalMap[] => {
  return [
    {
      id: DoorActionModal.ADD_MAPPING,
      Modal: AddMappingModal,
    },
    {
      id: DoorActionModal.ADD_TO_LOCATION,
      Modal: AddToLocationModal,
    },
    {
      id: DoorActionModal.REMOVE_MAPPING,
      Modal: RemoveMappingModal,
    },
    {
      id: DoorActionModal.ADD_DOOR_GROUP,
      Modal: AddToDoorGroupModal,
    },
    {
      id: DoorActionModal.REMOVE_DOOR_FROM_DOOR_GROUP,
      Modal: RemoveDoorFromDoorGroupModal,
    },
  ];
};
