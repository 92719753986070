import {
  FullScreenExitIcon,
  FullScreenIcon,
  Select,
} from '@hakimo-ui/hakimo/ui-elements';
import { eventTracker } from '@hakimo-ui/hakimo/util';
import { Button, ItemsSwitch, SwitchItem } from '@hakimo-ui/shared/ui-base';
import { EyeIcon, FilmIcon, PlusCircleIcon } from '@heroicons/react/24/outline';
import { MultiCamViewMode } from '../../constants';

interface Props {
  cameraCount: number;
  isFullScreen: boolean;
  viewMode: MultiCamViewMode;
  timezone?: string;
  allTimezones: string[];
  addCamera: () => void;
  toggleFullScreen: () => void;
  onChangeItemsSwitch: (item: SwitchItem) => void;
  updateTimezone: (timezone: string | null) => void;
}

export function CameraGridHeader(props: Props) {
  const {
    cameraCount,
    isFullScreen,
    viewMode,
    timezone,
    allTimezones,
    addCamera,
    toggleFullScreen,
    onChangeItemsSwitch,
    updateTimezone,
  } = props;

  const switchOptions = [
    {
      id: MultiCamViewMode.LIVE,
      name: 'Live',
      icon: EyeIcon,
      extra:
        viewMode === MultiCamViewMode.LIVE ? (
          <span className="bg-status-red h-1.5 w-1.5 animate-pulse rounded-full"></span>
        ) : null,
    },
    {
      id: MultiCamViewMode.PLAYBACK,
      name: 'Playback',
      icon: FilmIcon,
    },
  ];
  return (
    <div className="mb-2 flex items-center justify-between">
      <ItemsSwitch
        items={switchOptions}
        onChange={onChangeItemsSwitch}
        selectedItem={switchOptions.find((opt) => opt.id === viewMode)}
      />
      <div className="flex items-center gap-2">
        {timezone && viewMode === MultiCamViewMode.PLAYBACK && (
          <Select
            displayValue={(item) => item || ''}
            value={timezone}
            getItems={() => allTimezones}
            label=""
            onChange={updateTimezone}
          />
        )}
        {cameraCount < 4 && (
          <Button variant="icon" onClick={addCamera}>
            <PlusCircleIcon className="mr-2 h-5 w-5" />
            Add Camera
          </Button>
        )}
        <Button
          variant="icon"
          onClick={toggleFullScreen}
          onSideEffect={eventTracker('toggle_camera_grid_view_fullscreen')}
        >
          <span className="dark:text-dark-secondary-text text-onlight-text-3 flex items-center">
            {isFullScreen ? (
              <>
                <FullScreenExitIcon className="dark:fill-dark-text h-7 w-7" />
                Exit{' '}
              </>
            ) : (
              <FullScreenIcon className="dark:fill-dark-text h-7 w-7" />
            )}
            Fullscreen
          </span>
        </Button>
      </div>
    </div>
  );
}

export default CameraGridHeader;
