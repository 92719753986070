import { UpdateSiteDisarmWindowPayload } from '@hakimo-ui/hakimo/types';
import dayjs from 'dayjs';
import dayjsTimezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);
dayjs.extend(dayjsTimezone);

export function formatDateInTimezone(
  timestampInSeconds: number,
  timezone: string
) {
  return dayjs
    .unix(timestampInSeconds)
    .tz(timezone)
    .format('DD MMMM YYYY, h:mm A');
}

export function convertTimestampToFormattedDate(
  timestampInSeconds: number,
  timeZone: string
) {
  if (timestampInSeconds in [0, 1]) {
    return '';
  }
  const date = dayjs.unix(timestampInSeconds).tz(timeZone);
  const formattedDate = date.format('YYYY-MM-DDTHH:mm');
  return formattedDate;
}

export function convertDateStringToTimestamp(
  dateString: string,
  timeZone: string
) {
  const date = dayjs.tz(dateString, timeZone);
  const timestampInSeconds = date.unix();
  return timestampInSeconds;
}

export function validateDisarmWindow(
  siteDisarmWindowPayload: UpdateSiteDisarmWindowPayload
) {
  let message = '';
  // time is in seconds
  const { startTime, endTime } = siteDisarmWindowPayload;

  if (startTime >= endTime) {
    message = 'End Time should not be same or less than start time';
  }
  if (endTime * 1000 < new Date().getTime()) {
    message = 'End Time should not be less than current time';
  }

  return { isValid: !message, message };
}
