import { Camera } from '@hakimo-ui/hakimo/types';
import { Select } from '@hakimo-ui/hakimo/ui-elements';
import { useState } from 'react';

interface Props {
  cameras: Camera[];
  onChange: (camera: Camera) => void;
  selectedCamera?: Camera | null;
}

export function CameraSelect(props: Props) {
  const { selectedCamera, cameras, onChange } = props;

  const [cameraList, setCameraList] = useState<Camera[]>(cameras);

  const onCameraChange = (cam: Camera | null) => {
    cam && onChange(cam);
  };

  const getDisplayValue = (cam: Camera | undefined) => {
    if (cam?.id === selectedCamera?.id) {
      return cam?.name || '';
    } else {
      return cam ? `${cam.name} - ${cam.location?.name || ''}` : '';
    }
  };

  const onChangeQuery = (query: string) => {
    const updatedCameraList = cameras.filter((cam) =>
      cam.name.toLowerCase().includes(query.toLowerCase())
    );
    setCameraList(updatedCameraList);
  };
  return (
    <Select
      getItems={() => cameraList}
      value={selectedCamera}
      onChange={onCameraChange}
      displayValue={getDisplayValue}
      onChangeQuery={onChangeQuery}
      placeholder="Select a camera"
    />
  );
}
export default CameraSelect;
