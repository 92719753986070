import { UpdateField } from './UpdateField';

interface Props {
  cameraId: string;
  rtspUrl?: string;
  onClose: () => void;
}

export function UpdateRtspUrl(props: Props) {
  const { cameraId, onClose, rtspUrl } = props;

  return (
    <UpdateField
      cameraId={cameraId}
      name="rtspUrl"
      displayName="RTSP URL"
      currentValue={rtspUrl}
      onClose={onClose}
    />
  );
}
