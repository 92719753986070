import { HakimoSpinner } from '@hakimo-ui/shared/ui-base';
import { useEffect, useState } from 'react';

interface Props {
  videoRef?: React.RefObject<HTMLVideoElement>;
  videoPath?: string;
}

export function VideoLoadingIndicator(props: Props) {
  const { videoRef, videoPath } = props;
  const [waiting, setWaiting] = useState(true);
  const videoElement = videoRef?.current;

  useEffect(() => {
    const waitingHandler = () => setWaiting(true);
    const playingHandler = () => setWaiting(false);

    videoElement?.addEventListener('waiting', waitingHandler);
    videoElement?.addEventListener('playing', playingHandler);

    return () => {
      videoElement?.removeEventListener('waiting', waitingHandler);
      videoElement?.removeEventListener('playing', playingHandler);
    };
  }, [videoElement]);

  useEffect(() => {
    setWaiting(true);
  }, [videoPath]);

  return waiting ? (
    <div className="bg-dark-bg/25 absolute inset-0 z-[5] flex items-center justify-center">
      <HakimoSpinner className="text-hakimo-yellow" />
    </div>
  ) : null;
}
