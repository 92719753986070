import { AlarmsDTO } from '@hakimo-ui/hakimo/types';
import { useSetAtom } from 'jotai';
import { useEffect } from 'react';
import { alarmsRenderedTimeAtom } from '../shared/state';

export function useUpdateRenderedTime(data: AlarmsDTO | undefined) {
  const setAlarmsRenderedTime = useSetAtom(alarmsRenderedTimeAtom);

  useEffect(() => {
    if (!data) {
      return;
    }

    const { items } = data;
    const timestamp = new Date().getTime();

    setAlarmsRenderedTime((state) => {
      return items.reduce((acc, item) => {
        return {
          ...acc,
          [item.id]: state[item.id] ?? timestamp,
        };
      }, {});
    });
  }, [data, setAlarmsRenderedTime]);
}
