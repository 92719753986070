import { Button } from '@hakimo-ui/shared/ui-base';
import { Transition } from '@headlessui/react';
import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  ExclamationTriangleIcon,
  InformationCircleIcon,
  SpeakerWaveIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import clsx from 'clsx';
import { SVGProps } from 'react';
import { default as rhToast } from 'react-hot-toast';
import { NotificationSound } from './NotificationSound';
type ToastType = 'info' | 'success' | 'warning' | 'error';

interface Options {
  type?: ToastType;
  duration?: number;
  audio?: boolean;
  audioFileName?: string;
  id?: string;
}
export const dismissToast = (toastId: string) => {
  rhToast.dismiss(toastId);
};
export const toast = (message: string, options?: Options) => {
  let Icon: (props: SVGProps<SVGSVGElement>) => JSX.Element;

  switch (options?.type) {
    case 'success':
      Icon = CheckCircleIcon;
      break;
    case 'warning':
      Icon = ExclamationCircleIcon;
      break;
    case 'error':
      Icon = ExclamationTriangleIcon;
      break;
    default:
      Icon = InformationCircleIcon;
  }
  rhToast.custom(
    (t) => (
      <Transition
        appear
        show={t.visible}
        className="ml-auto w-full max-w-sm"
        enter="transform transition ease-in-out duration-300"
        enterFrom="translate-x-full"
        enterTo="translate-x-0"
        leave="transform transition ease-in-out duration-300"
        leaveFrom="translate-x-0"
        leaveTo="translate-x-full"
      >
        <div className="dark:bg-dark-surface pointer-events-auto overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
          <div className="p-4">
            <div className="flex items-center">
              <div className="flex-shrink-0">
                <Icon
                  className={clsx(
                    'h-6 w-6',
                    (options?.type === 'info' || options?.type === undefined) &&
                      'text-blue-400',
                    options?.type === 'success' && 'text-green-400',
                    options?.type === 'warning' && 'text-yellow-400',
                    options?.type === 'error' && 'text-red-400'
                  )}
                  aria-hidden="true"
                />
              </div>
              <div className="ml-3 w-0 flex-1 pt-0.5">
                <p className="dark:text-dark-text text-sm text-gray-900">
                  {message}
                </p>
              </div>

              {options?.audio && (
                <div className="dark:text-dark-text flex-shrink-0 pt-0.5">
                  <SpeakerWaveIcon className="h-5 w-5" />
                  <NotificationSound
                    enabled={t.visible && options.audio}
                    audioFileName={options.audioFileName}
                  />
                </div>
              )}
              <div className="ml-4 flex flex-shrink-0">
                <Button variant="icon" onClick={() => rhToast.dismiss(t.id)}>
                  <span className="sr-only">Close</span>
                  <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Transition>
    ),
    {
      duration: options?.duration ?? 5000,
      id: options?.id,
    }
  );
};
