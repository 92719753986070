interface Options<T> {
  /**
   * Custom item builder used to build a custom dropdown option
   */
  customItemBuilder?: (qry: string, val: T[]) => T;

  /**
   * A static list of options. Custom values may be allowed by specifying `customItem` builder.
   */
  staticItems?: T[];

  /** Determines if an option is custom built using `customItem` builder */
  isCustomItem?: (item: T) => boolean;
}

/**
 * @param query current text input
 * @param value list of selected options
 * @param filterPredicate determines if an option should be displayed in the dropdown
 * @param isEqual determines if given query equals an option
 * @param {Options} options additional configuration
 * @returns list of options to display in the dropdown
 */
export function getMultiSelectItems<T>(
  query: string,
  value: T[],
  filterPredicate: (qry: string, item: T) => boolean,
  isEqual: (qry: string, item: T) => boolean,
  options?: Options<T>
) {
  if (!options?.customItemBuilder) {
    if (!options?.staticItems) {
      throw Error(
        'staticItems is required if customItemBuilder is not provided'
      );
    }

    return options.staticItems.filter((item) => filterPredicate(query, item));
  }

  if (!options?.isCustomItem) {
    throw Error('isCustomItem is required if customItemBuilder is provided');
  }

  const items = options?.staticItems
    ? options?.staticItems.concat(value.filter(options.isCustomItem))
    : value;

  if (query === '') {
    return items;
  }

  const filtered = items.filter((item) => filterPredicate(query, item));

  if (items.some((item) => isEqual(query, item))) {
    return filtered;
  }

  return filtered.concat(options.customItemBuilder(query, items));
}

/**
 * @param query current text input
 * @param filterPredicate determines if an option should be displayed in the dropdown
 * @param isEqual determines if given query equals an option
 * @param value selected option
 * @param {Options} options additional configuration
 * @returns list of options to display in the dropdown
 */
export function getSelectItems<T>(
  query: string,
  filterPredicate: (qry: string, item: T) => boolean,
  isEqual: (qry: string, item: T) => boolean,
  value?: T | null,
  options?: Options<T>
) {
  if (!options?.customItemBuilder) {
    if (!options?.staticItems) {
      throw Error(
        'staticItems is required if customItemBuilder is not provided'
      );
    }

    return options.staticItems.filter((item) => filterPredicate(query, item));
  }

  if (!options?.isCustomItem) {
    throw Error('isCustomItem is required if customItemBuilder is provided');
  }

  const staticItems = options?.staticItems || [];

  const items =
    value && options.isCustomItem(value)
      ? staticItems.concat(value)
      : staticItems;

  if (query === '') {
    return items;
  }

  const filtered = items.filter((item) => filterPredicate(query, item));

  if (value && isEqual(query, value)) {
    return filtered;
  }

  return filtered.concat(options.customItemBuilder(query, items));
}
