/* eslint-disable max-lines */
import {
  getCallDetailsNode,
  getManualTalkdownDetailsNode,
  getManualTalkdownModalContentNode,
} from '@hakimo-ui/hakimo/feature-shared';
import {
  EscalationEventsDTO,
  EscalationEventStatus,
  EscalationStatus,
  EscalationUpdateType,
  ScanEscalation,
} from '@hakimo-ui/hakimo/types';
import {
  callMadeIcon,
  checkTimelineIcon,
  commentTimelineIcon,
  hakimoTimelineIcon,
  inProgressTimelineIcon,
  pendingTimelineIcon,
  speakerTalkdownTimelineIcon,
  TimelineEvent,
} from '@hakimo-ui/hakimo/ui-elements';

import { LabelType, Selectable } from '@hakimo-ui/shared/ui-base';

type TimelineEventSortable = TimelineEvent & { timestamp: number };
const HAKIMO_USERNAME = 'Hakimo AI Engine';
const SPEAKER_TALKDOWN_EVENT = 'Responded via speaker talk-down';

export interface UpdateObject {
  user?: Selectable;
  status?: EscalationEventStatus;
  event?: string;
  comment?: string;
  twilio_ssid?: string;
  twilio_calling_to_name?: string;
}

export function mapStatusTypeToLabelType(
  statusType: EscalationStatus
): LabelType {
  switch (statusType) {
    case 'Resolved':
      return 'success';
    case 'In Progress':
      return 'warning';
    case 'Pending':
      return 'error';
  }
}

export function getTimelineIcon(update: UpdateObject): JSX.Element {
  if (update.user?.name === HAKIMO_USERNAME || update.user === null) {
    return hakimoTimelineIcon;
  }
  if (update.status) {
    switch (update.status) {
      case EscalationEventStatus.RESOLVED:
        return checkTimelineIcon;
      case EscalationEventStatus.IN_PROGRESS:
        return inProgressTimelineIcon;
      case EscalationEventStatus.PENDING:
        return pendingTimelineIcon;
    }
  }
  switch (update.event) {
    case SPEAKER_TALKDOWN_EVENT:
    case EscalationUpdateType.MANUAL_TALKDOWN:
      return speakerTalkdownTimelineIcon;
    case EscalationUpdateType.CALL_SMS_LOGS:
      return callMadeIcon;
    default:
      return commentTimelineIcon;
  }
}

export const getEventDescription = (
  update: UpdateObject,
  assumeHakimo = false
) => {
  if (update.event === EscalationUpdateType.MANUAL_TALKDOWN) {
    if (assumeHakimo) {
      return `${
        update.user?.name ?? HAKIMO_USERNAME
      } responded via speaker talk-down`;
    } else if (update.user?.name !== undefined) {
      return `${update.user?.name} responded via speaker talk-down`;
    }
    return `Speaker talk-down conducted`;
  }
  if (update.event === EscalationUpdateType.CALL_SMS_LOGS) {
    return update.twilio_ssid
      ? getCallDetailsNode(
          update.twilio_ssid,
          update.twilio_calling_to_name,
          update.user?.name
        )
      : 'Twilio SSID not found';
  }
  return `${update.user?.name} added feedback: ${update.comment}`;
};

export const getEscalationEvents = (
  escalation: ScanEscalation,
  updates: EscalationEventsDTO
) => {
  const events: TimelineEventSortable[] = [];
  const talkdwownsState = updates.talkdowns || [];
  const escalationEvents = updates.items || [];

  //push creation of escalation
  events.push({
    icon: hakimoTimelineIcon,
    description: 'Escalation created',
    time: new Date(escalation.createdAt).toLocaleString(),
    timestamp: new Date(escalation.createdAt).getTime(), // handling edge case when there is only one alarm but need to show creation update first
  });

  events.push(
    ...escalationEvents.map<TimelineEventSortable>((update) => {
      const updateObj: UpdateObject = {
        user: update.user,
        status: update.update_status,
        event: update.update_type,
        comment: update.update_text,
        twilio_ssid: update.twilio_ssid,
        twilio_calling_to_name: update.twilio_calling_to_name,
      };

      const date = new Date(update.update_time);

      const ret: TimelineEventSortable = {
        icon: getTimelineIcon(updateObj),
        description: getEventDescription(updateObj, true),
        time: date.toLocaleString(),
        timestamp: date.getTime(),
        audio_url: update.audio_url,
      };

      if (update.update_type === EscalationUpdateType.MANUAL_TALKDOWN) {
        ret['extraDetails'] = getManualTalkdownDetailsNode(
          update,
          talkdwownsState
        );
        ret['extraDetailsModalContent'] = getManualTalkdownModalContentNode(
          update,
          talkdwownsState
        );
      }
      return ret;
    })
  );
  return events
    .sort((a, b) => b.timestamp - a.timestamp)
    .map((e) => ({
      icon: e.icon,
      description: e.description,
      time: e.time,
      audio_url: e.audio_url,
      extraDetails: e.extraDetails,
      extraDetailsModalContent: e.extraDetailsModalContent,
    }));
};
