import { UpsertLocationAlarmIncidentDTO } from '@hakimo-ui/hakimo/types';
import useAuthenticatedMutation from '../shared/useAuthenticatedMutation';

export function useCreateLocationAlarmIncident(
  onSuccess?: () => void,
  onError?: (err: Error) => void
) {
  const url = `/v2/orm/location_incidents/`;

  const request = new Request(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
  });

  return useAuthenticatedMutation<null, UpsertLocationAlarmIncidentDTO | null>(
    request,
    {
      onSuccessInvalidationKeys: [['locationAlarms']],
      onSuccess,
      onError,
    }
  );
}

export default useCreateLocationAlarmIncident;
