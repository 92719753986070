import { UserListFilters } from '@hakimo-ui/hakimo/types';
import { SlideOver } from '@hakimo-ui/hakimo/ui-elements';
import FilterPanelContent from './FilterPanelContent';
interface Props {
  open: boolean;
  filters: UserListFilters;
  onClose: () => void;
  onApplyFilters: (filters: UserListFilters) => void;
}

function FilterPanel(props: Props) {
  const { open, onClose, onApplyFilters, filters } = props;
  return (
    <SlideOver title="User Filters" open={open} onClose={onClose}>
      <FilterPanelContent filters={filters} onApplyFilters={onApplyFilters} />
    </SlideOver>
  );
}

export default FilterPanel;
