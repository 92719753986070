import { InputField, Radio, Selectable } from '@hakimo-ui/shared/ui-base';
import { armStatusRadioItems } from './util';

export interface FormValue {
  status: Selectable;
  startTime: string;
  endTime: string;
}

interface Props {
  value: FormValue;
  minDate: string; // YYYY-MM-DDTHH:mm
  onChange: (value: FormValue) => void;
}

function ArmedStatusForm(props: Props) {
  const { value, minDate, onChange } = props;
  const { status, startTime, endTime } = value;

  return (
    <div className="space-y-4">
      <Radio
        label="Status"
        items={armStatusRadioItems}
        id={(item) => item.id}
        displayValue={(item) => item.name}
        onChange={(v) => onChange({ ...value, status: v })}
        value={status}
      />
      <InputField
        type="datetime-local"
        label="Start Time"
        value={startTime}
        min={minDate}
        onChange={(e) => onChange({ ...value, startTime: e.target.value })}
      />
      <InputField
        type="datetime-local"
        label="End Time"
        value={endTime}
        min={startTime}
        onChange={(e) => onChange({ ...value, endTime: e.target.value })}
      />
    </div>
  );
}

export default ArmedStatusForm;
