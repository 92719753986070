/* eslint-disable max-lines */
import { PlaybackMotionMarkers } from '@hakimo-ui/hakimo/types';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { ZoomModeTickFactor } from '../constants';
dayjs.extend(utc);
dayjs.extend(timezone);

export type ShownDates = {
  startDate: Date;
  endDate: Date;
};

export type Tick = {
  text: string;
  date: string;
  isMidnight: boolean;
};

export enum ZoomMode {
  DAILY = 'daily',
  WEEKLY = 'weekly',
  THREE_DAY = '3day',
}

export const getTicks = (
  startDate: Date,
  endDate: Date,
  zoomMode: ZoomMode,
  timeZone: string
): Tick[] => {
  const allTicks = [];
  const currentTick = new Date(startDate);
  let safetyCounter = 0;
  while (currentTick < endDate) {
    safetyCounter++;
    if (safetyCounter > 100) break;
    const currentDay = dayjs(currentTick).tz(timeZone);
    let hours = +currentDay.format('HH');
    const minutes = +currentDay.format('mm');
    const period = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12;

    allTicks.push({
      text: `${hours}${minutes > 0 ? `:${minutes}` : ''} ${period}`,
      date: currentTick.toLocaleDateString('en-US', {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
      }),
      isMidnight: hours === 12 && period === 'AM' && minutes === 0,
    });
    zoomMode === ZoomMode.DAILY &&
      currentTick.setMinutes(currentTick.getMinutes() + 30);
    zoomMode === ZoomMode.THREE_DAY &&
      currentTick.setHours(currentTick.getHours() + 1);
    zoomMode === ZoomMode.WEEKLY &&
      currentTick.setHours(currentTick.getHours() + 2);
  }
  return allTicks;
};

export const getTimeStampFromX = (
  startDate: Date,
  x: number,
  tickGap: number,
  zoomMode: ZoomMode
) => {
  return new Date(
    startDate.getTime() +
      (x / tickGap) *
        (24 / ZoomModeTickFactor[zoomMode].perDayTicks) *
        60 *
        60 *
        1000
  );
};

export const getXFromTimestamp = (
  startDate: Date,
  tickGap: number,
  zoomMode: ZoomMode,
  timestamp: Date
) => {
  const diff = timestamp.getTime() - startDate.getTime();
  const position =
    (tickGap * diff) /
    (ZoomModeTickFactor[zoomMode].perTickEquivalentHours * 60 * 60 * 1000);
  return position;
};

export const adjustStartAndEndDates = (
  startDiff: number,
  endDiff: number,
  sectionStartTime: Date,
  sectionEndTime: Date,
  timelineStartDate: Date,
  timelineEndDate: Date,
  timeZone: string
) => {
  let timelineStartDay = dayjs(timelineStartDate.toString()).tz(timeZone);
  timelineStartDay = timelineStartDay
    .set('hour', 0)
    .set('minute', 0)
    .set('second', 0)
    .set('millisecond', 0);
  const timelineEndDay = dayjs(timelineEndDate.toString()).tz(timeZone);

  let sectionStart = dayjs(sectionStartTime.toString()).tz(timeZone);
  sectionStart = sectionStart
    .set('hour', 0)
    .set('minute', 0)
    .set('second', 0)
    .set('millisecond', 0);

  let sectionEnd = dayjs(sectionEndTime.toString()).tz(timeZone);
  sectionEnd = sectionEnd
    .set('hour', 23)
    .set('minute', 59)
    .set('second', 0)
    .set('millisecond', 0);

  sectionStart = sectionStart.subtract(startDiff, 'day');
  sectionEnd = sectionEnd.add(endDiff, 'day');

  if (sectionEnd.unix() > timelineEndDay.unix()) {
    const diffFromEnd = sectionEnd.diff(timelineEndDay, 'day');
    sectionEnd = timelineEndDay;
    sectionStart = sectionStart.subtract(diffFromEnd, 'day');
  } else if (sectionStart.unix() < timelineStartDay.unix()) {
    const diffFromStart = timelineStartDay.diff(sectionStart, 'day');
    sectionStart = timelineStartDay;
    sectionEnd = sectionEnd.add(diffFromStart, 'day');
  }

  return [sectionStart.toDate(), sectionEnd.toDate()];
};

export const getShownDates = (
  zoomMode: ZoomMode,
  startDate: Date,
  endDate: Date,
  seekerTime: Date,
  timeZone: string
) => {
  let zoomStartDate = new Date(seekerTime);
  let zoomEndDate = new Date(seekerTime);

  if (zoomMode === ZoomMode.DAILY) {
    let sectionStart = dayjs(zoomStartDate.toString()).tz(timeZone);
    sectionStart = sectionStart
      .set('hour', 0)
      .set('minute', 0)
      .set('second', 0)
      .set('millisecond', 0);

    zoomStartDate = sectionStart.toDate();

    let sectionEnd = dayjs(zoomEndDate.toString()).tz(timeZone);
    sectionEnd = sectionEnd
      .set('hour', 23)
      .set('minute', 59)
      .set('second', 0)
      .set('millisecond', 0);
    zoomEndDate = sectionEnd.toDate();

    zoomEndDate = endDate < zoomEndDate ? endDate : zoomEndDate;
  } else if (zoomMode === ZoomMode.THREE_DAY) {
    [zoomStartDate, zoomEndDate] = adjustStartAndEndDates(
      1,
      1,
      zoomStartDate,
      zoomEndDate,
      startDate,
      endDate,
      timeZone
    );
  } else if (zoomMode === ZoomMode.WEEKLY) {
    [zoomStartDate, zoomEndDate] = adjustStartAndEndDates(
      3,
      3,
      zoomStartDate,
      zoomEndDate,
      startDate,
      endDate,
      timeZone
    );
  }

  return {
    startDate: zoomStartDate,
    endDate: zoomEndDate,
  };
};

export const getFactor = (
  tickIndex: number,
  totalTicks: number,
  mode: ZoomMode
) => {
  if (
    tickIndex + 1 > totalTicks - ZoomModeTickFactor[mode].perDayTicks ||
    totalTicks < ZoomModeTickFactor[mode].perDayTicks // last midnight tick
  ) {
    const remainingTicks = totalTicks - tickIndex + 1;
    if (
      (mode === ZoomMode.WEEKLY || mode === ZoomMode.THREE_DAY) &&
      remainingTicks <= 5
    ) {
      return ZoomModeTickFactor[mode].perDayTicks;
    }
    return remainingTicks;
  }
  return ZoomModeTickFactor[mode].perDayTicks;
};

export const getMotionMarkers = (
  shownStartDate: Date,
  shownEndDate: Date,
  playbackMotionMarkers: PlaybackMotionMarkers,
  tickGap: number,
  zoomMode: ZoomMode
) => {
  const shownStartTime = shownStartDate.getTime();
  const shownEndTime = shownEndDate.getTime();
  let allMarkers: Array<{ start: number; end: number }> = [];
  allMarkers = playbackMotionMarkers.reduce((acc, marker) => {
    const markerStart = marker[0];
    const markerEnd = marker[1];
    if (markerStart >= shownStartTime && markerEnd <= shownEndTime) {
      acc.push({
        start: getXFromTimestamp(
          shownStartDate,
          tickGap,
          zoomMode,
          new Date(markerStart)
        ),
        end: getXFromTimestamp(
          shownStartDate,
          tickGap,
          zoomMode,
          new Date(markerEnd)
        ),
      });
    }
    return acc;
  }, allMarkers);
  return allMarkers;
};
