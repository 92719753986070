/* eslint-disable max-lines */
import { useUpdateSOP } from '@hakimo-ui/hakimo/data-access';
import { SOPWorkflow } from '@hakimo-ui/hakimo/types';
import { toast } from '@hakimo-ui/hakimo/util';
import { Alert, Button, Tooltip } from '@hakimo-ui/shared/ui-base';
import {
  InformationCircleIcon,
  PencilSquareIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import { useEffect, useState } from 'react';
import {
  SOPWorkflowFormItemsType,
  getFormData,
  getInitialData,
  getWorkflowFormItems,
  validateFormData,
} from './utils';

interface Props {
  sopId: string;
  sopWorkflow?: SOPWorkflow;
  showTitle?: boolean;
}

export function SOPWorkflowView(props: Props) {
  const { sopWorkflow, sopId, showTitle = true } = props;
  const [isEditing, setIsEditing] = useState(false);
  const [updatedSOPWorkflow, setUpdatedSOPWorkflow] = useState<
    SOPWorkflow | undefined
  >(sopWorkflow);
  const [validationState, setValidationState] = useState({
    isValid: true,
    message: '',
  });
  const onSuccess = () => {
    setIsEditing(false);
    toast('SOP workflow updated successfully', { type: 'success' });
  };

  const mutation = useUpdateSOP(sopId, onSuccess);

  useEffect(() => {
    if (!isEditing) {
      setUpdatedSOPWorkflow(sopWorkflow);
    }
  }, [isEditing, sopWorkflow]);

  const toggleEditing = () => setIsEditing((prev) => !prev);

  const onSubmit = () => {
    const { isValid, message } = validateFormData(updatedSOPWorkflow);

    if (isValid) {
      setValidationState({ isValid, message: '' });
    } else {
      setValidationState({ isValid, message: message || '' });
      return;
    }

    const payload = {
      sop_workflow: updatedSOPWorkflow,
    };
    mutation.mutate({ sop_text: JSON.stringify(payload) });
  };

  const onCancelEdit = () => setIsEditing(false);

  const enableWorkflow = () => {
    const initData = getInitialData();
    setUpdatedSOPWorkflow(initData);
    setIsEditing(true);
  };

  if (!updatedSOPWorkflow) {
    return (
      <div className="pl-4">
        {showTitle && (
          <div className="border-onlight-line-2 dark:border-ondark-line-2 mb-2 border-b p-2 pb-3.5 font-bold">
            Standard Operating procedure (SOP) Workflow
          </div>
        )}
        <div className="mt-16 flex flex-col items-center justify-center gap-4">
          <div>SOP workflow is not enabled for this location.</div>
          {showTitle && (
            <Button variant="primary" onClick={enableWorkflow}>
              Enable Workflow
            </Button>
          )}
        </div>
      </div>
    );
  }

  const formData = getFormData(isEditing, updatedSOPWorkflow, sopWorkflow);

  return (
    <div className="w-full p-2">
      {showTitle && (
        <div className="border-onlight-line-2 dark:border-ondark-line-2 mb-2 flex items-center justify-between border-b p-2">
          <span className="font-bold">
            Standard Operating procedure (SOP) Workflow
          </span>
          <Button onClick={toggleEditing} variant="icon">
            {isEditing ? (
              <XMarkIcon className="h-5 w-5" />
            ) : (
              <span className="flex gap-2">
                <PencilSquareIcon className="h-5 w-5" />
                Edit
              </span>
            )}
          </Button>
        </div>
      )}
      {mutation.isError && <Alert type="error">{mutation.error.message}</Alert>}
      {!validationState.isValid && (
        <Alert type="warning">{validationState.message}</Alert>
      )}
      <div className="flex flex-col gap-4 p-4">
        {formData.map((formItem: SOPWorkflowFormItemsType) => (
          <div key={formItem.id} className="grid grid-cols-2 gap-4">
            <div className="flex items-center gap-2">
              <span className="font-bold">{formItem.title}:</span>
              {formItem.info && (
                <Tooltip text={formItem.info} size="large" colorModifier="info">
                  <InformationCircleIcon className="h-5 w-5" />
                </Tooltip>
              )}
            </div>
            {getWorkflowFormItems(
              isEditing,
              formItem.id,
              formItem.inputType,
              updatedSOPWorkflow,
              setUpdatedSOPWorkflow,
              sopWorkflow
            )}
          </div>
        ))}
        {isEditing && (
          <div className="border-onlight-line-2 dark:border-ondark-line-2 flex gap-4 border-t px-2 py-4">
            <Button
              variant="primary"
              onClick={onSubmit}
              loading={mutation.isLoading}
            >
              Submit
            </Button>
            <Button onClick={onCancelEdit}>Cancel</Button>
          </div>
        )}
      </div>
    </div>
  );
}

export default SOPWorkflowView;
