import {
  EventsViewIcon,
  LiveViewIcon,
  MenuDropdown,
  SplitViewIcon,
} from '@hakimo-ui/hakimo/ui-elements';
import { useCanShareLocationAlarm } from '@hakimo-ui/hakimo/util';
import {
  EllipsisVerticalIcon,
  LinkIcon,
  ShareIcon,
} from '@heroicons/react/24/solid';
import { ViewMode } from '../types';

interface Props {
  viewMode: ViewMode;
  onCopyLink: () => void;
  onShare: () => void;
  onChangeViewMode: (mode: ViewMode) => void;
}

function AlarmMenu(props: Props) {
  const { viewMode, onCopyLink, onShare, onChangeViewMode } = props;
  const alarmMenuOptions = [
    {
      dataTestId: 'btn-share-location-alarm',
      name: 'Copy link',
      onClick: onCopyLink,
      icon: <LinkIcon className="h-4 w-4" />,
    },
    {
      dataTestId: 'btn-show-split-view',
      name: 'Split view',
      onClick: () => onChangeViewMode('split'),
      group: 'viewMode',
      icon: <SplitViewIcon className="h-4 w-4 flex-shrink-0" />,
    },
    {
      dataTestId: 'btn-show-events-view',
      name: 'Events view',
      onClick: () => onChangeViewMode('events'),
      group: 'viewMode',
      icon: <EventsViewIcon className="h-4 w-4 flex-shrink-0" />,
    },
    {
      dataTestId: 'btn-show-live-view',
      name: 'Live view',
      onClick: () => onChangeViewMode('live'),
      group: 'viewMode',
      icon: <LiveViewIcon className="h-4 w-4 flex-shrink-0" />,
    },
  ];

  const canShareLocationAlarm = useCanShareLocationAlarm();
  if (canShareLocationAlarm) {
    const shareAlarmAction = {
      dataTestId: 'btn-public-share-location-alarm',
      name: 'Share alarm',
      onClick: onShare,
      icon: <ShareIcon className="h-4 w-4" />,
    };
    alarmMenuOptions.splice(1, 0, shareAlarmAction);
  }

  const filteredOptions = alarmMenuOptions.filter(
    (item) => !item.dataTestId.includes(viewMode)
  );

  const menuButton = (
    <EllipsisVerticalIcon className="h-5 w-5" data-testid="menu-button" />
  );

  return (
    <span className="mr-1">
      <MenuDropdown items={filteredOptions} menuButton={menuButton} />
    </span>
  );
}

export default AlarmMenu;
