import { RadioGroup } from '@headlessui/react';
import clsx from 'clsx';

interface Props<T> {
  items: T[];
  value?: T;
  label?: string;
  onChange: (value: T) => void;
  displayValue: (item: T) => string;
  id?: (item: T) => string;
}

export function Radio<T>(props: Props<T>) {
  const { value, items, label, onChange, displayValue, id } = props;

  return (
    <div>
      {label && (
        <label className="dark:text-dark-secondary-text mb-1 block text-sm text-gray-700">
          {label}
        </label>
      )}
      <RadioGroup value={value} onChange={onChange}>
        <div className="dark:bg-dark-bg relative -space-y-px rounded-md bg-white">
          {items.map((item, idx) => (
            <RadioGroup.Option
              key={id ? id(item) : idx}
              value={item}
              className={({ checked }) =>
                clsx(
                  idx === 0 ? 'rounded-tl-md rounded-tr-md' : '',
                  idx === items.length - 1 ? 'rounded-bl-md rounded-br-md' : '',
                  checked
                    ? 'border-primary-200 bg-primary-50 dark:bg-primary-900/30 dark:border-primary-800'
                    : 'border-gray-200 dark:border-gray-600',
                  'relative flex w-full cursor-pointer flex-col border p-4 focus:outline-none'
                )
              }
            >
              {({ active, checked }) => (
                <span className="flex items-center text-sm">
                  <span
                    className={clsx(
                      checked
                        ? 'bg-primary-600 dark:bg-primary-700 border-transparent'
                        : 'dark:bg-dark-bg border-gray-300 bg-white dark:border-gray-700',
                      active
                        ? 'ring-primary-500 dark:ring-primary-700 ring-2 ring-offset-2 dark:ring-offset-gray-900'
                        : '',
                      'flex h-4 w-4 items-center justify-center rounded-full border'
                    )}
                    aria-hidden="true"
                  >
                    <span className="dark:bg-dark-bg h-1.5 w-1.5 rounded-full bg-white" />
                  </span>
                  <RadioGroup.Label
                    as="span"
                    className={clsx(
                      checked
                        ? 'text-primary-900 dark:text-primary-200'
                        : 'dark:text-dark-text text-gray-900',
                      'ml-3 font-medium'
                    )}
                  >
                    {displayValue(item)}
                  </RadioGroup.Label>
                </span>
              )}
            </RadioGroup.Option>
          ))}
        </div>
      </RadioGroup>
    </div>
  );
}

export default Radio;
