import { useLocationsByName } from '@hakimo-ui/hakimo/data-access';
import { LocationFilterValue } from '@hakimo-ui/hakimo/types';
import {
  FilterFooter,
  FilterSection,
  MultiSelectFilter,
} from '@hakimo-ui/hakimo/ui-filters';

interface Props {
  locationFilterValue: LocationFilterValue;
  onChangeLocationFilterValue: (value: LocationFilterValue) => void;
  onApplyFilters: () => void;
  onResetFilters: () => void;
}

function FilterPanelContent(props: Props) {
  const {
    locationFilterValue,
    onChangeLocationFilterValue,
    onApplyFilters,
    onResetFilters,
  } = props;

  const getLocationsByName = useLocationsByName();

  return (
    <div className="space-y-6">
      <div className="dark:divide-dark-border-bg dark:border-dark-border-bg divide-y border-t border-b">
        <FilterSection
          title="Location"
          content={
            <MultiSelectFilter
              value={locationFilterValue}
              label="Name"
              onChange={onChangeLocationFilterValue}
              enableNegativeFilters
              getOptions={getLocationsByName}
            />
          }
          hasFilterApplied={locationFilterValue.values.length > 0}
        />
      </div>
      <FilterFooter
        onApply={onApplyFilters}
        onReset={onResetFilters}
        trackingEventNameApply="apply_locations_filters"
        trackingEventNameReset="reset_locations_filters"
      />
    </div>
  );
}

export default FilterPanelContent;
