import { Route, Routes } from 'react-router-dom';
import AuditLogList from './audit-log-list/AuditLogList';

export function AuditLogRoutes() {
  return (
    <Routes>
      <Route path="/" element={<AuditLogList />} />
    </Routes>
  );
}

export default AuditLogRoutes;
