/*eslint-disable max-lines*/
import {
  useGetAlarmNotificationPreference,
  useTenantDetails,
} from '@hakimo-ui/hakimo/data-access';
import { MiniAudioPlayer } from '@hakimo-ui/hakimo/feature-shared';
import { AlarmNotification, Tenant } from '@hakimo-ui/hakimo/types';
import { TableData } from '@hakimo-ui/hakimo/ui-table';
import {
  DEFAULT_AUDIO_NOTIF_FILE,
  useCanUpdateAlarmNotifications,
  useUser,
  withAuthz,
  withErrorBoundary,
} from '@hakimo-ui/hakimo/util';
import { Alert, Button, HakimoSpinner } from '@hakimo-ui/shared/ui-base';
import { useCallback, useEffect, useState } from 'react';
import AlarmNotifConfigTable from './AlarmNotifConfigTable';
import {
  NEW_CONFIGURATION_ID,
  columns,
  getAlarmNotificationPreferenceFromPayload,
  getDisabledAlarmsForEachRow,
  getSelectedNotificationSounds,
  getTableData,
} from './utils';

export function AlarmNotifications() {
  const user = useUser();
  const tenantId = user.tenant.id;
  const [hasAlarmTypes, setHasAlarmTypes] = useState(false);
  const onSuccess = (tenant: Tenant) => {
    if (tenant.alarmProcessingConfig?.alarm_types) {
      setHasAlarmTypes(true);
    } else {
      setHasAlarmTypes(false);
    }
  };

  useTenantDetails(tenantId, onSuccess);

  const { isLoading, isError, error, data } =
    useGetAlarmNotificationPreference();
  const [initialValue, setInitialValue] = useState<AlarmNotification[]>([]);
  const [updatedData, setUpdatedData] = useState<AlarmNotification[]>([]);
  const [isEditing, setIsEditing] = useState(false);
  const [editingRow, setEditingRow] = useState<string>('');
  const [isAllNotifSoundSelected, setIsAllNotifSoundsSelected] =
    useState(false);
  const [disabledTypesPerRow, setDisabledTypesPerRow] = useState<
    Record<string, string[]>
  >({});
  const [selectedNotificationSounds, setSelectedNotificationSounds] = useState<
    string[]
  >([]);
  const canUpdate = useCanUpdateAlarmNotifications();

  const [tableData, setTableData] = useState<TableData>({
    columns,
    rows: [],
  });

  const onClickAdd = () => {
    setEditingRow(NEW_CONFIGURATION_ID);
    setIsEditing(true);
    setUpdatedData((prevModifyData) => [
      ...prevModifyData,
      {
        id: NEW_CONFIGURATION_ID,
        alarmTypes: [],
        notificationSound: '',
      },
    ]);
  };

  const tableHeader = (
    <div className="m-4 flex justify-start">
      <Button
        variant="primary"
        onClick={onClickAdd}
        disabled={isEditing || isAllNotifSoundSelected}
        title={
          isAllNotifSoundSelected
            ? 'All notification sounds have been selected. Edit below to configure.'
            : ''
        }
      >
        Add Custom Configuration
      </Button>
    </div>
  );

  const onClickEdit = useCallback((id: string) => {
    setEditingRow(id);
    setIsEditing(true);
  }, []);

  const onCancelEdit = useCallback(() => {
    setIsEditing(false);
    setUpdatedData(initialValue);
  }, [initialValue]);

  const onChangeSingleAlarmNotification = useCallback(
    (
      newSingleAlarmNotificationSound: string[],
      id: string,
      newNotificationSound: string
    ) => {
      setUpdatedData(() => {
        return updatedData.map((item) => {
          if (item.id === id) {
            return {
              ...item,
              alarmTypes: newSingleAlarmNotificationSound,
              notificationSound: newNotificationSound,
            };
          }
          return item;
        });
      });
    },
    [updatedData]
  );
  useEffect(() => {
    if (data) {
      const alarmNotificationData =
        getAlarmNotificationPreferenceFromPayload(data);
      setInitialValue(alarmNotificationData);
      setUpdatedData(alarmNotificationData);
      setDisabledTypesPerRow(
        getDisabledAlarmsForEachRow(alarmNotificationData)
      );
      const { isSelected, selectedNotifSound } = getSelectedNotificationSounds(
        alarmNotificationData
      );
      setIsAllNotifSoundsSelected(isSelected);
      setSelectedNotificationSounds(selectedNotifSound);
    }
  }, [data]);

  useEffect(() => {
    setTableData(
      getTableData(
        updatedData,
        canUpdate,
        onClickEdit,
        onChangeSingleAlarmNotification,
        editingRow,
        onCancelEdit,
        disabledTypesPerRow,
        isEditing,
        selectedNotificationSounds
      )
    );
  }, [
    initialValue,
    updatedData,
    canUpdate,
    onClickEdit,
    onChangeSingleAlarmNotification,
    editingRow,
    onCancelEdit,
    disabledTypesPerRow,
    isEditing,
    selectedNotificationSounds,
  ]);

  return (
    <div className="flex flex-col gap-4 p-10">
      {isError ? (
        <Alert type="error">{error.message}</Alert>
      ) : isLoading ? (
        <div className="flex h-24 items-center justify-center">
          <HakimoSpinner />
        </div>
      ) : (
        <>
          <Alert type="info">
            <div className="flex flex-row gap-4">
              <span>
                Default sound for all alarm type is: {DEFAULT_AUDIO_NOTIF_FILE}
              </span>
              <div className="w-24 cursor-pointer">
                <MiniAudioPlayer
                  src={`assets/notif-sounds/${DEFAULT_AUDIO_NOTIF_FILE}.mp3`}
                  isLoading={false}
                />
              </div>
            </div>
          </Alert>
          {hasAlarmTypes && (
            <div className="dark:bg-dark-bg dark:border-ondark-line-1 border-onlight-line-3 m-8 mx-4 min-h-0 justify-start border sm:-mx-6 md:mx-0 md:rounded-lg">
              <AlarmNotifConfigTable
                data={tableData}
                header={canUpdate && tableHeader}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
}
export default withAuthz(withErrorBoundary(AlarmNotifications), [
  'alarm/notifications:view',
]);
