/* eslint-disable max-lines */
import {
  DndContext,
  DragOverlay,
  UniqueIdentifier,
  useDroppable,
} from '@dnd-kit/core';
import { useUploadFloorplan } from '@hakimo-ui/hakimo/data-access';
import { toast } from '@hakimo-ui/hakimo/util';
import { Alert, Button, Modal } from '@hakimo-ui/shared/ui-base';
import { TrashIcon } from '@heroicons/react/24/outline';
import React, { useState } from 'react';

interface DroppableProps {
  id: UniqueIdentifier;
  children: React.ReactNode;
  onFileUpload: (file: File) => void;
}

const MAX_FILE_SIZE = 10;

const Droppable: React.FC<DroppableProps> = ({
  id,
  children,
  onFileUpload,
}) => {
  const { setNodeRef } = useDroppable({
    id,
  });

  const handleDrop = (
    event: React.DragEvent<HTMLDivElement> | React.ChangeEvent<HTMLInputElement>
  ) => {
    const files =
      event.type === 'drop'
        ? (event as React.DragEvent<HTMLDivElement>).dataTransfer.files
        : (event as React.ChangeEvent<HTMLInputElement>).target.files;
    if (files) {
      onFileUpload(files[0]);
    }
  };

  return (
    <div
      ref={setNodeRef}
      onDrop={handleDrop as unknown as React.DragEventHandler<HTMLDivElement>}
      className="border-onlight-line-2 dark:border-ondark-line-2 flex h-64 cursor-pointer items-center justify-center border-4 border-dashed"
    >
      <input
        type="file"
        onChange={handleDrop}
        accept="image/*"
        className="absolute h-64 cursor-pointer opacity-0"
      />
      {children}
    </div>
  );
};

interface UploadFloorplanModalProps {
  locationId: string;
  locationName: string;
  onClose: () => void;
}

const UploadFloorplanModal = (props: UploadFloorplanModalProps) => {
  const { onClose, locationId, locationName } = props;
  const [uploadedFile, setUploadedFile] = useState<File>();

  const onSuccess = () => {
    toast('Floorplan uploaded successfully');
    onClose();
  };

  const mutation = useUploadFloorplan(locationId, onSuccess);

  const onFileUpload = (file: File) => setUploadedFile(file);

  const onSubmitFloorplan = () => {
    const formData = new FormData();
    formData.append('floorplan', uploadedFile || '');
    mutation.mutate(formData);
  };

  const getFileSizeInMB = (file: File) => {
    const sizeInBytes = file.size;
    const sizeInKB = sizeInBytes / 1024;
    const sizeInMB = sizeInKB / 1024;
    return sizeInMB;
  };

  const isUploadedFileValid = uploadedFile
    ? getFileSizeInMB(uploadedFile) < MAX_FILE_SIZE
    : false;

  const actions = (
    <>
      <Button
        disabled={!isUploadedFileValid}
        loading={mutation.isLoading}
        variant="primary"
        onClick={onSubmitFloorplan}
      >
        Submit
      </Button>
      <Button variant="outline" onClick={onClose}>
        Cancel
      </Button>
    </>
  );

  const onRemoveFile = () => setUploadedFile(undefined);

  return (
    <Modal
      open
      onClose={onClose}
      closable
      footer={actions}
      title={`Upload floorplan for ${locationName}`}
    >
      <div className="p-8">
        {mutation.isError && (
          <Alert type="error">{mutation.error.message}</Alert>
        )}
        {uploadedFile ? (
          <>
            {!isUploadedFileValid && (
              <Alert type="warning">
                File size should be less than {MAX_FILE_SIZE} MB
              </Alert>
            )}
            <div className="flex items-center gap-5 text-sm italic">
              <div className="space-y-2 text-center">
                <span>{uploadedFile.name}</span>
                <img
                  src={URL.createObjectURL(uploadedFile)}
                  alt="Floorplan preview"
                  className="h-60 w-60"
                />
              </div>
              <Button variant="icon" title="Remove file" onClick={onRemoveFile}>
                <TrashIcon className="h-5 w-5" />
              </Button>
            </div>
          </>
        ) : (
          <DndContext>
            <Droppable id="droppable" onFileUpload={onFileUpload}>
              <div className="text-onlight-text-3 dark:text-ondark-text-3 px-2 text-center text-sm">
                Drag 'n' drop the file here, or click to browse
              </div>
            </Droppable>
            <DragOverlay>
              <div className="bg-primary-500 p-2 text-white">
                Drop the files here...
              </div>
            </DragOverlay>
          </DndContext>
        )}
      </div>
    </Modal>
  );
};

export default UploadFloorplanModal;
