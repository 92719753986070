import { AlarmVideoDetails, AlarmVideoMarker } from '@hakimo-ui/hakimo/types';
import { Alert } from '@hakimo-ui/shared/ui-base';
import clsx from 'clsx';
import { createRef, useEffect, useState } from 'react';
import AlarmVideoFooter from './AlarmVideoFooter';
import { getTimeStr, onTimestampClick } from './util';

interface Props {
  videoPath: string;
  videoDetails?: AlarmVideoDetails;
  onErrorLoadingVideo: () => void;
}

const EVENT_INDICATOR_OFFSET = 1;

export function AlarmVideo(props: Props) {
  const { videoPath, videoDetails, onErrorLoadingVideo } = props;
  const [hasError, setHasError] = useState(false);
  const [activeMarker, setActiveMarker] = useState<AlarmVideoMarker>();
  const videoRef = createRef<HTMLVideoElement>();
  const timerRef = createRef<HTMLSpanElement>();
  const markers = videoDetails?.markers;

  const onErrorSource = () => {
    setHasError(true);
    onErrorLoadingVideo();
  };

  useEffect(() => {
    const videoElem = videoRef.current;

    if (!videoElem) {
      return;
    }

    const onTimeUpdate = () => {
      const timerElem = timerRef.current;

      if (timerElem && typeof videoDetails?.startTime === 'string') {
        timerElem.textContent = getTimeStr(
          videoDetails.startTime,
          videoElem.currentTime
        );
      }

      setActiveMarker(
        markers?.find(
          (marker) =>
            Math.abs(videoElem.currentTime - marker.timestamp) <
            EVENT_INDICATOR_OFFSET
        )
      );
    };

    videoElem.addEventListener('timeupdate', onTimeUpdate);

    return () => {
      videoElem.removeEventListener('timeupdate', onTimeUpdate);
    };
  }, [videoRef, markers, timerRef, videoDetails]);

  const onTimestampClickCb = (timestamp: number) => {
    const videoElem = videoRef.current;

    if (!videoElem) {
      return;
    }

    onTimestampClick(videoElem, timestamp);
  };

  const timer = (
    <span
      ref={timerRef}
      className="dark:text-dark-text mr-4 text-sm font-medium text-gray-900"
    />
  );

  return (
    <>
      {hasError ? (
        <div className="p-6">
          <Alert>Cannot play video</Alert>
        </div>
      ) : (
        <div className="relative">
          <video
            ref={videoRef}
            key={videoPath}
            controls
            className="dark:bg-dark-bg/70 before:border-hakimo-yellow max-h-[48rem] w-full bg-gray-200"
          >
            <source src={videoPath} type="video/mp4" onError={onErrorSource} />
            <Alert>Your browser cannot play the provided video file.</Alert>
          </video>
          {activeMarker && (
            <div
              className={clsx(
                'pointer-events-none absolute inset-1 z-10 border-4',
                activeMarker?.type === 'Warning' && 'border-hakimo-yellow',
                activeMarker?.type === 'Info' &&
                  'border-primary-800 dark:border-primary-200',
                activeMarker?.type === 'Error' &&
                  'border-red-800 dark:border-red-200'
              )}
            />
          )}
        </div>
      )}
      <AlarmVideoFooter
        markers={markers}
        activeMarker={activeMarker}
        onTimestampClick={onTimestampClickCb}
        timer={timer}
      ></AlarmVideoFooter>
    </>
  );
}

export default AlarmVideo;
