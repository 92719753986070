import { AddDoorsToDoorGroupRequestDTO } from '@hakimo-ui/hakimo/types';
import useAuthenticatedMutation from '../shared/useAuthenticatedMutation';

interface Options {
  doorGroupId: string;
  onSuccess: () => void;
}

export function useAddDoorsToDoorGroup(options: Options) {
  const url = `/v2/orm/door_groups/${options.doorGroupId}/doors`;

  const request = new Request(url, {
    method: 'PATCH',
  });

  return useAuthenticatedMutation<null, AddDoorsToDoorGroupRequestDTO>(
    request,
    {
      onSuccessInvalidationKeys: [['doors']],
      onSuccess: options.onSuccess,
    }
  );
}

export default useAddDoorsToDoorGroup;
