import { MiniAudioPlayer } from '@hakimo-ui/hakimo/feature-shared';
import { AlarmUpdate, TalkdownStatus } from '@hakimo-ui/hakimo/types';
import {
  CheckCircleIcon,
  ExclamationTriangleIcon,
  XCircleIcon,
} from '@heroicons/react/24/outline';
import dayjs from 'dayjs';

export function getTalkdownStatusIconNode(status: string) {
  let icon;
  switch (status) {
    case 'success':
    case TalkdownStatus.SUCCESS:
      icon = (
        <CheckCircleIcon className="h-5 w-5 text-green-400 dark:text-green-200" />
      );
      break;
    case 'warning':
      icon = (
        <ExclamationTriangleIcon className="h-5 w-5 text-yellow-400 dark:text-yellow-200" />
      );
      break;
    case 'error':
    case TalkdownStatus.FAILED:
      icon = <XCircleIcon className="h-5 w-5 text-red-400 dark:text-red-300" />;
      break;
    default:
      icon = null;
  }
  return icon;
}

interface BasicColumn {
  id: string;
  name: string;
}
export const talkdownDetailsModalAllColumns: BasicColumn[] = [
  {
    id: 'rawAlarmId',
    name: 'Raw Alarm Id',
  },
  {
    id: 'rawAlarmAddedTime',
    name: 'Raw Alarm Added Time',
  },
  {
    id: 'status',
    name: 'Talkdown Status',
  },
  {
    id: 'ingestedTalkdown',
    name: 'Relayed Talkdown',
  },
  {
    id: 'talkdownTime',
    name: 'Talkdown Completed Time',
  },
  {
    id: 'comments',
    name: 'Comments',
  },
];

export const getColumnNode = (columns: BasicColumn[]) => {
  return columns.map((col) => (
    <div
      key={col.id}
      className="dark:text-ondark-text-1 font-bold text-gray-600"
    >
      {col.name}
    </div>
  ));
};

export function getTalkdownExtraDetailsNode(update: AlarmUpdate) {
  const talkdownState = update?.talkdown?.[0];
  if (!talkdownState) {
    return null;
  }
  let message = '';
  const status = talkdownState.status;
  switch (status) {
    case TalkdownStatus.SUCCESS:
      message = 'Talkdown transmitted successfully';
      break;
    case TalkdownStatus.FAILED:
      message = 'Talkdown tranmission failed';
      break;
    case TalkdownStatus.PENDING:
      message = 'Talkdown yet to be relayed';
      break;
  }
  return (
    <div className="flex items-center gap-2 text-xs">
      {message}
      {getTalkdownStatusIconNode(status)}
    </div>
  );
}

export function getTalkdownExtraDetailsModalContent(update: AlarmUpdate) {
  const talkdownState = update?.talkdown?.[0];
  if (!talkdownState) {
    return null;
  }
  const columns = talkdownDetailsModalAllColumns.filter(
    (col) => !['rawAlarmId', 'rawAlarmAddedTime'].includes(col.id)
  );
  return (
    <div className="grid grid-cols-4 gap-2 text-sm">
      {getColumnNode(columns)}
      <span className="flex items-center gap-2">
        {getTalkdownStatusIconNode(talkdownState.status)}
        {talkdownState.status}
      </span>
      <span>
        {talkdownState.ingested_talkdown_audio ? (
          <span className="flex">
            <MiniAudioPlayer
              src={talkdownState.ingested_talkdown_audio}
              isLoading={false}
            />
          </span>
        ) : (
          'NA'
        )}
      </span>
      <span>
        {talkdownState.talkdown_completed_utc
          ? dayjs(new Date(talkdownState.talkdown_completed_utc)).format(
              'DD/MM/YYYY, HH:mm:ss'
            )
          : '-'}
      </span>

      <span>{talkdownState.custom_message || '-'}</span>
    </div>
  );
}
