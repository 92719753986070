import { useNeighboringAlarms } from '@hakimo-ui/hakimo/data-access';
import { ReducedAlarm } from '@hakimo-ui/hakimo/types';
import { QueryResult } from '@hakimo-ui/hakimo/ui-elements';
import { TableData } from '@hakimo-ui/hakimo/ui-table';
import { ReactElement, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { getTableData } from './table-data';

const REFETCH_INTERVAL = 30000;

export interface Props {
  alarm: ReducedAlarm;
  searchParams: string;
  children: (tableData: TableData) => ReactElement;
}

export function RegularAlarmNeighboringEvents(props: Props) {
  const { alarm, searchParams, children } = props;
  const navigate = useNavigate();

  const onClickRow = useCallback(
    (alarm_: ReducedAlarm, event: React.MouseEvent | React.KeyboardEvent) => {
      const url = `/alarms/${alarm_.id}`;
      const isNewTab = event.ctrlKey || event.metaKey;
      isNewTab
        ? window.open(url, '_blank', 'noopener,noreferrer')
        : navigate(url);
    },
    [navigate]
  );

  const queryResult = useNeighboringAlarms(
    alarm.id,
    searchParams,
    REFETCH_INTERVAL
  );

  return (
    <QueryResult queryResult={queryResult}>
      {(data) => children(getTableData(data, alarm, onClickRow))}
    </QueryResult>
  );
}

export default RegularAlarmNeighboringEvents;
