import { MultiSelectFilterValue } from '@hakimo-ui/hakimo/types';
import { MultiSelectAsync } from '@hakimo-ui/hakimo/ui-elements';
import { Label, Selectable, Toggle } from '@hakimo-ui/shared/ui-base';
import { EyeSlashIcon } from '@heroicons/react/24/solid';
import { ReactElement } from 'react';

interface Props {
  value: MultiSelectFilterValue;
  label: string;
  enableNegativeFilters?: boolean;
  debounceDelay?: number;
  onChange: (value: MultiSelectFilterValue) => void;
  getOptions: (query: string) => Promise<Selectable[]>;
}

// @TODO check if we can make it work for static options too
export function MultiSelectFilter(props: Props) {
  const {
    value,
    label,
    enableNegativeFilters = false,
    debounceDelay,
    onChange,
    getOptions,
  } = props;

  const onChangeValue = (vals: Selectable[]) => {
    onChange({ values: vals, negative: value.negative });
  };

  const onChangeNegative = (neg: boolean) => {
    onChange({ ...value, negative: neg });
  };

  const onRemoveSelected = (index: number) => {
    onChangeValue([
      ...value.values.slice(0, index),
      ...value.values.slice(index + 1),
    ]);
  };

  const displayValue = (item: Selectable | undefined) =>
    item ? item?.name : '';

  const getId = (item: Selectable) => item.id;

  const customSelectedRenderer = (items: Selectable[]): ReactElement => {
    return (
      <>
        {items.map((item, idx) => (
          <span key={getId(item) || idx} className="mr-1">
            <Label
              text={displayValue(item)}
              removable
              onClickRemove={() => onRemoveSelected(idx)}
              type={value.negative ? 'error' : 'default'}
            />
          </span>
        ))}
      </>
    );
  };

  return (
    <>
      {enableNegativeFilters && (
        <div className="pb-2">
          <Toggle
            enabled={value.negative}
            onChange={onChangeNegative}
            type="negative"
            EnabledIcon={EyeSlashIcon}
            label="Negative Filtering"
          />
        </div>
      )}
      <MultiSelectAsync
        value={value.values}
        label={label}
        debounceDelay={debounceDelay}
        onChange={onChangeValue}
        onChangeQuery={getOptions}
        displayValue={(item) => item?.name || ''}
        customSelectedRenderer={customSelectedRenderer}
        id={(item) => item.id}
      />
    </>
  );
}

export default MultiSelectFilter;
