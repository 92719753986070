import { Checkbox } from '@hakimo-ui/shared/ui-base';
import Cell from './Cell';
import { Column, RowSelection } from './types';

interface Props {
  column: Column;
}

export function HeaderCell(props: Props) {
  const { column } = props;

  return (
    <Cell
      type="header"
      breakpoint={column.showAtBreakpoint}
      align={column.align}
    >
      {column.name}
    </Cell>
  );
}

interface SelectableCellProps {
  rowIds: string[];
  rowSelection: RowSelection;
}

HeaderCell.Selectable = function (props: SelectableCellProps) {
  const { rowIds, rowSelection } = props;

  return (
    <Cell type="header">
      <Checkbox
        checked={
          rowIds.length > 0 && rowSelection.selected.length === rowIds.length
        }
        onChange={(checked) => {
          if (checked) {
            rowSelection.onChangeSelected(rowIds);
          } else {
            rowSelection.onChangeSelected([]);
          }
        }}
      />
    </Cell>
  );
};

export default HeaderCell;
