import { ReactNode } from 'react';

interface Props {
  children: ReactNode;
}

export function Centered(props: Props) {
  const { children } = props;

  return (
    <div className="flex h-full items-center justify-center">{children}</div>
  );
}

export default Centered;
