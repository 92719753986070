import { eventTracker } from '@hakimo-ui/hakimo/util';
import { Button } from '@hakimo-ui/shared/ui-base';
import { FunnelIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';

interface Props {
  canAddAudioDevice: boolean;
  isLoading: boolean;
  onClickAddAudioDevice: () => void;
  hasCustomFiltersApplied: boolean;
  onClickFilter: () => void;
}

export function TableHeader(props: Props) {
  const {
    canAddAudioDevice,
    isLoading,
    onClickAddAudioDevice,
    hasCustomFiltersApplied,
    onClickFilter,
  } = props;

  return (
    <div
      className={clsx(
        'dark:bg-dark-bg flex items-center border-b bg-white p-4 dark:border-0',
        canAddAudioDevice ? 'justify-between' : 'justify-end'
      )}
    >
      {canAddAudioDevice && (
        <Button
          variant="primary"
          onClick={onClickAddAudioDevice}
          disabled={isLoading}
          onSideEffect={eventTracker('open_add_audio_device_modal')}
        >
          Add Audio Device
          <span className="sr-only">Add Audio Device</span>
        </Button>
      )}
      <div>
        <Button
          variant="icon"
          badge={hasCustomFiltersApplied}
          onClick={onClickFilter}
          disabled={isLoading}
          onSideEffect={eventTracker('open_audio_devices_filters_panel')}
        >
          <span className="sr-only">Open filters panel</span>
          <FunnelIcon className="h-5 w-5" aria-hidden="true" />
        </Button>
      </div>
    </div>
  );
}

export default TableHeader;
