import { DoorGroupDTO } from '@hakimo-ui/hakimo/types';
import { useHttpClient } from '@hakimo-ui/hakimo/util';
import { Selectable } from '@hakimo-ui/shared/ui-base';

/**
 * @param tap run any side-effects
 * @returns
 */
export function useDoorGroupsByName(tap?: (response: Response) => void) {
  const httpClient = useHttpClient();

  return (name: string) => {
    const url = `/v2/orm/door_groups?name=${name}`;

    return httpClient<Selectable[]>(url, {
      responseModifier: async (response) => {
        const res = await response.json();
        const payload = res.payload as DoorGroupDTO;
        return payload.items.map<Selectable>((item) => ({
          id: String(item.id),
          name: item.name,
        }));
      },
      tap,
    });
  };
}

export default useDoorGroupsByName;
