import { UpdateSiteDisarmWindowPayload } from '@hakimo-ui/hakimo/types';
import useAuthenticatedMutation from '../shared/useAuthenticatedMutation';

export function useUpdateTenantDisarmWindow(onSuccess: () => void) {
  const url = `/v2/orm/tenant/monitoring/disarm`;

  const request = new Request(url, {
    method: 'PATCH',
  });

  return useAuthenticatedMutation<null, UpdateSiteDisarmWindowPayload>(
    request,
    {
      onSuccessInvalidationKeys: [['tenant_monitoring_window']],
      onSuccess,
    }
  );
}

export default useUpdateTenantDisarmWindow;
