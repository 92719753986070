import { useDeleteDoorGroup } from '@hakimo-ui/hakimo/data-access';
import { DoorGroup } from '@hakimo-ui/hakimo/types';
import { toast } from '@hakimo-ui/hakimo/util';
import { Button, Modal } from '@hakimo-ui/shared/ui-base';

interface Props {
  doorGroup: DoorGroup;
  onClose: () => void;
}

export function DeleteDoorGroup(props: Props) {
  const { doorGroup, onClose } = props;
  const onSuccess = () => {
    toast('Door Group removed successfully', {
      type: 'success',
    });
    onClose();
  };
  const mutation = useDeleteDoorGroup(doorGroup.id, onSuccess);
  const onConfirm = () => {
    mutation.mutate({});
  };
  const actions = (
    <div className="flex flex-row gap-4">
      <Button variant="error" onClick={onConfirm} loading={mutation.isLoading}>
        Delete
      </Button>
      <Button variant="outline" onClick={onClose}>
        Cancel
      </Button>
    </div>
  );
  return (
    <Modal open onClose={onClose} footer={actions} title="Delete door group">
      <div className="w-[36rem] p-8">
        <div className="space-y-4">
          <p>
            Are you sure you want to delete the door group named{' '}
            <span className="dark:bg-ondark-bg-2 bg-onlight-bg-2 whitespace-pre rounded-md py-1 px-2 font-bold">
              {doorGroup.name}
            </span>{' '}
            having description{' '}
            <span className="dark:bg-ondark-bg-2 bg-onlight-bg-2 whitespace-pre rounded-md py-1 px-2 font-bold">
              {doorGroup.description}
            </span>{' '}
            ?
          </p>
        </div>
      </div>
    </Modal>
  );
}
export default DeleteDoorGroup;
