import AuditLogRoutes from './AuditLogRoutes';
import { StateProvider } from './store/StateProvider';

export function AuditLogRoot() {
  return (
    <StateProvider>
      <AuditLogRoutes />
    </StateProvider>
  );
}

export default AuditLogRoot;
