import { ScanEscalationDTO } from '@hakimo-ui/hakimo/types';
import useAuthenticatedRequest from '../shared/useAuthenticatedRequest';

export function useScanEscalations(searchParams: string) {
  const url = `/v2/orm/escalations?${searchParams}`;
  const request = new Request(url);

  return useAuthenticatedRequest<ScanEscalationDTO>(request, {
    queryKey: ['scan-escalations', { searchParams }],
    responseModifier: async (response) => {
      const respJson = await response.json();
      return respJson.payload;
    },
  });
}

export default useScanEscalations;
