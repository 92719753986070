//
import { Column, Row, TableData } from '@hakimo-ui/hakimo/ui-table';
import clsx from 'clsx';
import React from 'react';

interface SimpleTableProps {
  data: TableData;
  header?: React.ReactNode;
}

const AlarmNotifConfigTable: React.FC<SimpleTableProps> = ({
  data,
  header,
}) => {
  return (
    <div className="relative overflow-visible">
      {header}
      <table className="min-w-full">
        <thead>
          <tr>
            {data.columns.map((column: Column) => (
              <th
                key={column.id}
                className={clsx(
                  'bg-onlight-bg-3 dark:bg-ondark-bg-2 z-20 px-6 py-3 text-left text-sm font-semibold tracking-wider'
                )}
              >
                {column.name}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="dark:divide-ondark-line-1 divide-onlight-line-3  group divide-y focus-visible:outline-none">
          {data.rows.map((row: Row) => (
            <tr key={row.id} className="relative overflow-visible">
              {row.cells.map((cell, cellIndex) => (
                <td
                  key={cellIndex}
                  className="align-text-left text-md relative overflow-visible whitespace-nowrap px-6 py-4 font-normal"
                >
                  {cell.value}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default AlarmNotifConfigTable;
