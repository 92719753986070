import clsx from 'clsx';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { memo } from 'react';
import { ZoomModeTickFactor } from '../constants';
import { ShownDates, ZoomMode, adjustStartAndEndDates } from './util';
dayjs.extend(utc);
dayjs.extend(timezone);

interface Props {
  svgWidth: number;
  shownDates: ShownDates;
  startDate: Date;
  endDate: Date;
  timeZone: string;
  zoomMode: ZoomMode;
  onChangeShownDates: (shownDates: ShownDates) => void;
}
export function NavigateActions(props: Props) {
  const {
    svgWidth,
    shownDates,
    startDate,
    endDate,
    timeZone,
    zoomMode,
    onChangeShownDates,
  } = props;

  const onNavigate = (action: string) => () => {
    let updatedStartDate = new Date(shownDates.startDate);
    let updatedEndDate = new Date(shownDates.endDate);

    [updatedStartDate, updatedEndDate] = adjustStartAndEndDates(
      action === 'left'
        ? ZoomModeTickFactor[zoomMode].numberOfDays
        : -ZoomModeTickFactor[zoomMode].numberOfDays,
      action === 'left'
        ? -ZoomModeTickFactor[zoomMode].numberOfDays
        : ZoomModeTickFactor[zoomMode].numberOfDays,
      updatedStartDate,
      updatedEndDate,
      startDate,
      endDate,
      timeZone
    );
    onChangeShownDates({
      startDate: updatedStartDate,
      endDate: updatedEndDate,
    });
  };

  return (
    <g id="timeline-actions">
      <g
        id="navigate-action"
        className="dark:text-ondark-text-2 stroke-current"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <g
          transform={`translate(${svgWidth / 2 - 10}, 125)`}
          onClick={onNavigate('left')}
          className={clsx(
            'group',
            dayjs(shownDates.startDate).tz(timeZone).format('D') ===
              dayjs(startDate).tz(timeZone).format('D') &&
              'pointer-events-none opacity-50'
          )}
        >
          <path
            d="M15 18l-6-6 6-6"
            className="group-hover:stroke-ondark-primary"
          />
          <rect
            x="0"
            y="0"
            width="24"
            height="24"
            opacity="0"
            fill="transparent"
          />
        </g>
        <g
          transform={`translate(${svgWidth / 2 + 10}, 125)`}
          onClick={onNavigate('right')}
          className={clsx(
            'group',
            dayjs(shownDates.endDate).tz(timeZone).format('D') ===
              dayjs(endDate).tz(timeZone).format('D') &&
              'pointer-events-none opacity-50'
          )}
        >
          <path
            d="M9 18l6-6-6-6"
            className="group-hover:stroke-ondark-primary"
          />
          <rect
            x="0"
            y="0"
            width="24"
            height="24"
            opacity="0"
            fill="transparent"
          />
        </g>
      </g>
    </g>
  );
}

export default memo(NavigateActions);
