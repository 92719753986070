import { Selectable } from '@hakimo-ui/shared/ui-base';

export enum LocationDetailsTabs {
  FLOOR_PLAN = 'floorPlan',
  SITE_MONITORING_SCHEDULE = 'siteMonitoringSchedule',
  SOP = 'Sop',
  LOCATION_CONTACTS = 'locationContacts',
}
export const viewItems: Selectable<string, LocationDetailsTabs>[] = [
  {
    id: LocationDetailsTabs.SITE_MONITORING_SCHEDULE,
    name: 'Site Monitoring Schedule',
  },
  {
    id: LocationDetailsTabs.SOP,
    name: 'SOP',
  },
  {
    id: LocationDetailsTabs.FLOOR_PLAN,
    name: 'Floor Plan',
  },
  {
    id: LocationDetailsTabs.LOCATION_CONTACTS,
    name: 'Point of Contact',
  },
];
