import { ClockIcon } from '@heroicons/react/24/outline';
import { useEffect, useState } from 'react';

interface Props {
  isRunning: boolean;
}

export function Timer(props: Props) {
  const { isRunning } = props;
  const [time, setTime] = useState(0);

  useEffect(() => {
    let intervalId: number;

    if (isRunning) {
      intervalId = window.setInterval(() => {
        setTime((prevTime) => prevTime + 1);
      }, 1000);
    }
    return () => {
      intervalId && clearInterval(intervalId);
    };
  }, [isRunning]);

  // Format time in mm:ss
  const formatTime = (timerSecs: number) => {
    const minutes = Math.floor(timerSecs / 60);
    const seconds = timerSecs % 60;
    return `${minutes.toString().padStart(2, '0')}:${seconds
      .toString()
      .padStart(2, '0')}`;
  };

  return (
    <div className="flex flex-col items-center justify-center gap-1 text-xs">
      <ClockIcon className="h-4 w-4" />
      <span>{formatTime(time)}</span>
    </div>
  );
}
