import { CamFilterValues } from '@hakimo-ui/hakimo/types';
import { atom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';
import { DEFAULT_CAM_FILTER } from './constants';

export const pageAtom = atom<number>(1);
export const pageSizeAtom = atom<number>(30);
export const camFilterAtom = atomWithStorage<CamFilterValues>(
  'cameraFilters',
  DEFAULT_CAM_FILTER
);
