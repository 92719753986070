import { Route, Routes } from 'react-router-dom';
import LocationAlarmMonitoringDetails from './LocationAlarmMonitoringDetails';
import { MonitoringScreen } from './MonitoringScreen';

export function MonitoringRoot() {
  return (
    <Routes>
      <Route path="/" element={<MonitoringScreen />} />
      <Route
        path=":locationAlarmId"
        element={<LocationAlarmMonitoringDetails />}
      />
    </Routes>
  );
}

export default MonitoringRoot;
