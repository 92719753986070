import { EscalationEventsDTO } from '@hakimo-ui/hakimo/types';
import useAuthenticatedRequest from '../shared/useAuthenticatedRequest';

export function useEscalationEvents(
  escalationId: string,
  refetchInterval: number
) {
  const url = `/v2/orm/escalations/${escalationId}/events`;
  const request = new Request(url);

  return useAuthenticatedRequest<EscalationEventsDTO>(request, {
    queryKey: ['escalation_events', escalationId],
    responseModifier: async (response) => {
      const respJson = await response.json();
      return respJson.payload;
    },
    refetchInterval,
  });
}

export default useEscalationEvents;
