import {
  useResolveLocationAlarmIncident,
  useUpdateLocationAlarm,
} from '@hakimo-ui/hakimo/data-access';
import { LocationAlarmIncidentDTO, StatusType } from '@hakimo-ui/hakimo/types';
import {
  eventTracker,
  useCanUpdateLocationAlarmStatus,
  useUser,
} from '@hakimo-ui/hakimo/util';
import { Alert, Button, Modal, Textarea } from '@hakimo-ui/shared/ui-base';
import dayjs from 'dayjs';
import { useState } from 'react';

interface Props {
  locationAlarmId: string;
  locationIncident: LocationAlarmIncidentDTO;
  onStatusChange?: (status: StatusType) => void;
  onClose: () => void;
}

export function ResolveIncidentModal(props: Props) {
  const { locationAlarmId, locationIncident, onClose, onStatusChange } = props;
  const [textVal, setTextVal] = useState('');

  const user = useUser();
  const canUpdateStatus = useCanUpdateLocationAlarmStatus();

  const onSuccess = () => {
    onStatusChange && onStatusChange(StatusType.RESOLVED);
  };

  const resolveAlarmMutation = useUpdateLocationAlarm(
    locationAlarmId,
    onSuccess
  );
  const resolveIncidentMutation = useResolveLocationAlarmIncident();

  const onTextChange = (val: string) => setTextVal(val);

  const onSubmit = () => {
    const locationIncidentPayload = {
      incident_id: locationIncident.id,
      incident_end_time_utc: dayjs().utc().format('YYYY-MM-DDTHH:mm:ss'),
      resolved_by: user.id,
      resolution_comment: textVal,
    };
    resolveIncidentMutation.mutate(locationIncidentPayload);

    const resolveAlarmPayload = {
      status: StatusType.RESOLVED,
      comment: `Escalation - ${textVal}`,
    };
    resolveAlarmMutation.mutate(resolveAlarmPayload);
  };

  const actions = (
    <>
      {canUpdateStatus && (
        <Button
          title="Resolve"
          variant="primary"
          disabled={textVal === ''}
          onClick={onSubmit}
          loading={
            resolveAlarmMutation.isLoading || resolveIncidentMutation.isLoading
          }
          onSideEffect={eventTracker(
            'close_and_resolve_active_location_incident'
          )}
        >
          Resolve
        </Button>
      )}
      <Button onClick={onClose}>Cancel</Button>
    </>
  );

  return (
    <Modal
      title="Resolve Incident"
      onClose={onClose}
      open
      closable
      footer={actions}
    >
      <div className="space-y-4 p-8">
        {resolveAlarmMutation.isError ||
          (resolveIncidentMutation.isError && (
            <Alert type="error">
              Error submitting and resolving the incident
            </Alert>
          ))}
        <Alert type="info">
          Alarm should only be resolved once site POC and PD are alerted about
          the incident.
        </Alert>
        <div>Provide a brief comment on the escalation</div>
        <Textarea
          value={textVal}
          rows={4}
          placeholder="Describe the incident and the actions taken"
          onChange={onTextChange}
        />
      </div>
    </Modal>
  );
}

export default ResolveIncidentModal;
