/* eslint-disable max-lines */
import { WebrtcPlayer } from '@hakimo-ui/hakimo/ui-elements';
import { useFullscreen } from '@hakimo-ui/hakimo/util';
import {
  BellSnoozeIcon,
  ExclamationTriangleIcon,
  ShieldCheckIcon,
} from '@heroicons/react/24/outline';
import clsx from 'clsx';
import { useLayoutEffect, useRef, useState } from 'react';
import { EventHistory } from '../../../types/event';
import CamControls from './CamControls';
import CamPlaybackFeed from './CamPlaybackFeed';

type Props = {
  name: string;
  tenantName: string;
  livestreamUrl: string;
  hideActions: boolean;
  onClickSafe: () => void;
  onClickSnooze: () => void;
  onClickEscalate: () => void;
  playbackUrl?: string;
  eventHistory?: EventHistory[];
};

const CamFeed = (props: Props) => {
  const {
    name,
    livestreamUrl,
    hideActions,
    tenantName,
    onClickSafe,
    onClickSnooze,
    onClickEscalate,
    eventHistory = [],
    playbackUrl,
  } = props;
  const [isLiveMode, setIsLiveMode] = useState(true);
  const containerRef = useRef<HTMLDivElement>(null);
  const [size, setSize] = useState({ width: 0, height: 0 });
  const [playbackVideoSrc, setPlaybackVideoSrc] = useState<string>();
  const [isHovered, setIsHovered] = useState(false);

  const { isFullScreen, toggleFullScreen } = useFullscreen(containerRef);

  useLayoutEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      if (entries.length > 0) {
        const entry = entries[0];
        const parent = entry.target;
        setSize({
          width: parent ? parent.clientWidth - 4 : 0,
          height: parent ? parent.clientHeight - 4 : 0,
        });
      }
    });

    if (containerRef.current) {
      const parentDiv = containerRef.current.parentElement;
      parentDiv && resizeObserver.observe(parentDiv);
    }

    return () => {
      if (containerRef.current) {
        const parentDiv = containerRef.current.parentElement;
        parentDiv && resizeObserver.unobserve(parentDiv);
      }
    };
  }, []);

  const onChangeLiveMode = (val: boolean) => setIsLiveMode(val);

  const hideActionText =
    containerRef.current && containerRef.current.clientWidth < 280;

  const onChangePlaybackVideoSrc = (val: string) => setPlaybackVideoSrc(val);
  const handleDoubleClick = () => toggleFullScreen();
  const handleMouseOver = () => setIsHovered(true);
  const handleMouseOut = () => setIsHovered(false);

  return (
    <div
      className={clsx(
        'group/feed relative max-h-full max-w-full flex-grow',
        isFullScreen && ''
      )}
      ref={containerRef}
      onDoubleClick={handleDoubleClick}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
      style={{
        width: size.width,
        height: size.height,
      }}
    >
      <div className={clsx('h-full', isLiveMode ? 'visible' : 'hidden')}>
        <WebrtcPlayer url={livestreamUrl} showControls={false} />
      </div>
      <div className="h-full">
        <CamPlaybackFeed
          onChangeVideoSrc={onChangePlaybackVideoSrc}
          videoSrc={playbackVideoSrc}
          playbackUrl={playbackUrl}
        />
      </div>
      <CamControls
        eventHistory={eventHistory}
        playbackUrl={playbackUrl}
        isLiveMode={isLiveMode}
        onChangeLiveMode={onChangeLiveMode}
        camName={name}
        onChangeVideoSrc={onChangePlaybackVideoSrc}
        tenantName={tenantName}
        isFullScreen={isFullScreen}
        toggleFullscreen={toggleFullScreen}
        showSeeker={isHovered}
      />

      {!hideActions && (
        <div className="absolute top-1/2 left-1/2 hidden -translate-x-1/2 -translate-y-1/2 group-hover/feed:block">
          <div className="flex gap-2">
            <FeedActionButton
              icon={ShieldCheckIcon}
              text="Safe"
              onClick={onClickSafe}
              hideText={!!hideActionText}
            />
            <FeedActionButton
              icon={BellSnoozeIcon}
              text="Snooze"
              onClick={onClickSnooze}
              hideText={!!hideActionText}
            />
            <FeedActionButton
              icon={ExclamationTriangleIcon}
              text="Escalate"
              onClick={onClickEscalate}
              hideText={!!hideActionText}
            />
          </div>
        </div>
      )}
    </div>
  );
};

interface FeedActionButtonProps {
  text: string;
  icon: (props: React.SVGProps<SVGSVGElement>) => JSX.Element;
  onClick: () => void;
  hideText?: boolean;
}

function FeedActionButton(props: FeedActionButtonProps) {
  const { text, onClick, icon, hideText } = props;

  const ActionIcon = icon;

  return (
    <button
      onClick={onClick}
      title={text}
      className="flex items-center gap-1 rounded bg-black/50 px-3 py-1 text-xs font-semibold text-white transition-colors hover:bg-black/80"
    >
      <ActionIcon className="h-5 w-5 text-white" />
      {!hideText && text}
    </button>
  );
}

export default CamFeed;
