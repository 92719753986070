/* eslint-disable max-lines */
import { VideoControlsList } from '@hakimo-ui/hakimo/types';
import {
  Forward10Icon,
  FullScreenExitIcon,
  FullScreenIcon,
  Replay10Icon,
} from '@hakimo-ui/hakimo/ui-elements';
import { Button } from '@hakimo-ui/shared/ui-base';
import {
  PauseIcon,
  PlayIcon,
  SpeakerWaveIcon,
  SpeakerXMarkIcon,
} from '@heroicons/react/24/solid';
import clsx from 'clsx';
import { useCallback, useEffect, useState } from 'react';
import PlaybackSpeed from './PlaybackSpeed';
import { VideoLoadingIndicator } from './VideoLoadingIndicator';
import { VideoTimeline } from './VideoTimeline';
import { ZoomActions } from './ZoomActions';
import { formatTime } from './util';

interface Props {
  videoPath?: string;
  videoRef?: React.RefObject<HTMLVideoElement>;
  containerRef?: React.RefObject<HTMLDivElement>;
  time?: string;
  isFullScreen: boolean;
  onFullScreen: () => void;
  controlsList?: VideoControlsList[];
}

export function CustomVideoControls(props: Props) {
  const {
    videoPath,
    videoRef,
    containerRef,
    isFullScreen,
    onFullScreen,
    time,
    controlsList,
  } = props;
  const [volume, setVolume] = useState<number>(0);
  const [videoPaused, setVideoPaused] = useState(false);
  const [selectedPlaybackSpeed, setSelectedPlaybackSpeed] = useState(1);
  const videoElement = videoRef?.current;
  const [currentTime, setCurrentTime] = useState<number>();
  const [duration, setDuration] = useState<number>();
  const [opacity, setOpacity] = useState(0);

  const onTogglePlay = useCallback(() => {
    if (!videoElement || videoElement.currentTime < 0) return;
    videoElement.paused ? videoElement.play() : videoElement.pause();
    setVideoPaused(videoElement.paused);
  }, [videoElement]);

  useEffect(() => {
    const playingHandler = () => setVideoPaused(false);
    if (videoElement) {
      videoElement.addEventListener('mouseup', onTogglePlay);
      videoElement.addEventListener('playing', playingHandler);
    }

    return () => {
      videoElement?.removeEventListener('mouseup', onTogglePlay);
      videoElement?.removeEventListener('playing', playingHandler);
    };
  }, [onFullScreen, onTogglePlay, videoElement]);

  useEffect(() => {
    const handler = () => {
      if (!videoElement) return;
      const { currentTime: playingTime, duration: videoDuration } =
        videoElement;
      setCurrentTime(playingTime);
      setDuration(videoDuration);
    };
    if (videoElement) {
      videoElement.addEventListener('timeupdate', handler);
    }

    return () => {
      videoElement && videoElement.removeEventListener('timeupdate', handler);
    };
  }, [videoElement]);

  useEffect(() => {
    const handler = () => setVideoPaused(true);
    if (videoElement) {
      videoElement.addEventListener('ended', handler);
    }
    return () => {
      videoElement && videoElement.removeEventListener('ended', handler);
    };
  }, [videoElement]);

  useEffect(() => {
    const mouseEnterhandler = () => setOpacity(100);
    const mouseLeavehandler = () => setOpacity(0);
    const containerElement = containerRef?.current;
    if (containerElement) {
      containerElement.addEventListener('mouseenter', mouseEnterhandler);
      containerElement.addEventListener('mouseleave', mouseLeavehandler);
    }
    return () => {
      if (containerElement) {
        containerElement.removeEventListener('mouseenter', mouseEnterhandler);
        containerElement.removeEventListener('mouseleave', mouseLeavehandler);
      }
    };
  }, [containerRef]);

  const updateVolume = (vol: number) => {
    if (videoElement) {
      videoElement.volume = vol;
      videoElement.muted = false;
      setVolume(vol);
    }
  };

  const onSkipBackward = () => {
    if (videoElement) {
      videoElement.currentTime -= 10;
    }
  };

  const onSkipForward = () => {
    if (videoElement) {
      videoElement.currentTime += 10;
    }
  };

  const onChangePlaybackSpeed = (speed: number) => {
    if (videoElement) {
      videoElement.playbackRate = speed;
      setSelectedPlaybackSpeed(speed);
    }
  };

  const onSeekVideo = (seekTime: number) => {
    if (videoElement) {
      videoElement.currentTime = seekTime;
    }
  };

  const isControlShown = (type: VideoControlsList) =>
    controlsList ? !controlsList.includes(type) : true;

  return (
    <>
      <div
        className={clsx(
          'from-dark-surface absolute left-0 right-0 bottom-0 bg-gradient-to-t p-1 pt-2 transition-opacity duration-500',
          isFullScreen && 'px-4 pb-6 pt-8',
          `opacity-${opacity}`
        )}
      >
        {isControlShown('noVideoTimeline') && (
          <VideoTimeline
            currentTime={currentTime}
            duration={duration}
            onSeekVideo={onSeekVideo}
          />
        )}
        <ul className="grid grid-cols-3">
          <li className="flex items-center justify-start">
            {isControlShown('noAudio') && (
              <div className="group/audio relative">
                <Button
                  variant="icon"
                  classNames="text-dark-text enabled:hover:bg-slate-800/50 focus:ring-offset-0"
                  onClick={() => updateVolume(volume === 0 ? 0.5 : 0)}
                >
                  {volume === 0 ? (
                    <SpeakerXMarkIcon className="h-6 w-6" />
                  ) : (
                    <SpeakerWaveIcon className="h-6 w-6" />
                  )}
                </Button>
                <input
                  className="accent-ondark-primary absolute -top-10 -left-6 ml-1 hidden h-1 max-w-[75px] -rotate-90 group-hover/audio:block"
                  type="range"
                  min="0"
                  max="1"
                  onChange={(e) => updateVolume(+e.target.value)}
                  value={volume}
                  step="any"
                ></input>
              </div>
            )}
            <div className="text-dark-text ml-2 text-xs">
              {time || (
                <span className="ml-4 whitespace-nowrap">
                  {formatTime(currentTime || 0)} / {formatTime(duration || 0)}
                </span>
              )}
            </div>
          </li>
          <li className="flex justify-center">
            {isControlShown('noForwardBackward') && (
              <Button
                variant="icon"
                onClick={onSkipBackward}
                classNames="text-dark-text enabled:hover:bg-slate-800/50 focus:ring-offset-0"
              >
                <Replay10Icon className="fill-dark-text h-7 w-7" />
              </Button>
            )}
            {isControlShown('noPlayPause') && (
              <Button
                variant="icon"
                onClick={onTogglePlay}
                classNames="text-dark-text enabled:hover:bg-slate-800/50 focus:ring-offset-0"
              >
                {videoPaused ? (
                  <PlayIcon className="h-6 w-6" />
                ) : (
                  <PauseIcon className="h-6 w-6" />
                )}
              </Button>
            )}
            {isControlShown('noForwardBackward') && (
              <Button
                variant="icon"
                onClick={onSkipForward}
                classNames="text-dark-text enabled:hover:bg-slate-800/50 focus:ring-offset-0"
              >
                <Forward10Icon className="fill-dark-text h-7 w-7" />
              </Button>
            )}
          </li>
          <li className="flex items-center justify-end">
            {isControlShown('noPlaybackRate') && (
              <PlaybackSpeed
                onChange={onChangePlaybackSpeed}
                selectedId={selectedPlaybackSpeed}
              />
            )}
            {isControlShown('noFullscreen') && (
              <Button
                variant="icon"
                onClick={onFullScreen}
                classNames="enabled:hover:bg-slate-800/50 focus:ring-offset-0"
              >
                {isFullScreen ? (
                  <FullScreenExitIcon className="fill-dark-text h-7 w-7" />
                ) : (
                  <FullScreenIcon className="fill-dark-text h-7 w-7" />
                )}
              </Button>
            )}
          </li>
        </ul>
      </div>
      {isControlShown('noLoader') && (
        <VideoLoadingIndicator videoRef={videoRef} videoPath={videoPath} />
      )}
      {videoRef?.current && (
        <div
          className={clsx(
            'absolute top-0 right-0 z-10 transition-opacity',
            `opacity-${opacity}`
          )}
        >
          <ZoomActions video={videoRef.current} />
        </div>
      )}
    </>
  );
}

export default CustomVideoControls;
