import { useIsSupportRole } from '@hakimo-ui/hakimo/util';
import {
  LABEL_OFFSET,
  LABEL_PANEL_WIDTH,
  MARGIN,
  TIMELINE_HEIGHT,
  TIMELINE_V_SPACE,
} from './constants';
import { TimelineSource } from './types';

interface Props {
  sources: TimelineSource[];
  liveSourceId?: string;
  onSelectCamera: (camId: string) => void;
}

export function Sidebar(props: Props) {
  const { sources, liveSourceId, onSelectCamera } = props;
  const isSupportRole = useIsSupportRole();

  const onClick =
    (sourceId: string) => (event: React.MouseEvent<SVGGElement>) => {
      if (isSupportRole && (event.metaKey || event.ctrlKey)) {
        const url = `/cameras/?camId=${sourceId}`;
        window.open(url, '_blank', 'noopener,noreferrer');
      } else {
        onSelectCamera(sourceId);
      }
    };

  return (
    <g
      className="sidebar cursor-pointer"
      transform={`translate(0,${MARGIN.top})`}
    >
      {sources.map((source, index) => {
        return (
          <g
            key={source.id}
            className="label"
            transform={`translate(0,${
              index * (TIMELINE_HEIGHT + TIMELINE_V_SPACE)
            })`}
            clipPath="url(#label-clip)"
            onClick={onClick(source.id)}
          >
            <rect
              width={LABEL_PANEL_WIDTH}
              height={TIMELINE_HEIGHT}
              className="dark:fill-ondark-bg-2 fill-onlight-bg-2"
              filter="url(#right-shadow)"
            />
            <text
              x={LABEL_OFFSET}
              y={TIMELINE_HEIGHT / 2}
              dominantBaseline="central"
              className="dark:fill-ondark-text-2 fill-onlight-text-2 text-xs"
            >
              {source.name.length > 20
                ? source.name.slice(0, 10) + '...' + source.name.slice(-10)
                : source.name}
              <title>{source.name}</title>
            </text>
            {source.id === liveSourceId && (
              <circle
                cx={LABEL_PANEL_WIDTH - 10}
                cy={TIMELINE_HEIGHT / 2}
                r={3}
                className="fill-status-red"
              />
            )}
          </g>
        );
      })}
    </g>
  );
}
