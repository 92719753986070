import { UpsertLocationAlarmIncidentDTO } from '@hakimo-ui/hakimo/types';
import useAuthenticatedMutation from '../shared/useAuthenticatedMutation';

export function useResolveLocationAlarmIncident(
  onSuccess?: () => void,
  onError?: (err: Error) => void
) {
  const url = `/v2/orm/location_incidents/resolve`;

  const request = new Request(url, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
  });

  return useAuthenticatedMutation<null, UpsertLocationAlarmIncidentDTO | null>(
    request,
    {
      onSuccess,
      onError,
    }
  );
}

export default useResolveLocationAlarmIncident;
