import { Alert } from '@hakimo-ui/shared/ui-base';

export function Unauthorized() {
  return (
    <div className="p-4">
      <Alert>You are not authorized to view this content</Alert>
    </div>
  );
}

export default Unauthorized;
