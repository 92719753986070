import { filterConfigs } from './constants';
import {
  AlarmListFilterValues,
  AlarmListFilterValuesWithoutTenant,
  FilterValues,
} from './types';

export const getFilterConfigs = (canViewTenantColumn: boolean) =>
  filterConfigs.filter((config) => {
    return config.type !== 'tenant' || canViewTenantColumn;
  });

export const defaultFilterValues = (canViewTenantColumn: boolean) => {
  const configs = getFilterConfigs(canViewTenantColumn);

  return configs.reduce((acc, config) => {
    return { ...acc, [config.type]: config.defaultValue };
  }, {} as AlarmListFilterValues | AlarmListFilterValuesWithoutTenant);
};

export const patchFilterValues = (
  filterValues: FilterValues,
  canViewTenantColumn: boolean
) => {
  const defFilterValues = defaultFilterValues(canViewTenantColumn);

  return (
    Object.keys(defFilterValues) as Array<keyof typeof defFilterValues>
  ).reduce((acc, key) => {
    return { ...acc, [key]: filterValues[key] || defFilterValues[key] };
  }, {} as FilterValues);
};
