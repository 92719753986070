import { ReactElement } from 'react';

export interface Props {
  title: string;
  content: ReactElement;
}

export function ProfileSection(props: Props) {
  const { title, content } = props;

  return (
    <div className="dark:divide-dark-border-bg mt-6 divide-y divide-gray-200 sm:mt-10">
      <h3 className="dark:text-dark-text text-lg font-medium leading-6 text-gray-900">
        {title}
      </h3>
      <div className="mt-6">{content}</div>
    </div>
  );
}

export default ProfileSection;
