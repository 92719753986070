import { AlarmNotificationsMapping } from '@hakimo-ui/hakimo/types';
import useAuthenticatedRequest from '../shared/useAuthenticatedRequest';

export function useGetAlarmNotificationPreference() {
  const url = `/v2/orm/alarm_type/notifications`;
  const request = new Request(url);

  return useAuthenticatedRequest<AlarmNotificationsMapping>(request, {
    queryKey: ['alarm-notification-preference'],
    responseModifier: async (response) => {
      const respJson = await response.json();
      return {
        default_notification_sound: respJson.default.notification_sound,
        alarm_notification_mapping: respJson.payload,
      };
    },
  });
}

export default useGetAlarmNotificationPreference;
