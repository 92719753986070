import { ReactNode } from 'react';

interface Props {
  children: ReactNode;
}

export function SettingSections(props: Props) {
  const { children } = props;

  return (
    <div className="mx-auto max-w-2xl space-y-16 sm:space-y-20">{children}</div>
  );
}
