import { SiteSchedule } from '@hakimo-ui/hakimo/types';
import { Button, InputField, Label } from '@hakimo-ui/shared/ui-base';
import { TrashIcon } from '@heroicons/react/24/outline';
import { WeekdayActions } from './WeekdayActions';
import { convertToMinutes } from './util';

interface Props {
  schedule: SiteSchedule;
  updateSchedule: (schedule: SiteSchedule) => void;
  isDeletable?: boolean;
  onDelete?: () => void;
  isDefault?: boolean;
}

export function ScheduleItem(props: Props) {
  const { schedule, updateSchedule, isDeletable, onDelete, isDefault } = props;

  const { startTime, endTime, enabledDays } = schedule;

  const onStartChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    updateSchedule({ ...schedule, startTime: e.target.value });

  const onEndChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    updateSchedule({ ...schedule, endTime: e.target.value });

  const updateEnabledDays = (days: boolean[]) =>
    updateSchedule({ ...schedule, enabledDays: days });

  const showPlusOneDay =
    startTime &&
    endTime &&
    convertToMinutes(startTime) > convertToMinutes(endTime);

  return (
    <div className="flex items-center gap-4">
      <div className="w-32">
        <InputField
          label="Start time"
          onChange={onStartChange}
          type="time"
          value={startTime}
          required
        />
      </div>
      <div className="relative w-32">
        {showPlusOneDay && (
          <span className="text-onlight-text-3 dark:text-ondark-text-2 absolute top-1 right-2 text-xs">
            +1 Day
          </span>
        )}
        <InputField
          label="End time"
          onChange={onEndChange}
          type="time"
          value={endTime}
        />
      </div>

      <div className="mt-4 flex gap-6">
        <WeekdayActions
          selectedDays={enabledDays}
          updateSelectedDays={updateEnabledDays}
          disableSelectedDays={!isDeletable}
        />
        {isDeletable && (
          <Button
            variant="icon"
            onClick={onDelete}
            title="Delete schedule"
            classNames="ml-4"
          >
            <TrashIcon className="h-5 w-5" />
          </Button>
        )}
        {isDefault && (
          <div>
            <Label type="info" small text="Default" />
          </div>
        )}
      </div>
    </div>
  );
}

export default ScheduleItem;
