import { SiteSchedulePayload } from '@hakimo-ui/hakimo/types';
import useAuthenticatedRequest from '../shared/useAuthenticatedRequest';

export function useSiteSchedule(locationId: number, tenantId: string) {
  const url = `/v2/orm/tenant/monitoring/window?location_id=${locationId}&tenant_id=${tenantId}`;
  const request = new Request(url);

  return useAuthenticatedRequest<SiteSchedulePayload>(request, {
    queryKey: ['tenant_monitoring_window', { locationId, tenantId }],
    responseModifier: async (response) => {
      const respJson = await response.json();
      return respJson.payload[tenantId];
    },
  });
}

export default useSiteSchedule;
