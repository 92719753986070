import { SystemNotificationPreference } from '@hakimo-ui/hakimo/types';
import { Selectable } from '@hakimo-ui/shared/ui-base';
import { atom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';
import { ReactNode } from 'react';

export const pageTitleAtom = atom<ReactNode>(null);
export const draggableNodeAtom = atom<ReactNode>(null);
export const audioNotifyAtom = atomWithStorage<boolean>(
  'alarmAudioNotification',
  false
);
export const systemNotifyAtom = atomWithStorage(
  'systemNotification',
  SystemNotificationPreference.OFF
);

export const currentTenantAtom = atom<Selectable | undefined>(undefined);
