/* eslint-disable max-lines */
import { SOPWorkflow } from '@hakimo-ui/hakimo/types';
import { atom } from 'jotai';
import { ReactNode } from 'react';
import { LocationAlarmCamera } from '../quick-actions-panel/util';

export enum EscalationStep {
  PRE_ESCALATE = 'pre_escalation',
  ESCALATION = 'escalation',
}

export enum WorkflowItemType {
  ESCALATE = 'escalate_alarm',
  RESOLVE = 'resolve_alarm',
  STEPS = 'workflow_steps',
}

export enum SOPStepType {
  QUESTION = 'question',
  STATIC_TALKDOWN = 'staticTalkdown',
  DYANMIC_TALKDOWN = 'dynamicTalkdown',
}

export const currentStepAtom = atom<number>(0);
export const isSOPWorkflowEnabledAtom = atom<boolean>(true);

export type SOPStep = {
  type: SOPStepType;
  title: string;
  yesCallback: () => void;
  noCallback: () => void;
  exceptions?: ReactNode;
  notes?: ReactNode;
  talkdownText?: string;
};

export const getSOPSteps = (
  navigateToNextStep: () => void,
  navigateToResolve: (isQuickResolve?: boolean) => void,
  sop: SOPWorkflow,
  exceptions: ReactNode,
  notes: ReactNode,
  escalate: () => void,
  isSpeakerMapped: boolean
): SOPStep[] => {
  const sopSteps: SOPStep[] = [
    {
      type: SOPStepType.QUESTION,
      title: 'Do you see any person or moving vehicle?',
      yesCallback: navigateToNextStep,
      noCallback: () => navigateToResolve(true),
    },
    {
      type: SOPStepType.QUESTION,
      title:
        sop.exceptions.length > 0
          ? `Review the exceptions${
              sop?.notes?.length > 0 ? ' and notes' : ''
            } above. Does the activity pose a security threat?`
          : 'Did the person or vehicle appear to pose a security threat?',
      exceptions: sop.exceptions.length > 0 && exceptions,
      notes: sop?.notes?.length > 0 && notes,
      yesCallback: navigateToNextStep,
      noCallback: navigateToResolve,
    },
  ];

  const talkdownSteps = [];
  if (sop.isTalkdownEnabled && isSpeakerMapped) {
    sop.firstTalkdown &&
      talkdownSteps.push({
        type: SOPStepType.STATIC_TALKDOWN,
        title: 'Did the person or vehicle move away from the premises?',
        talkdownText: sop.firstTalkdown.text,
        yesCallback: navigateToResolve,
        noCallback: navigateToNextStep,
      });

    sop.secondTalkdown &&
      talkdownSteps.push({
        type: SOPStepType.DYANMIC_TALKDOWN,
        title: 'Did they move away from the premises?',
        talkdownText: sop.secondTalkdown.text,
        yesCallback: navigateToResolve,
        noCallback: navigateToNextStep,
      });
    sopSteps.splice(2, 0, ...talkdownSteps);
    sopSteps[sopSteps.length - 1].noCallback = escalate;
  }

  const lastStep = sopSteps[sopSteps.length - 1];
  if (lastStep.type === SOPStepType.QUESTION) {
    lastStep.yesCallback = escalate;
  }

  return sopSteps;
};

export enum SOPTab {
  SOP_WORKFLOW = 'sopWorkflow',
  SOP_TEXT = 'sop_text',
}

export const sopTabs = [
  { id: SOPTab.SOP_WORKFLOW, name: 'SOP Workflow' },
  { id: SOPTab.SOP_TEXT, name: 'SOP Overview' },
];

export function getLatestCameraWithTalkdown(
  cameras: LocationAlarmCamera[]
): LocationAlarmCamera | null {
  const talkdownEnabledCameras = cameras.filter((cam) => cam.isTalkdownEnabled);
  return talkdownEnabledCameras.length > 0 ? talkdownEnabledCameras[0] : null;
}
