import { UserEnabledFilter } from '@hakimo-ui/hakimo/types';
import { MultiSelect } from '@hakimo-ui/hakimo/ui-elements';
import { getMultiSelectItems, selectHelpers } from '@hakimo-ui/hakimo/util';

interface Option {
  id: string;
  name: 'Enabled' | 'Not Enabled';
}

const enabledOption: Option = { id: 'enabled', name: 'Enabled' };
const notEnabledOption: Option = { id: 'notEnabled', name: 'Not Enabled' };
const options: Option[] = [enabledOption, notEnabledOption];

interface Props {
  value: UserEnabledFilter;
  onChange: (value: UserEnabledFilter) => void;
}

function EnabledFilter(props: Props) {
  const { value, onChange } = props;

  const onChangeCb = (newVals: Option[]) => {
    onChange({
      showEnabled: newVals.includes(enabledOption),
      showNotEnabled: newVals.includes(notEnabledOption),
    });
  };
  const selected = options.filter(
    (option) =>
      (option.name === 'Enabled' && value.showEnabled) ||
      (option.name === 'Not Enabled' && value.showNotEnabled)
  );

  const getItems = (query: string) => {
    const { filterPredicate, isEqual } = selectHelpers;

    return getMultiSelectItems(query, selected, filterPredicate, isEqual, {
      staticItems: options,
    });
  };
  return (
    <MultiSelect
      label="User Enabled"
      value={selected}
      onChange={onChangeCb}
      getItems={getItems}
      displayValue={(item) => item?.name}
      id={(item) => item.id}
    />
  );
}

export default EnabledFilter;
