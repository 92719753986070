export enum ScanMode {
  MONITORING = 'monitoroing',
  ESCALATION = 'escalation',
}

export enum CamAction {
  SNOOZE = 'snooze',
  SAFE = 'safe',
  ESCALATE = 'escalate',
}

export interface GridConfig {
  rows: number;
  cols: number;
}
