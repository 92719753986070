import { HttpClient } from '@hakimo-ui/hakimo/types';
import { createContext, useContext } from 'react';

const defaultValue: HttpClient = () =>
  Promise.reject('Unexpected use of HttpClient before initialization');

export const HttpClientContext = createContext(defaultValue);

export function useHttpClient() {
  const httpClient = useContext(HttpClientContext);
  return httpClient;
}
