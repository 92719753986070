/* eslint-disable max-lines */
import { interpolateRgb } from 'd3-interpolate';
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

const BLUE = '#ef4444';
const RED = '#3b82f6';

export interface ReverseProgressRef {
  reset: () => void;
}

interface Props {
  duration?: number;
  size?: number;
  strokeWidth?: number;
  resetDuration?: number;
}

const ReverseProgress = forwardRef<ReverseProgressRef, Props>((props, ref) => {
  const {
    duration = 60,
    size = 200,
    strokeWidth = 15,
    resetDuration = 0.5,
  } = props;
  const [displayProgress, setDisplayProgress] = useState<number>(100);
  const progressRingCircleRef = useRef<SVGCircleElement | null>(null);
  const progressRef = useRef<number>(100);
  const isResettingRef = useRef<boolean>(false);
  const startTimeRef = useRef<number | null>(null);
  const resetStartTimeRef = useRef<number | null>(null);
  const resetStartProgressRef = useRef<number | null>(null);
  const animationFrameRef = useRef<number | null>(null);

  const radius = (size - strokeWidth) / 2;
  const circumference = radius * 2 * Math.PI;

  const animate = useCallback(
    (timestamp: number) => {
      if (!startTimeRef.current) {
        startTimeRef.current = timestamp;
      }

      let newProgress: number;
      if (isResettingRef.current) {
        if (!resetStartTimeRef.current) {
          resetStartTimeRef.current = timestamp;
          resetStartProgressRef.current = progressRef.current;
        }
        const resetElapsedTime = timestamp - resetStartTimeRef.current;
        const resetProgress =
          (resetElapsedTime / (resetDuration * 1000)) *
          (100 - (resetStartProgressRef.current ?? 100));
        newProgress = Math.min(
          100,
          (resetStartProgressRef.current ?? 100) + resetProgress
        );
      } else {
        const elapsedTime = timestamp - startTimeRef.current;
        newProgress = Math.max(
          0,
          100 - (elapsedTime / (duration * 1000)) * 100
        );
      }

      progressRef.current = newProgress;

      // Update stroke dashoffset directly for smoother animation
      const circle = progressRingCircleRef.current;
      if (circle) {
        circle.style.strokeDashoffset = `${
          circumference - (newProgress / 100) * circumference
        }`;
        circle.style.stroke = interpolateRgb(BLUE, RED)(newProgress / 100);
      }

      // Update displayed progress less frequently
      if (Math.abs(newProgress - displayProgress) > 1) {
        setDisplayProgress(Math.round(newProgress));
      }

      if (
        (isResettingRef.current && newProgress < 100) ||
        (!isResettingRef.current && newProgress > 0)
      ) {
        animationFrameRef.current = requestAnimationFrame(animate);
      } else if (isResettingRef.current && newProgress >= 100) {
        isResettingRef.current = false;
        startTimeRef.current = null;
        resetStartTimeRef.current = null;
        resetStartProgressRef.current = null;
        animationFrameRef.current = requestAnimationFrame(animate);
      }
    },
    [duration, resetDuration, displayProgress, circumference]
  );

  useEffect(() => {
    animationFrameRef.current = requestAnimationFrame(animate);
    return () => {
      if (animationFrameRef.current) {
        cancelAnimationFrame(animationFrameRef.current);
      }
    };
  }, [animate]);

  const reset = useCallback(() => {
    isResettingRef.current = true;
    resetStartTimeRef.current = null;
    resetStartProgressRef.current = null;
    if (animationFrameRef.current) {
      cancelAnimationFrame(animationFrameRef.current);
    }
    animationFrameRef.current = requestAnimationFrame(animate);
  }, [animate]);

  useImperativeHandle(ref, () => ({
    reset,
  }));

  return (
    <div className="relative flex items-center justify-center">
      <svg width={size} height={size} className="-rotate-90 transform">
        <circle
          cx={size / 2}
          cy={size / 2}
          r={radius}
          fill="none"
          strokeWidth={strokeWidth * 0.9}
          className="stroke-white"
        />
        <circle
          ref={progressRingCircleRef}
          cx={size / 2}
          cy={size / 2}
          r={radius}
          fill="none"
          strokeWidth={strokeWidth}
          strokeDasharray={circumference}
          strokeDashoffset={0}
        />
      </svg>
    </div>
  );
});

export default ReverseProgress;
