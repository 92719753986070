import { ShareResource } from '@hakimo-ui/hakimo/feature-shared';
import { LocationAlarm, StatusType } from '@hakimo-ui/hakimo/types';
import { toast } from '@hakimo-ui/hakimo/util';
import { Label } from '@hakimo-ui/shared/ui-base';
import * as Sentry from '@sentry/react';
import { useState } from 'react';
import {
  mapStatusToStatusType,
  mapStatusTypeToLabelType,
} from '../../../shared/util/common';
import AlarmMenu from '../alarm-menu/AlarmMenu';
import AlarmStatus from '../alarm-status/AlarmStatus';
import LocationAlarmIncident from '../location-alarm-incident/LocationAlarmIncident';
import { ViewMode } from '../types';

interface Props {
  alarm: LocationAlarm;
  viewMode: ViewMode;
  onChangeViewMode: (viewMode: ViewMode) => void;
  onStatusChange?: (status: StatusType) => void;
  showResolveAction?: boolean;
}

export function Header(props: Props) {
  const {
    alarm,
    viewMode,
    onChangeViewMode,
    onStatusChange,
    showResolveAction = true,
  } = props;
  const [isStatusEditing, setIsStatusEditing] = useState(false);
  const [openShareAlarm, setOpenShareAlarm] = useState(false);

  const onCopyLink = async () => {
    const locationAlarmLink = `${window.location.origin}/location-alarms/${alarm.id}`;
    try {
      await navigator.clipboard.writeText(locationAlarmLink);
      toast('Location alarm link copied to clipboard');
    } catch (err) {
      Sentry.captureMessage('Error copying text to clipboard');
    }
  };

  const onShare = () => {
    setOpenShareAlarm(true);
  };

  const locationIncident = alarm.locationIncidents
    ? alarm.locationIncidents[0]
    : null;

  return (
    <>
      {locationIncident && (
        <LocationAlarmIncident
          incident={locationIncident}
          locationAlarmId={alarm.id}
          onStatusChange={onStatusChange}
        />
      )}
      <div className="flex h-12 flex-shrink-0 items-center justify-between">
        <div className="flex items-center gap-4 px-4">
          {!isStatusEditing && (
            <span className="flex flex-shrink-0 flex-col">
              <span title={`#${alarm.id} - ${alarm.location?.name}`}>
                {alarm.location?.name}
              </span>
              <span className="dark:text-ondark-text-2 text-onlight-text-2 text-xs">
                {alarm.location.description}
              </span>
            </span>
          )}
          {!isStatusEditing && (
            <div className="h-4 border-l border-black/20 dark:border-white/20"></div>
          )}
          {showResolveAction ? (
            <AlarmStatus
              isEdit={isStatusEditing}
              onChangeEditStatus={setIsStatusEditing}
              alarm={alarm}
              onStatusChange={onStatusChange}
            />
          ) : (
            <Label
              type={mapStatusTypeToLabelType(
                mapStatusToStatusType(alarm.status)
              )}
            >
              {mapStatusToStatusType(alarm.status)}
            </Label>
          )}
        </div>

        {!isStatusEditing && (
          <AlarmMenu
            onChangeViewMode={onChangeViewMode}
            onCopyLink={onCopyLink}
            onShare={onShare}
            viewMode={viewMode}
          />
        )}

        {openShareAlarm && (
          <ShareResource
            resourceId={alarm.id}
            resourceType="location-alarm"
            onClose={() => setOpenShareAlarm(false)}
          />
        )}
      </div>
    </>
  );
}
export default Header;
