/* eslint-disable max-lines */
import {
  LocationAlarmUpdate,
  LocationAlarmUpdatesDTO,
  LocationAlarmUpdateType,
} from '@hakimo-ui/hakimo/types';
import {
  hakimoTimelineIcon,
  TimelineEvent,
  viewTimelineIcon,
} from '@hakimo-ui/hakimo/ui-elements';
import { UpdateObject } from '../../../shared/components/alarm-updates/types';
import {
  getEventDescription,
  getTimelineIcon,
} from '../../../shared/components/alarm-updates/util';
import {
  getManualTalkdownDetailsNode,
  getManualTalkdownModalContentNode,
  getNewAlarmEventDetailsModalContentNode,
  getNewAlarmEventDetailsNode,
} from './extraDetailsUtil';
type TimelineEventSortable = TimelineEvent & { timestamp: number };

export function getLocationAlarmUpdateEvents(
  updates: LocationAlarmUpdatesDTO,
  isSupportRole?: boolean
): TimelineEvent[] {
  const events: TimelineEventSortable[] = [];
  const talkdwownsState = updates.talkdowns || [];

  const sortedNewAlarmAddedEvents = [...updates.items]
    .filter((item) => item.update_type === LocationAlarmUpdateType.ADD_EVENT)
    .sort(
      (a, b) =>
        new Date(a.update_time).getTime() - new Date(b.update_time).getTime()
    );
  if (sortedNewAlarmAddedEvents && sortedNewAlarmAddedEvents.length > 0) {
    const firstAlarm = sortedNewAlarmAddedEvents[0];
    const firstAlarmDate = new Date(firstAlarm.update_time);
    events.push({
      icon: hakimoTimelineIcon,
      description: 'Location alarm created',
      time: firstAlarmDate.toLocaleString(),
      timestamp: firstAlarmDate.getTime() - 1, // handling edge case when there is only one alarm but need to show creation update first
    });
  }

  const [newAlarmAddedEvents, otherEvents] = updates.items.reduce<
    [LocationAlarmUpdate[][], LocationAlarmUpdate[]]
  >(
    (acc, update) => {
      if (update.update_type === LocationAlarmUpdateType.ADD_EVENT) {
        acc[0][acc[0].length - 1].push(update);
      } else {
        acc[1].push(update);
        acc[0].push([]);
      }
      return acc;
    },
    [[[]], []]
  );
  newAlarmAddedEvents.forEach((alarmAddedEvents) => {
    if (alarmAddedEvents.length > 0) {
      const date = new Date(
        alarmAddedEvents[alarmAddedEvents.length - 1].update_time
      );

      events.push({
        icon: hakimoTimelineIcon,
        description: `Added ${alarmAddedEvents.length} new alarm${
          alarmAddedEvents.length > 1 ? 's' : ''
        }`,
        time: date.toLocaleString(),
        timestamp: date.getTime(),
        extraDetails: getNewAlarmEventDetailsNode(
          alarmAddedEvents,
          talkdwownsState || []
        ),
        extraDetailsModalContent: getNewAlarmEventDetailsModalContentNode(
          alarmAddedEvents,
          talkdwownsState || [],
          isSupportRole
        ),
      });
    }
  });
  const [viewAlarmEvents, allOtherEvents] = otherEvents.reduce<
    [LocationAlarmUpdate[][], LocationAlarmUpdate[]]
  >(
    (acc, update) => {
      if (update.update_type === LocationAlarmUpdateType.VIEW_ALARM) {
        if (
          acc[0][acc[0].length - 1].length === 0 || // no previous view alarm
          update.user?.id === acc[0][acc[0].length - 1][0].user?.id // same user
        ) {
          acc[0][acc[0].length - 1].push(update);
        } else {
          acc[0].push([update]);
        }
      } else {
        acc[1].push(update);
        acc[0].push([]);
      }
      return acc;
    },
    [[[]], []]
  );
  viewAlarmEvents.forEach((alarmViewedEvents) => {
    if (alarmViewedEvents.length > 0) {
      const date = new Date(
        alarmViewedEvents[alarmViewedEvents.length - 1].update_time
      );
      const user = alarmViewedEvents[0].user;
      events.push({
        icon: viewTimelineIcon,
        description: `${user?.name} viewed this alarm ${
          alarmViewedEvents.length
        } time${alarmViewedEvents.length > 1 ? 's' : ''}`,
        time: date.toLocaleString(),
        timestamp: date.getTime(),
      });
    }
  });
  events.push(
    ...allOtherEvents.map<TimelineEventSortable>((update) => {
      const updateObj: UpdateObject = {
        user: update.user,
        status: update.update_status,
        event: update.update_type,
        comment: update.update_text,
        twilio_ssid: update.twilio_ssid,
        twilio_calling_to_name: update.twilio_calling_to_name,
      };

      const date = new Date(update.update_time);

      const ret: TimelineEventSortable = {
        icon: getTimelineIcon(updateObj),
        description: getEventDescription(updateObj, true),
        time: date.toLocaleString(),
        timestamp: date.getTime(),
        audio_url: update.audio_url,
      };

      if (update.update_type === LocationAlarmUpdateType.MANUAL_TALKDOWN) {
        ret['extraDetails'] = getManualTalkdownDetailsNode(
          update,
          talkdwownsState
        );
        ret['extraDetailsModalContent'] = getManualTalkdownModalContentNode(
          update,
          talkdwownsState
        );
      }
      return ret;
    })
  );

  return events
    .sort((a, b) => b.timestamp - a.timestamp)
    .map((e) => ({
      icon: e.icon,
      description: e.description,
      time: e.time,
      audio_url: e.audio_url,
      extraDetails: e.extraDetails,
      extraDetailsModalContent: e.extraDetailsModalContent,
    }));
}
