import { Alert, Selectable } from '@hakimo-ui/shared/ui-base';

import { useCreateScanEscalation } from '@hakimo-ui/hakimo/data-access';
import { Camera } from '@hakimo-ui/hakimo/types';
import { toast, useFullscreen, useUser } from '@hakimo-ui/hakimo/util';
import clsx from 'clsx';
import { useCallback, useEffect, useRef, useState } from 'react';
import { ScanMode } from '../../types/common';
import { VisionOutboundType } from '../../types/event';
import ScanSOP from '../scan-sop/ScanSOP';
import CamsMonitor, { CamsMonitorRef } from './CamsMonitor';

interface Props {
  visionTenants: Selectable[];
}

export function ScanMonitoring(props: Props) {
  const { visionTenants } = props;
  const containerRef = useRef<HTMLDivElement>(null);
  const [scanMode, setScanMode] = useState<ScanMode>(ScanMode.MONITORING);
  const camsMonitorRef = useRef<CamsMonitorRef>(null);
  const { isFullScreen, toggleFullScreen } = useFullscreen(containerRef);
  const [showWsCloseAlert, setShowWsCloseAlert] = useState(false);
  const [escalationState, setEscalationState] = useState({
    escalationId: '',
    locationId: '',
    cameraId: '',
    tenantId: '',
    createdTimestamp: 0,
  });
  const {
    data,
    isSuccess,
    mutate: createEscalation,
    isError,
    error,
  } = useCreateScanEscalation();
  const user = useUser();

  useEffect(() => {
    if (isSuccess) {
      data &&
        setEscalationState((prev) => ({
          ...prev,
          escalationId: data.escalation_id,
        }));
      toast('Incident escalated successfully');
    }
  }, [data, isSuccess]);

  const handleEscalation = (camera: Camera) => {
    setScanMode(ScanMode.ESCALATION);
    const cameraId = camera.id;
    const locationId = String(camera.location?.id) || '';
    createEscalation({
      camera_id: cameraId,
      location_id: locationId,
    });
    setEscalationState((prev) => ({
      ...prev,
      cameraId,
      locationId,
      tenantId: camera.tenantId || '',
      createdTimestamp: Date.now(),
    }));
  };

  const changeModeToMonitoring = (resolutionComment?: string) => {
    setScanMode(ScanMode.MONITORING);

    // send message in websocket once escalation is resolved
    const camsMonitor = camsMonitorRef.current;
    camsMonitor?.sendMessageInWebSocket({
      camera_id: escalationState.cameraId,
      event_type: VisionOutboundType.ESCALATION_CLOSE,
      tenant_id: escalationState.tenantId,
      additional_data: {
        username: user.email,
        escalation_close_timestamp: Date.now(),
        escalation_id: escalationState.escalationId,
        resolution_status: resolutionComment ?? 'cancelled',
      },
    });

    setEscalationState({
      cameraId: '',
      escalationId: '',
      locationId: '',
      tenantId: '',
      createdTimestamp: 0,
    });
  };

  const onShowWsCloseMessage = useCallback(() => setShowWsCloseAlert(true), []);

  if (showWsCloseAlert) {
    return (
      <Alert className="m-4" type="error">
        Scan connection terminated. This may be due to a connection error or
        login from another location. Refresh to use Scan in this window.
      </Alert>
    );
  }

  return (
    <div
      ref={containerRef}
      className={clsx(
        'bg-onlight-bg-1 dark:bg-ondark-bg-1 fixed bottom-0 left-0 right-0 flex overflow-hidden p-4',
        isFullScreen ? 'top-0' : 'top-20'
      )}
    >
      {isError && (
        <Alert className="m-4" type="error">
          {error.message}
        </Alert>
      )}
      <div className="flex-grow">
        <CamsMonitor
          ref={camsMonitorRef}
          visionTenants={visionTenants}
          isFullScreen={isFullScreen}
          escalatedCamId={escalationState.cameraId}
          scanMode={scanMode}
          toggleFullScreen={toggleFullScreen}
          onEscalate={handleEscalation}
          onCloseWs={onShowWsCloseMessage}
          onErrorWs={onShowWsCloseMessage}
        />
      </div>
      {scanMode === ScanMode.ESCALATION && (
        <div className="w-1/4">
          <ScanSOP
            onResolveEscalation={changeModeToMonitoring}
            escalationId={escalationState.escalationId}
            locationId={escalationState.locationId}
            escalatedCamId={escalationState.cameraId}
            onCancel={() => changeModeToMonitoring()}
          />
        </div>
      )}
    </div>
  );
}
