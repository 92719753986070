import { ReactNode, useState } from 'react';
import { TimelineEvent } from '../types';
import { DetailsModal } from './DetailsModal';

interface Props {
  events: TimelineEvent[];
}

export function Timeline(props: Props) {
  const { events } = props;
  const [modalContent, setModalContent] = useState<ReactNode>(null);

  const onOpenDetailsModal = (content: ReactNode) => () => {
    setModalContent(content);
  };

  return (
    <div className="flow-root p-6">
      <ul>
        {events.map((event, eventIdx) => (
          <li key={eventIdx} className="group">
            <div className=" relative pb-8 group-last:pb-0">
              {eventIdx !== events.length - 1 ? (
                <div
                  className="absolute top-10 left-4 bottom-2 -ml-px w-0.5 bg-gray-200 dark:bg-gray-500"
                  aria-hidden="true"
                />
              ) : null}
              <div className="relative flex space-x-3">
                <div className="flex-shrink-0">{event.icon}</div>
                <div className="flex-1">
                  <div className="dark:text-dark-secondary-text basis-5/12 text-xs font-medium text-gray-500">
                    {event.time}
                  </div>
                  <div className="basis-7/12 pt-1 text-sm text-gray-700 dark:text-gray-200">
                    {event.description}
                    {event.audio_url ? (
                      <audio
                        src={event.audio_url}
                        className="h-7"
                        controls
                      ></audio>
                    ) : null}
                    <span className="flex items-center">
                      {event?.extraDetails}
                      {event.extraDetailsModalContent && (
                        <button
                          className="text-ondark-primary rounded border-transparent px-2 text-xs enabled:hover:underline"
                          onClick={onOpenDetailsModal(
                            event.extraDetailsModalContent
                          )}
                        >
                          view details
                        </button>
                      )}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </li>
        ))}
      </ul>
      {modalContent && (
        <DetailsModal
          content={modalContent}
          onCloseCb={() => setModalContent(null)}
          open={modalContent !== null}
        />
      )}
    </div>
  );
}

export default Timeline;
