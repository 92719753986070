import { Route, Routes } from 'react-router-dom';
import { DoorGroupsList } from './door-groups-list/DoorGroupsList';

export function DoorGroupsRoot() {
  return (
    <Routes>
      <Route path="/" element={<DoorGroupsList />} />
    </Routes>
  );
}

export default DoorGroupsRoot;
