import { useHttpClient } from '@hakimo-ui/hakimo/util';

export function useGetPlaybackUrl(cameraId: string, timeStamp: number) {
  const httpClient = useHttpClient();

  return () => {
    const url = `/v2/orm/camera/videoPlayback/${cameraId}/${timeStamp}`;

    return httpClient<string>(url, {
      responseModifier: async (response) => {
        const respJson = await response.json();
        return respJson.playlist_url;
      },
    });
  };
}

export default useGetPlaybackUrl;
