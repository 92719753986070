import DoorsRoutes from './DoorsRoutes';
import StateProvider from './store/StateProvider';

export function DoorsRoot() {
  return (
    <StateProvider>
      <DoorsRoutes />
    </StateProvider>
  );
}

export default DoorsRoot;
