import { Alarm } from '@hakimo-ui/hakimo/types';
import { BasicPanel } from '@hakimo-ui/hakimo/ui-elements';
import { eventTracker, useCanChangeAlarmStatus } from '@hakimo-ui/hakimo/util';
import { Button } from '@hakimo-ui/shared/ui-base';
import { useState } from 'react';
import AlarmStatusBadge from '../../shared/components/badge/AlarmStatusBadge';
import { AlarmStatusModal } from './AlarmStatusModal';

interface Props {
  alarm: Alarm;
  onResolve?: () => void;
}
export function AlarmStatusPanel(props: Props) {
  const { alarm, onResolve } = props;
  const canChangeStatus = useCanChangeAlarmStatus();
  const [open, setOpen] = useState(false);

  const onClose = () => {
    setOpen(false);
  };

  return (
    <BasicPanel title="Status">
      <div className="flex items-center justify-between p-6">
        <AlarmStatusBadge status={alarm.status} />
        {canChangeStatus && (
          <Button
            variant="primary"
            onClick={() => setOpen(true)}
            onSideEffect={eventTracker('open_alarm_status_update_modal')}
          >
            Update
          </Button>
        )}
      </div>
      {open && (
        <AlarmStatusModal
          open={true}
          alarm={alarm}
          onCloseCb={onClose}
          onResolveCb={onResolve}
        />
      )}
    </BasicPanel>
  );
}

export default AlarmStatusPanel;
