import { Alarm } from '@hakimo-ui/hakimo/types';
import { BasicPanel } from '@hakimo-ui/hakimo/ui-elements';
import { Alert } from '@hakimo-ui/shared/ui-base';

interface Props {
  alarm: Alarm;
}

function Floorplan(props: Props) {
  const { alarm } = props;

  return (
    <BasicPanel title="Floorplan">
      {alarm.floorplanPath ? (
        <div className="flex justify-center bg-gray-200 dark:bg-gray-900">
          <img
            src={alarm.floorplanPath}
            alt="Floorplan"
            className="max-h-[48rem]"
          />
        </div>
      ) : (
        <div className="p-6">
          <Alert>Floorplan not available</Alert>
        </div>
      )}
    </BasicPanel>
  );
}

export default Floorplan;
