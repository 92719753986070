import useAuthenticatedMutation from '../shared/useAuthenticatedMutation';

export function useUploadFloorplan(locationId: string, onSuccess?: () => void) {
  const url = `/v2/orm/locations/${locationId}/floorplan`;

  const request = new Request(url, {
    method: 'PUT',
  });

  return useAuthenticatedMutation<null, FormData>(request, {
    onSuccess,
    onSuccessInvalidationKeys: [['locationId', locationId]],
    getBody: (data) => data,
  });
}

export default useUploadFloorplan;
