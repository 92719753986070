import { debounce } from '@hakimo-ui/hakimo/util';
import Hls from 'hls.js';

export interface PlaybackInfo {
  playbackStatus: 'seeking' | 'completed' | null;
  marker: Date | null;
}

export interface CameraHlsAndRef {
  videoRef: React.RefObject<HTMLVideoElement>;
  hls?: Hls;
}

export function onMarkerChange(
  cameraHlsAndRef: CameraHlsAndRef,
  onRetry: () => void,
  setSeekerTime: (timestamo: Date) => void,
  playbackInfoRef: React.MutableRefObject<PlaybackInfo>,
  requestNewVideoUrl: (marker: Date) => void
) {
  const updateVideoRefAndHls = (marker: Date) => {
    if (
      cameraHlsAndRef.videoRef.current &&
      cameraHlsAndRef.hls &&
      cameraHlsAndRef.hls.playingDate
    ) {
      const timeDiffSeconds =
        (marker.getTime() - cameraHlsAndRef.hls.playingDate.getTime()) / 1000;
      const nextTime =
        cameraHlsAndRef.videoRef.current.currentTime + timeDiffSeconds;
      if (
        nextTime >= cameraHlsAndRef.videoRef.current.duration ||
        nextTime < 0
      ) {
        requestNewVideoUrl(marker);
      }
      cameraHlsAndRef.videoRef.current.currentTime += timeDiffSeconds;
    } else if (cameraHlsAndRef.videoRef.current && cameraHlsAndRef.hls) {
      // case where due to any reason hls has thrown error and user selects any other time in timeline
      onRetry && onRetry();
      requestNewVideoUrl(marker);
    }
  };

  const debouncedUpdateVideoRefAndHls = debounce(updateVideoRefAndHls, 1000);

  const handleMarkerChange = (marker: Date) => {
    setSeekerTime(marker);
    playbackInfoRef.current = {
      playbackStatus: 'seeking',
      marker: marker,
    };
    debouncedUpdateVideoRefAndHls(marker);
  };

  return handleMarkerChange;
}
