import { ColumnsMenu } from '@hakimo-ui/hakimo/ui-table';
import { eventTracker, useUser } from '@hakimo-ui/hakimo/util';
import { Button } from '@hakimo-ui/shared/ui-base';
import { FunnelIcon } from '@heroicons/react/24/outline';
import { isNotDefaultFilter } from '../../shared/util/common';
import { AlarmColumn, FilterValues } from '../types';
import TenantFilter from './TenantFilter';

interface Props {
  selectableColumns: AlarmColumn[];
  alarmListShownColumns: string[];
  filterValues: FilterValues;
  onOpenFilterPanel: () => void;
  onChangeShownColumns: (columnIds: string[]) => void;
}

function AlarmListHeader(props: Props) {
  const {
    selectableColumns,
    alarmListShownColumns,
    filterValues,
    onOpenFilterPanel,
    onChangeShownColumns,
  } = props;

  const user = useUser();

  return (
    <div className="flex items-center justify-end gap-4">
      {user.mspTenants && user.mspTenants.length > 0 && (
        <TenantFilter user={user} />
      )}
      <ColumnsMenu
        options={selectableColumns}
        shownColumns={alarmListShownColumns}
        onChangeShownColumns={onChangeShownColumns}
      />
      <Button
        variant="icon"
        badge={isNotDefaultFilter(filterValues)}
        onClick={onOpenFilterPanel}
        onSideEffect={eventTracker('open_alarm_filters_panel')}
      >
        <span className="sr-only">Open filters panel</span>
        <FunnelIcon className="h-5 w-5" aria-hidden="true" />
      </Button>
    </div>
  );
}

export default AlarmListHeader;
