import { useGetPlaybackUrl } from '@hakimo-ui/hakimo/data-access';
import { HLSVideoPlayer } from '@hakimo-ui/hakimo/feature-shared';
import { VideoControlsList } from '@hakimo-ui/hakimo/types';
import { usePrevious } from '@hakimo-ui/hakimo/util';
import { Alert } from '@hakimo-ui/shared/ui-base';
import * as Sentry from '@sentry/react';
import Hls from 'hls.js';
import { memo, useCallback, useEffect, useState } from 'react';
import { useUpdatePlayStatus, useVideoTimeController } from './hooks';

interface Props {
  cameraId: string;
  cameraTimeZone: string;
  triggerTime: number;
  updatePlayStatus: (isPlaying: boolean) => void;
}

export function MultiCamPlaybackItem(props: Props) {
  const { cameraId, cameraTimeZone, triggerTime, updatePlayStatus } = props;
  const [playbackUrl, setPlaybackUrl] = useState('');
  const [cameraHlsAndRef, setCameraHlsAndRef] = useState<{
    videoRef: React.RefObject<HTMLVideoElement>;
    hls?: Hls;
  }>();
  const [hasError, setHasError] = useState(false);
  const controlsList: VideoControlsList[] = ['onlyTime'];

  const getPlaybackUrl = useGetPlaybackUrl(cameraId, triggerTime / 1000);
  const prevTriggerTime = usePrevious(triggerTime);

  useEffect(() => {
    let fetchUrl = false;
    if (!prevTriggerTime) {
      fetchUrl = true;
    } else {
      const currentTriggerDate = new Date(triggerTime);
      const prevTriggerDate = new Date(prevTriggerTime);
      // if UTC day got changed, fetch new URl
      if (prevTriggerDate.getUTCDay() !== currentTriggerDate.getUTCDay()) {
        fetchUrl = true;
      }
    }

    if (fetchUrl) {
      triggerTime &&
        getPlaybackUrl().then((res) => {
          setHasError(false);
          setPlaybackUrl(res);
          updatePlayStatus(false);
        });
    }
  }, [getPlaybackUrl, prevTriggerTime, triggerTime, updatePlayStatus]);

  useVideoTimeController(
    triggerTime,
    cameraHlsAndRef?.hls,
    cameraHlsAndRef?.videoRef
  );

  useUpdatePlayStatus(updatePlayStatus, cameraHlsAndRef?.videoRef);

  const onInit = useCallback(
    (hls: Hls, videoRef: React.RefObject<HTMLVideoElement>) => {
      setCameraHlsAndRef({ hls, videoRef });
    },
    []
  );

  const onHLSError = useCallback((e: Error) => {
    setHasError(true);
    Sentry.captureMessage(String(e));
  }, []);

  return (
    <>
      {hasError && <Alert>Unable to load current playback.</Alert>}
      <HLSVideoPlayer
        onInit={onInit}
        videoPath={playbackUrl}
        lockAspectRatio={true}
        onError={onHLSError}
        timeZone={cameraTimeZone}
        authRequired
        isPlayback
        controlsList={controlsList}
      />
    </>
  );
}

export default memo(MultiCamPlaybackItem);
