import { ScanEscalation } from '@hakimo-ui/hakimo/types';
import { Label } from '@hakimo-ui/shared/ui-base';
import clsx from 'clsx';
import { getLabelType } from './util';

interface Props {
  escalation: ScanEscalation;
  selected: boolean;
  onClick: () => void;
}

export function ListItem(props: Props) {
  const { selected, escalation, onClick } = props;

  return (
    <button
      onClick={onClick}
      className={clsx(
        'dark:bg-ondark-bg-2 bg-onlight-bg-2 hover:bg-onlight-bg-3 dark:hover:bg-ondark-bg-3 relative w-full cursor-pointer items-center outline-none',
        selected &&
          'dark:outline-ondark-primary outline-onlight-primary outline outline-offset-2',
        'animate-highlight dark:animate-dark-highlight'
      )}
    >
      <div className="flex items-stretch">
        <div className="flex flex-1 flex-col gap-2 truncate px-4 py-3">
          <span
            className="text-onlight-text-1 dark:text-ondark-text-1 flex items-center justify-between truncate text-sm font-medium"
            title={escalation.location?.name}
          >
            {escalation.location?.name}
          </span>
          <span className="flex items-center justify-between">
            <span className="dark:text-ondark-text-2 text-onlight-text-2 flex items-center gap-2 text-left text-sm">
              <span className="font-medium">
                {new Date(escalation.createdAt).toLocaleTimeString()}
              </span>
              <span className="opacity-70">
                {new Date(escalation.createdAt).toLocaleDateString()}
              </span>
            </span>
            <Label
              type={getLabelType(escalation.status)}
              text={escalation.status}
              small
            />
          </span>
        </div>
      </div>
    </button>
  );
}

export default ListItem;
