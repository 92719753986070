import useAuthenticatedMutation from '../shared/useAuthenticatedMutation';

export function useSendAudio(
  cameraId: string | null,
  alarmId?: string,
  locAlarmId?: string,
  onSuccess?: () => void,
  escalationId?: string
) {
  const params = new URLSearchParams();
  if (alarmId) {
    params.append('alarmId', alarmId);
  }
  if (locAlarmId) {
    params.append('locationAlarmId', locAlarmId);
  }
  if (escalationId) {
    params.append('escalationId', escalationId);
  }
  const url = `/v2/orm/camera/audio/${cameraId}?${params.toString()}`;

  const request = new Request(url, {
    method: 'POST',
  });

  return useAuthenticatedMutation<null, FormData>(request, {
    onSuccess,
    getBody: (data) => data,
    onSuccessInvalidationKeys: [
      ['alarm_updates', alarmId ?? '', locAlarmId ?? ''],
    ],
  });
}

export default useSendAudio;
