import { CameraDetail } from '@hakimo-ui/hakimo/types';
import { Label } from '@hakimo-ui/shared/ui-base';
import { SettingSection } from './SettingSection';

interface Props {
  camera: CameraDetail;
}

export function SettingSectionAudioDevices(props: Props) {
  const { camera } = props;
  const audioDeviceAvailable =
    camera.audioDeviceMapped &&
    camera.audioDevices &&
    camera.audioDevices.length > 0;
  return (
    <SettingSection name="Associated Audio Devices">
      {audioDeviceAvailable ? (
        <ul className="">
          {camera.audioDevices?.map((audioDevice) => (
            <li
              key={audioDevice.id}
              className="flex items-center gap-6 space-x-4 py-4"
            >
              <div className="flex items-center gap-x-3">
                <div className="dark:text-ondark-text-2 text-onlight-text-3 flex-none rounded-full p-1">
                  <div className="h-2 w-2 rounded-full bg-current"></div>
                </div>
                <h2 className="min-w-0 text-sm font-semibold leading-6">
                  {audioDevice.url}
                </h2>
              </div>
              <Label type="info">{audioDevice.deviceType}</Label>
            </li>
          ))}
        </ul>
      ) : (
        <div className="pt-4">No current audio device integration.</div>
      )}
    </SettingSection>
  );
}

export default SettingSectionAudioDevices;
