import { UserListFilters } from '@hakimo-ui/hakimo/types';

export interface State {
  userListFilters: UserListFilters;
  userListPage: number;
  userListPageSize: number;
}
export const initialFilters: UserListFilters = {
  names: [],
  emails: [],
  roles: [],
  enabled: {
    showEnabled: true,
    showNotEnabled: false,
  },
};

export const initialState: State = {
  userListFilters: initialFilters,
  userListPage: 1,
  userListPageSize: 30,
};
