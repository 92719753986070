import { LocationAlarm, StatusType } from '@hakimo-ui/hakimo/types';
import { Button } from '@hakimo-ui/shared/ui-base';
import { Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { Fragment, useEffect, useState } from 'react';
import { LocationAlarmContacts } from '../../location-alarms/alarm-overview/location-alarm-contacts/LocationAlarmContacts';
import LocationFloorplan from '../../location-alarms/alarm-overview/location-floorplan/LocationFloorplan';
import { SOPWorkflowContainer } from '../sop-workflow/SOPWorkflowContainer';
import QuickActionsIcons from './QuickActionsIcons';
import QuickActionsTour from './QuickActionsTour';
import {
  LocationAlarmCamera,
  QuickActionsId,
  getCamerasFromRawAlarms,
} from './util';

interface Props {
  locationAlarm: LocationAlarm;
  onAlarmResolve: (status: StatusType) => void;
}

export function QuickActionsPanel(props: Props) {
  const {
    locationAlarm: {
      id: locationAlarmId,
      location: { id: locationId, tenant_id: tenantId },
      rawAlarms,
      status: locationAlarmStatus,
      locationIncidents,
    },
    onAlarmResolve,
  } = props;
  const [selectedAction, setSelectedAction] = useState<
    QuickActionsId | undefined
  >(QuickActionsId.SOP);
  const [alarmCameras, setAlarmCameras] = useState<LocationAlarmCamera[]>([]);

  useEffect(() => {
    setAlarmCameras(getCamerasFromRawAlarms(rawAlarms));
  }, [rawAlarms]);

  const onClose = () => setSelectedAction(undefined);

  return (
    <div className="quick-actions-panel flex max-h-[85vh] translate-x-4 gap-1">
      <QuickActionsIcons
        selected={selectedAction}
        onChange={setSelectedAction}
      />

      <Transition.Root show={selectedAction !== undefined}>
        <div className="z-10 hidden lg:block lg:w-[23vw]">
          <Transition.Child
            as={Fragment}
            enter="transition-transform ease-in duration-500"
            enterFrom="translate-x-3/4"
            enterTo="translate-x-0"
            leave="transition-transform ease-out duration-200"
            leaveFrom="translate-x-0"
            leaveTo="translate-x-3/4"
          >
            <div className="relative max-h-[85vh] overflow-auto rounded p-2 lg:w-[23vw] lg:border-r lg:border-gray-200 lg:bg-gray-100 dark:lg:border-gray-800 lg:dark:bg-gray-800">
              <Button
                variant="icon"
                onClick={onClose}
                title="Close"
                classNames="absolute top-3 right-2 z-20"
              >
                <XMarkIcon className="h-5 w-5" />
              </Button>
              {selectedAction === QuickActionsId.SOP && (
                <SOPWorkflowContainer
                  locationAlarmId={locationAlarmId}
                  locationId={String(locationId)}
                  alarmCameras={alarmCameras}
                  onAlarmResolve={onAlarmResolve}
                  locationAlarmStatus={locationAlarmStatus}
                  locationAlarmIncident={locationIncidents?.[0]}
                  locationTenantId={tenantId}
                />
              )}
              {selectedAction === QuickActionsId.FLOORPLAN && (
                <LocationFloorplan locationId={String(locationId)} />
              )}
              {selectedAction === QuickActionsId.LOCATION_CONTACTS && (
                <LocationAlarmContacts
                  locationId={locationId}
                  locationAlarmId={locationAlarmId}
                  locationTenantId={tenantId}
                />
              )}
            </div>
          </Transition.Child>
        </div>
      </Transition.Root>
      <QuickActionsTour />
    </div>
  );
}

export default QuickActionsPanel;
