/* eslint-disable max-lines */
import { MiniAudioPlayer } from '@hakimo-ui/hakimo/feature-shared';
import {
  AlarmTalkdownState,
  LocationAlarmUpdate,
  TalkdownStatus,
  TalkdownType,
} from '@hakimo-ui/hakimo/types';
import { Button } from '@hakimo-ui/shared/ui-base';
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline';
import dayjs from 'dayjs';
import {
  getColumnNode,
  getTalkdownStatusIconNode,
  talkdownDetailsModalAllColumns,
} from '../../../shared/components/alarm-updates/extraDetailsUtil';

const getFormattedDate = (date?: string) => {
  if (!date) {
    return '-';
  }
  const dateObj = new Date(date);
  return dayjs(dateObj).format('DD/MM/YYYY, HH:mm:ss');
};

export function getNewAlarmEventDetailsNode(
  events: LocationAlarmUpdate[],
  talkdownsState: AlarmTalkdownState[]
) {
  const allEventsCount = events.length;
  const noRawAlarmId = events.every((ev) => !ev.raw_alarm_id);
  const automatedTalkdownState = talkdownsState.filter(
    (state) => state.talkdown_type === TalkdownType.AUTOMATED
  );
  if (noRawAlarmId || automatedTalkdownState.length === 0) {
    return null;
  }

  let eventTalkdownSuccessCount = 0;
  for (let i = 0; i < events.length; i++) {
    const rawAlarmId = events[i].raw_alarm_id;
    const isTalkdownSuccess = automatedTalkdownState.some(
      (talkdown) =>
        talkdown.raw_alarm_id === rawAlarmId &&
        talkdown.status === TalkdownStatus.SUCCESS
    );
    isTalkdownSuccess && eventTalkdownSuccessCount++;
  }
  let message = '';
  let type = 'info';
  if (allEventsCount === eventTalkdownSuccessCount) {
    message = `${
      allEventsCount > 1 ? 'All ' : ''
    }Automated talkdown were succcssfully transmitted`;
    type = 'success';
  } else if (eventTalkdownSuccessCount === 0) {
    message = `${
      allEventsCount > 1 ? 'All ' : ''
    }Automated talkdown tranmission failed`;
    type = 'error';
  } else if (allEventsCount > eventTalkdownSuccessCount) {
    message = `${
      allEventsCount > 1 ? 'Some ' : ''
    }Automated talkdowns tranmission failed`;
    type = 'warning';
  }

  const node = (
    <div className="flex items-center gap-2 text-xs">
      {message}
      {getTalkdownStatusIconNode(type)}
    </div>
  );
  return node;
}

interface EventTalkdownDetails {
  rawAlarmId?: string;
  rawAlarmAddedTime: string;
  ingestedTalkdownAudio: string;
  customMessage: string;
  status: TalkdownStatus;
  sentTalkdownAudio: string;
  completedTimeUtc: string;
}

export function getNewAlarmEventDetailsModalContentNode(
  events: LocationAlarmUpdate[],
  talkdownsState: AlarmTalkdownState[],
  isSupportRole?: boolean
) {
  const noRawAlarmId = events.every((ev) => !ev.raw_alarm_id);

  const automatedTalkdownState = talkdownsState.filter(
    (state) => state.talkdown_type === TalkdownType.AUTOMATED
  );
  if (noRawAlarmId || automatedTalkdownState.length === 0) {
    return null;
  }
  const eventTalkdownDetails: EventTalkdownDetails[] = [];
  events.reduce((acc, ev) => {
    const rawAlarmId = ev.raw_alarm_id;
    const alarmTalkdownState = automatedTalkdownState.find(
      (talkdownState) => talkdownState.raw_alarm_id === rawAlarmId
    );
    if (alarmTalkdownState) {
      acc.push({
        rawAlarmId: alarmTalkdownState?.raw_alarm_id,
        rawAlarmAddedTime: ev.update_time,
        ingestedTalkdownAudio: alarmTalkdownState.ingested_talkdown_audio,
        customMessage: alarmTalkdownState.custom_message,
        status: alarmTalkdownState.status,
        sentTalkdownAudio: alarmTalkdownState.talkdown_audio,
        completedTimeUtc: alarmTalkdownState.talkdown_completed_utc,
      });
    }
    return acc;
  }, eventTalkdownDetails);

  const onClickRawAlarmId = (alarmId?: string) => () => {
    if (isSupportRole && alarmId) {
      const url = `/alarms/${alarmId}`;
      window.open(url, '_blank', 'noopener,noreferrer');
    }
  };

  const columns = talkdownDetailsModalAllColumns;

  return (
    <div className="text-sm">
      <div className="grid grid-cols-6 gap-2">{getColumnNode(columns)}</div>
      <div className="max-h-[70vh] overflow-auto">
        {eventTalkdownDetails.map((det) => (
          <div
            key={det.rawAlarmId}
            className="hover:bg-onlight-bg-2 dark:hover:bg-ondark-bg-2 grid grid-cols-6 gap-2 rounded py-6"
          >
            <div className="flex items-center gap-2">
              <span className="w-[8rem] overflow-hidden text-ellipsis whitespace-nowrap">
                {det.rawAlarmId}
              </span>
              {isSupportRole && (
                <Button
                  variant="icon"
                  onClick={onClickRawAlarmId(det.rawAlarmId)}
                >
                  <ArrowTopRightOnSquareIcon className="text-primary-400 h-3 w-3" />
                </Button>
              )}
            </div>
            <span>{getFormattedDate(det.rawAlarmAddedTime)}</span>
            <span className="flex gap-2">
              {getTalkdownStatusIconNode(det.status)}
              {det.status}
            </span>
            <span>
              {det.ingestedTalkdownAudio ? (
                <span className="flex">
                  <MiniAudioPlayer
                    src={det.ingestedTalkdownAudio}
                    isLoading={false}
                  />
                </span>
              ) : (
                'NA'
              )}
            </span>
            <span>{getFormattedDate(det.completedTimeUtc)}</span>
            <span>{det.customMessage || '-'}</span>
          </div>
        ))}
      </div>
    </div>
  );
}

export function getManualTalkdownDetailsNode(
  update: LocationAlarmUpdate,
  talkdownsStates: AlarmTalkdownState[]
) {
  let updateAudioUrl = update.audio_url;
  if (!updateAudioUrl) {
    return null;
  }
  updateAudioUrl = updateAudioUrl.split('?')[0];
  const manualTalkdownState = talkdownsStates.find(
    (state) =>
      state.talkdown_type === TalkdownType.MANUAL &&
      updateAudioUrl &&
      state.talkdown_audio.includes(updateAudioUrl)
  );

  const talkdownStatus = manualTalkdownState?.status;
  let message = '';
  let icon = null;
  switch (talkdownStatus) {
    case TalkdownStatus.SUCCESS:
      message = 'Talkdown transmitted successfully';
      icon = getTalkdownStatusIconNode('success');
      break;
    case TalkdownStatus.PENDING:
      message = 'Talkdown yet to be relayed';
      break;
    case TalkdownStatus.FAILED:
      message = 'Talkdown tranmission failed';
      icon = getTalkdownStatusIconNode('error');
      break;
  }

  return (
    <div className="flex items-center gap-2 text-xs">
      {message}
      {icon}
    </div>
  );
}

export function getManualTalkdownModalContentNode(
  update: LocationAlarmUpdate,
  talkdownsStates: AlarmTalkdownState[]
) {
  let updateAudioUrl = update.audio_url;
  if (!updateAudioUrl) {
    return null;
  }
  updateAudioUrl = updateAudioUrl.split('?')[0].split('audio')[1];
  if (!updateAudioUrl) {
    return;
  }
  const manualTalkdownState = talkdownsStates.find(
    (state) =>
      state.talkdown_type === TalkdownType.MANUAL &&
      updateAudioUrl &&
      state.talkdown_audio.includes(updateAudioUrl)
  );
  if (!manualTalkdownState) {
    return null;
  }

  const columns = talkdownDetailsModalAllColumns.filter(
    (col) => !['rawAlarmId', 'rawAlarmAddedTime'].includes(col.id)
  );

  return (
    <div className="grid grid-cols-4 gap-2 text-sm">
      {getColumnNode(columns)}
      <span className="flex gap-2">
        {getTalkdownStatusIconNode(manualTalkdownState.status)}
        {manualTalkdownState.status}
      </span>
      <span>
        {manualTalkdownState.ingested_talkdown_audio ? (
          <span className="flex">
            <MiniAudioPlayer
              src={manualTalkdownState.ingested_talkdown_audio}
              isLoading={false}
            />
          </span>
        ) : (
          'NA'
        )}
      </span>
      <span>
        {getFormattedDate(manualTalkdownState.talkdown_completed_utc)}
      </span>

      <span>{manualTalkdownState.custom_message || '-'}</span>
    </div>
  );
}
