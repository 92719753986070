import { Box } from '@hakimo-ui/hakimo/types';
import {
  getPolygonLines,
  imageMarkerToClientMarker,
} from '@hakimo-ui/hakimo/ui-elements';
import { useEffect, useMemo, useRef } from 'react';
import { getVideoPolygons } from '../util';
interface Props {
  boxes?: Box[];
  videoRect?: DOMRect;
  videoWidth?: number;
  videoHeight?: number;
  videoRef?: React.RefObject<HTMLVideoElement>;
}
export function AlarmVideoBox(props: Props) {
  const { boxes = [], videoRect, videoHeight, videoWidth, videoRef } = props;
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const video = videoRef?.current;
    let observer: MutationObserver;
    if (video) {
      observer = new MutationObserver(function (mutations) {
        mutations.forEach(function () {
          const videoTransform = video.style.transform;
          const videoTranslate = video.style.translate;
          if (containerRef.current) {
            const svg = containerRef.current.children[0] as SVGElement;
            svg.style.transform = videoTransform;
            svg.style.translate = videoTranslate;
          }
        });
      });
      observer.observe(video, {
        attributes: true,
        attributeFilter: ['style'],
      });
    }

    return () => {
      observer && observer.disconnect();
    };
  }, [videoRef]);

  const boxesPolygon = useMemo(() => getVideoPolygons(boxes), [boxes]);
  const polygons = useMemo(() => {
    if (!videoRect || !boxesPolygon) {
      return [];
    }
    return boxesPolygon.map((polygon) => {
      return {
        ...polygon,
        points: polygon.points.map((marker) => {
          if (videoHeight && videoWidth) {
            return imageMarkerToClientMarker(
              [videoRect.width, videoRect.height],
              [videoWidth, videoHeight],
              { point: marker }
            ).point;
          }
          return marker;
        }),
      };
    });
  }, [videoRect, boxesPolygon, videoHeight, videoWidth]);
  return <div ref={containerRef}>{getPolygonLines(polygons)}</div>;
}
export default AlarmVideoBox;
