import { AuditLogDTO } from '@hakimo-ui/hakimo/types';
import useAuthenticatedRequest from '../shared/useAuthenticatedRequest';

export function useAuditLogs(searchParams: string, refetchInterval: number) {
  const url = `/v2/orm/tenant/audit-logs?${searchParams}`;
  const request = new Request(url);

  return useAuthenticatedRequest<AuditLogDTO>(request, {
    queryKey: ['audit-logs', { searchParams }],
    responseModifier: async (response) => {
      const respJson = await response.json();
      return respJson.payload;
    },
    refetchInterval,
  });
}

export default useAuditLogs;
