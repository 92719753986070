import { LABEL_PANEL_WIDTH, TIMELINE_HEIGHT } from './constants';

interface Props {
  visibleContentWidth: number;
  height: number;
}

export function Defs(props: Props) {
  const { visibleContentWidth, height } = props;

  return (
    <defs>
      <filter id="right-shadow">
        <feGaussianBlur in="SourceAlpha" stdDeviation="3" result="blur" />
        <feOffset in="blur" dx="1" dy="" result="offsetBlur" />
        <feFlood floodColor="rgba(0,0,0,0.5)" result="shadowColor" />
        <feComposite
          in="shadowColor"
          in2="offsetBlur"
          operator="in"
          result="shadow"
        />
        <feMerge>
          <feMergeNode in="shadow" />
          <feMergeNode in="SourceGraphic" />
        </feMerge>
      </filter>
      <clipPath id="label-clip">
        <rect
          x={0}
          y={0}
          width={LABEL_PANEL_WIDTH * 2}
          height={TIMELINE_HEIGHT}
        />
      </clipPath>
      <clipPath id="main-clip">
        <rect
          x={LABEL_PANEL_WIDTH}
          y={0}
          width={visibleContentWidth}
          height={height}
        />
      </clipPath>
    </defs>
  );
}
