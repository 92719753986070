import { useUpdateCamera } from '@hakimo-ui/hakimo/data-access';
import { CameraUpdatePayload } from '@hakimo-ui/hakimo/types';
import { toast } from '@hakimo-ui/hakimo/util';
import { Alert, Button, InputField, Modal } from '@hakimo-ui/shared/ui-base';
import { useState } from 'react';

interface Props {
  cameraId: string;
  name: keyof CameraUpdatePayload;
  displayName: string;
  currentValue?: string;
  onClose: () => void;
}

export function UpdateField(props: Props) {
  const { cameraId, name, displayName, currentValue = '', onClose } = props;
  const [value, setValue] = useState(currentValue);

  const updateCameraMutation = useUpdateCamera(cameraId, () => {
    toast(`Camera ${displayName} updated!`, {
      type: 'success',
    });
    onClose();
  });

  const onSubmit = () => {
    updateCameraMutation.mutate({
      [name]: value,
    });
  };

  const actions = (
    <>
      <span className="mr-2">
        <Button variant="primary" onClick={onSubmit}>
          Submit
        </Button>
      </span>
      <Button onClick={onClose}>Cancel</Button>
    </>
  );

  return (
    <Modal
      open={true}
      onClose={onClose}
      title={`Update Camera ${displayName}`}
      footer={actions}
    >
      <div className="w-[28rem] space-y-2 px-8 py-4">
        {updateCameraMutation.isError && (
          <Alert type="error">{updateCameraMutation.error.message}</Alert>
        )}
        <InputField
          type="text"
          label={displayName}
          value={value}
          onChange={(e) => setValue(e.target.value)}
        />
      </div>
    </Modal>
  );
}
