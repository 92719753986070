import {
  AgedAlarmFilterValue,
  Option,
  TimePeriod,
} from '@hakimo-ui/hakimo/types';
import { SelectMenu } from '@hakimo-ui/hakimo/ui-elements';
import { InputField } from '@hakimo-ui/shared/ui-base';
import { agedAlarmTimePeriodOptions } from '../constants';

interface Props {
  value: AgedAlarmFilterValue;
  onChange: (value: AgedAlarmFilterValue) => void;
}

export function AgedAlarmFilter(props: Props) {
  const { value, onChange } = props;

  const selected = agedAlarmTimePeriodOptions.find(
    (item) => item.name === value.name
  );

  const onChangeTimePeriod = (option: Option<TimePeriod>) => {
    onChange({
      id: option.id,
      name: option.name,
    });
  };

  const onCustomTimeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange({
      ...value,
      customTime: e.target.value,
    });
  };

  return (
    <>
      <SelectMenu
        label="Period"
        items={agedAlarmTimePeriodOptions}
        value={selected}
        onChange={onChangeTimePeriod}
        displayValue={(item) => (item ? item.name : '--Select--')}
      />
      {selected?.name === TimePeriod.CUSTOM_TIME && (
        <div className="mt-2">
          <InputField
            type="number"
            label="Custom time in minutes"
            value={value.customTime || ''}
            onChange={onCustomTimeChange}
          />
        </div>
      )}
    </>
  );
}

export default AgedAlarmFilter;
