import { useTimezone } from '@hakimo-ui/hakimo/data-access';
import { SelectAsync } from '@hakimo-ui/hakimo/ui-elements';

interface Props {
  value: string;
  onChange: (timezone: string) => void;
}

export function TimezoneSelect(props: Props) {
  const { value, onChange } = props;

  const getTimezone = useTimezone();

  return (
    <SelectAsync
      label="Timezone"
      placeholder="Search timezones..."
      onChangeQuery={getTimezone}
      value={value}
      onChange={onChange}
      displayValue={(item) => item || ''}
      infoTooltip="Search for timezone. For eg: America/Los_Angeles"
    />
  );
}

export default TimezoneSelect;
