import {
  DndContext,
  DragEndEvent,
  DragOverlay,
  DragStartEvent,
} from '@dnd-kit/core';
import { useCameras } from '@hakimo-ui/hakimo/data-access';
import { Camera } from '@hakimo-ui/hakimo/types';
import { SplitView, SplitViewWrapper } from '@hakimo-ui/hakimo/ui-elements';
import { useFullscreen } from '@hakimo-ui/hakimo/util';
import { Alert, SwitchItem } from '@hakimo-ui/shared/ui-base';
import { useEffect, useRef, useState } from 'react';
import { MultiCamViewMode } from '../constants';
import CameraGridList, { CameraGridListItem } from './CameraGridList';
import CameraGridHeader from './camera-grid-header/CameraGridHeader';
import GridItemsContainer from './grid-items-container/GridItemsContainer';

export function CameraGrid() {
  const { isFetching, isError, error, data } = useCameras('');
  const containerRef = useRef<HTMLDivElement>(null);
  const { isFullScreen, toggleFullScreen } = useFullscreen(containerRef);
  const [gridItems, setGridItems] = useState<Array<Camera | null>>(() =>
    new Array(4).fill(null)
  );
  const [dragCam, setDragCam] = useState<Camera>();
  const [viewMode, setViewMode] = useState<MultiCamViewMode>(
    MultiCamViewMode.LIVE
  );
  const [timezone, setTimezone] = useState<string>();
  const [allTimezones, setAllTimezones] = useState<string[]>([]);

  useEffect(() => {
    let allCamsTimezones: string[] = [];
    allCamsTimezones = gridItems.reduce((acc, item) => {
      item && acc.push(item?.location?.timezone || 'UTC');
      return acc;
    }, allCamsTimezones);

    const uniqueTimezones = Array.from(new Set(allCamsTimezones));
    setAllTimezones(uniqueTimezones);
    uniqueTimezones.length > 0 && setTimezone(uniqueTimezones[0]);
    const areAllItemsNull = gridItems.every((item) => item === null);
    if (areAllItemsNull) {
      setTimezone(undefined);
    }
  }, [gridItems]);

  const addCamera = () => {
    const updatedGridItems = [...gridItems, null];
    setGridItems(updatedGridItems);
  };
  const onDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    const droppedCam = active.data.current as Camera;
    const droppedGrid = over?.data.current;

    if (droppedCam && droppedGrid) {
      const droppedGridIndex = droppedGrid['index'];
      const updatedGridItems = [...gridItems];
      updatedGridItems[droppedGridIndex] = droppedCam;
      setGridItems(updatedGridItems);
    }
    setDragCam(undefined);
  };
  const onDragStart = (event: DragStartEvent) => {
    setDragCam(event.active.data.current as Camera);
  };

  const updateGridItems = (items: Array<Camera | null>) => setGridItems(items);

  const onChangeItemsSwitch = (item: SwitchItem) => {
    setViewMode(item.id as MultiCamViewMode);
    if (item.id === MultiCamViewMode.PLAYBACK) {
      const updatedGridItems = gridItems.map((gridItem) =>
        gridItem && !gridItem.isPlaybackEnabled ? null : gridItem
      );
      setGridItems(updatedGridItems);
    }
  };

  const updateTimezone = (val: string | null) => val && setTimezone(val);

  return (
    <DndContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
      <SplitViewWrapper ref={containerRef} classnames="p-1">
        <SplitView>
          <CameraGridList
            cameras={data?.items || []}
            isFetching={isFetching}
            viewMode={viewMode}
          />
        </SplitView>
        <SplitView>
          <>
            <div className="h-full w-full pl-4">
              {isError && (
                <div className="mb-2">
                  <Alert type="error">{error.message}</Alert>
                </div>
              )}
              <CameraGridHeader
                addCamera={addCamera}
                cameraCount={gridItems.length}
                isFullScreen={isFullScreen}
                onChangeItemsSwitch={onChangeItemsSwitch}
                toggleFullScreen={toggleFullScreen}
                viewMode={viewMode}
                timezone={timezone}
                allTimezones={allTimezones}
                updateTimezone={updateTimezone}
              />
              <GridItemsContainer
                items={gridItems}
                allCameras={data?.items}
                isFetching={isFetching}
                updateItems={updateGridItems}
                viewMode={viewMode}
                timezone={timezone}
              />
            </div>
            <DragOverlay>
              {dragCam && (
                <CameraGridListItem cam={dragCam} viewMode={viewMode} />
              )}
            </DragOverlay>
          </>
        </SplitView>
      </SplitViewWrapper>
    </DndContext>
  );
}
export default CameraGrid;
