const filterPredicate = (q: string, item: { name: string }) =>
  item.name.toLowerCase().includes(q.toLowerCase());

const isEqual = (q: string, item: { name: string }) => q === item.name;

const customItemBuilder = <T>(q: string, val: T[]) => ({
  id: `custom-option-${val.length + 1}`,
  name: q,
});

const isCustomItem = (item: { id: string }) =>
  item.id.startsWith('custom-option-');

export const selectHelpers = {
  filterPredicate,
  isEqual,
  customItemBuilder,
  isCustomItem,
};
