import { eventTracker } from '@hakimo-ui/hakimo/util';
import { Button } from '@hakimo-ui/shared/ui-base';
interface Props {
  canUpdate: boolean;
  isLoading: boolean;
  isValid: boolean;
  onClickSave: () => void;
  onClickClear: () => void;
  onClickReset: () => void;
}
function LabelPanelFooter(props: Props) {
  const {
    canUpdate,
    isLoading,
    isValid,
    onClickClear,
    onClickReset,
    onClickSave,
  } = props;
  return (
    <div className="mt-6 space-y-3">
      {canUpdate && (
        <>
          <Button
            disabled={isLoading || !isValid}
            variant="primary"
            block
            onClick={onClickSave}
            loading={isLoading}
            onSideEffect={eventTracker('save_door_label_details')}
          >
            Save
          </Button>
          <Button
            onClick={onClickReset}
            disabled={isLoading}
            block
            onSideEffect={eventTracker('reset_door_label_details')}
          >
            Reset
          </Button>
          <Button
            onClick={onClickClear}
            disabled={isLoading}
            variant="error"
            block
            onSideEffect={eventTracker('clear_door_label_details')}
          >
            Clear Label
          </Button>
        </>
      )}
    </div>
  );
}

export default LabelPanelFooter;
