import { AlarmTypeSelect } from '@hakimo-ui/hakimo/feature-shared';
import { ProcConfig } from '@hakimo-ui/hakimo/types';
import { Checkboxes } from '@hakimo-ui/shared/ui-base';
import { CalendarDaysIcon, ClockIcon } from '@heroicons/react/24/outline';
import { TimeSection } from './TimeSection';
import { ToggleSection } from './ToggleSection';
interface ExpandedConfigSectionProps {
  config: ProcConfig;
  onChange: (conf: Partial<ProcConfig>) => void;
  isEditing: boolean;
}
const DAYS_OF_WEEK = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
export function ExpandedConfigSection(props: ExpandedConfigSectionProps) {
  const { config, onChange, isEditing } = props;
  const daysCheckBox = DAYS_OF_WEEK.map((day, index) => {
    return {
      id: day,
      value: config.activeDays.at(index) ?? false,
      label: day,
    };
  });
  return (
    <>
      <div className="border-onlight-line-3 dark:border-ondark-line-3 mt-2 flex w-full flex-none gap-x-4 border-t px-6 pt-6">
        <div className="m-auto flex align-middle">
          <span className="sr-only">Client</span>
          <ClockIcon
            className="text-onlight-text-2 dark:text-ondark-text-2 h-5 w-5"
            aria-hidden="true"
          />
        </div>
        <div className="flex w-full gap-x-8 text-sm font-medium leading-6">
          <TimeSection
            title="Active Start Time"
            time={config.activeLocalTimeStart}
            onChange={(newTime) =>
              onChange({
                activeLocalTimeStart: newTime,
              })
            }
            isEditing={isEditing}
            tooltipText="Local Time at which this configuration will start being active"
          />
          <TimeSection
            title="Active End Time"
            time={config.activeLocalTimeEnd}
            onChange={(newTime) =>
              onChange({
                activeLocalTimeEnd: newTime,
              })
            }
            isEditing={isEditing}
            tooltipText="Local Time at which this configuration will end being active"
          />
        </div>
      </div>
      <div className="mt-4 flex w-full gap-x-4 px-6">
        <div className="m-auto flex align-middle">
          <span className="sr-only">Active Days</span>
          <CalendarDaysIcon
            className="text-onlight-text-2 dark:text-ondark-text-2 h-6 w-5"
            aria-hidden="true"
          />
        </div>
        <div className="dark:text-ondark-text-1 text-onlight-text-1 m-auto flex w-full flex-wrap gap-x-4 text-sm">
          {isEditing ? (
            <Checkboxes
              items={daysCheckBox}
              value={daysCheckBox.filter((item) => item.value)}
              onChange={(newDays) =>
                onChange({
                  activeDays: DAYS_OF_WEEK.map((day) =>
                    newDays.filter((item) => item.id === day).length > 0
                      ? true
                      : false
                  ),
                })
              }
            />
          ) : (
            daysCheckBox
              .filter((item) => item.value)
              .map((day) => <span key={day.id}>{day.id}</span>)
          )}
        </div>
      </div>
      <ToggleSection
        value={config.processVideo}
        title="Video Processing"
        onChange={(newVal) => {
          onChange({
            processVideo: newVal,
            enableTailgating: newVal && config.enableTailgating,
          });
        }}
        isEditing={isEditing}
      />
      <ToggleSection
        value={config.enableTailgating}
        title="Tailgating Detection"
        onChange={(newVal) => {
          onChange({
            enableTailgating: newVal,
            processVideo: config.processVideo || newVal,
          });
        }}
        isEditing={isEditing}
      />
      <ToggleSection
        value={!!config.enableUnauthorizedEntryAlarm}
        title="Unauthorised Entry Detection"
        onChange={(newVal) => {
          onChange({
            enableUnauthorizedEntryAlarm: newVal,
          });
        }}
        isEditing={isEditing}
      />
      <ToggleSection
        value={!config.enableForAllAlarmType}
        title="Enable For Specific Alarms"
        onChange={(newVal) => {
          onChange({
            enableForAllAlarmType: !newVal,
            alarm_types: !newVal ? null : config.alarm_types,
          });
        }}
        isEditing={isEditing}
      />
      {!config.enableForAllAlarmType && (
        <div className="px-6">
          <AlarmTypeSelect
            alarmTypes={config.alarm_types || []}
            isEditing={isEditing}
            onChange={(newVal) => {
              onChange({
                alarm_types: newVal,
              });
            }}
            label="Alarm Types"
          />
        </div>
      )}
    </>
  );
}
