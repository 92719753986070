import { SharedResourceTokenResponseDTO } from '@hakimo-ui/hakimo/types';
import useAuthenticatedRequest from '../shared/useAuthenticatedRequest';

export type ResourceType = 'alarm' | 'location-alarm' | 'live-view';

export function useSharedResourceToken(
  resourceId: string,
  duration: number,
  durationUnit: string,
  resourceType: ResourceType
) {
  const url = getSharedResourceTokenUrl(
    resourceId,
    resourceType,
    duration,
    durationUnit
  );
  const request = new Request(url);

  return useAuthenticatedRequest<SharedResourceTokenResponseDTO>(request, {
    responseModifier: async (response) => {
      const respJson = await response.json();
      return respJson.payload;
    },
    enabled: false,
    cacheTime: 0,
  });
}

function getSharedResourceTokenUrl(
  resourceId: string,
  resourceType: ResourceType,
  duration: number,
  durationUnit: string
) {
  let resourceName = '';
  let path = '';
  switch (resourceType) {
    case 'alarm':
      resourceName = 'alarm';
      break;
    case 'location-alarm':
      resourceName = 'location_alarm';
      break;
    case 'live-view':
      resourceName = 'live_view';
      break;
  }
  path = `/v2/orm/shared/${resourceName}/token/${resourceId}?duration=${duration}&durationUnit=${durationUnit}`;
  return path;
}

export default useSharedResourceToken;
