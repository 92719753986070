interface Props {
  beginTime: number;
  endTime: number;
}

export function AlarmHeader(props: Props) {
  const { beginTime, endTime } = props;

  return (
    <div className="h-9">
      <h2>Events</h2>
      <h3 className="text-onlight-text-2 dark:text-ondark-text-2 text-xs">
        {new Date(beginTime).toLocaleString()} -{' '}
        {new Date(endTime).toLocaleTimeString()}
      </h3>
    </div>
  );
}

export default AlarmHeader;
