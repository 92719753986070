import { LocationFilters, LocationFilterValue } from '@hakimo-ui/hakimo/types';
import { SlideOver } from '@hakimo-ui/hakimo/ui-elements';
import { useState } from 'react';
import { DEFAULT_LOCATION_FILTER_VALUE } from './constants';
import FilterPanelContent from './FilterPanelContent';

interface Props {
  open: boolean;
  filters: LocationFilters;
  onApplyFilters: (value: LocationFilters) => void;
  onClose: () => void;
}

function FilterPanel(props: Props) {
  const { open, onClose, filters, onApplyFilters } = props;

  const [locationFilterValue, setLocationFilterValue] =
    useState<LocationFilterValue>(filters.name);

  const onApplyFiltersCb = () => {
    onApplyFilters({
      name: locationFilterValue,
    });

    onClose();
  };

  const onResetFilters = () => {
    setLocationFilterValue(DEFAULT_LOCATION_FILTER_VALUE);
  };

  return (
    <SlideOver title="Locations Filters" open={open} onClose={onClose}>
      <FilterPanelContent
        locationFilterValue={locationFilterValue}
        onChangeLocationFilterValue={setLocationFilterValue}
        onApplyFilters={onApplyFiltersCb}
        onResetFilters={onResetFilters}
      />
    </SlideOver>
  );
}

export default FilterPanel;
