import { useDeleteAlarmNotificationPreference } from '@hakimo-ui/hakimo/data-access';
import { AlarmNotification } from '@hakimo-ui/hakimo/types';
import { toast } from '@hakimo-ui/hakimo/util';
import { Alert, Button, Modal } from '@hakimo-ui/shared/ui-base';
import { TrashIcon } from '@heroicons/react/24/outline';
import { useState } from 'react';

interface Props {
  item: AlarmNotification;
}

export function DeleteAlarmNotificationPreference(props: Props) {
  const { item } = props;
  const [showModal, setShowModal] = useState(false);
  const onCancel = () => {
    setShowModal(false);
  };
  const onSuccess = () => {
    toast('Alarm notification configuration removed successfully', {
      type: 'success',
    });
    onCancel();
  };
  const mutation = useDeleteAlarmNotificationPreference(item.id, onSuccess);
  const onConfirm = () => {
    mutation.mutate({});
  };
  const actions = (
    <>
      <Button variant="error" onClick={onConfirm} loading={mutation.isLoading}>
        Delete
      </Button>
      <Button variant="outline" onClick={onCancel}>
        Cancel
      </Button>
    </>
  );
  return (
    <div>
      <Button variant="icon" onClick={() => setShowModal(true)}>
        <TrashIcon className="h-6 w-6" />
      </Button>
      <Modal
        open={showModal}
        onClose={onCancel}
        footer={actions}
        title="Delete Alarm Notification Configuration"
      >
        <div className="max-w-3xl p-8">
          {mutation.isError ? (
            <div className="py-2">
              <Alert type="error">{mutation.error.message}</Alert>
            </div>
          ) : null}
          <div className="space-y-4">
            <p>
              Are you sure you want to delete the alarm notification with below
              configuration?
            </p>
            <div className="grid grid-cols-2 py-3 text-left font-semibold text-gray-700 dark:text-gray-400">
              <span>Notification Sound</span>
              <span>Alarm Types</span>
            </div>
            <div className="grid grid-cols-2 text-sm">
              <span>{item.notificationSound}</span>
              <div>
                {item.alarmTypes.map((alarmType, index) => (
                  <div key={index}>
                    <span>{alarmType}</span>
                    <br />{' '}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
export default DeleteAlarmNotificationPreference;
