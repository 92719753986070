import { AudioDevicesDTO } from '@hakimo-ui/hakimo/types';
import useAuthenticatedRequest from '../shared/useAuthenticatedRequest';

export function useAudioDevices(searchParams: string) {
  const params = searchParams.length > 0 ? `?${searchParams}` : '';
  const url = `/v2/orm/audio_devices${params}`;
  const request = new Request(url);

  return useAuthenticatedRequest<AudioDevicesDTO>(request, {
    queryKey: ['audio', { searchParams }],
    responseModifier: async (response) => {
      const respJson = await response.json();
      return respJson.payload;
    },
  });
}

export default useAudioDevices;
