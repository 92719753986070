import { useLocations } from '@hakimo-ui/hakimo/data-access';
import { MultiSelect } from '@hakimo-ui/hakimo/ui-elements';

interface Props {
  selectedLocationIds: number[];
  label?: string;
  onChange: (ids: number[]) => void;
}

export function LocationMultiSelect(props: Props) {
  const { selectedLocationIds, label, onChange } = props;

  const allLocationsResp = useLocations('');
  const allLocations = allLocationsResp.data?.items;

  const getItems = (query: string) => {
    const filteredLocations = query
      ? allLocations?.filter((loc) =>
          loc.name.toLowerCase().includes(query.toLowerCase())
        )
      : allLocations;
    return filteredLocations?.map((loc) => loc.id) || [];
  };

  const getDisplayValue = (item: number) => {
    // eslint-disable-next-line eqeqeq
    return allLocations?.find((loc) => loc.id == item)?.name || '';
  };

  return (
    <MultiSelect
      label={label}
      value={selectedLocationIds}
      onChange={onChange}
      getItems={getItems}
      displayValue={getDisplayValue}
      id={(item) => String(item)}
    />
  );
}

export default LocationMultiSelect;
