export function Dot() {
  return (
    <span
      className="mx-2 h-1 w-1 bg-gray-400 dark:bg-gray-600"
      aria-hidden="true"
    ></span>
  );
}

export default Dot;
