import { ReducedAlarm } from '@hakimo-ui/hakimo/types';
import { BasicPanel } from '@hakimo-ui/hakimo/ui-elements';
import { Table, TableData } from '@hakimo-ui/hakimo/ui-table';
import RegularAlarmNeighboringEvents from './RegularAlarmNeighboringEvents';
import SharedAlarmNeighboringEvents from './SharedAlarmNeighboringEvents';
import {
  getNeighboringAlarmsSearchParams,
  NEIGHBORING_ALARMS_AFTER,
  NEIGHBORING_ALARMS_BEFORE,
} from './util';

const searchParams = getNeighboringAlarmsSearchParams(
  NEIGHBORING_ALARMS_BEFORE,
  NEIGHBORING_ALARMS_AFTER
);
interface Props {
  alarm: ReducedAlarm;
  sharedToken?: string;
}

export function NeighboringEvents(props: Props) {
  const { alarm, sharedToken } = props;

  const renderContent = (tableData: TableData) => (
    <div className="pb-6">
      <Table data={tableData} embedded />
    </div>
  );

  return (
    <BasicPanel title="Neighboring Events">
      {sharedToken ? (
        <SharedAlarmNeighboringEvents
          alarm={alarm}
          sharedToken={sharedToken}
          searchParams={searchParams}
        >
          {renderContent}
        </SharedAlarmNeighboringEvents>
      ) : (
        <RegularAlarmNeighboringEvents
          alarm={alarm}
          searchParams={searchParams}
        >
          {renderContent}
        </RegularAlarmNeighboringEvents>
      )}
    </BasicPanel>
  );
}

export default NeighboringEvents;
