import { ReactNode, useContext, useReducer } from 'react';
import Context from './context';
import reducer from './reducer';
import { initialState } from './state';

interface Props {
  children: ReactNode;
}

export function StateProvider(props: Props) {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <Context.Provider value={{ state, dispatch }}>{children}</Context.Provider>
  );
}

export function useLocalState() {
  const { state } = useContext(Context);
  return state;
}

export function useLocalDispatch() {
  const { dispatch } = useContext(Context);
  return dispatch;
}

export default StateProvider;
