/* eslint-disable max-lines */
import {
  editOffer,
  getNonAdvertisedCodecs,
  parseOffer,
  requestICEServers,
  sendLocalCandidates,
  sendOffer,
} from './stream-manager-utils';
import { OfferData } from './types';

export function createStreamManager(
  url: string,
  videoRef: React.RefObject<HTMLVideoElement>,
  onErrorMessage: (msg: string) => void
) {
  const retryPause = 2000;

  let nonAdvertisedCodecs: string[] = [];
  let peerConnection: RTCPeerConnection | null = null;
  let restartTimeout: number | null = null;
  let sessionUrl = '';
  let offerData: OfferData | null = null;
  let queuedCandidates: RTCIceCandidate[] = [];

  function onError(err: string) {
    if (restartTimeout === null) {
      onErrorMessage(err + ', retrying in some seconds');

      if (peerConnection !== null) {
        peerConnection.close();
        peerConnection = null;
      }

      restartTimeout = window.setTimeout(async () => {
        restartTimeout = null;
        init();
      }, retryPause);

      if (sessionUrl !== '') {
        fetch(sessionUrl, {
          method: 'DELETE',
        });
      }
      sessionUrl = '';

      queuedCandidates = [];
    }
  }

  function onConnectionState() {
    if (restartTimeout !== null) {
      return;
    }

    if (peerConnection === null) {
      return;
    }

    if (peerConnection.iceConnectionState === 'disconnected') {
      onError('peer connection closed');
    }
  }

  async function onLocalCandidate(evt: RTCPeerConnectionIceEvent) {
    if (restartTimeout !== null) {
      return;
    }

    if (evt.candidate !== null) {
      if (sessionUrl === '') {
        queuedCandidates.push(evt.candidate);
      } else if (offerData !== null) {
        await sendLocalCandidates(sessionUrl, offerData, [evt.candidate]);
      }
    }
  }

  async function createOffer() {
    if (!peerConnection) {
      return;
    }
    const rtcSessionDescriptionInit = await peerConnection.createOffer();
    rtcSessionDescriptionInit.sdp = editOffer(
      nonAdvertisedCodecs,
      rtcSessionDescriptionInit.sdp
    );
    offerData = parseOffer(rtcSessionDescriptionInit.sdp);
    await peerConnection.setLocalDescription(rtcSessionDescriptionInit);
    const { sdpReply, sessionUrl: sUrl } = await sendOffer(
      rtcSessionDescriptionInit.sdp,
      url
    );
    sessionUrl = sUrl;
    await onRemoteAnswer(sdpReply);
  }

  async function onRemoteAnswer(sdp: string) {
    if (restartTimeout !== null) {
      return;
    }

    if (peerConnection === null) {
      return;
    }

    await peerConnection.setRemoteDescription(
      new RTCSessionDescription({
        type: 'answer',
        sdp,
      })
    );

    if (queuedCandidates.length !== 0 && offerData !== null) {
      await sendLocalCandidates(sessionUrl, offerData, queuedCandidates);
      queuedCandidates = [];
    }
  }

  function onTrack(evt: RTCTrackEvent) {
    if (videoRef.current === null) {
      return;
    }
    onErrorMessage('');
    videoRef.current.srcObject = evt.streams[0];
  }

  async function loadStream() {
    peerConnection = await requestICEServers(url);
    peerConnection.onicecandidate = onLocalCandidate;
    peerConnection.oniceconnectionstatechange = onConnectionState;
    peerConnection.ontrack = onTrack;
    await createOffer();
  }

  async function init() {
    try {
      nonAdvertisedCodecs = await getNonAdvertisedCodecs();
      await loadStream();
    } catch (error) {
      const errorMessage =
        error instanceof Error ? error.message : 'An error occurred';
      onError(errorMessage);
    }
  }

  function cleanup() {
    if (peerConnection !== null) {
      peerConnection.close();
      peerConnection = null;
    }
  }

  return {
    init,
    cleanup,
  };
}
