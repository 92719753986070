import { SMSDTO } from '@hakimo-ui/hakimo/types';
import useAuthenticatedMutation from '../shared/useAuthenticatedMutation';

export function useSendSMS(onSuccess: () => void) {
  const url = '/v2/orm/sms/twilio';
  const request = new Request(url, {
    method: 'POST',
  });
  return useAuthenticatedMutation<null, SMSDTO>(request, { onSuccess });
}
