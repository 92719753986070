import { Camera } from '@hakimo-ui/hakimo/types';
import { Label } from '@hakimo-ui/shared/ui-base';
import { MapPinIcon, SpeakerWaveIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';

interface Props {
  camera: Camera;
  selected?: boolean;
  onClick?: () => void;
  disabled?: boolean;
}

export function CameraItem(props: Props) {
  const { camera, selected, onClick, disabled } = props;

  return (
    <button
      onClick={onClick && onClick}
      className={clsx(
        'dark:bg-ondark-bg-2 bg-onlight-bg-2 hover:bg-onlight-bg-3 dark:hover:bg-ondark-bg-3 flex w-full cursor-pointer items-center justify-between rounded-md px-4 outline-none',
        selected &&
          'dark:outline-ondark-primary outline-onlight-primary bg-onlight-bg-3 dark:bg-ondark-bg-3 outline outline-offset-2',
        disabled &&
          'bg-onlight-bg-3/70 dark:bg-ondark-bg-3/70 pointer-events-none disabled:opacity-75'
      )}
    >
      <div className="grid grid-cols-1 grid-rows-2 gap-2 p-2">
        <span className="overflow-hidden overflow-ellipsis whitespace-nowrap text-start">
          {camera.name}
        </span>
        <div className="flex items-center gap-1 text-xs">
          <span>
            <MapPinIcon className="h-3 w-3" aria-hidden="true" />
          </span>
          <span>{camera.location?.name || '-'}</span>
        </div>
      </div>

      <span
        className="flex flex-col items-end gap-2"
        title={`Integration type - ${camera.integrationType}`}
      >
        <span className="flex items-center gap-1" title="Speaker mapped">
          {camera.audioDevices && camera.audioDevices?.length > 0 && (
            <SpeakerWaveIcon
              className="text-onlight-text-2 dark:text-ondark-text-2 w-5"
              aria-hidden="true"
            />
          )}

          <Label small type="info">
            {camera.integrationType}
          </Label>
        </span>
        <Label
          small
          text={camera.armStatus.isArmed ? 'Armed' : 'Disarmed'}
          type={camera.armStatus.isArmed ? 'success' : 'error'}
        />
      </span>
    </button>
  );
}

export default CameraItem;
