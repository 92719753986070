import { InputField } from '@hakimo-ui/shared/ui-base';

interface Props {
  label: string;
  value: string;
  onChange: (value: string) => void;
}

export function TextField(props: Props) {
  const { label, value, onChange } = props;

  return (
    <label className="grid h-14 grid-cols-12 items-center justify-between">
      <span className="col-span-4">{label}</span>
      <span className="col-span-8">
        <InputField
          type="text"
          value={value}
          onChange={(e) => onChange(e.target.value)}
        />
      </span>
    </label>
  );
}
