/* eslint-disable max-lines */
import {
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  MouseSensor,
  PointerSensor,
  TouchSensor,
  closestCenter,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  SortableContext,
  arrayMove,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { useLocationContacts } from '@hakimo-ui/hakimo/data-access';
import { LocationContact } from '@hakimo-ui/hakimo/types';
import { Alert, HakimoSpinner } from '@hakimo-ui/shared/ui-base';
import { useEffect, useState } from 'react';
import Actions from './Actions';
import DeleteContactModal from './DeleteContactModal';
import SortableItem from './SortableItem';
import { UpdateOrAddContactModel } from './UpdateOrAddContactModal';
import { ADD_CONTACT_ID_PREFIX, getColumns } from './utils';

interface Props {
  locationId: number;
  tenantId: string;
}

export function PointOfContact(props: Props) {
  const { locationId, tenantId } = props;
  const [contacts, setContacts] = useState<LocationContact[]>([]);
  const { data, isLoading } = useLocationContacts(locationId);
  const [updateContact, setUpdateContact] = useState<{
    mode: string;
    contact?: LocationContact;
  }>({
    mode: '',
  });
  const [deleteContactState, setDeleteContactState] = useState<{
    showModal: boolean;
    contact?: LocationContact;
  }>({
    showModal: false,
    contact: undefined,
  });
  const columns = getColumns();

  useEffect(() => {
    if (data) {
      const updatedData = [...data];
      updatedData.sort((a, b) => b.priority - a.priority);
      setContacts(updatedData);
    }
  }, [data]);

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (over && active.id !== over.id) {
      setContacts((items) => {
        const oldIndex = items.findIndex((item) => item.id === active.id);
        const newIndex = items.findIndex((item) => item.id === over.id);

        const updatedItems = arrayMove(items, oldIndex, newIndex);
        const length = updatedItems.length;
        return updatedItems.map((item, i) => ({
          ...item,
          priority: length - i - 1,
        }));
      });
    }
  };

  const onEditContact = (contact: LocationContact) => () => {
    setUpdateContact({ mode: 'edit', contact });
  };

  const onAddNewContact = () => setUpdateContact({ mode: 'add' });

  const onCloseModal = () => setUpdateContact({ contact: undefined, mode: '' });

  const onDeleteContact = (val: LocationContact) => () => {
    if (val.id.toString().includes(ADD_CONTACT_ID_PREFIX)) {
      // case where a new contact is added but not changes are not saved
      const updatedContacts = [...contacts].filter(
        (cont) => cont.id !== val.id
      );
      const prioUpdatedContacts = updatedContacts.map((item, i) => ({
        ...item,
        priority: updatedContacts.length - i - 1,
      }));
      setContacts(prioUpdatedContacts);
      return;
    }
    setDeleteContactState({ showModal: true, contact: val });
  };

  const onCloseDeleteModal = () => setDeleteContactState({ showModal: false });

  const onSubmitModal = (val: LocationContact) => {
    if (updateContact.mode === 'edit') {
      const modifiedContacts = contacts.map((ct) => {
        if (ct.id === val.id) {
          ct = val;
        }
        return ct;
      });
      setContacts(modifiedContacts);
    } else if (updateContact.mode === 'add') {
      const allContacts = [...contacts, val];
      const modifiedContacts = allContacts.map((item, i) => ({
        ...item,
        priority: allContacts.length - i - 1,
      }));
      setContacts(modifiedContacts);
    }
    onCloseModal();
  };

  const pointerSensor = useSensor(PointerSensor, {
    activationConstraint: {
      distance: 1,
    },
  });
  const mouseSensor = useSensor(MouseSensor);
  const touchSensor = useSensor(TouchSensor);
  const keyboardSensor = useSensor(KeyboardSensor);

  const sensors = useSensors(
    mouseSensor,
    touchSensor,
    keyboardSensor,
    pointerSensor
  );

  return (
    <DndContext
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
      sensors={sensors}
    >
      <div className="relative px-8 text-sm">
        <div className="mb-2">
          <Alert type="info">
            Higher priority contacts are displayed first. Drag and drop to
            rearrange the order
          </Alert>
        </div>
        <div className="text-onlight-text-2 dark:text-ondark-text-2 bg-onlight-bg-2 dark:bg-ondark-bg-2 grid grid-cols-5 px-4 py-6 font-bold">
          {columns.map((col) => (
            <div key={col.id}>{col.name}</div>
          ))}
        </div>
        {isLoading && (
          <>
            <div className="dark:bg-dark-bg/70 absolute inset-0 z-10 bg-white/70"></div>
            <div className="absolute inset-0 z-10 flex items-center justify-center">
              <HakimoSpinner />
            </div>
          </>
        )}
        {contacts.length === 0 && (
          <div className="text-onlight-text-3 dark:text-ondark-text-3 my-4 text-center text-sm">
            No contacts found for this location. Go ahead and add one.
          </div>
        )}
        <SortableContext
          items={contacts}
          strategy={verticalListSortingStrategy}
        >
          {contacts.map((contact) => (
            <SortableItem
              key={contact.id}
              contact={contact}
              onEdit={onEditContact(contact)}
              onDelete={onDeleteContact(contact)}
            />
          ))}
        </SortableContext>
        <Actions
          newData={contacts}
          oldData={data || []}
          onAddNewContact={onAddNewContact}
          locationId={locationId}
          tenantId={tenantId}
        />
      </div>
      {updateContact.mode && (
        <UpdateOrAddContactModel
          mode={updateContact.mode}
          contact={updateContact.contact}
          onClose={onCloseModal}
          onSubmitCb={onSubmitModal}
        />
      )}
      {deleteContactState.showModal && deleteContactState.contact && (
        <DeleteContactModal
          contact={deleteContactState.contact}
          onClose={onCloseDeleteModal}
        />
      )}
    </DndContext>
  );
}

export default PointOfContact;
