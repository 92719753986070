/* eslint-disable max-lines */
import { Camera } from '@hakimo-ui/hakimo/types';
import { trackEvent } from '@hakimo-ui/hakimo/util';
import clsx from 'clsx';
import { AnimatePresence, motion } from 'framer-motion';
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { CamAction, GridConfig, ScanMode } from '../../types/common';
import { EventHistory } from '../../types/event';
import CamFeed from './cam-feed/CamFeed';
import { calculateNewLayout, getNullRowsOrCols, removeColOrRow } from './utils';

export interface DynamicLayoutRef {
  addCameras: (cams: Camera[]) => void;
  getActiveCameras: () => Camera[];
  arrangeSmartly: () => void;
}

interface Props {
  onCameraAction: (actionType: CamAction, cameraId: string) => void;
  scanMode: ScanMode;
  escalationCamId: string;
  camsEventHistoryMap: Record<string, EventHistory[]>;
  isFullScreen: boolean;
  onCameraCountUpdate: (activeCameraCount: number) => void;
  gridConfig: GridConfig;
}

const DynamicLayout = forwardRef<DynamicLayoutRef, Props>((props, ref) => {
  const {
    onCameraAction,
    scanMode,
    escalationCamId,
    camsEventHistoryMap,
    isFullScreen,
    onCameraCountUpdate,
    gridConfig,
  } = props;
  const [visibleCameras, setVisibleCameras] = useState<(Camera | null)[]>([]);
  const [layout, setLayout] = useState({ rows: 0, cols: 0 });
  const containerRef = useRef<HTMLDivElement>(null);
  const [containerSize, setContainerSize] = useState({ width: 0, height: 0 });
  const [queuedCameras, setQueuedCameras] = useState<Camera[]>([]);

  const updateSize = () => {
    if (containerRef.current) {
      setContainerSize({
        width: containerRef.current.offsetWidth,
        height: containerRef.current.offsetHeight,
      });
    }
  };
  useEffect(() => {
    updateSize();
    containerRef.current && window.addEventListener('resize', updateSize);
    return () => window.removeEventListener('resize', updateSize);
  }, [escalationCamId, isFullScreen]);

  const getCamsToQueueAndAdd = (
    visibleCamsCount: number,
    cams: Camera[],
    maxCameraCount: number
  ) => {
    let camsToShow: Camera[] = [];
    let camsToQueue: Camera[] = [];
    const diff = maxCameraCount - (visibleCamsCount + cams.length);
    if (diff > 0) {
      camsToShow = cams;
    } else {
      const countCamsToQueue =
        visibleCamsCount + cams.length - maxCameraCount + 1;
      camsToShow = cams.slice(0, cams.length - countCamsToQueue);
      camsToQueue = cams.slice(cams.length - countCamsToQueue);
    }
    // If the limit is 16 cams,
    // 15 cams will be added to visible cams and rest will be added to queue
    return { camsToShow, camsToQueue };
  };

  const addCameras = useCallback(
    (camsToAdd: Camera[]) => {
      try {
        const { camsToShow: cams, camsToQueue } = getCamsToQueueAndAdd(
          visibleCameras.filter(Boolean).length,
          camsToAdd,
          gridConfig.cols * gridConfig.rows
        );

        if (camsToQueue.length > 0) {
          const queueCamIds = queuedCameras.map((cam) => cam.id);
          const updatedQueueCams = [...queuedCameras];
          for (const queuCam of camsToQueue) {
            if (!queueCamIds.includes(queuCam.id)) {
              updatedQueueCams.push(queuCam);
            }
          }
          setQueuedCameras(updatedQueueCams);
        }
        // all camsToAdd are queued
        if (cams.length === 0) {
          return;
        }

        const prevRows = layout.rows;
        const prevCols = layout.cols;
        const { rows: newRows, cols: newCols } = calculateNewLayout(
          visibleCameras.filter(Boolean).length + cams.length,
          prevRows,
          prevCols
        );
        let updatedCameras = [...visibleCameras];

        // Add new columns if necessary
        if (newCols > prevCols) {
          for (let i = 0; i < prevRows; i++) {
            for (let j = prevCols; j < newCols; j++) {
              updatedCameras.splice(i * newCols + j, 0, null);
            }
          }
        }

        // Add new rows if necessary
        if (newRows > prevRows) {
          const newRowsCount = newRows - prevRows;
          const newRowCameras = new Array(newRowsCount * newCols).fill(null);
          updatedCameras = [...updatedCameras, ...newRowCameras];
        }

        // Add new cameras to empty slots
        for (const cam of cams) {
          const emptyIndex = updatedCameras.findIndex(
            (camera) => camera === null
          );
          if (emptyIndex !== -1) {
            updatedCameras[emptyIndex] = cam;
          }
        }

        setLayout({ rows: newRows, cols: newCols });
        setVisibleCameras(updatedCameras);

        onCameraCountUpdate(updatedCameras.filter(Boolean).length);
        trackEvent('scan_camera_count', {
          camerasCount: updatedCameras.filter(Boolean).length,
          rows: newRows,
          cols: newCols,
        });
      } catch (error) {
        trackEvent('scan_error_adding_camera', {
          error: error,
          camsToAdd,
        });
        // console.error('Error adding cameras:', error);
      }
    },
    [
      gridConfig.cols,
      gridConfig.rows,
      layout.cols,
      layout.rows,
      onCameraCountUpdate,
      queuedCameras,
      visibleCameras,
    ]
  );

  const removeCamera = useCallback(
    (cams: Camera[]) => {
      let updatedCameras = [...visibleCameras];
      const camsToDeleteIds = cams.map((c) => c.id);
      for (let i = 0; i < updatedCameras.length; i++) {
        const updatedCam = updatedCameras[i];
        if (updatedCam && camsToDeleteIds.includes(updatedCam.id)) {
          updatedCameras[i] = null;
        }
      }
      const { nullRow: nullRowIndex, nullCol: nullColIndex } =
        getNullRowsOrCols(updatedCameras, layout.rows, layout.cols);
      let updatedRows = layout.rows;
      let updatedCols = layout.cols;
      if (nullRowIndex !== undefined) {
        updatedCameras = removeColOrRow(
          updatedCameras,
          layout.rows,
          layout.cols,
          nullRowIndex,
          true
        );
        updatedRows -= 1;
      }
      if (nullColIndex !== undefined) {
        updatedCameras = removeColOrRow(
          updatedCameras,
          layout.rows,
          layout.cols,
          nullColIndex,
          false
        );
        updatedCols -= 1;
      }

      const removeNullCams = (camsUpd: Array<Camera | null>) =>
        camsUpd.filter(Boolean);

      if (updatedRows > gridConfig.rows) {
        updatedRows = gridConfig.rows;
        updatedCameras = removeNullCams(updatedCameras);
      }
      if (updatedCols > gridConfig.cols) {
        updatedCols = gridConfig.cols;
        updatedCameras = removeNullCams(updatedCameras);
      }

      setLayout({ rows: updatedRows, cols: updatedCols });
      setVisibleCameras(updatedCameras);
      onCameraCountUpdate(updatedCameras.filter(Boolean).length);
      trackEvent('scan_camera_count', {
        camerasCount: updatedCameras.filter(Boolean).length,
        rows: updatedRows,
        cols: updatedCols,
      });
      updateSize();
    },
    [
      gridConfig.cols,
      gridConfig.rows,
      layout.cols,
      layout.rows,
      onCameraCountUpdate,
      visibleCameras,
    ]
  );

  useEffect(() => {
    // Filter out any falsy cameras
    const shownCameras: Camera[] = visibleCameras.filter(Boolean) as Camera[];
    const maxCamCount = gridConfig.rows * gridConfig.cols;
    const cameraThreshold = maxCamCount - 1;

    if (queuedCameras.length > 0 && shownCameras.length < cameraThreshold) {
      // Calculate how many cameras can be added
      const availableSlots = cameraThreshold - shownCameras.length;
      const camsToAdd = queuedCameras.slice(0, availableSlots);
      const updatedQueuedCameras = queuedCameras.slice(availableSlots);

      if (camsToAdd.length > 0) {
        addCameras(camsToAdd);
        setQueuedCameras(updatedQueuedCameras);
      }
    } else if (shownCameras.length > cameraThreshold) {
      // Calculate how many cameras need to be removed
      const excessCamerasCount = shownCameras.length - cameraThreshold;
      const camsToRemove = shownCameras.slice(-excessCamerasCount);

      if (camsToRemove.length > 0) {
        removeCamera(camsToRemove);
        setQueuedCameras((prevQueued) => [...prevQueued, ...camsToRemove]);
      }
    }
  }, [
    addCameras,
    gridConfig.cols,
    gridConfig.rows,
    queuedCameras,
    removeCamera,
    visibleCameras,
  ]);

  const getActiveCameras = () => [
    ...(visibleCameras.filter(Boolean) as Camera[]),
    ...queuedCameras,
  ];

  const arrangeSmartly = () => {
    const allCams = [...visibleCameras].filter(Boolean) as Camera[];
    // Group cameras by tenant
    const groupedCameras = allCams.reduce((acc, cam) => {
      const tenantId = cam.tenantId || 'unknown';
      if (!acc[tenantId]) {
        acc[tenantId] = [];
      }
      acc[tenantId].push(cam);
      return acc;
    }, {} as Record<string, Camera[]>);

    // Sort tenants by number of cameras (descending)
    const sortedTenants = Object.keys(groupedCameras).sort(
      (a, b) => groupedCameras[b].length - groupedCameras[a].length
    );

    // Calculate new layout
    const totalCameras = allCams.length;
    const newCols = Math.ceil(Math.sqrt(totalCameras));
    const newRows = Math.ceil(totalCameras / newCols);

    // Create a 2D array to represent the new layout
    const newLayout: (Camera | null)[][] = Array(newRows)
      .fill(null)
      .map(() => Array(newCols).fill(null));

    let currentCol = 0;
    let row = 0;

    // Place cameras in the new layout
    for (const tenantId of sortedTenants) {
      const tenantCameras = groupedCameras[tenantId];
      while (tenantCameras.length > 0) {
        newLayout[row][currentCol] = tenantCameras.shift() || null;
        row++;
        if (row >= newRows) {
          row = 0;

          currentCol++;
          if (currentCol >= newCols) break;
        }
      }
      if (currentCol >= newCols) break;
    }

    // Flatten the 2D array and ensure it has newCols * newRows elements
    const arrangedCameras = newLayout.flat();
    while (arrangedCameras.length < newCols * newRows) {
      arrangedCameras.push(null);
    }

    // Update state
    setLayout({ rows: newRows, cols: newCols });
    setVisibleCameras(arrangedCameras);
    trackEvent('scan_smart_rearrange', {
      camerasCount: arrangedCameras.filter(Boolean).length,
      rows: newRows,
      cols: newCols,
    });
  };

  useImperativeHandle(ref, () => ({
    addCameras,
    getActiveCameras,
    arrangeSmartly,
  }));

  const onSafe = (camera: Camera) => () => {
    trackEvent('event_marked_safe', {
      cameraId: camera.id,
      tenantId: camera.tenantId,
    });
    removeCamera([camera]);
    onCameraAction(CamAction.SAFE, camera.id);
  };

  const onSnooze = (camera: Camera) => () => {
    trackEvent('event_snoozed', {
      cameraId: camera.id,
      tenantId: camera.tenantId,
    });
    removeCamera([camera]);
    onCameraAction(CamAction.SNOOZE, camera.id);
    setTimeout(() => updateSize(), 0);
  };

  const onEscalate = (camera: Camera) => () => {
    trackEvent('event_escalated', {
      cameraId: camera.id,
      tenantId: camera.tenantId,
    });
    onCameraAction(CamAction.ESCALATE, camera.id);
  };

  const calculatePosition = useCallback(
    (index: number) => {
      const rows = layout.rows;
      const cols = layout.cols;
      const videoAspectRatio = 16 / 9;

      let cellWidth = containerSize.width / cols;
      let cellHeight = containerSize.height / rows;
      if (cellWidth / cellHeight > videoAspectRatio) {
        cellWidth = cellHeight * videoAspectRatio;
      } else {
        cellHeight = cellWidth / videoAspectRatio;
      }

      // Calculate the total space available for gaps
      const totalHorizontalGapSpace = containerSize.width - cellWidth * cols;
      const totalVerticalGapSpace = containerSize.height - cellHeight * rows;

      // Calculate gap sizes
      const horizontalGap = totalHorizontalGapSpace / (cols + 1);
      const verticalGap = totalVerticalGapSpace / (rows + 1);

      // Calculate the position
      const row = Math.floor(index / cols);
      const col = index % cols;

      const left = horizontalGap + col * (cellWidth + horizontalGap);
      const top = verticalGap + row * (cellHeight + verticalGap);

      return {
        left: `${left}px`,
        top: `${top}px`,
        width: `${cellWidth}px`,
        height: `${cellHeight}px`,
      };
    },
    [layout.rows, layout.cols, containerSize]
  );

  const queueLength = queuedCameras.length;

  return (
    <div
      className={clsx('relative h-[calc(100%)] max-h-full flex-grow')}
      ref={containerRef}
    >
      <AnimatePresence mode="popLayout">
        {visibleCameras.map((camera, index) => {
          return camera ? (
            <motion.div
              key={camera?.id || index}
              layout
              initial={{ opacity: 0, scale: 0.8 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.8 }}
              transition={{ duration: 0.4 }}
              className={clsx(
                'absolute flex flex-col overflow-hidden rounded-md p-[2px]'
              )}
              style={{
                ...calculatePosition(index),
              }}
            >
              <CamFeed
                name={camera.name}
                tenantName={camera.tenantId || ''}
                livestreamUrl={camera.livestreamUrl || ''}
                playbackUrl={camera.visionPlaybackUrl}
                hideActions={
                  scanMode === ScanMode.ESCALATION &&
                  camera.id === escalationCamId
                }
                onClickSafe={onSafe(camera)}
                onClickSnooze={onSnooze(camera)}
                onClickEscalate={onEscalate(camera)}
                eventHistory={camsEventHistoryMap[camera.id] || []}
              />
            </motion.div>
          ) : null;
        })}
        {queueLength > 0 && (
          <div
            style={{
              ...calculatePosition(gridConfig.cols * gridConfig.rows - 1),
            }}
            className={clsx(
              'absolute flex items-center justify-center rounded-md border font-bold',
              queueLength < 5 && 'text-green-500',
              queueLength >= 5 && queueLength < 10 && 'text-orange-500',
              queueLength >= 10 && 'text-red-500'
            )}
          >
            + {queueLength} Camera
          </div>
        )}
      </AnimatePresence>
    </div>
  );
});

export default DynamicLayout;
