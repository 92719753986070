import { Toggle } from '@hakimo-ui/shared/ui-base';

interface Props {
  name: string;
  value?: boolean;
  onToggle: () => void;
}

export function SettingFieldToggle(props: Props) {
  const { name, value = false, onToggle } = props;

  return (
    <div className="flex justify-between gap-x-6 pt-6">
      <div className="font-medium">{name}</div>
      <Toggle enabled={value} onChange={onToggle} />
    </div>
  );
}
