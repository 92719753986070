/*eslint-disable max-lines*/
import { SpaceWrapped } from '@hakimo-ui/hakimo/ui-elements';
import { eventTracker } from '@hakimo-ui/hakimo/util';
import { Button } from '@hakimo-ui/shared/ui-base';
import {
  ChevronDoubleRightIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from '@heroicons/react/24/outline';
import { ChevronDoubleLeftIcon } from '@heroicons/react/24/solid';
import clsx from 'clsx';
import { returnPaginationRange } from './utils';

interface Props {
  from: number;
  to: number;
  pageSize: number;
  total: number;
  disabled?: boolean;
  onChangePage: (pageNumber: number) => void;
}

export function Pagination(props: Props) {
  const { from, to, total, pageSize, disabled, onChangePage } = props;
  const currentPage = Math.ceil((to - 1) / pageSize);
  const siblings = 1;
  const totalPage = Math.ceil(total / pageSize);
  const arrayLargeScreen = returnPaginationRange(
    totalPage,
    currentPage,
    siblings,
    false
  );
  const arraySmallScreen = returnPaginationRange(
    totalPage,
    currentPage,
    siblings,
    true
  );

  const handlePageChange = (val: string | number) => {
    if (typeof val === 'number') {
      eventTracker('move_to_page_by_number');
      onChangePage(val);
    }
  };

  return (
    <nav
      className="@container flex items-center justify-between"
      aria-label="Pagination"
    >
      <div className=" flex flex-1 items-center justify-center">
        <div className="@md:block hidden">
          <Button
            onClick={() => handlePageChange(1)}
            disabled={disabled || total === 0 || from === 1}
            onSideEffect={eventTracker('move_first_page')}
            variant="icon"
            title="First Page"
          >
            <ChevronDoubleLeftIcon
              data-testid="First Page"
              className="h-5 w-5"
            />
          </Button>
        </div>
        <div className="@sm:pl-2 pl-1">
          <Button
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={disabled || total === 0 || from === 1}
            onSideEffect={eventTracker('move_previous_page')}
            variant="icon"
            title="Previous Page"
          >
            <ChevronLeftIcon data-testid="Previous" className="h-5 w-5" />
          </Button>
        </div>
        <div className="@lg:flex hidden items-center justify-center">
          {arrayLargeScreen.map((value, index) => {
            return (
              <ul key={index} className="pl-1">
                {currentPage === value ? (
                  <span className="bg-primary-600 relative inline-flex rounded-full border border-blue-600 py-1 px-2 text-sm text-white">
                    <span className="sr-only">You're on page</span> {value}
                  </span>
                ) : (
                  <button
                    className={clsx(
                      value === '...' && 'pointer-events-none',
                      'dark:text-ondark-text-2 text-onlight-text-3 rounded-full py-1 px-2 text-sm transition-colors duration-150 ease-in enabled:hover:bg-slate-200 dark:enabled:hover:bg-slate-700'
                    )}
                    onClick={() => handlePageChange(value)}
                    aria-label={`Page ${value}`}
                  >
                    {value}
                  </button>
                )}
              </ul>
            );
          })}
        </div>
        <div className="@lg:hidden flex items-center justify-center">
          {arraySmallScreen.map((value, index) => {
            return (
              <ul key={index} className="pl-1">
                {currentPage === value ? (
                  <span className="bg-primary-600 relative inline-flex rounded-full border border-blue-600 py-1 px-2 text-sm text-white">
                    <span className="sr-only">You're on page</span> {value}
                  </span>
                ) : (
                  <button
                    className={clsx(
                      value === '...' && 'pointer-events-none',
                      'dark:text-ondark-text-2 text-onlight-text-3 rounded-full py-1 px-2 text-sm transition-colors duration-150 ease-in enabled:hover:bg-slate-200 dark:enabled:hover:bg-slate-700'
                    )}
                    onClick={() => handlePageChange(value)}
                    aria-label={`Page ${value}`}
                  >
                    {value}
                  </button>
                )}
              </ul>
            );
          })}
        </div>

        <div className="pl-1 sm:pl-2">
          <Button
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={disabled || total === 0 || to === total}
            onSideEffect={eventTracker('move_next_page')}
            variant="icon"
            title="Next Page"
          >
            <ChevronRightIcon data-testid="Next" className="h-5 w-5" />
          </Button>
        </div>
        <div className="@md:block @md:pl-2 hidden pl-1">
          <Button
            onClick={() => handlePageChange(totalPage)}
            disabled={disabled || total === 0 || to === total}
            onSideEffect={eventTracker('move_last_page')}
            variant="icon"
            title="Last Page"
          >
            <ChevronDoubleRightIcon
              data-testid="Last Page"
              className="h-5 w-5"
            />
          </Button>
        </div>
      </div>

      <p className="@sm:block ml-auto hidden text-xs text-gray-500 dark:text-gray-400">
        {total > 0 && (
          <span>
            Showing
            <SpaceWrapped>
              <span className="font-medium">{from}</span>
            </SpaceWrapped>
            to
            <SpaceWrapped>
              <span className="font-medium">{to}</span>
            </SpaceWrapped>
            of
          </span>
        )}
        <SpaceWrapped>
          <span className="font-medium">{total}</span>
        </SpaceWrapped>
        results
      </p>
      <p className="@sm:hidden block text-xs text-gray-500 dark:text-gray-400">
        {from}-{to} of {total}
      </p>
    </nav>
  );
}

export default Pagination;
