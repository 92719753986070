import { AlarmUpdate } from '@hakimo-ui/hakimo/types';
import useAuthenticatedMutation from '../shared/useAuthenticatedMutation';

export function useUpdateAlarm(
  alarmId: string,
  onSuccess?: (data: null, variables: AlarmUpdate | null) => void
) {
  const url = `/v2/orm/alarm/update/${alarmId}`;

  const request = new Request(url, {
    method: 'PATCH',
  });

  return useAuthenticatedMutation<null, AlarmUpdate | null>(request, {
    onSuccessInvalidationKeys: [
      ['alarms'],
      ['alarm', alarmId],
      ['alarm_updates', alarmId],
    ],
    onSuccess,
  });
}

export default useUpdateAlarm;
