import { Link } from 'react-router-dom';
import SpaceWrapped from '../space-wrapped/SpaceWrapped';

interface Props {
  message?: string;
}

export function NotFound(props: Props) {
  const { message } = props;

  return (
    <div className="flex h-full items-center justify-center">
      <div className="dark:border-dark-border-bg flex flex-col items-center gap-2 rounded border p-16">
        <span role="img" aria-label="emoji eyes" className="text-4xl">
          👀
        </span>
        <span className="dark:text-dark-text">
          {message || "We couldn't find what you are looking for!"}
        </span>
        <span className="dark:text-dark-secondary-text text-sm text-gray-500">
          Click
          <SpaceWrapped>
            <Link to="/" className="text-primary-700 hover:text-primary-500">
              here
            </Link>
          </SpaceWrapped>
          to go back home
        </span>
      </div>
    </div>
  );
}

export default NotFound;
