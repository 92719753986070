import { User } from '@hakimo-ui/hakimo/types';
import { useEffect } from 'react';
import { IntercomSource } from './AppInit';

export function useIntercom(
  user: User,
  appId: string,
  enabled: boolean,
  source: IntercomSource
) {
  useEffect(() => {
    if (enabled) {
      window.Intercom('boot', {
        app_id: appId,
        email: user.email,
        user_id: user.id,
        name: user.name ?? 'Unknown',
        user_hash: user.userIntercomHash,
        source_app: source,
      });
    }
  }, [user, appId, enabled, source]);
}
