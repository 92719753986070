import useAuthenticatedMutation from '../shared/useAuthenticatedMutation';

export function useDeleteDoorGroup(id: number, onSuccess: () => void) {
  const url = `/v2/orm/door_groups/${id}`;

  const request = new Request(url, {
    method: 'DELETE',
  });

  return useAuthenticatedMutation(request, {
    onSuccessInvalidationKeys: [['door_groups']],
    onSuccess,
  });
}

export default useDeleteDoorGroup;
