import { useUpdateSOP } from '@hakimo-ui/hakimo/data-access';
import { SOPWorkflow } from '@hakimo-ui/hakimo/types';
import { toast } from '@hakimo-ui/hakimo/util';
import { Alert, Button, Textarea } from '@hakimo-ui/shared/ui-base';
import { PencilSquareIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { TrashIcon } from '@heroicons/react/24/solid';
import { useEffect, useState } from 'react';

interface Props {
  sopOverview: string;
  sopId: string;
  sopWorkflow?: SOPWorkflow;
}

export function SOPOverview(props: Props) {
  const { sopOverview, sopId, sopWorkflow } = props;
  const [isEditing, setIsEditing] = useState(false);
  const [updatedSOP, setUpdatedSOP] = useState(sopOverview);

  const onSuccess = () => {
    setIsEditing(false);
    toast('SOP overview updated successfully', { type: 'success' });
  };

  const mutation = useUpdateSOP(sopId, onSuccess);

  useEffect(() => {
    if (!isEditing) {
      setUpdatedSOP(sopOverview);
    }
  }, [isEditing, sopOverview]);

  const toggleEditing = () => setIsEditing((prev) => !prev);
  const onSOPChange = (val: string) => setUpdatedSOP(val);
  const onSubmitSOPChanges = () => {
    const payload = {
      sop_overview: updatedSOP,
      sop_workflow: sopWorkflow,
    };
    mutation.mutate({ sop_text: JSON.stringify(payload) });
  };
  const onCancelEdit = () => setIsEditing(false);

  const onDeprecateSOPOverview = () => {
    mutation.mutate({
      sop_text: JSON.stringify({ sop_workflow: sopWorkflow }),
    });
  };

  return (
    <div>
      <div className="border-onlight-line-2 dark:border-ondark-line-2 mb-2 flex items-center justify-between border-b p-2">
        <h2 className="font-bold">
          Standard Operating procedure (SOP) Overview
        </h2>
        <div className="flex items-center gap-2">
          <Button
            variant="icon"
            onClick={onDeprecateSOPOverview}
            disabled={mutation.isLoading}
            title="Depreciate SOP Overview"
          >
            <TrashIcon className="h-5 w-5 text-red-500" />
          </Button>
          <Button onClick={toggleEditing} variant="icon">
            {isEditing ? (
              <XMarkIcon className="h-5 w-5" />
            ) : (
              <span className="flex gap-2">
                <PencilSquareIcon className="h-5 w-5" />
                Edit
              </span>
            )}
          </Button>
        </div>
      </div>
      {isEditing ? (
        <>
          {mutation.isError && (
            <Alert type="error">{mutation.error.message}</Alert>
          )}
          <Textarea
            rows={20}
            value={updatedSOP}
            onChange={onSOPChange}
            placeholder="Compose the Standard Operating Procedure (SOP) here. Utilize HTML formatting to structure content seamlessly."
          />
          <div className="flex gap-4 px-2 py-4">
            <Button
              variant="primary"
              onClick={onSubmitSOPChanges}
              disabled={sopOverview === updatedSOP}
              loading={mutation.isLoading}
            >
              Submit
            </Button>
            <Button onClick={onCancelEdit}>Cancel</Button>
          </div>
        </>
      ) : (
        <div
          className="dark:bg-ondark-bg-2 bg-onlight-bg-2 mt-1 space-y-4 whitespace-pre-wrap p-3 text-sm"
          dangerouslySetInnerHTML={{
            __html: sopOverview,
          }}
        />
      )}
    </div>
  );
}

export default SOPOverview;
