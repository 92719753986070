import { BugReport } from '@hakimo-ui/hakimo/types';
import useAuthenticatedMutation from '../shared/useAuthenticatedMutation';

export function useReportBug(alarmId: string, onSuccess?: () => void) {
  const url = `/v2/orm/alarm/bug/${alarmId}`;

  const request = new Request(url, {
    method: 'POST',
  });

  return useAuthenticatedMutation<null, BugReport | null>(request, {
    onSuccess,
  });
}

export default useReportBug;
