import { Route, Routes } from 'react-router-dom';
import AudioDeviceList from './audio-device-list/AudioDeviceList';

export function AudioDevicesRoot() {
  return (
    <Routes>
      <Route path="/" element={<AudioDeviceList />} />
    </Routes>
  );
}

export default AudioDevicesRoot;
