import { MiniAudioPlayer } from '@hakimo-ui/hakimo/feature-shared';
import { AlarmNotification } from '@hakimo-ui/hakimo/types';
import { Select } from '@hakimo-ui/hakimo/ui-elements';
import { NOTIFICATION_SOUND_LIST } from '@hakimo-ui/hakimo/util';
import clsx from 'clsx';
import { useState } from 'react';

interface Props {
  item: AlarmNotification;
  isAdding: boolean;
  onChange: (item: string) => void;
  selectedNotificationSounds: string[];
}
export function SelectNotificationSound(props: Props) {
  const { item, isAdding, onChange, selectedNotificationSounds } = props;
  const [selectedNotificationSound, setSelectedNotificationSound] = useState(
    item.notificationSound
  );
  const onOptionChange = (val: string) => {
    setSelectedNotificationSound(val);
    onChange(val);
  };
  const getItems = (query: string) => {
    return NOTIFICATION_SOUND_LIST.filter((sound) =>
      sound.toLowerCase().includes(query.toLowerCase())
    );
  };
  const disabled = (sound: string) => {
    if (selectedNotificationSounds.includes(sound)) {
      return true;
    }
    return false;
  };

  return (
    <div className="flex flex-row gap-x-6">
      {isAdding ? (
        <div className="w-40">
          <Select
            value={item.notificationSound}
            displayValue={(sound) => sound || ''}
            placeholder="Select sound"
            getItems={(query) => getItems(query)}
            onChange={(val) => val && onOptionChange(val)}
            disabled={disabled}
          />
        </div>
      ) : (
        <p>{item.notificationSound}</p>
      )}
      <div
        className={clsx(
          selectedNotificationSound === '' &&
            'pointer-events-none cursor-default',
          'cursor-pointer'
        )}
      >
        <MiniAudioPlayer
          src={`assets/notif-sounds/${selectedNotificationSound}.mp3`}
          isLoading={false}
        />
      </div>
    </div>
  );
}
export default SelectNotificationSound;
