import { LogoutOptions, useAuth0 } from '@auth0/auth0-react';
import { environment } from '@hakimo-ui/hakimo/environments';
import { AuthUtils } from '@hakimo-ui/hakimo/types';
import { useLocalStorage } from '@hakimo-ui/hakimo/util';
import { ReactNode, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Auth0ProviderWithRedirectCallback from './Auth0ProviderWithRedirectCallback';
import AuthUtilsProvider from './AuthUtilsProvider';
import { listenForLogoutEvents, sendLogoutEvent } from './authWorker';

interface Props {
  children: ReactNode;
}

export const AuthProvider = (props: Props) => {
  const { children } = props;
  const [, , removeToken, getCurrent] = useLocalStorage<string>('token');
  const navigate = useNavigate();

  const localAuthUtils: AuthUtils = {
    getAccessToken: () => {
      const token = getCurrent();
      return token ? Promise.resolve(token) : Promise.reject();
    },
    logout: () => {
      removeToken();
      navigate('/login');
    },
  };

  return environment.authMode === 'auth0' ? (
    <Auth0ProviderWithRedirectCallback>
      <Auth0Auth>{children}</Auth0Auth>
    </Auth0ProviderWithRedirectCallback>
  ) : (
    <AuthUtilsProvider authUtils={localAuthUtils}>{children}</AuthUtilsProvider>
  );
};

function Auth0Auth({ children }: { children: ReactNode }) {
  const { getAccessTokenSilently, logout, user } = useAuth0();

  useEffect(() => {
    listenForLogoutEvents(logout);
  }, [logout]);

  const logoutAndPublish = (options?: LogoutOptions) => {
    logout(options);
    sendLogoutEvent();
  };

  return (
    <AuthUtilsProvider
      authUtils={{
        getAccessToken: getAccessTokenSilently,
        logout: logoutAndPublish,
        profilePic: user?.picture,
      }}
    >
      {children}
    </AuthUtilsProvider>
  );
}
