import { DoorsDTO } from '@hakimo-ui/hakimo/types';
import useAuthenticatedRequest from '../shared/useAuthenticatedRequest';

export function useDoors(searchParams: string) {
  const url = `/v2/orm/door?${searchParams}`;
  const request = new Request(url);

  return useAuthenticatedRequest<DoorsDTO>(request, {
    queryKey: ['doors', { searchParams }],
    responseModifier: async (response) => {
      const respJson = await response.json();
      return respJson.payload;
    },
  });
}

export default useDoors;
