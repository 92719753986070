import { NeighboringAlarmsDTO } from '@hakimo-ui/hakimo/types';
import useAuthenticatedRequest from '../shared/useAuthenticatedRequest';

export function useNeighboringAlarms(
  id: string,
  searchParams: string,
  refetchInterval: number
) {
  const url = `/v2/orm/alarm/neighbors/${id}?${searchParams}`;
  const request = new Request(url);

  return useAuthenticatedRequest<NeighboringAlarmsDTO>(request, {
    queryKey: ['alarm-neighbors', id, { searchParams }],
    responseModifier: async (response) => {
      const respJson = await response.json();
      return respJson.payload;
    },
    refetchInterval,
  });
}

export default useNeighboringAlarms;
