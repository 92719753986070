import { useHttpClient } from '@hakimo-ui/hakimo/util';

export function useGetLiveWsUrl(tap?: (response: Response) => void) {
  const httpClient = useHttpClient();

  return (name: string) => {
    const url = `/shared/v2/camera/wss?sharedToken=${name}`;

    return httpClient<string>(url, {
      responseModifier: async (res) => {
        const respJson = await res.json();
        return respJson.payload;
      },
      tap,
    });
  };
}

export default useGetLiveWsUrl;
