import { ModifyUserMspLocationsPayload } from '@hakimo-ui/hakimo/types';
import useAuthenticatedMutation from '../shared/useAuthenticatedMutation';

export function useUpdateMSPLocations(onSuccess: () => void) {
  const url = `/v2/orm/user/mspLocations`;

  const request = new Request(url, {
    method: 'PATCH',
  });

  return useAuthenticatedMutation<null, ModifyUserMspLocationsPayload | null>(
    request,
    {
      onSuccessInvalidationKeys: [['users']],
      onSuccess,
    }
  );
}
