import {
  CameraUpdateType,
  CameraUpdateZonePayload,
} from '@hakimo-ui/hakimo/types';
import useAuthenticatedMutation from '../shared/useAuthenticatedMutation';

export function useUpdateCameraZones(
  camId: string,
  updateType: CameraUpdateType,
  onSuccess?: () => void
) {
  const url = `/v2/orm/camera/${camId}/${updateType}`;

  const request = new Request(url, {
    method: 'PATCH',
  });

  return useAuthenticatedMutation<null, CameraUpdateZonePayload | null>(
    request,
    {
      onSuccessInvalidationKeys: [['cameras', camId]],
      onSuccess,
    }
  );
}

export default useUpdateCameraZones;
