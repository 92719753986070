import { Marker, Point } from '@hakimo-ui/hakimo/types';
import {
  ImagePanel,
  clientMarkerToImageMarker,
} from '@hakimo-ui/hakimo/ui-elements';
import { useAtom } from 'jotai';
import * as state from '../state';
import { ZoneWithType, getPolygonsMarkers } from './util';

interface Props {
  allZones: ZoneWithType[];
  partialZone: Point[] | undefined;
  selectedZone: string | undefined;
  imageUrl: string;
  imageResolution: Point | undefined;
  canUpdateZone: boolean;
  updateImageResolution: (val: Point) => void;
  onImageClick: (val: Marker) => void;
  onShapeClick: (val: Marker) => void;
}

export function ImagePanelWrapper(props: Props) {
  const {
    allZones,
    partialZone,
    selectedZone,
    imageUrl,
    imageResolution,
    canUpdateZone,
    updateImageResolution,
    onImageClick,
    onShapeClick,
  } = props;
  const [imageDisplayResolution, setimageDisplayResolution] = useAtom(
    state.imageDisplayResolutionAtom
  );
  const [imageTopLeft, setImageTopLeft] = useAtom(state.imageTopLeftAtom);

  const onClickImage: React.MouseEventHandler<HTMLImageElement> = (e) => {
    if (!imageResolution || !partialZone) {
      return;
    }
    const imgRect = e.currentTarget.getBoundingClientRect();
    setimageDisplayResolution([imgRect.width, imgRect.height]);
    setImageTopLeft([imgRect.left, imgRect.top]);
    const clickLocation: Marker = clientMarkerToImageMarker(
      [imgRect.width, imgRect.height],
      [imageResolution[0], imageResolution[1]],
      [imgRect.left, imgRect.top],
      { point: [e.clientX, e.clientY] }
    );

    onImageClick(clickLocation);
  };
  const onClickShape: React.MouseEventHandler<HTMLImageElement> = (e) => {
    if (
      !partialZone ||
      !imageResolution ||
      !imageDisplayResolution ||
      !imageTopLeft ||
      !canUpdateZone
    ) {
      return;
    }
    const clickLocation: Marker = clientMarkerToImageMarker(
      [imageDisplayResolution[0], imageDisplayResolution[1]],
      [imageResolution[0], imageResolution[1]],
      [imageTopLeft[0], imageTopLeft[1]],
      { point: [e.clientX, e.clientY] }
    );
    onShapeClick(clickLocation);
  };

  const onImageLoad = (
    imgResolution: Point,
    displayResolution: Point,
    imgTopLeft: Point
  ) => {
    updateImageResolution(imgResolution);
    setimageDisplayResolution(displayResolution);
    setImageTopLeft(imgTopLeft);
  };

  const [polygons, markers] = getPolygonsMarkers(
    allZones,
    partialZone,
    selectedZone
  );

  return (
    <div className="basis-4/5">
      <ImagePanel
        polygons={polygons}
        markers={markers}
        imgUrl={imageUrl}
        onClickImage={onClickImage}
        onClickShape={onClickShape}
        onImageLoadCallback={onImageLoad}
      />
    </div>
  );
}

export default ImagePanelWrapper;
