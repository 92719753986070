import {
  AlarmsRoot,
  LocationAlarmsRoot,
  MonitoringRoot,
  SharedAlarmRoot,
  SharedLocationAlarmRoot,
} from '@hakimo-ui/hakimo/feature-alarms';
import { AudioDevicesRoot } from '@hakimo-ui/hakimo/feature-audio-devices';
import { AuditLogRoot } from '@hakimo-ui/hakimo/feature-audit-log';
import {
  CamerasRoot,
  SharedLiveViewRoot,
} from '@hakimo-ui/hakimo/feature-cameras';
import { DoorGroupsRoot } from '@hakimo-ui/hakimo/feature-door-groups';
import { DoorsRoot } from '@hakimo-ui/hakimo/feature-doors';
import { InsightsRoot } from '@hakimo-ui/hakimo/feature-insights';
import { LocationsRoot } from '@hakimo-ui/hakimo/feature-locations';
import {
  AppInitOptions,
  AppMain,
  Login,
} from '@hakimo-ui/hakimo/feature-shared';
import { UserProfileRoot } from '@hakimo-ui/hakimo/feature-user-profile';
import { UsersRoot } from '@hakimo-ui/hakimo/feature-users';
import { AuthProvider, HttpClientProvider } from '@hakimo-ui/hakimo/providers';
import { NotFound } from '@hakimo-ui/hakimo/ui-elements';
import {
  ScanEscalationsRoot,
  ScanRoot,
} from '@hakimo-ui/libs/hakimo/feature-scan';
import { QueryClient, QueryClientProvider } from 'react-query';
import {
  Outlet,
  Route,
  createBrowserRouter,
  createRoutesFromElements,
} from 'react-router-dom';
import { SENTRY_DSN, isLocalOrAirgap } from './config';
import { DefaultRoute } from './routing/DefaultRoute';
import { navItems } from './routing/nav-items';

function Providers() {
  const queryClient = new QueryClient();
  return (
    <AuthProvider>
      <QueryClientProvider client={queryClient}>
        <HttpClientProvider>
          <Outlet />
        </HttpClientProvider>
      </QueryClientProvider>
    </AuthProvider>
  );
}
function getRouter() {
  const appInitOptions: AppInitOptions = {
    sentryDsn: SENTRY_DSN,
    sentryEnabled: !isLocalOrAirgap,
    amplitudeEnabled: !isLocalOrAirgap,
    intercomAppId: INTERCOM_APP_ID,
    intercomEnabled: !isLocalOrAirgap,
    intercomSource: 'hakimo',
  };

  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route element={<Providers />}>
        <Route path="/shared/alarm" element={<SharedAlarmRoot />} />
        <Route
          path="/shared/location-alarm"
          element={<SharedLocationAlarmRoot />}
        />
        <Route path="/shared/live-view" element={<SharedLiveViewRoot />} />
        <Route path="/login" element={<Login />} />
        <Route
          element={
            <AppMain navItems={navItems} appInitOptions={appInitOptions} />
          }
        >
          <Route path="/" element={<DefaultRoute />} />
          <Route path="/location-alarms/*" element={<LocationAlarmsRoot />} />
          <Route path="/monitoring/*" element={<MonitoringRoot />} />
          <Route path="/alarms/*" element={<AlarmsRoot />} />
          <Route path="/doors/*" element={<DoorsRoot />} />
          <Route path="/cameras/*" element={<CamerasRoot />} />
          <Route path="/users/*" element={<UsersRoot />} />
          <Route path="/insights/*" element={<InsightsRoot />} />
          <Route path="/profile/*" element={<UserProfileRoot />} />
          <Route path="/audit-log/*" element={<AuditLogRoot />} />
          <Route path="/locations/*" element={<LocationsRoot />} />
          <Route path="/doorgroups/*" element={<DoorGroupsRoot />} />
          <Route path="/audiodevices/*" element={<AudioDevicesRoot />} />
          <Route path="/scan/*" element={<ScanRoot />} />
          <Route path="/scan-escalations" element={<ScanEscalationsRoot />} />

          {/* add all new routes above this */}
          <Route path="*" element={<NotFound />} />
        </Route>
      </Route>
    )
  );

  return router;
}

export default getRouter;
