import { SOPTalkdown, SOPTalkdownType } from '@hakimo-ui/hakimo/types';
import {
  ItemsSwitch,
  SwitchItem,
  Textarea,
  Tooltip,
} from '@hakimo-ui/shared/ui-base';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import { talkdownTypeInfo } from './utils';

interface Props {
  talkdown: SOPTalkdown;
  onChange: (val: SOPTalkdown) => void;
}

export function WorkflowFormTalkdownItem(props: Props) {
  const { talkdown, onChange } = props;

  const switchOptions = [
    {
      id: SOPTalkdownType.STATIC,
      name: 'Static',
    },
    {
      id: SOPTalkdownType.DYANMIC,
      name: 'Dynamic',
    },
  ];

  const onChangeItemsSwitch = (item: SwitchItem) => {
    const updatedTalkdown = { ...talkdown, type: item.id as SOPTalkdownType };
    onChange(updatedTalkdown);
  };

  const onChangeText = (val: string) => {
    const updatedTalkdown = { ...talkdown, text: val };
    onChange(updatedTalkdown);
  };

  return (
    <div className="col-span-2 grid grid-cols-2 gap-2">
      <span className="flex items-center gap-2">
        <span>Talkdown type:</span>
        <Tooltip
          text={talkdownTypeInfo}
          colorModifier="info"
          position="top-right"
          size="large"
        >
          <InformationCircleIcon className="h-5 w-5" />
        </Tooltip>
      </span>
      <ItemsSwitch
        items={switchOptions}
        onChange={onChangeItemsSwitch}
        selectedItem={switchOptions.find((opt) => opt.id === talkdown.type)}
      />
      <span>Talkdown text:</span>
      <Textarea
        placeholder="Provide talkdown text"
        onChange={onChangeText}
        value={talkdown.text}
        rows={3}
      />
    </div>
  );
}

export default WorkflowFormTalkdownItem;
