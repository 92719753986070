/* eslint-disable max-lines */
import { useUpdateCameraZones } from '@hakimo-ui/hakimo/data-access';
import { CameraDetail, Marker, Point } from '@hakimo-ui/hakimo/types';
import { ExpandablePanel } from '@hakimo-ui/hakimo/ui-elements';
import {
  toast,
  useCanUpdateActiveZone,
  useCanUpdateDeadZones,
  useCanUpdateVehicleParkingZone,
} from '@hakimo-ui/hakimo/util';
import { Alert } from '@hakimo-ui/shared/ui-base';
import clsx from 'clsx';
import { useAtom } from 'jotai';
import { useEffect, useState } from 'react';
import * as state from '../state';
import ImagePanelWrapper from './ImagePanelWrapper';
import LabelPanel from './LabelPanel';
import {
  CamZoneState,
  CameraZone,
  checkUpdatePermission,
  getAllCamSections,
  getImagePanelSelectedIndex,
  getInitialCamZones,
  getPolygonColor,
  getZonesWithType,
  isClose,
  updateMarkerLocationToEdges,
} from './util';

interface Props {
  cam: CameraDetail;
  isDisabled?: boolean;
}

export function LabelCamera(props: Props) {
  const { cam, isDisabled } = props;
  const [activeCameraZone, setActiveCameraZone] = useState<
    CameraZone | undefined
  >(CameraZone.DeadZone);
  const canUpdateDeadZones = useCanUpdateDeadZones();
  const canUpdateVehicleParkingZone = useCanUpdateVehicleParkingZone();
  const canUpdateActiveZone = useCanUpdateActiveZone();

  const [imageResolution, setImageResolution] = useAtom(
    state.imageResolutionAtom
  );
  const [partialZone, setPartialZone] = useState<Point[] | undefined>();
  const [selectedZoneIndex, setSelectedZoneIndex] = useState<string>('0');
  const [camZoneState, setCamZoneState] = useState<CamZoneState>(
    getInitialCamZones(cam)
  );

  useEffect(() => {
    setPartialZone(undefined);
  }, [cam.id]);

  const updateDeadZoneMutation = useUpdateCameraZones(cam.id, 'deadzone', () =>
    toast('Deadzones updated', { type: 'success' })
  );

  const updateVehicleParkingZoneMutation = useUpdateCameraZones(
    cam.id,
    'vehicleparkingzone',
    () => toast('Vehicle parking zones updated', { type: 'success' })
  );

  const updateActiveZoneMutation = useUpdateCameraZones(
    cam.id,
    'activezone',
    () => toast('Active zones updated', { type: 'success' })
  );

  const canUpdateZone = checkUpdatePermission(
    activeCameraZone,
    canUpdateDeadZones,
    canUpdateVehicleParkingZone,
    canUpdateActiveZone
  );

  const addFinishedZoneToCamZoneState = (val: Point[]) => {
    const newCamZoneState = Object.assign({}, camZoneState);
    if (activeCameraZone === CameraZone.DeadZone) {
      newCamZoneState.deadZones.push(val);
    } else if (activeCameraZone === CameraZone.VehicleParkingZone) {
      newCamZoneState.vehicleParkingZones.push(val);
    } else if (activeCameraZone === CameraZone.ActiveZones) {
      newCamZoneState.activeZones.push(val);
    }
    setCamZoneState(newCamZoneState);

    activeCameraZone &&
      setSelectedZoneIndex(
        String(newCamZoneState[activeCameraZone].length - 1)
      );
  };

  const onImageClick = (clickLocation: Marker) => {
    const updatedClickLocation = updateMarkerLocationToEdges(
      clickLocation,
      imageResolution
    );
    setPartialZone((oldPartial) => {
      if (!oldPartial) {
        return [updatedClickLocation.point];
      }
      return [...oldPartial, updatedClickLocation.point];
    });
  };

  const onShapeClick = (clickLocation: Marker) => {
    if (
      partialZone &&
      partialZone.length > 2 &&
      isClose(clickLocation.point, partialZone[0], 10)
    ) {
      addFinishedZoneToCamZoneState(partialZone);
      setPartialZone(undefined);
    }
  };

  const onClickReset = () => {
    if (!canUpdateZone) {
      return;
    }
    setPartialZone(undefined);
    setCamZoneState(getInitialCamZones(cam));
  };

  const onClickClear = (camZone: CameraZone) => () => {
    setPartialZone(undefined);
    const updatedCamZoneState = Object.assign({}, camZoneState);
    updatedCamZoneState[camZone] = [];
    setCamZoneState(updatedCamZoneState);
  };

  const onNewZone = () => {
    if (!canUpdateZone) {
      return;
    }
    setPartialZone([]);
  };

  const onClickSave = (camZone: CameraZone) => () => {
    if (
      camZoneState[camZone] !== undefined &&
      imageResolution &&
      canUpdateZone
    ) {
      camZone === CameraZone.DeadZone &&
        updateDeadZoneMutation.mutate({
          deadZones: {
            deadZones: [...camZoneState.deadZones],
            labellingResolution: imageResolution,
          },
        });

      camZone === CameraZone.VehicleParkingZone &&
        updateVehicleParkingZoneMutation.mutate({
          vehicleParkingZones: {
            vehicleParkingZones: [...camZoneState.vehicleParkingZones],
            labellingResolution: imageResolution,
          },
        });
      camZone === CameraZone.ActiveZones &&
        updateActiveZoneMutation.mutate({
          activeZones: {
            activeZones: [...camZoneState.activeZones],
            labellingResolution: imageResolution,
          },
        });
    }
  };

  const onTogglePanel = (val: CameraZone) => () => {
    onClickReset();
    setSelectedZoneIndex('0');
    val === activeCameraZone
      ? setActiveCameraZone(undefined)
      : setActiveCameraZone(val);
  };

  const onDeleteZone = (val: CameraZone) => (idx: string) => {
    const index = parseInt(idx);
    const updatedDeadzones = [...camZoneState.deadZones];
    const updatedVehicleParkingZone = [...camZoneState.vehicleParkingZones];
    const updatedActiveZones = [...camZoneState.activeZones];
    if (val === CameraZone.DeadZone) {
      updatedDeadzones.splice(index, 1);
    } else if (val === CameraZone.VehicleParkingZone) {
      updatedVehicleParkingZone.splice(index, 1);
    } else if (val === CameraZone.ActiveZones) {
      updatedActiveZones.splice(index, 1);
    }
    setCamZoneState({
      deadZones: updatedDeadzones,
      vehicleParkingZones: updatedVehicleParkingZone,
      activeZones: updatedActiveZones,
    });
    const newSelectedZone = index > 0 ? String(index - 1) : '0';
    setSelectedZoneIndex(newSelectedZone);
  };

  const zonesWithType = getZonesWithType(camZoneState);
  const allCamSections = getAllCamSections();

  return (
    <>
      {isDisabled && (
        <Alert type="info">
          Editing special zones is being handled at camera level and cannot be
          edited here. This config can be updated in respective location. Please
          contact admin for more info.
        </Alert>
      )}
      <div
        tabIndex={isDisabled ? -1 : 0}
        className={clsx(
          'relative flex flex-col gap-6 lg:flex-row',
          isDisabled && 'pointer-events-none'
        )}
      >
        {isDisabled && (
          <div
            tabIndex={-1}
            className="absolute inset-0 z-10 rounded-lg bg-gray-500/30 bg-opacity-50"
          />
        )}
        <ImagePanelWrapper
          allZones={zonesWithType}
          canUpdateZone={canUpdateZone}
          imageResolution={imageResolution}
          updateImageResolution={setImageResolution}
          imageUrl={cam.imageUrl}
          partialZone={partialZone}
          selectedZone={getImagePanelSelectedIndex(
            selectedZoneIndex,
            activeCameraZone,
            camZoneState
          )}
          onImageClick={onImageClick}
          onShapeClick={onShapeClick}
        />
        <div className="dark:divide-ondark-line-2 divide-onlight-line-2 basis-1/5 space-y-6 divide-y">
          {allCamSections.map((section) => (
            <div className="w-full self-start pt-2" key={section.id}>
              <ExpandablePanel
                title={section.title}
                isExpanded={activeCameraZone === section.id}
                onToggle={onTogglePanel(section.id)}
                tagColor={getPolygonColor(section.id)}
              >
                <LabelPanel
                  label={section.title}
                  zones={camZoneState[section.id] ?? []}
                  partialZone={partialZone}
                  isLoading={
                    updateDeadZoneMutation.isLoading ||
                    updateVehicleParkingZoneMutation.isLoading ||
                    updateActiveZoneMutation.isLoading
                  }
                  selected={selectedZoneIndex}
                  onClickReset={onClickReset}
                  onClickClear={onClickClear(section.id)}
                  onClickSave={onClickSave(section.id)}
                  onSelect={setSelectedZoneIndex}
                  onDeleteZone={onDeleteZone(section.id)}
                  onNewZone={onNewZone}
                  canUpdateZones={canUpdateZone}
                />
              </ExpandablePanel>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default LabelCamera;
