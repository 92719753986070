import { DoorImageAPIResponse } from '@hakimo-ui/hakimo/types';
import useAuthenticatedRequest from '../shared/useAuthenticatedRequest';

export function useDoorImage(dcpId: string) {
  const url = `/v2/orm/dcp/${dcpId}/image-url`;
  const request = new Request(url);

  return useAuthenticatedRequest<DoorImageAPIResponse>(request, {
    responseModifier: async (response) => {
      const respJson = await response.json();
      return respJson.payload;
    },
  });
}

export default useDoorImage;
