import { useState } from 'react';
import { EscalationStep } from '../util';
import Escalation from './Escalation';
import PreEscalate from './PreEscalate';

interface Props {
  siteAddress: string;
  escalationPoints: string[];
  escalationProtocol: string[];
  siteGoogleMapLocation: string;
  locationId: string;
  locationAlarmId: string;
  isEscalated: boolean;
  locationTenantId?: string;
  onCancel: () => void;
}

export function EscalateAlarm(props: Props) {
  const {
    siteAddress,
    escalationPoints,
    escalationProtocol,
    locationId,
    siteGoogleMapLocation,
    locationAlarmId,
    isEscalated,
    locationTenantId,
    onCancel,
  } = props;

  const [escalationStep, setEscalationStep] = useState<EscalationStep>(
    isEscalated ? EscalationStep.ESCALATION : EscalationStep.PRE_ESCALATE
  );

  const onChangeEscalationStep = (val: EscalationStep) => () =>
    setEscalationStep(val);

  return (
    <>
      {escalationStep === EscalationStep.PRE_ESCALATE && (
        <PreEscalate
          locationId={locationId}
          locationAlarmId={locationAlarmId}
          navToEscalation={onChangeEscalationStep(EscalationStep.ESCALATION)}
          onCancel={onCancel}
        />
      )}
      {escalationStep === EscalationStep.ESCALATION && (
        <Escalation
          locationId={locationId}
          locationAlarmId={locationAlarmId}
          siteAddress={siteAddress}
          escalationPoints={escalationPoints}
          escalationProtocol={escalationProtocol}
          siteGoogleMapLocation={siteGoogleMapLocation}
          locationTenantId={locationTenantId}
        />
      )}
    </>
  );
}
