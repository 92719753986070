/* eslint-disable max-lines */
import {
  useAddLocation,
  useUpdateLocation,
} from '@hakimo-ui/hakimo/data-access';
import { TimezoneSelect } from '@hakimo-ui/hakimo/feature-shared';
import { Location, UpsertLocationRequestDTO } from '@hakimo-ui/hakimo/types';
import { toast } from '@hakimo-ui/hakimo/util';
import {
  Button,
  InputField,
  Modal,
  Textarea,
  Toggle,
} from '@hakimo-ui/shared/ui-base';
import { useState } from 'react';

type FormValues = UpsertLocationRequestDTO;

interface Props {
  onClose: () => void;
  mode: 'add' | 'edit';
  location?: Location;
}

const initialFormValue: UpsertLocationRequestDTO = {
  name: '',
  description: '',
  city: '',
  state: '',
  country: '',
  timezone: '',
  location_code: '',
  is_manned: true,
  isDeadzoneEnabled: true,
};

function AddUpdateLocation(props: Props) {
  const { onClose, mode, location } = props;
  const [formValue, setFormValue] = useState<UpsertLocationRequestDTO>(
    mode === 'edit' && location ? { ...location } : initialFormValue
  );

  const addLocationMutation = useAddLocation(() => {
    toast('Location added', { type: 'success' });
    onClose();
  });

  const updateLocationMutation = useUpdateLocation(
    () => {
      toast('Location updated', { type: 'success' });
      onClose();
    },
    location ? location.id : 0
  );

  const mutation =
    mode === 'add' ? addLocationMutation : updateLocationMutation;

  const onSubmit = () => {
    mutation.mutate(formValue);
  };
  const valuePending = Object.entries(formValue).some(([key, value]) => {
    if (value === '' && key === 'location_code') return false;
    return value === '';
  });

  const valueUpdater = (name: keyof FormValues) => {
    return (arg: React.ChangeEvent<HTMLInputElement> | string | boolean) => {
      setFormValue((value) => ({
        ...value,
        [name]:
          typeof arg === 'string' || typeof arg === 'boolean'
            ? arg
            : arg.target.value,
      }));
    };
  };

  const actions = (
    <>
      <Button
        variant="primary"
        onClick={onSubmit}
        disabled={valuePending || mutation.isLoading}
      >
        {mode === 'add' ? 'Submit' : 'Save'}
      </Button>
      <Button onClick={onClose}>Cancel</Button>
    </>
  );

  return (
    <Modal
      title={`${mode === 'add' ? 'Add' : 'Edit'} Location`}
      open
      onClose={onClose}
      footer={actions}
    >
      <div className="w-[36rem] space-y-4 p-8">
        <InputField
          type="text"
          label="Name"
          value={formValue.name}
          onChange={valueUpdater('name')}
        />
        <Textarea
          rows={2}
          label="Description"
          value={formValue.description}
          onChange={valueUpdater('description')}
        />
        <div className="grid grid-cols-2 gap-4">
          <InputField
            type="text"
            label="City"
            value={formValue.city}
            onChange={valueUpdater('city')}
          />
          <InputField
            type="text"
            label="State"
            value={formValue.state}
            onChange={valueUpdater('state')}
          />

          <InputField
            type="text"
            label="Country"
            value={formValue.country}
            onChange={valueUpdater('country')}
          />
          <TimezoneSelect
            value={formValue.timezone}
            onChange={valueUpdater('timezone')}
          />
          <InputField
            type="text"
            label="Location Code"
            value={formValue.location_code ? formValue.location_code : ''}
            onChange={valueUpdater('location_code')}
            placeholder="(Optional)"
          />
        </div>
        <div className="flex flex-col gap-6 pt-4">
          <Toggle
            enabled={!!formValue.is_manned}
            label="Staffed Location"
            onChange={valueUpdater('is_manned')}
            infoText="Indicate if this location is regularly staffed by personnel"
          />
          <Toggle
            enabled={!!formValue.isDeadzoneEnabled}
            label="Is Deadzone Enabled"
            onChange={valueUpdater('isDeadzoneEnabled')}
            infoText="Indicate if editing deadzone is allowed for this location"
          />
        </div>
      </div>
    </Modal>
  );
}
export default AddUpdateLocation;
