import {
  ResourceType,
  useSharedResourceToken,
} from '@hakimo-ui/hakimo/data-access';
import { SelectMenu } from '@hakimo-ui/hakimo/ui-elements';
import { capitalize, eventTracker } from '@hakimo-ui/hakimo/util';
import { Alert, Button, Modal } from '@hakimo-ui/shared/ui-base';
import { useEffect, useState } from 'react';
import CopyButton from './CopyButton';
import {
  DurationUnit,
  getDurationOptions,
  getSharedResourcePath,
} from './util';

export interface Props {
  resourceId: string;
  resourceType: ResourceType;
  onClose: () => void;
}

export function ShareResource(props: Props) {
  const { resourceId, resourceType, onClose } = props;
  const durationOptions = getDurationOptions(resourceType);
  const [duration, setDuration] = useState<number>(1);
  const [durationValues, setDurationValues] = useState<number[]>([]);
  const [durationUnit, setDurationUnit] = useState<DurationUnit>(
    resourceType === 'live-view' ? 'hour' : 'week'
  );

  const { data, isFetching, isError, error, refetch } = useSharedResourceToken(
    resourceId,
    duration,
    durationUnit,
    resourceType
  );

  useEffect(() => {
    const opts =
      durationOptions.find((o) => o.unit === durationUnit)?.allowedValues ?? [];
    setDurationValues(opts);
    setDuration((d) => (opts.includes(d) ? d : 1));
  }, [durationUnit, durationOptions]);

  const displayValueDurationUnit = (item?: DurationUnit) =>
    typeof item === 'string'
      ? capitalize(item) + (duration > 1 ? 's' : '')
      : '--Select--';

  const footer = (
    <>
      <span className="mr-2">
        <Button
          variant="primary"
          onSideEffect={eventTracker('generate_share_alarm_link')}
          onClick={() => refetch()}
          loading={isFetching}
          disabled={isFetching}
        >
          Generate Link
        </Button>
      </span>
      <Button
        onClick={onClose}
        onSideEffect={eventTracker('close_generate_share_alarm_link_modal')}
      >
        Close
      </Button>
    </>
  );

  const onCopyURL = (url: string) => {
    navigator.clipboard.writeText(url);
  };

  const getUrl = (accessToken: string) => {
    const path = getSharedResourcePath(resourceType, accessToken);
    return `${window.location.origin}${path}`;
  };

  const durationUnitOptions = durationOptions.map((o) => o.unit);

  return (
    <Modal
      open
      onClose={onClose}
      title="Generate Shareable Link"
      footer={footer}
    >
      <div className="w-[36rem] px-8 py-4">
        {isError && (
          <div className="mb-4">
            <Alert type="error">{error.message}</Alert>
          </div>
        )}
        <h2>Link Expiry Time</h2>
        <div className="mt-4 flex gap-4">
          <SelectMenu
            label="Duration"
            items={durationValues}
            displayValue={(item) =>
              typeof item === 'number' ? String(item) : '--Select--'
            }
            value={duration}
            onChange={(v) => setDuration(v)}
          />
          <SelectMenu
            label="Duration Unit"
            items={durationUnitOptions}
            displayValue={displayValueDurationUnit}
            value={durationUnit}
            onChange={(v) => setDurationUnit(v)}
          />
        </div>
        {data && (
          <div className="mt-4 flex items-center gap-2 text-lg">
            <div className="dark:border-dark-border-bg dark:bg-dark-surface overflow-x-scroll whitespace-nowrap break-words rounded border p-4 align-middle font-mono text-sm">
              {getUrl(data.accessToken)}
            </div>
            <CopyButton onClick={() => onCopyURL(getUrl(data.accessToken))} />
          </div>
        )}
      </div>
    </Modal>
  );
}

export default ShareResource;
