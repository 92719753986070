import clsx from 'clsx';
import { JSXElementConstructor, ReactNode } from 'react';

export interface SwitchItem {
  id: string;
  name: string;
  icon?: JSXElementConstructor<{ className: string }>;
  disabled?: boolean;
  extra?: ReactNode;
}

interface Props {
  items: SwitchItem[];
  selectedItem?: SwitchItem;
  onChange: (item: SwitchItem) => void;
}

export function ItemsSwitch(props: Props) {
  const { items, selectedItem, onChange } = props;

  const onItemClick = (id: string) => () => {
    const target = items.find((item) => item.id === id);
    target && onChange(target);
  };

  return (
    <nav className="flex space-x-4" aria-label="Tabs">
      {items.map((item) => {
        const IconComp = item.icon;
        return (
          <button
            key={item.id}
            onClick={onItemClick(item.id)}
            className={clsx(
              selectedItem?.id === item.id
                ? 'bg-primary-200 text-primary-700 dark:bg-primary-800 dark:text-primary-200'
                : 'text-onlight-text-3 hover:text-onlight-text-2 dark:text-ondark-text-2 dark:hover:text-ondark-text-1',
              'flex items-center gap-2 rounded-md px-3 py-2 text-sm font-medium'
            )}
          >
            {IconComp && <IconComp className="h-5 w-5" />}
            {item.name}
            {item.extra}
          </button>
        );
      })}
    </nav>
  );
}
