import { LoginRequestDTO, LoginResponseDTO } from '@hakimo-ui/hakimo/types';
import useAuthenticatedMutation from '../shared/useAuthenticatedMutation';

export function useLogin(onSuccess?: (data: LoginResponseDTO) => void) {
  const url = `/auth/login`;

  const request = new Request(url, {
    method: 'POST',
  });

  return useAuthenticatedMutation<LoginResponseDTO, LoginRequestDTO>(request, {
    onSuccess,
    noAuth: true,
  });
}

export default useLogin;
