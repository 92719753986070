import { useSharedAlarmNeighboringAlarms } from '@hakimo-ui/hakimo/data-access';
import { ReducedAlarm } from '@hakimo-ui/hakimo/types';
import { QueryResult } from '@hakimo-ui/hakimo/ui-elements';
import { TableData } from '@hakimo-ui/hakimo/ui-table';
import { ReactElement } from 'react';
import { getTableData } from './table-data';

const REFETCH_INTERVAL = 30000;

export interface Props {
  alarm: ReducedAlarm;
  searchParams: string;
  sharedToken: string;
  children: (tableData: TableData) => ReactElement;
}

export function SharedAlarmNeighboringEvents(props: Props) {
  const { alarm, searchParams, sharedToken, children } = props;

  const queryResult = useSharedAlarmNeighboringAlarms(
    sharedToken,
    searchParams,
    REFETCH_INTERVAL
  );

  return (
    <QueryResult queryResult={queryResult}>
      {(data) => children(getTableData(data, alarm))}
    </QueryResult>
  );
}

export default SharedAlarmNeighboringEvents;
