import { SystemNotificationPreference } from '@hakimo-ui/hakimo/types';
import {
  DescriptionList,
  DescriptionListItem,
} from '@hakimo-ui/hakimo/ui-elements';
import {
  audioNotifyAtom,
  isWebNotificationSupported,
  systemNotifyAtom,
  toast,
} from '@hakimo-ui/hakimo/util';
import { Alert } from '@hakimo-ui/shared/ui-base';
import { Switch } from '@headlessui/react';
import clsx from 'clsx';
import { useAtom } from 'jotai';
import { useEffect, useState } from 'react';
import { getBrowserSpecificMessage, systemNotifOptions } from '../../util';

export function Notifications() {
  const [notificationsEnabled, setNotificationsEnabled] =
    useAtom(audioNotifyAtom);

  const [systemNotifyPreference, setSystemNotifyPreference] =
    useAtom(systemNotifyAtom);

  const [notifPermission, setNotifPermission] = useState(
    isWebNotificationSupported()
      ? global.Notification.permission
      : 'notSupported'
  );

  useEffect(() => {
    if (isWebNotificationSupported() && Notification.permission !== 'granted') {
      setSystemNotifyPreference(SystemNotificationPreference.OFF);
    }
  }, [setSystemNotifyPreference]);

  const onChangeSystemNotifPref: React.ChangeEventHandler<
    HTMLInputElement
  > = async (event) => {
    const preference = event.target.id;
    if (preference !== SystemNotificationPreference.OFF) {
      const permission: NotificationPermission =
        await Notification.requestPermission();
      if (permission === 'granted') {
        setSystemNotifyPreference(preference as SystemNotificationPreference);
      } else if (permission !== 'denied') {
        toast('You must click "Allow" in the prompt to grant permission');
      }
      setNotifPermission(permission);
    } else {
      setSystemNotifyPreference(preference);
    }
  };

  const items: DescriptionListItem[] = [
    {
      name: 'Audio Notification',
      value: (
        <Switch
          checked={notificationsEnabled}
          onChange={setNotificationsEnabled}
          className={clsx(
            notificationsEnabled
              ? 'bg-primary-600'
              : 'dark:bg-dark-surface bg-gray-200',
            'focus:ring-primary-500 dark:focus:ring-offset-dark-bg relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-offset-2 sm:ml-auto'
          )}
        >
          <span
            aria-hidden="true"
            className={clsx(
              notificationsEnabled ? 'translate-x-5' : 'translate-x-0',
              'inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
            )}
          />
        </Switch>
      ),
    },
    {
      name: 'System Notification',
      infoText: `System notifications are OS-level alerts, make sure to enable browser notifications for full functionality.\n
        Off: No notification will be shown.
        When inactive: Recieve notifications when not actively using the app.
        Always: Always recieve notifications`,
      value: (
        <span className="flex flex-shrink-0 items-start space-x-4">
          <fieldset>
            <legend className="sr-only">System Notification</legend>
            <div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10">
              {systemNotifOptions.map((option) => (
                <div key={option.id} className="flex items-center">
                  <input
                    id={option.id}
                    name="system-notification"
                    type="radio"
                    disabled={notifPermission === 'notSupported'}
                    checked={systemNotifyPreference === option.id}
                    className="text-primary-600 focus:ring-primary-500 dark:checked:bg-primary-500 dark:focus:ring-primary-600 h-4 w-4 border-gray-300 bg-gray-100 focus:ring-2 dark:border-none dark:bg-gray-700 dark:ring-offset-gray-800"
                    onChange={onChangeSystemNotifPref}
                  />
                  <label
                    htmlFor={option.id}
                    className="dark:text-dark-text ml-3 block flex-shrink-0 text-sm font-medium text-gray-700"
                  >
                    {option.title}
                  </label>
                </div>
              ))}
            </div>
          </fieldset>
        </span>
      ),
    },
  ];

  const browserMessage = getBrowserSpecificMessage();

  return (
    <>
      <DescriptionList items={items} />
      {notifPermission === 'denied' && (
        <Alert>
          <div className="flex flex-col">
            <span>
              Notification permission has been denied. Please go to your browser
              settings and enable notifications for this website.
            </span>
            {browserMessage && <span className="">Go to {browserMessage}</span>}
          </div>
        </Alert>
      )}
      {notifPermission === 'notSupported' && (
        <Alert>
          <div className="flex flex-col">
            <span>
              Web Notifications are not supported in this browser / device.
            </span>
          </div>
        </Alert>
      )}
    </>
  );
}

export default Notifications;
