import { TableauUrlResponse } from '@hakimo-ui/hakimo/types';
import useAuthenticatedRequest from '../shared/useAuthenticatedRequest';

export function useTableauUrl() {
  const url = `/v2/orm/tenant/tableau`;
  const request = new Request(url);

  return useAuthenticatedRequest<TableauUrlResponse>(request, {
    queryKey: ['insights', 'tableau'],
    responseModifier: async (response) => {
      const respJson = await response.json();
      return respJson.payload;
    },
  });
}

export default useTableauUrl;
