export function isWebNotificationSupported() {
  if (!window.Notification) {
    return false;
  }

  if (!('serviceWorker' in navigator)) {
    return false;
  }

  if (!('PushManager' in window)) {
    return false;
  }

  return true;
}
