import { ProcConfig } from '@hakimo-ui/hakimo/types';
import { Alert } from '@hakimo-ui/shared/ui-base';
import { SingleConfig } from './SingleConfig';

interface ConfigDisplayProps {
  procConfigs: ProcConfig[];
  onChangeSingleConfig: (id: number, newConf: Partial<ProcConfig>) => void;
  onResetConfig: (id: number) => void;
  isAdding: boolean;
}
export function ConfigDisplay(props: ConfigDisplayProps) {
  const { procConfigs, onChangeSingleConfig, onResetConfig, isAdding } = props;
  return (
    <>
      {procConfigs
        .sort((a, b) =>
          a.id < 0 ? Infinity : b.id < 0 ? -Infinity : a.id - b.id
        )
        .map((config) => (
          <SingleConfig
            key={config.id}
            config={config}
            onChange={(partialConfig) =>
              onChangeSingleConfig(config.id, partialConfig)
            }
            onResetConfig={() => onResetConfig(config.id)}
          />
        ))}
      {procConfigs.length === 0 && !isAdding && (
        <Alert>No processing configurations attached to this door group</Alert>
      )}
    </>
  );
}
