import { ProcConfig } from '@hakimo-ui/hakimo/types';
import { Button, InputField } from '@hakimo-ui/shared/ui-base';
import { CheckCircleIcon, PlusCircleIcon } from '@heroicons/react/24/outline';
import { XMarkIcon } from '@heroicons/react/24/solid';
import { useState } from 'react';
import { DEFAULT_PROC_CONF } from './util';

interface AddNewProcConfsProps {
  onAddNew: (conf: ProcConfig) => void;
  isAdding: boolean;
  onStartAdd: () => void;
  onCancelAdd: () => void;
}

export function AddNewProcConf(props: AddNewProcConfsProps) {
  const { isAdding, onStartAdd, onCancelAdd, onAddNew } = props;
  const [newConf, setNewConf] = useState<ProcConfig>(DEFAULT_PROC_CONF);
  return (
    <>
      {!isAdding && (
        <Button variant="icon" onClick={onStartAdd}>
          <PlusCircleIcon className="mr-2 h-5 w-5" />
          Add new processing config
        </Button>
      )}
      {isAdding && (
        <>
          <div>New processing configuration</div>
          <NewProcConfForm
            onCancel={() => {
              onCancelAdd();
              setNewConf(DEFAULT_PROC_CONF);
            }}
            config={newConf}
            onChange={(changedConf) =>
              setNewConf((oldConf) => {
                return { ...oldConf, ...changedConf };
              })
            }
            onSubmit={() => onAddNew(newConf)}
          />
        </>
      )}
    </>
  );
}

interface NewProcConfFormProps {
  onCancel: () => void;
  config: ProcConfig;
  onChange: (conf: Partial<ProcConfig>) => void;
  onSubmit: () => void;
}
function NewProcConfForm(props: NewProcConfFormProps) {
  const { config, onChange, onCancel, onSubmit } = props;
  return (
    <>
      <div className="mt-2">
        <InputField
          value={config.name}
          onChange={(e) => onChange({ name: e.currentTarget.value })}
          label="Processing Configuration Name"
          type="text"
        />
      </div>
      <div className="mt-2">
        <InputField
          value={config.description}
          onChange={(e) => onChange({ description: e.currentTarget.value })}
          label="Processing Configuration Description"
          type="text"
        />
      </div>
      <div className="mt-2 flex gap-x-2">
        <Button variant="icon" onClick={onSubmit}>
          <span className="flex gap-x-2">
            <CheckCircleIcon className="text-status-green h-5 w-5" />
            Submit
          </span>
        </Button>
        <Button variant="icon" onClick={onCancel}>
          <span className="flex gap-x-2">
            <XMarkIcon className="text-status-red h-5 w-5" />
            Cancel
          </span>
        </Button>
      </div>
    </>
  );
}
