import useAuthenticatedMutation from '../shared/useAuthenticatedMutation';

export function useDeleteLocationContact(
  tenant_id: string,
  locationId: number,
  contact_id: string,
  onSuccess: () => void
) {
  const url = `/v2/orm/locations/${tenant_id}/${locationId}/contacts/${contact_id}`;

  const request = new Request(url, {
    method: 'DELETE',
  });

  return useAuthenticatedMutation(request, {
    onSuccessInvalidationKeys: [['location-contacts']],
    onSuccess,
  });
}
export default useDeleteLocationContact;
