import { CameraPosition, DCPLabel } from '@hakimo-ui/hakimo/types';
import { getImageCoordinatesLabel } from '@hakimo-ui/hakimo/ui-elements';
import { useCanUpdateDcp } from '@hakimo-ui/hakimo/util';
import { Label, Radio, Selectable } from '@hakimo-ui/shared/ui-base';
import { checkValidity } from '../util';

import LabelPanelFooter from './LabelPanelFooter';

interface Props {
  label: DCPLabel;
  isLoading: boolean;
  onClickReset: () => void;
  onClickClear: () => void;
  onChangeCameraPosition: (position: CameraPosition) => void;
  onClickSave: () => void;
}

const cameraPositionOptions: Selectable[] = [
  {
    id: 'secure',
    name: 'On Secure Side',
  },
  {
    id: 'unsecure',
    name: 'On Unsecure Side',
  },
];

function LabelPanel(props: Props) {
  const {
    label,
    isLoading,
    onClickReset,
    onClickClear,
    onChangeCameraPosition,
    onClickSave,
  } = props;
  const doorMarkers = label?.doorMarkers ?? [];
  const floorMarker = label?.floorMarker;
  const cameraPosition = label?.cameraPosition ?? 'unknown';
  const canUpdate = useCanUpdateDcp();

  const isValid = checkValidity(label);

  const cameraPositionValue = cameraPositionOptions.find(
    (option) => option.id === cameraPosition
  ) ?? { id: 'unknown', name: '' };

  const onChangeCameraPositionValue = (value: Selectable) => {
    if (canUpdate) {
      onChangeCameraPosition(value.id as CameraPosition);
    }
  };

  return (
    <>
      <div>
        <h2 className="dark:text-dark-text font-medium text-gray-800">
          Door Markers
        </h2>
        <dl className="dark:divide-dark-border-bg dark:border-dark-border-bg mt-2 divide-y divide-gray-200 border-t border-b border-gray-200">
          {Array(4)
            .fill(null)
            .map((_, markerIndex) => (
              <div
                key={markerIndex}
                className="flex justify-between py-2 text-sm font-medium"
              >
                <dt className="dark:text-dark-text text-gray-500">
                  {markerIndex + 1}
                </dt>
                <dd className="dark:text-dark-text text-gray-700">
                  {getImageCoordinatesLabel(doorMarkers[markerIndex])}
                </dd>
              </div>
            ))}
        </dl>
      </div>
      <div className="mt-6">
        <h2 className="dark:text-dark-text font-medium text-gray-800">
          Floor Marker
        </h2>
        <dl className="dark:divide-dark-border-bg dark:border-dark-border-bg mt-2 divide-y divide-gray-200 border-t border-b border-gray-200">
          <div className="flex justify-between py-2 text-sm font-medium">
            <dt className="dark:text-dark-text text-gray-500">1</dt>
            <dd className="dark:text-dark-text text-gray-700">
              {getImageCoordinatesLabel(floorMarker)}
            </dd>
          </div>
        </dl>
      </div>
      <div className="mt-6">
        <h2 className="dark:text-dark-text font-medium text-gray-800">
          Camera Position
        </h2>
        <div className="dark:text-dark-text mt-2 text-gray-700">
          {canUpdate ? (
            <Radio
              items={cameraPositionOptions}
              value={cameraPositionValue}
              onChange={onChangeCameraPositionValue}
              id={(item) => item.id}
              displayValue={(item) => item.name}
            />
          ) : (
            <Label
              type="default"
              text={cameraPositionValue?.name ?? 'Unknown'}
            />
          )}
        </div>
      </div>
      <LabelPanelFooter
        canUpdate={canUpdate}
        isLoading={isLoading}
        isValid={isValid}
        onClickSave={onClickSave}
        onClickReset={onClickReset}
        onClickClear={onClickClear}
      />
    </>
  );
}

export default LabelPanel;
