import { HttpClient, RequestOptions } from '@hakimo-ui/hakimo/types';
import { handleResponse, handleUnauthorized } from './error';

export function createHttpClient(
  getAccessTokenSilently: () => Promise<string>,
  logout: () => void
): HttpClient {
  return async <T>(
    requestInfo: RequestInfo,
    options: RequestOptions<T> = {}
  ) => {
    const { noAuth = false } = options;

    const request = await getRequest(
      requestInfo,
      getAccessTokenSilently,
      noAuth
    );

    const modifyResponse =
      options?.responseModifier ??
      ((response: Response) => {
        return response.json();
      });

    function tap(response: Response) {
      options.tap && options.tap(response.clone());
      return response;
    }

    if (noAuth) {
      return fetch(request).then(handleResponse).then(tap).then(modifyResponse);
    }

    return fetch(request)
      .then(handleUnauthorized(logout))
      .then(handleResponse)
      .then(tap)
      .then(modifyResponse);
  };
}

async function getRequest(
  requestInfo: RequestInfo,
  getAccessTokenSilently: () => Promise<string>,
  noAuth: boolean
) {
  if (noAuth) {
    return new Request(requestInfo);
  } else {
    let accessToken = '';

    try {
      accessToken = await getAccessTokenSilently();
    } catch {
      throw Error('Failed to perform authenticated request');
    }

    const request = new Request(requestInfo);
    request.headers.set('Authorization', `Bearer ${accessToken}`);

    return request;
  }
}
