import ContactSMS from './ContactSMS';
import { VoiceCall } from './VoiceCall';
interface Props {
  toNumber: string;
  toName: string;
  locationAlarmId?: string;
  locationTenantId?: string;
}

function ContactConnect(props: Props) {
  const { toNumber, toName, locationAlarmId, locationTenantId } = props;

  return (
    <div className="p-2">
      <div className="text-start">{toName}</div>
      <div className="text-xs">{toNumber}</div>
      <div className="mt-2 flex justify-end gap-2">
        <VoiceCall
          toNumber={toNumber}
          toName={toName}
          locationAlarmId={locationAlarmId}
          locationTenantId={locationTenantId}
        />
        <ContactSMS toNumber={toNumber} toName={toName} />
      </div>
    </div>
  );
}

export default ContactConnect;
