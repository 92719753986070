import { useUpdateDoorGroupDetails } from '@hakimo-ui/hakimo/data-access';
import { DoorGroup, DoorGroupRequestDTO } from '@hakimo-ui/hakimo/types';
import { eventTracker, toast } from '@hakimo-ui/hakimo/util';
import {
  Alert,
  Button,
  InputField,
  Modal,
  Textarea,
} from '@hakimo-ui/shared/ui-base';
import { useState } from 'react';

interface Props {
  doorGroup: DoorGroup;
  onClose: () => void;
}

export function EditDoorGroupDetails(props: Props) {
  const { doorGroup, onClose } = props;
  const initialFormValue: DoorGroupRequestDTO = {
    name: doorGroup.name,
    description: doorGroup.description,
  };
  const [formValue, setFormValue] =
    useState<DoorGroupRequestDTO>(initialFormValue);

  const onSuccess = () => {
    toast('Door Group Details Updated', { type: 'success' });
    onClose();
  };

  const updateDoorGroupMutation = useUpdateDoorGroupDetails(
    doorGroup.id,
    onSuccess
  );

  const onSubmit = () => {
    updateDoorGroupMutation.mutate(formValue);
  };

  const valuePending = Object.entries(formValue).some(
    ([_, value]: [string, string]) => {
      return value === '';
    }
  );

  const valueUpdater =
    (name: keyof DoorGroupRequestDTO) =>
    (arg: React.ChangeEvent<HTMLInputElement> | string) => {
      setFormValue((value) => ({
        ...value,
        [name]: typeof arg === 'string' ? arg : arg.target.value,
      }));
    };

  const actions = (
    <>
      <Button
        variant="primary"
        onClick={onSubmit}
        disabled={valuePending || updateDoorGroupMutation.isLoading}
        onSideEffect={eventTracker('submit_update_door_group_details')}
        loading={updateDoorGroupMutation.isLoading}
      >
        Submit
      </Button>
      <Button
        onClick={onClose}
        onSideEffect={eventTracker('cancel_update_door_group_details')}
      >
        Cancel
      </Button>
    </>
  );

  return (
    <Modal
      title="Edit Door Group Details"
      open
      onClose={onClose}
      footer={actions}
    >
      {updateDoorGroupMutation.isError && (
        <div className="mt-4 px-8">
          <Alert type="error">{updateDoorGroupMutation.error.message}</Alert>
        </div>
      )}
      <div className="w-[36rem] space-y-4 p-8">
        <InputField
          type="text"
          label="Name"
          value={formValue.name}
          onChange={valueUpdater('name')}
        />
        <Textarea
          rows={2}
          label="Description"
          value={formValue.description}
          onChange={valueUpdater('description')}
        />
      </div>
    </Modal>
  );
}

export default EditDoorGroupDetails;
