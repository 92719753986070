import { Toggle } from '@hakimo-ui/shared/ui-base';

interface Props {
  label: string;
  value: boolean;
  onChange: (value: boolean) => void;
}

export function ToggleField(props: Props) {
  const { label, value, onChange } = props;

  return (
    <label className="grid h-14 grid-cols-12 items-center justify-between">
      <span className="col-span-4">{label}</span>
      <span className="col-span-8 justify-self-end">
        <Toggle enabled={value} onChange={onChange} />
      </span>
    </label>
  );
}
