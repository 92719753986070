import {
  AlarmUpdate,
  ExtraFeedback,
  HakimoOutput,
} from '@hakimo-ui/hakimo/types';
import { CheckboxItem } from '@hakimo-ui/shared/ui-base';

export type Feedback = CheckboxItem<ExtraFeedback>;
export type DisabledField = Extract<ExtraFeedback, 'tapTooLow' | 'tapTooHigh'>;

export const feedbackCheckboxItems: Feedback[] = [
  {
    id: 'missing-tag',
    value: 'missingTag',
    label: 'Missing Tag',
  },
  {
    id: 'extra-tag',
    value: 'extraTag',
    label: 'Extra Tag',
  },
  {
    id: 'tap-too-high',
    value: 'tapTooHigh',
    label: 'True Alarm Probability is too high',
  },
  {
    id: 'tap-too-low',
    value: 'tapTooLow',
    label: 'True Alarm Probability is too low',
  },
];

export interface RadioItem {
  id: HakimoOutput;
  name: string;
}

export const radioItems: RadioItem[] = [
  {
    id: 'correct',
    name: 'Correct',
  },
  {
    id: 'incorrect',
    name: 'Incorrect',
  },
];

export function getAlarmUpdateDTO(
  output: RadioItem,
  comment: string,
  feedbacks: Feedback[]
): AlarmUpdate {
  return {
    timestamp: new Date().toISOString(),
    feedback: {
      hakimoOutput: output.id,
      extraFeedback: feedbacks.map((v) => v.value),
    },
    comment: comment,
  };
}
export function isValidFeedback(
  feedbacks: Feedback[],
  comment: string
): boolean {
  return feedbacks.length > 0 || comment.length > 0;
}
