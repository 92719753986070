import { useRemoveDoorFromDoorGroup } from '@hakimo-ui/hakimo/data-access';
import { DoorGroup } from '@hakimo-ui/hakimo/types';
import { toast } from '@hakimo-ui/hakimo/util';
import { DoorGroupMembers } from './DoorGroupMembers';

interface Props {
  doorGroup: DoorGroup;
}

export function DoorGroupExpandedRow(props: Props) {
  const { doorGroup } = props;

  function onSuccess() {
    toast('Removed door from door group successfully', {
      type: 'success',
    });
  }
  const mutation = useRemoveDoorFromDoorGroup(onSuccess);

  const removeDoor = (doorId: string) => () => {
    mutation.mutate({
      doors: [{ door_group_id: String(doorGroup.id), doors: [doorId] }],
    });
  };

  return (
    <DoorGroupMembers
      onRemoveDoor={removeDoor}
      doorGroup={doorGroup}
      deleteDisabled={mutation.isLoading}
    />
  );
}

export default DoorGroupExpandedRow;
