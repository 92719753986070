import { Toggle } from '@hakimo-ui/shared/ui-base';
import { PauseCircleIcon, PlayCircleIcon } from '@heroicons/react/24/outline';

interface Props {
  enabled: boolean;
  onChange: (val: boolean) => void;
}

export function Autoplay(props: Props) {
  const { onChange, enabled } = props;

  return (
    <foreignObject width={50} height={25} x={5} y={0}>
      <Toggle
        enabled={enabled}
        onChange={onChange}
        EnabledIcon={PlayCircleIcon}
        DisabledIcon={PauseCircleIcon}
        title={`Auto-play is ${enabled ? 'on' : 'off'}`}
      />
    </foreignObject>
  );
}
