import { Selectable } from '@hakimo-ui/shared/ui-base';
import { PagedResponseDTO } from './common';

export type DoorGroupDTO = PagedResponseDTO<DoorGroup>;

export interface DoorGroup {
  description: string;
  id: number;
  name: string;
  doors: { id: string; name: string }[];
  processingConfigs: GroupProcessingConfig[];
}

export enum GroupPriority {
  ignore = 'ignore',
  P0 = 'P0',
  default = 'default',
}
export interface GroupProcessingConfig {
  id: number;
  name: string;
  description: string;
  priority: GroupPriority;
  enableTailgating: boolean;
  processVideo: boolean;
  activeLocalTimeStart: string;
  activeLocalTimeEnd: string;
  activeDays: boolean[];
  enableUnauthorizedEntryAlarm: boolean;
  enableForAllAlarmType: boolean;
  alarm_types?: string[] | null;
}
/* Internal type that can be used to represent processing configs that are new
or being modified */
export interface ProcConfig extends GroupProcessingConfig {
  updated: boolean;
  isNew: boolean;
  deleted: boolean;
  isEditing: boolean;
}

export type DoorGroupNameFilterValue = {
  values: Selectable[];
  negative: boolean;
};

export interface DoorGroupFilters {
  name: DoorGroupNameFilterValue;
}

export interface DoorGroupRequestDTO {
  name: string;
  description: string;
}

export interface AddDoorsToDoorGroupRequestDTO {
  doors: string[];
  operation: string;
}

export interface RemoveDoorFromDoorGroupDTO {
  doors: {
    door_group_id: string;
    doors: string[];
  }[];
}
export interface UpdateProcessingConfigRequest {
  processingConfigs: ProcConfig[];
}
