import { Alarm } from '@hakimo-ui/hakimo/types';
import useAuthenticatedRequest from '../shared/useAuthenticatedRequest';

export function useSharedAlarm(sharedToken: string) {
  const url = `/shared/v2/alarm?sharedToken=${sharedToken}`;
  const request = new Request(url);

  return useAuthenticatedRequest<Alarm>(request, {
    queryKey: ['alarms', 'shared', sharedToken],
    responseModifier: async (response) => {
      const respJson = await response.json();
      return respJson.payload;
    },
    noAuth: true,
  });
}

export default useSharedAlarm;
