import { useRemoveDCP } from '@hakimo-ui/hakimo/data-access';
import { Door } from '@hakimo-ui/hakimo/types';
import { eventTracker, toast } from '@hakimo-ui/hakimo/util';
import { Alert, Button, Modal } from '@hakimo-ui/shared/ui-base';

export type RemoveMappingModalProps = {
  open?: boolean;
  onClose: () => void;
  inputDoor?: Door;
};

function RemoveMappingModal(props: RemoveMappingModalProps) {
  const { open = false, onClose, inputDoor } = props;

  function onRemoveDcpSuccess() {
    toast('Removed Mapping Successfully', {
      type: 'success',
    });
    onClose();
  }

  const removeMappingMutation = useRemoveDCP(
    inputDoor ? inputDoor.dcp?.id : '',
    onRemoveDcpSuccess
  );

  const onCloseCb = () => {
    onClose();
    removeMappingMutation.reset();
  };
  const actions = (
    <>
      <Button
        variant="error"
        onClick={() => removeMappingMutation.mutate({})}
        loading={removeMappingMutation.isLoading}
        disabled={!inputDoor || !inputDoor.dcp?.id}
        onSideEffect={eventTracker('submit_delete_door_camera_mapping')}
      >
        Delete Mapping
      </Button>
      <Button
        onClick={onCloseCb}
        onSideEffect={eventTracker('cancel_delete_door_camera_mapping')}
      >
        Cancel
      </Button>
    </>
  );
  return (
    <Modal
      title="Remove Mapping"
      open={open}
      onClose={onCloseCb}
      footer={actions}
    >
      <div className="w-[36rem] p-8">
        {removeMappingMutation.isError ? (
          <div className="py-4 px-8">
            <Alert type="error">{removeMappingMutation.error.message} </Alert>
          </div>
        ) : null}
        <div className="space-y-4">
          <p>
            Are you sure you want to remove the mapping between{' '}
            <b>{inputDoor?.name}</b> and <b>{inputDoor?.dcp?.cameraName}</b>
          </p>
        </div>
      </div>
    </Modal>
  );
}

export default RemoveMappingModal;
