import { SetCameraArmedStatusDTO } from '@hakimo-ui/hakimo/types';
import useAuthenticatedMutation from '../shared/useAuthenticatedMutation';

export function useSetCameraArmedStatus(camId: string, onSuccess: () => void) {
  const url = `/v2/orm/camera/${camId}/armedStatus`;

  const request = new Request(url, {
    method: 'PATCH',
  });

  return useAuthenticatedMutation<null, SetCameraArmedStatusDTO>(request, {
    onSuccessInvalidationKeys: [['cameras']],
    onSuccess,
  });
}
