import { BasicPanel } from '@hakimo-ui/hakimo/ui-elements';
import { Alert, Label } from '@hakimo-ui/shared/ui-base';

interface Props {
  tags: string[];
}

function AlarmVideoTags(props: Props) {
  const { tags } = props;
  return (
    <BasicPanel title="Alarm Tags">
      {tags && tags.length > 0 ? (
        <div className="flex flex-wrap gap-2 bg-white p-6 dark:bg-gray-800">
          {tags.map((tag, idx) => (
            <span key={idx}>
              <Label text={tag} />
            </span>
          ))}
        </div>
      ) : (
        <div className="p-6">
          <Alert>No tags are assigned to this alarm</Alert>
        </div>
      )}
    </BasicPanel>
  );
}

export default AlarmVideoTags;
