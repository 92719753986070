import { useCanAddCamera, useDebounce } from '@hakimo-ui/hakimo/util';
import { Button, HakimoSpinner, InputField } from '@hakimo-ui/shared/ui-base';
import { FunnelIcon } from '@heroicons/react/24/outline';
import { useEffect, useState } from 'react';
import { AddCamera } from './add-camera/AddCamera';

export interface Props {
  isLoading: boolean;
  isDefault: boolean;
  onClickOpenFilterPanel: () => void;
  onSearch: (value: string) => void;
}

export function CameraListHeader(props: Props) {
  const { isLoading, onClickOpenFilterPanel, isDefault, onSearch } = props;
  const [addCamera, setAddCamera] = useState(false);
  const canAddCamera = useCanAddCamera();

  const [searchCameraValue, setSearchCameraValue] = useState('');
  const debouncedCameraValue = useDebounce(searchCameraValue, 400);

  useEffect(() => {
    onSearch(debouncedCameraValue);
  }, [debouncedCameraValue, onSearch]);

  const onSearchValChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchCameraValue(e.target.value);
  };

  return (
    <>
      <div className="flex flex-shrink-0 flex-col gap-4  py-1 pr-4 pl-1">
        <div className="flex flex-row items-center justify-between">
          <div className="flex flex-row items-center gap-4">
            <Button onClick={() => setAddCamera(true)}>Add Camera</Button>
            {isLoading && <HakimoSpinner />}
          </div>
          <div className="">
            <Button
              variant="icon"
              onClick={onClickOpenFilterPanel}
              badge={!isDefault}
            >
              <FunnelIcon className="dark:text-ondark-text-2 h-5 w-5" />
            </Button>
          </div>
        </div>
        <div className=" flex flex-row items-start justify-start gap-4">
          <InputField
            label=""
            onChange={onSearchValChange}
            value={searchCameraValue}
            type="search"
            placeholder="Search camera..."
          />
        </div>
      </div>
      {canAddCamera && addCamera && (
        <AddCamera onClose={() => setAddCamera(false)} />
      )}
    </>
  );
}

export default CameraListHeader;
