import clsx from 'clsx';
import { MouseEventHandler, ReactNode } from 'react';
import HakimoSpinner from '../spinner/HakimoSpinner';

interface Props {
  children: ReactNode;
  variant?: 'primary' | 'outline' | 'icon' | 'link' | 'error' | 'success';
  disabled?: boolean;
  block?: boolean;
  badge?: boolean;
  insetRing?: boolean;
  loading?: boolean;
  dataTestid?: string;
  type?: 'button' | 'submit';
  title?: string;
  autoFocus?: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  onSideEffect?: () => void;
  classNames?: string;
}

export function Button(props: Props) {
  const {
    children,
    variant = 'outline',
    disabled = false,
    block = false,
    badge = false,
    insetRing = false,
    loading = false,
    dataTestid,
    type = 'button',
    title,
    autoFocus,
    classNames,
    onClick,
    onSideEffect,
  } = props;

  const showBadge = variant === 'icon' && badge;

  const onClickCb: MouseEventHandler<HTMLButtonElement> = (e) => {
    onSideEffect && onSideEffect();
    onClick && onClick(e);
  };

  return (
    <button
      type={type}
      title={title}
      autoFocus={autoFocus}
      className={clsx(
        'focus-visible:ring-primary-500 dark:ring-offset-dark-bg inline-flex items-center justify-center border text-sm font-medium focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2',
        (variant === 'primary' ||
          variant === 'success' ||
          variant === 'outline' ||
          variant === 'error') &&
          'rounded-md px-4 py-2',
        variant === 'primary' &&
          'bg-primary-800 enabled:hover:bg-primary-900 dark:enabled:hover:bg-primary-700 border-transparent text-white',
        variant === 'error' &&
          'border-transparent bg-red-700 text-white enabled:hover:bg-red-600 dark:bg-red-800 dark:text-red-100 dark:enabled:hover:bg-red-700',
        variant === 'outline' &&
          'dark:text-dark-text border-gray-300 text-gray-700 enabled:hover:bg-gray-200 dark:border-gray-700 dark:enabled:hover:bg-gray-700',
        variant === 'icon' &&
          'dark:text-ondark-text-1 text-onlight-text-2 rounded-full border-transparent p-1 enabled:hover:bg-slate-200 dark:enabled:hover:bg-slate-700',
        variant === 'link' &&
          'text-ondark-primary rounded border-transparent px-2 enabled:hover:underline',
        variant === 'success' &&
          'border-transparent bg-green-500 text-white enabled:hover:bg-green-800 dark:enabled:hover:bg-green-700',
        disabled && 'disabled:opacity-75',
        block && 'w-full',
        (showBadge || loading) && 'relative',
        insetRing && 'ring-inset focus:ring-offset-0',
        classNames || ''
      )}
      disabled={disabled}
      onClick={onClickCb}
      data-testid={dataTestid}
    >
      {children}
      {loading && (
        <span className="absolute inset-0 inline-flex items-center justify-center bg-inherit">
          <HakimoSpinner />
        </span>
      )}
      {showBadge && (
        <span
          className="bg-primary-500 absolute top-1 right-1 h-2 w-2 rounded-full ring-1 ring-white"
          aria-hidden="true"
        ></span>
      )}
    </button>
  );
}

export default Button;
