interface Props {
  livestreamUrl: string;
}

export function LiveWebRtc(props: Props) {
  const { livestreamUrl } = props;

  return (
    <iframe
      src={livestreamUrl}
      title="live view"
      allowFullScreen
      className="aspect-video w-full"
    ></iframe>
  );
}
