import { useSourceEntitiesByName } from '@hakimo-ui/hakimo/data-access';
import { SourceEntityNameFilterValue } from '@hakimo-ui/hakimo/types';
import MultiSelectFilter from '../multi-select-filter/MultiSelectFilter';

interface Props {
  value: SourceEntityNameFilterValue;
  label: string;
  enableNegativeFilters?: boolean;
  onChange: (value: SourceEntityNameFilterValue) => void;
}

export function SourceEntityFilter(props: Props) {
  const { value, label, enableNegativeFilters = false, onChange } = props;
  const getSourceEntitiesByName = useSourceEntitiesByName();

  return (
    <MultiSelectFilter
      value={value}
      label={label}
      enableNegativeFilters={enableNegativeFilters}
      onChange={onChange}
      getOptions={getSourceEntitiesByName}
    />
  );
}

export default SourceEntityFilter;
