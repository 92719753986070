import { Selectable } from '@hakimo-ui/shared/ui-base';
import {
  incorrectDetectionOptions,
  personDetectedOptions,
  vehicleDetectedOptions,
} from '../../../constants';

export enum DetectionType {
  PERSON = 'person',
  VEHICLE = 'vehicle',
  INCORRECT_DETECTION = 'incorrect detection',
}

export interface StepperState {
  step: number;
  detectionType: DetectionType | undefined;
  comment: string;
}

const commentOptionsMap = {
  [DetectionType.PERSON]: personDetectedOptions,
  [DetectionType.VEHICLE]: vehicleDetectedOptions,
  [DetectionType.INCORRECT_DETECTION]: incorrectDetectionOptions,
};

export const getStatusCommentOptions = (
  detectionType: DetectionType
): Selectable[] => {
  const options = commentOptionsMap[detectionType];
  return options.map((item) => ({ id: String(item), name: item }));
};

export const getCommentPrefix = (type?: DetectionType) => {
  switch (type) {
    case DetectionType.PERSON:
      return 'Person Present';
    case DetectionType.VEHICLE:
      return 'Moving Vehicle';
    case DetectionType.INCORRECT_DETECTION:
      return 'False Positive';
    default:
      return '';
  }
};
