import { StatusType } from '@hakimo-ui/hakimo/types';

export function checkNeedsComment(
  oldStatus: StatusType,
  newStatus: StatusType | undefined,
  commentOptional: boolean
): boolean {
  if (commentOptional) {
    return false;
  }

  if (oldStatus === 'Pending' && newStatus === 'In Progress') {
    return false;
  }
  return true;
}
