import { Route, Routes } from 'react-router-dom';
import UserList from './user-list/UserList';

export function UsersRoutes() {
  return (
    <Routes>
      <Route path="/" element={<UserList />} />
      {/* <Route path=":userId" element={<UserDetailsRoot />} /> */}
    </Routes>
  );
}

export default UsersRoutes;
