import { AudioDevice } from '@hakimo-ui/hakimo/types';
import { Column as TableColumn, TableData } from '@hakimo-ui/hakimo/ui-table';
import { Button } from '@hakimo-ui/shared/ui-base';
import { PlusCircleIcon } from '@heroicons/react/24/outline';
import AutomatedTalkdownToggle from '../update-automated-talkdown/AutomatedTalkdownToggle';
export const columns: TableColumn[] = [
  {
    id: 'url',
    name: 'URL',
  },
  {
    id: 'location',
    name: 'Location',
    showAtBreakpoint: 'sm',
  },
  {
    id: 'enabled',
    name: 'Auto Talkdown Enabled',
    showAtBreakpoint: 'sm',
  },
  { id: 'action', name: '', showAtBreakpoint: 'sm' },
];

export function getTableData(
  items: AudioDevice[],
  canModifyAudioCamMap: boolean,
  onClickAddCam?: (item: AudioDevice) => void
): TableData {
  return {
    rows: items.map((audioDevice) => {
      return {
        id: String(audioDevice.id),
        cells: [
          {
            value: audioDevice.url,
          },
          {
            value: audioDevice.location.name,
          },
          {
            value: <AutomatedTalkdownToggle audioDevice={audioDevice} />,
          },
          {
            value:
              canModifyAudioCamMap && onClickAddCam ? (
                <Button
                  variant="icon"
                  onClick={() => onClickAddCam(audioDevice)}
                >
                  <PlusCircleIcon className="h-5 w-5" />
                </Button>
              ) : null,
          },
        ],
      };
    }),
    columns,
  };
}
