import { UpdateLocationContactsDTO } from '@hakimo-ui/hakimo/types';
import useAuthenticatedMutation from '../shared/useAuthenticatedMutation';

export function useUpdateLocationContacts(
  locationId: number,
  onSuccess: () => void
) {
  const url = `/v2/orm/locations/${locationId}/contacts`;

  const request = new Request(url, {
    method: 'POST',
  });

  return useAuthenticatedMutation<null, UpdateLocationContactsDTO>(request, {
    onSuccessInvalidationKeys: [['location-contacts']],
    onSuccess,
  });
}
export default useUpdateLocationContacts;
