import { NeighboringAlarmsDTO } from '@hakimo-ui/hakimo/types';
import useAuthenticatedRequest from '../shared/useAuthenticatedRequest';

export function useSharedAlarmNeighboringAlarms(
  sharedToken: string,
  searchParams: string,
  refetchInterval: number
) {
  const url = `/shared/v2/alarm/neighbors?sharedToken=${sharedToken}&${searchParams}`;
  const request = new Request(url);

  return useAuthenticatedRequest<NeighboringAlarmsDTO>(request, {
    queryKey: ['alarm-neighbors', 'shared', sharedToken, { searchParams }],
    responseModifier: async (response) => {
      const respJson = await response.json();
      return respJson.payload;
    },
    refetchInterval,
    noAuth: true,
  });
}

export default useSharedAlarmNeighboringAlarms;
