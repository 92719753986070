import useAuthenticatedRequest from '../../shared/useAuthenticatedRequest';

export function useGetPending(onSuccess: (data: string | undefined) => void) {
  const url = `/v2/orm/location_alarms/pending`;
  const request = new Request(url);

  return useAuthenticatedRequest<string | undefined>(request, {
    queryKey: ['location_alarms', 'pending'],
    responseModifier: async (response) => {
      const respJson = await response.json();
      return respJson.payload && respJson.payload.length > 0
        ? String(respJson.payload[0])
        : undefined;
    },
    enabled: false,
    cacheTime: 0,
    onSuccess,
  });
}

export default useGetPending;
