import { useGetLiveWsUrl } from '@hakimo-ui/hakimo/data-access';
import { HLSVideoPlayer } from '@hakimo-ui/hakimo/feature-shared';
import { VideoControlsList } from '@hakimo-ui/hakimo/types';
import { LiveMse, NotFound } from '@hakimo-ui/hakimo/ui-elements';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

interface Props {
  sharedToken: string;
}

function SharedLiveViewDetails(props: Props) {
  const { sharedToken } = props;
  const controlsList: VideoControlsList[] = [
    'noVideoTimeline',
    'noPlaybackRate',
  ];

  const [wsLiveUrl, setWsLiveUrl] = useState<string | undefined>(undefined);

  const getCameraWsUrl = useGetLiveWsUrl();

  useEffect(() => {
    getCameraWsUrl(sharedToken)
      .then((res) => {
        setWsLiveUrl(res);
      })
      .catch(() => {
        setWsLiveUrl(undefined);
      });
  }, [getCameraWsUrl, sharedToken]);

  const url = `${window.location.origin}/shared/v2/camera/live/index.m3u8?sharedToken=${sharedToken}`;

  return (
    <div className="container mx-auto">
      <h2 className="mt-2 flex items-center gap-2">
        <span className="bg-status-red h-1.5 w-1.5 animate-pulse rounded-full"></span>
        <span>Live</span>
      </h2>
      {wsLiveUrl !== undefined ? (
        <LiveMse mseUrl={wsLiveUrl} lockAspectRatio={true} />
      ) : (
        <HLSVideoPlayer
          videoPath={url}
          lockAspectRatio={true}
          controlsList={controlsList}
        />
      )}
    </div>
  );
}

export function SharedLiveViewRoot() {
  const [searchParams] = useSearchParams();
  const sharedToken = searchParams.get('sharedToken');

  if (sharedToken === null) {
    return (
      <NotFound message="Missing shared token in shared location alarm URL" />
    );
  }

  return <SharedLiveViewDetails sharedToken={sharedToken} />;
}

export default SharedLiveViewRoot;
