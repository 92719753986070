import { Button } from '@hakimo-ui/shared/ui-base';
import { BookmarkIcon } from '@heroicons/react/24/outline';
import { ChevronRightIcon } from '@heroicons/react/24/solid';
import clsx from 'clsx';
import { getMarkerColor } from '../common-util';

interface Props {
  title: string;
  isExpanded: boolean;
  onToggle: () => void;
  children: React.ReactNode;
  tagColor?: string;
}

export function ExpandablePanel(props: Props) {
  const { title, onToggle, isExpanded, tagColor } = props;

  const onClick = () => onToggle();

  return (
    <div>
      <div className="bg-onlight-bg-2 dark:bg-ondark-bg-3 relative flex items-center justify-between rounded p-2">
        {tagColor && (
          <BookmarkIcon
            className={`absolute top-0 left-0 h-5 w-5 text-${getMarkerColor(
              tagColor
            )}`}
          />
        )}
        <h2 className="dark:text-dark-text pl-5 font-medium text-gray-800">
          {title}
        </h2>
        <Button
          variant="icon"
          title={clsx(isExpanded ? 'Collapse' : 'Expand')}
          onClick={onClick}
        >
          <ChevronRightIcon
            className={clsx(
              'h-5 w-5 transition duration-300',
              isExpanded && 'rotate-90 transform'
            )}
          />
        </Button>
      </div>
      {isExpanded && <div>{props.children}</div>}
    </div>
  );
}

export default ExpandablePanel;
