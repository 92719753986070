import { Pagination, Table, TableData } from '@hakimo-ui/hakimo/ui-table';
import { HakimoSpinner } from '@hakimo-ui/shared/ui-base';
import AlarmListHeader from '../alarm-list-header/AlarmListHeader';
import { FilterValues } from '../types';
import { columns } from '../util';

export interface Props {
  tableData: TableData;
  canViewTenantColumn: boolean;
  shownColumns: string[];
  isLoading: boolean;
  isRefetching: boolean;
  total: number;
  page: number;
  pageSize: number;
  filterValues: FilterValues;
  onChangeShownColumns: (columnsIds: string[]) => void;
  onOpenFilterPanel: () => void;
  onChangePage: (val: number) => void;
}

export function AlarmListTable(props: Props) {
  const {
    tableData,
    canViewTenantColumn,
    shownColumns,
    isLoading,
    isRefetching,
    total,
    page,
    pageSize,
    filterValues,
    onChangeShownColumns,
    onOpenFilterPanel,
    onChangePage,
  } = props;

  const onChangeShownColumnsCb = (selectedColumns: string[]) => {
    onChangeShownColumns(selectedColumns);
  };

  const selectableColumns = columns.filter(
    (col) => col.id !== 'tenant' || canViewTenantColumn
  );

  const fromIndex = (page - 1) * pageSize;
  const toIndex = Math.min(page * pageSize, total) - 1;

  const tableHeader = (
    <div className="dark:bg-dark-bg flex items-center justify-end gap-4 border-b bg-white p-4 dark:border-0">
      {isRefetching && <HakimoSpinner />}
      <AlarmListHeader
        selectableColumns={selectableColumns}
        filterValues={filterValues}
        alarmListShownColumns={shownColumns}
        onOpenFilterPanel={onOpenFilterPanel}
        onChangeShownColumns={onChangeShownColumnsCb}
      />
    </div>
  );

  const tableFooter = (
    <div className="dark:bg-dark-bg border-t bg-white p-4 dark:border-gray-800">
      <Pagination
        from={fromIndex + 1}
        to={toIndex + 1}
        pageSize={pageSize}
        total={total}
        disabled={isLoading}
        onChangePage={onChangePage}
      />
    </div>
  );

  return (
    <div className="dark:bg-dark-bg -mx-4 flex min-h-0 flex-1 flex-col justify-start overflow-y-hidden sm:-mx-6 md:mx-0 md:rounded-lg">
      <Table
        data={tableData}
        loading={isLoading}
        header={tableHeader}
        footer={tableFooter}
        scrollResetKey={page}
      />
    </div>
  );
}

export default AlarmListTable;
