import {
  useAddDCP,
  useCamerasByName,
  useDoorsByName,
} from '@hakimo-ui/hakimo/data-access';
import { Door } from '@hakimo-ui/hakimo/types';
import { SelectAsync } from '@hakimo-ui/hakimo/ui-elements';
import { eventTracker, toast } from '@hakimo-ui/hakimo/util';
import { Alert, Button, Modal, Selectable } from '@hakimo-ui/shared/ui-base';
import { useEffect, useState } from 'react';
export type AddMappingModalProps = {
  open?: boolean;
  onClose: () => void;
  inputDoor?: Door;
};

function AddMappingModal(props: AddMappingModalProps) {
  const { open = false, onClose, inputDoor } = props;
  const [door, setDoor] = useState<Selectable>({ id: '', name: '' });
  const [camera, setCamera] = useState<Selectable>({ id: '', name: '' });
  const addMappingMutation = useAddDCP(() => {
    onClose();
    toast('Mapping added', { type: 'success' });
  });
  const getDoorsByName = useDoorsByName();
  const getCamerasByName = useCamerasByName();
  useEffect(() => {
    if (inputDoor) {
      setDoor({ id: inputDoor.id, name: inputDoor.name });
    }
  }, [inputDoor]);
  const onCloseCb = () => {
    onClose();
    addMappingMutation.reset();
    setDoor({ id: '', name: '' });
    setCamera({ id: '', name: '' });
  };

  const actions = (
    <>
      <Button
        variant="primary"
        onClick={() => {
          addMappingMutation.mutate({
            // OR conditions not needed because button is disabled
            // if door or camera does not exist - needed for typescript
            // compiler
            doorName: door?.name || '',
            cameraName: camera?.name || '',
          });
        }}
        // Only enable if input exists
        disabled={
          door && camera && door.id !== '' && camera.id !== '' ? false : true
        }
        loading={addMappingMutation.isLoading}
        onSideEffect={eventTracker('submit_add_door_camera_mapping')}
      >
        Submit
      </Button>
      <Button
        onClick={onCloseCb}
        onSideEffect={eventTracker('cancel_add_door_camera_mapping')}
      >
        Cancel
      </Button>
    </>
  );
  return (
    <Modal title="Add Mapping" open={open} onClose={onCloseCb} footer={actions}>
      <div className="w-[36rem] p-8">
        {addMappingMutation.isError ? (
          <div className="py-4 px-8">
            <Alert type="error">{addMappingMutation.error.message} </Alert>
          </div>
        ) : null}
        <div className="space-y-4">
          <SelectAsync
            label="Door"
            value={door}
            onChange={setDoor}
            onChangeQuery={getDoorsByName}
            displayValue={(item) => (item ? item.name : '')}
            id={(item) => item.id}
          />
          <SelectAsync
            label="Camera"
            value={camera}
            onChange={setCamera}
            onChangeQuery={getCamerasByName}
            displayValue={(item) => (item ? item.name : '')}
            id={(item) => item.id}
          />
        </div>
      </div>
    </Modal>
  );
}

export default AddMappingModal;
