import { SOPDTO } from '@hakimo-ui/hakimo/types';
import useAuthenticatedMutation from '../shared/useAuthenticatedMutation';

export function useUpdateSOP(
  sopId: string,
  onSuccess?: () => void,
  onError?: (err: Error) => void
) {
  const url = `/v2/orm/sop/update/${sopId}`;

  const request = new Request(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
  });

  return useAuthenticatedMutation<null, SOPDTO | null>(request, {
    onSuccessInvalidationKeys: [['sop']],
    onSuccess,
    onError,
  });
}

export default useUpdateSOP;
