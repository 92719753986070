import * as amplitude from '@amplitude/analytics-browser';
import { environment } from '@hakimo-ui/hakimo/environments';
import { User } from '@hakimo-ui/hakimo/types';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { getRootNavigationEventName } from './analytics-util';

export function useAmplitude(user: User, enabled: boolean) {
  const location = useLocation();

  useEffect(() => {
    amplitude.init(environment.amplitudeApiKey, undefined, {
      optOut: !enabled,
      defaultTracking: {
        pageViews: {
          trackHistoryChanges: 'all',
        },
        sessions: true,
      },
    });

    /**
     * Adds environment name to all events sent to Amplitude
     */
    const addEnvironmentNamePlugin: amplitude.Types.Plugin = {
      name: 'addEnvironmentNamePlugin',
      type: amplitude.Types.PluginType.ENRICHMENT,
      async setup() {
        return;
      },
      async execute(context: amplitude.Types.Event) {
        context.event_properties = {
          ...context.event_properties,
          environment_name: environment.name,
        };
        return context;
      },
    };

    amplitude.add(addEnvironmentNamePlugin);
  }, [enabled]);

  useEffect(() => {
    const eventName = getRootNavigationEventName(location.pathname);
    if (eventName) {
      amplitude.track(eventName);
    }
  }, [location.pathname]);

  useEffect(() => {
    amplitude.setUserId(user.email);
    const identifyObj = new amplitude.Identify();
    identifyObj.set('role', user.roles[0]);
    identifyObj.set('tenant_name', user.tenant.name);
    identifyObj.set('msp_tenants', user.mspTenants?.map((t) => t.name) ?? []);
    identifyObj.set('is_hakimo_user', user.email.endsWith('@hakimo.ai'));
    amplitude.identify(identifyObj);
  }, [user]);
}
