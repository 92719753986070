import { useTableauUrl } from '@hakimo-ui/hakimo/data-access';
import { QueryResult } from '@hakimo-ui/hakimo/ui-elements';
import { Page } from '@hakimo-ui/hakimo/ui-layout';

export function InsightsRoot() {
  const result = useTableauUrl();

  return (
    <QueryResult queryResult={result}>
      {(data) => (
        <Page title="Insights">
          <div className="flex h-full">
            <iframe
              frameBorder="0"
              allowFullScreen
              title="Data Visualization"
              marginHeight={0}
              marginWidth={0}
              src={data.tableauUrl}
              width="100%"
            ></iframe>
          </div>
        </Page>
      )}
    </QueryResult>
  );
}

export default InsightsRoot;
