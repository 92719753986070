import { useEscalationEvents } from '@hakimo-ui/hakimo/data-access';
import { ScanEscalation } from '@hakimo-ui/hakimo/types';
import { QueryResult, Timeline } from '@hakimo-ui/hakimo/ui-elements';
import { Alert } from '@hakimo-ui/shared/ui-base';
import { getEscalationEvents } from './util';

interface Props {
  escalation: ScanEscalation;
}

export function EscalationUpdates(props: Props) {
  const { escalation } = props;
  const query = useEscalationEvents(escalation.id, 60000);

  return (
    <div className="space-y-1">
      <h2 className="dark:border-ondark-line-2 flex h-9 items-center border-b p-2">
        Escalation Events
      </h2>
      <QueryResult queryResult={query} loadingText="">
        {(data) => {
          const events = getEscalationEvents(escalation, data);
          return (
            <div className="dark:bg-ondark-bg-2 bg-onlight-bg-2 max-h-[30rem] overflow-auto">
              {events.length > 0 ? (
                <Timeline events={events} />
              ) : (
                <div className="p-6">
                  <Alert>No updates</Alert>
                </div>
              )}
            </div>
          );
        }}
      </QueryResult>
    </div>
  );
}

export default EscalationUpdates;
