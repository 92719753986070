import { PlaybackMotionMarkers } from '@hakimo-ui/hakimo/types';
import { memo, useMemo, useRef, useState } from 'react';
import { DEFAULT_SVG_WIDTH, MARGIN, SVG_HEIGHT } from '../constants';
import NavigateActions from './NavigateActions';
import SeekerAndMotion from './SeekerAndMotion';
import TicksContainer from './TicksContainer';
import ZoomActions from './ZoomActions';
import {
  ShownDates,
  ZoomMode,
  getShownDates,
  getTicks,
  getTimeStampFromX,
} from './util';

interface Props {
  timeZone: string;
  startDate: Date;
  endDate: Date;
  seekerTime: Date;
  onMarkerChange: (marker: Date) => void;
  playbackMotionMarkers: PlaybackMotionMarkers;
}

export function PlaybackTimeline(props: Props) {
  const {
    timeZone,
    startDate,
    endDate,
    seekerTime,
    onMarkerChange,
    playbackMotionMarkers,
  } = props;
  const containerRef = useRef<HTMLDivElement>(null);
  const [zoomMode, setZoomMode] = useState<ZoomMode>(ZoomMode.THREE_DAY);
  const [shownDates, setShownDates] = useState<ShownDates>(() => {
    return getShownDates(zoomMode, startDate, endDate, seekerTime, timeZone);
  });

  const allTicks = useMemo(
    () =>
      getTicks(shownDates.startDate, shownDates.endDate, zoomMode, timeZone),
    [shownDates.endDate, shownDates.startDate, timeZone, zoomMode]
  );
  const svgWidth = containerRef.current
    ? containerRef.current.clientWidth
    : DEFAULT_SVG_WIDTH;

  const tickGap = (svgWidth - MARGIN.left - MARGIN.right) / allTicks.length;

  const onRectClick = (event: MouseEvent) => {
    const rect = event.target as SVGRectElement;
    if (rect) {
      const svgRect = rect.getBoundingClientRect();
      const x = event.clientX - svgRect.left;
      const newSeekerTime = getTimeStampFromX(
        shownDates.startDate,
        x,
        tickGap,
        zoomMode
      );
      onMarkerChange(newSeekerTime);
    }
  };

  return (
    <div className="mt-4" key={`${zoomMode}`} ref={containerRef}>
      <svg
        key={`${zoomMode}`}
        width={svgWidth}
        height={SVG_HEIGHT}
        version="1.1"
        className="select-none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <TicksContainer
          allTicks={allTicks}
          svgWidth={svgWidth}
          tickGap={tickGap}
          zoomMode={zoomMode}
        />
        <g id="rect-box">
          <rect
            x={MARGIN.left}
            y="60"
            width={svgWidth - MARGIN.right - MARGIN.left}
            height="30"
            className="fill-status-gray stroke-status-gray cursor-pointer stroke-1 opacity-50 transition-opacity dark:opacity-100"
            onClick={(e) => onRectClick(e.nativeEvent)}
          />
          <line
            x1={MARGIN.left}
            x2={svgWidth - MARGIN.right}
            y1="100"
            y2="100"
            className="stroke-status-gray stroke-1"
          />
        </g>

        <SeekerAndMotion
          seekerTime={seekerTime}
          shownStartDate={shownDates.startDate}
          shownEndDate={shownDates.endDate}
          tickGap={tickGap}
          timeZone={timeZone}
          zoomMode={zoomMode}
          playbackMotionMarkers={playbackMotionMarkers}
        />

        <ZoomActions
          endDate={endDate}
          seekerTime={seekerTime}
          onChangeShownDates={setShownDates}
          onChangeZoomMode={setZoomMode}
          startDate={startDate}
          timeZone={timeZone}
          zoomMode={zoomMode}
        />
        <NavigateActions
          endDate={endDate}
          onChangeShownDates={setShownDates}
          shownDates={shownDates}
          startDate={startDate}
          svgWidth={svgWidth}
          timeZone={timeZone}
          zoomMode={zoomMode}
        />
      </svg>
    </div>
  );
}

export default memo(PlaybackTimeline);
