import { useLocationById } from '@hakimo-ui/hakimo/data-access';
import { NotFound, QueryResult } from '@hakimo-ui/hakimo/ui-elements';
import { withAuthz, withErrorBoundary } from '@hakimo-ui/hakimo/util';
import { useParams } from 'react-router-dom';
import LocationDetails from './LocationDetails';

type Params = {
  locationId: string;
};

interface Props {
  locationId: string;
}

function LocationDetailsData(props: Props) {
  const { locationId } = props;

  const queryResult = useLocationById(locationId);

  return (
    <QueryResult queryResult={queryResult}>
      {(data) => <LocationDetails location={data} />}
    </QueryResult>
  );
}

function LocationDetailsRoot() {
  const { locationId } = useParams<Params>();

  if (locationId === undefined) {
    return <NotFound />;
  }

  return <LocationDetailsData locationId={locationId} />;
}
export default withAuthz(withErrorBoundary(LocationDetailsRoot), [
  'location:update',
]);
