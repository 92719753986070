import { Button } from '@hakimo-ui/shared/ui-base';
import { ReactNode } from 'react';

interface Props {
  name: string;
  value?: ReactNode;
  onClickUpdate: () => void;
}

export function SettingFieldText(props: Props) {
  const { name, value, onClickUpdate } = props;

  return value ? (
    <div className="pt-6 sm:flex">
      <dt className="font-medium sm:w-64 sm:flex-none sm:pr-6">{name}</dt>
      <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
        <div>{value}</div>
        <Button variant="link" onClick={onClickUpdate}>
          Update
        </Button>
      </dd>
    </div>
  ) : (
    <div className="flex justify-between gap-x-6 pt-6">
      <div className="font-medium">{name}</div>
      <Button variant="link" onClick={onClickUpdate}>
        Update
      </Button>
    </div>
  );
}
