import { LocationContact } from '@hakimo-ui/hakimo/types';
import { Alert, Button, InputField, Modal } from '@hakimo-ui/shared/ui-base';
import { useId, useState } from 'react';
import { ADD_CONTACT_ID_PREFIX, validateValues } from './utils';

interface Props {
  onClose: () => void;
  onSubmitCb: (contact: LocationContact) => void;
  mode: string;
  contact?: LocationContact;
}

export function UpdateOrAddContactModel(props: Props) {
  const { mode, onClose, onSubmitCb, contact } = props;
  const [validState, setValidState] = useState({
    isValid: true,
    message: '',
  });
  const id = useId();
  const [updatedContact, setUpdatedContact] = useState(() => {
    return (
      contact ||
      ({
        id: ADD_CONTACT_ID_PREFIX + id,
        name: '',
        email: '',
        phoneNumber: '',
      } as LocationContact)
    );
  });

  const onChangeValues =
    (key: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
      const val = e.target.value;
      setUpdatedContact((prev) => ({ ...prev, [key]: val }));
      setValidState({ isValid: true, message: '' });
    };

  const onSubmit = () => {
    const { isValid, message } = validateValues(updatedContact);
    setValidState({ isValid, message });
    if (!isValid) {
      return;
    }
    onSubmitCb(updatedContact);
  };

  const actions = (
    <>
      <Button variant="primary" onClick={onSubmit}>
        Submit
      </Button>
      <Button onClick={onClose}>Cancel</Button>
    </>
  );
  return (
    <Modal
      title={mode === 'add' ? 'Add contact' : 'Edit contact'}
      open
      onClose={onClose}
      footer={actions}
    >
      <div className="min-w-[30rem] space-y-6 py-8 px-12">
        {!validState.isValid && (
          <Alert type="warning">{validState.message}</Alert>
        )}
        <InputField
          type="text"
          value={updatedContact.name || ''}
          onChange={onChangeValues('name')}
          label="Name"
          placeholder="Enter contact name..."
        />
        <InputField
          type="text"
          value={updatedContact.phoneNumber || ''}
          onChange={onChangeValues('phoneNumber')}
          label="Phone Number"
          placeholder="Enter contact phone number..."
        />
        <InputField
          type="email"
          value={updatedContact.email || ''}
          onChange={onChangeValues('email')}
          label="Email"
          placeholder="Enter contact email..."
        />
      </div>
    </Modal>
  );
}
