import { UpdateField } from './UpdateField';

interface Props {
  cameraId: string;
  name: string;
  onClose: () => void;
}

export function UpdateName(props: Props) {
  const { cameraId, onClose, name } = props;

  return (
    <UpdateField
      cameraId={cameraId}
      name="name"
      displayName="Name"
      currentValue={name}
      onClose={onClose}
    />
  );
}
