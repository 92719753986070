import { UserProfileProvider } from '@hakimo-ui/hakimo/providers';
import { RootLayout } from '@hakimo-ui/hakimo/ui-layout';
import { NavItem } from '@hakimo-ui/hakimo/ui-navigation';
import { Outlet } from 'react-router-dom';
import AuthHandler from '../auth/AuthHandler';
import AppInit, { AppInitOptions } from '../init/AppInit';
import Toaster from './Toaster';

interface Props {
  navItems: NavItem[];
  appInitOptions: AppInitOptions;
}

export function AppMain(props: Props) {
  const { navItems, appInitOptions } = props;

  return (
    <AuthHandler>
      <UserProfileProvider>
        <AppInit options={appInitOptions} />
        <RootLayout navItems={navItems}>
          <div className="dark:bg-dark-bg h-full bg-white">
            <Outlet />
            <Toaster />
          </div>
        </RootLayout>
      </UserProfileProvider>
    </AuthHandler>
  );
}

export default AppMain;
