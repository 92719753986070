import clsx from 'clsx';
import { ChangeEventHandler } from 'react';
import { TabItem } from '../types';

interface Props<T extends string> {
  items: TabItem<T>[];
  activeTabId: T;
  onChange: (tabId: T) => void;
}

export function Tabs<T extends string>(props: Props<T>) {
  const { items, activeTabId, onChange } = props;

  const activeTab = items.find((item) => item.id === activeTabId);

  const onSelectItem: ChangeEventHandler<HTMLSelectElement> = (event) => {
    const selectedItem = items.find((item) => item.id === event.target.value);

    if (selectedItem) {
      onChange(selectedItem.id);
    }
  };

  const onClickItem = (item: TabItem<T>) => {
    onChange(item.id);
  };

  return (
    <div>
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        <select
          id="tabs"
          name="tabs"
          className="focus:border-primary-500 focus:ring-primary-500 dark:bg-dark-bg dark:border-dark-border-bg block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:outline-none sm:text-sm"
          defaultValue={activeTab?.id}
          onChange={onSelectItem}
        >
          {items.map((item) => (
            <option key={item.id} value={item.id}>
              {item.name}
            </option>
          ))}
        </select>
      </div>
      <div className="hidden sm:block">
        <div className="dark:border-ondark-line-2 border-onlight-line-2 border-b">
          <nav className="-mb-px flex space-x-8" aria-label="Tabs">
            {items.map((item) => (
              <button
                key={item.id}
                className={clsx(
                  item.id === activeTabId
                    ? 'border-primary-700 text-primary-700 dark:text-primary-400 dark:border-primary-400'
                    : 'dark:hover:text-dark-text dark:text-dark-secondary-text border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                  'focus-visible:ring-primary-500 dark:focus-visible:ring-offset-dark-bg whitespace-nowrap border-b-2 py-2 px-1 text-sm font-medium focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2'
                )}
                aria-current={item.id === activeTabId ? 'page' : undefined}
                onClick={() => onClickItem(item)}
              >
                {item.name}
              </button>
            ))}
          </nav>
        </div>
      </div>
    </div>
  );
}

export default Tabs;
