import { useAuditLogs } from '@hakimo-ui/hakimo/data-access';
import { Page } from '@hakimo-ui/hakimo/ui-layout';
import { Pagination, Table, TableData } from '@hakimo-ui/hakimo/ui-table';
import { withAuthz, withErrorBoundary } from '@hakimo-ui/hakimo/util';
import { useEffect, useState } from 'react';
import { useLocalDispatch, useLocalState } from '../store/StateProvider';
import { createSetPageAction } from '../store/action-creators';
import { columns, getSearchParams, getTableData } from './utils';

function AuditLogList() {
  const { auditLogPage: page, auditLogPageSize: pageSize } = useLocalState();
  const dispatch = useLocalDispatch();

  const [tableData, setTableData] = useState<TableData>({
    columns,
    rows: [],
  });
  const [total, setTotal] = useState(0);
  const { data, isLoading } = useAuditLogs(
    getSearchParams(page, pageSize),
    20000
  );
  const fromIndex = (page - 1) * pageSize;
  const toIndex = Math.min(page * pageSize, total) - 1;

  useEffect(() => {
    if (data) {
      const { items, total: t } = data;
      setTableData(getTableData(items));
      setTotal(t);
    }
  }, [data]);

  const onChangePage = (val: number) => {
    dispatch(createSetPageAction(val));
  };

  const tableFooter = (
    <div className="dark:bg-dark-bg border-t bg-white p-4 dark:border-gray-800">
      <Pagination
        from={fromIndex + 1}
        to={toIndex + 1}
        pageSize={pageSize}
        total={total}
        onChangePage={onChangePage}
      />
    </div>
  );

  return (
    <Page title="Audit Log">
      <div className="dark:bg-dark-bg -mx-4 flex min-h-0 flex-1 flex-col justify-start overflow-y-hidden sm:-mx-6 md:mx-0 md:rounded-lg">
        <Table
          data={tableData}
          loading={isLoading}
          footer={tableFooter}
          scrollResetKey={page}
        />
      </div>
    </Page>
  );
}

export default withAuthz(withErrorBoundary(AuditLogList), ['audit-log:view']);
