import clsx from 'clsx';

interface Props {
  full?: boolean;
  lg?: boolean;
}

export function HakimoLogo(props: Props) {
  const { full = false, lg = false } = props;

  return (
    <>
      <span className="hidden dark:inline-block">
        <img
          className={clsx('w-auto', lg ? 'h-16' : 'h-8')}
          src={`assets/hakimo${full ? '-full' : ''}-dark.svg`}
          alt="Hakimo logo"
        />
      </span>
      <span className="inline-block dark:hidden">
        <img
          className={clsx('w-auto', lg ? 'h-16' : 'h-8')}
          src={`assets/hakimo${full ? '-full' : ''}.svg`}
          alt="Hakimo logo"
        />
      </span>
    </>
  );
}

export default HakimoLogo;
