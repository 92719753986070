import { Selectable } from '@hakimo-ui/shared/ui-base';
import useAuthenticatedRequest from '../shared/useAuthenticatedRequest';

export function useCustomComments(searchString?: string) {
  const url = searchString
    ? `/v2/orm/tenant/comments?searchString=${searchString}`
    : `/v2/orm/tenant/comments`;
  const request = new Request(url);

  return useAuthenticatedRequest<Selectable[]>(request, {
    queryKey: ['comments'],
    responseModifier: async (response) => {
      const respJson = await response.json();
      return respJson.payload.map((item: string, idx: number) => ({
        id: String(idx),
        name: item,
      }));
    },
  });
}

export default useCustomComments;
