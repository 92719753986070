export function getNeighboringAlarmsSearchParams(
  alarmsBefore: number,
  alarmsAfter: number
): string {
  const params = new URLSearchParams();
  params.append('alarmsBefore', String(alarmsBefore));
  params.append('alarmsAfter', String(alarmsAfter));

  return params.toString();
}

export const NEIGHBORING_ALARMS_BEFORE = 5;
export const NEIGHBORING_ALARMS_AFTER = 5;
