import { getWeekdays } from '@hakimo-ui/hakimo/util';
import { Button } from '@hakimo-ui/shared/ui-base';
import clsx from 'clsx';

interface Props {
  selectedDays: boolean[];
  updateSelectedDays: (enabledDays: boolean[]) => void;
  disableSelectedDays?: boolean;
}

export function WeekdayActions(props: Props) {
  const { selectedDays, updateSelectedDays, disableSelectedDays } = props;

  const onClick = (i: number) => () => {
    const updatedEnabledDays = [...selectedDays];
    updatedEnabledDays[i] = !updatedEnabledDays[i];
    updateSelectedDays(updatedEnabledDays);
  };

  const weekdays = getWeekdays();

  return (
    <div className="dark:text-ondark-text-2 bg-on flex items-center justify-center gap-2 ">
      {weekdays.map((day, i) => (
        <Button
          classNames={clsx(
            'w-6',
            selectedDays[i]
              ? 'text-ondark-text-1 bg-primary-500 enabled:hover:bg-primary-700 dark:enabled:hover:bg-primary-600'
              : 'bg-ondark-text-1 dark:bg-ondark-bg-3 dark:enabled:hover:bg-ondark-text-3 enabled:hover:bg-ondark-text-2'
          )}
          variant="icon"
          disabled={disableSelectedDays && selectedDays[i]}
          key={day.name}
          onClick={onClick(i)}
          title={day.name}
        >
          {day.symbol}
        </Button>
      ))}
    </div>
  );
}
