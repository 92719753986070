import useAuthenticatedRequest from '../shared/useAuthenticatedRequest';

export function useTwilioToken(
  locationTenantId?: string,
  onSuccess?: (token: string) => void,
  onError?: (err: Error) => void
) {
  let url = `/v2/orm/voice/twilio/token`;

  if (locationTenantId) {
    url += `?location_tenant_id=${locationTenantId}`;
  }

  const request = new Request(url);

  return useAuthenticatedRequest<string>(request, {
    queryKey: ['voice-twilio-token'],
    responseModifier: async (response) => {
      const respJson = await response.json();
      return respJson.token;
    },
    onSuccess,
    onError,
    cacheTime: 0,
    refetchInterval: 55 * 60 * 1000, // 5 mins before token expires
  });
}

export default useTwilioToken;
