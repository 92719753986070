import { DeleteUserPayload } from '@hakimo-ui/hakimo/types';
import useAuthenticatedMutation from '../shared/useAuthenticatedMutation';

export function useDeleteUser(id: string, onSuccess: () => void) {
  const url = `/v2/orm/user/${id}`;

  const request = new Request(url, {
    method: 'DELETE',
  });

  return useAuthenticatedMutation<null, DeleteUserPayload | null>(request, {
    onSuccessInvalidationKeys: [['users'], ['user', id]],
    onSuccess,
  });
}
