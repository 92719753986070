/* eslint-disable max-lines */
import { useDoorGroups } from '@hakimo-ui/hakimo/data-access';
import { DoorGroup, DoorGroupFilters } from '@hakimo-ui/hakimo/types';
import { Page } from '@hakimo-ui/hakimo/ui-layout';
import { Pagination, Table, TableData } from '@hakimo-ui/hakimo/ui-table';
import {
  useCanAddDoorGroup,
  withAuthz,
  withErrorBoundary,
} from '@hakimo-ui/hakimo/util';
import { Alert } from '@hakimo-ui/shared/ui-base';
import { useAtom } from 'jotai';
import { useEffect, useState } from 'react';
import AddDoorGroup from '../add-door-group/AddDoorGroup';
import { stateAtom } from '../state';
import DoorGroupExpandedRow from './DoorGroupExpandedRow';
import TableHeader from './TableHeader';
import FilterPanel from './filter-panel/FilterPanel';
import ProcessingConfigModal from './processing-config-modal/ProcessingConfigModal';
import { columns, getSearchParams, getTableData } from './util';

export function DoorGroupsList() {
  const [state, setState] = useAtom(stateAtom);
  const { filters, page, pageSize } = state;

  const [tableData, setTableData] = useState<TableData>({
    columns,
    rows: [],
  });

  const [allDoorGroups, setAllDoorGroups] = useState<DoorGroup[]>([]);

  const [total, setTotal] = useState(0);
  const [openFilterPanel, setOpenFilterPanel] = useState(false);
  const [openAddDoorGroup, setOpenAddDoorGroup] = useState(false);
  const [openViewProcessingConfig, setOpenViewProcessing] = useState(false);
  const [processingDoorGroup, setProcessingDoorGroup] = useState<
    DoorGroup | undefined
  >();

  const { isLoading, isError, error, data } = useDoorGroups(
    getSearchParams(page, filters, pageSize)
  );

  const fromIndex = (page - 1) * pageSize;
  const toIndex = Math.min(page * pageSize, total) - 1;

  useEffect(() => {
    if (data) {
      const { items, total: t } = data;
      setTableData(
        getTableData(items, (doorGroupId: number) => () => {
          setOpenViewProcessing(true);
          setProcessingDoorGroup(
            items.find((item: DoorGroup) => item.id === doorGroupId)
          );
        })
      );
      setAllDoorGroups(items);
      setTotal(t);
    }
  }, [data]);

  const onApplyFilters = (value: DoorGroupFilters) => {
    setState((oldState) => {
      return { ...oldState, filters: value, page: 1 };
    });
  };

  const canAddDoorGroup = useCanAddDoorGroup();

  const tableHeader = (
    <TableHeader
      canAddDoorGroup={canAddDoorGroup}
      hasCustomFiltersApplied={filters.name.values.length > 0}
      isLoading={isLoading}
      onClickAddLocation={() => setOpenAddDoorGroup(true)}
      onClickFilter={() => setOpenFilterPanel(true)}
    />
  );

  const tableFooter = (
    <div className="dark:bg-dark-bg border-t bg-white p-4 dark:border-gray-800">
      <Pagination
        from={fromIndex + 1}
        to={toIndex + 1}
        pageSize={pageSize}
        total={total}
        disabled={isLoading}
        onChangePage={(val) => {
          setState((oldState) => {
            return { ...oldState, page: val };
          });
        }}
      />
    </div>
  );

  const getExpandedRow = (rowId: string) => {
    const expandedDoorGroup = allDoorGroups.find(
      (group) => String(group.id) === rowId
    );
    return expandedDoorGroup ? (
      <DoorGroupExpandedRow doorGroup={expandedDoorGroup} />
    ) : null;
  };

  return (
    <Page title="Door Groups">
      {isError && (
        <div className="mb-2">
          <Alert type="error">{error.message}</Alert>
        </div>
      )}
      {openAddDoorGroup && (
        <AddDoorGroup onClose={() => setOpenAddDoorGroup(false)} />
      )}
      {openViewProcessingConfig && processingDoorGroup && (
        <ProcessingConfigModal
          onClose={() => setOpenViewProcessing(false)}
          doorGroup={processingDoorGroup}
        />
      )}
      <FilterPanel
        open={openFilterPanel}
        filters={filters}
        onApplyFilters={onApplyFilters}
        onClose={() => setOpenFilterPanel(false)}
      />
      <div className="dark:bg-dark-bg -mx-4 flex min-h-0 flex-1 flex-col justify-start overflow-y-hidden sm:-mx-6 md:mx-0 md:rounded-lg">
        <Table
          data={tableData}
          header={tableHeader}
          footer={tableFooter}
          loading={isLoading}
          renderExpandedRow={getExpandedRow}
        />
      </div>
    </Page>
  );
}

export default withAuthz(withErrorBoundary(DoorGroupsList), [
  'door_group:view',
]);
