import {
  CreateEscalationDTO,
  CreateEscalationResponse,
} from '@hakimo-ui/hakimo/types';
import useAuthenticatedMutation from '../shared/useAuthenticatedMutation';

export function useCreateScanEscalation() {
  const url = `/v2/orm/escalations`;

  const request = new Request(url, {
    method: 'POST',
  });

  return useAuthenticatedMutation<
    CreateEscalationResponse,
    CreateEscalationDTO
  >(request, {
    onSuccessInvalidationKeys: [['scan-escalations']],
  });
}

export default useCreateScanEscalation;
