import useAuthenticatedMutation from '../shared/useAuthenticatedMutation';

export function useRemoveDCP(dcpId: string | undefined, onSuccess: () => void) {
  const url = `/v2/orm/dcp/${dcpId}`;

  const request = new Request(url, {
    method: 'DELETE',
  });

  return useAuthenticatedMutation(request, {
    onSuccessInvalidationKeys: [['doors']],
    onSuccess,
  });
}

export default useRemoveDCP;
