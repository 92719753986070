export const MIN_LEFT_OFFSET = 0;
export const MIN_RIGHT_OFFSET = 50;

export const formatTime = (time: number) => {
  const seconds = Math.floor(time % 60),
    minutes = Math.floor(time / 60) % 60,
    hours = Math.floor(time / 3600);

  const formatedSeconds = seconds < 10 ? `0${seconds}` : seconds;
  const formatedMinutes = minutes < 10 ? `0${minutes}` : minutes;
  const formatedHours = hours < 10 ? `0${hours}` : hours;

  if (hours === 0) {
    return `${formatedMinutes}:${formatedSeconds}`;
  }
  return `${formatedHours}:${formatedMinutes}:${formatedSeconds}`;
};
