/* eslint-disable max-lines */
import { CameraDetail, Marker, Point, Polygon } from '@hakimo-ui/hakimo/types';
import { Button, StackedCardOption } from '@hakimo-ui/shared/ui-base';
import { TrashIcon } from '@heroicons/react/24/outline';

export enum CameraZone {
  DeadZone = 'deadZones',
  VehicleParkingZone = 'vehicleParkingZones',
  ActiveZones = 'activeZones',
}

const CAMERA_ZONES_ORDER = [
  CameraZone.DeadZone,
  CameraZone.VehicleParkingZone,
  CameraZone.ActiveZones,
];

export interface CamZoneState {
  deadZones: Point[][];
  vehicleParkingZones: Point[][];
  activeZones: Point[][];
}

export interface ZoneWithType {
  point: Point[];
  zoneType: CameraZone;
}

export const getPolygonColor = (camZone?: CameraZone) => {
  switch (camZone) {
    case CameraZone.DeadZone:
      return 'blue';
    case CameraZone.VehicleParkingZone:
      return 'emerald';
    case CameraZone.ActiveZones:
      return 'orange';
    default:
      return 'blue';
  }
};

export function getStackedCardOptions(
  zones: Point[][],
  onDelete: (index: string) => void,
  canUpdateZones: boolean,
  label: string
): StackedCardOption[] {
  const options: StackedCardOption[] = zones.map((_, index) => ({
    id: String(index),
    name: `${label} ${index}`,
    additional: canUpdateZones ? (
      <Button variant="icon" onClick={() => onDelete(String(index))}>
        <TrashIcon className="w-5 " />
      </Button>
    ) : undefined,
  }));

  return options;
}

export function isClose(pointA: Point, pointB: Point, diff: number) {
  return (
    Math.abs(pointA[0] - pointB[0]) <= diff &&
    Math.abs(pointA[1] - pointB[1]) <= diff
  );
}

export function getPolygonsMarkers(
  zones: ZoneWithType[] | undefined,
  partialZone: Point[] | undefined,
  selectedZone: string | undefined
): [Polygon[], Marker[]] {
  const polygons: Polygon[] = [];
  if (zones) {
    polygons.push(
      ...zones.map((val, index) => {
        return {
          points: val.point,
          color:
            String(index) === selectedZone
              ? 'yellow'
              : getPolygonColor(val.zoneType),
          pointSize: 'small',
          closed: true,
        } as Polygon;
      })
    );
  }
  const markers: Marker[] = [];
  if (partialZone && partialZone.length > 1) {
    polygons.push({
      points: partialZone,
      pointSize: 'small',
      color: 'red',
      closed: false,
    });
  } else if (partialZone) {
    markers.push(
      ...partialZone.map((point) => {
        return { point, color: 'red', size: 'small' } as Marker;
      })
    );
  }
  return [polygons, markers];
}

export function checkUpdatePermission(
  camZone: CameraZone | undefined,
  canUpdateDeadZone: boolean,
  canUpdateVehicleParkingZone: boolean,
  canUpdateActiveZone: boolean
) {
  if (camZone === CameraZone.DeadZone) {
    return canUpdateDeadZone;
  } else if (camZone === CameraZone.VehicleParkingZone) {
    return canUpdateVehicleParkingZone;
  } else if (camZone === CameraZone.ActiveZones) {
    return canUpdateActiveZone;
  } else {
    return false;
  }
}

export function getAllCamSections() {
  return [
    {
      id: CameraZone.DeadZone,
      title: 'Dead Zone',
    },
    {
      id: CameraZone.VehicleParkingZone,
      title: 'Vehicle Parking Zone',
    },
    {
      id: CameraZone.ActiveZones,
      title: 'Active zones',
    },
  ];
}

export const getInitialCamZones = (cam: CameraDetail) => {
  return {
    deadZones: [...(cam.deadZones || [])],
    vehicleParkingZones: [...(cam.vehicleParkingZones || [])],
    activeZones: [...(cam.activeZones || [])],
  };
};

export const getZonesWithType = (camZoneState: CamZoneState) => {
  const allZonesWithType: ZoneWithType[] = [];

  camZoneState.deadZones.map((point) =>
    allZonesWithType.push({ point, zoneType: CameraZone.DeadZone })
  );

  camZoneState.vehicleParkingZones.map((point) =>
    allZonesWithType.push({ point, zoneType: CameraZone.VehicleParkingZone })
  );

  camZoneState.activeZones.map((point) =>
    allZonesWithType.push({ point, zoneType: CameraZone.ActiveZones })
  );
  return allZonesWithType;
};

export const getImagePanelSelectedIndex = (
  idx: string,
  camZone: CameraZone | undefined,
  camZoneState: CamZoneState
) => {
  if (!camZone) {
    return '0';
  }
  const selectedZoneIndex = parseInt(idx);
  const zoneOrderIndex = CAMERA_ZONES_ORDER.indexOf(camZone);

  let imagePanelSelectedIndex = selectedZoneIndex;

  for (let i = 0; i < zoneOrderIndex; i++) {
    imagePanelSelectedIndex += camZoneState[CAMERA_ZONES_ORDER[i]].length;
  }

  return String(imagePanelSelectedIndex);
};

const marginFactor = 0.03;

export function updateMarkerLocationToEdges(
  clickLocation: Marker,
  imageResolution: Point | undefined
) {
  const updatedClickLocation: Marker = {
    ...clickLocation,
    point: [...clickLocation.point],
  };

  if (imageResolution) {
    const [resolutionX, resolutionY] = imageResolution;
    const marginX = resolutionX * marginFactor;
    const marginY = resolutionY * marginFactor;

    const [clickX, clickY] = clickLocation.point;

    if (resolutionX - clickX <= marginX) {
      updatedClickLocation.point[0] = resolutionX;
    } else if (clickX <= marginX) {
      updatedClickLocation.point[0] = 0;
    }

    if (resolutionY - clickY <= marginY) {
      updatedClickLocation.point[1] = resolutionY;
    } else if (clickY <= marginY) {
      updatedClickLocation.point[1] = 0;
    }
  }

  return updatedClickLocation;
}
