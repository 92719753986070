import { sanitize } from 'dompurify';

let domPurifyInstance: typeof sanitize | undefined;

export const loadDomPurify = async (): Promise<typeof sanitize> => {
  if (!domPurifyInstance) {
    const module = await import('dompurify');
    domPurifyInstance = module.default.sanitize;
  }
  return domPurifyInstance;
};
