import { CustomVideoControls } from '@hakimo-ui/hakimo/feature-shared';
import { Box } from '@hakimo-ui/hakimo/types';
import { useFullscreen } from '@hakimo-ui/hakimo/util';
import { Button, useRerenderOnWindowResize } from '@hakimo-ui/shared/ui-base';
import { BackwardIcon, ForwardIcon } from '@heroicons/react/24/outline';
import * as Sentry from '@sentry/react';
import { useEffect, useRef, useState } from 'react';
import AlarmVideoBox from './AlarmVideoBox';

export interface Props {
  videoPath?: string;
  startTime?: number;
  hasNext: boolean;
  hasPrevious: boolean;
  onNext: (isAutoplay?: boolean) => void;
  onPrevious: () => void;
  boxes?: Box[];
  isAutoplayEnabled: boolean;
}

export function AlarmVideo(props: Props) {
  const {
    videoPath,
    startTime = 0,
    hasNext,
    hasPrevious,
    onNext,
    onPrevious,
    boxes,
    isAutoplayEnabled,
  } = props;
  const videoRef = useRef<HTMLVideoElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);

  const { isFullScreen, toggleFullScreen } = useFullscreen(containerRef);

  useRerenderOnWindowResize();

  useEffect(() => {
    if (!videoRef.current) {
      return;
    }

    const video = videoRef.current;
    const onLoadedMetadata = () => {
      setIsVideoLoaded(true);
      video.play().catch((error) => {
        Sentry.captureMessage('Failed to play video', {
          extra: {
            videoPath,
            error,
          },
        });
      });
    };

    if (videoPath) {
      video.load();
      video.currentTime = startTime;
      video.addEventListener('loadedmetadata', onLoadedMetadata);
    }

    return () => {
      setIsVideoLoaded(false);
      video.removeEventListener('loadedmetadata', onLoadedMetadata);
    };
  }, [videoPath, startTime]);

  useEffect(() => {
    if (!videoRef.current) {
      return;
    }
    const video = videoRef.current;
    const onVideoEnd = () => {
      isAutoplayEnabled && onNext(true);
    };
    if (videoPath) {
      video.addEventListener('ended', onVideoEnd);
    }
    return () => {
      video.removeEventListener('ended', onVideoEnd);
    };
  }, [isAutoplayEnabled, onNext, videoPath]);

  return (
    <div className="relative space-y-3">
      <div className="group relative overflow-hidden" ref={containerRef}>
        <video
          ref={videoRef}
          className="dark:bg-ondark-bg-2 bg-onlight-bg-2 peer aspect-video w-full object-fill"
          muted
        >
          <source src={videoPath} type="video/mp4"></source>
        </video>
        <CustomVideoControls
          isFullScreen={isFullScreen}
          onFullScreen={toggleFullScreen}
          videoRef={videoRef}
          containerRef={containerRef}
        />
        {hasPrevious && (
          <div className="invisible absolute top-1/2 left-2 -translate-y-1/2 group-hover:visible">
            <Button variant="icon" onClick={onPrevious}>
              <BackwardIcon className="w-5" />
            </Button>
          </div>
        )}
        {hasNext && (
          <div className="invisible absolute top-1/2 right-2 -translate-y-1/2 group-hover:visible">
            <Button variant="icon" onClick={() => onNext()}>
              <ForwardIcon className="w-5" />
            </Button>
          </div>
        )}
        {isVideoLoaded && (
          <AlarmVideoBox
            boxes={boxes}
            videoRef={videoRef}
            videoHeight={videoRef.current?.videoHeight}
            videoWidth={videoRef.current?.videoWidth}
            videoRect={videoRef.current?.getBoundingClientRect()}
          />
        )}
      </div>
    </div>
  );
}

export default AlarmVideo;
