import clsx from 'clsx';
import { useEffect, useRef, useState } from 'react';
import { MsePlayer, createMsePlayer } from './mse-util';

interface Props {
  mseUrl: string;
  lockAspectRatio?: boolean;
}

export function LiveMse(props: Props) {
  const { mseUrl, lockAspectRatio } = props;
  const videoEl = useRef<HTMLVideoElement>(null);
  const [errorState, setErrorState] = useState({
    isError: false,
    message: '',
  });

  useEffect(() => {
    let msePlayer: MsePlayer;
    if (videoEl.current) {
      try {
        msePlayer = createMsePlayer(videoEl.current);
        msePlayer.startPlay(mseUrl);
      } catch (err) {
        setErrorState({
          isError: true,
          message:
            'Live video is not supported in this browser. Please use desktop/laptop browser',
        });
      }
    }
    return () => {
      msePlayer && msePlayer.stopPlay();
    };
  }, [mseUrl]);

  return errorState.isError ? (
    <div>{errorState.message}</div>
  ) : (
    <video
      ref={videoEl}
      className={clsx(
        'dark:bg-ondark-bg-2 bg-onlight-bg-2 w-full object-fill',
        lockAspectRatio && 'aspect-video'
      )}
      autoPlay
      muted
      playsInline
      controls
    ></video>
  );
}
