import { useSOP } from '@hakimo-ui/hakimo/data-access';
import { SOPWorkflowView } from '@hakimo-ui/hakimo/feature-shared';
import { SOPWorkflow } from '@hakimo-ui/hakimo/types';
import { QueryResult } from '@hakimo-ui/hakimo/ui-elements';
import { loadDomPurify } from '@hakimo-ui/hakimo/util';

import { useState } from 'react';

export interface Props {
  locationId: string;
}

export function SOP(props: Props) {
  const { locationId } = props;
  const [sopData, setSOPData] = useState<{
    overviewData?: string;
    workflowData?: SOPWorkflow;
    id?: string;
  }>();

  const queryResult = useSOP(
    {
      locationId,
    },
    async ({ sop_text, sop_workflow, id }) => {
      const domPurify = await loadDomPurify();
      setSOPData({
        id,
        overviewData: domPurify(sop_text),
        workflowData: sop_workflow,
      });
    }
  );

  let sopWorkflow = sopData?.workflowData;
  if (sopWorkflow) {
    sopWorkflow = {
      ...sopWorkflow,
      notes: sopWorkflow.notes ?? [],
      escalationProtocol: sopWorkflow.escalationProtocol ?? [],
      quickResolveActions: sopWorkflow.quickResolveActions ?? [],
    };
  }

  return (
    <div>
      <h2 className="flex h-9 items-center">Standard Operating Procedure</h2>
      <QueryResult queryResult={queryResult} loadingText="">
        {() =>
          sopWorkflow ? (
            <div className="dark:bg-ondark-bg-2 bg-onlight-bg-2 overflow-hidden">
              <SOPWorkflowView
                showTitle={false}
                sopId={sopData?.id || ''}
                sopWorkflow={sopWorkflow}
              />
            </div>
          ) : (
            <ul className="dark:bg-ondark-bg-2 bg-onlight-bg-2 mt-1 space-y-4 p-3">
              <div
                className="whitespace-pre-wrap text-sm"
                dangerouslySetInnerHTML={{
                  __html: sopData?.overviewData ?? '',
                }}
              />
            </ul>
          )
        }
      </QueryResult>
    </div>
  );
}

export default SOP;
