import { useEmployeesByName } from '@hakimo-ui/hakimo/data-access';
import { EmployeeFilterValue } from '@hakimo-ui/hakimo/types';
import { MultiSelectFilter } from '../multi-select-filter/MultiSelectFilter';

interface Props {
  employees: EmployeeFilterValue;
  onChange: (value: EmployeeFilterValue) => void;
  label: string;
}

export function EmployeeFilter(props: Props) {
  const { employees, onChange, label } = props;
  const getEmployeesByName = useEmployeesByName();

  return (
    <MultiSelectFilter
      value={employees}
      label={label}
      enableNegativeFilters={false}
      onChange={onChange}
      getOptions={getEmployeesByName}
    />
  );
}

export default EmployeeFilter;
