import { useSharedAlarm } from '@hakimo-ui/hakimo/data-access';
import { NotFound, QueryResult } from '@hakimo-ui/hakimo/ui-elements';
import { useSearchParams } from 'react-router-dom';
import AlarmDetails from './AlarmDetails';

interface Props {
  sharedToken: string;
}

function SharedAlarmDetails(props: Props) {
  const { sharedToken } = props;

  const queryResult = useSharedAlarm(sharedToken);
  const { refetch } = queryResult;

  return (
    <QueryResult queryResult={queryResult}>
      {(data) => (
        <AlarmDetails
          alarm={data}
          sharedToken={sharedToken}
          onRefresh={() => refetch()}
        />
      )}
    </QueryResult>
  );
}

export function SharedAlarmRoot() {
  const [searchParams] = useSearchParams();
  const sharedToken = searchParams.get('sharedToken');

  if (sharedToken === null) {
    return <NotFound message="Missing shared token in shared alarm URL" />;
  }

  return <SharedAlarmDetails sharedToken={sharedToken} />;
}

export default SharedAlarmRoot;
