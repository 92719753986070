import { EventSidebar } from '@hakimo-ui/hakimo/types';
import { CollapseIcon } from '@hakimo-ui/hakimo/ui-elements';
import { eventTracker } from '@hakimo-ui/hakimo/util';
import { Button } from '@hakimo-ui/shared/ui-base';
import { Bars3Icon } from '@heroicons/react/24/solid';

interface Props {
  collapsed?: boolean;
  onClick: () => void;
}

function SidebarButton(props: Props) {
  const { collapsed, onClick } = props;
  const eventName: EventSidebar = collapsed
    ? 'expand_sidebar'
    : 'collapse_sidebar';

  return (
    <Button
      title={eventName.split('_').join(' ')}
      variant="icon"
      onClick={onClick}
      onSideEffect={eventTracker(eventName)}
    >
      <span className="sr-only">{eventName}</span>
      {collapsed ? (
        <Bars3Icon
          className="h-6 text-gray-700 dark:text-gray-400"
          aria-hidden="true"
        />
      ) : (
        <span className="inline-block h-6 w-6 flex-shrink-0">
          <CollapseIcon aria-hidden="true" />
        </span>
      )}
    </Button>
  );
}

export default SidebarButton;
