import {
  useAudioDeviceAddCamMapping,
  useCamerasByName,
} from '@hakimo-ui/hakimo/data-access';
import { AudioDevice, BaseCamera } from '@hakimo-ui/hakimo/types';
import { SelectAsync } from '@hakimo-ui/hakimo/ui-elements';
import { eventTracker, toast } from '@hakimo-ui/hakimo/util';
import { Alert, Button, Modal } from '@hakimo-ui/shared/ui-base';
import { useState } from 'react';

interface Props {
  audioDevice: AudioDevice;
  onClose: () => void;
}

export function AddAudioDeviceCamMapping(props: Props) {
  const { audioDevice, onClose } = props;
  const [cam, setCam] = useState<BaseCamera>({ id: '', name: '' });
  const getCameras = useCamerasByName();
  const modifyAudioDeviceMutation = useAudioDeviceAddCamMapping(() => {
    toast('Audio Device Added', { type: 'success' });
    onClose();
  });

  const onSubmit = () => {
    cam.id &&
      modifyAudioDeviceMutation.mutate({
        audioDeviceId: audioDevice.id,
        cameraId: cam.id,
      });
  };
  const actions = (
    <>
      <Button
        variant="primary"
        onClick={onSubmit}
        disabled={modifyAudioDeviceMutation.isLoading || cam.id === undefined}
        onSideEffect={eventTracker('submit_add_audio_cam_mapping')}
        loading={modifyAudioDeviceMutation.isLoading}
      >
        Submit
      </Button>
      <Button
        onClick={onClose}
        onSideEffect={eventTracker('cancel_add_audio_cam_mapping')}
      >
        Cancel
      </Button>
    </>
  );

  return (
    <Modal
      title="Add New Audio Device Camera Mapping"
      open
      onClose={onClose}
      footer={actions}
    >
      {modifyAudioDeviceMutation.isError && (
        <div className="mt-4 px-8">
          <Alert type="error">{modifyAudioDeviceMutation.error.message}</Alert>
        </div>
      )}
      <div className="w-[36rem] space-y-4 p-8">
        <div className="px-4">Adding camera to </div>
        <span className="dark:bg-ondark-bg-2 bg-onlight-bg-2 mx-4 whitespace-pre rounded-md px-2 py-1 font-bold">
          {audioDevice.url}
        </span>
        <div className="p-4">
          <SelectAsync
            value={cam}
            label="Camera"
            onChange={setCam}
            onChangeQuery={getCameras}
            displayValue={(item) => item?.name || ''}
            id={(item) => item.id}
          />
        </div>
      </div>
    </Modal>
  );
}

export default AddAudioDeviceCamMapping;
