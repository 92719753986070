import { useTextToSpeech } from '@hakimo-ui/hakimo/data-access';
import { environment } from '@hakimo-ui/hakimo/environments';
import { Button, InputField } from '@hakimo-ui/shared/ui-base';
import clsx from 'clsx';
import {
  MouseEventHandler,
  memo,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import MiniAudioPlayer from '../mini-audio-player/MiniAudioPlayer';
import { getFinalText } from '../util';

interface Props {
  text: string;
  updateAudioFile: (file: File) => void;
  prefetch?: boolean;
  inputAfterWordCount?: number;
  placeholder?: string;
  updateFinalTemplateText?: (text: string) => void;
  isInputValueInvalid?: boolean;
  showFetchAction?: boolean;
  alignment?: 'vertical' | 'horizontal';
}

export function TextToSpeech(props: Props) {
  const {
    text,
    prefetch,
    inputAfterWordCount,
    updateAudioFile,
    placeholder,
    updateFinalTemplateText,
    isInputValueInvalid,
    showFetchAction = true,
    alignment = 'horizontal',
  } = props;
  const [inputValue, setInputValue] = useState('');
  const [audioSrc, setAudioSrc] = useState<string>('');

  const { isLoading, fetchAudio } = useTextToSpeech(
    environment.elevenLabsApiKey
  );
  const hasFetchedAudio = useRef(false);

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.currentTarget.value);
    updateFinalTemplateText &&
      updateFinalTemplateText(
        getFinalText(text, inputAfterWordCount, e.currentTarget.value)
      );
  };

  let inputNode;
  let firstPart = '';
  let lastPart = '';

  if (inputAfterWordCount !== undefined) {
    const splitTextArr = text.split(' ');
    const inputField = (
      <InputField
        type="text"
        required
        error={isInputValueInvalid}
        onChange={onInputChange}
        value={inputValue}
        placeholder={placeholder}
      />
    );
    if (splitTextArr.length > 1) {
      firstPart = splitTextArr.slice(0, inputAfterWordCount).join(' ');
      lastPart = splitTextArr.slice(inputAfterWordCount).join(' ');
      inputNode = (
        <>
          {firstPart} <span className="mx-2 max-w-[8rem]">{inputField}</span>{' '}
          {lastPart}
        </>
      );
    } else {
      inputNode = <span className="w-[40rem]">{inputField}</span>;
    }
  } else {
    inputNode = text;
  }

  const fetchAudioAndSetSrc = useCallback(async () => {
    const response = await fetchAudio(
      getFinalText(text, inputAfterWordCount, inputValue)
    );
    if (!(response instanceof Error)) {
      const { audioUrl, file } = response;
      setAudioSrc(audioUrl);
      updateAudioFile(file);
    }
  }, [fetchAudio, inputAfterWordCount, inputValue, text, updateAudioFile]);

  useEffect(() => {
    if (prefetch && !hasFetchedAudio.current) {
      hasFetchedAudio.current = true;
      fetchAudioAndSetSrc();
    }
  }, [fetchAudioAndSetSrc, prefetch]);

  const onClick: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    fetchAudioAndSetSrc();
  };

  return (
    <div
      className={clsx(
        alignment === 'horizontal' &&
          'grid w-[62rem] grid-cols-8 justify-items-center',
        alignment === 'vertical' && 'flex flex-col items-center space-y-3'
      )}
    >
      <div className="col-span-6 flex items-center justify-self-start">
        {inputNode}
      </div>
      <span className="flex items-center">
        <MiniAudioPlayer src={audioSrc} isLoading={isLoading} />
      </span>
      {showFetchAction && (
        <Button
          onClick={onClick}
          loading={isLoading}
          disabled={inputAfterWordCount !== undefined && inputValue === ''}
        >
          <span className="text-xs">
            {audioSrc ? 'Refetch' : 'Fetch'} Audio
          </span>
        </Button>
      )}
    </div>
  );
}

export default memo(TextToSpeech);
