import { eventTracker } from '@hakimo-ui/hakimo/util';
import { Button } from '@hakimo-ui/shared/ui-base';
import { XCircleIcon } from '@heroicons/react/24/solid';
import { useState } from 'react';
import CommentDropdown from './CommentDropdown';
import MotionDetector from './MotionDetector';
import { DetectionType, StepperState, getCommentPrefix } from './util';

interface Props {
  onSubmit: (comment: string) => void;
  onClose: () => void;
  isSubmitInProgress?: boolean;
  initialDetectionType?: DetectionType;
}

export function StatusStepper(props: Props) {
  const { onSubmit, onClose, isSubmitInProgress, initialDetectionType } = props;
  const initStepperState = {
    step: initialDetectionType ? 1 : 0,
    comment: '',
    detectionType: initialDetectionType,
  };
  const [stepperState, setStepperState] =
    useState<StepperState>(initStepperState);

  const onDetection = (detectionType: DetectionType) => {
    setStepperState({
      ...stepperState,
      step: 1,
      detectionType,
    });
  };

  const onSubmitComment = (comment: string) => {
    const commentPrefix = getCommentPrefix(stepperState.detectionType);
    onSubmit(`${commentPrefix} - ${comment}`);
  };

  return (
    <div className="flex w-full items-center gap-8">
      {stepperState.step === 0 && <MotionDetector onSubmit={onDetection} />}
      {stepperState.step > 0 && stepperState.detectionType && (
        <CommentDropdown
          detectionType={stepperState.detectionType}
          onSubmit={onSubmitComment}
          isSubmitInProgress={isSubmitInProgress}
        />
      )}
      <div className="self-start">
        <Button
          title="Cancel"
          variant="icon"
          onClick={onClose}
          onSideEffect={eventTracker('cancel_status_stepper')}
        >
          <XCircleIcon className="h-5 w-5" aria-hidden="true" />
        </Button>
      </div>
    </div>
  );
}

export default StatusStepper;
