import clsx from 'clsx';
import { ReactNode } from 'react';

export type LabelType = 'default' | 'info' | 'success' | 'warning' | 'error';

interface Props {
  text?: string;
  type?: LabelType;
  removable?: boolean;
  children?: ReactNode;
  small?: boolean;
  onClickRemove?: () => void;
}

export function Label(props: Props) {
  const {
    text,
    type = 'default',
    removable = false,
    children,
    small,
    onClickRemove,
  } = props;

  return (
    <span
      className={clsx(
        'inline-flex items-center rounded-3xl font-medium',
        {
          'bg-gray-100 text-gray-800 dark:bg-gray-200/10 dark:text-gray-400':
            type === 'default',
          'bg-blue-100 text-blue-800 dark:bg-blue-800/20 dark:text-blue-600':
            type === 'info',
          'bg-green-100 text-green-800 dark:bg-green-800/20 dark:text-green-600':
            type === 'success',
          'bg-yellow-100 text-yellow-800 dark:bg-yellow-800/20 dark:text-yellow-600':
            type === 'warning',
          'bg-red-100 text-red-800 dark:bg-red-700/20 dark:text-red-600':
            type === 'error',
        },
        small ? 'px-3 py-0.5 text-xs' : 'px-3.5 py-0.5 text-sm'
      )}
    >
      {text ?? children ?? null}
      {removable && (
        <button
          type="button"
          className="hover:text-status-red ml-0.5 inline-flex flex-shrink-0 items-center justify-center rounded-full p-1 focus:outline-none"
          onClick={onClickRemove}
        >
          <span className="sr-only">remove option</span>
          <svg
            className="h-2.5 w-2.5"
            stroke="currentColor"
            fill="none"
            viewBox="0 0 8 8"
          >
            <path
              strokeLinecap="round"
              strokeWidth="1.5"
              d="M1 1l6 6m0-6L1 7"
            />
          </svg>
        </button>
      )}
    </span>
  );
}

export default Label;
