import { HakimoSpinner, Tooltip } from '@hakimo-ui/shared/ui-base';
import { Combobox } from '@headlessui/react';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import { ChevronUpDownIcon } from '@heroicons/react/24/solid';
import { useRef, useState } from 'react';
import { DEFAULT_DEBOUNCE_DELAY } from '../constants';
import Option from './Option';

interface Props<T> {
  value?: T;
  label?: string;
  debounceDelay?: number;
  onChange: (value: T) => void;
  displayValue: (item?: T) => string;
  onChangeQuery: (query: string) => Promise<T[]>;
  id?: (item: T) => string;
  placeholder?: string;
  infoTooltip?: string;
}

export function SelectAsync<T>(props: Props<T>) {
  const {
    label,
    value: selected,
    debounceDelay,
    onChange,
    displayValue,
    onChangeQuery,
    id: getId,
    placeholder,
    infoTooltip,
  } = props;

  const [items, setItems] = useState<T[]>([]);
  const [loading, setLoading] = useState(false);

  const timerRef = useRef<number>();

  const onChangeQueryCb = (query: string) => {
    window.clearTimeout(timerRef.current);

    timerRef.current = window.setTimeout(() => {
      setLoading(true);

      onChangeQuery(query)
        .then((nextItems) => {
          setItems(nextItems);
        })
        .finally(() => {
          setLoading(false);
        });
    }, debounceDelay || DEFAULT_DEBOUNCE_DELAY);
  };

  return (
    <Combobox as="div" value={selected} onChange={onChange}>
      <>
        <div className="flex items-center gap-2">
          {label && (
            <Combobox.Label className="dark:text-dark-text mb-1 block text-xs text-gray-700">
              {label}
            </Combobox.Label>
          )}
          {infoTooltip && (
            <span>
              <Tooltip
                text={infoTooltip}
                colorModifier="info"
                position="top-right"
                size="medium"
              >
                <InformationCircleIcon className="h-4 w-4" />
              </Tooltip>
            </span>
          )}
        </div>
        <div className="relative">
          <Combobox.Input
            placeholder={placeholder}
            className="focus:border-primary-500 focus:ring-primary-500 dark:bg-dark-bg w-full rounded border-gray-400 text-sm dark:border-gray-600"
            onChange={(event) => onChangeQueryCb(event.target.value)}
            displayValue={displayValue}
          />
          <Combobox.Button className=" absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
            <ChevronUpDownIcon
              className="h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </Combobox.Button>
          {loading && (
            <span className="absolute right-8 translate-y-1/2">
              <HakimoSpinner />
            </span>
          )}
          {!loading && items.length > 0 && (
            <Combobox.Options className="dark:bg-dark-hover-bg absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white  py-1  text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {items.map((item, idx) => (
                <Option
                  key={getId ? getId(item) : idx}
                  item={item}
                  displayValue={displayValue}
                />
              ))}
            </Combobox.Options>
          )}
        </div>
      </>
    </Combobox>
  );
}

export default SelectAsync;
