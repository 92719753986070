import { useUpdateScanEscalation } from '@hakimo-ui/hakimo/data-access';
import {
  ScanEscalation,
  UpdateEscalationRequest,
} from '@hakimo-ui/hakimo/types';
import { toast } from '@hakimo-ui/hakimo/util';
import { Label } from '@hakimo-ui/shared/ui-base';
import { useEffect, useState } from 'react';
import { ResolveAction } from '../common/ResolveAction';
import { mapStatusTypeToLabelType } from './util';

interface Props {
  escalation: ScanEscalation;
}

export function DetailHeader(props: Props) {
  const { escalation } = props;
  const [isStatusEditing, setIsStatusEditing] = useState(false);
  const { isSuccess, mutate, isLoading } = useUpdateScanEscalation(
    escalation.id
  );

  useEffect(() => {
    if (isSuccess) {
      toast('Escalation resolved successfully');
    }
  }, [isSuccess]);

  const onResolve = (message: string) => {
    setIsStatusEditing(false);
    const payload: UpdateEscalationRequest = {
      escalation_id: escalation.id,
      resolution_comment: message,
      status: 'Resolved',
    };
    mutate(payload);
  };

  const onClickResolveAction = () => setIsStatusEditing(true);
  const onCancelResolve = () => setIsStatusEditing(false);

  return (
    <div className="flex h-12 items-center gap-4 px-4">
      {!isStatusEditing && (
        <>
          <span className="flex flex-shrink-0 flex-col">
            <span title={`#${escalation.id} - ${escalation.location?.name}`}>
              {escalation.location?.name}
            </span>
            <span className="dark:text-ondark-text-2 text-onlight-text-2 text-xs">
              {escalation.location.description}
            </span>
          </span>
          <div className="h-4 border-l border-black/20 dark:border-white/20"></div>
        </>
      )}
      <Label type={mapStatusTypeToLabelType(escalation.status)}>
        {escalation.status}
      </Label>

      {escalation.status !== 'Resolved' && (
        <>
          <div className="h-4 border-l border-black/20 dark:border-white/20"></div>
          <ResolveAction
            onResolve={onResolve}
            isLoading={isLoading}
            onClickResolveAction={onClickResolveAction}
            onCancelResolve={onCancelResolve}
          />
        </>
      )}

      <div className="ml-auto text-sm">
        Escalated by: {escalation.createdByUserId}
      </div>
    </div>
  );
}

export default DetailHeader;
