import { DoorGroup } from '@hakimo-ui/hakimo/types';
import { eventTracker } from '@hakimo-ui/hakimo/util';
import { Button } from '@hakimo-ui/shared/ui-base';
import { TrashIcon } from '@heroicons/react/24/solid';

interface DoorGroupMembersProps {
  doorGroup: DoorGroup;
  onRemoveDoor: (doorId: string) => () => void;
  deleteDisabled: boolean;
}
export function DoorGroupMembers(props: DoorGroupMembersProps) {
  const { doorGroup, onRemoveDoor, deleteDisabled } = props;
  return (
    <div className="p-4 text-xs">
      {doorGroup.doors.map((door) => (
        <div
          key={door.id}
          className="m-2 inline-flex w-52 items-center justify-center gap-4 rounded-xl border-2 p-2 dark:border-gray-600"
        >
          <span
            title={door.name}
            className="overflow-hidden overflow-ellipsis whitespace-nowrap"
          >
            {door.name}
          </span>
          <Button
            variant="icon"
            onClick={onRemoveDoor(door.id)}
            title="Remove door from door group"
            onSideEffect={eventTracker('submit_remove_door_from_door_group')}
            disabled={deleteDisabled}
            loading={deleteDisabled}
          >
            <TrashIcon className="h-4 w-4 hover:text-red-500" />
          </Button>
        </div>
      ))}
      {doorGroup.doors.length === 0 && (
        <div className="text-center text-gray-600 dark:text-gray-400">
          No doors attached to this door group
        </div>
      )}
    </div>
  );
}
