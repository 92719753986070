import { useSharedLocationAlarm } from '@hakimo-ui/hakimo/data-access';
import { NotFound, QueryResult } from '@hakimo-ui/hakimo/ui-elements';
import { useSearchParams } from 'react-router-dom';
import { AlarmOverviewNoAuth } from './alarm-overview/AlarmOverview';

interface Props {
  sharedToken: string;
}

function SharedLocationAlarmDetails(props: Props) {
  const { sharedToken } = props;

  const queryResult = useSharedLocationAlarm(sharedToken);

  return (
    <QueryResult queryResult={queryResult}>
      {(data) => (
        <div className="container mx-auto">
          <AlarmOverviewNoAuth alarm={data} sharedToken={sharedToken} />
        </div>
      )}
    </QueryResult>
  );
}

export function SharedLocationAlarmRoot() {
  const [searchParams] = useSearchParams();
  const sharedToken = searchParams.get('sharedToken');

  if (sharedToken === null) {
    return (
      <NotFound message="Missing shared token in shared location alarm URL" />
    );
  }

  return <SharedLocationAlarmDetails sharedToken={sharedToken} />;
}

export default SharedLocationAlarmRoot;
