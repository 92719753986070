import { useCameraDetails } from '@hakimo-ui/hakimo/data-access';
import { LiveViewSwitch } from '@hakimo-ui/hakimo/feature-shared';
import { QueryResult } from '@hakimo-ui/hakimo/ui-elements';

interface Props {
  cameraId: string;
}

export function LiveView(props: Props) {
  const { cameraId } = props;
  const cameraQueryResult = useCameraDetails(cameraId);

  return (
    <QueryResult queryResult={cameraQueryResult}>
      {(data) => {
        return <LiveViewSwitch camera={data} />;
      }}
    </QueryResult>
  );
}
