import { SOPDTO } from '@hakimo-ui/hakimo/types';
import useAuthenticatedRequest from '../shared/useAuthenticatedRequest';

type Options =
  | {
      alarmId: string;
    }
  | {
      locationId: string;
    };

export function useSOP(
  opt: Options,
  onSuccess?: (data: SOPDTO) => void,
  onError?: () => void
) {
  const search = new URLSearchParams();

  if ('alarmId' in opt) {
    search.set('alarm_id', opt.alarmId);
  } else {
    search.set('location_id', opt.locationId);
  }

  const url = `/v2/orm/sop/find?${search.toString()}`;
  const request = new Request(url);

  return useAuthenticatedRequest<SOPDTO>(request, {
    queryKey: ['sop', opt],
    responseModifier: async (response) => {
      const respJson = await response.json();
      const sopResp = respJson.payload;

      try {
        const sopObj = JSON.parse(sopResp.sop_text);
        return {
          sop_text: sopObj.sop_overview,
          sop_workflow: sopObj.sop_workflow,
          id: sopResp.id,
          scan_sop: sopObj.scan_sop,
        };
      } catch (error) {
        return {
          sop_text: sopResp.sop_text,
          id: sopResp.id,
        };
      }
    },
    onSuccess,
    onError,
  });
}

export default useSOP;
