import { eventTracker, pageTitleAtom } from '@hakimo-ui/hakimo/util';
import { Button } from '@hakimo-ui/shared/ui-base';
import { ArrowLeftIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';
import { useSetAtom } from 'jotai';
import { ReactNode, useEffect, useMemo } from 'react';

interface Props {
  title: string;
  subtitle?: ReactNode;
  children: ReactNode;
  secondary?: ReactNode;
  fullWidth?: boolean;
  onClickBack?: () => void;
}

export function Page(props: Props) {
  const {
    title,
    subtitle,
    children,
    secondary,
    fullWidth = false,
    onClickBack,
  } = props;

  const setTitleNode = useSetAtom(pageTitleAtom);

  const titleNode = useMemo(
    () => (
      <div className="flex items-center justify-between">
        <div className="flex items-center py-6">
          {onClickBack && (
            <div className="mr-4">
              <Button
                variant="icon"
                onClick={onClickBack}
                onSideEffect={eventTracker('navigate_back_page')}
              >
                <ArrowLeftIcon className="w-6" />
              </Button>
            </div>
          )}
          <div>
            <h1 className="dark:text-dark-text text-2xl leading-8 text-gray-800">
              {title}
            </h1>
            <h2 className="pt-1 text-base text-gray-600 dark:text-gray-300">
              {subtitle}
            </h2>
          </div>
        </div>
      </div>
    ),
    [onClickBack, subtitle, title]
  );

  useEffect(() => {
    setTitleNode(titleNode);
  }, [setTitleNode, titleNode]);

  return (
    <main className="dark:bg-ondark-bg-1 dark:text-dark-text bg-onlight-bg-1 h-full overflow-y-auto pt-8">
      <div
        className={clsx(
          'mx-auto h-full px-4 sm:px-6 md:px-8',
          !fullWidth && 'max-w-container'
        )}
      >
        <div className="flex h-full flex-col">
          {secondary && (
            <div className="mb-2 flex justify-end">{secondary}</div>
          )}
          <div className="flex min-h-0 flex-1 flex-col pb-8">{children}</div>
        </div>
      </div>
    </main>
  );
}

export default Page;
