import clsx from 'clsx';

interface KeypadProps {
  onKeypadButtonClick: (digit: string) => void;
}

function Keypad(props: KeypadProps) {
  interface ButtonMetadata {
    digit: string;
    subText?: string;
  }
  const buttonList: ButtonMetadata[] = [
    { digit: '1' },
    { digit: '2', subText: 'ABC' },
    { digit: '3', subText: 'DEF' },
    { digit: '4', subText: 'GHI' },
    { digit: '5', subText: 'JKL' },
    { digit: '6', subText: 'MNO' },
    { digit: '7', subText: 'PQRS' },
    { digit: '8', subText: 'TUV' },
    { digit: '9', subText: 'WXYZ' },
    { digit: '*' },
    { digit: '0' },
    { digit: '#' },
  ];

  const { onKeypadButtonClick } = props;
  return (
    <div className="grid grid-cols-3 gap-2 pt-4">
      {buttonList.map((b) => (
        <KeypadButton
          key={b.digit}
          digit={b.digit}
          subText={b.subText}
          onClick={() => onKeypadButtonClick(b.digit)}
        />
      ))}
    </div>
  );
}

interface KeypadButtonProps {
  digit: string;
  subText?: string;
  onClick: () => void;
}

function KeypadButton(props: KeypadButtonProps) {
  const { digit, subText, onClick } = props;
  return (
    <button
      className={clsx(
        'inline-flex items-center justify-center rounded-md text-sm font-medium',
        'focus:ring-primary-500 dark:ring-offset-ondark-bg-1 focus:outline-none focus:ring-2 focus:ring-offset-2',
        'bg-primary-500 enabled:hover:bg-primary-600 dark:enabled:hover:bg-primary-700'
      )}
      onClick={onClick}
    >
      <div className="mt-2 grid grid-rows-2">
        <div className="text-ondark-text-1">{digit}</div>
        <div className="text-ondark-text-2 text-xs">{subText}</div>
      </div>
    </button>
  );
}

export default Keypad;
