import { useLocationById } from '@hakimo-ui/hakimo/data-access';
import {
  FullScreenExitIcon,
  FullScreenIcon,
  QueryResult,
} from '@hakimo-ui/hakimo/ui-elements';
import { useFullscreen } from '@hakimo-ui/hakimo/util';
import { Alert, Button } from '@hakimo-ui/shared/ui-base';
import clsx from 'clsx';
import { useRef } from 'react';

interface Props {
  locationId: string;
}

export function LocationFloorplan(props: Props) {
  const { locationId } = props;
  const containerRef = useRef<HTMLDivElement>(null);
  const queryResult = useLocationById(locationId);

  const { isFullScreen, toggleFullScreen } = useFullscreen(containerRef);

  return (
    <div className="group min-h-[15rem] space-y-1">
      <h2 className="flex h-9 items-center">Location Floorplan</h2>
      <div ref={containerRef} className="relative">
        <QueryResult queryResult={queryResult}>
          {(location) => (
            <>
              {location.floor_plan && (
                <div className="invisible absolute top-1 right-1 group-hover:visible">
                  <Button
                    variant="icon"
                    onClick={toggleFullScreen}
                    classNames="dark:bg-ondark-bg-3 bg-onlight-text-3 enabled:hover:bg-onlight-text-2/90 dark:enabled:hover:bg-ondark-bg-2 focus:ring-offset-0"
                  >
                    {isFullScreen ? (
                      <FullScreenExitIcon className="fill-dark-text h-7 w-7" />
                    ) : (
                      <FullScreenIcon className="fill-dark-text h-7 w-7" />
                    )}
                  </Button>
                </div>
              )}
              {location.floor_plan ? (
                <div className="flex h-full justify-center bg-gray-200 dark:bg-gray-900">
                  <img
                    src={location.floor_plan}
                    alt="Floorplan"
                    className={clsx(
                      isFullScreen ? 'max-h-[100vh]' : 'max-h-[48rem]'
                    )}
                  />
                </div>
              ) : (
                <div className="p-6">
                  <Alert>Floorplan not available</Alert>
                </div>
              )}
            </>
          )}
        </QueryResult>
      </div>
    </div>
  );
}

export default LocationFloorplan;
