import { UserEnabledFilter, UserListFilters } from '@hakimo-ui/hakimo/types';
import { SEPARATOR } from '../shared';

interface Options {
  page?: number;
  pageSize?: number;
  filters?: UserListFilters;
  tenants?: string[];
}

export function getUserListSearchParams(options: Options = {}): string {
  const { page, pageSize, filters, tenants } = options;
  const params = new URLSearchParams();

  if (filters) {
    params.append('emails', filters.emails.join(SEPARATOR));
    params.append('names', filters.names.join(SEPARATOR));
    params.append('roles', filters.roles.join(SEPARATOR));
    params.append('enabled', getEnabledQueryParams(filters.enabled));
  }

  if (page) {
    params.append('page', String(page));
  }

  if (pageSize) {
    params.append('pageSize', String(pageSize));
  }

  if (tenants) {
    params.append('listByTenants', tenants.join(SEPARATOR));
  }

  return params.toString();
}

function getEnabledQueryParams(enabledFilter: UserEnabledFilter): string {
  const ret = [];
  if (enabledFilter.showEnabled) {
    ret.push('true');
  }
  if (enabledFilter.showNotEnabled) {
    ret.push('false');
  }
  return ret.join(SEPARATOR);
}
