import { Route, Routes } from 'react-router-dom';
import AlarmDetailsRoot from './alarm-details/AlarmDetailsRoot';
import AlarmListRoot from './alarm-list/AlarmListRoot';

export function AlarmsRoutes() {
  return (
    <Routes>
      <Route path="/" element={<AlarmListRoot />} />
      <Route path=":alarmId" element={<AlarmDetailsRoot />} />
    </Routes>
  );
}

export default AlarmsRoutes;
