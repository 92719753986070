import { Page } from '@hakimo-ui/hakimo/ui-layout';
import { useUser } from '@hakimo-ui/hakimo/util';
import { Tabs } from '@hakimo-ui/shared/ui-base';
import { useEffect, useState } from 'react';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import { AlarmNotifications } from './alarm-notifications/AlarmNotifications';
import General from './general/General';
import Preferences from './preferences/Preferences';
import { TabId, tabItems, useActiveTabId } from './util';

export function UserProfileRoot() {
  const user = useUser();
  const navigate = useNavigate();
  const tabIdOnNavigate = useActiveTabId();
  const [activeTabId, setActiveTabId] = useState(tabIdOnNavigate);

  useEffect(() => {
    setActiveTabId(tabIdOnNavigate);
  }, [tabIdOnNavigate]);

  const onChangeTab = (tabId: TabId) => {
    navigate(`/profile/${tabId}`);
  };

  return (
    <Page title="Profile">
      <Tabs items={tabItems} activeTabId={activeTabId} onChange={onChangeTab} />
      <div className="mx-auto w-full">
        <Routes>
          <Route path="/" element={<Navigate to="general" />} />
          <Route path="general" element={<General user={user} />} />
          <Route path="preferences" element={<Preferences />} />
          <Route path="alarm-notifications" element={<AlarmNotifications />} />
        </Routes>
      </div>
    </Page>
  );
}

export default UserProfileRoot;
