import { HakimoSpinner } from '@hakimo-ui/shared/ui-base';

interface Props {
  text?: string;
}

export function LoadingIndicator(props: Props) {
  const { text } = props;

  return (
    <div className="flex items-center gap-2">
      <div data-testid="spinner-container">
        <HakimoSpinner />
      </div>
      <span
        className="dark:text-dark-text text-gray-500"
        data-testid="text-container"
      >
        {text ?? 'Loading...'}
      </span>
    </div>
  );
}

export default LoadingIndicator;
