/* eslint-disable max-lines */
import { useCameras, useLocationById } from '@hakimo-ui/hakimo/data-access';
import { Camera } from '@hakimo-ui/hakimo/types';
import {
  Alert,
  Button,
  HakimoSpinner,
  InputField,
} from '@hakimo-ui/shared/ui-base';
import { XMarkIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { LiveViewSwitch } from '../live-view/LiveViewSwitch';

interface Props {
  locationId: string;
  locationName: string;
  onClose: () => void;
  initialCameraIds?: string[];
}

const InitialMaxNumberOfCameras = 4;

export function SiteWatchLive(props: Props) {
  const { locationId, locationName, initialCameraIds = [], onClose } = props;
  const [selectedCameras, setSelectedCameras] = useState<Camera[]>([]);
  const [camSearchValue, setCamSearchValue] = useState('');
  const [allCameras, setAllCameras] = useState<Camera[]>([]);

  const params = new URLSearchParams();
  params.append('locations', locationName);

  const { isFetching, isError, error, data } = useCameras(params.toString());

  const locationData = useLocationById(locationId);

  useEffect(() => {
    if (data) {
      const allCams = data.items;
      setAllCameras(allCams);
      let cameras: Camera[] = [];
      if (initialCameraIds.length > 0) {
        cameras = allCams.filter((item) => initialCameraIds.includes(item.id));
      }
      for (const cam of allCams) {
        if (!initialCameraIds.includes(cam.id)) {
          cameras.push(cam);
        }
        if (cameras.length >= InitialMaxNumberOfCameras) {
          break;
        }
      }
      setSelectedCameras(cameras);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, initialCameraIds.length]);

  useEffect(() => {
    const updatedCams = [...(data?.items || [])].filter((cam) =>
      cam.name.toLowerCase().includes(camSearchValue.toLowerCase())
    );
    setAllCameras(updatedCams);
  }, [camSearchValue, data?.items]);

  const onSelectCamera = (cam: Camera) => () => {
    let updatedCams = [...selectedCameras];
    if (updatedCams.find((c) => c.id === cam.id)) {
      updatedCams = updatedCams.filter((c) => c.id !== cam.id);
    } else {
      updatedCams.push(cam);
    }
    setSelectedCameras(updatedCams);
  };

  const onSearchValChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value;
    setCamSearchValue(val);
  };

  // const allCameras = data?.items;
  const selectedCamLength = selectedCameras.length;

  return (
    <div className="relative flex h-full flex-col justify-between p-4 pt-6">
      <Button
        classNames="absolute right-1 top-0"
        variant="icon"
        onClick={onClose}
      >
        <XMarkIcon className="h-8 w-8" />
      </Button>
      <div
        className={clsx(
          'grid h-[80%] gap-5 overflow-auto py-4 px-8',
          selectedCamLength <= 4 && 'grid-cols-2',
          selectedCamLength > 4 && selectedCamLength <= 8 && 'grid-cols-3',
          selectedCameras.length > 8 && 'grid-cols-4'
        )}
      >
        {selectedCamLength === 0 && (
          <span className="col-span-2 row-span-2 text-center">
            Select cameras from below list to view their live feed
          </span>
        )}
        {selectedCameras.map((cam) => (
          <div className="relative max-h-full" key={cam.id}>
            <span className="bg-ondark-bg-1/80 absolute top-1 right-1 rounded-md p-1 text-xs text-white">
              {cam.name}
            </span>
            <div className="h-full">
              <LiveViewSwitch camera={cam} />
            </div>
          </div>
        ))}
      </div>

      <div className="dark:border-ondark-line-2 bg-onlight-bg-1 dark:bg-ondark-bg-1 relative flex items-center gap-5 rounded-md border p-2">
        {locationData.data?.floor_plan && (
          <img
            src={locationData.data?.floor_plan}
            alt="Floorplan"
            className="bottom-0 z-10 h-32 w-32 rounded transition-all duration-300 ease-in-out hover:absolute hover:h-[70vh] hover:w-[70vh]"
          />
        )}
        <div className="h-full p-2">
          <div className="mb-2 flex items-center gap-2">
            {isError && <Alert type="error">{error.message}</Alert>}
            <span className="text-onlight-text-1 dark:text-ondark-text-1 mb-2">
              Nearby Cameras at {locationName}
            </span>
            <div className="flex w-[30%]">
              <InputField
                label=""
                onChange={onSearchValChange}
                value={camSearchValue}
                type="search"
                placeholder="Search with camera name..."
              />
            </div>
            {isFetching && (
              <div>
                <HakimoSpinner />
              </div>
            )}
          </div>
          <div
            className={clsx(
              'flex items-center gap-6 overflow-auto pb-4',
              locationData.data?.floor_plan ? 'w-[86vw]' : 'w-[95vw]'
            )}
          >
            {allCameras?.map((cam) => (
              <div
                key={cam.id}
                className={clsx(
                  'min-w-32 flex h-20 cursor-pointer items-center whitespace-pre rounded border p-2 text-center text-sm shadow-md',
                  selectedCameras.find((c) => c.id === cam.id)
                    ? 'border-primary-500 bg-primary-500 text-onlight-line-1 hover:bg-primary-700 border-2'
                    : 'border-onlight-text-2 dark:border-ondark-text-3 hover:bg-onlight-bg-3 dark:hover:bg-ondark-bg-3'
                )}
                tabIndex={1}
                onClick={onSelectCamera(cam)}
              >
                {cam.name}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default SiteWatchLive;
