import { environment } from '@hakimo-ui/hakimo/environments';
import { User } from '@hakimo-ui/hakimo/types';
import { BrowserTracing } from '@sentry/browser';
import * as Sentry from '@sentry/react';
import { useEffect } from 'react';

export function useSentry(user: User, dsn: string, enabled: boolean) {
  useEffect(() => {
    Sentry.init({
      dsn,
      integrations: [new BrowserTracing()],

      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 1.0,
      enabled,
      environment: environment.name,
      release: environment.release,
    });

    Sentry.setUser({
      id: user.id,
      email: user.email,
      username: user.name ?? 'Unknown',
    });
  }, [user, dsn, enabled]);
}
