import { useUpdateCamera } from '@hakimo-ui/hakimo/data-access';
import { LocationSelect } from '@hakimo-ui/hakimo/feature-shared';
import { toast } from '@hakimo-ui/hakimo/util';
import { Alert, Button, Modal } from '@hakimo-ui/shared/ui-base';
import { useState } from 'react';

interface Props {
  cameraId: string;
  locationId: string;
  onClose: () => void;
}

export function UpdateLocation(props: Props) {
  const { cameraId, locationId, onClose } = props;
  const [value, setValue] = useState(locationId);

  const updateCameraMutation = useUpdateCamera(cameraId, () => {
    toast(`Camera location updated!`, {
      type: 'success',
    });
    onClose();
  });

  const onSubmit = () => {
    updateCameraMutation.mutate({
      locationId: value,
    });
  };

  const actions = (
    <>
      <span className="mr-2">
        <Button
          variant="primary"
          onClick={onSubmit}
          loading={updateCameraMutation.isLoading}
        >
          Submit
        </Button>
      </span>
      <Button onClick={onClose}>Cancel</Button>
    </>
  );

  const onChange = (locId: string) => {
    setValue(locId);
  };

  return (
    <Modal
      open={true}
      onClose={onClose}
      title="Update Camera Location"
      footer={actions}
    >
      <div className="w-[28rem] space-y-2 px-8 py-4">
        {updateCameraMutation.isError && (
          <Alert type="error">{updateCameraMutation.error.message}</Alert>
        )}
        <LocationSelect
          locationId={value}
          onChange={onChange}
          label="Location"
        />
      </div>
    </Modal>
  );
}

export default UpdateLocation;
