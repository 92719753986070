import { BugReport, BugReportSeverity } from '@hakimo-ui/hakimo/types';

export function getBugReport(
  description: string,
  severity: BugReportSeverity,
  bugReporter?: string | null
): BugReport {
  return { description, severity, bugReporter };
}

export function isValidBugReport(
  description: string,
  severity?: BugReportSeverity | null
): boolean {
  if (description.length === 0) {
    return false;
  }
  if (!severity) {
    return false;
  }
  return true;
}

export interface RadioItem {
  id: BugReportSeverity;
  name: string;
}

export const radioItems: RadioItem[] = [
  {
    id: 'low',
    name: 'Low',
  },
  {
    id: 'medium',
    name: 'Medium',
  },
  {
    id: 'high',
    name: 'High',
  },
];
