import { LocationAlarm } from '@hakimo-ui/hakimo/types';
import useAuthenticatedRequest from '../shared/useAuthenticatedRequest';

export function useSingleLocationAlarm(
  locationAlarmId: string,
  refetchEvery?: number
) {
  const url = `/v2/orm/location_alarms/${locationAlarmId}`;
  const request = new Request(url);

  return useAuthenticatedRequest<LocationAlarm>(request, {
    queryKey: ['locationAlarms', locationAlarmId],
    responseModifier: async (response) => {
      const respJson = await response.json();
      return respJson.payload;
    },
    refetchInterval: refetchEvery ?? false,
  });
}

export default useSingleLocationAlarm;
