import { LocationAlarm } from '@hakimo-ui/hakimo/types';
import useAuthenticatedRequest from '../shared/useAuthenticatedRequest';

export function useSharedLocationAlarm(sharedToken: string) {
  const url = `/shared/v2/location_alarm?sharedToken=${sharedToken}`;
  const request = new Request(url);

  return useAuthenticatedRequest<LocationAlarm>(request, {
    queryKey: ['locationAlarms', 'shared', sharedToken],
    responseModifier: async (response) => {
      const respJson = await response.json();
      return respJson.payload;
    },
    noAuth: true,
  });
}

export default useSharedLocationAlarm;
