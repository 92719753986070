import { eventTracker } from '@hakimo-ui/hakimo/util';
import { Button, Checkboxes } from '@hakimo-ui/shared/ui-base';
import { Popover } from '@headlessui/react';
import { Bars3Icon } from '@heroicons/react/24/outline';
import { useState } from 'react';
import { ColumMenuAction, Column, ColumnMenuItem } from './types';

interface Props {
  options: Column[];
  shownColumns: string[];
  onChangeShownColumns: (value: string[]) => void;
}

export function ColumnsMenu(props: Props) {
  const { shownColumns, options, onChangeShownColumns } = props;
  const checkboxItems: ColumnMenuItem[] = options.map((item: Column) => ({
    id: item.id,
    value: item.id,
    label: item.name,
  }));
  const checkedItems = checkboxItems.filter((item) =>
    shownColumns.includes(item.id)
  );

  const [columnMenuItems, setColumnMenuItems] =
    useState<ColumnMenuItem[]>(checkedItems);

  const onSelectColumn = (currentDisplayedColumns: ColumnMenuItem[]) => {
    const columnsToShow = currentDisplayedColumns.map((col) => col.id);
    onChangeShownColumns([...columnsToShow]);
    setColumnMenuItems(currentDisplayedColumns);
  };

  const onHideAll = () => {
    onChangeShownColumns([]);
    setColumnMenuItems([]);
  };

  const onShowAll = () => {
    const columnIds: string[] = options.map((option) => option.id);
    onChangeShownColumns(columnIds);
    setColumnMenuItems(checkboxItems);
  };

  const actions: ColumMenuAction[] = [
    {
      name: 'Show All',
      action: onShowAll,
    },
    {
      name: 'Hide All',
      action: onHideAll,
    },
  ];

  return (
    <Popover className="relative">
      <Popover.Button as={'span'}>
        <Button
          variant="icon"
          onSideEffect={eventTracker('update_visible_columns')}
        >
          <span className="sr-only">Open hidden/shown columns menu</span>
          <Bars3Icon className="h-6 w-6" aria-hidden="true" />
        </Button>
      </Popover.Button>
      <Popover.Panel className="container absolute top-9 right-1 z-10 mx-auto inline w-44">
        <div className="dark:bg-dark-bg rounded-md bg-white py-2 px-2 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none dark:divide-gray-700 dark:shadow-gray-700 dark:ring-gray-700">
          <h3 className="dark:text-dark-text mb-2 text-center text-sm font-semibold text-gray-800">
            Show Columns
          </h3>
          <div className="pl-1">
            <Checkboxes
              items={checkboxItems}
              value={columnMenuItems}
              onChange={onSelectColumn}
            />
          </div>
          <div className="mt-3 flex justify-center">
            <span className="w-full text-center">
              {actions.map((action) => (
                <Button
                  key={action.name}
                  variant="link"
                  onClick={action.action}
                >
                  {action.name}
                </Button>
              ))}
            </span>
          </div>
        </div>
      </Popover.Panel>
    </Popover>
  );
}

export default ColumnsMenu;
