export function debounce(
  func: (...args: any[]) => void,
  delay: number
): (...args: any[]) => void {
  let timeoutId: number | null;

  return function debouncedFunction(...args: any[]) {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    timeoutId = window.setTimeout(() => {
      func(...args);
      timeoutId = null;
    }, delay);
  };
}
