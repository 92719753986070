import { GroupPriority, ProcConfig } from '@hakimo-ui/hakimo/types';
import { Select } from '@hakimo-ui/hakimo/ui-elements';
import { InputField, Label } from '@hakimo-ui/shared/ui-base';
import clsx from 'clsx';
interface ConfigTitleProps {
  expanded: boolean;
  onChange: (newConf: Partial<ProcConfig>) => void;
  config: ProcConfig;
}
const getPriorityItems = () => Object.values(GroupPriority);
const getDisplayValue = (item: GroupPriority | undefined) => {
  switch (item) {
    case GroupPriority.ignore:
      return 'Ignore';
    case GroupPriority.P0:
      return 'P0';
    default:
      return 'Default';
  }
};
export function ConfigTitle(props: ConfigTitleProps) {
  const { expanded, onChange, config } = props;
  return (
    <div
      className={clsx(
        'm-2 flex w-3/4 max-w-[58%] flex-wrap gap-x-4',
        !expanded && 'mb-4'
      )}
    >
      <div className="mt-1 overflow-x-auto text-base font-semibold leading-6">
        {config.isEditing ? (
          <InputField
            value={config.name}
            onChange={(e) => onChange({ name: e.currentTarget.value })}
            label="Name"
            type="text"
          />
        ) : (
          <span>{config.name}</span>
        )}
        <div className="text-onlight-text-2 mt-2 flex text-xs font-semibold leading-6">
          {config.isEditing ? (
            <InputField
              value={config.description}
              onChange={(e) => onChange({ description: e.currentTarget.value })}
              label="Description"
              type="text"
            />
          ) : (
            <span>{config.description}</span>
          )}
        </div>
      </div>
      <div className="mt-1 block max-w-[10rem] text-base font-semibold leading-6">
        {config.isEditing ? (
          <>
            <div className="dark:text-ondark-text-2 text-onlight-text-2 mb-1 text-sm font-semibold">
              Priority
            </div>
            <Select
              getItems={getPriorityItems}
              value={GroupPriority[config.priority]}
              onChange={(priority) =>
                priority &&
                onChange({
                  priority: priority ?? GroupPriority.default,
                })
              }
              displayValue={getDisplayValue}
            />
          </>
        ) : (
          <Label text={getDisplayValue(config.priority)} type="info" />
        )}
      </div>
    </div>
  );
}
