import { MARGIN } from './constants';

export interface Props {
  min: number;
  max: number;
  contentWidth: number;
}

export function TimeRange(props: Props) {
  const { min, max, contentWidth } = props;

  return (
    <g>
      <text
        x={0}
        y={MARGIN.top / 2}
        className="dark:fill-ondark-text-2 fill-onlight-text-2 cursor-default text-xs"
        dominantBaseline="central"
      >
        <tspan>{new Date(min).toLocaleTimeString()}</tspan>
      </text>
      <text
        x={contentWidth}
        y={MARGIN.top / 2}
        className="dark:fill-ondark-text-2 fill-onlight-text-2 cursor-default text-xs"
        dominantBaseline="central"
        textAnchor="end"
      >
        <tspan>{new Date(max).toLocaleTimeString()}</tspan>
      </text>
    </g>
  );
}

export default TimeRange;
