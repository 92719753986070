import { hasPermission, useUser } from '@hakimo-ui/hakimo/util';
import clsx from 'clsx';
import HakimoNavLink from '../hakimo/HakimoNavLink';
import Links from './Links';
import SidebarButton from './SidebarButton';
import { NavItem } from './types';

interface Props {
  collapsed?: boolean;
  navItems: NavItem[];
  onClick?: () => void;
  toggleSidebar?: () => void;
  isMobile?: boolean;
}
function SidebarContent(props: Props) {
  const {
    collapsed,
    navItems,
    onClick = () => null,
    toggleSidebar,
    isMobile,
  } = props;
  const user = useUser();

  const items = navItems.filter((item) =>
    item.requiredPermissions.every((perm) => hasPermission(user, perm))
  );

  return (
    <div
      className={clsx(
        !collapsed ? 'space-y-8 px-8' : 'space-y-8 px-2 text-center'
      )}
    >
      {isMobile && <HakimoNavLink />}

      {toggleSidebar &&
        (collapsed ? (
          <div className="border-onlight-line-2 dark:border-ondark-line-2 border-b">
            <HakimoNavLink short />
            <div className="py-4">
              <SidebarButton collapsed onClick={toggleSidebar} />
            </div>
          </div>
        ) : (
          <div className="border-onlight-line-2 dark:border-ondark-line-2 flex h-20 items-center justify-between border-b">
            <HakimoNavLink />
            <SidebarButton onClick={toggleSidebar} />
          </div>
        ))}
      {!collapsed ? (
        <div className="-mx-3 space-y-4">
          <Links items={items} onClick={onClick} />
        </div>
      ) : (
        <Links short items={items} onClick={onClick} />
      )}
    </div>
  );
}

export default SidebarContent;
