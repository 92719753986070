import { useLocationsByName } from '@hakimo-ui/hakimo/data-access';
import {
  CameraFilterValue,
  LocationFilterValue,
} from '@hakimo-ui/hakimo/types';
import MultiSelectFilter from '../multi-select-filter/MultiSelectFilter';

interface Props {
  value: LocationFilterValue;
  label: string;
  enableNegativeFilters?: boolean;
  onChange: (value: LocationFilterValue) => void;
  cameraFilterValue?: CameraFilterValue | undefined;
  onCameraChange?: (
    cameravalue: CameraFilterValue,
    locationValue: LocationFilterValue
  ) => void;
}

export function LocationFilter(props: Props) {
  const {
    value,
    label,
    enableNegativeFilters = false,
    onChange,
    cameraFilterValue,
    onCameraChange,
  } = props;

  const getLocationsByName = useLocationsByName();

  const handleLocationChange = (newValue: LocationFilterValue) => {
    const previousValue = value;
    if (
      cameraFilterValue &&
      cameraFilterValue.values.length > 0 &&
      onCameraChange
    ) {
      const deselectedLocationIds = previousValue.values
        .filter((location) => !newValue.values.includes(location))
        .map((location) => location.id)[0];

      if (deselectedLocationIds !== undefined) {
        const updatedCameraValues = [...cameraFilterValue.values].filter(
          (camera) =>
            camera.location_id !== undefined &&
            !deselectedLocationIds.includes(camera.location_id)
        );
        const updatedCameraFilterValue: CameraFilterValue = {
          ...cameraFilterValue,
          values: updatedCameraValues,
        };
        onCameraChange(updatedCameraFilterValue, newValue);
        return;
      }
    }
    onChange(newValue);
  };

  return (
    <MultiSelectFilter
      value={value}
      label={label}
      enableNegativeFilters={enableNegativeFilters}
      onChange={handleLocationChange}
      getOptions={getLocationsByName}
    />
  );
}

export default LocationFilter;
