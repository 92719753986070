/* eslint-disable max-lines */
import {
  useAddAudioDevices,
  useLocationsByName,
} from '@hakimo-ui/hakimo/data-access';
import {
  AudioDevice,
  Location,
  LocationsDTO,
  audioDeviceTypes,
} from '@hakimo-ui/hakimo/types';
import { SelectAsync, SelectMenu } from '@hakimo-ui/hakimo/ui-elements';
import { eventTracker, toast } from '@hakimo-ui/hakimo/util';
import {
  Alert,
  Button,
  InputField,
  Modal,
  Toggle,
} from '@hakimo-ui/shared/ui-base';
import { useState } from 'react';
import { validateFormValue } from './util';

interface Props {
  onClose: () => void;
}

const initialFormValue: Partial<AudioDevice> = {
  deviceType: audioDeviceTypes[0],
  enableAutomatedTalkdown: true,
};
export function AddAudioDevice(props: Props) {
  const { onClose } = props;
  const [formValue, setFormValue] =
    useState<Partial<AudioDevice>>(initialFormValue);
  const [locations, setLocations] = useState<Location[]>();
  const [validationState, setValidationState] = useState({
    isValid: true,
    message: '',
  });
  const getLocations = useLocationsByName(async (response) => {
    const res = await response.json();
    const payload = res.payload as LocationsDTO;
    setLocations(payload.items);
  });
  const addAudioDeviceMutation = useAddAudioDevices(() => {
    toast('Audio Device Added', { type: 'success' });
    onClose();
  });
  const onSubmit = () => {
    const { isValid, message } = validateFormValue(formValue);
    setValidationState({ isValid, message });
    if (!isValid) {
      return;
    }
    const selectedLocation = locations?.find(
      (loc) => loc.id === formValue.location?.id
    );
    const payload = { ...formValue, tenantId: selectedLocation?.tenant_id };
    addAudioDeviceMutation.mutate([payload]);
  };
  const actions = (
    <>
      <Button
        variant="primary"
        onClick={onSubmit}
        disabled={addAudioDeviceMutation.isLoading}
        onSideEffect={eventTracker('submit_add_audio_device')}
        loading={addAudioDeviceMutation.isLoading}
      >
        Submit
      </Button>
      <Button
        onClick={onClose}
        onSideEffect={eventTracker('cancel_add_audio_device')}
      >
        Cancel
      </Button>
    </>
  );

  const valueUpdater =
    (name: keyof Partial<AudioDevice>) =>
    (arg: React.ChangeEvent<HTMLInputElement> | string) => {
      setFormValue((value) => ({
        ...value,
        [name]: typeof arg === 'string' ? arg : arg.target.value,
      }));
    };

  return (
    <Modal title="Add New Audio Device" open onClose={onClose} footer={actions}>
      {addAudioDeviceMutation.isError && (
        <div className="mt-4 px-8">
          <Alert type="error">{addAudioDeviceMutation.error.message}</Alert>
        </div>
      )}
      {!validationState.isValid && (
        <div className="mt-4 px-8">
          <Alert type="warning">{validationState.message}</Alert>
        </div>
      )}
      <div className="w-[36rem] space-y-4 p-8">
        <InputField
          type="text"
          label="URL"
          value={formValue.url ?? ''}
          onChange={valueUpdater('url')}
        />
        <SelectMenu
          onChange={valueUpdater('deviceType')}
          items={audioDeviceTypes}
          value={formValue.deviceType}
          displayValue={(val) => val ?? 'Unknown'}
        />
        <SelectAsync
          value={{
            id: formValue.location?.id
              ? String(formValue.location.id)
              : 'unknown',
            name: formValue.location?.name || '',
          }}
          label="Location"
          onChange={(newLoc) =>
            setFormValue((oldVal) => {
              return {
                ...oldVal,
                location: {
                  id: Number(newLoc.id),
                  name: newLoc.name ?? 'unknown',
                },
              };
            })
          }
          onChangeQuery={getLocations}
          displayValue={(item) => item?.name || ''}
          id={(item) => String(item.id)}
        />
        <InputField
          type="text"
          label="Username"
          value={formValue.userName ?? ''}
          onChange={valueUpdater('userName')}
        />
        <InputField
          type="text"
          label="Password File"
          value={formValue.passwordFilePath ?? ''}
          onChange={valueUpdater('passwordFilePath')}
        />
        <Toggle
          enabled={formValue.enableAutomatedTalkdown ?? true}
          onChange={(newEnabled) =>
            setFormValue((oldVal) => {
              return { ...oldVal, enableAutomatedTalkdown: newEnabled };
            })
          }
          label="Automated Talkdown"
        />
      </div>
    </Modal>
  );
}
export default AddAudioDevice;
