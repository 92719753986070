import {
  AlarmNotificationPayload,
  UpsertAlarmNotificationPayload,
} from '@hakimo-ui/hakimo/types';
import useAuthenticatedMutation from '../shared/useAuthenticatedMutation';

export function useUpsertAlarmNotificationPreference(onSuccess: () => void) {
  const url = `/v2/orm/alarm_type/notifications`;

  const request = new Request(url, {
    method: 'PUT',
  });

  return useAuthenticatedMutation<
    AlarmNotificationPayload,
    UpsertAlarmNotificationPayload
  >(request, {
    onSuccessInvalidationKeys: [['alarm-notification-preference']],
    responseModifier: async (response) => {
      const respJson = await response.json();
      return respJson.payload;
    },
    onSuccess,
  });
}

export default useUpsertAlarmNotificationPreference;
