import { useDoorsByName } from '@hakimo-ui/hakimo/data-access';
import { DoorNameFilterValue } from '@hakimo-ui/hakimo/types';
import MultiSelectFilter from '../multi-select-filter/MultiSelectFilter';

interface Props {
  value: DoorNameFilterValue;
  label: string;
  enableNegativeFilters?: boolean;
  onChange: (value: DoorNameFilterValue) => void;
}

export function DoorFilter(props: Props) {
  const { value, label, enableNegativeFilters = false, onChange } = props;
  const getDoorsByName = useDoorsByName();

  return (
    <MultiSelectFilter
      value={value}
      label={label}
      enableNegativeFilters={enableNegativeFilters}
      onChange={onChange}
      getOptions={getDoorsByName}
    />
  );
}

export default DoorFilter;
