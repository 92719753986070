import { useGetTwilioCallDetails } from '@hakimo-ui/hakimo/data-access';
import { QueryResult } from '@hakimo-ui/hakimo/ui-elements';

interface Props {
  twilioSsid: string;
  twilioCallingToName?: string;
  userName?: string;
}

export const TwilioCallDetails = (props: Props) => {
  const { twilioSsid, twilioCallingToName, userName } = props;

  const query = useGetTwilioCallDetails(twilioSsid);

  return (
    <div>
      <QueryResult queryResult={query} loadingText="Loading call details">
        {(data) => {
          return (
            <div>
              <div>
                {userName ? `${userName} made a call to ` : 'Call was made to '}
                {twilioCallingToName
                  ? `${twilioCallingToName}(${data.callTo || '-'})`
                  : data.callTo || '-'}
              </div>
              {data.mediaUrl ? (
                <audio
                  preload="auto"
                  src={data.mediaUrl}
                  className="h-7"
                  controls
                ></audio>
              ) : (
                'Call recording not found.'
              )}
            </div>
          );
        }}
      </QueryResult>
    </div>
  );
};

export const getCallDetailsNode = (
  ssid: string,
  twilioCallingToName?: string,
  userName?: string
) => {
  return (
    <TwilioCallDetails
      twilioSsid={ssid}
      twilioCallingToName={twilioCallingToName}
      userName={userName}
    />
  );
};
