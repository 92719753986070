import { useDoorGroupsByName } from '@hakimo-ui/hakimo/data-access';
import { DoorGroupNameFilterValue } from '@hakimo-ui/hakimo/types';
import {
  FilterFooter,
  FilterSection,
  MultiSelectFilter,
} from '@hakimo-ui/hakimo/ui-filters';

interface Props {
  doorGroupFilterValue: DoorGroupNameFilterValue;
  onChangeDoorGroupFilterValue: (value: DoorGroupNameFilterValue) => void;
  onApplyFilters: () => void;
  onResetFilters: () => void;
}

function FilterPanelContent(props: Props) {
  const {
    doorGroupFilterValue,
    onChangeDoorGroupFilterValue,
    onApplyFilters,
    onResetFilters,
  } = props;

  const getDoorGroupsByName = useDoorGroupsByName();

  return (
    <div className="space-y-6">
      <div className="dark:divide-dark-border-bg dark:border-dark-border-bg divide-y border-t border-b">
        <FilterSection
          title="Door Groups"
          content={
            <MultiSelectFilter
              value={doorGroupFilterValue}
              label="Name"
              onChange={onChangeDoorGroupFilterValue}
              enableNegativeFilters
              getOptions={getDoorGroupsByName}
            />
          }
          hasFilterApplied={doorGroupFilterValue.values.length > 0}
        />
      </div>
      <FilterFooter
        onApply={onApplyFilters}
        onReset={onResetFilters}
        trackingEventNameApply="apply_door_groups_filters"
        trackingEventNameReset="reset_door_groups_filters"
      />
    </div>
  );
}

export default FilterPanelContent;
