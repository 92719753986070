import { DoorGroupRequestDTO } from '@hakimo-ui/hakimo/types';
import useAuthenticatedMutation from '../shared/useAuthenticatedMutation';

export function useUpdateDoorGroupDetails(id: number, onSuccess: () => void) {
  const url = `/v2/orm/door_groups/${id}`;

  const request = new Request(url, {
    method: 'PUT',
  });

  return useAuthenticatedMutation<null, DoorGroupRequestDTO>(request, {
    onSuccessInvalidationKeys: [['door_groups']],
    onSuccess,
  });
}

export default useUpdateDoorGroupDetails;
