import { eventTracker } from '@hakimo-ui/hakimo/util';
import { Button } from '@hakimo-ui/shared/ui-base';
import { ExclamationCircleIcon } from '@heroicons/react/24/solid';

interface PendingAlarmProps {
  count: number;
  onGetPending: () => void;
  isLoading: boolean;
}
export function PendingAlarm(props: PendingAlarmProps) {
  const { count, onGetPending, isLoading } = props;
  return (
    <div className="my-auto flex-col">
      <div className="text-status-red mx-auto flex flex-auto justify-center motion-safe:animate-bounce">
        <ExclamationCircleIcon className="max-h-[24rem]" />
      </div>
      <div className="text-onlight-text-2 dark:text-ondark-text-2 mx-auto flex justify-center text-4xl">
        {String(count)} alarms pending
      </div>
      <div className="mx-auto mt-4 flex flex-auto justify-center rounded-md">
        <Button
          variant="primary"
          onClick={onGetPending}
          loading={isLoading}
          disabled={isLoading}
          onSideEffect={eventTracker('get_monitoring_alarm')}
        >
          <div className="text-6xl">Get alarm</div>
        </Button>
      </div>
    </div>
  );
}
