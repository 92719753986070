import { reset } from '@amplitude/analytics-browser';
import { MenuDropdown } from '@hakimo-ui/hakimo/ui-elements';
import { useAuthUtils, useUser } from '@hakimo-ui/hakimo/util';
import { UserCircleIcon } from '@heroicons/react/24/outline';
import { ChevronDownIcon } from '@heroicons/react/24/solid';
import { useNavigate } from 'react-router-dom';

export function UserMenu() {
  const { logout, profilePic } = useAuthUtils();
  const { name } = useUser();
  const navigate = useNavigate();
  const onLogout = () => {
    reset();
    logout({ returnTo: window.location.origin });
  };

  const onClickViewProfile = () => {
    navigate('/profile/general');
  };
  const userMenuItems = [
    {
      name: 'Profile and Settings',
      onClick: onClickViewProfile,
    },
    {
      name: 'Logout',
      onClick: onLogout,
    },
  ];

  const menuButton = (
    <>
      <span className="sr-only">Open user menu</span>
      {profilePic ? (
        <img
          className="h-8 w-8 rounded-full"
          src={profilePic}
          alt="user avatar"
        />
      ) : (
        <UserCircleIcon className="h-8 w-8" />
      )}
      <span className="mx-2 hidden lg:flex lg:items-center">
        <span className="text-sm leading-6" aria-hidden="true">
          {name}
        </span>
        <ChevronDownIcon className="ml-2 h-4 w-4" aria-hidden="true" />
      </span>
    </>
  );

  return <MenuDropdown items={userMenuItems} menuButton={menuButton} />;
}

export default UserMenu;
