import { useSingleLocationAlarm } from '@hakimo-ui/hakimo/data-access';
import { NotFound, QueryResult } from '@hakimo-ui/hakimo/ui-elements';
import { withAuthz, withErrorBoundary } from '@hakimo-ui/hakimo/util';
import { useParams } from 'react-router-dom';
import { AlarmOverview } from '../alarm-overview/AlarmOverview';

type Params = {
  locationAlarmId: string;
};

interface Props {
  locationAlarmId: string;
}

function LocationAlarmDetailsData(props: Props) {
  const { locationAlarmId } = props;

  const queryResult = useSingleLocationAlarm(locationAlarmId);

  return (
    <QueryResult queryResult={queryResult}>
      {(data) => <AlarmOverview alarm={data} />}
    </QueryResult>
  );
}

function LocationAlarmDetailsRoot() {
  const { locationAlarmId } = useParams<Params>();

  if (locationAlarmId === undefined) {
    return <NotFound />;
  }

  return (
    <div className="m-8">
      <LocationAlarmDetailsData locationAlarmId={locationAlarmId} />
    </div>
  );
}

export default withAuthz(withErrorBoundary(LocationAlarmDetailsRoot), [
  'location_alarm/detail:view',
]);
