import { Button, Modal } from '@hakimo-ui/shared/ui-base';

interface Props {
  onConfirm: () => void;
  onClose: () => void;
}

export function ConfirmDelete(props: Props) {
  const { onConfirm, onClose } = props;

  const actions = (
    <>
      <span className="mr-2">
        <Button variant="error" onClick={onConfirm}>
          Confirm
        </Button>
      </span>
      <Button onClick={onClose}>Cancel</Button>
    </>
  );

  return (
    <Modal title="Confirmation" open={true} onClose={onClose} footer={actions}>
      <div className="w-96 px-8 py-4 text-sm">
        Do you really want to delete this camera?
      </div>
    </Modal>
  );
}
