import { CameraDetail } from '@hakimo-ui/hakimo/types';
import useAuthenticatedRequest from '../shared/useAuthenticatedRequest';

export function useCameraDetails(
  camId: string,
  onData?: (camera: CameraDetail) => void
) {
  const url = `/v2/orm/camera/${camId}`;
  const request = new Request(url);

  return useAuthenticatedRequest<CameraDetail>(request, {
    queryKey: ['cameras', camId],
    responseModifier: async (response) => {
      const respJson = await response.json();
      return respJson.payload;
    },
    onSuccess: onData,
    staleTime: 1000 * 60 * 60 * 24 * 7,
  });
}

export default useCameraDetails;
