import useAuthenticatedRequest from '../../shared/useAuthenticatedRequest';

export function usePendingCount(refetchInterval: number) {
  const url = `/v2/orm/location_alarms/pending/count`;
  const request = new Request(url);

  return useAuthenticatedRequest<number>(request, {
    queryKey: ['location_alarms', 'count'],
    responseModifier: async (response) => {
      const respJson = await response.json();
      return respJson.payload;
    },
    cacheTime: 0,
    refetchInterval,
    refetchIntervalInBackground: true,
  });
}

export default usePendingCount;
