import { DoorFilters } from '@hakimo-ui/hakimo/types';

export const UPDATE_FILTERS = 'UPDATE_FILTERS';
export const UPDATE_PAGE = 'UPDATE_PAGE';

export interface UpdateFiltersAction {
  type: typeof UPDATE_FILTERS;
  payload: DoorFilters;
}

export interface UpdatePageAction {
  type: typeof UPDATE_PAGE;
  payload: number;
}

export type Action = UpdateFiltersAction | UpdatePageAction;
