import { eventTracker } from '@hakimo-ui/hakimo/util';
import { Button } from '@hakimo-ui/shared/ui-base';
interface Props {
  isLoading: boolean;
  onClickSave: () => void;
  onClickClear: () => void;
  onClickReset: () => void;
}
function LabelPanelFooter(props: Props) {
  const { isLoading, onClickClear, onClickReset, onClickSave } = props;
  return (
    <div className="mt-6 space-y-3">
      {
        <>
          <Button
            disabled={isLoading}
            variant="primary"
            block
            onClick={onClickSave}
            loading={isLoading}
            onSideEffect={eventTracker('save_camera_deadzones')}
          >
            Save
          </Button>
          <Button
            onClick={onClickReset}
            disabled={isLoading}
            block
            onSideEffect={eventTracker('reset_camera_deadzones')}
          >
            Reset
          </Button>
          <Button
            onClick={onClickClear}
            disabled={isLoading}
            variant="error"
            block
            onSideEffect={eventTracker('clear_camera_deadzones')}
          >
            Clear Label
          </Button>
        </>
      }
    </div>
  );
}

export default LabelPanelFooter;
