import useAuthenticatedMutation from '../shared/useAuthenticatedMutation';

export function useDeleteAlarmNotificationPreference(
  id: string,
  onSuccess: () => void
) {
  const url = `/v2/orm/alarm_type/notifications/${id}`;

  const request = new Request(url, {
    method: 'DELETE',
  });

  return useAuthenticatedMutation(request, {
    onSuccessInvalidationKeys: [['alarm-notification-preference']],
    onSuccess,
  });
}

export default useDeleteAlarmNotificationPreference;
