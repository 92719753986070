import { useModifyAudioDevices } from '@hakimo-ui/hakimo/data-access';
import { AudioDevice } from '@hakimo-ui/hakimo/types';
import {
  eventTracker,
  toast,
  useCanModifyAudioDevice,
} from '@hakimo-ui/hakimo/util';
import { Button, Modal, Toggle } from '@hakimo-ui/shared/ui-base';
import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/24/outline';
import { useEffect, useState } from 'react';

interface Props {
  audioDevice: AudioDevice;
}

export function AutomatedTalkdownToggle(props: Props) {
  const { enableAutomatedTalkdown, id, cameras, tenantId, location } =
    props.audioDevice;
  const [showModal, setShowModal] = useState(false);
  const onModalClose = () => setShowModal(false);
  const canModifyAudioDevice = useCanModifyAudioDevice();
  const [enabled, setEnabled] = useState(enableAutomatedTalkdown);

  const modifyAudioDeviceMutation = useModifyAudioDevices(
    () => {
      toast('Automated talkdown updated', { type: 'success' });
      onModalClose();
      setEnabled((prev) => !prev);
    },
    () => {
      toast('Failed to update automated talkdown', { type: 'error' });
      onModalClose();
    }
  );

  useEffect(() => {
    setEnabled(enableAutomatedTalkdown);
  }, [enableAutomatedTalkdown]);

  const onToggle = () => setShowModal(true);

  const onSubmitAction = () => {
    const payload = {
      id,
      tenantId,
      enableAutomatedTalkdown: !enableAutomatedTalkdown,
      location,
    };
    modifyAudioDeviceMutation.mutate(payload);
  };

  const modalActions = (
    <>
      <Button
        variant="primary"
        onClick={onSubmitAction}
        disabled={modifyAudioDeviceMutation.isLoading}
        onSideEffect={eventTracker('update_automated_talkdown')}
        loading={modifyAudioDeviceMutation.isLoading}
      >
        {enabled ? 'Disable' : 'Enable'}
      </Button>
      <Button onClick={onModalClose}>Cancel</Button>
    </>
  );

  if (!canModifyAudioDevice) {
    return enabled ? (
      <div className="text-status-green h-5 w-5">
        <CheckCircleIcon />
      </div>
    ) : (
      <div className="text-status-red h-5 w-5">
        <XCircleIcon />
      </div>
    );
  }

  return (
    <div>
      <Toggle enabled={enabled} onChange={onToggle} />
      {showModal && (
        <Modal
          onClose={onModalClose}
          title="Automated talkdown"
          open={showModal}
          footer={modalActions}
        >
          <div className="p-6 leading-8">
            <div>Associated Cameras</div>
            <ul className="list-disc pl-4">
              {cameras.map((cam) => (
                <li>{cam.name}</li>
              ))}
            </ul>
            <div>
              You are about to
              <span className="dark:bg-ondark-bg-2 bg-onlight-bg-2 whitespace-pre rounded-md py-1 px-2 font-bold">
                {enabled ? 'disable' : 'enable'}
              </span>
              automated talkdown for this audio device. Are you sure ?
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
}

export default AutomatedTalkdownToggle;
