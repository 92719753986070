import { LocationContact } from '@hakimo-ui/hakimo/types';

export const ADD_CONTACT_ID_PREFIX = 'contact-add-';

export const getColumns = () => {
  return [
    {
      id: 'priority',
      name: 'Priority',
    },
    {
      id: 'name',
      name: 'Name',
    },
    {
      id: 'phoneNumber',
      name: 'Phone Number',
    },
    {
      id: 'email',
      name: 'Email',
    },
    {
      id: 'action',
      name: 'Actions',
    },
  ];
};

export function validateValues(contact: LocationContact) {
  let message = '';
  if (contact.name === '') {
    message = 'Contact name should be provided';
  } else if (contact.phoneNumber === '') {
    message = 'Contact phone number should be provided';
  }

  return { isValid: !message, message };
}
