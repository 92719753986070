import { MultiSelect } from '@hakimo-ui/hakimo/ui-elements';
import { getMultiSelectItemsSelectable } from '@hakimo-ui/hakimo/util';
import { Selectable } from '@hakimo-ui/shared/ui-base';
interface Props {
  value: Selectable[];
  onChange: (value: Selectable[]) => void;
}

function EmailFilter(props: Props) {
  const { value, onChange } = props;

  const getItems = (query: string) => {
    return getMultiSelectItemsSelectable(query, value);
  };

  return (
    <MultiSelect
      label="Email"
      value={value}
      onChange={onChange}
      getItems={getItems}
      displayValue={(item) => item?.name}
      id={(item) => item.id}
    />
  );
}

export default EmailFilter;
