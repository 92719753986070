import { AlarmVideoMarker } from '@hakimo-ui/hakimo/types';
import { Button } from '@hakimo-ui/shared/ui-base';
import clsx from 'clsx';
import { ReactElement } from 'react';

export interface Props {
  markers?: AlarmVideoMarker[];
  activeMarker?: AlarmVideoMarker;
  onTimestampClick: (timestamp: number) => void;
  timer: ReactElement;
}

export function AlarmVideoFooter(props: Props) {
  const { markers, activeMarker, onTimestampClick, timer } = props;

  return (
    <div className="flex items-center justify-between">
      <div className="space-x-3 px-4 py-4">
        {markers &&
          markers
            .sort((a, b) => a.timestamp - b.timestamp)
            .map((marker, idx) => {
              return (
                <span
                  key={idx}
                  className={clsx(
                    'inline-flex items-center rounded-full bg-opacity-30',
                    marker === activeMarker &&
                      (marker?.type === 'Warning'
                        ? 'bg-hakimo-yellow'
                        : marker?.type === 'Info'
                        ? 'bg-primary-400 dark:bg-primary-800'
                        : marker?.type === 'Error'
                        ? 'bg-red-400 dark:bg-red-800'
                        : '')
                  )}
                >
                  <Button
                    variant="link"
                    onClick={() => onTimestampClick(marker.timestamp)}
                  >
                    {marker.label}
                  </Button>
                </span>
              );
            })}
      </div>
      {timer}
    </div>
  );
}

export default AlarmVideoFooter;
