import StateProvider from './store/StateProvider';
import UserRoutes from './UserRoutes';

export function UsersRoot() {
  return (
    <StateProvider>
      <UserRoutes />
    </StateProvider>
  );
}

export default UsersRoot;
