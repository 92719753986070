/* eslint-disable max-lines */
import { LiveViewSwitch } from '@hakimo-ui/hakimo/feature-shared';
import { Camera } from '@hakimo-ui/hakimo/types';
import { debounce } from '@hakimo-ui/hakimo/util';
import { HakimoSpinner } from '@hakimo-ui/shared/ui-base';
import clsx from 'clsx';
import { useCallback, useEffect, useRef, useState } from 'react';
import { MultiCamViewMode } from '../../constants';
import MultiCamItemWrapper from '../multi-cam-item-wrapper/MultiCamItemWrapper';
import MultiCamPlaybackItem from '../multi-cam-playback-item/MultiCamPlaybackItem';
import MultiCamTimeline from '../multi-cam-timeline/MultiCamTimeline';

interface Props {
  items: Array<Camera | null>;
  viewMode: MultiCamViewMode;
  timezone?: string;
  updateItems: (items: Array<Camera | null>) => void;
  allCameras?: Camera[];
  isFetching?: boolean;
}

export function GridItemsContainer(props: Props) {
  const {
    items,
    viewMode,
    allCameras = [],
    updateItems,
    timezone,
    isFetching,
  } = props;
  const [seekerTime, setSeekerTime] = useState<Date>();
  const [camsTriggerTime, setCamsTriggerTime] = useState<number[]>([]);

  const cameraCount = items.length;
  const [camsPlayStatus, setCamsPlayStatus] = useState<boolean[]>([]);
  const seekerTimeRef = useRef<number>();

  useEffect(() => {
    if (viewMode === MultiCamViewMode.PLAYBACK) {
      let updatedTime = seekerTimeRef.current;
      if (!updatedTime) {
        const today = new Date();
        today.setMinutes(today.getMinutes() - 15);
        updatedTime = today.getTime();
      }
      setSeekerTime(new Date(updatedTime));
      setCamsTriggerTime(new Array(items.length).fill(updatedTime));
    } else {
      setSeekerTime(undefined);
    }
  }, [items, viewMode]);

  useEffect(() => {
    let intervalId: number;
    const isOneVideoPlaying = camsPlayStatus.some((status) => status === true);
    if (
      viewMode === MultiCamViewMode.PLAYBACK &&
      timezone &&
      isOneVideoPlaying
    ) {
      intervalId = window.setInterval(() => {
        setSeekerTime((prev) => {
          if (prev !== undefined) {
            const newTime = new Date(prev.getTime());
            newTime.setSeconds(newTime.getSeconds() + 1);
            seekerTimeRef.current = newTime.getTime();
            return newTime;
          }
          return prev;
        });
      }, 1000);
    }

    return () => {
      intervalId && window.clearInterval(intervalId);
    };
  }, [camsPlayStatus, timezone, viewMode]);

  useEffect(() => {
    const intervalId = window.setInterval(() => {
      setCamsTriggerTime((prev) => {
        const length = prev.length;
        return seekerTimeRef.current
          ? new Array(length).fill(seekerTimeRef.current)
          : prev;
      });
    }, 10000);
    return () => {
      window.clearInterval(intervalId);
    };
  }, []);

  const onRemoveCamera = (index: number) => () => {
    const updatedItems = [...items];
    updatedItems.splice(index, 1);
    updateItems(updatedItems);
  };
  const onCameraChange = (index: number) => (cam: Camera) => {
    const updatedItems = [...items];
    updatedItems[index] = cam;
    updateItems(updatedItems);
  };

  const debouncedUpdateCamsTriggerTime = debounce((time: Date) => {
    setCamsTriggerTime(new Array(items.length).fill(time.getTime()));
  }, 1000);

  const updateSeekerTime = (time: Date) => {
    setSeekerTime(time);
    debouncedUpdateCamsTriggerTime(time);
  };

  const updateCamPlayStatus = useCallback(
    (index: number) => (isPlaying: boolean) => {
      setCamsPlayStatus((prev) => {
        const updatedCamStatus = [...prev];
        updatedCamStatus[index] = isPlaying;
        return updatedCamStatus;
      });
    },
    []
  );

  const getGridItem = (cam: Camera | null, i: number) => {
    if (isFetching && allCameras.length === 0) {
      return <Loader key={cam ? cam.id : i} />;
    }

    let viewModeComponent = null;

    if (viewMode === MultiCamViewMode.LIVE && cam) {
      viewModeComponent = (
        <LiveViewSwitch
          camera={cam}
          controlsList={['noForwardBackward', 'noPlayPause']}
          lockAspectRatio
        />
      );
    } else if (viewMode === MultiCamViewMode.PLAYBACK && cam) {
      viewModeComponent = (
        <MultiCamPlaybackItem
          cameraId={cam.id}
          cameraTimeZone={cam.location.timezone}
          triggerTime={camsTriggerTime[i]}
          updatePlayStatus={updateCamPlayStatus(i)}
        />
      );
    }
    return (
      <MultiCamItemWrapper
        index={i}
        key={cam ? `${cam.id}-${i} ` : i}
        isClosedHidden={cameraCount === 1}
        allCameras={allCameras}
        onRemoveCamera={onRemoveCamera(i)}
        selectedCamera={cam}
        onCameraChange={onCameraChange(i)}
      >
        {cam && viewModeComponent}
      </MultiCamItemWrapper>
    );
  };

  return (
    <>
      <div
        className={clsx(
          'grid gap-2',
          cameraCount === 1 ? 'sm:grid-cols-1' : 'sm:grid-cols-1 lg:grid-cols-2'
        )}
      >
        {items.map((cam, i) => getGridItem(cam, i))}
      </div>
      {seekerTime && timezone && (
        <MultiCamTimeline
          seekerTime={seekerTime}
          timeZone={timezone}
          updateSeekerTime={updateSeekerTime}
        />
      )}
    </>
  );
}

export default GridItemsContainer;

function Loader() {
  return (
    <div className="border-onlight-line-2 dark:border-ondark-line-2 flex min-h-[20rem] items-center justify-center border">
      <HakimoSpinner />
    </div>
  );
}
