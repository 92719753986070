import { AudioDevice } from '@hakimo-ui/hakimo/types';

export function validateFormValue(value: Partial<AudioDevice>) {
  let isValid = true;
  let message = '';
  if (!value.url || value.url?.trim() === '') {
    message = 'Provide a valid URL for the audio device';
    isValid = false;
  } else if (!value.deviceType) {
    message = 'Select a valid device type for the audio device';
    isValid = false;
  } else if (value.location === undefined) {
    message = 'Select a valid location for the audio device';
    isValid = false;
  }
  return { isValid, message };
}
