import { TimePeriod } from '@hakimo-ui/hakimo/types';
import { atom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';
import { defaultFilterValues } from './util';

export const pageAtom = atom<number>(1);
export const pageSizeAtom = atom<number>(30);
export const timePeriodAtom = atomWithStorage<TimePeriod>(
  'escalationList/timePeriod',
  TimePeriod.PAST_24_HOURS
);

export const escalationFiltersAtom = atomWithStorage(
  'escalationList/filters',
  defaultFilterValues
);
