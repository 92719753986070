import { Alarm } from '@hakimo-ui/hakimo/types';
import useAuthenticatedRequest from '../shared/useAuthenticatedRequest';

export function useAlarm(id: string) {
  const url = `/v2/orm/alarm/${id}`;
  const request = new Request(url);

  return useAuthenticatedRequest<Alarm>(request, {
    queryKey: ['alarms', id],
    responseModifier: async (response) => {
      const respJson = await response.json();
      return respJson.payload;
    },
  });
}

export default useAlarm;
