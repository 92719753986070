import { NewDCP } from '@hakimo-ui/hakimo/types';
import useAuthenticatedMutation from '../shared/useAuthenticatedMutation';

export function useAddDCP(onSuccess: () => void) {
  const url = `/v2/orm/dcp`;

  const request = new Request(url, {
    method: 'POST',
  });

  return useAuthenticatedMutation<null, NewDCP | null>(request, {
    onSuccessInvalidationKeys: [['dcps'], ['doors']],
    onSuccess,
  });
}

export default useAddDCP;
