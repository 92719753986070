import { UpdateSelfPayload } from '@hakimo-ui/hakimo/types';
import useAuthenticatedMutation from '../shared/useAuthenticatedMutation';

export function useModifySelf(onSuccess: () => void) {
  const url = `/v2/orm/user`;

  const request = new Request(url, {
    method: 'PATCH',
  });

  return useAuthenticatedMutation<null, UpdateSelfPayload | null>(request, {
    onSuccessInvalidationKeys: [['users'], ['user', 'profile']],
    onSuccess,
  });
}

export default useModifySelf;
