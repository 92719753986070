import { Alarm } from '@hakimo-ui/hakimo/types';
import { Dot } from '@hakimo-ui/hakimo/ui-elements';
import { Page } from '@hakimo-ui/hakimo/ui-layout';
import { useCanVoiceCall, useLocalStorage } from '@hakimo-ui/hakimo/util';
import { useNavigate } from 'react-router-dom';
import AlarmMedia from './alarm-media/AlarmMedia';
import AlarmStatusPanel from './alarm-status/AlarmStatus';
import AlarmSummary from './alarm-summary/AlarmSummary';
import AlarmUpdates from './alarm-updates/AlarmUpdates';
import AlarmVideoTags from './alarm-video-tags/AlarmVideoTags';
import Floorplan from './floorplan/Floorplan';
import LocationContacts from './location-contacts/LocationContacts';
import NeighboringEvents from './neighboring-events/NeighboringEvents';
import PageActions from './page-actions/PageActions';
import { useTrackAlarmEvents } from './useTrackAlarmEvents';

interface Props {
  alarm: Alarm;
  onRefresh: () => void;
  sharedToken?: string;
}

function AlarmDetails(props: Props) {
  const { alarm, sharedToken, onRefresh } = props;
  const { onTrackResolve, onTrackTalkdown } = useTrackAlarmEvents(alarm.id);

  const navigate = useNavigate();
  const [alarmReturnPostResolve] = useLocalStorage<boolean>(
    'alarmReturnPostResolve'
  );
  const canVoiceCall = useCanVoiceCall();
  const subtitle = (
    <span className="dark:text-dark-secondary-text inline-flex max-w-[65%] items-center text-xs text-gray-500 xl:max-w-full">
      {alarm.type}
      <Dot />
      {alarm.sourceEntity?.name ?? 'Unknown'}
      <span title={alarm.id} className="hidden items-center sm:inline-flex">
        <Dot />
        {alarm.id}
      </span>
    </span>
  );

  const onClickBack = () => {
    navigate('/alarms');
  };

  const onResolveAlarm = () => {
    onTrackResolve();

    if (alarmReturnPostResolve) {
      onClickBack();
    }
  };

  return (
    <Page
      title="Alarm Details"
      subtitle={subtitle}
      secondary={
        <PageActions
          alarmId={alarm.id}
          sharedToken={sharedToken}
          onRefresh={onRefresh}
        />
      }
      onClickBack={!sharedToken ? onClickBack : undefined}
    >
      <div className="lg:grid-cols-3/4-1/4 grid grid-cols-1 items-start gap-6 pb-8">
        <div className="grid gap-6">
          <AlarmMedia
            alarm={alarm}
            sharedToken={sharedToken}
            onTalkdown={onTrackTalkdown}
          />
          <div className="grid grid-cols-2 gap-6">
            <AlarmStatusPanel alarm={alarm} onResolve={onResolveAlarm} />
            <AlarmVideoTags tags={alarm.tags || []} />
            <NeighboringEvents alarm={alarm} sharedToken={sharedToken} />
            <AlarmUpdates alarm={alarm} sharedToken={sharedToken} />
          </div>
          <Floorplan alarm={alarm} />
        </div>
        <div className="sticky top-6 z-0">
          <AlarmSummary alarm={alarm} />
          <div className="mt-4" />
          {canVoiceCall && alarm.location && (
            <LocationContacts location={alarm.location} />
          )}
        </div>
      </div>
    </Page>
  );
}

export default AlarmDetails;
