/* eslint-disable max-lines */
import { useCameras } from '@hakimo-ui/hakimo/data-access';
import { Camera } from '@hakimo-ui/hakimo/types';
import { trackEvent, useAuthUtils, useUser } from '@hakimo-ui/hakimo/util';
import { Selectable } from '@hakimo-ui/shared/ui-base';
import clsx from 'clsx';
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useVisionEvents } from '../../hooks/useVisionEvents';
import { CamAction, GridConfig, ScanMode } from '../../types/common';
import {
  EventHistory,
  Person,
  VisionEvent,
  VisionOutboundPayload,
  VisionOutboundType,
} from '../../types/event';
import { getQueryParams } from '../utils';
import DynamicLayout, { DynamicLayoutRef } from './DynamicLayout';
import ScanToolbar from './ScanToolbar';
import { SnoozedCams } from './SnoozedCams';
import {
  getImageBlob,
  getPersonIds,
  removeOldHistoryEvents,
  SNOOZE_TIME,
} from './utils';

interface Props {
  visionTenants: Selectable[];
  isFullScreen: boolean;
  escalatedCamId: string;
  toggleFullScreen: () => void;
  scanMode: ScanMode;
  onEscalate: (camera: Camera) => void;
  onCloseWs?: (event: CloseEvent) => void;
  onErrorWs?: (event: Event) => void;
}

export interface CamsMonitorRef {
  sendMessageInWebSocket: (data: VisionOutboundPayload) => void;
}

const CamsMonitor = forwardRef<CamsMonitorRef, Props>((props, ref) => {
  const {
    isFullScreen,
    toggleFullScreen,
    scanMode,
    visionTenants,
    onEscalate,
    escalatedCamId,
    onCloseWs,
    onErrorWs,
  } = props;
  const [snoozedCamIds, setSnoozedCamIds] = useState<string[]>([]);
  const dynamicLayoutRef = useRef<DynamicLayoutRef>(null);
  const [camToEventHistoryMap, setCamToEventHistoryMap] = useState<
    Record<string, EventHistory[]>
  >({});
  const camsPopupTriggeredAtRef = useRef<Record<string, number>>({});
  const camHistoryFetchMapRef = useRef<
    Record<string, { isHistoryFetched: boolean; tenantId?: string }>
  >({});
  const camToRecentEventMapRef = useRef<Record<string, Person[]>>({});
  const { getAccessToken } = useAuthUtils();
  const { data: allCamerasDto } = useCameras(
    getQueryParams(visionTenants, 'tenants')
  );
  const user = useUser();
  const [gridConfig, setGridConfig] = useState<GridConfig>({
    cols: 4,
    rows: 4,
  });

  const allCameras = useMemo(
    () => allCamerasDto?.items || [],
    [allCamerasDto?.items]
  );

  const handleDetectionEvent = useCallback(
    (events: VisionEvent[]) => {
      const eventCamIds = events.map((event) => {
        trackEvent('event_received', {
          cameraId: event.camera_id,
          tenantId: event.tenant_id,
          groupId: event.group_id,
        });
        return event.camera_id;
      });
      const camIds = Array.from(new Set(eventCamIds));
      const dynamicLayout = dynamicLayoutRef.current;
      if (!dynamicLayout) {
        return;
      }
      const activeCams = dynamicLayout.getActiveCameras();
      const newCamToAdd: Camera[] = [];
      for (const camId of camIds) {
        const isAlreadyActive = activeCams.some(
          (activeCam) => activeCam.id === camId
        );
        const isCamSnoozed = snoozedCamIds.some(
          (snoozedCamId) => snoozedCamId === camId
        );
        if (!isAlreadyActive && !isCamSnoozed) {
          const newCam = allCameras?.find((cam) => cam.id === camId);
          newCam && newCamToAdd.push(newCam);
          newCamToAdd.length > 0 && dynamicLayout.addCameras(newCamToAdd);
          const camHistoryFetchMap = camHistoryFetchMapRef.current;
          const isHistoryFetched = camHistoryFetchMap[camId];
          if (!isHistoryFetched) {
            camHistoryFetchMapRef.current = {
              ...camHistoryFetchMap,
              [camId]: { isHistoryFetched: false, tenantId: newCam?.tenantId },
            };
          }
          const camsPopupTriggeredAt = camsPopupTriggeredAtRef.current;
          if (newCam) {
            camsPopupTriggeredAt[newCam.id] = Date.now();
            camsPopupTriggeredAtRef.current = camsPopupTriggeredAt;
          }

          trackEvent('event_triggering_camera_pop_up', {
            cameraId: newCam?.id,
            tenantId: newCam?.tenantId,
          });
        }
      }
      // save most recent detection event for all cameras
      // this will be used while sending safe event
      const camToRecentEventMap = camToRecentEventMapRef.current;
      const updatedCamToRecentEventMap = { ...camToRecentEventMap };
      events.forEach((event) => {
        if (!updatedCamToRecentEventMap[event.camera_id]) {
          updatedCamToRecentEventMap[event.camera_id] = [];
        }
        updatedCamToRecentEventMap[event.camera_id] = event.metadata.persons;
      });
      camToRecentEventMapRef.current = updatedCamToRecentEventMap;
    },
    [allCameras, snoozedCamIds]
  );

  const handleHistoryEvent = (events: VisionEvent[]) => {
    const updatedMap = { ...camToEventHistoryMap };
    events.forEach((event) => {
      const camId = event.id;
      const camEvents = updatedMap[camId] ? [...updatedMap[camId]] : [];
      const historyEvent: EventHistory = {
        createdTime: event.timestamp_utc,
        frame: getImageBlob(event.metadata.frame),
        id: event.id,
        cameraId: event.camera_id,
        cameraName: event.camera_name,
        personIds: getPersonIds(event.metadata),
      };
      updatedMap[camId] = [...camEvents, historyEvent];
    });
    setCamToEventHistoryMap(updatedMap);
    // TODO: Call this method periodically instead with every update
    removeOldHistoryEvents(camToEventHistoryMap, setCamToEventHistoryMap);
  };

  const socketUrl = useMemo(() => {
    const allTenants = visionTenants.map((tenant) => tenant.id).join(',');

    const host = window.location.hostname;
    return `wss://event-flow-${host}/events?tenants=${allTenants}`;
  }, [visionTenants]);

  const { sendMessage: sendMessageInWS } = useVisionEvents(
    socketUrl,
    getAccessToken,
    handleDetectionEvent,
    handleHistoryEvent,
    onCloseWs,
    onErrorWs
  );

  useEffect(() => {
    const allActiveCamIds = Object.keys(camHistoryFetchMapRef.current);
    allActiveCamIds.forEach((camId) => {
      const camHistoryFetchMap = camHistoryFetchMapRef.current;
      const camHistoryFetchData = camHistoryFetchMap[camId];
      const isHistoryFetched = camHistoryFetchData.isHistoryFetched;
      if (!isHistoryFetched) {
        sendMessageInWS({
          camera_id: camId,
          tenant_id: camHistoryFetchData.tenantId || '',
          event_type: VisionOutboundType.HISTORY,
        });
        const updatedFetchData = {
          ...camHistoryFetchData,
          isHistoryFetched: true,
        };
        camHistoryFetchMapRef.current = {
          ...camHistoryFetchMap,
          [camId]: updatedFetchData,
        };
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sendMessageInWS, camHistoryFetchMapRef.current]);

  const sendMessageInWebSocket = (data: VisionOutboundPayload) => {
    sendMessageInWS(data);
  };

  useImperativeHandle(ref, () => ({
    sendMessageInWebSocket,
  }));

  const onArrangeSmartly = () => {
    const dynamicLayout = dynamicLayoutRef.current;
    if (!dynamicLayout) {
      return;
    }
    dynamicLayout.arrangeSmartly();
  };

  const onCameraAction = (actionType: CamAction, cameraId: string) => {
    const camsPopupTriggeredAt = camsPopupTriggeredAtRef.current;
    switch (actionType) {
      case CamAction.SNOOZE:
        setSnoozedCamIds((prev) => [...prev, cameraId]);
        setTimeout(() => {
          setSnoozedCamIds((prev) => {
            const updatedCams = [...prev];
            const index = updatedCams.findIndex((cam) => cam === cameraId);
            index > -1 && updatedCams.splice(index, 1);
            return updatedCams;
          });
        }, SNOOZE_TIME * 1000);
        break;
      case CamAction.ESCALATE: {
        const selectedCam = allCameras.find((cam) => cam.id === cameraId);
        if (!selectedCam) {
          return;
        }
        onEscalate(selectedCam);
        sendMessageInWS({
          camera_id: selectedCam?.id || '',
          tenant_id: selectedCam?.tenantId || '',
          event_type: VisionOutboundType.ESCALATION_OPEN,
          additional_data: {
            username: user.email,
            camera_display_timestamp: camsPopupTriggeredAt[selectedCam.id] ?? 0,
            escalation_open_timestamp: Date.now(),
          },
        });
        break;
      }
      case CamAction.SAFE: {
        const selectedCam = allCameras.find((cam) => cam.id === cameraId);
        const camToRecentEventMap = camToRecentEventMapRef.current;
        if (!selectedCam) {
          return;
        }
        const recentEvent = camToRecentEventMap[cameraId];
        const personIds = recentEvent.map((p) => p.person_id);

        sendMessageInWS({
          camera_id: selectedCam?.id || '',
          tenant_id: selectedCam?.tenantId || '',
          event_type: VisionOutboundType.SAFE,
          person_ids: personIds ?? [],
          additional_data: {
            username: user.email,
            camera_display_timestamp: camsPopupTriggeredAt[selectedCam.id] ?? 0,
            safe_action_timestamp: Date.now(),
          },
        });

        //clear history events and cam history fetch map
        const camHistoryFetchMap = { ...camHistoryFetchMapRef.current };
        delete camHistoryFetchMap[cameraId];
        camHistoryFetchMapRef.current = camHistoryFetchMap;
        const updatedCamToHistoryMap = { ...camToEventHistoryMap };
        updatedCamToHistoryMap[cameraId] = [];
        setCamToEventHistoryMap(updatedCamToHistoryMap);

        break;
      }
      default:
        break;
    }
  };

  const unSnoozeCam = (camId: string) => {
    const updatedSnoozedCamIdss = [...snoozedCamIds];
    const camIndex = updatedSnoozedCamIdss.findIndex((cam) => cam === camId);
    camIndex > -1 && updatedSnoozedCamIdss.splice(camIndex, 1);
    setSnoozedCamIds(updatedSnoozedCamIdss);
    trackEvent('camera_unsnoozed', {
      cameraId: camId,
    });
  };

  const snoozedCameras = allCameras.filter((item) =>
    snoozedCamIds.includes(item.id)
  );

  const onCameraCountUpdate = (cameraCount: number) => {
    sendMessageInWS({
      event_type: VisionOutboundType.CAMERA_COUNT,
      additional_data: {
        username: user.email,
        active_camera_count: cameraCount,
        snoozed_camera_count: snoozedCamIds.length,
      },
    });
  };

  return (
    <div
      className={clsx(
        'flex flex-col',
        isFullScreen ? 'h-[calc(100vh-2rem)]' : 'h-[calc(100%-8px)]'
      )}
    >
      <div className="w-full flex-grow">
        <DynamicLayout
          ref={dynamicLayoutRef}
          scanMode={scanMode}
          isFullScreen={isFullScreen}
          onCameraAction={onCameraAction}
          escalationCamId={escalatedCamId}
          camsEventHistoryMap={camToEventHistoryMap}
          onCameraCountUpdate={onCameraCountUpdate}
          gridConfig={gridConfig}
        />
      </div>
      <div className="mr-14 flex max-w-[calc(100vw-24px)] items-center gap-2">
        <SnoozedCams
          cameras={snoozedCameras || []}
          unSnoozeCam={unSnoozeCam}
          camsEventHistoryMap={camToEventHistoryMap}
        />
        <ScanToolbar
          toggleFullScreen={toggleFullScreen}
          isFullScreen={isFullScreen}
          onArrangeSmartly={onArrangeSmartly}
          gridConfig={gridConfig}
          updateGridConfig={setGridConfig}
        />
      </div>
    </div>
  );
});

export default CamsMonitor;
