/* eslint-disable max-lines */
import { useSendAudio } from '@hakimo-ui/hakimo/data-access';
import { SOPWorkflow } from '@hakimo-ui/hakimo/types';
import { toast, trackEvent } from '@hakimo-ui/hakimo/util';
import { Button } from '@hakimo-ui/shared/ui-base';
import { useAtom } from 'jotai';
import { useCallback, useMemo, useState } from 'react';
import { LocationAlarmCamera } from '../quick-actions-panel/util';
import { WorkflowActions } from './WorkflowActions';
import { WorkflowBody } from './WorkflowBody';
import WorkflowHeader from './WorkflowHeader';
import {
  SOPStepType,
  currentStepAtom,
  getLatestCameraWithTalkdown,
  getSOPSteps,
} from './util';

interface Props {
  navigateToResolve: (isQuickResolve?: boolean) => void;
  navigateToEscalate: () => void;
  sopWorkflow: SOPWorkflow;
  locationId: string;
  locationAlarmId: string;
  alarmCameras: LocationAlarmCamera[];
  onQuickResolve: (comment: string) => void;
  isResolveSubmitting: boolean;
}

export function SOPWorkflowRoot(props: Props) {
  const {
    navigateToEscalate,
    navigateToResolve,
    sopWorkflow,
    alarmCameras,
    locationAlarmId,
    onQuickResolve,
    isResolveSubmitting,
  } = props;

  const [currentStep, setCurrentStep] = useAtom(currentStepAtom);
  const [isTimerRunning, setIsTimerRunning] = useState(false);
  const [talkdownInitiated, setTalkdownInitiated] = useState(false);

  const latestCameraWithSpeaker = useMemo(() => {
    return getLatestCameraWithTalkdown(alarmCameras);
  }, [alarmCameras]);

  const exceptions = sopWorkflow.exceptions.map((exception, i) => (
    <li key={i}>{exception}</li>
  ));

  const notes = (sopWorkflow?.notes || []).map((note, i) => (
    <li key={i}>{note}</li>
  ));

  const navigateToNextStep = () => {
    if (currentStep === 0) {
      setIsTimerRunning(true);
    }
    setCurrentStep((prev) => prev + 1);
    setTalkdownInitiated(false);
  };

  const onStepChange = (stepIndex: number) => {
    setCurrentStep(stepIndex);
    setTalkdownInitiated(false);
  };

  const logTalkdown = useCallback(
    (cameraId: string) => {
      trackEvent('submit_camera_details_audio_talkdown', {
        camera_id: cameraId,
        locationAlarmId,
      });
      setTalkdownInitiated(true);
    },
    [locationAlarmId]
  );

  const mutation = useSendAudio(
    latestCameraWithSpeaker ? latestCameraWithSpeaker.id : null,
    undefined,
    locationAlarmId,
    () => {
      logTalkdown(latestCameraWithSpeaker ? latestCameraWithSpeaker.id : '');
      toast('Audio sent to camera successfully');
    }
  );

  const performTalkdown = useCallback(
    (file?: File) => {
      const formData = new FormData();
      formData.append('audio', file || '');
      mutation.mutate(formData);
      logTalkdown(latestCameraWithSpeaker ? latestCameraWithSpeaker.id : '');
    },
    [latestCameraWithSpeaker, logTalkdown, mutation]
  );

  const logAndEscalate = () => {
    trackEvent('sop_workflow_escalate_location_alarm_step_count', {
      locationAlarmId,
      escalated_after_step_count: currentStep,
    });
    navigateToEscalate();
  };

  const items = getSOPSteps(
    navigateToNextStep,
    navigateToResolve,
    sopWorkflow,
    exceptions,
    notes,
    logAndEscalate,
    latestCameraWithSpeaker !== null
  );

  const currentItem = items[currentStep];
  const isItemBodyAvailable =
    currentItem.exceptions || currentItem.talkdownText;

  const showQuestionAndAction =
    currentItem.type === SOPStepType.QUESTION ? true : talkdownInitiated;

  const onQuickResolveAction = (action: string) => () => onQuickResolve(action);

  return (
    <>
      <div className="divide-onlight-line-3 dark:divide-ondark-line-3 relative space-y-6 divide-y">
        <WorkflowHeader
          totalSteps={items.length}
          currentStep={currentStep}
          isTimerRunning={isTimerRunning}
          onStepChange={onStepChange}
        />
        {isItemBodyAvailable && (
          <WorkflowBody
            sopItem={currentItem}
            cameraId={latestCameraWithSpeaker?.id || ''}
            cameraName={latestCameraWithSpeaker?.name || ''}
            logTalkdown={logTalkdown}
            performTalkdown={performTalkdown}
            isTalkdownInProcess={mutation.isLoading}
          />
        )}
        {showQuestionAndAction && (
          <>
            <div className="pt-4 font-bold">{currentItem.title}</div>
            <WorkflowActions
              sopItem={currentItem}
              locationAlarmId={locationAlarmId}
              stepNumber={currentStep}
            />
          </>
        )}
      </div>
      {sopWorkflow?.quickResolveActions &&
        sopWorkflow.quickResolveActions.length > 0 &&
        currentStep === 1 && (
          <div className="mt-auto grid grid-cols-2 items-center gap-2">
            {sopWorkflow.quickResolveActions.map((action, i) => (
              <Button
                key={i}
                onClick={onQuickResolveAction(action)}
                disabled={isResolveSubmitting}
              >
                {action}
              </Button>
            ))}
          </div>
        )}
    </>
  );
}
