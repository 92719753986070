import { CameraDetail } from '@hakimo-ui/hakimo/types';
import {
  useCanUpdateArmedStatus,
  useCanUpdateCamera,
} from '@hakimo-ui/hakimo/util';
import { useState } from 'react';
import { SettingFieldText } from './SettingFieldText';
import { SettingFieldToggle } from './SettingFieldToggle';
import { SettingSection } from './SettingSection';
import { UpdateArmedStatus } from './update-fields/update-armed-status/UpdateArmedStatus';
// import { useUpdateMotionDetection } from './update-fields/useUpdateMotionDetection';
import { convertToMinutes } from '../util';
import UpdateAutomatedTalkdownWindow from './update-fields/update-automated-talkdown-window/UpdateAutomatedTalkdownWindow';
import { useUpdatePlayback } from './update-fields/useUpdatePlayback';

interface Props {
  camera: CameraDetail;
}

export function SettingSectionActions(props: Props) {
  const { camera } = props;
  const [updateArmedStatus, setUpdateArmedStatus] = useState(false);
  const [updateAutomatedTalkdown, setUpdateAutomatedTalkdown] = useState(false);
  const canUpdateArmedStatus = useCanUpdateArmedStatus();
  const canUpdateCamera = useCanUpdateCamera();
  // const [motionDetectionEnabled, toggleMotionDetection] =
  //   useUpdateMotionDetection(
  //     camera.id,
  //     camera.isMotionDetectionEnabled ?? false
  //   );

  const [playbackEnabled, togglePlayback] = useUpdatePlayback(
    camera.id,
    camera.isPlaybackEnabled ?? false
  );
  const automatedTalkdownWindowTimezone = 'UTC'; // UTC is default for automated talkdown window

  const cameraStatus = camera.armStatus.endTimeText ? (
    camera.armStatus.endTimeText
  ) : camera.armStatus.isArmed ? (
    <span className="text-status-green">Armed</span>
  ) : (
    <span className="text-status-red">Disarmed</span>
  );

  const getAutomatedTalkdownValue = () => {
    if (
      !camera.automatedTalkdownEndTimeUTC &&
      !camera.automatedTalkdownStartTimeUTC
    ) {
      return <span>As per location's monitoring window</span>;
    }

    const startTime = camera.automatedTalkdownStartTimeUTC
      ? camera.automatedTalkdownStartTimeUTC
      : '';
    const endTime = camera.automatedTalkdownEndTimeUTC
      ? camera.automatedTalkdownEndTimeUTC
      : '';

    const showPlusOneDay =
      convertToMinutes(startTime) > convertToMinutes(endTime);

    const displayStartTime = startTime !== '' ? startTime : 'NOT SET';
    const displayEndTime = endTime !== '' ? endTime : 'NOT SET';

    return (
      <div className="flex flex-col">
        <span>
          Start Time: {displayStartTime}{' '}
          {camera.automatedTalkdownStartTimeUTC &&
            automatedTalkdownWindowTimezone}
        </span>
        <span>
          End Time: {displayEndTime}{' '}
          {camera.automatedTalkdownEndTimeUTC &&
            automatedTalkdownWindowTimezone}{' '}
          {showPlusOneDay && '(Next Day)'}
        </span>
      </div>
    );
  };

  return (
    <>
      <SettingSection name="Actions">
        {/* {canUpdateCamera ? (
          <SettingFieldToggle
            name="Motion Detection"
            value={motionDetectionEnabled}
            onToggle={toggleMotionDetection}
          />
        ) : null} */}
        {canUpdateCamera ? (
          <SettingFieldToggle
            name="Playback"
            value={playbackEnabled}
            onToggle={togglePlayback}
          />
        ) : null}
        {canUpdateArmedStatus ? (
          <SettingFieldText
            name="Status"
            value={cameraStatus}
            onClickUpdate={() => setUpdateArmedStatus(true)}
          />
        ) : null}

        {canUpdateCamera ? (
          <SettingFieldText
            name="Automated Talkdown"
            value={getAutomatedTalkdownValue()}
            onClickUpdate={() => setUpdateAutomatedTalkdown(true)}
          />
        ) : null}
      </SettingSection>
      {updateArmedStatus && (
        <UpdateArmedStatus
          cameraId={camera.id}
          isArmed={camera.armStatus.isArmed}
          onClose={() => setUpdateArmedStatus(false)}
        />
      )}
      {updateAutomatedTalkdown && (
        <UpdateAutomatedTalkdownWindow
          open={updateAutomatedTalkdown}
          camera={camera}
          timezone={automatedTalkdownWindowTimezone}
          onClose={() => setUpdateAutomatedTalkdown(false)}
        />
      )}
    </>
  );
}
