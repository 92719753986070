import { useDroppable } from '@dnd-kit/core';
import { Camera } from '@hakimo-ui/hakimo/types';
import { Button } from '@hakimo-ui/shared/ui-base';
import { MapPinIcon, XCircleIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';
import CameraSelect from '../CameraSelect';

interface Props {
  allCameras: Camera[];
  index: number;
  onRemoveCamera: () => void;
  selectedCamera: Camera | null;
  onCameraChange: (cam: Camera) => void;
  isClosedHidden?: boolean;
  children: React.ReactNode;
}

export function MultiCamItemWrapper(props: Props) {
  const {
    allCameras,
    onRemoveCamera,
    isClosedHidden,
    selectedCamera,
    onCameraChange,
    index,
    children,
  } = props;

  const { isOver, setNodeRef } = useDroppable({
    id: `droppable-${index}`,
    data: { index },
  });

  return (
    <div
      ref={setNodeRef}
      className={clsx(
        'border-onlight-line-2 dark:border-ondark-line-2 flex h-full min-h-[20rem] border',
        selectedCamera
          ? 'flex-col'
          : 'relative flex-col items-center justify-center gap-2',
        isOver
          ? 'bg-onlight-bg-2 dark:bg-ondark-bg-3/50'
          : 'bg-onlight-bg-1 dark:bg-ondark-bg-2'
      )}
    >
      {!selectedCamera && (
        <div className="text-onlight-text-2 dark:text-ondark-text-2 text-sm">
          Drag and drop a camera from the list or
        </div>
      )}
      <div
        className={clsx(
          'flex justify-between p-1',
          selectedCamera && 'border-b-ondark-text-2 border-b'
        )}
      >
        <CameraSelect
          cameras={allCameras}
          onChange={onCameraChange}
          selectedCamera={selectedCamera}
        />
        {selectedCamera && (
          <div className="flex gap-4">
            <div className="dark:text-dark-text mr-1 flex items-center gap-2 text-xs">
              <span title={selectedCamera.location?.name || '-'}>
                <MapPinIcon
                  xlinkTitle=""
                  className="h-5 w-5"
                  aria-hidden="true"
                />
              </span>
              <span>{selectedCamera.location?.name || '-'}</span>
            </div>
          </div>
        )}
        {!isClosedHidden && (
          <div className={clsx(!selectedCamera && 'absolute top-0 right-0')}>
            <Button title="Remove" variant="icon" onClick={onRemoveCamera}>
              <XCircleIcon className="h-6 w-6" aria-hidden="true" />
            </Button>
          </div>
        )}
      </div>
      {selectedCamera && children}
    </div>
  );
}

export default MultiCamItemWrapper;
