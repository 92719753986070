import { useUpsertAlarmNotificationPreference } from '@hakimo-ui/hakimo/data-access';
import { AlarmNotification } from '@hakimo-ui/hakimo/types';
import { toast } from '@hakimo-ui/hakimo/util';
import { Alert, Button, Modal } from '@hakimo-ui/shared/ui-base';
import { useState } from 'react';
import {
  getPayloadForAlarmNotification,
  validateUpsertAlarmNotification,
} from '../utils';

interface Props {
  item: AlarmNotification;
  onCancelEdit: () => void;
}

export function AddEditAlarmNotificationPreference(props: Props) {
  const { item, onCancelEdit } = props;
  const [showModal, setShowModal] = useState(false);
  const onCancel = () => {
    setShowModal(false);
  };
  const onError = () => {
    toast('Please select alarm types and notification sound', {
      type: 'error',
    });
  };
  const onSuccess = () => {
    toast('Alarm notification configuration added/updated successfully.', {
      type: 'success',
    });
    onCancel();
    onCancelEdit();
  };
  const onSave = () => {
    const isValid = validateUpsertAlarmNotification(item);
    if (!isValid) {
      onError();
    } else {
      setShowModal(true);
    }
  };
  const mutation = useUpsertAlarmNotificationPreference(onSuccess);
  const onConfirm = () => {
    mutation.mutate(getPayloadForAlarmNotification(item));
  };
  const actions = (
    <>
      <Button
        variant="primary"
        onClick={onConfirm}
        loading={mutation.isLoading}
      >
        Confirm
      </Button>
      <Button variant="outline" onClick={onCancel}>
        Go Back
      </Button>
    </>
  );
  return (
    <div>
      <Button variant="primary" onClick={onSave}>
        Save
      </Button>
      <Modal
        open={showModal}
        onClose={onCancel}
        footer={actions}
        title="Add/Update Alarm Notification Configuration"
      >
        <div className="max-w-3xl p-8">
          {mutation.isError ? (
            <div className="py-2">
              <Alert type="error">{mutation.error?.message}</Alert>
            </div>
          ) : null}
          <div className="space-y-4">
            <p>
              Are you sure you want to add/update the alarm notification with
              below configuration?
            </p>
            <div className="grid grid-cols-2 py-3 text-left font-semibold text-gray-700 dark:text-gray-400">
              <span>Notification Sound</span>
              <span>Alarm Types</span>
            </div>
            <div className="grid grid-cols-2 text-sm">
              <span>{item.notificationSound}</span>
              <div>
                {item.alarmTypes.map((alarmType, index) => (
                  <div key={index}>
                    <span>{alarmType}</span>
                    <br />{' '}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
export default AddEditAlarmNotificationPreference;
