import * as Sentry from '@sentry/react';
import { createRef, useCallback, useEffect, useState } from 'react';
import { DEFAULT_AUDIO_NOTIF_FILE } from '../constants';
interface Props {
  enabled: boolean;
  audioFileName?: string;
}
export function NotificationSound(props: Props) {
  const { enabled, audioFileName = DEFAULT_AUDIO_NOTIF_FILE } = props;
  const [key, setKey] = useState(1);
  const audioRef = createRef<HTMLAudioElement>();

  /* As per browser policy, audio cant be autoplayed initially without user's interaction with the document
 Attaching and detaching top level click event handler which rerenders the audio component.
  */
  const rerenderAudioTag = useCallback(() => {
    setKey((prev) => prev + 1);
    window.removeEventListener('click', rerenderAudioTag);
  }, []);

  useEffect(() => {
    if (audioRef.current !== null) {
      audioRef.current.addEventListener('canplaythrough', () => {
        // the audio is now playable; play it if permissions allow
        if (enabled) {
          audioRef.current?.play().catch((e) => {
            window.addEventListener('click', rerenderAudioTag);
            Sentry.captureMessage(e);
          });
        }
      });
      audioRef.current.addEventListener('ended', () => {
        // the audio is now playable; play it if permissions allow
        if (enabled) {
          audioRef.current?.play().catch((e) => {
            Sentry.captureMessage(e);
          });
        }
      });
    }
  }, [audioRef, enabled, rerenderAudioTag]);
  return (
    <audio
      key={key}
      ref={audioRef}
      src={`assets/notif-sounds/${audioFileName}.mp3`}
      autoPlay
    ></audio>
  );
}

export default NotificationSound;
