import { RadioGroup } from '@headlessui/react';
import clsx from 'clsx';
export interface StackedCardOption {
  id: string;
  name: string;
  description?: string | JSX.Element;
  additional?: string | JSX.Element;
}
interface Props {
  label: string;
  options: StackedCardOption[];
  selected?: StackedCardOption;
  onChange: (option: StackedCardOption) => void;
}
const compare = (a: StackedCardOption, b: StackedCardOption) => {
  if (!a || !b) return false;
  return a?.id === b?.id;
};
export function StackedCards(props: Props) {
  const { options, selected, onChange, label } = props;
  return (
    <RadioGroup value={selected} onChange={onChange} by={compare}>
      <RadioGroup.Label className="sr-only"> {label} </RadioGroup.Label>
      <div className="mt-4 space-y-4">
        {options.map((option) => (
          <RadioGroup.Option
            key={option.name}
            value={option}
            className={({ checked, active }) =>
              clsx(
                checked
                  ? 'dark:border-dark-border-bg border-transparent'
                  : 'border-onlight-bg-3 dark:border-dark-border-surface',
                active && 'border-onlight-primary ring-onlight-primary ring-2',
                'hover:dark:bg-dark-hover-bg hover:bg-onlight-bg-2 bg-onlight-bg-1 dark:bg-ondark-bg-2 relative block cursor-pointer rounded-lg border px-6 py-4 shadow-sm focus:outline-none sm:flex sm:justify-between'
              )
            }
          >
            {({ active, checked }) => (
              <>
                <span className="flex items-center">
                  <span className="flex flex-col text-sm">
                    <RadioGroup.Label
                      as="span"
                      className="text-onlight-text-1 dark:text-ondark-text-1 font-medium"
                    >
                      {option.name}
                    </RadioGroup.Label>
                    {option.description && (
                      <RadioGroup.Description
                        as="span"
                        className="text-onlight-text-2 dark:text-ondark-text-2"
                      >
                        <span className="block sm:inline">
                          {option.description}
                        </span>
                      </RadioGroup.Description>
                    )}
                  </span>
                </span>
                {option.additional && (
                  <RadioGroup.Description
                    as="span"
                    className="mt-2 flex text-sm sm:mt-0 sm:ml-4 sm:flex-col sm:text-right"
                  >
                    <span className="text-onlight-text-1 dark:text-ondark-text-1 font-medium">
                      {option.additional}
                    </span>
                  </RadioGroup.Description>
                )}
                <span
                  className={clsx(
                    active ? 'border' : 'border-2',
                    checked ? 'border-onlight-primary' : 'border-transparent',
                    'pointer-events-none absolute -inset-px rounded-lg'
                  )}
                  aria-hidden="true"
                />
              </>
            )}
          </RadioGroup.Option>
        ))}
      </div>
    </RadioGroup>
  );
}

export default StackedCards;
