import { CameraAddPayload } from '@hakimo-ui/hakimo/types';
import useAuthenticatedMutation from '../shared/useAuthenticatedMutation';

export function useAddCamera(onSuccess?: () => void) {
  const url = `/v2/orm/camera`;

  const request = new Request(url, {
    method: 'POST',
  });

  return useAuthenticatedMutation<null, CameraAddPayload | null>(request, {
    onSuccessInvalidationKeys: [['cameras']],
    onSuccess,
  });
}

export default useAddCamera;
