import { trackEvent } from '@hakimo-ui/hakimo/util';
import { useAtomValue } from 'jotai';
import { selectAtom } from 'jotai/utils';
import { useCallback, useEffect, useRef } from 'react';
import { alarmsRenderedTimeAtom } from '../shared/state';

export function useTrackAlarmEvents(alarmId: string) {
  const alarmViewTimestamp = useRef(new Date().getTime());

  const selector = useCallback(
    (v: Record<string, number>) => v[alarmId],
    [alarmId]
  );

  const alarmRenderedTime = useAtomValue(
    selectAtom(alarmsRenderedTimeAtom, selector)
  );

  useEffect(() => {
    if (alarmRenderedTime === undefined) {
      return;
    }

    trackEvent('view_alarm_details', {
      alarm_id: alarmId,
      time_to_view: alarmViewTimestamp.current - alarmRenderedTime,
    });
  }, [alarmId, alarmRenderedTime, alarmViewTimestamp]);

  function onTrackResolve() {
    trackEvent('resolve_alarm', {
      alarm_id: alarmId,
      time_to_resolve: new Date().getTime() - alarmViewTimestamp.current,
    });
  }

  function onTrackTalkdown(cameraId: string) {
    trackEvent('submit_audio_recording', {
      camera_id: cameraId,
      alarm_id: alarmId,
      time_to_talkdown: new Date().getTime() - alarmViewTimestamp.current,
    });
  }

  return {
    onTrackResolve,
    onTrackTalkdown,
  };
}
