import { useAlarmUpdates } from '@hakimo-ui/hakimo/data-access';
import { AlarmUpdatesDTO } from '@hakimo-ui/hakimo/types';
import { ReactElement } from 'react';
import { UseQueryResult } from 'react-query';
import { getAlarmUpdatesSearchParams } from '../../shared/components/alarm-updates/util';

const REFETCH_INTERVAL = 60000;

export interface Props {
  alarmId: string;
  children: (
    queryResult: UseQueryResult<AlarmUpdatesDTO, Error>
  ) => ReactElement;
}

export function RegularAlarmUpdates(props: Props) {
  const { alarmId, children } = props;

  const queryResult = useAlarmUpdates(
    alarmId,
    getAlarmUpdatesSearchParams(),
    REFETCH_INTERVAL
  );

  return children(queryResult);
}

export default RegularAlarmUpdates;
