import { DoorGroupDTO } from '@hakimo-ui/hakimo/types';
import useAuthenticatedRequest from '../shared/useAuthenticatedRequest';

export function useDoorGroups(searchParams: string) {
  const url = `/v2/orm/door_groups?${searchParams}`;
  const request = new Request(url);

  return useAuthenticatedRequest<DoorGroupDTO>(request, {
    queryKey: ['door_groups', { searchParams }],
    responseModifier: async (response) => {
      const respJson = await response.json();
      return respJson.payload;
    },
  });
}

export default useDoorGroups;
