import { ReactElement } from 'react';
import { SettingField } from './SettingField';
import { SettingFieldText } from './SettingFieldText';
import { SettingFieldToggle } from './SettingFieldToggle';

type SectionChild =
  | ReactElement<typeof SettingFieldText>
  | ReactElement<typeof SettingFieldToggle>
  | ReactElement<typeof SettingField>;

interface Props {
  name: string;
  infoText?: string;
  children: SectionChild | (SectionChild | null)[];
}

export function SettingSection(props: Props) {
  const { name, infoText, children } = props;

  return (
    <div>
      <h2 className="text-base font-semibold leading-7">{name}</h2>
      {infoText && (
        <p className="text-onlight-text-2 dark:text-ondark-text-2 mt-1 text-sm leading-6">
          {infoText}
        </p>
      )}
      <dl className="dark:divide-ondark-line-1 divide-onlight-line-1 border-onlight-line-2 dark:border-ondark-line-2 mt-6 space-y-6 divide-y border-t text-sm leading-6">
        {children}
      </dl>
    </div>
  );
}
