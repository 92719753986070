import { Tooltip } from '@hakimo-ui/shared/ui-base';
import { Disclosure } from '@headlessui/react';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import { MinusIcon, PlusIcon } from '@heroicons/react/24/solid';
import clsx from 'clsx';
import { ReactElement } from 'react';
interface Props {
  title: string;
  content: ReactElement;
  hasFilterApplied?: boolean;
  negativeApplied?: boolean;
  info?: string;
}

export function FilterSection(props: Props) {
  const {
    title,
    content,
    hasFilterApplied = false,
    negativeApplied = false,
    info,
  } = props;
  return (
    <Disclosure as="div" className="py-2">
      {({ open }) => (
        <>
          <Disclosure.Button className="dark:bg-dark-bg dark:text-dark-text focus-visible:ring-primary-500 dark:focus-visible:ring-offset-dark-bg flex w-full items-center justify-between rounded py-3 text-gray-800 hover:text-gray-600 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 hover:dark:text-gray-400">
            <span className="flex items-center gap-2 text-sm">
              {title}
              {hasFilterApplied && (
                <span
                  className={clsx(
                    'h-2 w-2 rounded-full',
                    negativeApplied ? 'bg-red-500' : 'bg-primary-500'
                  )}
                  aria-hidden="true"
                ></span>
              )}
              {info && (
                <Tooltip colorModifier="info" text={info} size="medium">
                  <InformationCircleIcon className="h-5 w-5" />
                </Tooltip>
              )}
            </span>
            {open ? (
              <MinusIcon className="w-4" />
            ) : (
              <PlusIcon className="w-4" />
            )}
          </Disclosure.Button>
          <Disclosure.Panel className="pt-4 pb-6" unmount={false}>
            {content}
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}

export default FilterSection;
