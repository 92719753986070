import { DoorFilters } from '@hakimo-ui/hakimo/types';

export interface State {
  doorListFilters: DoorFilters;
  doorListPage: number;
  doorPageSize: number;
}

export const initialState: State = {
  doorListFilters: {
    labelStatus: {
      showLabeled: true,
      showUnlabeled: true,
    },
    location: { values: [], negative: false },
    door: { values: [], negative: false },
  },
  doorListPage: 1,
  doorPageSize: 20,
};
