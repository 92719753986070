import { MouseEvent, useRef, useState } from 'react';
import { MIN_LEFT_OFFSET, MIN_RIGHT_OFFSET, formatTime } from './util';

interface Props {
  currentTime?: number;
  duration?: number;
  onSeekVideo: (time: number) => void;
}

export function VideoTimeline(props: Props) {
  const { currentTime, duration, onSeekVideo } = props;
  const timelineRef = useRef<HTMLDivElement>(null);
  const [tooltipOffset, setTooltipOffset] = useState(0);
  const [tooltipValue, setTooltipValue] = useState('');

  let progress = 0;

  if (currentTime && duration) {
    progress = (currentTime / duration) * 100;
  }

  const onMouseMove = (e: MouseEvent<HTMLDivElement>) => {
    const timelineWidth = timelineRef.current?.clientWidth;
    if (duration && timelineWidth) {
      let offsetX = e.nativeEvent.offsetX - MIN_LEFT_OFFSET;
      offsetX =
        offsetX < MIN_LEFT_OFFSET
          ? MIN_LEFT_OFFSET
          : offsetX > timelineWidth - MIN_RIGHT_OFFSET
          ? timelineWidth - MIN_RIGHT_OFFSET
          : offsetX;

      setTooltipOffset(offsetX);
      const tooltipTime = Math.floor((duration / timelineWidth) * offsetX);
      setTooltipValue(formatTime(tooltipTime));
    }
  };

  const onMouseLeave = () => setTooltipValue('');

  const onClickTimeline = (e: MouseEvent<HTMLDivElement>) => {
    const offsetX = e.nativeEvent.offsetX - MIN_LEFT_OFFSET;
    const timelineWidth = timelineRef.current?.clientWidth;
    if (duration && timelineWidth) {
      const tooltipTime = (duration / timelineWidth) * offsetX;
      onSeekVideo(tooltipTime);
    }
  };

  return (
    <div
      className="video-timeline h-2 w-full cursor-pointer"
      onMouseMove={onMouseMove}
      onMouseLeave={onMouseLeave}
      ref={timelineRef}
      onClick={onClickTimeline}
    >
      <div className="progress-area bg-ondark-bg-3 relative h-1">
        <span
          className="text-onlight-bg-2 pointer-events-none absolute -top-6 text-sm"
          style={{ left: `${tooltipOffset}px` }}
        >
          {tooltipValue}
        </span>
        <div
          className="bg-ondark-text-1 h-full transition-all duration-700 ease-in-out"
          style={{ width: `${progress}%` }}
        ></div>
      </div>
    </div>
  );
}
