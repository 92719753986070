import { Selectable } from '@hakimo-ui/shared/ui-base';
import { FilterValues, StatusFilterValue } from './alarms/filters';
import { Location } from './location';
import { AlarmTalkdownState, TwilioLogType } from './location-alarms';

export interface CreateEscalationDTO {
  location_id: string;
  camera_id: string;
}

export interface CreateEscalationResponse {
  escalation_id: string;
}

export interface UpdateEscalationRequest {
  escalation_id: string;
  status?: string;
  resolution_comment?: string;
  twilio_log_type?: TwilioLogType;
  twilio_ssid?: string;
  twilio_calling_to_name?: string;
  update_type?: string;
}

export const escalationStatuses = [
  'Pending',
  'Resolved',
  'In Progress',
] as const;

export type EscalationStatus = typeof escalationStatuses[number];

export interface EscalationMedia {
  cameraId: string;
  createdAtUTC: string;
  mediaUrl: string;
}

export interface ScanEscalation {
  id: string;
  cameraId: string;
  resolutionComment: string;
  status: EscalationStatus;
  location: Location;
  createdByUserId: string;
  resolvedByUserId: string;
  createdAt: string;
  updatedAt: string;
  media: EscalationMedia[];
}

export interface ScanEscalationDTO {
  items: ScanEscalation[];
  total: number;
}

export interface EscalationEventsDTO {
  items: EscalationEvent[];
  talkdowns?: AlarmTalkdownState[];
}

export enum EscalationEventStatus {
  PENDING = 'Pending',
  IN_PROGRESS = 'In Progress',
  RESOLVED = 'Resolved',
}

export enum EscalationUpdateType {
  MANUAL_TALKDOWN = 'Manual audio talkdown',
  CALL_SMS_LOGS = 'Call SMS logs',
  CHANGE_STATUS = 'Change status',
}

export interface EscalationEvent {
  id: number;
  update_status?: EscalationEventStatus;
  update_text: string;
  update_time: string;
  update_type: EscalationUpdateType;
  user?: Selectable;
  audio_url?: string;
  twilio_log_type?: TwilioLogType;
  twilio_ssid?: string;
  twilio_calling_to_name?: string;
  raw_alarm_id?: string;
}

export interface EscalationListFilterValues
  extends Pick<FilterValues, 'status' | 'location' | 'camera'> {
  status: StatusFilterValue<EscalationStatus>;
}
