import { useUpdateLocationAlarm } from '@hakimo-ui/hakimo/data-access';
import { TwilioCall } from '@hakimo-ui/hakimo/feature-shared';
import { TwilioLogType } from '@hakimo-ui/hakimo/types';
import { Call } from '@twilio/voice-sdk';

interface Props {
  toName: string;
  toNumber: string;
  onCloseCb: () => void;
  locationAlarmId?: string;
  locationTenantId?: string;
}

export function DraggableCallView(props: Props) {
  const { toName, toNumber, onCloseCb, locationAlarmId, locationTenantId } =
    props;

  const mutation = useUpdateLocationAlarm(locationAlarmId || '');

  const onCallInitiated = (c: Call) => {
    if (locationAlarmId && c.parameters['CallSid']) {
      mutation.mutate({
        twilio_log_type: TwilioLogType.TWILIO_CALLS,
        twilio_ssid: c.parameters['CallSid'],
        twilio_calling_to_name: toName,
      });
    }
  };

  return (
    <TwilioCall
      toName={toName}
      toNumber={toNumber}
      onCloseCb={onCloseCb}
      onCallInitiated={onCallInitiated}
      locationTenantId={locationTenantId}
    />
  );
}

export default DraggableCallView;
