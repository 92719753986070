import { useUpdateAutomatedTalkdownWindow } from '@hakimo-ui/hakimo/data-access';
import { CameraDetail } from '@hakimo-ui/hakimo/types';
import { eventTracker, toast } from '@hakimo-ui/hakimo/util';
import { Alert, Button, InputField, Modal } from '@hakimo-ui/shared/ui-base';
import { useState } from 'react';
import { convertToMinutes } from '../../../util';
import ConfirmationPrompt from './ConfirmationPrompt';

interface Props {
  open: boolean;
  onClose: () => void;
  timezone: string;
  camera: CameraDetail;
}
export function UpdateAutomatedTalkdownWindow(props: Props) {
  const { onClose, open, camera, timezone } = props;
  const [confirmationPrompt, setConfirmationPrompt] = useState(false);
  const [startTime, setStartTime] = useState(
    camera.automatedTalkdownStartTimeUTC || ''
  );
  const [endTime, setEndTime] = useState(
    camera.automatedTalkdownEndTimeUTC || ''
  );
  const onStartChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setStartTime(e.target.value);
  };
  const onEndChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEndTime(e.target.value);
  };
  const showPlusOneDay =
    convertToMinutes(startTime) > convertToMinutes(endTime);
  const mutation = useUpdateAutomatedTalkdownWindow(camera.id, () => {
    toast(`Camera ${camera.name} automated talkdown window updated!`, {
      type: 'success',
    });
    onClose();
  });

  const onSubmit = () => {
    if (startTime.trim() !== '' && endTime.trim() !== '') {
      mutation.mutate({
        automatedTalkdownEndTimeUTC: endTime,
        automatedTalkdownStartTimeUTC: startTime,
      });
    } else {
      toast(`Please provide valid start and end times`, {
        type: 'error',
      });
    }
  };
  const onCancel = () => {
    if (confirmationPrompt) {
      setConfirmationPrompt(false);
    } else {
      onClose();
    }
  };

  const actions = (
    <>
      <Button onClick={onCancel}>
        {confirmationPrompt ? 'Back' : 'Cancel'}
      </Button>
      <span>
        {confirmationPrompt ? (
          <Button
            variant="primary"
            onClick={onSubmit}
            loading={mutation.isLoading}
            onSideEffect={eventTracker('update_automated_talkdown_time')}
          >
            Submit
          </Button>
        ) : (
          <Button variant="primary" onClick={() => setConfirmationPrompt(true)}>
            Save
          </Button>
        )}
      </span>
    </>
  );
  return (
    <Modal
      title={`Automated Talkdown`}
      open={open}
      onClose={onClose}
      footer={actions}
    >
      <div className="mx-4 flex max-w-[28rem] flex-col px-4 py-4">
        <Alert type="info">
          All times are referenced in {timezone} timezone
        </Alert>
        <div className="my-4 flex flex-row gap-4 py-4">
          {confirmationPrompt ? (
            <ConfirmationPrompt
              startTime={startTime}
              endTime={endTime}
              plusOneDay={showPlusOneDay}
              timezone={timezone}
            ></ConfirmationPrompt>
          ) : (
            <>
              <div className="w-32 ">
                <InputField
                  label="Start time"
                  onChange={onStartChange}
                  type="time"
                  value={startTime}
                  required
                />
              </div>
              <div className="relative w-32">
                {showPlusOneDay && (
                  <span className="text-onlight-text-3 dark:text-ondark-text-2 absolute top-1 right-2 text-xs">
                    +1 Day
                  </span>
                )}
                <InputField
                  label="End time"
                  onChange={onEndChange}
                  type="time"
                  value={endTime}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </Modal>
  );
}
export default UpdateAutomatedTalkdownWindow;
