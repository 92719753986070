import produce from 'immer';
import { useSetAtom } from 'jotai';
import { useEffect } from 'react';
import { locationAlarmsRenderedTimeAtom } from '../state';

export function useUpdateRenderedTime(alarmId: string) {
  const setAlarmsRenderedTime = useSetAtom(locationAlarmsRenderedTimeAtom);

  useEffect(() => {
    setAlarmsRenderedTime((state) => ({
      ...state,
      [alarmId]: new Date().getTime(),
    }));

    return () => {
      setAlarmsRenderedTime((state) => {
        return produce(state, (draft) => {
          delete draft[alarmId];
        });
      });
    };
  }, [alarmId, setAlarmsRenderedTime]);
}
