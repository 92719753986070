import {
  usePlaybackMarkers,
  usePlaybackVideo,
} from '@hakimo-ui/hakimo/data-access';
import {
  HLSVideoPlayer,
  PlaybackControls,
} from '@hakimo-ui/hakimo/feature-shared';
import {
  PlaybackMotionMarkers,
  VideoControlsList,
} from '@hakimo-ui/hakimo/types';
import { Alert } from '@hakimo-ui/shared/ui-base';
import * as Sentry from '@sentry/react';
import Hls from 'hls.js';
import { useCallback, useState } from 'react';
import { DEFAULT_PLAYBACK_PERIOD } from '../../constants';

interface Props {
  cameraId: string;
  cameraTimeZone?: string;
}

export function PlaybackVideo(props: Props) {
  const { cameraId, cameraTimeZone } = props;
  const [playbackTime, setPlaybackTime] = useState<Date>(new Date());
  const [hasError, setHasError] = useState(false);
  const [videoUrl, setVideoUrl] = useState('');
  const [playbackMotionMarkers, setPlaybackMotionMarkers] =
    useState<PlaybackMotionMarkers>([]);
  const [playbackPeriod] = useState(() => {
    const endDate = new Date();
    const startDate = new Date();
    startDate.setDate(startDate.getDate() - DEFAULT_PLAYBACK_PERIOD);
    return {
      start: startDate.getTime(),
      end: endDate.getTime(),
    };
  });
  const controlsList: VideoControlsList[] = ['noVideoTimeline'];

  const [cameraHlsAndRef, setCameraHlsAndRef] = useState<{
    videoRef: React.RefObject<HTMLVideoElement>;
    hls?: Hls;
  }>();

  const onPlaybackVideoData = (url: string) => {
    setVideoUrl(url);
  };

  const onPlaybackMarkerData = (markers: PlaybackMotionMarkers) =>
    setPlaybackMotionMarkers(markers);

  usePlaybackVideo(
    cameraId,
    Math.floor(playbackTime.getTime() / 1000),
    onPlaybackVideoData
  );

  usePlaybackMarkers(
    cameraId,
    playbackPeriod.start,
    playbackPeriod.end,
    onPlaybackMarkerData
  );

  const onHLSError = useCallback((e: Error) => {
    setHasError(true);
    Sentry.captureMessage(String(e));
  }, []);

  const requestNewVideoUrl = useCallback(
    (marker: Date) => setPlaybackTime(marker),
    []
  );

  const onRetry = () => setHasError(false);

  const onInit = useCallback(
    (hls: Hls, videoRef: React.RefObject<HTMLVideoElement>) =>
      setCameraHlsAndRef({ hls, videoRef }),
    []
  );

  return (
    <>
      {hasError && (
        <div className="pb-1">
          <Alert>
            Unable to load current playback. Try selecting an alternative date /
            time.
          </Alert>
        </div>
      )}
      <>
        <HLSVideoPlayer
          onInit={onInit}
          videoPath={videoUrl}
          lockAspectRatio={true}
          onError={onHLSError}
          timeZone={cameraTimeZone}
          authRequired
          isPlayback
          controlsList={controlsList}
        />
        {cameraHlsAndRef && (
          <PlaybackControls
            timeZone={cameraTimeZone || 'UTC'}
            cameraHlsAndRef={cameraHlsAndRef}
            requestNewVideoUrl={requestNewVideoUrl}
            playbackPeriod={DEFAULT_PLAYBACK_PERIOD}
            onRetry={onRetry}
            playbackMotionMarkers={playbackMotionMarkers}
          />
        )}
      </>
    </>
  );
}

export default PlaybackVideo;
