import { useCameraDetails } from '@hakimo-ui/hakimo/data-access';
import { LoadingIndicator } from '@hakimo-ui/hakimo/ui-elements';
import { Alert } from '@hakimo-ui/shared/ui-base';
import { CameraDetailsView } from './CameraDetailsView';

interface Props {
  camId: string;
  camType?: string;
  onDeletedCamera: () => void;
}

export function CameraDetails(props: Props) {
  const { camId, camType, onDeletedCamera } = props;

  const { data: camera, isFetching, isError, error } = useCameraDetails(camId);

  return (
    <>
      {isFetching && !camera && (
        <div className="flex h-full w-full items-center justify-center">
          <LoadingIndicator />
        </div>
      )}
      {camera && (
        <CameraDetailsView
          camId={camId}
          camType={camType}
          camera={camera}
          onDeletedCamera={onDeletedCamera}
        />
      )}
      {isError && <Alert type="error">{error.message}</Alert>}
    </>
  );
}

export default CameraDetails;
