import React, { MouseEvent as ReactMouseEvent, useRef } from 'react';

interface Props {
  children: [React.ReactNode, React.ReactNode];
  initialLeftWidth?: number;
}

export function DraggableSplitView(props: Props) {
  const { children, initialLeftWidth = 300 } = props;
  const leftContainerRef = useRef<HTMLDivElement>(null);

  const handleMouseDown = (
    mde: ReactMouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    if (!leftContainerRef.current) {
      return;
    }

    const leftContainerElement = leftContainerRef.current;
    const xMouseDown = mde.clientX;
    const { width: widthMouseDown } =
      leftContainerElement.getBoundingClientRect();

    const handleMouseMove = (e: MouseEvent) => {
      const diffX = e.clientX - xMouseDown;
      leftContainerElement.style.width = `${widthMouseDown + diffX}px`;
    };

    const handleMouseUp = () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };

    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
  };

  const [leftChild, rightChild] = children;

  return (
    <div className="flex h-full w-full overflow-auto">
      <div
        ref={leftContainerRef}
        className="overflow-auto"
        style={{ width: `${initialLeftWidth}px` }}
      >
        {leftChild}
      </div>
      <div
        className="bg-onlight-line-2 dark:bg-ondark-line-2 w-0.5 cursor-ew-resize"
        onMouseDown={handleMouseDown}
      ></div>
      <div className="flex-1 overflow-auto">{rightChild}</div>
    </div>
  );
}

export default DraggableSplitView;
