import { useCallback, useEffect, useRef, useState } from 'react';

export enum WebSocketStatus {
  CONNECTING = 'CONNECTING',
  OPEN = 'OPEN',
  CLOSED = 'CLOSED',
  ERROR = 'ERROR',
}

export interface UseWebSocketOptions<TMessage> {
  url: string;
  onMessage?: (event: MessageEvent<TMessage>) => void;
  onOpen?: (event: Event) => void;
  onClose?: (event: CloseEvent) => void;
  onError?: (event: Event) => void;
  getAccessToken?: () => Promise<string>;
}

export interface UseWebSocketResult<TOutboundMessage> {
  socketStatus: WebSocketStatus;
  sendMessage: (data: TOutboundMessage) => void;
  closeConnection: () => void;
}

export function useWebSocket<TMessage, TOutboundMessage>({
  url,
  onMessage,
  onOpen,
  onClose,
  onError,
  getAccessToken,
}: UseWebSocketOptions<TMessage>): UseWebSocketResult<TOutboundMessage> {
  const [socketStatus, setSocketStatus] = useState<WebSocketStatus>(
    WebSocketStatus.CONNECTING
  );
  const socketRef = useRef<WebSocket | null>(null);
  const isManualClose = useRef<boolean>(false);

  const connect = useCallback(async () => {
    if (socketRef.current) {
      return;
    }

    const accessToken = getAccessToken ? await getAccessToken() : '';
    socketRef.current = new WebSocket(url, [accessToken]);
    setSocketStatus(WebSocketStatus.CONNECTING);

    socketRef.current.onopen = (event: Event) => {
      setSocketStatus(WebSocketStatus.OPEN);
      if (onOpen) onOpen(event);
    };

    socketRef.current.onmessage = (event: MessageEvent<TMessage>) => {
      if (onMessage) onMessage(event);
    };

    socketRef.current.onclose = (event: CloseEvent) => {
      setSocketStatus(WebSocketStatus.CLOSED);
      if (onClose) onClose(event);
    };

    socketRef.current.onerror = (event: Event) => {
      setSocketStatus(WebSocketStatus.ERROR);
      if (onError) onError(event);
    };
  }, [getAccessToken, url, onOpen, onMessage, onClose, onError]);

  useEffect(() => {
    connect();
    return () => {
      if (socketRef.current) {
        socketRef.current.close();
      }
    };
  }, [connect]);

  const sendMessage = useCallback((data: TOutboundMessage) => {
    if (socketRef.current && socketRef.current.readyState === WebSocket.OPEN) {
      socketRef.current.send(JSON.stringify(data));
    } else {
      // eslint-disable-next-line no-console
      console.error('WebSocket is not open. Unable to send message.');
    }
  }, []);

  const closeConnection = useCallback(() => {
    isManualClose.current = true;
    if (socketRef.current) {
      socketRef.current.close();
    }
  }, []);

  return {
    socketStatus,
    sendMessage,
    closeConnection,
  };
}

export default useWebSocket;
