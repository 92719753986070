import { SystemNotificationPreference } from '@hakimo-ui/hakimo/types';
import {
  isWebNotificationSupported,
  pageTitleAtom,
  systemNotifyAtom,
} from '@hakimo-ui/hakimo/util';
import { Button } from '@hakimo-ui/shared/ui-base';
import { Bars3BottomLeftIcon } from '@heroicons/react/24/solid';
import { useAtomValue, useSetAtom } from 'jotai';
import { useEffect } from 'react';
import HakimoNavLink from '../hakimo/HakimoNavLink';
import UserMenu from './UserMenu';

interface Props {
  expandSidebar: () => void;
}

export function RootHeader({ expandSidebar }: Props) {
  const pageTitleNode = useAtomValue(pageTitleAtom);
  const setSystemNotifyPreference = useSetAtom(systemNotifyAtom);
  useEffect(() => {
    // handle scenario where user has manually denied notification permission
    if (isWebNotificationSupported()) {
      global.Notification.permission !== 'granted' &&
        setSystemNotifyPreference(SystemNotificationPreference.OFF);
    }
  }, [setSystemNotifyPreference]);

  return (
    <div className="dark:bg-ondark-bg-1 dark:text-dark-text bg-onlight-bg-2 border-onlight-line-1 dark:border-ondark-line-1 sticky top-0 z-10 flex h-20 items-center border-b px-4 sm:px-6 md:px-8">
      <div className="mr-4">
        <Button type="button" variant="icon" onClick={expandSidebar}>
          <span className="sr-only">Open sidebar</span>
          <Bars3BottomLeftIcon
            className="h-6 w-6 rounded-full"
            aria-hidden="true"
          />
        </Button>
      </div>
      <div className="dark:text-dark-text flex flex-1 items-center justify-between lg:w-full lg:px-0">
        <span className="sm:max-w-xs lg:max-w-max">{pageTitleNode}</span>
        <span className="absolute left-1/2 -translate-x-1/2">
          <span className="hidden sm:block">
            <HakimoNavLink />
          </span>
        </span>
        <div className="flex-shrink-0 pr-4">
          <UserMenu />
        </div>
      </div>
    </div>
  );
}

export default RootHeader;
