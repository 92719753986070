import Checkbox from '../checkbox/Checkbox';
import { CheckboxItem } from '../types';

interface Props<T> {
  items: CheckboxItem<T>[];
  value: CheckboxItem<T>[];
  onChange: (value: CheckboxItem<T>[]) => void;
}

export function Checkboxes<T>(props: Props<T>) {
  const { items, value, onChange } = props;

  const onChangeItem = (item: CheckboxItem<T>, checked: boolean) => {
    const nextValue = value.filter((v) => v.id !== item.id);

    if (checked) {
      nextValue.push(item);
    }

    onChange(nextValue);
  };

  return (
    <>
      {items.map((item) => (
        <div className="mt-2" key={item.id}>
          <Checkbox
            label={item.label}
            checked={value.some((v) => v.id === item.id)}
            disabled={item.disabled}
            onChange={(checked) => onChangeItem(item, checked)}
          />
        </div>
      ))}
    </>
  );
}

export default Checkboxes;
