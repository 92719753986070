import { Toggle } from '@hakimo-ui/shared/ui-base';

interface ToggleSectionProps {
  value: boolean;
  title: string;
  onChange: (newVal: boolean) => void;
  isEditing: boolean;
}
export function ToggleSection(props: ToggleSectionProps) {
  const { value, title, onChange, isEditing } = props;
  const onChangeCb = isEditing ? onChange : () => null;
  return (
    <div className="mt-4 flex w-full gap-x-4 px-6">
      <div className="flex-none">
        <span className="text-onlight-text-2 dark:text-ondark-text-2 text-sm">
          {title}
        </span>
      </div>
      <div className="text-onlight-text-3 dark:text-ondark-text-3 mb-4 flex w-full flex-wrap gap-x-4 text-sm">
        <Toggle enabled={value} onChange={onChangeCb} />
      </div>
    </div>
  );
}
