import { useSendSMS } from '@hakimo-ui/hakimo/data-access';
import { eventTracker, toast } from '@hakimo-ui/hakimo/util';
import { Alert, Button, Modal, Textarea } from '@hakimo-ui/shared/ui-base';
import { ChatBubbleLeftIcon } from '@heroicons/react/24/outline';
import { useState } from 'react';

interface Props {
  toNumber: string;
  toName: string;
}

export function ContactSMS(props: Props) {
  const { toName, toNumber } = props;
  const [showSMSModal, setShowSMSModal] = useState(false);
  const [message, setMessage] = useState('');

  const onClick = () => setShowSMSModal(true);

  const onCloseCb = () => setShowSMSModal(false);

  const onChange = (val: string) => setMessage(val);

  const mutation = useSendSMS(() => {
    toast('Message sent successfully', { type: 'success' });
    onCloseCb();
  });

  const onSend = () => {
    mutation.mutate({ to_number: toNumber, message: message });
  };

  const actions = (
    <>
      <Button
        variant="primary"
        onClick={onSend}
        loading={mutation.isLoading}
        onSideEffect={eventTracker('sms_location_contact')}
      >
        Send
      </Button>
      <Button
        variant="outline"
        onClick={onCloseCb}
        disabled={mutation.isLoading}
      >
        Close
      </Button>
    </>
  );

  return (
    <>
      <Button onClick={onClick}>
        <ChatBubbleLeftIcon className="mr-2 h-4 w-4" />
        SMS
      </Button>
      {showSMSModal && (
        <Modal open={true} onClose={onCloseCb} title="Message" footer={actions}>
          <div className="w-[30rem] p-4">
            {mutation.isError && (
              <div className="px-8 pt-2">
                <Alert type="error">{mutation.error.message}</Alert>
              </div>
            )}
            <div className="mb-4">
              To: {toName} <span className="text-xs">{toNumber}</span>
            </div>
            <Textarea
              rows={3}
              value={message}
              onChange={onChange}
              placeholder="Write your message here..."
            />
          </div>
        </Modal>
      )}
    </>
  );
}

export default ContactSMS;
