import { DCP } from '@hakimo-ui/hakimo/types';
import useAuthenticatedRequest from '../shared/useAuthenticatedRequest';

export function useDCP(dcpId: string) {
  const url = `/v2/orm/dcp/${dcpId}`;
  const request = new Request(url);

  return useAuthenticatedRequest<DCP>(request, {
    queryKey: ['dcp', dcpId],
    responseModifier: async (response) => {
      const respJson = await response.json();
      return respJson.payload;
    },
  });
}

export default useDCP;
