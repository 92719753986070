import { PageLoadingIndicator } from '@hakimo-ui/hakimo/ui-elements';
import {
  currentTenantAtom,
  useUser,
  withAuthz,
  withErrorBoundary,
} from '@hakimo-ui/hakimo/util';
import { useAtom } from 'jotai';
import { useEffect } from 'react';
import AlarmList from './AlarmList';

function AlarmListRoot() {
  const [currentTenant, setCurrentTenant] = useAtom(currentTenantAtom);
  const user = useUser();

  useEffect(() => {
    if (!currentTenant) {
      setCurrentTenant(user.tenant);
    }
  }, [currentTenant, setCurrentTenant, user]);

  return currentTenant ? <AlarmList /> : <PageLoadingIndicator />;
}

export default withAuthz(withErrorBoundary(AlarmListRoot), ['alarm:view']);
