import { StatusType } from '@hakimo-ui/hakimo/types';
import { useCanUpdateLocationAlarmStatus } from '@hakimo-ui/hakimo/util';
import { Button, Label, LabelType } from '@hakimo-ui/shared/ui-base';

interface Props {
  statusType: StatusType;
  labelType: LabelType;
  onChange: (statusType: StatusType) => void;
}

function StatusAction(props: Props) {
  const { statusType, labelType, onChange } = props;
  const canUpdateStatus = useCanUpdateLocationAlarmStatus();

  return (
    <div className="flex items-center gap-4">
      <Label type={labelType}>{statusType}</Label>
      {statusType !== StatusType.RESOLVED && canUpdateStatus && (
        <Button
          title="Resolve"
          variant="success"
          onClick={() => onChange(StatusType.RESOLVED)}
        >
          Resolve
        </Button>
      )}
    </div>
  );
}

export default StatusAction;
