import { Route, Routes } from 'react-router-dom';
import LocationAlarmsList from './LocationAlarmsList';
import LocationAlarmDetailsRoot from './location-alarm-details/LocationAlarmDetailsRoot';

export function LocationAlarmsRoutes() {
  return (
    <Routes>
      <Route path="/" element={<LocationAlarmsList />} />
      <Route path=":locationAlarmId" element={<LocationAlarmDetailsRoot />} />
    </Routes>
  );
}

export default LocationAlarmsRoutes;
