import LocationRoutes from './LocationRoutes';
import StateProvider from './store/StateProvider';

export function LocationsRoot() {
  return (
    <StateProvider>
      <LocationRoutes />
    </StateProvider>
  );
}

export default LocationsRoot;
