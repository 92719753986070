import {
  EscalationListFilterValues,
  EscalationStatus,
  FilterConfig,
  FilterValues,
  StatusFilterOption,
  TimePeriod,
} from '@hakimo-ui/hakimo/types';

export const getLabelType = (status: EscalationStatus) => {
  switch (status) {
    case 'Resolved':
      return 'success';
    case 'In Progress':
      return 'warning';
    case 'Pending':
      return 'error';
    default:
      return 'default';
  }
};

const statusOptions: StatusFilterOption<EscalationStatus>[] = [
  { id: 'pending', name: 'Pending' },
  { id: 'resolved', name: 'Resolved' },
  { id: 'inProgress', name: 'In Progress' },
];

export const filterConfigs: FilterConfig[] = [
  {
    type: 'status',
    defaultValue: {
      values: [],
      negative: false,
    },
    options: statusOptions,
  },
  {
    type: 'location',
    defaultValue: {
      values: [],
      negative: false,
    },
  },
];

export const defaultFilterValues = filterConfigs.reduce((acc, config) => {
  return { ...acc, [config.type]: config.defaultValue };
}, {} as EscalationListFilterValues);

export function isNotDefaultFilter(filters: Partial<FilterValues>): boolean {
  return (
    (filters.location && filters.location.values.length > 0) ||
    (filters.sourceSystem && filters.sourceSystem.length > 0) ||
    (filters.sourceEntity && filters.sourceEntity.values.length > 0) ||
    (filters.type && filters.type.values.length > 0) ||
    (filters.employee && filters.employee.values.length > 0) ||
    (filters.status && filters.status.values.length > 0) ||
    (filters.tenant && filters.tenant.values.length > 0) ||
    (filters.time && filters.time.period !== TimePeriod.ALL_TIME) ||
    (filters.camera && filters.camera.values.length > 0) ||
    false
  );
}
