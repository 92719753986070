import { CameraUpdatePayload } from '@hakimo-ui/hakimo/types';
import useAuthenticatedMutation from '../shared/useAuthenticatedMutation';

export function useUpdateCamera(
  camId: string,
  onSuccess?: () => void,
  onError?: () => void,
  isDeleting?: boolean
) {
  const url = `/v2/orm/camera/${camId}`;

  const request = new Request(url, {
    method: 'PATCH',
  });

  return useAuthenticatedMutation<null, CameraUpdatePayload | null>(request, {
    onSuccessInvalidationKeys: isDeleting
      ? [['cameras']]
      : [['cameras'], ['cameras', camId]],
    onSuccess,
    onError,
  });
}

export default useUpdateCamera;
