import { PlaybackSpeedIcon } from '@hakimo-ui/hakimo/ui-elements';
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon } from '@heroicons/react/24/solid';
import clsx from 'clsx';
import { Fragment } from 'react';

interface Props {
  onChange: (speed: number) => void;
  selectedId: number;
}

const options = [
  {
    id: 2,
    name: '2x',
  },
  {
    id: 1.5,
    name: '1.5x',
  },
  {
    id: 1,
    name: 'Normal',
  },
  {
    id: 0.75,
    name: '0.75x',
  },
  {
    id: 0.5,
    name: '0.5x',
  },
];

export function PlaybackSpeed(props: Props) {
  const { onChange, selectedId } = props;
  const selectedOption = options.find((opt) => opt.id === selectedId);
  return (
    <div className="flex items-center justify-center">
      <Listbox value={selectedOption} onChange={(opt) => onChange(opt.id)}>
        {({ open }) => (
          <>
            <Listbox.Label className="sr-only">
              Change playback speed
            </Listbox.Label>
            <div className="relative flex">
              <Listbox.Button className="rounded-full border-transparent p-1 hover:bg-slate-800/50">
                <PlaybackSpeedIcon className="fill-dark-text h-6 w-6" />
              </Listbox.Button>
              <Transition
                show={open}
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options className="dark:bg-ondark-bg-2 bg-onlight-bg-2 absolute bottom-6 -left-14 z-10 mt-1 w-24 overflow-auto rounded-md py-1 text-xs shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  {options.map((option) => (
                    <Listbox.Option
                      key={option.id}
                      className={({ active }) =>
                        clsx(
                          active
                            ? 'bg-onlight-primary dark:bg-ondark-primary text-white'
                            : 'text-onlight-text-1 dark:text-ondark-text-1',
                          'relative cursor-default select-none px-3 py-1'
                        )
                      }
                      value={option}
                    >
                      {({ selected, active }) => (
                        <div className="flex w-full flex-col">
                          <div className="flex justify-between">
                            <p
                              className={selected ? 'font-bold' : 'font-normal'}
                            >
                              {option.name}
                            </p>
                            {selected ? (
                              <span
                                className={
                                  active
                                    ? 'text-white'
                                    : 'text-onlight-primary dark:text-ondark-primary'
                                }
                              >
                                <CheckIcon
                                  className="h-4 w-4"
                                  aria-hidden="true"
                                />
                              </span>
                            ) : null}
                          </div>
                        </div>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </>
        )}
      </Listbox>
    </div>
  );
}

export default PlaybackSpeed;
