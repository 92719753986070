//Function to create a linear mapping from domain to range
export function createLinearMapping(
  domain: [number, number],
  range: [number, number]
) {
  //Calculate slope and y-intercept from domain and range
  const slope = (range[1] - range[0]) / (domain[1] - domain[0]);
  const yIntercept = range[0] - slope * domain[0];

  //Return a function that maps domain value to range value
  return function (x: number) {
    return slope * x + yIntercept;
  };
}

// Function to return an array of timestamps
export const getSplitTimes = (
  fromDate: number,
  toDate: number,
  interval: number
) => {
  const timestamps: number[] = [];
  let currentTimestamp = fromDate;

  // safety check
  if (
    !Number.isFinite(fromDate) ||
    !Number.isFinite(toDate) ||
    !Number.isFinite(interval)
  ) {
    return timestamps;
  }

  // Loop until the current timestamp is equal to the end date
  while (currentTimestamp <= toDate) {
    timestamps.push(currentTimestamp);
    currentTimestamp += interval;
  }

  return timestamps;
};

export function secondsToHumanReadableTime(seconds: number): string {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds - hours * 3600) / 60);
  const secondsRemaining = seconds - hours * 3600 - minutes * 60;

  const times = [];

  if (hours > 0) {
    times.push(`${hours}h`);
  }

  if (minutes > 0) {
    times.push(`${minutes}m`);
  }

  if (secondsRemaining > 0) {
    times.push(`${secondsRemaining}s`);
  }

  return times.join(' ');
}
