import { PlaybackMotionMarkers } from '@hakimo-ui/hakimo/types';
import useAuthenticatedRequest from '../shared/useAuthenticatedRequest';

export function usePlaybackMarkers(
  cameraId: string,
  startTS: number,
  endTS: number,
  onSuccess: (markers: PlaybackMotionMarkers) => void
) {
  const url = `/v2/orm/camera/alarmMarkers/${cameraId}?startTS=${startTS}&endTS=${endTS}`;
  const request = new Request(url);
  return useAuthenticatedRequest<PlaybackMotionMarkers>(request, {
    responseModifier: async (response) => {
      const respJson = await response.json();
      return respJson;
    },
    onSuccess,
  });
}
