import { DoorGroup } from '@hakimo-ui/hakimo/types';
import { Button } from '@hakimo-ui/shared/ui-base';
import { TrashIcon } from '@heroicons/react/24/outline';
import { PencilSquareIcon } from '@heroicons/react/24/solid';
import { useEffect, useState } from 'react';
import DeleteDoorGroup from './DeleteDoorGroup';
import EditDoorGroupDetails from './EditDoorGroupDetails';

interface Props {
  doorGroup: DoorGroup;
}
export function ActionsDoorGroup(props: Props) {
  const { doorGroup } = props;
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [canDelete, setCanDelete] = useState(false);

  useEffect(() => {
    if (doorGroup) {
      if (
        doorGroup.doors.length === 0 &&
        doorGroup.processingConfigs.length === 0
      ) {
        setCanDelete(true);
      } else {
        setCanDelete(false);
      }
    }
  }, [doorGroup]);
  return (
    <div className="flex flex-row gap-4">
      <Button
        variant="icon"
        onClick={() => {
          setOpenEditModal(true);
        }}
      >
        <PencilSquareIcon className="mr-1 h-5 w-5" />
        Edit Door Group
      </Button>
      {openEditModal && (
        <EditDoorGroupDetails
          doorGroup={doorGroup}
          onClose={() => setOpenEditModal(false)}
        />
      )}
      <Button
        variant="icon"
        onClick={() => setOpenDeleteModal(true)}
        disabled={!canDelete}
        title={
          canDelete
            ? ''
            : 'There are associated doors and processing configurations with this door group'
        }
      >
        {' '}
        <TrashIcon className="h-5 w-5" />
      </Button>
      {openDeleteModal && (
        <DeleteDoorGroup
          doorGroup={doorGroup}
          onClose={() => setOpenDeleteModal(false)}
        />
      )}
    </div>
  );
}
