import { useDraggable } from '@dnd-kit/core';
import { Camera } from '@hakimo-ui/hakimo/types';
import { HakimoSpinner, InputField, Tooltip } from '@hakimo-ui/shared/ui-base';
import { NoSymbolIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import CameraItem from '../cameras-list/CameraItem';
import { MultiCamViewMode } from '../constants';

interface Props {
  isFetching: boolean;
  cameras: Camera[];
  viewMode: MultiCamViewMode;
}

export function CameraGridList(props: Props) {
  const { cameras, isFetching, viewMode } = props;
  const [query, setQuery] = useState('');
  const [filteredCams, setFilteredCams] = useState(cameras);

  useEffect(() => {
    setFilteredCams(cameras);
  }, [cameras]);

  const onSearchValChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value;
    setQuery(val);
    const updatedCams = [...cameras].filter(
      (cam) =>
        cam.name.toLowerCase().includes(val.toLowerCase()) ||
        cam?.location?.name.toLowerCase().includes(val.toLowerCase())
    );
    setFilteredCams(updatedCams);
  };

  return (
    <div className="mt-2 mr-4 flex w-[24rem] flex-col space-y-1 overflow-y-auto">
      <div className="px-1">
        <InputField
          label=""
          onChange={onSearchValChange}
          value={query}
          type="search"
          placeholder="Search..."
        />
      </div>
      {isFetching && cameras.length === 0 ? (
        <div className="flex justify-center">
          <HakimoSpinner />
        </div>
      ) : (
        filteredCams.map((cam) => (
          <CameraGridListItem cam={cam} key={cam.id} viewMode={viewMode} />
        ))
      )}
    </div>
  );
}

interface ItemProps {
  cam: Camera;
  viewMode: MultiCamViewMode;
}

export function CameraGridListItem(props: ItemProps) {
  const { cam, viewMode } = props;
  const disabled =
    viewMode === MultiCamViewMode.PLAYBACK && !cam.isPlaybackEnabled;
  const { attributes, listeners, setNodeRef } = useDraggable({
    id: `draggable-${cam.id}`,
    data: cam,
    disabled,
  });

  return (
    <div
      className={clsx(disabled && 'relative cursor-auto')}
      ref={setNodeRef}
      {...attributes}
      {...listeners}
    >
      {disabled && (
        <span className="absolute top-6 left-1">
          <Tooltip
            position="right"
            size="medium"
            text="Playback not enabled for this camera."
            colorModifier="info"
          >
            <NoSymbolIcon className="h-4 w-4 text-orange-700" />
          </Tooltip>
        </span>
      )}
      <CameraItem camera={cam} disabled={disabled} />
    </div>
  );
}

export default CameraGridList;
