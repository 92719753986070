import { AudioFilterValues, FilterConfig } from '@hakimo-ui/hakimo/types';
import { atom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';

export interface State {
  page: number;
  pageSize: number;
}

export const initialState: State = {
  page: 1,
  pageSize: 20,
};

export const stateAtom = atom<State>(initialState);

export const filterConfigs: FilterConfig[] = [
  {
    type: 'location',
    label: 'Audio Device Location',
    defaultValue: {
      values: [],
      negative: false,
    },
  },
];

export const DEFAULT_AUDIO_FILTER = {
  location: { values: [], negative: false },
};

export const audioFilterAtom = atomWithStorage<AudioFilterValues>(
  'cameraFilters',
  DEFAULT_AUDIO_FILTER
);
