import clsx from 'clsx';

interface Props {
  isArming: boolean;
  fromDate: Date;
  toDate: Date;
}

function ConfirmationPrompt(props: Props) {
  const { isArming, fromDate, toDate } = props;

  return (
    <div className="leading-8">
      You are about to{' '}
      <span
        className={clsx(
          'font-bold',
          isArming ? 'text-status-green' : 'text-status-red'
        )}
      >
        {isArming ? 'Arm' : 'Disarm'}
      </span>{' '}
      the camera from{' '}
      <span className="dark:bg-ondark-bg-2 bg-onlight-bg-2 whitespace-pre rounded-md py-1 px-2 font-bold">
        {fromDate.toLocaleString()}
      </span>{' '}
      to{' '}
      <span className="dark:bg-ondark-bg-2 bg-onlight-bg-2 whitespace-pre rounded-md py-1 px-2 font-bold">
        {toDate.toLocaleString()}
      </span>
      . Are you sure?
    </div>
  );
}

export default ConfirmationPrompt;
