import { useEffect } from 'react';
import { Toaster as RHToaster, toast, useToasterStore } from 'react-hot-toast';

const TOAST_LIMIT = 5;

function Toaster() {
  const { toasts } = useToasterStore();

  useEffect(() => {
    toasts
      .filter((t) => t.visible)
      .filter((_, i) => i >= TOAST_LIMIT)
      .forEach((t) => toast.dismiss(t.id));
  }, [toasts]);

  return <RHToaster />;
}

export default Toaster;
