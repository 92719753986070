import { useSiteSchedule } from '@hakimo-ui/hakimo/data-access';
import { Location, SiteDisarmWindow } from '@hakimo-ui/hakimo/types';
import { Alert, Button, HakimoSpinner } from '@hakimo-ui/shared/ui-base';
import { useEffect, useState } from 'react';
import UpdateMonitoringDisarmModal from './UpdateMonitoringDisarmModal';
import { formatDateInTimezone } from './util';

interface Props {
  location: Location;
}

export function MonitoringDisarm(props: Props) {
  const { location } = props;

  const [siteDisarmWindow, setSiteDisarmWindow] = useState<SiteDisarmWindow>();
  const [showUpdateDisarmModal, setShowUpdateDisarmModal] = useState(false);

  const { isLoading, isError, error, data } = useSiteSchedule(
    location.id,
    location.tenant_id || ''
  );

  useEffect(() => {
    if (data) {
      setSiteDisarmWindow(data.disarmWindow);
    }
  }, [data]);

  const onUpdateDisarmStatus = () => setShowUpdateDisarmModal(true);
  const onCloseModal = () => setShowUpdateDisarmModal(false);
  const isRelevantWindow =
    siteDisarmWindow &&
    siteDisarmWindow.endTimestamp * 1000 >= new Date().getTime();

  return (
    <div className="flex items-center gap-4">
      {isLoading && (
        <div className="flex h-24 items-center justify-center">
          <HakimoSpinner />
        </div>
      )}
      {isError && <Alert type="error">{error.message}</Alert>}
      {isRelevantWindow && (
        <div className="flex flex-col text-xs">
          <div className="space-x-2">
            <span className="text-sm font-bold text-red-500">
              Monitoring Disarmed
            </span>
            <span>(time in {siteDisarmWindow.timezone})</span>
          </div>
          <span>
            <b>From: </b>
            {formatDateInTimezone(
              siteDisarmWindow.startTimestamp,
              siteDisarmWindow.timezone
            )}
          </span>
          <span>
            <b>Till: </b>
            {formatDateInTimezone(
              siteDisarmWindow.endTimestamp,
              siteDisarmWindow.timezone
            )}
          </span>
        </div>
      )}
      <Button
        variant={isRelevantWindow ? 'outline' : 'error'}
        onClick={onUpdateDisarmStatus}
      >
        {isRelevantWindow ? 'Update Disarm Status' : 'Disarm Monitoring'}
      </Button>
      {showUpdateDisarmModal && location.tenant_id && (
        <UpdateMonitoringDisarmModal
          onClose={onCloseModal}
          siteDisarmWindow={siteDisarmWindow}
          tenantId={location.tenant_id}
          isRelevantWindow={isRelevantWindow}
        />
      )}
    </div>
  );
}

export default MonitoringDisarm;
