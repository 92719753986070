import { Spinner } from '@hakimo-ui/shared/ui-base';
import { PauseIcon, PlayIcon } from '@heroicons/react/24/solid';
import clsx from 'clsx';
import { useEffect, useRef, useState } from 'react';
import { getFormattedSeconds } from './util';

interface Props {
  src: string;
  isLoading: boolean;
}

export function MiniAudioPlayer(props: Props) {
  const { isLoading, src } = props;
  const audioRef = useRef<HTMLAudioElement>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [time, setTime] = useState(0);

  useEffect(() => {
    let intervaId: number;

    if (isPlaying) {
      intervaId = window.setInterval(() => {
        setTime(audioRef.current?.currentTime || 0);
      }, 1000);
    }

    return () => {
      intervaId && window.clearInterval(intervaId);
    };
  }, [isPlaying]);

  useEffect(() => {
    const audioPlayer = audioRef.current;
    const handler = () => {
      setIsPlaying(false);
    };
    if (audioPlayer) {
      audioPlayer.addEventListener('ended', handler);
    }

    return () => {
      audioPlayer && audioPlayer.removeEventListener('ended', handler);
    };
  }, []);

  const togglePlayPause = () => {
    setIsPlaying((prev) => !prev);
    if (isPlaying) {
      audioRef.current?.pause();
    } else {
      audioRef.current?.play();
    }
  };

  return (
    <div
      className={clsx(
        'dark:bg-ondark-bg-2 bg-onlight-bg-3 dark:text-ondark-text-1 text-onlight-text-1 dark:border-ondark-line-2 border-onlight-line-2 flex items-center gap-2 rounded-xl border px-6 py-1',
        src === '' && 'pointer-events-none opacity-75'
      )}
      tabIndex={1}
      onClick={togglePlayPause}
    >
      {isPlaying ? (
        <PauseIcon className="h-3 w-3" />
      ) : (
        <PlayIcon className="h-3 w-3" />
      )}
      <span className="text-xs">{getFormattedSeconds(time)}</span>
      {isLoading && (
        <span className="text-onlight-text-3">
          <Spinner />
        </span>
      )}
      <audio
        ref={audioRef}
        src={src}
        className="inline-block h-12 scale-75"
      ></audio>
    </div>
  );
}

export default MiniAudioPlayer;
