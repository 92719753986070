import { LocationAlarmUpdateRequestDTO } from '@hakimo-ui/hakimo/types';
import useAuthenticatedMutation from '../shared/useAuthenticatedMutation';

export function useUpdateLocationAlarm(
  alarmId: string,
  onSuccess?: (
    data: null,
    variables: LocationAlarmUpdateRequestDTO | null
  ) => void
) {
  const url = `/v2/orm/location_alarms/update/${alarmId}`;

  const request = new Request(url, {
    method: 'POST',
  });

  return useAuthenticatedMutation<null, LocationAlarmUpdateRequestDTO | null>(
    request,
    {
      onSuccessInvalidationKeys: [
        ['locationAlarms'],
        ['locationAlarms', alarmId],
        ['location_alarm_updates'],
      ],
      onSuccess,
    }
  );
}

export default useUpdateLocationAlarm;
