import { Selectable } from '@hakimo-ui/shared/ui-base';
import { selectHelpers } from './select-helpers';
import { getMultiSelectItems, getSelectItems } from './select-items';

const { filterPredicate, isEqual, customItemBuilder, isCustomItem } =
  selectHelpers;

export function getMultiSelectItemsSelectable(
  query: string,
  value: Selectable[],
  staticItems?: Selectable[]
) {
  return getMultiSelectItems(query, value, filterPredicate, isEqual, {
    customItemBuilder,
    isCustomItem,
    staticItems,
  });
}

export function getSelectItemsSelectable(
  query: string,
  value?: Selectable | null,
  staticItems?: Selectable[]
) {
  return getSelectItems(query, filterPredicate, isEqual, value, {
    customItemBuilder,
    isCustomItem,
    staticItems,
  });
}
