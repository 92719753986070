import { LocationSelect } from '@hakimo-ui/hakimo/feature-shared';

interface Props {
  value: string;
  onChange: (value: string) => void;
}

export function AddLocationField(props: Props) {
  const { value, onChange } = props;
  return (
    <label className="grid h-14 grid-cols-12 items-center justify-between">
      <span className="col-span-4">Location</span>
      <span className="col-span-8">
        <LocationSelect locationId={value} onChange={onChange} />
      </span>
    </label>
  );
}
