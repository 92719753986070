import { FilterConfig } from '@hakimo-ui/hakimo/types';

export enum CameraIntegrationType {
  RTSP = 'RTSP',
  AVIGILON = 'AVIGILON',
  GENETEC = 'GENETEC',
  EXACQVISION = 'EXACQVISION',
  MILESTONE = 'MILESTONE',
}

export enum CameraDetailViews {
  SPECIAL_ZONES = 'specialZones',
  LIVE = 'live',
  PLAYBACK = 'playback',
  SETTINGS = 'settings',
}

export enum CamerasViewMode {
  LIST_VIEW = 'listView',
  GRID_VIEW = 'gridView',
}

export enum MultiCamViewMode {
  LIVE = 'live',
  PLAYBACK = 'playback',
}

export const DEFAULT_PLAYBACK_PERIOD = 15; //days
export const DEFAULT_CAM_FILTER = {
  location: { values: [], negative: false },
};

export const filterConfigs: FilterConfig[] = [
  {
    type: 'location',
    label: 'Camera Location',
    defaultValue: {
      values: [],
      negative: false,
    },
  },
];
