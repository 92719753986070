/* eslint-disable max-lines */
import { useTextToSpeech } from '@hakimo-ui/hakimo/data-access';
import { environment } from '@hakimo-ui/hakimo/environments';
import { eventTracker } from '@hakimo-ui/hakimo/util';
import { Alert, Button, Modal } from '@hakimo-ui/shared/ui-base';
import clsx from 'clsx';
import { useCallback, useState } from 'react';
import TextToSpeech from './text-to-speech/TextToSpeech';
import { Templates } from './util';

interface Props {
  onClose: () => void;
  open: boolean;
  cameraName: string;
  onSubmitAudio: (file: File) => void;
}

export function AudioUploadModal(props: Props) {
  const { onClose, open, cameraName, onSubmitAudio } = props;
  const [audioFiles, setAudioFiles] = useState<File[]>(
    new Array(Templates.length)
  );
  const [selectedTemplate, setSelectedTemplate] = useState(0);
  const { isLoading: isAudioLoading, fetchAudio } = useTextToSpeech(
    environment.elevenLabsApiKey
  );
  const [finalTemplatesText, setFinalTemplatesText] = useState(
    Templates.map((t) => t.text)
  );
  const [validationState, setValidationState] = useState({
    isValid: true,
    message: '',
  });
  const [errorState, setErrorState] = useState({ isError: false, message: '' });

  const onSubmit = async () => {
    if (
      Templates[selectedTemplate].inputAfterWordCount !== undefined &&
      Templates[selectedTemplate].text === finalTemplatesText[selectedTemplate]
    ) {
      setValidationState({
        isValid: false,
        message: 'Provide a valid value in the input field',
      });
      return;
    } else {
      setValidationState({
        isValid: true,
        message: '',
      });
    }
    if (!audioFiles[selectedTemplate]) {
      const text = finalTemplatesText[selectedTemplate];
      const response = await fetchAudio(text);
      if (response instanceof Error) {
        setErrorState({ isError: true, message: response.message });
        return;
      }
      const { file } = response;
      onSubmitAudio(file);
    } else {
      onSubmitAudio(audioFiles[selectedTemplate]);
    }
    onClose();
  };

  const actions = (
    <>
      <Button
        variant="primary"
        onClick={onSubmit}
        onSideEffect={() =>
          eventTracker('submit_text_to_speech_camera_talkdown')({
            message: finalTemplatesText[selectedTemplate],
            cameraName,
          })
        }
        loading={isAudioLoading}
      >
        Send audio
      </Button>
      <Button onClick={onClose}>Cancel</Button>
    </>
  );

  const updateAudioFile = useCallback(
    (index: number) => (file: File) => {
      const updatedAudioFiles = [...audioFiles];
      updatedAudioFiles[index] = file;
      setAudioFiles(updatedAudioFiles);
      setSelectedTemplate(index);
    },
    [audioFiles]
  );

  const updateFinalTemplateText = (index: number) => (text: string) => {
    const updatedFinalTexts = [...finalTemplatesText];
    updatedFinalTexts[index] = text;
    setFinalTemplatesText(updatedFinalTexts);
    setValidationState({
      isValid: true,
      message: '',
    });
  };

  const radioOptions = Templates.map((template, i) => ({
    ...template,
    node: (
      <TextToSpeech
        prefetch={template.prefetch}
        text={template.text}
        updateAudioFile={updateAudioFile(i)}
        inputAfterWordCount={template.inputAfterWordCount}
        placeholder={template.placeholder}
        updateFinalTemplateText={updateFinalTemplateText(i)}
        isInputValueInvalid={!validationState.isValid && selectedTemplate === i}
      />
    ),
    index: i,
  }));

  const onChangeRadio: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    setSelectedTemplate(+event.target.id || 0);
    setValidationState({
      isValid: true,
      message: '',
    });
  };
  return (
    <Modal
      onClose={onClose}
      open={open}
      closable={true}
      footer={actions}
      title={`Live talkdown for ${cameraName}`}
    >
      <div className="p-8">
        {!validationState.isValid && (
          <Alert type="warning">{validationState.message}</Alert>
        )}
        {errorState.isError && <Alert type="error">{errorState.message}</Alert>}
        <fieldset>
          <legend className="sr-only">Theme</legend>
          <div className="flex flex-col space-y-4 rounded-md">
            {radioOptions.map((option) => {
              const checked = option.index === selectedTemplate;
              return (
                <div
                  key={option.index}
                  className={clsx(
                    'flex items-center p-2',
                    checked
                      ? 'border-primary-200 bg-primary-50 dark:bg-primary-900/30 dark:border-primary-800'
                      : 'border-gray-200 dark:border-gray-600'
                  )}
                >
                  <input
                    id={`${option.index}`}
                    name="notification-method"
                    type="radio"
                    defaultChecked={checked}
                    className="text-primary-600 focus:ring-primary-500 dark:checked:bg-primary-500 dark:focus:ring-primary-600 h-4 w-4 border-gray-300 bg-gray-100 focus:ring-2 dark:border-none dark:bg-gray-700 dark:ring-offset-gray-800"
                    onChange={onChangeRadio}
                  />
                  <label
                    htmlFor={`${option.index}`}
                    className={clsx(
                      'ml-3 block text-sm font-medium',
                      checked
                        ? 'text-primary-900 dark:text-primary-200'
                        : 'dark:text-dark-text text-gray-900'
                    )}
                  >
                    {option.node}
                  </label>
                </div>
              );
            })}
          </div>
        </fieldset>
      </div>
    </Modal>
  );
}

export default AudioUploadModal;
