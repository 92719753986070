import { Alert } from '@hakimo-ui/shared/ui-base';
import * as Sentry from '@sentry/react';
import { ComponentType, ReactElement } from 'react';

export function withErrorBoundary<P extends object>(
  WrappedComponent: ComponentType<P>,
  fallback?: ReactElement
) {
  return Sentry.withErrorBoundary(WrappedComponent, {
    showDialog: true,
    fallback: fallback || (
      <div className="p-4">
        <Alert>
          An error occurred.{' '}
          <a
            href={window.location.pathname}
            className="hover:text-primary-500 underline"
          >
            Click here to reload
          </a>{' '}
          this page.
        </Alert>
      </div>
    ),
  });
}

export default withErrorBoundary;
