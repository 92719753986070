import {
  DescriptionList,
  DescriptionListItem,
} from '@hakimo-ui/hakimo/ui-elements';
import { useLocalStorage } from '@hakimo-ui/hakimo/util';

enum ThemeId {
  dark = 'dark',
  light = 'light',
  system = 'system',
}

const themes = [
  { id: ThemeId.dark, title: 'Dark' },
  { id: ThemeId.light, title: 'Light' },
  { id: ThemeId.system, title: 'System' },
];

const alarmListReturnOptions = [
  { id: 'enabled', title: 'Enabled' },
  { id: 'disabled', title: 'Disabled' },
];
export function Application() {
  const [currentTheme, setCurrentTheme, removeItem] =
    useLocalStorage<ThemeId>('theme');

  const [alarmReturnPostResolve, setAlarmReturnPostResolve] =
    useLocalStorage<boolean>('alarmReturnPostResolve', true);

  const onChangeAlarmReturn: React.ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    switch (event.target.id) {
      case 'enabled':
        setAlarmReturnPostResolve(true);
        break;
      default:
        setAlarmReturnPostResolve(false);
    }
  };
  const onChangeTheme: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    switch (event.target.id) {
      case ThemeId.dark:
        setCurrentTheme(ThemeId.dark);
        document.documentElement.classList.add('dark');
        break;
      case ThemeId.light:
        setCurrentTheme(ThemeId.light);
        document.documentElement.classList.remove('dark');
        break;
      default:
        removeItem();
        if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
          document.documentElement.classList.add('dark');
        } else {
          document.documentElement.classList.remove('dark');
        }
    }
  };

  const items: DescriptionListItem[] = [
    {
      name: 'Theme',
      value: (
        <span className="flex flex-shrink-0 items-start space-x-4">
          <fieldset>
            <legend className="sr-only">Theme</legend>
            <div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10">
              {themes.map((theme) => (
                <div key={theme.id} className="flex items-center">
                  <input
                    id={theme.id}
                    name="notification-method"
                    type="radio"
                    defaultChecked={
                      theme.id === currentTheme ||
                      (theme.id === ThemeId.system &&
                        currentTheme === undefined)
                    }
                    className="text-primary-600 focus:ring-primary-500 dark:checked:bg-primary-500 dark:focus:ring-primary-600 h-4 w-4 border-gray-300 bg-gray-100 focus:ring-2 dark:border-none dark:bg-gray-700 dark:ring-offset-gray-800"
                    onChange={onChangeTheme}
                  />
                  <label
                    htmlFor={theme.id}
                    className="dark:text-dark-text ml-3 block text-sm font-medium text-gray-700"
                  >
                    {theme.title}
                  </label>
                </div>
              ))}
            </div>
          </fieldset>
        </span>
      ),
    },
    {
      name: 'Return To Alarm List On Alarm Resolution',
      value: (
        <span className="flex flex-shrink-0 items-start space-x-4">
          <fieldset>
            <legend className="sr-only">Theme</legend>
            <div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10">
              {alarmListReturnOptions.map((option) => (
                <div key={option.id} className="flex items-center">
                  <input
                    id={option.id}
                    name="alarm-list-return"
                    type="radio"
                    defaultChecked={
                      (option.id === 'enabled' && alarmReturnPostResolve) ||
                      (option.id === 'disabled' && !alarmReturnPostResolve)
                    }
                    className="text-primary-600 focus:ring-primary-500 dark:checked:bg-primary-500 dark:focus:ring-primary-600 h-4 w-4 border-gray-300 bg-gray-100 focus:ring-2 dark:border-none dark:bg-gray-700 dark:ring-offset-gray-800"
                    onChange={onChangeAlarmReturn}
                  />
                  <label
                    htmlFor={option.id}
                    className="dark:text-dark-text ml-3 block text-sm font-medium text-gray-700"
                  >
                    {option.title}
                  </label>
                </div>
              ))}
            </div>
          </fieldset>
        </span>
      ),
    },
  ];

  return <DescriptionList items={items} />;
}

export default Application;
