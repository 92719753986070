import { CamerasDTO } from '@hakimo-ui/hakimo/types';
import useAuthenticatedRequest from '../shared/useAuthenticatedRequest';

export function useCameras(searchParams: string) {
  const params = searchParams.length > 0 ? `?${searchParams}` : '';
  const url = `/v2/orm/camera${params}`;
  const request = new Request(url);

  return useAuthenticatedRequest<CamerasDTO>(request, {
    queryKey: ['cameras', { searchParams }],
    responseModifier: async (response) => {
      const respJson = await response.json();
      return respJson.payload;
    },
  });
}

export default useCameras;
