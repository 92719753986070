import { User } from '@hakimo-ui/hakimo/types';
import {
  DescriptionList,
  DescriptionListItem,
} from '@hakimo-ui/hakimo/ui-elements';
import ProfileSection from '../profile-section/ProfileSection';

export interface Props {
  user: User;
}

export function General(props: Props) {
  const { user } = props;

  const listItems: DescriptionListItem[] = [
    {
      name: 'Name',
      value: user.name ?? '-',
    },
    {
      name: 'Email',
      value: user.email,
    },
    {
      name: 'Roles',
      value: user.roles.join(', '),
    },
    {
      name: 'Tenant',
      value: user.tenant.name,
    },
  ];

  const account = <DescriptionList items={listItems} />;

  return (
    <div className="mx-auto w-full max-w-2xl">
      <ProfileSection title="Account" content={account} />
    </div>
  );
}

export default General;
