import { delay } from '@hakimo-ui/hakimo/util';
import { Button, Tooltip } from '@hakimo-ui/shared/ui-base';
import { CheckIcon, ClipboardIcon } from '@heroicons/react/24/outline';
import { useState } from 'react';

interface Props {
  onClick: () => void;
}

export function CopyButton(props: Props) {
  const { onClick } = props;
  const [clicked, setClicked] = useState(false);

  const onClickCb = async () => {
    onClick();
    setClicked(true);
    await delay(3000);
    setClicked(false);
  };

  return clicked ? (
    <Tooltip text="Copied!" colorModifier="success" position="bottom" always>
      <Button variant="icon" disabled>
        <CheckIcon className="w-4 text-green-500" />
      </Button>
    </Tooltip>
  ) : (
    <Button variant="icon" onClick={onClickCb}>
      <ClipboardIcon className="w-5" />
    </Button>
  );
}

export default CopyButton;
