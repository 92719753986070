import Spinner from './Spinner';

export function HakimoSpinner(props: React.ComponentProps<'span'>) {
  return (
    <span className="dark:text-hakimo-yellow text-primary-700" {...props}>
      <Spinner />
    </span>
  );
}

export default HakimoSpinner;
