import { Combobox } from '@headlessui/react';
import { ChevronUpDownIcon } from '@heroicons/react/24/solid';
import { KeyboardEvent, useEffect, useRef, useState } from 'react';
import Option from './Option';

interface Props<T> {
  value?: T | null;
  label?: string;
  onChange: (value: T | null) => void;
  displayValue: (item?: T) => string;
  getItems: (query: string) => T[];
  id?: (item: T) => string;
  /*
    Optional if parent wants to be aware of changes to query
   */
  onChangeQuery?: (newQuery: string) => void;
  onKeyUp?: (event: KeyboardEvent) => void;
  placeholder?: string;
  disabled?: (item: T) => boolean;
}

export function Select<T>(props: Props<T>) {
  const {
    label,
    value: selected,
    onChange,
    displayValue,
    getItems,
    id: getId,
    onChangeQuery: queryChangeCallback,
    onKeyUp,
    placeholder,
    disabled,
  } = props;
  const [query, setQuery] = useState('');
  const [items, setItems] = useState<T[]>([]);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setItems(getItems(query));
  }, [query, getItems]);

  const onChangeQuery = (queryVal: string) => {
    setQuery(queryVal);
    if (queryChangeCallback) {
      queryChangeCallback(queryVal);
    }
  };

  const handleSelectionChange = (value: T | null) => {
    onChange(value);
    inputRef.current && inputRef.current.focus();
  };

  return (
    <Combobox
      as="div"
      value={selected}
      onChange={handleSelectionChange}
      nullable
    >
      {label && (
        <Combobox.Label className="dark:text-dark-text mb-1 block text-xs text-gray-700">
          {label}
        </Combobox.Label>
      )}
      <div className="relative">
        <Combobox.Input
          className="focus:border-primary-500 focus:ring-primary-500 dark:bg-dark-bg dark:text-dark-text w-full rounded border-gray-400 text-sm dark:border-gray-600"
          onChange={(event) => {
            onChangeQuery(event.target.value);
          }}
          displayValue={displayValue}
          autoFocus
          ref={inputRef}
          onKeyUp={onKeyUp}
          placeholder={placeholder}
        />
        <Combobox.Button className=" absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
          <ChevronUpDownIcon
            className="h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
        </Combobox.Button>
        {items.length > 0 && (
          <Combobox.Options
            hold
            className="dark:bg-dark-hover-bg absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
          >
            {items.map((item, idx) => (
              <Option
                key={getId ? getId(item) : idx}
                item={item}
                displayValue={displayValue}
                disabled={disabled && disabled(item)}
              />
            ))}
          </Combobox.Options>
        )}
      </div>
    </Combobox>
  );
}

export default Select;
