import clsx from 'clsx';
import { NavLink } from 'react-router-dom';
import { NavItem } from './types';

interface Props {
  items: NavItem[];
  onClick?: () => void;
  short?: boolean;
}

function Links(props: Props) {
  const { items, short, onClick = () => null } = props;

  return (
    <nav>
      <div className="space-y-1">
        {items.map((item) => (
          <NavLink
            key={item.name}
            to={item.href}
            title={item.name}
            className={({ isActive }) =>
              clsx(
                isActive
                  ? 'dark:text-dark-text bg-gray-200 text-gray-700 dark:bg-gray-700 '
                  : 'text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-300',
                'focus-visible:ring-primary-500 dark:focus:ring-offset-dark-surface group flex items-center rounded-md px-3 py-2 text-sm font-medium focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2'
              )
            }
            onClick={onClick}
          >
            {({ isActive }) => (
              <>
                <item.icon
                  className={clsx(
                    isActive
                      ? 'dark:text-dark-text text-gray-700'
                      : 'text-gray-500 group-hover:text-gray-700 dark:group-hover:text-gray-300',
                    'mr-3 h-6 w-6 flex-shrink-0'
                  )}
                  aria-hidden="true"
                />
                {short ? '' : item.name}
              </>
            )}
          </NavLink>
        ))}
      </div>
    </nav>
  );
}

export default Links;
