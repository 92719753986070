import { LocationFilterValue } from '@hakimo-ui/hakimo/types';
import { LocationFilter } from '@hakimo-ui/hakimo/ui-filters';
import { Toggle } from '@hakimo-ui/shared/ui-base';
import { useEffect, useState } from 'react';

interface DoorLocationFilterProps {
  locationFilterValue: LocationFilterValue;
  onChangeLocationFilterValue: (value: LocationFilterValue) => void;
}

const NULL_LOCATION_ITEM = {
  id: 'null',
  name: 'Show doors without location',
};

function DoorLocationFilter(props: DoorLocationFilterProps) {
  const { locationFilterValue, onChangeLocationFilterValue } = props;
  const [isNullLocationEnabled, setIsNullLocationEnabled] = useState(
    locationFilterValue.values.some((val) => val.id === NULL_LOCATION_ITEM.id)
  );

  useEffect(() => {
    const isNullLocation = locationFilterValue.values.some(
      (val) => val.id === NULL_LOCATION_ITEM.id
    );
    setIsNullLocationEnabled(isNullLocation);
  }, [locationFilterValue.values]);

  const onNullLocationChange = (val: boolean) => {
    setIsNullLocationEnabled(val);
    let updatedFilterValues = [...locationFilterValue.values];
    if (val) {
      updatedFilterValues.push(NULL_LOCATION_ITEM);
    } else {
      updatedFilterValues = updatedFilterValues.filter(
        (filterVal) => filterVal.id !== NULL_LOCATION_ITEM.id
      );
    }

    onChangeLocationFilterValue({
      ...locationFilterValue,
      values: updatedFilterValues,
    });
  };

  return (
    <div className="space-y-4">
      <Toggle
        enabled={isNullLocationEnabled}
        label="Show doors without location"
        onChange={onNullLocationChange}
      />
      <LocationFilter
        label="Door Location"
        value={locationFilterValue}
        onChange={onChangeLocationFilterValue}
      />
    </div>
  );
}

export default DoorLocationFilter;
