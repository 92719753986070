import { CameraDetail } from '@hakimo-ui/hakimo/types';
import { useState } from 'react';
import { SettingFieldText } from './SettingFieldText';
import { SettingSection } from './SettingSection';
import { UpdatePasswordFile } from './update-fields/UpdatePasswordFile';
import { UpdateUserId } from './update-fields/UpdateUserId';

interface Props {
  camera: CameraDetail;
}

export function SettingSectionCreds(props: Props) {
  const { camera } = props;
  const [updateUserId, setUpdateUserId] = useState(false);
  const [updatePasswordFile, setUpdatePasswordFile] = useState(false);

  return (
    <>
      <SettingSection name="Credentials">
        <SettingFieldText
          name="User ID"
          value={camera.camUserId}
          onClickUpdate={() => setUpdateUserId(true)}
        />
        <SettingFieldText
          name="Password File Path"
          value={camera.camPasswordFilePath}
          onClickUpdate={() => setUpdatePasswordFile(true)}
        />
      </SettingSection>
      {updateUserId && (
        <UpdateUserId
          cameraId={camera.id}
          userId={camera.camUserId}
          onClose={() => setUpdateUserId(false)}
        />
      )}
      {updatePasswordFile && (
        <UpdatePasswordFile
          cameraId={camera.id}
          passwordFile={camera.camPasswordFilePath}
          onClose={() => setUpdatePasswordFile(false)}
        />
      )}
    </>
  );
}
