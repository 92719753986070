import { Selectable } from '@hakimo-ui/shared/ui-base';
import { MotionAlarm, StatusType } from './alarms/alarms';
import { Location } from './location';

export interface LocationAlarmsDTO {
  total: number;
  items: LocationAlarm[];
}

export interface LocationAlarm {
  id: string;
  guid: string;
  alarmTime: string;
  status: LocationAlarmStatus;
  location: Location;
  tap: number;
  rawAlarms: MotionAlarm[];
  locationIncidents?: LocationAlarmIncidentDTO[];
}
export const locationAlarmStatuses = [
  'Analyzing',
  'Pending',
  'Resolved',
  'In Progress',
] as const;

export type LocationAlarmStatus = typeof locationAlarmStatuses[number];

export enum TwilioLogType {
  TWILIO_CALLS = 'Twilio calls',
  Twilio_SMS = 'Twilio sms',
}

export interface LocationAlarmUpdateRequestDTO {
  comment?: string;
  status?: StatusType;
  twilio_log_type?: TwilioLogType;
  twilio_ssid?: string;
  twilio_calling_to_name?: string;
}

export enum TalkdownStatus {
  SUCCESS = 'SUCCESS',
  PENDING = 'PENDING',
  FAILED = 'FAILED',
}

export enum TalkdownType {
  MANUAL = 'manual',
  AUTOMATED = 'automated',
}

export interface AlarmTalkdownState {
  id: string;
  custom_message: string;
  ingested_talkdown_audio: string;
  location_alarm_id: string;
  raw_alarm_id?: string;
  status: TalkdownStatus;
  talkdown_audio: string;
  talkdown_completed_utc: string;
  talkdown_state: string;
  talkdown_type: TalkdownType;
}
export interface LocationAlarmUpdatesDTO {
  items: LocationAlarmUpdate[];
  talkdowns?: AlarmTalkdownState[];
}

export interface LocationAlarmUpdate {
  id: number;
  update_status: LocationAlarmStatus;
  update_text: string;
  update_time: string;
  update_type: LocationAlarmUpdateType;
  user?: Selectable;
  audio_url?: string;
  twilio_log_type?: TwilioLogType;
  twilio_ssid?: string;
  twilio_calling_to_name?: string;
  raw_alarm_id?: string;
}

export enum LocationAlarmUpdateType {
  ADD_EVENT = 'Add new event',
  CHANGE_STATUS = 'Change Status',
  UPDATE_TAP = 'Update TAP',
  ADD_COMMENT = 'Add comment',
  VIEW_ALARM = 'View alarm',
  MANUAL_TALKDOWN = 'Manual audio talkdown',
  CALL_SMS_LOGS = 'Call SMS logs',
}

export interface PartialLocationAlarmIncidentDTO {
  location_id?: number;
  tenant_id?: string;
  title?: string;
  description?: string;
  created_by?: string;
  resolved_by?: string;
}
export interface LocationAlarmIncidentDTO
  extends PartialLocationAlarmIncidentDTO {
  id?: number;
  incidentStartTimeUtc?: string;
  incidentEndTimeUtc?: string;
  initiatingLocationAlarmId?: string;
}
export interface UpsertLocationAlarmIncidentDTO
  extends PartialLocationAlarmIncidentDTO {
  incident_id?: number;
  location_alarm_id?: string;
  incident_start_time_utc?: string;
  incident_end_time_utc?: string;
  resolution_comment?: string;
}

export interface TwilioCalDetailsDTO {
  callDuration: string;
  callTo: string;
  endTime: number;
  startTime: number;
  mediaUrl: string;
}
