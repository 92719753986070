import { CameraSelectable } from '@hakimo-ui/hakimo/types';
import { useHttpClient } from '@hakimo-ui/hakimo/util';

export function useCamerasByName(searchParams?: string) {
  const httpClient = useHttpClient();

  return (name: string) => {
    const url = `/v2/orm/camera/names?name=${name}${
      searchParams ? `&${searchParams}` : ''
    }`;

    return httpClient<CameraSelectable[]>(url, {
      responseModifier: async (res) => {
        const respJson = await res.json();
        return respJson.payload;
      },
    });
  };
}

export default useCamerasByName;
