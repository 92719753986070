import { useHttpClient } from '@hakimo-ui/hakimo/util';
import { useQuery, UseQueryOptions } from 'react-query';

type RequestOptions<T> = UseQueryOptions<T, Error> & {
  responseModifier?: (response: Response) => Promise<T>;
  /** Make an unauthenticated request by skipping the Authorization header */
  noAuth?: boolean;
};

export function useAuthenticatedRequest<T>(
  requestInfo: Request,
  options?: RequestOptions<T>
) {
  const httpClient = useHttpClient();

  async function queryFn() {
    return httpClient<T>(requestInfo, {
      noAuth: options?.noAuth,
      responseModifier: options?.responseModifier,
    });
  }

  return useQuery<T, Error>({
    retry: false,
    refetchOnWindowFocus: false,
    queryFn: queryFn,
    ...options,
  });
}

export default useAuthenticatedRequest;
