import { eventTracker } from '@hakimo-ui/hakimo/util';
import { Button } from '@hakimo-ui/shared/ui-base';
import { FunnelIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';

interface Props {
  canAddDoorGroup: boolean;
  hasCustomFiltersApplied: boolean;
  isLoading: boolean;
  onClickAddLocation: () => void;
  onClickFilter: () => void;
}

export function TableHeader(props: Props) {
  const {
    canAddDoorGroup,
    hasCustomFiltersApplied,
    isLoading,
    onClickAddLocation,
    onClickFilter,
  } = props;

  return (
    <div
      className={clsx(
        'dark:bg-dark-bg flex items-center border-b bg-white p-4 dark:border-0',
        canAddDoorGroup ? 'justify-between' : 'justify-end'
      )}
    >
      {canAddDoorGroup && (
        <Button
          variant="primary"
          onClick={onClickAddLocation}
          disabled={isLoading}
          onSideEffect={eventTracker('open_add_door_group_modal')}
        >
          Add Door Group
          <span className="sr-only">Add Door Group</span>
        </Button>
      )}
      <Button
        variant="icon"
        badge={hasCustomFiltersApplied}
        onClick={onClickFilter}
        disabled={isLoading}
        onSideEffect={eventTracker('open_door_group_filters_panel')}
      >
        <span className="sr-only">Open filters panel</span>
        <FunnelIcon className="h-5 w-5" aria-hidden="true" />
      </Button>
    </div>
  );
}

export default TableHeader;
