import { useIsSupportRole } from '@hakimo-ui/hakimo/util';
import Event from './Event';
import { TIMELINE_HEIGHT, TIMELINE_V_SPACE } from './constants';
import { TimelineSource } from './types';

interface Props {
  sources: TimelineSource[];
  selected: string;
  map: (x: number) => number;
  onChangeSelected: (eventId: string, sourceId: string) => void;
}

export function Events(props: Props) {
  const { sources, selected, map, onChangeSelected } = props;
  const isSupportRole = useIsSupportRole();

  const onEventClick =
    (eventId: string, sourceId: string) =>
    (event: React.MouseEvent<SVGRectElement>) => {
      if (isSupportRole && (event.metaKey || event.ctrlKey)) {
        const url = `/alarms/${eventId}`;
        window.open(url, '_blank', 'noopener,noreferrer');
      } else {
        onChangeSelected(eventId, sourceId);
      }
    };

  return (
    <g className="timelines">
      {sources.map((source, i) => (
        <g
          key={source.id}
          className="timeline"
          transform={`translate(0, ${
            (TIMELINE_HEIGHT + TIMELINE_V_SPACE) * i
          })`}
        >
          {source.events.map((event) => {
            const {
              timespan: [s, e],
            } = event;

            const x1 = map(s);
            const x2 = map(e);
            const startTime = new Date(s).toLocaleTimeString();
            const endTime = new Date(e).toLocaleTimeString();

            return (
              <g key={event.id}>
                <title>
                  {startTime} - {endTime}
                </title>
                <Event
                  selected={selected === event.id}
                  x1={x1}
                  x2={x2}
                  onClick={onEventClick(event.id, source.id)}
                  colorClass={event.colorClass}
                />
              </g>
            );
          })}
        </g>
      ))}
    </g>
  );
}
