import { Alarm } from '@hakimo-ui/hakimo/types';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

export function useOnClickRow() {
  const navigate = useNavigate();

  const onClickRow = useCallback(
    (alarm: Alarm, event: React.MouseEvent | React.KeyboardEvent) => {
      const url = `/alarms/${alarm.id}`;
      const isNewTab = event.ctrlKey || event.metaKey;
      isNewTab
        ? window.open(url, '_blank', 'noopener,noreferrer')
        : navigate(url);
    },
    [navigate]
  );

  return onClickRow;
}
