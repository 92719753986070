import { range } from '@hakimo-ui/hakimo/util';
export const returnPaginationRange = (
  totalPage: number,
  page: number,
  siblings: number,
  isSmallScreen: boolean
) => {
  const totalPageNoInArray = isSmallScreen ? 3 + siblings : 7 + siblings;
  if (totalPageNoInArray >= totalPage) {
    return range(1, totalPage);
  }
  const leftSiblingsIndex = Math.max(page - siblings, 1);
  const rightSiblingsIndex = Math.min(page + siblings, totalPage);
  const showLeftDots = leftSiblingsIndex > 2;
  const showRightDots = isSmallScreen
    ? rightSiblingsIndex < totalPage - 1
    : rightSiblingsIndex < totalPage - 2;
  if (!showLeftDots && showRightDots) {
    const leftItemsCount = isSmallScreen ? 1 + siblings : 3 + 2 * siblings;
    const leftRange = range(1, leftItemsCount + 1);
    return [...leftRange, '...', totalPage];
  } else if (showLeftDots && !showRightDots) {
    const rightItemsCount = isSmallScreen ? 1 + siblings : 3 + 2 * siblings;
    const rightRange = range(totalPage - rightItemsCount, totalPage);
    return [1, '...', ...rightRange];
  } else {
    const middleRange = isSmallScreen
      ? range(page, page + 1)
      : range(leftSiblingsIndex, rightSiblingsIndex + 1);
    return [1, '...', ...middleRange, '...', totalPage];
  }
};
