import { LogoutOptions } from '@auth0/auth0-react';

const broadcastChannel = new global.BroadcastChannel('sync-sessions');

export const sendLogoutEvent = () => {
  broadcastChannel.postMessage({ type: 'logout' });
};

export const listenForLogoutEvents = (
  logout: (options?: LogoutOptions) => void
) => {
  broadcastChannel.addEventListener('message', (event) => {
    if (event.data.type === 'logout') {
      logout({ returnTo: window.location.origin });
    }
  });
};
