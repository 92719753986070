import { ScanEscalation } from '@hakimo-ui/hakimo/types';
import DetailHeader from './DetailHeader';
import EscalationUpdates from './EscalationUpdates';

interface Props {
  escalation: ScanEscalation;
}

export function EscalationDetails(props: Props) {
  const { escalation } = props;

  return (
    <div className="divide-y dark:divide-white/10">
      <DetailHeader escalation={escalation} />
      <div className="flex flex-col gap-4">
        <div className="p-4">
          <h2 className="dark:border-ondark-line-2 border-b p-2">
            Escalation Media
          </h2>

          <div className="flex items-center gap-4 p-4">
            {escalation.media.length === 0 && (
              <div className="bg-onlight-bg-2 dark:bg-ondark-bg-2 flex h-20 w-full items-center justify-center rounded-md">
                <span>No Media attached to this escalation yet</span>
              </div>
            )}
            {escalation.media.map((media) => (
              <video
                key={media.createdAtUTC}
                src={media.mediaUrl}
                autoPlay
                controls
                className="dark:bg-ondark-bg-2 bg-onlight-bg-2 peer aspect-video w-full object-fill"
              ></video>
            ))}
          </div>
          <div>
            <EscalationUpdates escalation={escalation} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default EscalationDetails;
