import { useHttpClient } from '@hakimo-ui/hakimo/util';
import { useState } from 'react';

const voiceId = 'VR6AewLTigWG4xSOukaG';
const baseUrl = 'https://api.elevenlabs.io/v1/text-to-speech';
/**
 * @param tap run any side-effects
 * @returns
 */
export function useTextToSpeech(
  apiKey: string,
  tap?: (response: Response) => void
) {
  const httpClient = useHttpClient();
  const [isLoading, setIsLoading] = useState(false);

  const fetchAudio = (text: string) => {
    setIsLoading(true);
    const url = `${baseUrl}/${voiceId}`;
    const body = {
      text,
      model_id: 'eleven_multilingual_v2',
      voice_settings: {
        similarity_boost: 0.5,
        stability: 0.75,
        use_speaker_boost: true,
      },
    };

    const request = new Request(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'xi-api-key': apiKey,
        Accept: 'audio/mpeg',
      },
      body: JSON.stringify(body),
    });

    return httpClient(request, {
      responseModifier: async (response) => {
        setIsLoading(false);
        const blob = await response.blob();
        const fileUrl = URL.createObjectURL(blob);
        return {
          audioUrl: fileUrl,
          file: new File([blob], Date.now() + '.mpeg'),
        };
      },
      noAuth: true,
      tap,
    });
  };

  return { fetchAudio, isLoading };
}

export default useTextToSpeech;
