import {
  EventFilter,
  FilterConfig,
  FilterValues,
} from '@hakimo-ui/hakimo/types';
import { SlideOver } from '@hakimo-ui/hakimo/ui-elements';
import { useEffect, useState } from 'react';
import FilterFooter from '../filter-footer/FilterFooter';
import FilterGroup from '../filter-group/FilterGroup';

interface Props<T extends Partial<FilterValues>> {
  open: boolean;
  title: string;
  filterConfigs: FilterConfig[];
  initialFilterValues: T;
  trackingEventNameApply: EventFilter;
  trackingEventNameReset: EventFilter;
  onClose: () => void;
  onApply: (values: T) => void;
  enableNegativeFilters?: boolean;
}

export function FilterPanel<T extends Partial<FilterValues>>(props: Props<T>) {
  const {
    open,
    title,
    filterConfigs,
    initialFilterValues,
    trackingEventNameApply,
    trackingEventNameReset,
    onClose,
    onApply,
    enableNegativeFilters = false,
  } = props;
  const [filterValues, setFilterValues] = useState<T>(initialFilterValues);
  // Needed because the "initial filter value" provided can change without unmounting the component
  useEffect(() => setFilterValues(initialFilterValues), [initialFilterValues]);
  return (
    <SlideOver title={title} open={open} onClose={onClose}>
      <div className="flex h-full flex-col space-y-6">
        <div className="dark:divide-dark-border-bg dark:border-dark-border-bg flex-shrink-0 divide-y border-t border-b">
          <FilterGroup
            configs={filterConfigs}
            values={filterValues}
            onChange={setFilterValues}
            enableNegativeFilters={enableNegativeFilters}
          />
        </div>
        <div className="w-full grow"></div>
        <div className="flex-shrink-0">
          <FilterFooter
            onApply={() => onApply(filterValues)}
            onReset={() =>
              setFilterValues(
                filterConfigs.reduce((acc, config) => {
                  return { ...acc, [config.type]: config.defaultValue };
                }, {} as T)
              )
            }
            trackingEventNameApply={trackingEventNameApply}
            trackingEventNameReset={trackingEventNameReset}
          />
        </div>
      </div>
    </SlideOver>
  );
}

export default FilterPanel;
