import {
  createHttpClient,
  HttpClientContext,
  useAuthUtils,
} from '@hakimo-ui/hakimo/util';
import { ReactElement } from 'react';

interface Props {
  children: ReactElement;
}

export function HttpClientProvider(props: Props) {
  const { children } = props;
  const { getAccessToken, logout } = useAuthUtils();
  const httpClient = createHttpClient(getAccessToken, logout);

  return (
    <HttpClientContext.Provider value={httpClient}>
      {children}
    </HttpClientContext.Provider>
  );
}

export default HttpClientProvider;
