import { UpdateProcessingConfigRequest } from '@hakimo-ui/hakimo/types';
import useAuthenticatedMutation from '../shared/useAuthenticatedMutation';

export function useUpdateProcessingConfig(
  doorGroupId: number,
  onSuccess: () => void
) {
  const url = `/v2/orm/door_groups/${String(doorGroupId)}/procConfigs`;

  const request = new Request(url, {
    method: 'PATCH',
  });

  return useAuthenticatedMutation<null, UpdateProcessingConfigRequest>(
    request,
    {
      onSuccessInvalidationKeys: [
        ['door_groups'],
        ['door_groups', String(doorGroupId)],
      ],
      onSuccess,
    }
  );
}

export default useUpdateProcessingConfig;
