import { AddAudioDevicePayload } from '@hakimo-ui/hakimo/types';
import useAuthenticatedMutation from '../shared/useAuthenticatedMutation';

export function useAddAudioDevices(
  onSuccess?: () => void,
  onError?: () => void
) {
  const url = `/v2/orm/audio_devices`;

  const request = new Request(url, {
    method: 'POST',
  });

  return useAuthenticatedMutation<null, AddAudioDevicePayload>(request, {
    onSuccess,
    onSuccessInvalidationKeys: [['audio']],
    onError,
  });
}

export default useAddAudioDevices;
