import { PlaybackMotionMarkers } from '@hakimo-ui/hakimo/types';
import { useCallback, useRef, useState } from 'react';
import { INITIAL_LOAD_LAG } from './constants';
import { useLoadAndUpdateHLS, useSeekerTime, useVideoEnd } from './hooks';
import PlaybackTimeline from './playback-timeline/PlaybackTimeline';
import { CameraHlsAndRef, PlaybackInfo, onMarkerChange } from './utils';
interface Props {
  cameraHlsAndRef: CameraHlsAndRef;
  timeZone: string;
  playbackPeriod: number; // in days
  requestNewVideoUrl: (marker: Date) => void;
  onRetry: () => void;
  playbackMotionMarkers: PlaybackMotionMarkers;
}

export function PlaybackControls(props: Props) {
  const {
    timeZone,
    cameraHlsAndRef,
    requestNewVideoUrl,
    onRetry,
    playbackPeriod,
    playbackMotionMarkers,
  } = props;
  const [seekerTime, setSeekerTime] = useState<Date>();
  const [startDate] = useState(() => {
    const prevDate = new Date();
    prevDate.setDate(prevDate.getDate() - playbackPeriod);
    return prevDate;
  });
  const [endDate] = useState(() => new Date());

  const playbackInfoRef = useRef<PlaybackInfo>({
    playbackStatus: null,
    marker: null,
  });

  /* load next day index when current day video ends*/
  useVideoEnd(cameraHlsAndRef, playbackInfoRef, endDate, requestNewVideoUrl);

  // updates seeker time every 1 second
  useSeekerTime(playbackInfoRef, setSeekerTime, cameraHlsAndRef.hls);

  /**
   * For initial load starting at [currentTime - 15mins]
   * For marker goes to any other date
   */
  useLoadAndUpdateHLS(playbackInfoRef, cameraHlsAndRef.hls);

  //updateVideoRefAndHls on marker change
  const handleMarkerChange = useCallback(
    onMarkerChange(
      cameraHlsAndRef,
      onRetry,
      setSeekerTime,
      playbackInfoRef,
      requestNewVideoUrl
    ),
    [
      cameraHlsAndRef,
      onRetry,
      setSeekerTime,
      playbackInfoRef,
      requestNewVideoUrl,
    ]
  );

  const defaultSeekerTime = new Date();
  defaultSeekerTime.setSeconds(
    defaultSeekerTime.getSeconds() - INITIAL_LOAD_LAG
  );
  return (
    <PlaybackTimeline
      timeZone={timeZone}
      startDate={startDate}
      endDate={endDate}
      seekerTime={seekerTime || defaultSeekerTime}
      playbackMotionMarkers={playbackMotionMarkers}
      onMarkerChange={handleMarkerChange}
    />
  );
}
export default PlaybackControls;
