import { Tooltip } from '@hakimo-ui/shared/ui-base';

interface TimeSectionProps {
  time: string;
  title: string;
  onChange: (newVal: string) => void;
  tooltipText: string;
  isEditing: boolean;
}
export function TimeSection(props: TimeSectionProps) {
  const { time, title, onChange, tooltipText, isEditing } = props;
  // Since Date objects need a date, arbitrarily chose one here to avoid string parsing
  // to convert to 12 hour time
  const timeString = new Date(`2000-01-01 ${time}`).toLocaleTimeString();
  return (
    <label className="my-2 inline">
      <Tooltip text={tooltipText}>
        <span className="dark:text-ondark-text-1 text-onlight-text-2 text-xs">
          {title}
        </span>
      </Tooltip>
      {isEditing ? (
        <input
          type="time"
          step="1"
          className="dark:bg-ondark-bg-1 border-onlight-line-3 dark:border-ondark-line-3 dark:text-ondark-text-1 mt-1 block w-full rounded text-sm dark:[color-scheme:dark]"
          value={time}
          onChange={(e) => onChange(e.currentTarget.value)}
        />
      ) : (
        <div>{timeString}</div>
      )}
    </label>
  );
}
