import clsx from 'clsx';
import { ShownDates, ZoomMode, getShownDates } from './util';

interface Props {
  startDate: Date;
  endDate: Date;
  timeZone: string;
  zoomMode: ZoomMode;
  seekerTime: Date;
  onChangeZoomMode: (mode: ZoomMode) => void;
  onChangeShownDates: (shownDates: ShownDates) => void;
}

export function ZoomActions(props: Props) {
  const {
    zoomMode,
    startDate,
    endDate,
    seekerTime,
    timeZone,
    onChangeZoomMode,
    onChangeShownDates,
  } = props;
  const onZoomIn = () => {
    const newZoomMode =
      zoomMode === ZoomMode.WEEKLY ? ZoomMode.THREE_DAY : ZoomMode.DAILY;
    onChangeZoomMode(newZoomMode);
    onChangeShownDates(
      getShownDates(newZoomMode, startDate, endDate, seekerTime, timeZone)
    );
  };

  const onZoomOut = () => {
    const newZoomMode =
      zoomMode === ZoomMode.DAILY ? ZoomMode.THREE_DAY : ZoomMode.WEEKLY;
    onChangeZoomMode(newZoomMode);
    const newShownDates = getShownDates(
      newZoomMode,
      startDate,
      endDate,
      seekerTime,
      timeZone
    );
    onChangeShownDates(newShownDates);
  };
  return (
    <g
      id="zoom-action"
      className="dark:text-ondark-text-2 stroke-current stroke-[1.5] "
      fill="none"
      style={{ pointerEvents: 'all' }}
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        onClick={onZoomIn}
        viewBox="0 0 24 24"
        className={clsx(
          'hover:stroke-ondark-primary ',
          zoomMode === ZoomMode.DAILY && 'pointer-events-none opacity-50'
        )}
        x="15"
        y="125"
        height="20px"
        width="20px"
      >
        <path d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607zM10.5 7.5v6m3-3h-6" />
      </svg>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        onClick={onZoomOut}
        className={clsx(
          'hover:stroke-ondark-primary',
          zoomMode === ZoomMode.WEEKLY && 'pointer-events-none opacity-50'
        )}
        x="40"
        y="125"
        height="20px"
        width="20px"
      >
        <path d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607zM13.5 10.5h-6" />
      </svg>
    </g>
  );
}

export default ZoomActions;
