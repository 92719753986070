import { UpdateField } from './UpdateField';

interface Props {
  cameraId: string;
  userId?: string;
  onClose: () => void;
}

export function UpdateUserId(props: Props) {
  const { cameraId, onClose, userId } = props;

  return (
    <UpdateField
      cameraId={cameraId}
      name="userId"
      displayName="User ID"
      currentValue={userId}
      onClose={onClose}
    />
  );
}
