import { Timer } from '@hakimo-ui/shared/ui-base';
import clsx from 'clsx';

interface Props {
  totalSteps: number;
  currentStep: number;
  isTimerRunning?: boolean;
  onStepChange: (step: number) => void;
}

export function WorkflowHeader(props: Props) {
  const { totalSteps, currentStep, isTimerRunning, onStepChange } = props;

  const allSteps = new Array(totalSteps).fill(null);

  const onNavClick = (index: number) => () => onStepChange(index);

  return (
    <div className="space-y-8">
      <nav className="flex items-center justify-center" aria-label="Progress">
        <ol className="flex items-center space-x-5">
          {allSteps.map((_, i) => (
            <li key={i} className={clsx(i === currentStep && 'relative')}>
              {i === currentStep ? (
                <span
                  className="relative flex items-center justify-center"
                  aria-current="step"
                >
                  <span
                    className="absolute flex h-5 w-5 p-px"
                    aria-hidden="true"
                  >
                    <span className="h-full w-full rounded-full bg-indigo-200 dark:bg-indigo-400"></span>
                  </span>
                  <span
                    className="bg-primary-500 dark:bg-primary-600 relative block h-2.5 w-2.5 rounded-full"
                    aria-hidden="true"
                  ></span>
                </span>
              ) : (
                <span
                  onClick={i <= currentStep ? onNavClick(i) : undefined}
                  className={clsx(
                    'block h-2.5 w-2.5 rounded-full',
                    i > currentStep &&
                      'bg-onlight-text-3 dark:bg-ondark-text-2 dark:hover:bg-ondark-text-3 hover:bg-onlight-text-2',
                    i <= currentStep &&
                      'bg-primary-500 dark:bg-primary-600 dark:hover:bg-primary-800 hover:bg-primary-700'
                  )}
                ></span>
              )}
            </li>
          ))}
        </ol>
      </nav>
      {isTimerRunning && (
        <div className="absolute -top-16 -left-4">
          <Timer isRunning={isTimerRunning} />
        </div>
      )}
    </div>
  );
}

export default WorkflowHeader;
