import { Point } from '@hakimo-ui/hakimo/types';
import {
  Button,
  StackedCardOption,
  StackedCards,
} from '@hakimo-ui/shared/ui-base';
import { PlusIcon } from '@heroicons/react/24/solid';
import LabelPanelFooter from './LabelPanelFooter';
import { getStackedCardOptions } from './util';

interface Props {
  label: string;
  zones: Point[][];
  partialZone?: Point[];
  selected?: string;
  isLoading: boolean;
  onClickReset: () => void;
  onClickClear: () => void;
  onClickSave: () => void;
  onDeleteZone: (id: string) => void;
  onNewZone: () => void;
  onSelect: (id: string) => void;
  canUpdateZones: boolean;
}

function LabelPanel(props: Props) {
  const {
    zones,
    onClickReset,
    onClickClear,
    onClickSave,
    onNewZone,
    isLoading,
    selected,
    onSelect,
    onDeleteZone,
    partialZone,
    canUpdateZones,
    label,
  } = props;
  const options = getStackedCardOptions(
    zones,
    onDeleteZone,
    canUpdateZones,
    label
  );
  const onChange = (option: StackedCardOption) => {
    onSelect(option.id);
  };
  const selectedZone = selected
    ? options.find((val) => val.id === selected)
    : undefined;
  return (
    <>
      <div className="mb-6">
        <StackedCards
          label={label}
          options={options}
          selected={selectedZone}
          onChange={onChange}
        />
        {canUpdateZones && (
          <div className="mt-6">
            <Button onClick={onNewZone} disabled={isLoading} block>
              {partialZone ? 'Labelling' : <PlusIcon className="w-5" />}
            </Button>
          </div>
        )}
      </div>
      {canUpdateZones && (
        <LabelPanelFooter
          isLoading={isLoading}
          onClickSave={onClickSave}
          onClickReset={onClickReset}
          onClickClear={onClickClear}
        />
      )}
    </>
  );
}

export default LabelPanel;
