import { useCallback, useEffect, useState } from 'react';

export function useFullscreen(containerRef: React.RefObject<HTMLDivElement>) {
  const [isFullScreen, setIsFullScreen] = useState<boolean>(false);

  const toggleFullScreen = useCallback(() => {
    const container = containerRef.current;
    if (document.fullscreenElement) {
      document.exitFullscreen();
    } else {
      container && container.requestFullscreen();
    }
  }, [containerRef]);

  useEffect(() => {
    const container = containerRef.current;
    const handleFullscreenChange = () => {
      setIsFullScreen(!!document.fullscreenElement);
    };
    if (container) {
      container.addEventListener('fullscreenchange', handleFullscreenChange);
    }

    return () => {
      container &&
        container?.removeEventListener(
          'fullscreenchange',
          handleFullscreenChange
        );
    };
  }, [containerRef, toggleFullScreen]);

  return { isFullScreen, toggleFullScreen };
}
