import {
  ROLE_MSP_OPERATOR,
  useCanViewLocationAlarm,
  useUser,
} from '@hakimo-ui/hakimo/util';
import { Navigate } from 'react-router-dom';

export function DefaultRoute() {
  const user = useUser();
  const canViewLocationAlarms = useCanViewLocationAlarm();

  if (user.roles[0] === ROLE_MSP_OPERATOR) {
    return <Navigate to="/monitoring" replace />;
  }

  if (canViewLocationAlarms) {
    return <Navigate to="/location-alarms" replace />;
  }

  return <Navigate to="/alarms" replace />;
}
