import { Button, Modal } from '@hakimo-ui/shared/ui-base';
import { ReactNode } from 'react';

interface Props {
  open: boolean;
  onCloseCb: () => void;
  content: ReactNode;
}

export function DetailsModal(props: Props) {
  const { content, onCloseCb, open } = props;
  const actions = <Button onClick={onCloseCb}>Close</Button>;
  return (
    <Modal
      open={open}
      onClose={onCloseCb}
      title="Talkdown Status"
      footer={actions}
    >
      <div className="p-8">{content}</div>
    </Modal>
  );
}
