import { useTwilioToken } from '@hakimo-ui/hakimo/data-access';

import {
  CallEndIcon,
  CallingIcon,
  DialpadIcon,
} from '@hakimo-ui/hakimo/ui-elements';
import { toast } from '@hakimo-ui/hakimo/util';
import { Button, HakimoSpinner } from '@hakimo-ui/shared/ui-base';
import { Call, Device } from '@twilio/voice-sdk';
import { useEffect, useState } from 'react';
import DraggableHOC from '../draggable-hoc/DraggableHOC';
import Keypad from './Keypad';

interface Props {
  toName: string;
  toNumber: string;
  onCloseCb: () => void;
  locationTenantId?: string;
  onCallInitiated?: (call: Call) => void;
  onCallDisconnected?: (call: Call) => void;
  onErrorCb?: (err: Error) => void;
}

export function TwilioCall(props: Props) {
  const {
    toName,
    toNumber,
    onCloseCb,
    locationTenantId,
    onCallInitiated,
    onCallDisconnected,
    onErrorCb,
  } = props;
  const [showKeypad, setShowKeypad] = useState(false);
  const [device, setDevice] = useState<Device>();
  const [call, setCall] = useState<Call>();
  const [twilioToken, setTwilioToken] = useState('');
  const [callInProgress, setCallInProgress] = useState(false);
  const [deviceConnected, setDeviceConnected] = useState(false);

  const onSuccess = (token: string) => setTwilioToken(token);
  const onError = (err: Error) => {
    toast("Couldn't connect call - " + err.message, { type: 'error' });
    onErrorCb && onErrorCb(err);
    onCloseCb();
  };

  useTwilioToken(locationTenantId, onSuccess, onError);

  useEffect(() => {
    if (!twilioToken) return;
    if (!device) {
      import('@twilio/voice-sdk').then((twilio) => {
        setDevice(
          new twilio.Device(twilioToken, {
            codecPreferences: [Call.Codec.Opus, Call.Codec.PCMU],
          })
        );
      });
    } else {
      device.updateToken(twilioToken);
      device?.on('error', (_, erroredCall) => {
        erroredCall?.disconnect();
      });
    }
  }, [device, setDevice, twilioToken]);

  useEffect(() => {
    if (!device || deviceConnected) return;
    if (!deviceConnected) {
      setDeviceConnected(true);
    }
    call?.disconnect();
    const connectOptions = { To: toNumber };
    setDeviceConnected(true);
    device?.connect({ params: connectOptions }).then((c) => {
      setCall(c);

      c?.on('accept', () => {
        setCallInProgress(true);
        onCallInitiated && onCallInitiated(c);
      });
      c?.on('disconnect', () => {
        setCallInProgress(false);
        toast('Call ended');
        onCallDisconnected && onCallDisconnected(c);
        onCloseCb();
      });
    });
  }, [
    call,
    device,
    deviceConnected,
    onCallDisconnected,
    onCallInitiated,
    onCloseCb,
    toName,
    toNumber,
  ]);

  const closeAndHangup = () => {
    call?.disconnect();
    onCloseCb();
  };

  const onClickKeypad = () => setShowKeypad((prev) => !prev);

  return (
    <DraggableHOC>
      <div className="bg-onlight-bg-2 dark:bg-ondark-bg-2 dark:divide-ondark-line-2 space-y-4 divide-y py-4 px-6">
        <div className="flex items-center gap-4">
          <CallingIcon className="dark:fill-ondark-text-1 h-8 w-8" />
          <div className="flex flex-col gap-2 text-sm">
            <span>{toName}</span>
            <span className="text-xs">{toNumber}</span>
          </div>
        </div>
        {callInProgress ? (
          <div className="flex justify-evenly pt-4">
            <Button
              variant="icon"
              onClick={onClickKeypad}
              title={`${showKeypad ? 'Hide' : 'Show'} Keypad`}
            >
              <DialpadIcon className="dark:fill-ondark-text-1 h-8 w-8" />
            </Button>
            <Button variant="icon" title="End call" onClick={closeAndHangup}>
              <CallEndIcon className="h-10 w-10 fill-red-500" />
            </Button>
          </div>
        ) : (
          <div className="flex h-24 flex-col items-center justify-center gap-2">
            <HakimoSpinner />
            <span className="animate-pulse">Calling...</span>
          </div>
        )}

        {showKeypad && (
          <div>
            <Keypad onKeypadButtonClick={(digit) => call?.sendDigits(digit)} />
          </div>
        )}
      </div>
    </DraggableHOC>
  );
}

export default TwilioCall;
