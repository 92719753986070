import { GroupPriority, ProcConfig } from '@hakimo-ui/hakimo/types';

export const DEFAULT_PROC_CONF: ProcConfig = {
  id: -1,
  name: 'New config',
  description: 'New config description',
  updated: true,
  enableTailgating: false,
  processVideo: false,
  activeLocalTimeStart: '00:00:00',
  activeLocalTimeEnd: '00:00:00',
  priority: GroupPriority.default,
  activeDays: [true, true, true, true, true, true, true],
  isNew: true,
  deleted: false,
  isEditing: false,
  enableForAllAlarmType: true,
  alarm_types: null,
  enableUnauthorizedEntryAlarm: false,
};

export const ERROR_MSG =
  'Error modifying processing configurations. Please double check that configurations are valid.';
