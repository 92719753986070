import { UpdateField } from './UpdateField';

interface Props {
  cameraId: string;
  livestreamUrl?: string;
  onClose: () => void;
}

export function UpdateLivestreamUrl(props: Props) {
  const { cameraId, onClose, livestreamUrl } = props;

  return (
    <UpdateField
      cameraId={cameraId}
      name="livestreamUrl"
      displayName="Livestream URL"
      currentValue={livestreamUrl}
      onClose={onClose}
    />
  );
}
