export interface MsePlayer {
  startPlay(url: string): void;
  stopPlay(): void;
}

export function createMsePlayer(videoEl: HTMLVideoElement): MsePlayer {
  const mseQueue: ArrayBuffer[] = [];
  let mseSourceBuffer: SourceBuffer;
  let mseStreamingStarted = false;
  let ws: WebSocket;

  function pauseEventListener() {
    if (
      videoEl.currentTime > videoEl.buffered.end(videoEl.buffered.length - 1)
    ) {
      videoEl.currentTime =
        videoEl.buffered.end(videoEl.buffered.length - 1) - 0.1;
      videoEl.play();
    }
  }

  function startPlay(url: string): void {
    const mse = new MediaSource();
    videoEl.src = window.URL.createObjectURL(mse);
    mse.addEventListener(
      'sourceopen',
      function () {
        ws = new WebSocket(url);
        ws.binaryType = 'arraybuffer';
        // ws.onopen = function (_event: Event): void {
        //   // eslint-disable-next-line no-console
        //   console.log('Connect to ws');
        // };
        ws.onmessage = function (event: MessageEvent): void {
          const data = new Uint8Array(event.data);
          if (data[0] === 9) {
            const decodedArr = data.slice(1);
            const mimeCodec = new TextDecoder('utf-8').decode(decodedArr);
            mseSourceBuffer = mse.addSourceBuffer(
              'video/mp4; codecs="' + mimeCodec + '"'
            );
            mseSourceBuffer.mode = 'segments';
            mseSourceBuffer.addEventListener('updateend', pushPacket);
          } else {
            readPacket(event.data);
          }
        };
      },
      false
    );
  }

  function stopPlay(): void {
    if (ws) {
      ws.close();
    }
    videoEl.removeEventListener('pause', pauseEventListener);
  }

  function readPacket(packet: ArrayBuffer): void {
    if (!mseStreamingStarted) {
      mseSourceBuffer.appendBuffer(packet);
      mseStreamingStarted = true;
      return;
    }
    mseQueue.push(packet);
    if (!mseSourceBuffer.updating) {
      pushPacket();
    }
  }

  function pushPacket(): void {
    if (!mseSourceBuffer.updating) {
      if (mseQueue.length > 0) {
        const packet = mseQueue.shift();
        packet && mseSourceBuffer.appendBuffer(packet);
      } else {
        mseStreamingStarted = false;
      }
    }
    if (videoEl.buffered.length > 0) {
      if (typeof document.hidden !== 'undefined' && document.hidden) {
        videoEl.currentTime =
          videoEl.buffered.end(videoEl.buffered.length - 1) - 0.5;
      }
    }
  }

  videoEl.addEventListener('pause', pauseEventListener);

  return {
    startPlay,
    stopPlay,
  };
}
