import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

export function useOnClickRow() {
  const navigate = useNavigate();

  const onClickRow = useCallback(
    (url: string, event: React.MouseEvent | React.KeyboardEvent) => {
      const isNewTab = event.ctrlKey || event.metaKey;
      isNewTab
        ? window.open(url, '_blank', 'noopener,noreferrer')
        : navigate(url);
    },
    [navigate]
  );

  return onClickRow;
}
