import clsx from 'clsx';
import { ForwardedRef, ReactElement, forwardRef } from 'react';

interface SplitViewProps {
  children: ReactElement | null;
}

export function SplitView(props: SplitViewProps) {
  return props.children;
}

interface Props {
  children: ReactElement<SplitViewProps>[];
  classnames?: string;
}

export const SplitViewWrapper = forwardRef(function SplitViewWrapper(
  props: Props,
  ref: ForwardedRef<HTMLDivElement>
) {
  const { children, classnames = '' } = props;

  return (
    <div
      ref={ref}
      className={clsx(
        'bg-onlight-bg-1 dark:bg-ondark-bg-1 flex h-full divide-x overflow-y-auto dark:divide-white/10',
        classnames
      )}
    >
      <div className="overflow-y-auto">{children[0]}</div>
      <div className="flex-1 overflow-auto">{children[1]}</div>
    </div>
  );
});

export default SplitViewWrapper;
