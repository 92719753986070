import { TimePeriod } from '@hakimo-ui/hakimo/types';
import { atom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';
import { ViewMode } from './alarm-overview/types';
import { defaultFilterValues } from './constants';

export const timePeriodAtom = atomWithStorage<TimePeriod>(
  'locationAlarms/timePeriod',
  TimePeriod.PAST_24_HOURS
);

export const alarmReturnPostResolveAtom = atomWithStorage<boolean>(
  'alarmReturnPostResolve',
  false
);

export const pageAtom = atom<number>(1);
export const pageSizeAtom = atom<number>(30);

export const viewModeAtom = atomWithStorage<ViewMode>(
  'locationAlarms/viewMode',
  'split'
);

export const alarmFiltersAtom = atomWithStorage(
  'locationAlarms/filters',
  defaultFilterValues
);

type AlarmId = string;
type Timestamp = number;

export const locationAlarmsRenderedTimeAtom = atom<Record<AlarmId, Timestamp>>(
  {}
);
