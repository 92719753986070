/* eslint-disable max-lines */
import { Camera } from '@hakimo-ui/hakimo/types';
import { EventHistory, VisionEventMetadata } from '../../types/event';

export const SNOOZE_TIME = 60; // in seconds

export const TIMELINE_HEIGHT = 12;
export const HOVER_MARGIN_TOP = 24;

const RETENTION_PERIOD = 10 * 60 * 1000; // 10 minutes in milliseconds

export const calculateNewLayout = (
  cameraCount: number,
  rows: number,
  cols: number
) => {
  while (rows * cols < cameraCount) {
    if (cols > rows) {
      rows++;
    } else {
      cols++;
    }
  }
  return { rows, cols };
};

export const getNullRowsOrCols = (
  items: (Camera | null)[],
  rows: number,
  cols: number
) => {
  const nullRows = [];
  const nullCols = [];

  // Check each row to see if all items are null
  for (let row = 0; row < rows; row++) {
    const start = row * cols;
    const end = start + cols;
    const rowItems = items.slice(start, end);

    if (rowItems.every((item) => item === null)) {
      nullRows.push(row);
    }
  }

  // Check each column to see if all items are null
  for (let col = 0; col < cols; col++) {
    let colAllNull = true;

    for (let row = 0; row < rows; row++) {
      const index = row * cols + col;

      if (items[index] !== null) {
        colAllNull = false;
        break;
      }
    }

    if (colAllNull) {
      nullCols.push(col);
    }
  }
  return {
    nullRow: nullRows.length > 0 ? nullRows[0] : undefined,
    nullCol: nullCols.length > 0 ? nullCols[0] : undefined,
  };
};

export const removeColOrRow = (
  items: (Camera | null)[],
  rows: number,
  cols: number,
  index: number,
  isRow = true
) => {
  const newItems = [...items];

  if (isRow) {
    // Remove all items from the specified row
    const start = index * cols;
    newItems.splice(start, cols);
  } else {
    // Remove all items from the specified column
    for (let row = rows - 1; row >= 0; row--) {
      const itemIndex = row * cols + index;
      newItems.splice(itemIndex, 1);
    }
  }

  return newItems;
};

export const getTenantColor = (tenantId: string) => {
  const colors = [
    '#40C4FF', // Bright light blue
    '#81C784', // Brighter green
    '#4DD0E1', // Brighter cyan
    '#FF8A65', // Brighter coral
    '#FFE57F', // Bright amber
    '#FF8A65', // Brighter orange
    '#FF80AB', // Brighter pink
    '#FFD54F', // Bright yellow
    '#4DB6AC', // Brighter teal
  ];
  const hash = tenantId
    .split('')
    .reduce((acc, char) => char.charCodeAt(0) + acc, 0);
  return colors[hash % colors.length];
};

export const getImageBlob = (frameHexCode?: string): string => {
  if (!frameHexCode) {
    return '';
  }
  const matchString = frameHexCode.match(/.{1,2}/g);
  if (!matchString) {
    return '';
  }
  const frameBytes = new Uint8Array(
    matchString.map((byte: string) => parseInt(byte, 16))
  );

  // Create a Blob from the bytes
  const blob = new Blob([frameBytes], { type: 'image/webp' });

  // Create an Object URL from the Blob
  const url = URL.createObjectURL(blob);
  return url;
};

export const getPersonIds = (eventMetadata: VisionEventMetadata) => {
  if (
    !eventMetadata ||
    !eventMetadata.persons ||
    eventMetadata.persons.length === 0
  ) {
    return [];
  }
  return eventMetadata.persons.map((p) => p.person_id);
};

export const removeOldHistoryEvents = (
  camToEventHistoryMap: Record<string, EventHistory[]>,
  setCamToEventHistoryMap: React.Dispatch<
    React.SetStateAction<Record<string, EventHistory[]>>
  >
) => {
  setTimeout(() => {
    const now = Date.now();
    const updatedMap: Record<string, EventHistory[]> = {};
    let hasChanges = false;

    for (const [camId, events] of Object.entries(camToEventHistoryMap)) {
      const cutoffIndex = events.findIndex(
        (event) => now - event.createdTime <= RETENTION_PERIOD
      );

      if (cutoffIndex === -1) {
        // All events are old, remove this camera from the map
        hasChanges = true;
      } else if (cutoffIndex > 0) {
        // Some events are old, keep only the recent ones
        updatedMap[camId] = events.slice(cutoffIndex);
        hasChanges = true;
      } else {
        // All events are recent, keep them all
        updatedMap[camId] = events;
      }
    }

    if (hasChanges) {
      setCamToEventHistoryMap(updatedMap);
    }
  }, 5);
};
