import { NeighboringAlarmsDTO, ReducedAlarm } from '@hakimo-ui/hakimo/types';
import { TableData } from '@hakimo-ui/hakimo/ui-table';
import React from 'react';

export function getTableData(
  nbrAlarms: NeighboringAlarmsDTO,
  alarm: ReducedAlarm,
  onClickRow?: (
    alarm: ReducedAlarm,
    events: React.MouseEvent | React.KeyboardEvent
  ) => void
) {
  const alarms = nbrAlarms.items.slice();
  alarms.splice(nbrAlarms.curIdx, 0, alarm);

  const tableData: TableData = {
    columns: [
      {
        id: 'alarmType',
        name: 'Type',
      },
      {
        id: 'timestamp',
        name: 'Time',
      },
    ],
    rows: alarms.map((curAlarm, idx) => {
      return {
        onClick:
          onClickRow &&
          ((event: React.MouseEvent | React.KeyboardEvent) =>
            onClickRow(curAlarm, event)),
        id: `${curAlarm.id}`,
        highlighted: idx === nbrAlarms.curIdx,
        cells: [
          {
            value: curAlarm.type,
          },
          {
            value: new Date(curAlarm.timestamp).toLocaleString(),
          },
        ],
      };
    }),
  };

  return tableData;
}
