import { AuditLog } from '@hakimo-ui/hakimo/types';
import { Column } from '@hakimo-ui/hakimo/ui-table';

export const columns: Column[] = [
  {
    id: 'activity',
    name: 'Activity',
  },
  {
    id: 'timestamp',
    name: 'Timestamp',
    showAtBreakpoint: 'lg',
  },
];

export function getTableData(items: AuditLog[]) {
  return {
    columns,
    rows: items.map((item) => ({
      id: item.id,
      cells: [{ value: item.activity }, { value: item.timestamp }],
    })),
  };
}

export function getSearchParams(page: number, pageSize: number): string {
  const params = new URLSearchParams();
  params.append('page', String(page));
  params.append('pageSize', String(pageSize));
  return params.toString();
}
