import useAuthenticatedMutation from '../shared/useAuthenticatedMutation';

export function useViewLocationAlarm(
  onSuccess?: (data: null, variables: { alarmId: string }) => void
) {
  const url = `/v2/orm/location_alarms/view`;

  const request = new Request(url, {
    method: 'POST',
  });

  return useAuthenticatedMutation<null, { alarmId: string }>(request, {
    onSuccessInvalidationKeys: [['locationAlarms'], ['location_alarm_updates']],
    onSuccess,
  });
}

export default useViewLocationAlarm;
