import {
  AlarmStatus,
  LocationAlarmStatus,
  StatusFilterOption,
  StatusFilterValue,
} from '@hakimo-ui/hakimo/types';
import { MultiSelect } from '@hakimo-ui/hakimo/ui-elements';
import { getMultiSelectItems, selectHelpers } from '@hakimo-ui/hakimo/util';
import { Label, Selectable, Toggle } from '@hakimo-ui/shared/ui-base';
import { EyeSlashIcon } from '@heroicons/react/24/outline';

interface Props<T extends AlarmStatus | LocationAlarmStatus> {
  options: StatusFilterOption<T>[];
  value: StatusFilterValue<T>;
  onChange: (value: StatusFilterValue<T>) => void;
  enableNegativeFilters: boolean;
}

function StatusFilter<T extends AlarmStatus | LocationAlarmStatus>(
  props: Props<T>
) {
  const { options, value: status, onChange, enableNegativeFilters } = props;
  const selected = options.filter((option) =>
    status.values.includes(option.name)
  );

  const onChangeSelected = (updated: StatusFilterOption<T>[]) => {
    onChange({
      negative: status.negative,
      values: updated.map((option) => option.name),
    });
  };
  const onChangeNegative = (neg: boolean) => {
    onChange({ ...status, negative: neg });
  };

  const getItems = (query: string) => {
    const { filterPredicate, isEqual } = selectHelpers;

    return getMultiSelectItems(query, selected, filterPredicate, isEqual, {
      staticItems: options,
    });
  };
  const onRemoveSelected = (index: number) => {
    onChangeSelected([
      ...selected.slice(0, index),
      ...selected.slice(index + 1),
    ]);
  };
  const displayValue = (item: Selectable) => item?.name;
  const getId = (item: Selectable) => item.id;
  const customSelectedRenderer = (items: Selectable[]) => {
    return (
      <div className="flex flex-wrap gap-1">
        {items.map((item, idx) => (
          <span key={getId(item) || idx}>
            <Label
              text={displayValue(item)}
              removable
              onClickRemove={() => onRemoveSelected(idx)}
              type={status.negative ? 'error' : 'default'}
            />
          </span>
        ))}
      </div>
    );
  };

  return (
    <>
      {enableNegativeFilters && (
        <div className="pb-2">
          <Toggle
            enabled={status.negative}
            onChange={onChangeNegative}
            type="negative"
            EnabledIcon={EyeSlashIcon}
            label="Negative Filtering"
          />
        </div>
      )}
      <MultiSelect
        label="Alarm Status"
        value={selected}
        onChange={onChangeSelected}
        getItems={getItems}
        displayValue={displayValue}
        id={getId}
        customSelectedRenderer={customSelectedRenderer}
      />
    </>
  );
}

export default StatusFilter;
