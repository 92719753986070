import { SiteWatchLive } from '@hakimo-ui/hakimo/feature-shared';
import { Location } from '@hakimo-ui/hakimo/types';
import { MultiLiveViewIcon } from '@hakimo-ui/hakimo/ui-elements';
import { useFullscreen } from '@hakimo-ui/hakimo/util';
import { Button } from '@hakimo-ui/shared/ui-base';
import { useRef } from 'react';

interface Props {
  location: Location;
  initalCameraIds: string[];
}

export function CamSurvelliance(props: Props) {
  const { location, initalCameraIds } = props;
  const containerRef = useRef<HTMLDivElement>(null);
  const { isFullScreen, toggleFullScreen } = useFullscreen(containerRef);

  return (
    <div
      ref={containerRef}
      className="bg-onlight-bg-1 dark:bg-ondark-bg-1 overflow-auto"
    >
      {isFullScreen ? (
        <SiteWatchLive
          locationId={String(location.id)}
          locationName={location.name}
          initialCameraIds={initalCameraIds}
          onClose={toggleFullScreen}
        />
      ) : (
        <Button
          variant="icon"
          onClick={toggleFullScreen}
          title="Site Watch survilliance"
        >
          <MultiLiveViewIcon className="h-6 w-6" />
        </Button>
      )}
    </div>
  );
}

export default CamSurvelliance;
