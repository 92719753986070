import {
  AudioRecorder,
  AutoTextToSpeech,
} from '@hakimo-ui/hakimo/feature-shared';
import { SOPStep, SOPStepType } from './util';

interface Props {
  sopItem: SOPStep;
  cameraName?: string;
  cameraId: string;
  logTalkdown: (cameraId: string, alarmId?: string) => void;
  performTalkdown: (audioFile: File) => void;
  isTalkdownInProcess?: boolean;
}

export function WorkflowBody(props: Props) {
  const {
    sopItem,
    performTalkdown,
    cameraId,
    logTalkdown,
    cameraName,
    isTalkdownInProcess,
  } = props;

  const title =
    sopItem.type === SOPStepType.QUESTION
      ? sopItem.exceptions
        ? 'Exceptions'
        : ''
      : 'Perform the talkdown';

  return (
    <div className="py-2">
      <div className="text-primary-800 dark:text-primary-400 my-2">{title}</div>
      {sopItem.type === SOPStepType.QUESTION && (
        <div>
          <div>
            {sopItem.exceptions && (
              <div className="text-onlight-text-2 dark:text-ondark-text-2 my-2 space-y-1 text-sm italic">
                {sopItem.exceptions}
              </div>
            )}
          </div>
          <div>
            {sopItem.notes && (
              <>
                <div className="text-primary-800 dark:text-primary-400 my-2">
                  Notes
                </div>
                <div className="text-onlight-text-2 dark:text-ondark-text-2 my-2 space-y-1 text-sm italic">
                  {sopItem.notes}
                </div>
              </>
            )}
          </div>
        </div>
      )}
      {sopItem.type === SOPStepType.STATIC_TALKDOWN && (
        <div className="text-sm italic">
          <AutoTextToSpeech
            text={sopItem.talkdownText || ''}
            performTalkdown={performTalkdown}
            isLoading={isTalkdownInProcess}
          />
        </div>
      )}
      {sopItem.type === SOPStepType.DYANMIC_TALKDOWN && (
        <div className="flex flex-wrap items-center justify-around gap-2 text-sm">
          <div className="text-primary-800 dark:text-primary-400 my-2">
            Assess identification details such as clothes color and perform the
            talkdown.
          </div>
          <span className="italic">{sopItem.talkdownText || ''}</span>
          <AudioRecorder
            cameraId={cameraId}
            cameraName={cameraName}
            onTalkdown={logTalkdown}
          />
        </div>
      )}
    </div>
  );
}
