/* eslint-disable max-lines */
import { Marker, Point, PointSize, Polygon } from '@hakimo-ui/hakimo/types';
import clsx from 'clsx';
import { scaleLinear } from 'd3-scale';
import { line } from 'd3-shape';
import { getMarkerColor } from '../common-util';
import MarkerIcon from './MarkerIcon';

export function normalizePoint(point: Point): Point {
  return [normalizeMarkerValue(point[0]), normalizeMarkerValue(point[1])];
}

export function normalizeMarkerValue(val: number) {
  return Math.max(0, Math.ceil(val));
}

export function getImageCoordinatesLabel(point?: Point | null) {
  if (!point) {
    return '-';
  }
  return `${normalizeMarkerValue(point[0])}, ${normalizeMarkerValue(point[1])}`;
}

export function clientMarkerToImageMarker(
  displaySize: Point,
  imgSize: Point,
  imgTopLeft: Point,
  marker: Marker
): Marker {
  const scaleX = scaleLinear()
    .domain([0, displaySize[0]])
    .range([0, imgSize[0]]);

  const scaleY = scaleLinear()
    .domain([0, displaySize[1]])
    .range([0, imgSize[1]]);

  const markerImageRect = [
    marker.point[0] - imgTopLeft[0],
    marker.point[1] - imgTopLeft[1],
  ];

  const markerImage: Marker = {
    ...marker,
    point: [scaleX(markerImageRect[0]), scaleY(markerImageRect[1])],
  };

  return markerImage;
}

export function imageMarkerToClientMarker(
  displaySize: Point,
  imgSize: Point,
  marker: Marker
): Marker {
  const scaleX = scaleLinear()
    .domain([0, imgSize[0]])
    .range([0, displaySize[0]]);

  const scaleY = scaleLinear()
    .domain([0, imgSize[1]])
    .range([0, displaySize[1]]);

  const markerImageRect: Point = [
    scaleX(marker.point[0]),
    scaleY(marker.point[1]),
  ];
  const markerClient: Marker = {
    ...marker,
    point: [markerImageRect[0], markerImageRect[1]],
  };
  return markerClient;
}
function getMarkerStyleFromSize(size?: PointSize): [number, string] {
  switch (size) {
    case 'small':
      return [6, 'w-6'];
    case 'large':
      return [16, 'w-16'];
    default:
      return [12, 'w-12'];
  }
}
export function getPolygonDrawings(
  polygons: Polygon[],
  onClickShape?: React.MouseEventHandler<HTMLOrSVGElement>
) {
  return polygons.map((polygon, polyIndex) => {
    return polygon.points.map((marker, markerIndex) => {
      const [size, style] = getMarkerStyleFromSize(polygon.pointSize);
      return (
        <MarkerIcon
          key={`${polyIndex}-${markerIndex}`}
          style={{
            left: marker[0] - 2 * size,
            top: marker[1] - 2 * size,
          }}
          className={clsx(
            'absolute',
            style,
            `text-${getMarkerColor(polygon.color)}`
          )}
          onClick={onClickShape}
        />
      );
    });
  });
}
const lineGenerator = line();

export function getPolygonLines(polygons: Polygon[]) {
  const boundingPathData = polygons.map((polygon) => {
    return {
      path:
        (polygon.points
          ? lineGenerator(polygon.points) +
            (polygon.closed === undefined || polygon.closed ? 'z' : '')
          : '') ?? '',
      color: polygon.color,
      tag: polygon.tag,
      tagCoord: [
        Math.max(...polygon.points.map((point) => point[0])),
        Math.min(...polygon.points.map((point) => point[1])),
      ],
    };
  });
  return (
    <svg className="pointer-events-none absolute left-0 top-0 h-full w-full">
      {boundingPathData.map((path, pathIndex) => {
        return (
          <g key={String(pathIndex)}>
            <path
              d={path.path}
              className={clsx(
                `fill-transparent stroke-2`,
                `stroke-${getMarkerColor(path.color)}`
              )}
            />
            {path.tag && (
              <text
                x={path.tagCoord[0]}
                y={path.tagCoord[1]}
                textAnchor="end"
                dx={0}
                dy={-4}
                className={`fill-${getMarkerColor(path.color)} text-[10px]`}
              >
                {path.tag}
              </text>
            )}
          </g>
        );
      })}
    </svg>
  );
}

export function getMarkerDrawings(
  markers?: Marker[],
  onClickShape?: React.MouseEventHandler<HTMLOrSVGElement>
) {
  return markers?.map((marker, pointIndex) => {
    const [size, style] = getMarkerStyleFromSize(marker.size);
    return (
      <MarkerIcon
        key={String(pointIndex)}
        style={{
          left: marker.point[0] - size * 2,
          top: marker.point[1] - size * 2,
        }}
        className={clsx(
          'absolute',
          style,
          'text-' + getMarkerColor(marker.color)
        )}
        onClick={onClickShape}
      />
    );
  });
}
