import { Alarm } from '@hakimo-ui/hakimo/types';
import { BasicPanel, DescriptionList } from '@hakimo-ui/hakimo/ui-elements';
import { buildDLItems } from './util';

interface Props {
  alarm: Alarm;
}

function AlarmSummary(props: Props) {
  const { alarm } = props;

  return (
    <BasicPanel title="Overview">
      <div className="min-h-[20rem] px-6 pb-4">
        <DescriptionList items={buildDLItems(alarm)} align="vertical" />
      </div>
    </BasicPanel>
  );
}

export default AlarmSummary;
