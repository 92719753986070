import { useAlarm } from '@hakimo-ui/hakimo/data-access';
import { NotFound, QueryResult } from '@hakimo-ui/hakimo/ui-elements';
import { withAuthz, withErrorBoundary } from '@hakimo-ui/hakimo/util';
import { useParams } from 'react-router-dom';
import AlarmDetails from './AlarmDetails';

type Params = {
  alarmId: string;
};

interface Props {
  alarmId: string;
}

function AlarmDetailsData(props: Props) {
  const { alarmId } = props;

  const queryResult = useAlarm(alarmId);
  const { refetch } = queryResult;

  return (
    <QueryResult queryResult={queryResult}>
      {(data) => <AlarmDetails alarm={data} onRefresh={() => refetch()} />}
    </QueryResult>
  );
}

function AlarmDetailsRoot() {
  const { alarmId } = useParams<Params>();

  if (alarmId === undefined) {
    return <NotFound />;
  }

  return <AlarmDetailsData alarmId={alarmId} />;
}

export default withAuthz(withErrorBoundary(AlarmDetailsRoot), [
  'alarm/details:view',
]);
