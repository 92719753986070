import {
  NEGATIVE_FILTER_TOKEN,
  SEPARATOR,
} from '@hakimo-ui/hakimo/data-access';
import {
  FilterValues,
  TimeFilterValue,
  TimePeriod,
} from '@hakimo-ui/hakimo/types';
import { useMemo } from 'react';

function getFilterQuery(values: string[], negative: boolean): string {
  const query = values;
  if (negative) {
    query.push(NEGATIVE_FILTER_TOKEN);
  }
  return query.join(SEPARATOR);
}

function getStartEndTimes(time: TimeFilterValue): [number, number] {
  let endTime: number;
  let startTime: number;

  if (time.period === TimePeriod.CUSTOM_RANGE) {
    startTime = new Date(time.customRangeStart).getTime();
    endTime = new Date(time.customRangeEnd).getTime();
  } else {
    endTime = Date.now();
    if (time.period === TimePeriod.PAST_MINUTE) {
      startTime = endTime - 60 * 1000;
    } else if (time.period === TimePeriod.PAST_HOUR) {
      startTime = endTime - 60 * 60 * 1000;
    } else if (time.period === TimePeriod.PAST_SIX_HOURS) {
      startTime = endTime - 6 * 60 * 60 * 1000;
    } else if (time.period === TimePeriod.PAST_24_HOURS) {
      startTime = endTime - 24 * 60 * 60 * 1000;
    } else if (time.period === TimePeriod.PAST_WEEK) {
      startTime = endTime - 7 * 24 * 60 * 60 * 1000;
    } else if (time.period === TimePeriod.PAST_MONTH) {
      startTime = endTime - 30 * 24 * 60 * 60 * 1000;
    } else {
      startTime = endTime - 3 * 30 * 24 * 60 * 60 * 1000;
    }
  }
  return [startTime, endTime];
}

function getMHFromTimePeriod(timePeriod: TimePeriod): [number, number] {
  if (timePeriod === TimePeriod.PAST_MINUTE) {
    return [1, 0];
  } else if (timePeriod === TimePeriod.PAST_HOUR) {
    return [0, 1];
  } else if (timePeriod === TimePeriod.PAST_SIX_HOURS) {
    return [0, 6];
  } else if (timePeriod === TimePeriod.PAST_24_HOURS) {
    return [0, 24];
  } else if (timePeriod === TimePeriod.PAST_WEEK) {
    return [0, 24 * 7];
  } else if (timePeriod === TimePeriod.PAST_MONTH) {
    return [0, 24 * 30];
  } else if (timePeriod === TimePeriod.PAST_THREE_MONTHS) {
    return [0, 24 * 30 * 3];
  }
  return [0, 0];
}

export const getSearchParams = (
  page: number,
  pageSize: number,
  values: Partial<FilterValues>
) => {
  const params = new URLSearchParams();
  params.append('page', String(page));
  params.append('pageSize', String(pageSize));
  if (values.location && values.location.values.length > 0) {
    const query = getFilterQuery(
      values.location.values.map((item) => item.id),
      values.location.negative
    );
    params.append('locationIds', query);
  }
  if (values.status && values.status.values.length > 0) {
    const statusQuery: string[] = [...values.status.values];
    const query = getFilterQuery(statusQuery, values.status.negative);
    params.append('statuses', query);
  }
  if (values.time && values.time.period !== TimePeriod.ALL_TIME) {
    if (values.time.period === TimePeriod.CUSTOM_RANGE) {
      const [startTime, endTime] = getStartEndTimes(values.time);
      params.append('dateFrom', String(startTime));
      params.append('dateTo', String(endTime));
    } else {
      const [minutes, hours] = getMHFromTimePeriod(values.time.period);
      if (hours) {
        params.append('relativeTimeHours', String(hours));
      }
      if (minutes) {
        params.append('relativeTimeMinutes', String(minutes));
      }
    }
  }
  return params.toString();
};

export function useWithTimeFilterValue<T>(currentValue: T, period: TimePeriod) {
  const alarmListFilters: T & {
    time: TimeFilterValue;
  } = useMemo(
    () => ({
      time: {
        period,
        customRangeStart: '',
        customRangeEnd: '',
      },
      ...currentValue,
    }),
    [currentValue, period]
  );

  return alarmListFilters;
}
