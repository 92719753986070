import { CameraDetail } from '@hakimo-ui/hakimo/types';
import {
  useCanUpdateArmedStatus,
  useCanUpdateCamera,
} from '@hakimo-ui/hakimo/util';
import { SettingSectionActions } from './SettingSectionActions';
import SettingSectionAudioDevices from './SettingSectionAudioDevices';
import { SettingSectionCreds } from './SettingSectionCreds';
import { SettingSectionDelete } from './SettingSectionDelete';
import { SettingSectionDetails } from './SettingSectionDetails';
import { SettingSections } from './SettingSections';

interface Props {
  camera: CameraDetail;
  onDeletedCamera: () => void;
}

export function CameraSettings(props: Props) {
  const { camera, onDeletedCamera } = props;
  const canUpdateCamera = useCanUpdateCamera();
  const canUpdateArmedStatus = useCanUpdateArmedStatus();

  return (
    <SettingSections>
      {(canUpdateCamera || canUpdateArmedStatus) && (
        <SettingSectionActions camera={camera} />
      )}
      {canUpdateCamera && <SettingSectionDetails camera={camera} />}
      <SettingSectionAudioDevices camera={camera} />
      {canUpdateCamera && <SettingSectionCreds camera={camera} />}
      {canUpdateCamera && (
        <SettingSectionDelete camera={camera} onDeleted={onDeletedCamera} />
      )}
    </SettingSections>
  );
}
