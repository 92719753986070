import { Pagination } from '@hakimo-ui/hakimo/ui-table';

interface Props {
  fromIndex: number;
  toIndex: number;
  pageSize: number;
  total: number;
  onChangePage: (val: number) => void;
}

function UserListFooter(props: Props) {
  const { fromIndex, toIndex, pageSize, total, onChangePage } = props;

  return (
    <div className="dark:bg-dark-bg border-t bg-white p-4 dark:border-gray-800">
      <Pagination
        from={fromIndex + 1}
        to={toIndex + 1}
        pageSize={pageSize}
        total={total}
        onChangePage={(val) => onChangePage(val)}
      />
    </div>
  );
}

export default UserListFooter;
