import { useTenantDetails } from '@hakimo-ui/hakimo/data-access';
import { Tenant } from '@hakimo-ui/hakimo/types';
import { MultiSelect } from '@hakimo-ui/hakimo/ui-elements';
import { useUser } from '@hakimo-ui/hakimo/util';
import { Label } from '@hakimo-ui/shared/ui-base';
import { ReactElement, useState } from 'react';

interface Props {
  onChange: (items: string[]) => void;
  alarmTypes: string[];
  isEditing: boolean;
  disabledTypes?: string[];
  label?: string;
}

export function AlarmTypeSelect(props: Props) {
  const { onChange, alarmTypes, isEditing, disabledTypes, label } = props;
  const [allAlarmTypes, setAllAlarmTypes] = useState<string[]>([]);

  const user = useUser();
  const tenantId = user.tenant.id;

  const onSuccess = (tenant: Tenant) => {
    setAllAlarmTypes(tenant.alarmProcessingConfig?.alarm_types || []);
  };

  useTenantDetails(tenantId, onSuccess);

  const getDisplayValue = (item: string) => item;

  const getItems = (query: string) =>
    allAlarmTypes.filter((type) =>
      type.toLowerCase().includes(query.toLowerCase())
    );

  const onRemoveSelected = (index: number) => {
    const updatedAlarmTypes = [...alarmTypes];
    updatedAlarmTypes.splice(index, 1);
    onChange(updatedAlarmTypes);
  };
  const disabled = (item: string) => {
    if (disabledTypes && disabledTypes.includes(item)) {
      return true;
    }
    return false;
  };

  const customSelectedRenderer = (items: string[]): ReactElement => {
    return (
      <>
        {items.map((item, idx) => (
          <span key={item} className="mr-1">
            <Label
              text={getDisplayValue(item)}
              removable={isEditing}
              onClickRemove={() => onRemoveSelected(idx)}
              type="info"
            />
          </span>
        ))}
      </>
    );
  };

  return (
    <div className="mt-4 flex w-full items-center gap-x-4 pb-2">
      <span className="text-onlight-text-2 dark:text-ondark-text-2 text-sm">
        {label}
      </span>
      {isEditing ? (
        <MultiSelect
          value={alarmTypes}
          getItems={getItems}
          displayValue={getDisplayValue}
          onChange={onChange}
          customSelectedRenderer={customSelectedRenderer}
          disabled={disabled}
        />
      ) : (
        <div className="flex flex-wrap">
          {alarmTypes.map((item) => (
            <span className="mr-1 mb-1">
              <Label text={item} type="info" />
            </span>
          ))}
        </div>
      )}
    </div>
  );
}

export default AlarmTypeSelect;
