import { useLocalStorage } from '@hakimo-ui/hakimo/util';
import { ComponentType } from 'react';
import { Navigate } from 'react-router-dom';

function withAuthenticationRequired<T extends object>(
  WrapperComponent: ComponentType<T>
) {
  const WrappedComponent = (props: T) => {
    const [token] = useLocalStorage<string>('token');

    return typeof token === 'string' ? (
      <WrapperComponent {...props} />
    ) : (
      <Navigate to={`/login?redirectTo=${window.location.pathname}`} />
    );
  };

  return WrappedComponent;
}

export default withAuthenticationRequired;
