/* eslint-disable max-lines */
import {
  SiteSchedule,
  SiteScheduleOverride,
  SiteSchedulePayload,
} from '@hakimo-ui/hakimo/types';
import { WEEKDAYS, getWeekdays } from '@hakimo-ui/hakimo/util';
import dayjs from 'dayjs';
import dayjsTimezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);
dayjs.extend(dayjsTimezone);
interface CustomScheduleMap {
  [key: string]: number[];
}

export const getUpdatedSchedules = (
  scheduleIndex: number,
  newSchedule: SiteSchedule,
  allSchedules: SiteSchedule[]
) => {
  let finalAllSchedules = [...allSchedules];
  const oldSchedule = allSchedules[scheduleIndex];
  const changedDayIndex = newSchedule.enabledDays.findIndex(
    (day, i) => day !== oldSchedule.enabledDays[i]
  );
  //case where only start time and end time are updated
  if (changedDayIndex === -1) {
    finalAllSchedules[scheduleIndex] = newSchedule;
    return finalAllSchedules;
  }
  const changedDayValue = newSchedule.enabledDays[changedDayIndex];
  if (changedDayValue) {
    finalAllSchedules = finalAllSchedules.map((schedule, i) => {
      if (i !== scheduleIndex) {
        schedule.enabledDays[changedDayIndex] = false;
      }
      return schedule;
    });
  } else {
    finalAllSchedules[0].enabledDays[changedDayIndex] = true;
  }
  finalAllSchedules[scheduleIndex] = newSchedule;
  return finalAllSchedules;
};

export const getUpdatedSchedulesOnDelete = (
  deleteIndex: number,
  allSchedules: SiteSchedule[]
) => {
  const updatedSchedules = [...allSchedules];
  const deletedSchedule = updatedSchedules.splice(deleteIndex, 1)[0];
  allSchedules[0].enabledDays = allSchedules[0].enabledDays.map((day, j) => {
    if (deletedSchedule.enabledDays[j]) {
      day = true;
    }
    return day;
  });
  return updatedSchedules;
};

export const convertToMinutes = (time: string) => {
  const [hours, minutes] = time.split(':').map(Number);
  return hours * 60 + minutes;
};

export const convertToTimeFromMinutes = (totalMinutes: number) => {
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;
  const formattedHours = String(hours).padStart(2, '0');
  const formattedMinutes = String(minutes).padStart(2, '0');
  return `${formattedHours}:${formattedMinutes}`;
};

export const validateAllSchedules = (schedules: SiteSchedule[]) => {
  let isValid = true;
  let message = '';

  for (let i = 0; i < schedules.length; i++) {
    const currentSchedule = schedules[i];
    const startTime = currentSchedule.startTime;
    const endTime = currentSchedule.endTime;
    if (startTime === '' || endTime === '') {
      isValid = false;
      message =
        'Please provide a start time and an end time for every schedule.';
      break;
    }
    if (startTime === endTime) {
      isValid = false;
      message = 'Start time and end time should not be same for a schedule.';
      break;
    }
    const isNoDaySelected = currentSchedule.enabledDays.every(
      (day) => day === false
    );
    if (isNoDaySelected) {
      isValid = false;
      message = 'Ensure at least one day is chosen for each schedule.';
    }
  }

  return { isValid, message };
};

export const getDefaultSchedule = () => ({
  startTime: '',
  endTime: '',
  enabledDays: new Array(7).fill(true),
});

export function getSchedulesFromPayload(data: SiteSchedulePayload) {
  const allSchedules: SiteSchedule[] = [];
  // default schedule
  allSchedules.push({
    startTime: data.start_time,
    endTime: data.end_time,
    enabledDays: new Array(7).fill(true),
  });

  const customScheduleMap: CustomScheduleMap = {};

  const overrides = data.overrides || [];
  overrides.forEach((custom) => {
    const dayIndex = WEEKDAYS.indexOf(custom.day_of_week.toUpperCase());
    const key = `${custom.start_time}-${custom.end_time}`;
    if (customScheduleMap[key]) {
      customScheduleMap[key].push(dayIndex);
    } else {
      customScheduleMap[key] = [dayIndex];
    }
    // mark false for default schedule
    allSchedules[0].enabledDays[dayIndex] = false;
  });

  Object.entries(customScheduleMap).forEach(([key, value]) => {
    const [startTime, endTime] = key.split('-');
    const enabledDays = new Array(7).fill(false);
    value.forEach((val) => (enabledDays[val] = true));
    allSchedules.push({ startTime, endTime, enabledDays });
  });
  return allSchedules;
}

export function getPayloadFromSchedules(
  schedules: SiteSchedule[],
  timezone: string,
  tenantId: string
) {
  const payload: SiteSchedulePayload = {
    start_time: schedules[0].startTime,
    end_time: schedules[0].endTime,
    overrides: [],
    timezone,
    tenant_id: tenantId,
  };
  if (schedules.length > 1) {
    const customSchedules = schedules.slice(1);
    customSchedules.forEach((sch) => {
      sch.enabledDays.forEach((day, i) => {
        if (day) {
          const overrideItem: SiteScheduleOverride = {
            start_time: sch.startTime,
            end_time: sch.endTime,
            day_of_week: WEEKDAYS[i],
          };
          if (payload.overrides) {
            payload.overrides.push(overrideItem);
          } else {
            payload.overrides = [overrideItem];
          }
        }
      });
    });
  }

  return payload;
}

const rotateArrayValues = (arrVal: boolean[], direction: number) => {
  if (direction === 1) {
    // Rotate right
    arrVal.unshift(!!arrVal.pop());
  } else if (direction === -1) {
    // Rotate left
    arrVal.push(!!arrVal.shift());
  }
  return arrVal;
};

export function getUpdatedScheduleOnTimezoneChange(
  schedules: SiteSchedule[],
  newTimezone: string,
  prevTimezone: string
) {
  const updatedSchedules = schedules.map((schedule) => {
    const startTime = schedule.startTime;
    const endTime = schedule.endTime;
    const isPrevTzUTC = prevTimezone.toLowerCase() === 'utc';

    const [startHour, startMinute] = startTime.split(':');
    let prevTzStartDate = isPrevTzUTC ? dayjs.utc() : dayjs().tz(prevTimezone);
    prevTzStartDate = prevTzStartDate
      .set('hour', +startHour)
      .set('minute', +startMinute);

    const [endHour, endMinute] = endTime.split(':');
    let prevTzEndDate = isPrevTzUTC ? dayjs.utc() : dayjs().tz(prevTimezone);
    prevTzEndDate = prevTzEndDate
      .set('hour', +endHour)
      .set('minute', +endMinute);

    const newTzStartDate = prevTzStartDate.tz(newTimezone);
    const newTzEndDate = prevTzEndDate.tz(newTimezone);

    schedule.startTime = newTzStartDate.format('HH:mm');
    schedule.endTime = newTzEndDate.format('HH:mm');

    const isDayOfWeekChanged =
      prevTzEndDate.format('d') !== newTzStartDate.format('d');
    if (isDayOfWeekChanged) {
      let diff =
        parseInt(newTzStartDate.format('d')) -
        parseInt(prevTzEndDate.format('d'));
      // it resolves the case if converted day becomes saturday to sunday
      // Sunday is 0 while Saturday is 6. Handling this case
      diff = diff / Math.abs(diff);
      const updatedEnabledDays = rotateArrayValues(
        [...schedule.enabledDays],
        diff
      );
      schedule.enabledDays = updatedEnabledDays;
    }

    return schedule;
  });

  return updatedSchedules;
}

export interface DayRange {
  name: string;
  symbol: string;
  ranges: { startTime: number; endTime: number; isOverflow?: boolean }[];
}

export const getWeekRanges = (schedules: SiteSchedule[]) => {
  const weekDays = getWeekdays();
  const allWeekRanges: DayRange[] = weekDays.map((day) => {
    return {
      name: day.name,
      symbol: day.symbol,
      ranges: [],
    };
  });

  schedules.forEach((sched) => {
    const enabledDays = sched.enabledDays;
    for (let i = 0; i < enabledDays.length; i++) {
      if (enabledDays[i] && sched.startTime && sched.endTime) {
        const startTime = convertToMinutes(sched.startTime);
        const endTime = convertToMinutes(sched.endTime);

        if (startTime <= endTime) {
          allWeekRanges[i].ranges.push({
            startTime,
            endTime,
          });
        } else {
          allWeekRanges[i].ranges.push({
            startTime,
            endTime: convertToMinutes('23:59'),
          });
          const nextDayIndex = (i + 1) % weekDays.length;
          allWeekRanges[nextDayIndex].ranges.push({
            startTime: convertToMinutes('00:00'),
            endTime,
            isOverflow: true,
          });
        }
      }
    }
  });

  return allWeekRanges;
};
