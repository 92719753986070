import { useSOP } from '@hakimo-ui/hakimo/data-access';
import { SOPWorkflowView } from '@hakimo-ui/hakimo/feature-shared';
import { SOPDTO } from '@hakimo-ui/hakimo/types';
import { loadDomPurify } from '@hakimo-ui/hakimo/util';
import { Alert, HakimoSpinner } from '@hakimo-ui/shared/ui-base';
import { useState } from 'react';
import SOPOverview from './SOPOverview';

interface Props {
  locationId: string;
}

export function SOPRoot(props: Props) {
  const { locationId } = props;
  const [sop, setSOP] = useState<SOPDTO>();
  const { isLoading, isRefetching, isError, error } = useSOP(
    {
      locationId,
    },
    async ({ sop_text, sop_workflow, id }) => {
      const domPurify = await loadDomPurify();
      setSOP({ sop_text: domPurify(sop_text), sop_workflow, id });
    }
  );

  if (isLoading || isRefetching) {
    return (
      <span className="flex h-[26rem] w-full items-center justify-center">
        <HakimoSpinner />
      </span>
    );
  }

  let sopWorkflow = sop?.sop_workflow;
  if (sopWorkflow) {
    sopWorkflow = {
      ...sopWorkflow,
      notes: sopWorkflow.notes ?? [],
      escalationProtocol: sopWorkflow.escalationProtocol ?? [],
      quickResolveActions: sopWorkflow.quickResolveActions ?? [],
    };
  }

  return (
    <>
      {isError && <Alert type="error">{error.message}</Alert>}
      {sop && (
        <div className="flex gap-4 divide-x">
          {sop?.sop_text && (
            <div className="flex-0">
              <SOPOverview
                sopOverview={sop.sop_text}
                sopId={sop?.id || ''}
                sopWorkflow={sopWorkflow}
              />
            </div>
          )}
          <SOPWorkflowView sopWorkflow={sopWorkflow} sopId={sop?.id || ''} />
        </div>
      )}
    </>
  );
}

export default SOPRoot;
