import {
  useDeleteUser,
  useUpdateUser,
  useValidRoles,
} from '@hakimo-ui/hakimo/data-access';
import { QueryResult } from '@hakimo-ui/hakimo/ui-elements';
import { toast } from '@hakimo-ui/hakimo/util';
import { Alert, Button, Modal, Radio } from '@hakimo-ui/shared/ui-base';
import { useState } from 'react';

interface Props {
  currentUserData: {
    id: string;
    role: string;
    email: string;
  };
  onClose: () => void;
}

export function EditUserModal(props: Props) {
  const { currentUserData, onClose } = props;
  const { id: userId, role: currentUserRole, email } = currentUserData;
  const [role, setRole] = useState<string>(currentUserRole);

  const roleUpdated = Boolean(userId && role && currentUserRole !== role);

  const onCloseCb = () => {
    onClose();
    updateUserMutation.reset();
  };
  const updateUserMutation = useUpdateUser(userId, () => {
    onCloseCb();
    toast('User updated', { type: 'success' });
  });
  const deleteUserMutation = useDeleteUser(userId, () => {
    onCloseCb();
    toast('User deleted', { type: 'success' });
  });
  const roleQuery = useValidRoles();

  const actions = (
    <>
      <Button
        variant="primary"
        onClick={() => updateUserMutation.mutate({ roles: [role] })}
        disabled={!roleUpdated}
        loading={deleteUserMutation.isLoading || updateUserMutation.isLoading}
      >
        Submit
      </Button>
      <Button onClick={onCloseCb}>Cancel</Button>
    </>
  );

  return (
    <Modal title="Edit User" open={true} onClose={onCloseCb} footer={actions}>
      <div className="w-96">
        {updateUserMutation.isError ? (
          <div className="py-4 px-8">
            <Alert type="error">{updateUserMutation.error.message}</Alert>
          </div>
        ) : null}
        {deleteUserMutation.isError ? (
          <div className="py-4 px-8">
            <Alert type="error">{deleteUserMutation.error.message}</Alert>
          </div>
        ) : null}
        <div className="mb-2 px-8 py-4">
          <QueryResult queryResult={roleQuery}>
            {(data) => (
              <Radio
                items={data ?? []}
                value={role}
                onChange={setRole}
                displayValue={(item) => item}
              />
            )}
          </QueryResult>
        </div>
        <div className="mb-2 px-8 py-4">
          <Button
            variant="error"
            onClick={() => deleteUserMutation.mutate({ enabled: false })}
            loading={
              deleteUserMutation.isLoading || updateUserMutation.isLoading
            }
          >
            Delete User {email}
          </Button>
        </div>
      </div>
    </Modal>
  );
}

export default EditUserModal;
