import { SiteSchedulePayload } from '@hakimo-ui/hakimo/types';
import useAuthenticatedMutation from '../shared/useAuthenticatedMutation';

export function useUpdateSiteSchedule(onSuccess: () => void) {
  const url = `/v2/orm/tenant/monitoring/window`;

  const request = new Request(url, {
    method: 'PATCH',
  });

  return useAuthenticatedMutation<null, SiteSchedulePayload>(request, {
    onSuccess,
    onSuccessInvalidationKeys: [['tenant_monitoring_window']],
  });
}

export default useUpdateSiteSchedule;
