import {
  LABEL_PANEL_WIDTH,
  MARGIN,
  TIMELINE_HEIGHT,
  TIMELINE_V_SPACE,
} from './constants';
import { TimelineSource } from './types';

interface Props {
  sources: TimelineSource[];
  width: number;
}

export function Tracks(props: Props) {
  const { sources, width } = props;
  return (
    <g
      className="tracks"
      transform={`translate(${LABEL_PANEL_WIDTH},${MARGIN.top})`}
    >
      {sources.map((source, i) => (
        <g
          key={source.id}
          className="timeline"
          transform={`translate(0, ${
            (TIMELINE_HEIGHT + TIMELINE_V_SPACE) * i
          })`}
        >
          <rect
            x="0"
            y="0"
            width={width}
            height={TIMELINE_HEIGHT}
            className="dark:fill-ondark-bg-2 fill-onlight-bg-3 opacity-40"
          />
        </g>
      ))}
    </g>
  );
}
