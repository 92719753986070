import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { Fragment, ReactNode } from 'react';
import Button from '../button/Button';

interface Props {
  open: boolean;
  onClose: () => void;
  children: ReactNode;
  title?: string;
  footer?: ReactNode;
  closable?: boolean;
}

export function Modal(props: Props) {
  const { open, onClose, children, title, footer, closable = true } = props;

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={onClose}
        static={!closable}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="dark:bg-dark-bg dark:text-dark-text relative transform rounded-lg bg-white text-left shadow-xl transition-all">
                {closable && (
                  <div className="absolute top-4 right-8">
                    <Button variant="icon" onClick={onClose}>
                      <XMarkIcon className="w-5" />
                    </Button>
                  </div>
                )}
                {title && (
                  <div className="flex items-center justify-between border-b px-8 py-4 dark:border-gray-600">
                    <h1 className="text-lg text-gray-800 dark:text-gray-200">
                      {title}
                    </h1>
                  </div>
                )}
                {children}
                {footer && (
                  <div className="flex gap-2 border-t px-8 py-4 dark:border-gray-600">
                    {footer}
                  </div>
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default Modal;
