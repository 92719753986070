import { ProcConfig } from '@hakimo-ui/hakimo/types';
import { Button, Label } from '@hakimo-ui/shared/ui-base';
import {
  CheckCircleIcon,
  ChevronDownIcon,
  PencilSquareIcon,
  TrashIcon,
} from '@heroicons/react/24/outline';
import { ChevronRightIcon } from '@heroicons/react/24/solid';
import { useState } from 'react';
import { ConfigTitle } from './ConfigTitle';
import { ExpandedConfigSection } from './ExpandedConfigSection';

interface SingleConfigProp {
  config: ProcConfig;
  onChange: (newConfig: Partial<ProcConfig>) => void;
  onResetConfig: () => void;
}
export function SingleConfig(props: SingleConfigProp) {
  const { config, onChange, onResetConfig } = props;
  const [expanded, setExpanded] = useState<boolean>(false);
  return (
    <div className="bg-onlight-bg-2 dark:bg-ondark-bg-2 text-onlight-text-1 dark:text-ondark-text-1 rounded-lg shadow-sm">
      <div className="flex justify-start">
        <div className="m-2 my-auto align-middle">
          <Button
            variant="icon"
            onClick={() => setExpanded((oldExpand) => !oldExpand)}
          >
            {expanded ? (
              <ChevronDownIcon className="text-onlight-text-2 dark:text-ondark-text-2 h-5 w-5" />
            ) : (
              <ChevronRightIcon className="text-onlight-text-2 dark:text-ondark-text-2 h-5 w-5" />
            )}
          </Button>
        </div>
        <ConfigTitle expanded={expanded} onChange={onChange} config={config} />
        <div className="flex justify-start">
          {!config.deleted && (
            <span className="my-auto ml-10 mr-2 flex gap-x-2 align-middle">
              {config.isEditing ? (
                <Button
                  variant="icon"
                  onClick={() => onChange({ isEditing: false })}
                >
                  <CheckCircleIcon className="h-5 w-5" />
                  <span className="ml-1">Done</span>
                </Button>
              ) : (
                <>
                  <Button
                    variant="icon"
                    onClick={() =>
                      onChange({ deleted: true, isEditing: false })
                    }
                  >
                    <TrashIcon className="h-5 w-5" />
                  </Button>
                  <Button
                    variant="icon"
                    onClick={() => {
                      onChange({ isEditing: true });
                      setExpanded(true);
                    }}
                  >
                    <PencilSquareIcon className="h-5 w-5" />
                  </Button>
                </>
              )}
            </span>
          )}
          <span className="my-auto mr-2 inline-block align-middle">
            {config.isNew ? (
              <Label text="New" type="success" />
            ) : config.deleted ? (
              <Label
                text="Deleted"
                type="error"
                removable
                onClickRemove={() => onChange({ deleted: false })}
              />
            ) : config.updated ? (
              <Label
                text="Modified"
                type="warning"
                removable
                onClickRemove={onResetConfig}
              />
            ) : null}
          </span>
        </div>
      </div>
      <div>
        {expanded && (
          <ExpandedConfigSection
            config={config}
            onChange={onChange}
            isEditing={config.isEditing}
          />
        )}
      </div>
    </div>
  );
}
