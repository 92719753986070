import {
  DoorFilters,
  DoorNameFilterValue,
  LabelStatusFilterValue,
  LocationFilterValue,
} from '@hakimo-ui/hakimo/types';
import { SlideOver } from '@hakimo-ui/hakimo/ui-elements';
import { useState } from 'react';
import {
  DEFAULT_DOOR_FILTER_VALUE,
  DEFAULT_LABEL_STATUS_FILTER_VALUE,
  DEFAULT_LOCATION_FILTER_VALUE,
} from '../constants';
import FilterPanelContent from './FilterPanelContent';

interface Props {
  open: boolean;
  filters: DoorFilters;
  onApplyFilters: (value: DoorFilters) => void;
  onClose: () => void;
}

function FilterPanel(props: Props) {
  const { open, onClose, filters, onApplyFilters } = props;

  const [labelStatusFilterValue, setLabelStatusFilterValue] =
    useState<LabelStatusFilterValue>(filters.labelStatus);

  const [locationFilterValue, setLocationFilterValue] =
    useState<LocationFilterValue>(filters.location);

  const [doorFilterValue, setDoorFilterValue] = useState<DoorNameFilterValue>(
    filters.door
  );

  const onApplyFiltersCb = () => {
    onApplyFilters({
      labelStatus: labelStatusFilterValue,
      location: locationFilterValue,
      door: doorFilterValue,
    });

    onClose();
  };

  const onResetFilters = () => {
    setLabelStatusFilterValue(DEFAULT_LABEL_STATUS_FILTER_VALUE);
    setLocationFilterValue(DEFAULT_LOCATION_FILTER_VALUE);
    setDoorFilterValue(DEFAULT_DOOR_FILTER_VALUE);
  };

  return (
    <SlideOver title="Door Filters" open={open} onClose={onClose}>
      <FilterPanelContent
        labelStatusFilterValue={labelStatusFilterValue}
        locationFilterValue={locationFilterValue}
        doorFilterValue={doorFilterValue}
        onChangeLabelStatusFilterValue={setLabelStatusFilterValue}
        onChangeLocationFilterValue={setLocationFilterValue}
        onChangeDoorFilterValue={setDoorFilterValue}
        onApplyFilters={onApplyFiltersCb}
        onResetFilters={onResetFilters}
      />
    </SlideOver>
  );
}

export default FilterPanel;
