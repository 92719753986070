import { Door } from '@hakimo-ui/hakimo/types';
import { DoorActionModal, getModalMapping } from './utils';

interface Props {
  activeModal: DoorActionModal;
  onClose: () => void;
  selectedMapping?: Door;
  selectedDoorIds: string[];
  allDoors: Door[];
  doorToMap?: Door;
}
export function DoorListModals(props: Props) {
  const {
    selectedMapping,
    selectedDoorIds,
    onClose,
    activeModal,
    allDoors,
    doorToMap,
  } = props;

  const SelectedModal = getModalMapping().find(
    (modal) => modal.id === activeModal
  );

  const modalProps = {
    selectedDoors: selectedDoorIds,
    onClose,
    allDoors,
    dcp: selectedMapping,
    open: true,
    inputDoor: doorToMap,
  };

  return SelectedModal ? <SelectedModal.Modal {...modalProps} /> : null;
}
