import { useLocalStorage } from '@hakimo-ui/hakimo/util';
import Joyride, { CallBackProps, Placement, STATUS } from 'react-joyride';

export function QuickActionsTour() {
  const [showTour, setShowTour] = useLocalStorage<boolean>(
    'showQuickActionsTour',
    true
  );

  const handleCallback = (val: CallBackProps) => {
    const { status } = val;

    if (status === STATUS.FINISHED || status === STATUS.SKIPPED) {
      setShowTour(false);
    }
  };

  const steps = [
    {
      target: '.quick-actions-panel',
      content:
        'This is Quick Actions Panel to check out actions without letting live view going out of your sight.',
      placement: 'left' as Placement,
      disableBeacon: true,
    },
    {
      target: '.sop-quick-action',
      content: 'Check out Standard Operating Procedure (SOP) from here',
      placement: 'left' as Placement,
    },
    {
      target: '.floorplan-quick-action',
      content: 'Look at premises floor plan over here.',
      placement: 'left' as Placement,
    },
    {
      target: '.contacts-quick-action',
      content: 'Check location contacts over here',
      placement: 'left' as Placement,
    },
  ];

  return (
    <Joyride
      steps={steps}
      callback={handleCallback}
      run={showTour}
      continuous
      showSkipButton
      showProgress
      disableScrollParentFix
      locale={{
        last: 'Close',
      }}
    />
  );
}

export default QuickActionsTour;
