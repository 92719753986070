import { useUser } from '@hakimo-ui/hakimo/util';
import { useAmplitude } from './useAmplitude';
import { useIntercom } from './useIntercom';
import { useSentry } from './useSentry';

export type IntercomSource = 'hakimo' | 'hakimo-admin';

export interface AppInitOptions {
  sentryDsn: string;
  sentryEnabled: boolean;
  intercomAppId: string;
  intercomEnabled: boolean;
  intercomSource: IntercomSource;
  amplitudeEnabled: boolean;
}

interface Props {
  options: AppInitOptions;
}

export function AppInit(props: Props) {
  const {
    options: {
      sentryDsn,
      sentryEnabled,
      intercomAppId,
      intercomEnabled,
      intercomSource,
      amplitudeEnabled,
    },
  } = props;

  const user = useUser();
  useAmplitude(user, amplitudeEnabled);
  useIntercom(user, intercomAppId, intercomEnabled, intercomSource);
  useSentry(user, sentryDsn, sentryEnabled);
  return null;
}

export default AppInit;
