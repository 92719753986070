import useAuthenticatedRequest from '../shared/useAuthenticatedRequest';

export function useCameraLivestreamUrl(cameraId: string) {
  const url = `/v2/orm/camera/livestream/${cameraId}`;
  const request = new Request(url);

  return useAuthenticatedRequest<string>(request, {
    queryKey: ['livestream-url', cameraId],
    responseModifier: async (response) => {
      const respJson = await response.json();
      return respJson.payload;
    },
    cacheTime: 0,
    refetchInterval: 10 * 60 * 1000,
  });
}

export default useCameraLivestreamUrl;
