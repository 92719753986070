import LoadingIndicator from '../loading-indicator/LoadingIndicator';

interface Props {
  text?: string;
}
export function PageLoadingIndicator(props: Props) {
  const { text } = props;
  return (
    <div className="dark:bg-dark-bg flex h-screen items-center justify-center">
      <LoadingIndicator text={text} />
    </div>
  );
}

export default PageLoadingIndicator;
