import { draggableNodeAtom, eventTracker } from '@hakimo-ui/hakimo/util';
import { Button } from '@hakimo-ui/shared/ui-base';
import { PhoneIcon } from '@heroicons/react/24/outline';
import { useSetAtom } from 'jotai';
import { DraggableCallView } from './DraggableCallView';

interface Props {
  toNumber: string;
  toName: string;
  locationAlarmId?: string;
  locationTenantId?: string;
}

export function VoiceCall(props: Props) {
  const { toNumber, toName, locationAlarmId, locationTenantId } = props;
  const setDraggableNode = useSetAtom(draggableNodeAtom);
  const onClose = () => {
    setDraggableNode(null);
  };

  const onClick = () => {
    setDraggableNode(
      <DraggableCallView
        onCloseCb={onClose}
        toName={toName}
        toNumber={toNumber}
        locationAlarmId={locationAlarmId}
        locationTenantId={locationTenantId}
      />
    );
  };

  return (
    <Button
      onClick={onClick}
      onSideEffect={eventTracker('call_location_contact')}
    >
      <PhoneIcon className="mr-2 h-4 w-4" />
      Call
    </Button>
  );
}
