import { VideoControlsList } from '@hakimo-ui/hakimo/types';
import Hls from 'hls.js';
import { useEffect, useState } from 'react';
import CustomVideoControls from '../custom-video-controls/CustomVideoControls';

interface Props {
  videoPath?: string;
  videoRef?: React.RefObject<HTMLVideoElement>;
  containerRef?: React.RefObject<HTMLDivElement>;
  timeZone: string;
  hls?: Hls;
  isFullScreen: boolean;
  onFullScreen: () => void;
  controlsList: VideoControlsList[];
}

export function HLSVideoControls(props: Props) {
  const { hls, timeZone, controlsList, ...remainingProps } = props;
  const [timestamp, setTimestamp] = useState<Date>();

  useEffect(() => {
    const intervalId = window.setInterval(() => {
      if (hls && hls.playingDate) {
        setTimestamp(hls.playingDate);
      }
    }, 1000);

    return () => {
      intervalId && clearInterval(intervalId);
    };
  }, [hls]);

  return controlsList?.includes('onlyTime') ? (
    timestamp && (
      <div className="from-dark-surface text-dark-text absolute left-0 right-0 bottom-0 ml-2 bg-gradient-to-t p-1 pt-2 text-xs">
        {timestamp.toLocaleString('en-US', {
          timeZone,
        })}
      </div>
    )
  ) : (
    <CustomVideoControls
      time={timestamp?.toLocaleString('en-US', {
        timeZone,
      })}
      controlsList={controlsList}
      {...remainingProps}
    />
  );
}
