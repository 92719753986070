import { Selectable } from '@hakimo-ui/shared/ui-base';

export const armStatusRadioItems: Selectable[] = [
  {
    id: 'arm',
    name: 'Arm',
  },
  {
    id: 'disarm',
    name: 'Disarm',
  },
];

export const DATE_FORMAT = 'YYYY-MM-DDTHH:mm';
