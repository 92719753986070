import { useSaveLabel } from '@hakimo-ui/hakimo/data-access';
import { DCP, Point } from '@hakimo-ui/hakimo/types';
import { Dot } from '@hakimo-ui/hakimo/ui-elements';
import { Page } from '@hakimo-ui/hakimo/ui-layout';
import { toast } from '@hakimo-ui/hakimo/util';
import { Alert } from '@hakimo-ui/shared/ui-base';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DOOR_ANNOTATION_NOT_VALID_WARNING_MESSAGE } from './constants';
import DoorLabelling from './DoorLabelling';
import LabelPanel from './label-panel/LabelPanel';
import {
  cameraPositionAtom,
  doorMarkersAtom,
  floorMarkerAtom,
  localDCPAtom,
} from './store/state';
import { normalizeLabel, validateDoorAnnotation } from './util';

interface Props {
  dcp: DCP;
}

export function LabelDoor(props: Props) {
  const { dcp } = props;

  const saveLabelMutation = useSaveLabel(dcp.id, () => {
    toast('Label saved', { type: 'success' });
  });
  const navigate = useNavigate();
  const [doorMarkers, setDoorMarkers] = useAtom(doorMarkersAtom);
  const setCameraPosition = useSetAtom(cameraPositionAtom);
  const [floorMarker, setFloorMarker] = useAtom(floorMarkerAtom);
  const localDCP = useAtomValue(localDCPAtom);
  useEffect(() => {
    setDoorMarkers(dcp.label?.doorMarkers ?? null);
    setFloorMarker(dcp.label?.floorMarker ?? null);
    setCameraPosition(dcp.label?.cameraPosition ?? 'unknown');
  }, [dcp, setDoorMarkers, setFloorMarker, setCameraPosition]);
  const [isDoorAnnotationValid, setIsDoorAnnotationValid] = useState(true);

  useEffect(() => {
    setIsDoorAnnotationValid(validateDoorAnnotation(localDCP));
  }, [localDCP]);

  const onAddDoorMarker = (point: Point) => {
    setDoorMarkers((oldMarkers) => {
      if (oldMarkers) {
        return [...oldMarkers, point];
      } else {
        return [point];
      }
    });
  };

  const onClickClear = () => {
    setDoorMarkers(null);
    setFloorMarker(null);
    setCameraPosition('unknown');
  };
  const onClickReset = () => {
    setCameraPosition(dcp.label?.cameraPosition ?? 'unknown');
    setDoorMarkers(dcp.label?.doorMarkers ?? null);
    setFloorMarker(dcp.label?.floorMarker ?? null);
  };

  const onClickSave = () => {
    saveLabelMutation.mutate(normalizeLabel(localDCP));
  };

  const subtitle = (
    <span className="inline-flex items-center text-xs text-gray-500">
      {dcp.doorName}
      <Dot />
      {dcp.id}
    </span>
  );

  return (
    <Page
      title="Door Details"
      subtitle={subtitle}
      onClickBack={() => navigate('/doors')}
    >
      <div className="space-y-4 pb-8">
        {saveLabelMutation.isError && (
          <Alert type="error">{saveLabelMutation.error.message}</Alert>
        )}
        {!isDoorAnnotationValid && (
          <Alert type="warning">
            {DOOR_ANNOTATION_NOT_VALID_WARNING_MESSAGE}
          </Alert>
        )}
        <div className="flex flex-col gap-6 lg:flex-row">
          <div className="basis-4/5">
            <DoorLabelling
              dcpId={dcp.id}
              doorMarkers={doorMarkers}
              floorMarker={floorMarker}
              onAddDoorMarker={onAddDoorMarker}
              onAddFloorMarker={setFloorMarker}
            />
          </div>
          <div className="sticky top-4 w-full basis-1/5 self-start">
            <LabelPanel
              label={localDCP}
              isLoading={saveLabelMutation.isLoading}
              onChangeCameraPosition={setCameraPosition}
              onClickReset={onClickReset}
              onClickClear={onClickClear}
              onClickSave={onClickSave}
            />
          </div>
        </div>
      </div>
    </Page>
  );
}

export default LabelDoor;
