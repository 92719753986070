import { Marker, Point, Polygon } from '@hakimo-ui/hakimo/types';
import { useRerenderOnWindowResize } from '@hakimo-ui/shared/ui-base';
import { useMemo, useRef } from 'react';
import {
  getMarkerDrawings,
  getPolygonDrawings,
  getPolygonLines,
  imageMarkerToClientMarker,
} from './util';
interface Props {
  imgUrl: string;
  polygons?: Polygon[];
  markers?: Marker[];
  onClickImage: React.MouseEventHandler<HTMLImageElement>;
  onClickShape?: React.MouseEventHandler<HTMLOrSVGElement>;
  onImageLoadCallback: (
    imgSize: Point,
    displaySize: Point,
    imgTopLeft: Point
  ) => void;
}
export function ImagePanel(props: Props) {
  const {
    imgUrl,
    polygons: polygonsImage,
    markers: markersImage,
    onClickImage,
    onClickShape,
    onImageLoadCallback,
  } = props;
  const imageRef = useRef<HTMLImageElement>(null);
  const imageRect = imageRef.current?.getBoundingClientRect();
  useRerenderOnWindowResize();
  const polygons = useMemo(() => {
    if (!imageRect || !imageRef.current || !polygonsImage) {
      return [];
    }
    return polygonsImage.map((polygon: Polygon) => {
      return {
        ...polygon,
        points: polygon.points.map((marker) => {
          if (imageRef.current) {
            return imageMarkerToClientMarker(
              [imageRect.width, imageRect.height],
              [imageRef.current.naturalWidth, imageRef.current.naturalHeight],
              { point: marker }
            ).point;
          }
          return marker;
        }),
      };
    });
  }, [imageRect, imageRef, polygonsImage]);

  const markers = useMemo(() => {
    if (!markersImage) {
      return undefined;
    }
    if (!imageRect || !imageRef.current) {
      return [];
    }
    return markersImage.map((point) => {
      if (imageRef.current) {
        return imageMarkerToClientMarker(
          [imageRect.width, imageRect.height],
          [imageRef.current.naturalWidth, imageRef.current.naturalHeight],
          point
        );
      }
      return point;
    });
  }, [imageRef, imageRect, markersImage]);
  const onLoadImage: React.ReactEventHandler<HTMLImageElement> = (e) => {
    const imgSize: Point = [
      e.currentTarget.naturalWidth,
      e.currentTarget.naturalHeight,
    ];
    const imgRect = e.currentTarget.getBoundingClientRect();
    onImageLoadCallback(
      imgSize,
      [imgRect.width, imgRect.height],
      [imgRect.left, imgRect.top]
    );
  };
  return (
    <div className="relative cursor-crosshair">
      <img
        src={imgUrl}
        ref={imageRef}
        alt="camera view                                                                            "
        onLoad={onLoadImage}
        className="w-full"
        onClick={onClickImage}
        onContextMenu={onClickImage}
      />
      {getPolygonDrawings(polygons, onClickShape)}
      {getPolygonLines(polygons)}
      {getMarkerDrawings(markers, onClickShape)}
    </div>
  );
}

export default ImagePanel;
